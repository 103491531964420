/**
 * This component displays a user's name and flags.
 */
import _ from 'lodash';
import React from 'react';
import {Popup} from 'semantic-ui-react';

import {validateLength} from 'concierge-common';
import {isUserDisplayName} from 'concierge-common';
import {log} from 'concierge-common';
import ActionUtils from './utils/action-utils';
import ApiUtils from '../client-db/api/api-utils';
//import ObjC from '../client-db/api/obj';
import UserC from '../client-db/api/user';
import MyCardDisplayName from './my-card-display-name';
import Flag from './flag';


class UserNameAndFlagsComponent extends React.Component {

  /**
   * Called by MyCardHeader when the user is finished editing the
   * the user's displayName property and wants to send the new
   * value to the server.
   *
   * @param {Object} userProps - An Object containing the new displayName.
   * (This is the "task" parameter that the RIEInput sends us.)
   * e.g. {displayName:"John Smith"}
   * @param {String} userProps.displayName - The new displayName value.
   */
  handleChangeDisplayName = (userProps) => {
    log.trace("handleChangeDisplayName(), userProps:", userProps);
    userProps.displayName = _.trim(userProps.displayName);
    //const error = validateUserDisplayName(userProps.displayName);
    const error = validateLength(userProps.displayName,
      UserC.DISPLAY_NAME_MIN_LENGTH,
      UserC.DISPLAY_NAME_MAX_LENGTH, "user's name");
    const {curUser, user, cA_SetError} = this.props;
    const userId = user._id;
    ActionUtils.possiblyEditUser(userProps, error, curUser,
      userId, cA_SetError);
  }

  handleValidationFailDisplayName = (result, displayName) => {
    log.trace("handleValidationFailDisplayName(), displayName:", displayName);
    const {cA_SetError} = this.props;
    //const error = validateUserDisplayName(displayName);
    const error = validateLength(displayName,
      UserC.DISPLAY_NAME_MIN_LENGTH,
      UserC.DISPLAY_NAME_MAX_LENGTH, "user's name");
    cA_SetError(error);
  }

  handleAddFlag(user) {
    log.trace("handleAddFlag()");
    const {curUser} = this.props;

    user.addFlag(UserC.DEFAULT_FLAG_COUNTRY_CODE);
    const flags = user.flags;
    const userProps = {flags};
    ApiUtils.editRxDBUser(curUser, user._id, userProps);
  }
  handleDeleteFlag(user, countryCode) {
    log.trace("handleDeleteFlag(), countryCode:", countryCode);

    const {curUser} = this.props;
    user.deleteFlag(countryCode);
    const flags = user.flags;
    const userProps = {flags};
    ApiUtils.editRxDBUser(curUser, user._id, userProps);
  }
  handleSelectFlag(user, oldCountryCode, newCountryCode) {
    log.trace("handleSelectFlag(), oldCountryCode:", oldCountryCode);
    log.trace("handleSelectFlag(), newCountryCode:", newCountryCode);
    const {curUser} = this.props;

    //user.addFlag(newCountryCode);
    //user.deleteFlag(oldCountryCode);
    user.replaceFlag(oldCountryCode, newCountryCode);
    const flags = user.flags;
    log.trace("After edit, flags:", flags);
    const userProps = {flags};
    ApiUtils.editRxDBUser(curUser, user._id, userProps);
  }

  createUserFlags(user) {
    const flags = user.flags;
    log.trace("UserComponent.createUserFlags(), flags:", flags);
    if (!flags || flags.length < 1) {
      // Should never happen.
      return undefined;
    }

    const userFlagDisabled = !user.edit;

    // removeFlags is the list of flags that should NOT appear in
    // the list of flag choices because the flags already exist in
    // the user's list of flags.
    const removeFlags = flags;
    log.trace("UserComponent.createUserFlags(), removeFlags:", removeFlags);

    // Set up the onAdd function if the user does not already
    // have the max number of flags in his/her list of flags.
    const onAdd = flags.length < 4 ?
      () => this.handleAddFlag(user) :
      undefined;

    // Similar to onAdd, set up the onDelete function if the
    // user has more than one flag in his/her list of flags.
    // (The user must always have at least one flag.)
    const onDelete = flags.length > 1 ?
      (notUsed, countryCode) => this.handleDeleteFlag(user, countryCode) :
      undefined;

    const onSelect = (oldCountryCode, newCountryCode) =>
                this.handleSelectFlag(user, oldCountryCode, newCountryCode);

    const className = "flags";
    const component =
      <span className={className}>
        {_.map(flags, (flag, index) => {
          return(
            <Flag
              countryCode={flag}
              disabled={userFlagDisabled}
              key={index}
              onAdd={onAdd}
              onDelete={onDelete}
              onSelect={onSelect}
              removeFlags={removeFlags}
            />
          )
        })}
      </span>
    return component;
  }

  render() {
    const {props} = this;
    log.trace("UserNameAndFlagsComponent.render() props:", props);
    const {user} = props;
    const {displayName} = user;

    const isEditable = !!user.edit;

    const userFlags = this.createUserFlags(user);
    const userFlagsPopupContent = this.createUserFlags(user);
    const overflowIndicator = <span className="overflow-indicator">...</span>
    return(
      <MyCardDisplayName text={displayName}
        propName="displayName"
        isEditable={isEditable}
        change={this.handleChangeDisplayName}
        handleValidationFail={this.handleValidationFailDisplayName}
        validate={isUserDisplayName}
        maxLength={UserC.DISPLAY_NAME_MAX_LENGTH} >
        {userFlags}
        <Popup on="hover" 
          content={userFlagsPopupContent}
          trigger={overflowIndicator}
        />
      </MyCardDisplayName>
    );
  };
}

export default UserNameAndFlagsComponent;
