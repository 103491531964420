/**
 */
import _ from 'lodash';
import React from 'react';
import {log, Act} from 'concierge-common';
import Obj from '../client-db/api/obj';
import ObjUtils from '../client-db/api/obj-utils';
import MyMap from '../containers/my-map';


/**
 */
class LocationMapPanel extends React.Component {

  static createStateFromProps(props) {
    const {curUser, objId, cache, cA_GetObj} = props;
    const obj = ObjUtils.get(curUser, objId, undefined, cache, cA_GetObj);
    const state = {obj};
    return state;
  }

  static getDerivedStateFromProps(props, state) {
    const newState = LocationMapPanel.createStateFromProps(props);
    if (_.isEqual(newState, state)) {  // Should I be using _.isEqual?
      return null;
    }
    else {
      return newState;
    }
  }

  /*
  componentDidMount() {
    const state = LocationMapPanel.createStateFromProps(this.props);
    this.setState(state);
  }
  */
  
  constructor(props) {
    super(props);
    //this.state = LocationMapPanel.createStateFromProps(props);
    this.state = {obj:null};
  }

  render() {
    log.trace("LocationMapPanel.render(), this.props:", this.props);
    const {props, state} = this;
    const {obj} = state;

    return (
      <div className="map-panel">
        <MyMap obj={obj} {...props} />
      </div>
    );
  }
}

export default LocationMapPanel;
