import _ from 'lodash';
import {log, MyError} from 'concierge-common';
import {USERS, WAYS, ACTS, ITNS} from '../../global-constants';
import {PENDING, FLAGGED, APPROVED} from '../../global-constants';
import ImageUtils from '../../utils/image-utils';
import LocalDB from '../../client-db/local-db';
import Obj from '../../client-db/api/obj';
import User from '../../client-db/api/user';
import ObjUtils from '../../client-db/api/obj-utils';
import ApiUtils from '../../client-db/api/api-utils';
//import LocationUtils from './location-utils';
//import ConfirmationUtils from './confirmation-utils';
import ActionUtils from './action-utils';

// NOTE: Hack.
//import FilterAndSortButton from '../filter-and-sort-button';


// Add the passed in action to the passed in actions array
// if action is not falsey.
const add = (action, actions) => {
  if (action) {
    if (Array.isArray(action)) {
      actions.push(...action);
    }
    else {
      actions.push(action);
    }
  }
}

// Set obj.parkWaypoint to undefined.
const removeParkWaypoint = (obj, curUserObj) => {
  let action = undefined;
  if (obj.edit && obj.parkWaypoint) {
    action = {
      displayName:"Remove Parking",
      dispatch:() => {
        ApiUtils.editRxDBObj(curUserObj, obj._id,
          {parkWaypoint:undefined});
        //cA_History("back")
      },
      //imageSrc:"../../img/wayRemove.png",  // TODO: Proper image
      iconName:"car",
      iconCornerName:"minus",
    };
  }
  return action;
}

// Display page to let user select Waypoint and we will
// set obj.parkWaypoint to what the user selected.
const selectParkWaypoint = (obj, cA_History) => {
  let action = undefined;
  if (obj.edit) {
    // Select the Waypoint that will be the Adventure's parkWaypoint.
    // I.e. replace the current obj.parkWaypoint value.
    const title = obj.parkWaypoint ?
      "Change Parking" : "Select Parking";
    const imageSrc = obj.parkWaypoint ?
      "../../img/way.png" :
      "../../img/wayReplace.png";

    const url = "/selectObj?task=selectParkWaypoint"+
      "&objId="+obj._id+
      "&selectCn=ways"+
      "&showOnly=viewable"+
      "&multiselect=false"+
      "&title="+title;
    action = {
      displayName:title,
      dispatch:() => {
        //cA_History("replace", url);
        cA_History("push", url);
      },
      //;imageSrc,  // TODO: Proper image
      iconName:"car",
      iconCornerName:"plus",
    };
  }
  return action;
}

// Display page to let user edit the obj.parkWaypoint object.
const editParkWaypoint = (obj, curUserObj, cA_History, cA_GetObj, cache) => {
  const parkWaypointObj = obj.parkWaypoint ?
    ObjUtils.get(curUserObj, obj.parkWaypoint, undefined, cache, cA_GetObj) :
    undefined;
  log.trace("parkWaypointObj: ", parkWaypointObj);
  let action = undefined;
  if (parkWaypointObj && parkWaypointObj.edit) {
    // Edit the Waypoint that is the Adventure's parkWaypoint.
    // obj.parkWaypoint = ways_22r3sksdfklsdf_sdsdf
    const url = "/obj?objId="+obj.parkWaypoint+
      "&parentId="+obj._id+
      "&parentObjPropName=parkWaypoint"+
      "&propDisplayName=Parking"+
      "&title=Edit "+obj.displayName+" Parking";
    action = {
      displayName:"Edit Parking",
      dispatch:() => {
        //cA_History("replace", url);
        cA_History("push", url);
      },
      //imageSrc:"../../img/wayReplace.png",
      //imageSrc:"../../img/way.png",  // TODO: Proper image
      iconName:"car",
      //iconCornerName:"plus",
    };
  }
  log.trace("action: ", action);
  return action;
}

const viewObj = (obj) => {
  const objType = Obj.DISPLAY_NAME(obj.cn);
  const action = {
    displayName:"View "+objType,
    iconName:"map outline",
    url:"/obj?objId="+obj._id,
  };
  return action;
}

const directions = (obj, cA_History) => {
  let action = undefined;
  const  {locationPoint} = obj;
  if (locationPoint) {
    // Show Google Map Directions to obj's locationPoint.
    const coordinates = locationPoint ?
      locationPoint.coordinates : null;
    const lat = coordinates && coordinates.length >=2 ?
      coordinates[1] : null;
    const lng = coordinates && coordinates.length >=1 ?
      coordinates[0] : null;
    if (lat !== null && lng !== null) {
      action = {
        displayName:"Directions",
        dispatch:()=>ActionUtils.showDirections(lat, lng, cA_History),
        iconName:"car",
      };
    }
  }
  return action;
}

// Let curUser select Users and then we will add them
// to user.userIds.
// I.e. these are the users who can view the passed in
// user specified by the passed in user parameter.
/*
const selectViewers = (user, curUser) => {
  log.trace("addUserIds(), user: ", user);

  const selectType = Obj.DISPLAY_NAME(USERS);
  const selectTypes = Obj.DISPLAY_NAME_PLURAL(USERS);
  const url = "/selectObj?task=add"+
    "&objId="+user._id+
    "&direction=toObj"+
    "&objPropName=userIds"+
    "&selectCn="+USERS+
    "&showOnly=viewable"+
    "&multiselect=true";
  const imageSrc = "../../img/navbar/user.png";
  const action = {
    displayName:"Select Viewers",
    imageSrc,
    url,
  };
  return action;
}
*/
// Display a page showing the users in the passed in
// user.userIds list.
// I.e. these are the users who can view the passed in
// user's profile.
const viewUsers = (user, curUserObj) => {
  if (!user || !user.viewUsers || !curUserObj/* ||
      !user.userIds ||
      !Array.isArray(user.userIds) ||
      user.userIds.length < 1*/) {
    return undefined;
  }

  const title = curUserObj._id == user._id ?
    "Your Inner Circle" :
    user.displayName+"'s Inner Circle";

  const url = "/anys?"+
    "objId="+user._id+
    "&curUserId="+curUserObj._id+
    "&objPropName=userIds"+
    "&collectionName=users"+
    "&title="+title;
    //"&selectCn="+USERS+
    //"&showOnly=viewable"+
    //"&multiselect=true";
  // Won't work because image is white on white background
  // unless you set the img element CSS "filter:invert(100%);"
  //const imageSrc = "../../img/navbar/users.png";
  const action = {
    displayName:"View Circle",
    //imageSrc,
    iconName:"users",
    url,
  };
  return action;
}

// Display a page showing the Users in the passed in
// user.guides list.
// I.e. these are the Users who can edit a User's favorites
// and see his/her location.
const viewGuides = (user, curUserObj) => {
  if (!user || !user.viewGuides || !curUserObj ||
      !user.guides || !Array.isArray(user.guides) ||
      user.guides.length < 1) {
    return undefined;
  }

  const title = curUserObj._id == user._id ?
    "Your Guides" :
    user.displayName+"'s Guides:";

  //const url = "/objs?"+
  const url = "/anys?"+
    "objId="+user._id+
    "&curUserId="+curUserObj._id+
    "&objPropName=guides"+
    "&collectionName=users"+
    "&title="+title;
  // Won't work because image is white on white background
  // unless you set the img element CSS "filter:invert(100%);"
  //const imageSrc = "../../img/navbar/users.png";
  const action = {
    displayName:"View Guides",
    //imageSrc,
    iconName:"map signs",
    url,
  };
  return action;
}

/**
 * Display a page showing followers, (i.e. Users), who have
 * the passed in Guide as one of their guides.
 */ 
const viewFollowers = (guide, curUserObj) => {
  if (!guide || !guide.viewFollowers || !curUserObj) {
    return undefined;
  }

  const title = curUserObj._id == guide._id ?
    "Your Followers" :
    guide.displayName+"'s Followers:";

  //const url = "/objs?"+
  const url = "/anys?"+
    "objId="+guide._id+
    "&curUserId="+curUserObj._id+
    "&objPropName=followers"+  // NOTE: No such prop in reality.
    "&collectionName=users"+
    "&title="+title;
  // Won't work because image is white on white background
  // unless you set the img element CSS "filter:invert(100%);"
  //const imageSrc = "../../img/navbar/users.png";
  const action = {
    displayName:"View Followers",
    //imageSrc,
    iconName:"users",
    url,
  };
  return action;
}

// Add passed in user._id to curUser.userIds.
const addToCircle = (user, curUser) => {
  const parentType = Obj.DISPLAY_NAME(USERS);
  if (!curUser || !curUser.visibility ||
      curUser.visibility == "isPublic") {
    // No property we can set.
    return undefined;
  }
  if (curUser._id == user._id) {
    // A user cannot add themselves as a viewer of themselves.
    return undefined;
  }

  // Allow curUser to add admins as viewers even though it
  // is not needed?  Makes them think they have more control?
  /*
  if (user.hasAdminPriv && user.hasAdminPriv()) {
    // This user can see all users anyway.
    return undefined;
  }
  */

  if (curUser.userIds &&
      curUser.userIds.indexOf(user._id) >= 0) {
    // Passed in user is already in userIds, so we can't add him/her.
    return undefined;
  }

  // Shallow copy of userIds array.
  const userIds = curUser.userIds ? curUser.userIds.slice(0) : [];
  userIds.push(user._id);
  const newProps = {userIds};
  const action = {
    displayName:"Add To Circle",
    dispatch:() => ApiUtils.editRxDBObj(curUser,
      curUser._id, newProps),
    //iconName:"user outline",
    iconName:"user plus",
  };

  return action;
}

// Remove userToRemove from fromUser.usersIds array.
const removeFromCircle = (userToRemove, fromUser/*, orderedObjIndex*/) => {
  if (!fromUser.edit) {
    return undefined;
  }

  const parentType = Obj.DISPLAY_NAME(USERS);
  if (!fromUser || !fromUser.userIds ||
      !fromUser.visibility ||
      fromUser.visibility == "isPublic") {
    // No property we can set.
    return undefined;
  }
  if (fromUser.userIds.indexOf(userToRemove._id) < 0) {
    // Passed in userToRemove is not in userIds, so we can't
    // remove it.
    return undefined;
  }

  // Shallow copy of userIds array.
  const userIds = fromUser.userIds.slice(0);
  // Remove all matching userIds.  Should only be one.
  _.pull(userIds, userToRemove._id);
  //children.splice(orderedObjIndex, 1);  // Remove child at specified index.
  const newProps = {userIds};
  const action = {
    displayName:"Remove From Circle",
    dispatch:() => ApiUtils.editRxDBObj(fromUser,
      fromUser._id, newProps),
    //iconName:"user outline",
    iconName:"user delete",
  };

  return action;
}

// Remove userToRemove from fromUser.guides array.
/*
const removeGuide = (userToRemove, fromUser) => {
  if (!fromUser.edit) {
    return undefined;
  }

  const parentType = Obj.DISPLAY_NAME(USERS);
  if (!fromUser || !fromUser.guides) {
    // No property we can set.
    return undefined;
  }
  if (fromUser.guides.indexOf(userToRemove._id) < 0) {
    // Passed in userToRemove is not in userIds, so we can't
    // remove it.
    return undefined;
  }

  // Shallow copy of guides array.
  const guides = fromUser.guides.slice(0);
  // Remove all matching userIds.  Should only be one.
  _.pull(guides, userToRemove._id);
  const newProps = {guides};
  log.trace("Edited guides:", guides);
  const action = {
    displayName:"Remove From Guides",
    dispatch:() => ApiUtils.editRxDBObj(fromUser,
      fromUser._id, newProps),
    //iconName:"user outline",
    iconName:"map signs",
    iconCornerName:"minus",
  };

  return action;
}
*/

// Let user select users that will be added to
// obj.userIds.
// Creates and returns an array with two actions.
// obj parameter is the User object whose userIds
// will be modified.
const addSelectedUsersToUserIds = (obj, curUserObj) => {
  if (!obj.edit && !curUserObj.hasAdminPriv()) {
    return undefined;
  }

  const actions = [];

  // Add a single User
  let url = "/selectObj?task=add"+
    "&objId="+obj._id+
    "&direction=toObj"+
    "&objPropName=userIds"+
    "&selectCn=users"+
    "&showOnly=viewable"+
    "&preventDuplicates=true"+
    "&multiselect=false";
  actions.push({
    displayName:"Add User",
    imageSrc:"../../img/navbar/user.png",
    url,
  });

  // Add multiple Users
  url = "/selectObj?task=add"+
    "&objId="+obj._id+
    "&direction=toObj"+
    "&objPropName=userIds"+
    "&selectCn=users"+
    "&showOnly=viewable"+
    "&preventDuplicates=true"+
    "&multiselect=true";
  actions.push({
    displayName:"Add Users",
    imageSrc:"../../img/navbar/users.png",
    url,
  });

  return actions;
}

// Let user select objects and then we will add them
// to obj.children.
// Creates and returns an array with two actions.
const addSelectedToChildrenOfObj = (obj) => {
  log.trace("addSelectedToChildrenOfObj(), obj: ", obj);
  const actions = [];

  const selectType = Obj.DISPLAY_NAME(obj.childrenCn);
  const selectTypes = Obj.DISPLAY_NAME_PLURAL(obj.childrenCn);
  let url = "/selectObj?task=add"+
    "&objId="+obj._id+
    "&direction=toObj"+
    "&objPropName=children"+
    "&selectCn="+obj.childrenCn+
    "&showOnly=viewable"+
    "&multiselect=false";
  let imageSrc = obj.childrenCn == WAYS ?
    "../../img/addWay.png" :
    "../../img/addAct.png";
  actions.push({
    displayName:"Add "+selectType,
    imageSrc,
    url,
  });

  // Add multiple Ways to an Act.
  // Add multiple Acts to an Itn.
  url = "/selectObj?task=add"+
    "&objId="+obj._id+
    "&direction=toObj"+
    "&objPropName=children"+
    "&selectCn="+obj.childrenCn+
    "&showOnly=viewable"+
    "&multiselect=true";
  imageSrc = obj.childrenCn == WAYS ?
    "../../img/addWays.png" :
    "../../img/addActs.png";
  actions.push({
    displayName:"Add "+selectTypes,
    imageSrc,
    url,
  });

  return actions;
}

/**
 *
 * NOTE: This can add objects to any property.  For example:
 * "children", "favorites", "recommendations".
 *
 * TODO: Change name of this function to addToPropOfSelectedObj()
 * or perhaps addToArrayInSelectedObj().
 *
 * Let user select object(s) and then we will add obj
 * to the specified children/favorites/recommendations property.
 *
 * @param objPropName The name of the property in the object
 * the user will select, (Act.children, User.favorites) into
 * which obj._id will be pushed.  "children", "favorites",
 * "recommendations".
 *
 * @param selectCn The collectionName of the object the user
 * will be selecting.  ("acts", "itns", "users").
 *
 * Creates and returns an array with two actions.
 */
const addToChildrenOfSelectedObj = (obj, objPropName = "children",
  selectCn = obj.parentCn) => {
  const actions = [];

  const parentType = Obj.DISPLAY_NAME(selectCn);
  const parentTypes = Obj.DISPLAY_NAME_PLURAL(selectCn);

  let showOnly = "editable";
  let displayNameSingle;
  let displayNamePlural;
  switch(objPropName) {
    case "children":
      displayNameSingle = "Add To "+parentType;
      displayNamePlural = "Add To "+parentTypes;
      break;
    case "favorites":
      displayNameSingle = "Add To "+parentType+" Favorites";
      displayNamePlural = "Add To "+parentTypes+" Favorites";
      break;
    case "recommendations":
      displayNameSingle = "Recommend To "+parentType;
      displayNamePlural = "Recommend To "+parentTypes;
      // Show only followers of curUser or all users if
      // curUser is an admin.
      showOnly = "followers";
      break;
    default:
      log.bug("In addToChildrenOfSelectedObj(), unhandled objPropName: ",
        objPropName);
  }

  let iconName = undefined;
  let iconCornerName = undefined;
  let imageSrc = undefined;
  if (objPropName == "children") {
    // Add to one selected object's children array.
    imageSrc = obj.cn == WAYS ?
      //"../../img/addWay.png" :  // TODO: Proper image
      "../../img/addAct.png" :
      //"../../img/itnActs.png";
      //"../../img/addAct.png";
      "../../img/itnOverview.png";
  }
  else if (objPropName == "favorites") {
    iconName = "heart";
    iconCornerName = "plus";
  }
  else if (objPropName == "recommendations") {
    iconName = "star";
    iconCornerName = "plus";
  }

  // Add this Way/Act to one Act/Itn.
  // User needs to select one Act/Itn.
  let url = "/selectObj?task=add"+
    "&objId="+obj._id+
    "&direction=toSelected"+
    "&selectCn="+selectCn+
    "&objPropName="+objPropName+
    "&showOnly="+showOnly+
    "&multiselect=false";
  actions.push({
    displayName:displayNameSingle,
    imageSrc,
    iconName,
    iconCornerName,
    url,
  });

  if (objPropName == "children") {
    // Add to multiple selected objects' children array.
    imageSrc = obj.cn == WAYS ?
      //"../../img/addWays.png" :  // TODO: Proper image
      //"../../img/itnActs.png";
      "../../img/addActs.png" :
      "../../img/itnOverviewMulti.png";
  }

  // Add this Way/Act to multiple Acts/Itns.
  // User can select multiple Acts/Itns.
  url = "/selectObj?task=add"+
    "&objId="+obj._id+
    "&direction=toSelected"+
    "&selectCn="+selectCn+
    "&objPropName="+objPropName+
    "&showOnly="+showOnly+
    "&multiselect=true";
  actions.push({
    displayName:displayNamePlural,
    // TODO: Need proper icon for this.
    imageSrc,
    iconName,
    iconCornerName,
    url,
  });

  return actions;
}

// Remove obj parameter from parentObj.children array.
const removeFromParentsChildren = (obj, parentObj, curUserObj,
  orderedObjIndex) => {
  const parentType = Obj.DISPLAY_NAME(parentObj.cn);
  const imageSrc = parentObj.cn == ACTS ?
    "../../img/removeAct.png" :  // TODO: Proper image
    "../../img/itnRemoveAct.png";
  // Shallow copy of children array.
  const children = parentObj.children.slice(0);
  //_.pull(children, obj._id);  // Removes all children with matching IDs.
  children.splice(orderedObjIndex, 1);  // Remove child at specified index.
  const parentProps = {children};
  log.trace("Edited children:", children);
  // Remove Way/Act from Act/Itn that contains it.
  const action = {
    displayName:"Remove From "+parentType,
    dispatch:() => ApiUtils.editRxDBObj(curUserObj,
      parentObj._id, parentProps),
    imageSrc,
  };
  return action;
}

// Display a page showing the objects in the passed in
// user.favorites list.
const viewFavorites = (user, curUserObj) => {
  if (!user || !user.viewFavorites || !curUserObj ||
    !user.favorites || !Array.isArray(user.favorites) ||
    user.favorites.length < 1) {
    return undefined;
  }

  const title = curUserObj._id == user._id ?
    "Your Favorites" :
    user.displayName+"'s Favorites";

  const url = "/anys?"+
    "objId="+user._id+
    "&curUserId="+curUserObj._id+
    "&objPropName=favorites"+
    "&title="+title;
  const action = {
    displayName:"View Favorites",
    //imageSrc,
    iconName:"heart outline",
    url,
  };
  return action;
}

// Display a page showing the objects in the passed in
// user.recommendations list.
const viewRecommendations = (user, curUserObj) => {
  if (!user || !user.viewRecommendations || !curUserObj ||
    !user.recommendations || !Array.isArray(user.recommendations) ||
    user.recommendations.length < 1) {
    return undefined;
  }

  const title = curUserObj._id == user._id ?
    "Your Recommendations" :
    user.displayName+"'s Recommendations";

  const url = "/anys?"+
    "objId="+user._id+
    "&curUserId="+curUserObj._id+
    "&objPropName=recommendations"+
    "&title="+title;
  const action = {
    displayName:"View Recommendations",
    //imageSrc,
    iconName:"star outline",
    url,
  };
  return action;
}

// Add obj parameter to user.favorites array.
const addToFavorites = (obj, user, curUserObj, cA_Confirmation) => {
  if (!user || !obj || !obj._id || !curUserObj ||
      (user.favorites && !Array.isArray(user.favorites))) {
    log.bug("In addToFavorites(), bad input params.");
    debugger;
    return undefined;
  }
  if (user.favorites && (user.favorites.indexOf(obj._id) >= 0)) {
    // obj is already in user.favorites list, so the
    // addToFavorites action is not possible.
    return undefined;
  }
  if (user._id == obj._id) {
    // User can't add themselves as a favorite.
    return undefined;
  }
  if (!obj.favorite) {
    // Adding to favorites action was redacted by the object.
    return;
  }

  const dispatch = () => ApiUtils.possiblyAddToFavorites(
    obj, user, curUserObj, cA_Confirmation);
  //const imageSrc = "../../img/addFavorite.png";
  const action = {
    displayName:"Add To Favorites",
    dispatch,
    //imageSrc,
    iconName:"heart outline", //"heart", "star", "star outline",
    iconCornerName:"plus",
  };
  return action;
}

// Add obj parameter to user.recommendations array.
/*
const addToRecommendations = (obj, user, curUserObj, cA_Confirmation) => {
  if (!user || !obj || !obj._id || !curUserObj ||
      (user.recommendations && !Array.isArray(user.recommendations))) {
    log.bug("In addToRecommendations(), bad input params.");
    debugger;
    return undefined;
  }
  if (!obj.recommend) {
    // obj is not recommendable, either because its statusAttribute
    // is not yet "approved", or because curUserObj is not an admin
    // or a guide.
    return;
  }
  if (user.recommendations && (user.recommendations.indexOf(obj._id) >= 0)) {
    // obj is already in user.recommendations list, so the
    // addToRecommendations action is not possible.
    return undefined;
  }
  if (user._id == obj._id) {
    // User can't add themselves as a recommendation.
    return undefined;
  }

  const dispatch = () => ApiUtils.possiblyAddToRecommendations(
    obj, user, curUserObj, cA_Confirmation);
  //const imageSrc = "../../img/addFavorite.png";
  const action = {
    displayName:"Recommend",
    dispatch,
    //imageSrc,
    iconName:"star outline", //"heart", "star", "heart outline",
    iconCornerName:"plus",
  };
  return action;
}
*/

/**
 * Add the User specified by the guide parameter to
 * user.guides array.
 */
const addToGuides = (guide, user, curUserObj, cA_Confirmation) => {
  if (!user || !guide || !guide._id || !curUserObj) {
    log.bug("In addToGuides(), bad input params.");
    debugger;
    return undefined;
  }
  if (!guide.hasRole(User.Roles.GUIDE)) {
    // The passed in guide parameter is not actually a guide.
    return undefined;
  }
  if (user.guides && (Array.isArray(user.guides) &&
      user.guides.indexOf(guide._id) >= 0)) {
    // guide is already in user.guides list, so the
    // addToGuides action is not possible.
    return undefined;
  }
  if (user._id == guide._id) {
    // User can't be in their own list of guides.
    return undefined;
  }

  const dispatch = () => ApiUtils.possiblyAddToGuides(
    guide, user, curUserObj, cA_Confirmation);
  //const imageSrc = "../../img/addFavorite.png";
  const action = {
    displayName:"Add To Guides",
    dispatch,
    //imageSrc,
    iconName:"map signs",
    iconCornerName:"plus",
  };
  return action;
}


// Remove obj parameter from user.favorites array.
const removeFromFavorites = (obj, user, curUserObj, cA_Confirmation) => {
  if (!user || !user.favorites || !obj || !obj._id ||
    !Array.isArray(user.favorites) ||
    user.favorites.indexOf(obj._id) < 0) {
    // obj is not in user.favorites list, so the
    // removeFromFavorites action is not possible.
    return undefined;
  }

  const dispatch = () => ApiUtils.possiblyRemoveFromFavorites(
    obj, user, curUserObj, cA_Confirmation);
  //const imageSrc = "../../img/removeFavorite.png";
  const action = {
    displayName:"Remove From Favorites",
    dispatch,
    //imageSrc,
    iconName:"heart outline", //"heart", "star", "star outline",
    iconCornerName:"minus",
  };
  return action;
}

// Remove obj parameter from user.recommendations array.
const removeFromRecommendations = (obj, user, curUserObj, cA_Confirmation) => {
  if (!user || !user.recommendations || !obj || !obj._id ||
    !Array.isArray(user.recommendations) ||
    user.recommendations.indexOf(obj._id) < 0) {
    // obj is not in user.recommendations list, so the
    // removeFromRecommendations action is not possible.
    return undefined;
  }

  const dispatch = () => ApiUtils.possiblyRemoveFromRecommendations(
    obj, user, curUserObj, cA_Confirmation);
  //const imageSrc = "../../img/removeFavorite.png";
  const action = {
    displayName:"Remove Recommendation",
    dispatch,
    //imageSrc,
    iconName:"star outline", //"heart", "star", "heart outline",
    iconCornerName:"minus",
  };
  return action;
}

// Remove guide from user.guides array.
const removeFromGuides = (guide, user, curUserObj, cA_Confirmation) => {
  if (!user || !user.guides || !guide || !guide._id ||
    !Array.isArray(user.guides) ||
    user.guides.indexOf(guide._id) < 0) {
    // guide is not in user.guides list, so the
    // removeFromGuides action is not possible.
    return undefined;
  }

  const dispatch = () => ApiUtils.possiblyRemoveFromGuides(
    guide, user, curUserObj, cA_Confirmation);
  //const imageSrc = "../../img/removeFavorite.png";
  const action = {
    displayName:"Remove From Guides",
    dispatch,
    //imageSrc,
    iconName:"map signs",
    iconCornerName:"minus",
  };
  return action;
}

// Let curUser select user(s) and then we will add the passed
// in obj parameter to their favorities property.
// Creates and returns an array with two actions.
/*
const addToFavoritesOfSelectedUser = (obj) => {
  const actions = [];

  const parentType = Obj.DISPLAY_NAME(USERS);
  const parentTypes = Obj.DISPLAY_NAME_PLURAL(USERS);

  // Add to one selected object's children array.
  let imageSrc = obj.cn == WAYS ?
    //"../../img/addWay.png" :  // TODO: Proper image
    "../../img/addAct.png" :
    //"../../img/itnActs.png";
    //"../../img/addAct.png";
    "../../img/itnOverview.png";
  // Add this Way/Act to one Act/Itn.
  // User needs to select one Act/Itn.
  let url = "/selectObj?task=add"+
    "&objId="+obj._id+
    "&direction=toSelected"+
    "&selectCn="+obj.parentCn+
    "&objPropName=children"+
    "&showOnly=editable"+
    "&multiselect=false";
  actions.push({
    displayName:"Add To "+parentType,
    imageSrc,
    url,
  });

  // Add to multiple selected objects' children array.
  imageSrc = obj.cn == WAYS ?
    //"../../img/addWays.png" :  // TODO: Proper image
    //"../../img/itnActs.png";
    "../../img/addActs.png" :
    "../../img/itnOverviewMulti.png";
  // Add this Way/Act to multiple Acts/Itns.
  // User can select multiple Acts/Itns.
  url = "/selectObj?task=add"+
    "&objId="+obj._id+
    "&direction=toSelected"+
    "&selectCn="+obj.parentCn+
    "&objPropName=children"+
    "&showOnly=editable"+
    "&multiselect=true";
  actions.push({
    displayName:"Add To "+parentTypes,
    // TODO: Need proper icon for this.
    imageSrc,
    url,
  });

  return actions;
}
*/

const uploadImage = (obj) => {
  if (!obj.edit) {
    return;
  }
  const docName = obj.cn.slice(0, -1);
  const filename = docName+"ImageSrc_"+obj._id;
  const url = "/imageUpload?objId="+obj._id+
    "&propName=imageSrc"+
    //"&aspect=1"+  // Do not define.  I.e. allow any.
    "&filename="+filename;
  const action = {
    displayName:"Upload Image",
    iconName:"image", //"camera",
    iconCornerName:"plus",
    url,
  };
  return action;
}

const uploadAvatar = (obj) => {
  if (!obj.edit) {
    return;
  }
  const docName = obj.cn.slice(0, -1);
  const filename = docName+"AvatarSrc_"+obj._id;
  const url = "/imageUpload?objId="+obj._id+
    "&propName=avatarSrc"+
    "&aspect=1"+
    "&filename="+filename;
  const action = {
    displayName:"Upload Avatar Image",
    iconName:"image", //"camera",
    iconCornerName:"plus",
    url,
  };
  return action;
}

const removeImage = (obj, curUserObj, cA_Confirmation) => {
  let action = undefined;
  if (obj.edit && obj.hasUserSetImageSrc()) {
    const removeImage = async () => {
      const firebaseUrl = obj.hasUserSetImageSrc() ?
        obj.imageSrc : null;
      await ApiUtils.editRxDBObj(curUserObj, obj._id,
        {imageSrc:undefined});
      if (firebaseUrl) {
        ImageUtils.possiblyDeleteFromFirebase(firebaseUrl);
      }
    };

    const objType = Obj.DISPLAY_NAME(obj.cn);
    //const parentTypes = Obj.DISPLAY_NAME(obj.parentCn);
    const title = "Remove Image?";
    const message1 = "Are you sure you want to remove the image from the "+objType+".";
    const message2 = undefined;
    const okAction = removeImage;
    const okLabel = "Remove Image";
    const dispatch = () => cA_Confirmation(title, message1, message2,
      okAction, okLabel);

    action = {
      displayName:"Remove Image",
      dispatch,
      iconName:"image",
      iconCornerName:"minus",
    };
  }

  return action;
}

const removeAvatar = (obj, curUserObj, cA_Confirmation) => {
  let action = undefined;
  if (obj.edit && obj.hasUserSetAvatarSrc()) {
    const removeAvatar = async () => {
      const firebaseUrl = obj.hasUserSetAvatarSrc() ?
        obj.avatarSrc : null;
      await ApiUtils.editRxDBObj(curUserObj, obj._id,
        {avatarSrc:undefined});
      if (firebaseUrl) {
        ImageUtils.possiblyDeleteFromFirebase(firebaseUrl);
      }
    };

    const objType = Obj.DISPLAY_NAME(obj.cn);
    //const parentTypes = Obj.DISPLAY_NAME(obj.parentCn);
    const title = "Remove Avatar Image?";
    const message1 = "Are you sure you want to remove the avatar image from the "+objType+".";
    const message2 = undefined;
    const okAction = removeAvatar;
    const okLabel = "Remove Avatar Image";
    const dispatch = () => cA_Confirmation(title, message1, message2,
      okAction, okLabel);

    action = {
      displayName:"Remove Avatar Image",
      dispatch,
      iconName:"image"
    };
  }

  return action;
}

const toggleTestData = (obj, curUserObj, cA_Confirmation) => {
  let action = undefined;
  if (curUserObj && curUserObj.hasAdminPriv &&
    curUserObj.hasAdminPriv()) {
    const toggleTestData = async () => {
      await ApiUtils.editRxDBObj(curUserObj, obj._id,
        {isTestData:!obj.isTestData});
    };

    const objType = Obj.DISPLAY_NAME(obj.cn);
    //const parentTypes = Obj.DISPLAY_NAME(obj.parentCn);
    const title = obj.isTestData ?
      "Set Not Test Data?" : "Set Is Test Data?";
    const message1 = obj.isTestData ?
      "Set this "+objType+" to NOT be test data." :
      "Set this "+objType+" to be test data." ;
    const message2 = obj.isTestData ?
      '"Real" users will be able to see it.  '+
      '"Test" users will NOT see it.  '+
      'The current user, i.e. you, will no longer see it.' :
      '"Real" users will NOT be able to see it.  '+
      'Only "Test" users will see it.  '+
      'The current user, i.e. you, will no longer see it.';
    const okAction = toggleTestData;
    const okLabel = obj.isTestData ? "Set Not Test Data" :
      "Set Is Test Data";
    //const okButtonProps = {positive:false, negative:false,
    //  primary:true, inverted:false};
    const okButtonProps = undefined;
    const iconName = "bug";
    const iconCornerName = obj.isTestData ? "minus" : "plus";
    const dispatch = () => cA_Confirmation(title, message1, message2,
      okAction, okLabel, undefined, undefined, 0,
      undefined, undefined, undefined, undefined,
      okButtonProps, iconName);

    action = {
      displayName:obj.isTestData ? "Set Not Test Data" :
        "Set Is Test Data",
      dispatch,
      iconName,
      iconCornerName,
    };
  }

  return action;
}

const setPending = (obj, curUserObj, cA_Confirmation) => {
  let action = undefined;
  if (curUserObj && curUserObj.hasAdminPriv &&
    curUserObj.hasAdminPriv() &&
    obj.statusAttribute != PENDING) {
    const setPending = async () => {
      await ApiUtils.editRxDBObj(curUserObj, obj._id,
        {statusAttribute:PENDING});
    };

    const objType = Obj.DISPLAY_NAME(obj.cn);
    const title = "Set Pending?";
    const message1 = 'Set this '+objType+'\'s status to "Pending".';
    const message2 = undefined;
    const okAction = setPending;
    const okLabel = "Set Pending";
    const okButtonProps = {positive:false, negative:false, primary:true,
      inverted:false};
    const iconName = "hand paper";//"thumbs down";
    const dispatch = () => cA_Confirmation(title, message1, message2,
      okAction, okLabel, undefined, undefined, 0,
      undefined, undefined, undefined, undefined,
      okButtonProps, iconName);

    action = {
      displayName:"Set Pending",
      dispatch,
      iconName,
    };
  }

  return action;
}

const approve = (obj, curUserObj, cA_Confirmation) => {
  let action = undefined;
  if (curUserObj && curUserObj.hasAdminPriv &&
    curUserObj.hasAdminPriv() &&
    obj.statusAttribute != APPROVED) {
    const approve = async () => {
      await ApiUtils.editRxDBObj(curUserObj, obj._id,
        {statusAttribute:APPROVED});
    };

    const objType = Obj.DISPLAY_NAME(obj.cn);
    const title = "Approve?";
    const message1 = "Approve this "+objType+".";
    const message2 = obj.statusAttribute == FLAGGED ?
      "Note:  Some user has previously flagged this!" :
      undefined;
    const okAction = approve;
    const okLabel = "Approve";
    const okButtonProps = {positive:true, negative:false};
    const iconName = "thumbs up";
    const dispatch = () => cA_Confirmation(title, message1, message2,
      okAction, okLabel, undefined, undefined, 0,
      undefined, undefined, undefined, undefined,
      okButtonProps, iconName);

    action = {
      displayName:"Approve",
      dispatch,
      iconName,
    };
  }

  return action;
}

const flag = (obj, curUserObj, cA_Confirmation) => {
  if (!curUserObj || curUserObj.statusAttribute != APPROVED ||
      obj.statusAttribute == FLAGGED || !obj.flag) {
    return;
  }

  let action = undefined;
  const flag = async () => {
    await ApiUtils.editRxDBObj(curUserObj, obj._id,
      {statusAttribute:FLAGGED});
  };

  const objType = Obj.DISPLAY_NAME(obj.cn);
  const title = "Flag As Offensive?";
  const message1 = "Flag this "+objType+
    " for review by a Jasiri administrator.";
  const message2 = "This "+objType+
    " will not be publicly visible until it has been reviewed.";
  const okAction = flag;
  const okLabel = "Flag";
  //const okButtonProps = {positive:true, negative:false};
  const iconName = "warning sign";  // "ban" is circle no symbol
  const dispatch = () => cA_Confirmation(title, message1, message2,
    okAction, okLabel, undefined, undefined, 0,
    undefined, undefined, undefined, undefined,
    undefined, iconName);

  action = {
    displayName:"Flag As Offensive",
    dispatch,
    iconName,
  };

  return action;
}

const createLink = (obj, curUserObj, cA_History) => {
  const dispatch = ()=>{
    // Create a url object and add it to the obj's
    // urls prop array.
    const propIndex = ObjUtils.createUrlProp(curUserObj, obj._id);
    if (propIndex < 0) {
      log.bug("ObjUtils.createUrlProp(), Error creating new Url prop.");
      return;
    }

    // Now display the urlProp editing page.
    const url = "/urlProp?objId="+obj._id+
      //"&collectionName="+obj.cn+
      "&propName=urls"+
      "&propIndex="+propIndex;
    cA_History("push", url);
  };
  const action = {
    displayName:"Add Link",
    //imageSrc:"../../img/link.png",
    iconName:"linkify",
    iconCornerName:"plus",
    dispatch,
  };
  return action;
}

const deleteObj = (obj, curUserObj, noDrillDown, cA_History,
  cA_Confirmation, cA_UsageConfirmation) => {
  const deleteObjAndPossiblyGoBack = async () => {
    await ApiUtils.removeAllOccurrencesOfObjIdFromAllObjsInAllCollections(
      curUserObj, obj._id);
    await ApiUtils.deleteRxDBObj(curUserObj, obj);
    if (noDrillDown) {
      // The View object panel is displayed,
      // and we deleted the object, so navigate back.
      cA_History("back");
    }
  };
  const orphanObjAndPossiblyGoBack = async () => {
    await ApiUtils.orphanObj(curUserObj, obj);
    if (noDrillDown) {
      // The View object panel is displayed,
      // and we deleted the object, so navigate back.
      cA_History("back");
    }
  };
  const objType = Obj.DISPLAY_NAME(obj.cn);
  const parentTypes = obj.parentCn ?
    Obj.DISPLAY_NAME(obj.parentCn)+"." : "objects.";
  const title = "No Changes Made";
  const message1 = "We did not delete the "+objType;
  const message2 = "No changes were made to any "+parentTypes;
  const okAction = null;
  const okLabel = "Close";
  const cancelAction = () => cA_Confirmation(title, message1, message2,
    okAction, okLabel);
  const dispatch = () => cA_UsageConfirmation(curUserObj, obj,
    deleteObjAndPossiblyGoBack, orphanObjAndPossiblyGoBack, cancelAction);

  const displayName = "Delete "+objType;
  const action = {
    displayName,
    dispatch,
    iconName:"trash alternate outline"
  };
  return action;
}

/*
const deleteUser = (user, curUserObj, noDrillDown, cA_History,
  cA_Confirmation, cA_UsageConfirmation) => {
  const displayName = "Delete "+User.DISPLAY_NAME;
  const deleteUserAndPossiblyGoBack = async () => {
    await ApiUtils.removeAllOccurrencesOfObjIdFromAllObjsInAllCollections(
      curUserObj, user._id);
    await ApiUtils.deleteRxDBUser(curUserObj, user);
    if (noDrillDown) {
      // The View User (UserPanel) is displayed,
      // and we deleted the User, so navigate back.
      cA_History("back");
    }
  };
  const noOrphanFunction = undefined;
  const title = "No Changes Made";
  const message1 = "We did not delete the "+User.DISPLAY_NAME;
  const message2 = undefined;
  const okAction = null;
  const okLabel = "Close";
  const cancelAction = () => cA_Confirmation(title, message1, message2,
    okAction, okLabel);
  const dispatch = () => cA_UsageConfirmation(curUserObj, user,
    deleteUserAndPossiblyGoBack, noOrphanFunction, cancelAction);

  const action = {
    displayName,
    dispatch,
    iconName:"trash alternate outline"
  };
  return action;
}
*/

/**
 * Calculate the location related actions for an object.
 * These are only available when the object is displayed
 * on a map.
 *
 * This function returns an array of two actions.
 */
const calcLocationActions = (setPoint, resetPoint, cA_SetLoader) => {
  const actions = [];

  if (setPoint) {
    actions.push({
      displayName:"Use Current Location",
      dispatch:()=>ActionUtils.setPointToCurrentLocation(setPoint,
        cA_SetLoader),
      //iconName:"compass",
      iconName:"location arrow",
    });
  }

  if (resetPoint) {
    actions.push({
      displayName:"Reset Location",
      dispatch:resetPoint,
      iconName:"repeat"
    });
  }

  return actions;
}

const viewMessages = (obj) => {
  if (obj.cn != USERS || !obj.viewMessages ||
      !obj.messages || !Array.isArray(obj.messages) ||
      obj.messages.length < 1) {
    return;
  }
  const url = "/viewMessages?userId="+obj._id;
  const action = {
    displayName:"View Messages",
    iconName:"inbox",
    url,
  };
  return action;
}

const sendMessage = (obj) => {
  log.trace("sendMessage obj: ", obj);
  if (obj.cn != USERS || !obj.sendMessage) {
    return;
  }

  const url = "/createMessage?userId="+obj._id+
    "&task=message";  // Or "connect"
  const action = {
    displayName:"Send Message",
    iconName:"mail outline",
    url,
  };
  return action;
}

const connect = (obj, curUserObj, cA_SetError) => {
  if (obj.cn != USERS) {
    return;
  }

  let action = undefined;
  if (obj.connect) {
    const url = "/createMessage?userId="+obj._id+
      "&task=connect";  // Or "connect"
    action = {
      displayName:"Connect",
      iconName:"connectdevelop",
      iconCornerName:"plus",
      url,
    };
  }
  else if (curUserObj && curUserObj._id &&
           !obj.isConnected(curUserObj._id) &&
           obj.connectionPending(curUserObj._id)) {
    const msg = obj.displayName+" has not yet responded to your "+
      "previous connection request.";
    const error = MyError.createSubmitError({msg});
    error.severity = MyError.INFO;
    log.trace("error: ", error);
    action = {
      displayName:"Connection Pending",
      iconName:"connectdevelop",
      dispatch:() => {error.time = Date.now(); log.trace(error.time); cA_SetError(error);},
    };
  }
  return action;
}

const acceptConnection = (obj, curUserObj) => {
  if (obj.cn != USERS || !obj.acceptConnection ||
      !curUserObj) {
    return;
  }
  const action = {
    displayName:"Accept Connection",
    iconName:"connectdevelop",
    iconCornerName:"plus",
    dispatch:() => ApiUtils.acceptConnection(curUserObj, curUserObj, obj),
  };
  return action;
}

const disconnect = (obj, curUserObj, cA_Confirmation) => {
  if (obj.cn != USERS || !obj.disconnect ||
      !curUserObj) {
    return;
  }
  const dispatch = () => ApiUtils.possiblyDeleteConnection(
    obj, curUserObj, cA_Confirmation);

  const action = {
    displayName:"Disconnect",
    iconName:"connectdevelop",
    iconCornerName:"minus",
    //dispatch:() => ApiUtils.deleteConnection(
    //  curUserObj, curUserObj._id, obj._id),
    dispatch,
  };
  return action;
}

const createObj = (collectionName, curUserObj, cA_History) => {
  if (!curUserObj || !curUserObj.canCreateObj()) {
    return;
  }
  const objType = Obj.DISPLAY_NAME(collectionName);
  const displayName = "Create "+objType;
  const dispatch = async () => {
    const defaultProps = Obj.DEFAULT_PROPS(collectionName);
    const newObj = Object.assign({}, defaultProps);
    delete newObj._id;
    let imageSrc = undefined;
    switch(collectionName) {
      case WAYS:
        imageSrc = await LocalDB.getNextWayImageSrc();
        break;
      case ACTS:
        imageSrc = await LocalDB.getNextActImageSrc();
        break;
      case ITNS:
        imageSrc = undefined;
        break;
      default:
        log.bug("In ObjActions.createObj(), "+
          "unhandled collectionName: ", collectionName);
        debugger;
        return;
    }
    newObj.imageSrc = imageSrc;
    if (curUserObj.isTestData) {
      newObj.isTestData = true;
    }
    newObj.statusAttribute = curUserObj.statusAttribute;
    newObj.displayName = "New "+objType;
    newObj.details = "Some details about this "+objType+"...";
    log.trace("curUserObj._id: ", curUserObj._id);
    const payload = await ApiUtils.createRxDBObj(
      curUserObj, newObj, collectionName);

    log.trace("payload.obj: ", payload.obj);
    if (payload.error) {
      log.todo("Display error: ", payload.error);
    }
    else {
      const {obj} = payload;
      // Change collectionName to the singular document name.
      // e.g. "ways" to "way".
      //const docName = collectionName.slice(0, -1);
      const url = "/obj?objId="+obj._id;
      log.trace("url: ", url);
      cA_History("push", url);
    }
  };

  let actionImageSrc = undefined;
  switch(collectionName) {
    case WAYS:
      actionImageSrc = "../../../img/way.png";
      break;
    case ACTS:
      actionImageSrc = "../../../img/act.png";
      break;
    case ITNS:
      actionImageSrc = "../../img/itnOverview.png";
      break;
    default:
      log.bug("In ObjActions.createObj(), "+
        "unhandled collectionName: ", collectionName);
      debugger;
  }
  const action = {
    displayName,
    //iconName:"connectdevelop",
    imageSrc:actionImageSrc,
    dispatch,
  };
  return action;
}

const createPropsToEdit = (collectionName, componentProps,
  handleChangeFilter, handleChangeSort) => {
  const propsToEdit = [];

  // First do Filter properties.
  let propToEdit = {};
  switch(collectionName) {
    case USERS:
      propToEdit = {
        //displayName:"Roles";
        propName:"roles",
        propType:"checkboxes",
        choices:[
          {label:"Root", value:"root"/*, onChange:()=>{}*/},
          {label:"Admin", value:"admin"},
          {label:"Guide", value:"guide"},
          {label:"Traveler", value:"traveler"}],
        propPath:"ui.filterAndSort."+collectionName+".filter.roles",
        onChange:(e, choice, propToEd) =>
          handleChangeFilter(e, choice, propToEd),
      };
      propsToEdit.push(propToEdit);
      //break;
      // Fall through
    case WAYS:
      // Fall through
    case ACTS:
      // Fall through
    case ITNS:
      propToEdit = {
        //displayName:"Status",
        propName:"statusAttribute",
        propType:"checkboxes",
        choices:[
          {label:"Pending", value:"pending"/*, onChange:()=>{}*/},
          {label:"Flagged", value:"flagged"},
          {label:"Approved", value:"approved"}],
        propPath:"ui.filterAndSort."+collectionName+".filter.statusAttribute",
        onChange:(e, choice, propToEd) =>
          handleChangeFilter(e, choice, propToEd),
      };
      propsToEdit.push(propToEdit);
      break;
    default:
      log.bug("In FilterAndSortButton.createPropsToEdit(), "+
        "unhandled collectionName: ", collectionName);
      debugger;
  }

  // Done with Filter properties, now do Sort properties.
  switch(collectionName) {
    case USERS:
      // Fall through
    case WAYS:
      // Fall through
    case ACTS:
      // Fall through
    case ITNS:
      propToEdit = {
        propName:"sort",
        choices:[
          {
            label:"Name", propName:"displayName",
            values:["asc", "desc"],
            iconNames:["sort alphabet down", "sort alphabet up"],
          },
          {
            label:"Created", propName:"created",
            values:["asc", "desc"],
            iconNames:["sort amount up", "sort amount down"],
          }
        ],
        propPath:"ui.filterAndSort."+collectionName+".sort",
      };
      break;
    default:
      log.bug("In FilterAndSortButton.createPropsToEdit(), "+
        "unhandled collectionName: ", collectionName);
      debugger;
  }
  if (propToEdit) {
    // Only one box is "selected", (i.e. you are sorting based on
    // that property, and its value toggles from "asc" to "desc as
    // the user clicks on it.
    //propToEdit.displayName = "Sort";
    propToEdit.propType = "togglingRadioBoxes";
    propToEdit.onChange = (e, choice, propToEdit) =>
      handleChangeSort(e, choice, propToEdit);
    propsToEdit.push(propToEdit);
  }

  return propsToEdit;
}

// NOTE: This really isn't an object action.  Maybe should go
// in a separate place?
const toggleFilterAndSortVisibility = (componentProps,
  handleChangeFilter, handleChangeSort) => {
  const {collectionName, cA_History, shellType, filterAndSort,
    cA_PropsEditor, cA_SetFilterAndSortVisibility} = componentProps;
  const {visible} = filterAndSort;

  let displayName;
  let dispatch;
  if (shellType == "shell-mobile") {
    displayName = "Filter And Sort";
    const dialogProps = {
      title:"Filter And Sort",
      headerIcon:"filter",
      okIcon:"filter",
    };
    const propsToEdit = createPropsToEdit(collectionName, componentProps,
      handleChangeFilter, handleChangeSort);
    dispatch = () => cA_PropsEditor(propsToEdit, dialogProps);
  }
  else {
    displayName = visible ? "Hide Filter And Sort" :
      "Show Filter And Sort";
    dispatch = () => cA_SetFilterAndSortVisibility(!visible);
  }

  const action = {
    displayName,
    iconName:"filter",
    imageSrc:"../../img/act.png",
    dispatch,
  };
  return action;
}


class ObjActions {

  /**
   * Calculate the actions for an object.
   *
   * This is called by ObjComponent.
   */
  static calcObjActions(obj, objPropName, parentObj, parentObjPropName,
    curUserObj, cA_History, cA_GetObj, cache, noDrillDown = false,
    orderedObjIndex, cA_Confirmation, cA_UsageConfirmation,
    cA_SetError) {
    log.trace("calcObjActions(), obj: ", obj);
    log.trace("objPropName: ", objPropName);
    log.trace("parentObj: ", parentObj);
    log.trace("parentObjPropName: ", parentObjPropName);
    const actions = [];

    if (!curUserObj) {
      return actions;
    }

    if (!cA_SetError) debugger;

    // Toggle object's isTestData prop.
    if (curUserObj && curUserObj.hasAdminPriv &&
      curUserObj.hasAdminPriv()) {
      add(toggleTestData(obj, curUserObj, cA_Confirmation), actions);
    }

    // Approve the object
    if (curUserObj && curUserObj.hasAdminPriv &&
      curUserObj.hasAdminPriv()) {
      add(approve(obj, curUserObj, cA_Confirmation), actions);
    }

    // Set object status to Pending.
    if (curUserObj && curUserObj.hasAdminPriv &&
      curUserObj.hasAdminPriv()) {
      add(setPending(obj, curUserObj, cA_Confirmation), actions);
    }

    // Parking
    if (obj.cn == ACTS || obj.cn == ITNS) {
      add(removeParkWaypoint(obj, curUserObj), actions);
      add(selectParkWaypoint(obj, cA_History), actions);
      add(editParkWaypoint(obj, curUserObj, cA_History,
        cA_GetObj, cache), actions);
    }

    // View the obj.  Don't add View action if already showing it.
    if (obj.view && !noDrillDown) {
      add(viewObj(obj), actions);
    }

    // Directions to obj
    if (obj.view) {
      add(directions(obj, cA_History), actions);
    }

    if (obj.viewFavorites) {
      add(viewFavorites(obj, curUserObj), actions);
    }

    if (obj._id == "users_auth0|5f585f72a0e4b2006c986250") {
      //debugger;
    }
    if (obj.viewRecommendations) {
      add(viewRecommendations(obj, curUserObj), actions);
    }

    // Add obj to a user selected parentObj.children list.
    // E.g. add a Way to an Act's list of waypoints.
    if (obj.view && obj.parentCn) {
      add(addToChildrenOfSelectedObj(obj), actions);
    }

    // Admin can add favorites to other users.
    if (obj.favorite && curUserObj && curUserObj.hasAdminPriv &&
        curUserObj.hasAdminPriv()) {
      add(addToChildrenOfSelectedObj(obj, "favorites", USERS), actions);
    }

    // Remove obj from parentObj.children.
    if (parentObj && parentObj.edit && objPropName == "children") {
      add(removeFromParentsChildren(obj, parentObj, curUserObj,
        orderedObjIndex), actions);
    }

    if (parentObj && parentObj.edit && objPropName == "userIds") {
      // Add/remove userIds from parentObj.userIds
      add(addToCircle(obj, parentObj), actions);
      add(removeFromCircle(obj, parentObj), actions);
    }

    if (!((curUserObj && curUserObj.visibility) == "isPublic") &&
        obj.view && obj.cn == USERS) {
        add(addToCircle(obj, curUserObj), actions);
        add(removeFromCircle(obj, curUserObj), actions);
    }

    if (curUserObj && curUserObj.edit &&
        curUserObj.hasAdminPriv &&
        !curUserObj.hasAdminPriv()) {
      // Add obj to curUserObj.favorites.
      add(addToFavorites(obj, curUserObj, curUserObj,
        cA_Confirmation), actions);
      // Remove obj from curUserObj.favorites.
      add(removeFromFavorites(obj, curUserObj, curUserObj,
        cA_Confirmation), actions);

      if (obj.cn == USERS) {
        add(addToGuides(obj, curUserObj, curUserObj,
          cA_Confirmation), actions);
        add(removeFromGuides(obj, curUserObj, curUserObj,
          cA_Confirmation), actions);
      }
    }

    // NOTE: Applies if we are looking at a user's Favorites page.
    if (parentObj && parentObj.cn == USERS &&
        parentObj.edit && objPropName == "favorites") {
      // Add obj to parentObj.favorites.
      //add(addToFavorites(obj, parentObj, curUserObj,
      //  cA_Confirmation), actions);
      // Remove obj from parentObj.favorites.
      add(removeFromFavorites(obj, parentObj, curUserObj,
        cA_Confirmation), actions);
    }

    // NOTE: Applies if we are looking at a user's Recommendations page.
    if (parentObj && parentObj.cn == USERS &&
        (parentObj.edit || parentObj.guides.includes(curUserObj._id)) &&
        objPropName == "recommendations") {
      // Remove obj from parentObj.recommendations.
      add(removeFromRecommendations(obj, parentObj, curUserObj,
        cA_Confirmation), actions);

      // Add recommendation to a different selected user.
      //add(addToRecommendations(obj, parentObj, curUserObj,
      //  cA_Confirmation), actions);
    }

    if (obj.recommend &&
        ((curUserObj && curUserObj.hasRole && curUserObj.hasRole("guide")) ||
        (curUserObj && curUserObj.hasAdminPriv && curUserObj.hasAdminPriv()))) {
      add(addToChildrenOfSelectedObj(obj, "recommendations", USERS), actions);
    }

    // NOTE: Applies if we are looking at a user's Guides page.
    if (parentObj && parentObj.edit && objPropName == "guides" &&
        obj.cn == USERS) {
      // Add obj to parentObj.guides.
      add(addToGuides(obj, parentObj, curUserObj,
        cA_Confirmation), actions);
      // Remove obj from parentObj.guides.
      add(removeFromGuides(obj, parentObj, curUserObj,
        cA_Confirmation), actions);
    }

    // Upload Image
    if (obj.edit) {
      add(uploadImage(obj), actions);
    }

    // Remove Image
    if (obj.edit) {
      add(removeImage(obj, curUserObj, cA_Confirmation), actions);
    }

    // Create URL Link
    if (obj.edit) {
      add(createLink(obj, curUserObj, cA_History), actions);
    }

    // Add selected objects to obj.children.
    if (obj.edit && obj.childrenCn) {
      add(addSelectedToChildrenOfObj(obj), actions);
    }

    // Delete the object
    if (obj.deleteObj) {
      add(deleteObj(obj, curUserObj, noDrillDown, cA_History,
        cA_Confirmation, cA_UsageConfirmation), actions);
    }

    if (obj.cn == USERS) {
      log.trace("In ObjActions doing user specific actions on user: ", obj);
      if (obj.view) {
        add(viewMessages(obj), actions);
        add(sendMessage(obj), actions);
        add(connect(obj, curUserObj, cA_SetError), actions);
      }
      if (obj.edit) {
        add(uploadAvatar(obj), actions);
        add(removeAvatar(obj, curUserObj, cA_Confirmation), actions);

        // Show the users in userIds list.
        add(viewUsers(obj, curUserObj), actions);
      }
      add(acceptConnection(obj, curUserObj), actions);
      add(disconnect(obj, curUserObj, cA_Confirmation), actions);

      // Show the users in guides list.
      add(viewGuides(obj, curUserObj), actions);

      // Show the users who have obj as one of their guides.
      add(viewFollowers(obj, curUserObj), actions);

      //add(deleteUser(obj, curUserObj, noDrillDown, cA_History,
      //  cA_Confirmation, cA_UsageConfirmation), actions);
    }

    // Flag the object as offensive
    add(flag(obj, curUserObj, cA_Confirmation), actions);
    
    return actions;
  }

  /**
   * Calculate all the actions applicable to an object
   * displayed on MyMap.  This is all the normal actions
   * plus the location related actions for an object.
   *
   * This is called by the MyMap component.
   */
  static calcObjOnMapActions(obj, objPropName, parentObj, parentObjPropName,
    curUserObj, cA_History, cA_GetObj, cache, noDrillDown,
    orderedObjIndex, cA_Confirmation, cA_UsageConfirmation,
    setPoint, resetPoint, cA_SetLoader, cA_SetError) {

    const actions = ObjActions.calcObjActions(obj, objPropName,
    parentObj, parentObjPropName,
    curUserObj, cA_History, cA_GetObj, cache, noDrillDown,
    orderedObjIndex, cA_Confirmation, cA_UsageConfirmation,
    cA_SetError);

    if (obj.edit) {
      add(calcLocationActions(setPoint, resetPoint,
        cA_SetLoader), actions);
    }

    return actions;
  }
}

export default ObjActions;
export {add, approve, createObj, toggleFilterAndSortVisibility};
export {viewUsers, viewGuides, viewMessages};
export {addSelectedUsersToUserIds};

