import React from 'react';
import DropzoneComponent from 'react-dropzone-component';
import {Container, Header, Segment, Button, Grid} from 'semantic-ui-react';
import {log, ImageUtils, ConciergeRestApi, MyError, HttpStatus} from 'concierge-common';
import ImageUtilsCon from '../utils/image-utils';
import ApiUtils from '../client-db/api/api-utils';
import Obj from '../client-db/api/obj';


class ImageUploadPanel extends React.Component {

  handleClickCancel() {
    log.trace("ImageUploadPanel.handleClickCancel()");
    //const {cA_SetXXXShellContents, cA_NotYetImplemented} = this.props;
    //cA_NotYetImplemented("TODO: Implement navigation back to calling page.");
    //cA_SetXXXShellContents("home");

    const {cA_History} = this.props;
    cA_History("back");
  }

  /**
   * This is called if the user clicks the "Crop" button instead
   * of the immediate "Upload" button.  This displays the
   * ImageCropPanel which lets the user crop the image before
   * uploading it.
   */
  handleClickCropImage() {
    log.trace("ImageUploadPanel.handleClickCropImage()");
    const {props} = this;
    const {cA_SetShellContentsProps, cA_SetError, history} = props;
    const {dropzone} = this.state;
    const acceptedFiles = dropzone.getAcceptedFiles();
    log.trace("acceptedFiles:", acceptedFiles);
    if (acceptedFiles.length < 1) {
      const msg = "You must select at least one image.";
      const props = {msg};
      const error = MyError.createSubmitError(props);
      cA_SetError(error);
      return;
    }
    const file = acceptedFiles[0];

    log.trace("file:", file);
    //const cropTitle = title ? title+" : Crop" : "Crop Image";
    //const cropTitle = title ? title : "Upload / Crop Image";
    //const cropTitle = "Upload / Crop Image";
    //const name = "imageCrop";
    const shellContentsProps = {
      file,
      uploadSuccessHandler:this.uploadSuccessHandler.bind(this),
    }

    // This call just sets the store state.ui.contentsProps.
    // It does not cause navigation.
    //cA_SetXXXShellContents(name, shellContentsProps);
    cA_SetShellContentsProps(shellContentsProps);

    //let url = history.location.pathname+history.location.search; 
    const url = "/imageCrop"+history.location.search; 
    // TODO: Change this to use cA_History("push", url)?
    props.history.push(url);
  }

  /**
   * Error handler if this client does the upload to Firebase.
   * (As opposed to the concierge-rest server doing the upload to Firebase.)
   */
  uploadErrorHandler(err) {
    log.trace("Error during upload, err:", err);
    let msg = "Image upload failed.  ";
    try {
      const serverResponse = JSON.parse(err.serverResponse);
      const {code} = serverResponse.error;
      if (code == HttpStatus.FORBIDDEN) {
        const maxSize = ConciergeRestApi.MAX_IMAGE_SIZE_STRING;
        msg += "Image too large.  Maximum allowed size: "+maxSize;
      }
      else {
        msg += "Firebase ref.put(file) threw error.  err.serverResponse: "+err.serverResponse;
      }
    }
    catch(errNotUsed) {
      // Exception thrown while trying to parse the "err".
      // So simply try to display it as a string.
      msg += "Possible code bug?  Details: err: "+err;
    }
    const props = {msg};
    const error = MyError.createSubmitError(props);
    const {cA_SetError, cA_SetLoader} = this.props;
    cA_SetError(error);
    cA_SetLoader(false);
  }

  constructor(props) {
    super(props);
    const state = {
      dropzone:null,
      maxFiles:props.maxFiles ? props.maxFiles : 1,
      buttonsDisabled:true
    };
    this.state = state;
  }

  dropzoneAddedfile(file) {
    // Clear any error messages before we start the upload.
    log.trace("file:", file);
    log.trace("file.name:", file.name);
    log.trace("file.type:", file.type);

    //const props = this.props;
    const {dropzone, maxFiles} = this.state;

    //const files = dropzone.getAcceptedFiles();
    const files = dropzone.files;
    log.trace("files:", files);
    if (files.length > maxFiles) {
      log.trace("Removing first file");
      dropzone.removeFile(files[0]);
    }
    const acceptedFiles = dropzone.getAcceptedFiles();
    log.trace("acceptedFiles:", acceptedFiles);
    setTimeout(() => {
      const acceptedFiles = dropzone.getAcceptedFiles();
      log.trace("acceptedFiles:", acceptedFiles);
        if (acceptedFiles.length >= 1) {
          this.setState({buttonsDisabled:false});
        }
        else {
          this.setState({buttonsDisabled:true});
        }
      }, 500);  // 0 Works, but using 500 to be "sure".
  }

  dropzoneInit(dropzone) {
    this.setState({dropzone});
    log.trace("dropzone:", dropzone);
  }

  dropzoneError(file, err) {
    const error = MyError.createSubmitError({msg:err});
    this.props.cA_SetError(error);
  }

  dropzoneAccept(file, done) {
    if (file.name == "invalidFile.jpg") {
      done('"You are not allowed to upload a file with the name invalidFile.jpg"');
    }
    else {
      done();
    }
  }

  // Currently, this is only needed to workaround a Chrome66 bug.
  getChromeVersion () {
    var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
    return raw ? parseInt(raw[2], 10) : false;
  }

  /**
   * Create the DropzoneComponent that will "wrap" the image.
   * Clicking or dragging/dropping on the component will cause
   * a new image to be uploaded.
   */
  createDropzone() {
    const {props} = this;
    const {touch, hover} = props.device || {};
    //alert("touch("+touch+")");
    let {maxFiles} = props;
    maxFiles = maxFiles ? maxFiles : 1;

    const uploadMultiple = maxFiles > 1 ? true : false;

    // These are configuration variables for the DropzoneComponent.
    const componentConfig = {
      // Honored by selection dialog, but not drag/drop.
      maxFiles,

      // The two props below show the file icons when hovering.
      // Problem is the hovered area is not actually a drop target.
      // Bug in the Dropzone module?
      iconFiletypes: ['.jpg', '.jpeg', '.png', '.gif'],
      showFiletypeIcon: true,

      // Have not seen this called even when dropping multiple files
      // on the Dropzone.
      maxfilesexceeded:file => log.trace("XXXXXXXXXXXXXXXXXXX Max Files Exceeded"),

      // Don't have Dropzone do the POST.
      // Setting postUrl to "no-url" means the DropzoneComponent
      // does NOT call the server API using POST.  Instead, we
      // must do the work.
      postUrl:"no-url",
    };

    // NOTE: If I move the init event handler into the djsConfig,
    // it does not work.  Called too late?
    const eventHandlers = {
      init:this.dropzoneInit.bind(this),  // Works only in here.
      addedfile:this.dropzoneAddedfile.bind(this),  // Works only in here.

      // Works in both places, but only see the error "X" icon if it is here.
      error:this.dropzoneError.bind(this),

      //accept:this.dropzoneAccept.bind(this),  // Does NOT work here.
    }
    //const eventHandlers = {};

    // The bug described at this URL shows up in chrome66 on Android 6.
    // Discussion: https://productforums.google.com/forum/#!topic/chrome/5mj0j2v7LOg;context-place=forum/chrome
    // christopher.r.haley gives details and the fix I am using
    // He also gives the setups that work/don't work.
    //
    // April 2018
    //
    const chrome66Android6BugWorkaround = true;
    let chromeVersionHasBug = false;
    if (chrome66Android6BugWorkaround) {
      const chromeVersion = this.getChromeVersion();
      log.trace("chromeVersion:", chromeVersion);
      //alert("chromeVersion("+chromeVersion+")");
      chromeVersionHasBug = chromeVersion == 66;
    }

    // Create the message displayed to the user in the dropzone.
    let dictDefaultMessage;
    if (touch && chromeVersionHasBug) {
      // Mobile/touch device running chrome that has bug that
      // crashes chrome if input element allows file selection.
      // So, only allow image upload via taking a picture with
      // phone's camera.
      dictDefaultMessage = "Tap here to use camera.";
    }
    else if (touch) {
      // Mobile/touch device running chrome that does NOT have
      // the bug.  So allow user to select file OR use camera.
      dictDefaultMessage = "Tap here to select photo or use camera.";
    }
    else if (hover) { 
      // Desktop computer with a mouse.
      dictDefaultMessage = "Click here, or drop a file here.";
    }
    else {
      // We don't know what kind of device it is.
      dictDefaultMessage = "Click, tap, or drop a file here.";
    }
    dictDefaultMessage += "  Then Crop or Upload.";

    //let maxFilesize = ConciergeRestApi.MAX_IMAGE_SIZE / (1024 * 1024);  // Megabytes
    let maxFilesize = 10;  // Megabytes
    maxFilesize = maxFilesize.toFixed(2);  // Two decimal places.
    // NOTE: djsConfig is the object containing the properties that
    // are passed to the DropzoneJS component that the
    // react-dropzone-component uses.
    const djsConfig = {
      // Honored by selection dialog, but not drag/drop.
      uploadMultiple:uploadMultiple,
      // Honored by selection dialog, but not drag/drop.
      acceptedFiles:"image/jpeg,image/png,image/gif",
      // Honored by selection dialog, but not drag/drop.
      maxFiles,

      dictDefaultMessage,  // Message to user in dropzone.
      maxFilesize,  // Megabytes
      maxThumbnailFilesize:maxFilesize,  // Megabytes
      dictInvalidFileType:"Image must be of type jpg, jpeg, png, or gif.",

      accept:this.dropzoneAccept.bind(this),  // Works only in here.

      // Works in both places, but only see the error "X" icon if it is
      // declared in the eventHandlers object.
      //error:this.dropzoneError.bind(this),  // Works in both places.

      //init:this.dropzoneInit.bind(this),  // Does NOT work here.
      //addedfile:this.dropzoneAddedfile.bind(this),  // Does NOT work here.

      // Probably don't need to set this because postUrl is "no-url",
      // so Dropzone is not sending the files to the server anyway.
      autoProcessQueue:false,

      // Shows "Remove File" link on added files if true.
      // But, because we have set pointer-events:none on the
      // dz-preview element, the link would do nothing.
      // If you set addRemoveLinks to true, then remove the
      // pointer-events:none setting from the dz-preview CSS.
      addRemoveLinks:false,
    };

    if (chromeVersionHasBug) {
      // See above for info about Chrome66 bug.

      // I tried the "accept" property, but it is ignored.
      // The "acceptedFiles" property is what
      // react-dropzone-component puts into the input element's
      // "accept" attribute.
      //djsConfig.accept = "image/*";
      djsConfig.acceptedFiles = "image/*";
      djsConfig.capture = "camera";
    }
    log.trace("djsConfig:", djsConfig);

    return(
      <DropzoneComponent config={componentConfig}
        eventHandlers={eventHandlers}
        djsConfig={djsConfig} />
    );
  }

  /**
   * TODO: This function and image-crop-panel.js handleClickOriginal()
   * are almost identical.
   * image-crop-panel.js handleClickCropped() is also almost the
   * same except for the cropping part.  Put the code into a utility.
   *
   * "file" comes from a different place.
   */
  async handleClickUploadImage() {
    log.trace("ImageUploadPanel.handleClickUploadImage()");
    const {cA_SetError, cA_SetLoader, filename} = this.props;

    const {dropzone} = this.state;
    const acceptedFiles = dropzone.getAcceptedFiles();
    log.trace("acceptedFiles:", acceptedFiles);
    if (acceptedFiles.length < 1) {
      const msg = "You must select at least one image.";
      const props = {msg};
      const error = MyError.createSubmitError(props);
      cA_SetError(error);
      return;
    }
    let file = acceptedFiles[0];
    log.trace("file:", file);

    log.trace("file.name:", file.name);
    //log.trace("file.originalname:", file.originalname);
    //log.trace("file.mimetype:", file.mimetype);
    log.trace("file.type:", file.type);
    const fileExt = ImageUtils.getFileExt(file.name, file.type);
    log.trace("fileExt:", fileExt);
    if (fileExt != "jpg" && fileExt != "jpeg" && fileExt != "png" && fileExt != "gif") {
      const props = {
        msg:'Your image\'s format was '+fileExt+
          '.  Image format must be: jpg, jpeg, gif, or png.',
      };
      const error = MyError.createSubmitError(props);
      cA_SetError(error);
      return;
    }

    //const filename = file.name;
    log.trace("filename:", filename);

    // TODO: Perhaps clear error messages elsewhere?
    cA_SetError(null);
    cA_SetLoader(true, "Preparing image for upload...");

    try {
      // The filename we pass in does NOT have an extension.  If I add something
      // like ".jpg" to "foo", Firebase adds on its own ".jpeg", making it 
      // "foo.jpg.jpeg".  Perhaps if I set the file.type to "image/jpg" it wouldn't?
      const result = await ImageUtilsCon.resizeConvertUpload(file, filename);
      log.trace("result:", result);
      const {error, downloadURL} = result;
      if (error) {
        cA_SetError(error);
      }
      else {
        this.uploadSuccessHandler(downloadURL);
      }
    }
    catch(err) {
      this.uploadErrorHandler(err);
    }
    cA_SetLoader(false);
  }

  /**
   * Image upload succeeded.  Show a notification message.
   * and then call the consumer supplied uploadSuccessHandler
   * callback.  If a User object was the consumer, the callback
   * it supplied use will update update the User object's profile
   * image src property to point to the newly uploaded image.
   * The Act object's callback would do something similar.
   *
   * This function is called whether the server or the client did
   * the upload to Firebase, assuming the upload succeeded.
   */
  uploadSuccessHandler(imageURL) {
    log.trace("ImageUploadPanel.uploadSuccessHandler(), imageURL:", imageURL);
    const {curUser, objId, propName, cA_SetError, cA_SetLoader,
      cA_History} = this.props;
    const collectionName = Obj.getCnFromId(objId);
    const props = {
      msg:"Image upload succeeded.",
      severity:MyError.SUCCESS
    };
    const error = MyError.createSubmitError(props);
    cA_SetError(error);

    // Create the property object that will tell mongoose over
    // on the concierge-rest server the new property value.  E.g.
    // objProps = {
    //   imageSrc:"https://firebasestorage.googleapis.com/image/123..."
    // }
    const objProps = {[propName]:imageURL};
    ApiUtils.editRxDBObj(curUser, objId, objProps/*, collectionName*/);

    // TODO: Perhaps make the call above an async call so we can
    // wait until it returns before setting the loader to false?
    cA_SetLoader(false);

    // Navigate back to the page that originally displayed this
    // image upload page.
    cA_History("back");
  }

  buttonsDisabled() {
    const {buttonsDisabled} = this.state;
    return buttonsDisabled;
  }

  render() {
    log.trace("ImageUploadPanel.render() this.props:", this.props);
    const {props} = this;
    //const {objType, objId} = props;
    let {className} = props;
    className = "image-upload-panel" + (className ? " "+className : "");

    const title = "Adventure: Upload / Crop Image";

    const dropzone = this.createDropzone();

    const buttonsDisabled = this.buttonsDisabled();
    log.trace("buttonsDisabled:", buttonsDisabled);

    return (
      <Container text className={className}>
        <Header as='h2'>{title}</Header>
        <Segment padded basic>
          <Grid textAlign="center" relaxed>
            <Grid.Row>
            {dropzone}
            </Grid.Row>
            <div className="stackable">
              <Button positive
                disabled={buttonsDisabled}
                onClick={this.handleClickCropImage.bind(this)}>
                Crop
              </Button>
              <Button primary
                disabled={buttonsDisabled}
                onClick={this.handleClickUploadImage.bind(this)}>
                Upload
              </Button>
              <Button
                onClick={this.handleClickCancel.bind(this)}>
                Cancel
              </Button>
            </div>
          </Grid>
        </Segment>
      </Container>
    );
  }
}

export default ImageUploadPanel;
