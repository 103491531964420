import React from 'react';
import ReactCrop, {makeAspectCrop} from 'react-image-crop';
import {Container, Header, Button} from 'semantic-ui-react';
import {Image} from 'semantic-ui-react';
import {log, ConciergeRestApi, MyError, HttpStatus} from 'concierge-common';

// NOTE: ImageUtilsCom and ImageUtilsCon are two different modules.
import {ImageUtils as ImageUtilsCom} from 'concierge-common';
import ImageUtilsCon from '../utils/image-utils';


class ImageCropPanel extends React.Component {

  async componentDidMount() {
    log.trace("ImageCropPanel.componentDidMount(), props:", this.props);
    const {file} = this.props.contentsProps;
    if (!file) {
      log.bug("ImageCropPanel.componentDidMount(), file is null.  Need to navigate to ImageUploadPanel or Home perhaps.");
      return;
    }
    const dataUrl = await ImageUtilsCon.readAsDataURLPromise(file);
    this.setState({src:dataUrl});
  }

  setState(state) {
    super.setState(state);
    log.trace("setState() state: ", state);
  }

  constructor(props) {
    super(props);
    let {aspect} = this.props;

    this.state = {
      // TOOD: Allow caller to pass this in?
      src:ImageUtilsCom.PLACEHOLDER_ACT_IMAGE,
    };

    // Values are percentages of the full image width or height.
    const crop = {
      unit:"%",
      x: 10,
      y: 10,
      width: 20,
    }
    if (aspect) {
      crop.aspect = aspect;
    }
    else {
      crop.height = 20;
    }
    this.state.crop = crop;

    // On phone/mobile, prevent the user's dragging/resizing of
    // the crop area from scrolling the whole page.
    window.addEventListener("touchmove", function() {/* Do nothing */});
  }

  /*
  handleChange(pixelCrop, percentCrop) {
    log.trace("ImageCropPanel.handleChange(), pixelCrop:", pixelCrop);
    log.trace("ImageCropPanel.handleChange(), percentCrop:", percentCrop);
    this.setState({crop:pixelCrop});
  }
  */

  onImageLoaded = (image) => {
    let {aspect} = this.props;
    aspect = aspect ? parseFloat(aspect) : undefined;

    // Values are percentages of the full image width or height.
    let crop;
    if (aspect) {
      crop = {
        unit:"%",
        x:25,
        y:25,
        aspect,
        width:50,  // Initial width, but user can change.
      };
      // This is the passed in image's actual "natural" aspect ratio.
      const naturalAspect = image.naturalWidth / image.naturalHeight;
      crop = makeAspectCrop(crop, naturalAspect);
    }
    else {
      // No aspect specified, let the user do whatever s/he wants.
      crop = {
        unit:"%",
        x:25,
        y:25,
        width:50,
        height:50
      };
    }

    const state = {
      crop,
      image
    };
    this.setState(state);
  }

  onCropComplete = (pixelCrop, percentCrop) => {
    log.trace('onCropComplete, pixelCrop:', pixelCrop);
    log.trace('onCropComplete, percentCrop:', percentCrop);
    this.setState({crop:percentCrop});
  }

  onCropChange = (pixelCrop, percentCrop) => {
    log.trace('onCropChange, pixelCrop:', pixelCrop);
    log.trace('onCropChange, percentCrop:', percentCrop);
    this.setState({crop:percentCrop});
  }

  getPixelCrop() {
    const {crop, image} = this.state;
    return {
      x: Math.round(image.naturalWidth * (crop.x / 100)),
      y: Math.round(image.naturalHeight * (crop.y / 100)),
      width: Math.round(image.naturalWidth * (crop.width / 100)),
      height: Math.round(image.naturalHeight * (crop.height / 100))
    };
  }

  async handleClickCropped() {
    log.trace("\n\nXXXXX\nImageCropPanel.handleClickCropped(), props:",
      this.props);
    let {pixelCrop} = this.state;
    log.trace("pixelCrop:", pixelCrop);
    if (pixelCrop == null) {
      // I think pixelCrop will always be null in the new 
      // version of react-image-crop we are using.
      pixelCrop = this.getPixelCrop();
    }
    log.trace("pixelCrop:", pixelCrop);

    const {cA_SetError, cA_SetLoader, filename} = this.props;
    log.trace("filename:", filename);
    let {file} = this.props.contentsProps;
    log.trace("file.name:", file.name);
    //log.trace("file.originalname:", file.originalname);
    //log.trace("file.mimetype:", file.mimetype);
    log.trace("file.type:", file.type);
    const fileExt = ImageUtilsCom.getFileExt(file.name, file.type);
    log.trace("fileExt:", fileExt);
    if (fileExt != "jpg" && fileExt != "jpeg" && fileExt != "png" && fileExt != "gif") {
      const props = {
        msg:'Your image\'s format was '+fileExt+
          '.  Image format must be: jpg, jpeg, gif, or png.',
      };
      const error = MyError.createSubmitError(props);
      cA_SetError(error);
      return;
    }

    const {image} = this.state;
    log.trace("image:", image);
    log.trace("filename:", filename);

    let blob;
    try {
      // I don't actually think it matters what the value of filename
      // is for the creation of this blob.  Later, it matters in the
      // resizeConvertUpload() call.
      //blob = await ImageUtilsCon.getCroppedImg(image, pixelCrop, filename+".jpg");
      blob = await ImageUtilsCon.getCroppedImg(image, pixelCrop, filename);
      log.trace("blob:", blob);
    }
    catch(err) {
      log.trace("err:", err);
      log.error("TODO: call consumer error handler.");
      return;
    }

    //let file = image;
    //log.trace("file:", file);

    //const dataURL = image.src;
    //log.trace("dataURL:", dataURL);
    //const blob = ImageUtilsCon.dataURLtoBlob(dataURL);
    //log.trace("blob:", blob);
    file = blob;
    log.trace("file:", file);
    log.trace("this:", this);
    log.trace("this.props:", this.props);

    // TODO: Perhaps clear error messages elsewhere?
    cA_SetError(null);
    cA_SetLoader(true, "Preparing image for upload...");

    try {
      const result = await ImageUtilsCon.resizeConvertUpload(file, filename);
      log.trace("result:", result);
      const {error, downloadURL} = result;
      if (error) {
        cA_SetError(error);
      }
      else {
        this.uploadSuccessHandler(downloadURL);
      }
    }
    catch(err) {
      this.uploadErrorHandler(err);
    }
    cA_SetLoader(false);
  }

  /**
   * Image upload succeeded.  Show a notification message.
   * and then call the consumer supplied uploadSuccessHandler
   * callback.  If a User object was the consumer, the callback
   * it supplied use will update update the User object's profile
   * image src property to point to the newly uploaded image.
   * The Act object's callback would do something similar.
   *
   * This function is called whether the server or the client did
   * the upload to Firebase, assuming the upload succeeded.
   */
  uploadSuccessHandler(imageURL) {
    log.trace("ImageCropPanel.uploadSuccessHandler(), imageURL:", imageURL);
    const {cA_SetError, cA_SetLoader, cA_History,
      contentsProps} = this.props;
    const {uploadSuccessHandler} = contentsProps;
    log.trace("uploadSuccessHandler:", uploadSuccessHandler);
    log.trace("typeof uploadSuccessHandler:", typeof uploadSuccessHandler);
    const props = {
      msg:"Image upload succeeded.",
      severity:MyError.SUCCESS
    };
    const error = MyError.createSubmitError(props);
    cA_SetError(error);

    // Image upload succeeded.  Now call the consumer supplied
    // uploadSuccessHandler.  For example, a User object's
    // uploadSuccessHandler will change the User's image "src"
    // property to point to the newly uploaded image.
    if (uploadSuccessHandler) {
      log.trace("Calling uploadSuccessHandler");
      uploadSuccessHandler(imageURL);
      log.trace("Returned from uploadSuccessHandler");
    }
    else {
      log.bug("ImageCropPanel was not given an uploadSuccessHandler() by the consumer.");
    }

    // TODO: Perhaps make the call above an async call so we can
    // wait until it returns before setting the loader to false?
    cA_SetLoader(false);

    // Navigate back to the page that originally displayed this
    // image upload page.
    cA_History("back");
  }

  /**
   * Error handler if this client does the upload to Firebase.
   * (As opposed to the concierge-rest server doing the upload to Firebase.)
   */
  uploadErrorHandler(err) {
    log.trace("Error during upload, err:", err);
    let msg = "Image upload failed.  ";
    try {
      const serverResponse = JSON.parse(err.serverResponse);
      const {code} = serverResponse.error;
      if (code == HttpStatus.FORBIDDEN) {
        const maxSize = ConciergeRestApi.MAX_IMAGE_SIZE_STRING;
        msg += "Image too large.  Maximum allowed size: "+maxSize;
      }
      else {
        msg += "Firebase ref.put(file) threw error.  err.serverResponse: "+err.serverResponse;
      }
    }
    catch(errNotUsed) {
      // Exception thrown while trying to parse the "err".
      // So simply try to display it as a string.
      msg += "Possible code bug?  Details: err: "+err;
    }
    const props = {msg};
    const error = MyError.createSubmitError(props);
    const {cA_SetError, cA_SetLoader} = this.props;
    cA_SetError(error);
    cA_SetLoader(false);
  }

  async handleClickOriginal() {
    log.trace("ImageCropPanel.handleClickOriginal()");
    const {cA_SetError, cA_SetLoader, contentsProps} = this.props;
    const {filename} = contentsProps;

    let {file} = this.props.contentsProps;
    log.trace("file.name:", file.name);
    //log.trace("file.originalname:", file.originalname);
    //log.trace("file.mimetype:", file.mimetype);
    log.trace("file.type:", file.type);
    const fileExt = ImageUtilsCom.getFileExt(file.name, file.type);
    log.trace("fileExt:", fileExt);
    if (fileExt != "jpg" && fileExt != "jpeg" && fileExt != "png" && fileExt != "gif") {
      const props = {
        msg:'Your image\'s format was '+fileExt+
          '.  Image format must be: jpg, jpeg, gif, or png.',
      };
      const error = MyError.createSubmitError(props);
      cA_SetError(error);
      return;
    }

    //const filename = file.name;
    log.trace("filename:", filename);

    const {image} = this.state;
    log.trace("image:", image);

    //let file = image;
    //log.trace("file:", file);
    const dataURL = image.src;
    //log.trace("dataURL:", dataURL);

    const blob = ImageUtilsCon.dataURLtoBlob(dataURL);
    //log.trace("blob:", blob);
    file = blob;
    log.trace("file:", file);

    // TODO: Perhaps clear error messages elsewhere?
    cA_SetError(null);
    cA_SetLoader(true, "Preparing image for upload...");

    try {
      const result = await ImageUtilsCon.resizeConvertUpload(file, filename);
      log.trace("result:", result);
      const {error, downloadURL} = result;
      if (error) {
        cA_SetError(error);
      }
      else {
        this.uploadSuccessHandler(downloadURL);
      }
    }
    catch(err) {
      this.uploadErrorHandler(err);
    }
    cA_SetLoader(false);
  }

  handleClickCancel() {
    log.trace("ImageCropPanel.handleClickCancel()");
    //const {cA_SetXXXShellContents, cA_NotYetImplemented} = this.props;
    //cA_NotYetImplemented("TODO: Implement navigation back to calling page.");
    //cA_SetXXXShellContents("home");

    const {cA_History} = this.props;
    cA_History("back");
  }

  async rotate(clockwise) {
    log.trace("ImageCropPanel.handleClickRotateLeft()");
    //const {file} = this.props.contentsProps;

    // TODO: This is wasteful to keep converting it between
    // base64 dataUrl format and Blob format.
    // Could provide 3 buttons instead: 90Left, 90Right, 180,
    // and then always be working with the original contentsProps.file.
    const file = ImageUtilsCon.dataURLtoBlob(this.state.src);

    const dataUrl = await ImageUtilsCon.readAsDataURLPromise(file);
    log.trace("Calling rotate90Degrees()");
    let rotatedDataUrl = await ImageUtilsCon.rotate90Degrees(dataUrl,
      clockwise);
    log.trace("Rotate returned.");
    log.trace("rotatedDataUrl:", rotatedDataUrl);
    this.setState({src:rotatedDataUrl});
  }

  handleClickRotateLeft() {
    log.trace("ImageCropPanel.handleClickRotateLeft()");
    const clockwise = false;
    this.rotate(clockwise);
  }

  handleClickRotateRight() {
    log.trace("ImageCropPanel.handleClickRotateRight()");
    const clockwise = true;
    this.rotate(clockwise);
  }

  render() {
    log.trace("ImageCropPanel.render() this.props:", this.props);
    log.trace("render() this.state.crop:", this.state.crop);
    const {file/*, title*/} = this.props.contentsProps;
    const title = "Upload / Crop Image";

    if (!file) {
      return <div>No image file has been selected.</div>
    }

    // This test should be unneccessary.
    //this.state.src = this.state.src ? this.state.src : ImageUtilsCom.PLACEHOLDER_ACT_IMAGE;

    return (
      <Container text className="image-crop-panel">
        <Header as='h2'>{title}</Header>
        <ReactCrop src={this.state.src}
          crop={this.state.crop}
          onImageLoaded={this.onImageLoaded}
          onComplete={this.onCropComplete}
          onChange={this.onCropChange}
          minWidth={5}
          minHeight={5}
        />
        <div className="stackable">
          <Button positive className="rotate left"
            onClick={this.handleClickRotateLeft.bind(this)}>
            <Image src="../../img/rotateLeft.png" />
            <span>Rotate Left</span>
          </Button>
          <Button positive className="rotate right"
            onClick={this.handleClickRotateRight.bind(this)}>
            <span>Rotate Right</span>
            <Image src="../../img/rotateRight.png" />
          </Button>
        </div>
        <div className="stackable">
          <Button primary
            onClick={this.handleClickCropped.bind(this)}>
            Cropped
          </Button>
          <Button primary
            onClick={this.handleClickOriginal.bind(this)}>
            Uncropped
          </Button>
          <Button
            onClick={this.handleClickCancel.bind(this)}>
            Cancel
          </Button>
        </div>
      </Container>
    );
  }
}

export default ImageCropPanel;
