import _ from 'lodash';
import {log} from 'concierge-common';


/**
 * NOTE: Comments will refer to "acts" or "users", but
 * these utilities are used for ways, acts, itns, and
 * users.  They are all stored in the same
 * way in store.state.ways, store.state.acts, ... properties.
 *
 * NOTE: These functions do NOT edit the store.state.cache
 *
 * NOTE: There is no update function.  A store.state.users.objIds
 * element cannot be "updated" or "edited".
 * The element object in store.state.cache.objs gets 
 * updated or edited.
 */
class ObjUtils {

  // An empty list of users, ways, acts, itns.
  static get EMPTY_COLLECTION() {
    return {
      collectionName:"A value like users, ways, acts, itns",
      objIds:[],
      findParam:{},
      sortParam:{},
    };
  }; 

  static _EMPTY_USERS;
  static get EMPTY_USERS() {
    if (!ObjUtils._EMPTY_USERS) {
      ObjUtils._EMPTY_USERS = ObjUtils.EMPTY_COLLECTION;
      ObjUtils._EMPTY_USERS.collectionName = "users";
    }
    return ObjUtils._EMPTY_USERS;
  }; 
  static _EMPTY_WAYS;
  static get EMPTY_WAYS() {
    if (!ObjUtils._EMPTY_WAYS) {
      ObjUtils._EMPTY_WAYS = ObjUtils.EMPTY_COLLECTION;
      ObjUtils._EMPTY_WAYS.collectionName = "ways";
    }
    return ObjUtils._EMPTY_WAYS;
  }; 
  static _EMPTY_ACTS;
  static get EMPTY_ACTS() {
    if (!ObjUtils._EMPTY_ACTS) {
      ObjUtils._EMPTY_ACTS = ObjUtils.EMPTY_COLLECTION;
      ObjUtils._EMPTY_ACTS.collectionName = "acts";
    }
    return ObjUtils._EMPTY_ACTS;
  }; 
  static _EMPTY_ITNS;
  static get EMPTY_ITNS() {
    if (!ObjUtils._EMPTY_ITNS) {
      ObjUtils._EMPTY_ITNS = ObjUtils.EMPTY_COLLECTION;
      ObjUtils._EMPTY_ITNS.collectionName = "itns";
    }
    return ObjUtils._EMPTY_ITNS;
  }; 
  static _EMPTY_ANYS;
  static get EMPTY_ANYS() {
    if (!ObjUtils._EMPTY_ANYS) {
      ObjUtils._EMPTY_ANYS = ObjUtils.EMPTY_COLLECTION;
      ObjUtils._EMPTY_ANYS.title = "The Objects:";
    }
    return ObjUtils._EMPTY_ANYS;
  }; 
  static _EMPTY_USAGE;
  static get EMPTY_USAGE() {
    if (!ObjUtils._EMPTY_USAGE) {
      ObjUtils._EMPTY_USAGE = ObjUtils.EMPTY_COLLECTION;
      ObjUtils._EMPTY_USAGE.title = "Heterogeneous Objects";
    }
    return ObjUtils._EMPTY_USAGE;
  }; 

  /**
   * If the passed in obj is an array, return it unchanged.
   * If it is not an array, create an array and put the object
   * in the array.
   */
  static createArray(obj) {
    if (Array.isArray(obj)) {
      return obj;
    }
    const objArray = [];
    objArray.push(obj);
    return objArray;
  }

  /**
   * Delete objIds from the passed in collection.
   * E.g. store.state.users
   *
   * The passed in array is modified.
   */
  /*
  static del(collection = ObjUtils.EMPTY_COLLECTION, objIdsToDelete) {
    debugger;
    const {objIds} = collection;
    // If the caller passed us a single object, but it into
    // an array of objects.  I.e. an array with one element.
    objIdsToDelete = ObjUtils.createArray(objIdsToDelete);

    objIdsToDelete.forEach(objIdToDelete =>
      _.remove(objIds, objId => objId == objIdToDelete));
  }
  */
}

export default ObjUtils;
