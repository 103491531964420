import {connect} from 'react-redux';

// Import the LoginSignupLogoutButton from which this
// LoginSignupLogoutButton is created.
import LoginSignupLogoutButtonComponent from '../components/login-signup-logout-button';

// Import action creators that this component will use.
import {cA_SetError} from '../actions/error';
import {cA_SetAuth0State} from '../actions/ui';


function mapStateToProps(state) {
  return({
    auth0State:state.auth0State
  });
}
const mapDispatchToProps = {cA_SetError, cA_SetAuth0State};
const LoginSignupLogoutButtonContainer =
  connect(mapStateToProps, mapDispatchToProps)
  (LoginSignupLogoutButtonComponent);
export default LoginSignupLogoutButtonContainer;
