import {log} from 'concierge-common';
import * as ACTION_TYPES from '../actions/types';
import {cA_SetError} from '../actions/error';
import ObjUtils from './utils/obj-utils';


/**
 * This reducer manages the value of the application's 
 * store.state.users property.
 *
 * @param users The current store.state.users object.
 * We should never modify this value.
 *
 * @param {Object} action The current action to be performed.
 * @param {String} action.type This tells us "what" we need to do.
 * @param {Object} action.payload This is the data that we will use to
 * complete the action.
 * @param {Object} action.payload.users The object that we are
 * going to act upon.
 */
function usersReducer(users = ObjUtils.EMPTY_USERS, action) {
  log.trace("usersReducer("+action.type+") users:", users);
  log.trace("usersReducer(), action:", action);
  //action.type = _.endsWith(action.type, "_") ?
  //  action.type.slice(0,-1) : action.type;

  const {payload} = action;
  /*
  if (!payload) {
    // Redux "interal" action types such as @@redux/INIT
    // don't have payloads, and we should ignore them
    // anyway.
    return users;
  }
  */

  let newUsers = users;
  switch(action.type) {

    case ACTION_TYPES.SET_USERS:
      log.trace("SET_USERS usersReducer(), action:", action);
      if (payload.error) {
        action.asyncDispatch(cA_SetError(payload.error));
        return users;
      }
      newUsers = Object.assign({}, users, payload);
      log.trace("SET_USERS newUsers:", newUsers);
      return newUsers;

    case ACTION_TYPES.SIGN_OUT_S:
      log.trace("SIGN_OUT_S usersReducer(), action:", action);
      if (payload.error) {
        return users;
      }
      newUsers = ObjUtils.EMPTY_USERS;
      return newUsers;

    default:
      log.trace("users-reducer default");
      return users;
  }
};

export default usersReducer;
