import {HttpStatus, MyError, log} from 'concierge-common';
import store from '../../store';

function createErrorFromException(err, whenTryingTo) {
  log.trace("createErrorFromException(), err:", err);
  log.trace("err.request:", err.request);
  log.trace("err.response:", err.response);
  log.trace("err.response.status:",
    err.response && err.response.status);
  log.trace("err.config:", err.config);
  let httpStatus;
  let msg;
  let error;
  let componentId;

  if (err.response == null) {
    // The request was made, but the server did not respond.
    httpStatus = HttpStatus.SERVICE_UNAVAILABLE;
    msg = "Server did not respond when trying to "+whenTryingTo+
      ".  Server not running?  Bad internet connection?"
  }
  else {
    // We got a response of some sort.
    const data = err.response.data;
    log.trace("data:", data);
    if (data) {
      componentId = (data.rootError && data.rootError.error &&
        data.rootError.error.componentId) ||
        (data.error && data.error.componentId) ||
        null;
      log.trace("data.rootError:", data.rootError);
      if (data.rootError) {
        log.trace("data.rootError.error:", data.rootError.error);
      }
    }
    httpStatus = err.response.status || HttpStatus.BAD_REQUEST;
    if (httpStatus == HttpStatus.UNAUTHORIZED) {
      const curUser = store.getState().curUser;
      if (curUser.token) {
        msg = "You are not authorized to "+whenTryingTo+".";
      }
      else {
        msg = "You are not authorized to "+whenTryingTo+
          " because you are not signed in.";
      }
    }
    else {
      msg = (data && data.error && data.error.msg) ||
            (data && data.error) ||
            (data && data.rootError &&
             data.rootError.error && data.rootError.error.msg) ||
        "Unknown error occurred on server when trying to "+whenTryingTo+".";
    }
  }
  log.trace("msg:", msg);

  log.trace("componentId:", componentId);
  error = MyError.createSubmitError({httpStatus, msg, componentId});
  error.handle.report = true;  // Should be true by default?
  log.trace("createErrorFromException returning error:", error);
  //Object.assign(userProps, {email, password});

  return error;
}

export {createErrorFromException};
