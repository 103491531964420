/**
 */
import React from 'react';
//import Loader from 'react-loader-advanced';
//import MDSpinner from "react-md-spinner";
import {Loader, Dimmer} from 'semantic-ui-react';
import {log} from 'concierge-common';

class MyLoader extends React.Component {

  render() {
    log.trace("MyLoader.render() this.props:", this.props);
    const {props} = this;
    const {children} = props;
    let {className} = props;
    className = className ? "my-loader " + className : "my-loader";

    const {visible} = props.ui.loader;
    let {message} = props.ui.loader;
    message = message ? message : "Loading...";

    return(
      <Dimmer.Dimmable className={className} dimmed={visible}>
        <Dimmer active={visible} inverted>
          <Loader>{message}</Loader>
        </Dimmer>
        {children}
      </Dimmer.Dimmable>
    );
  };
}

export default MyLoader;
