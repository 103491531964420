/**
 * Actions associated with the UI.  E.g. show/hide the sidebar.
 */
//import Promise from 'promise';
import {log} from 'concierge-common';
import * as ACTION_TYPES from './types';
import store from '../store';
import UI from '../utils/ui-state';
import Auth0Provider from '../client-db/auth0-provider';
import LocalDB from '../client-db/local-db';
import ApiUtils from '../client-db/api/api-utils';


/**
 * Set the store.state.auth0State properties.
 *
 * @param auth0State If this is null/undefined, then we wil
 * call Auth0Provider.init() to initialize the values.
 * I.e. we will log the user in.
 * If this is not a falsey value, then we will set the
 * store.state.auth0State to the new value passed in.
 */
function cA_SetAuth0State(auth0State) {
  log.trace("Entering cA_SetAuth0State()");

  return({
    type:ACTION_TYPES.SET_AUTH0_STATE,
    async payload() {
      log.trace("Entering cA_SetAuth0State() payload()");
      let payload = {auth0State};
      if (!auth0State) {
        const newAuth0State = await Auth0Provider.init();
        log.trace("cA_SetAuth0State() newAuth0State: ", newAuth0State);
        payload = {auth0State:newAuth0State};
      }
      return payload;
    }
  });
}

function cA_SetDBState(dbState = UI.DB_STATE_STOPPED) {
  log.trace("Entering cA_SetDBState("+dbState+")");
  const payload = {dbState};
  return({
    type:ACTION_TYPES.SET_DB_STATE,
    payload
  });
}

/**
 * Update the list of object Ids in the specified store.state
 * collection so that they are filtered and sorted according
 * to the passed in params.  E.g. store.state.users,
 * store.state.ways
 */
 /*
function cA_UpdateStoreStateObj(curUserObj, collectionName,
  findParam, sortParam) {
  log.trace("collectionName: ", collectionName);
  log.trace("findParam: ", findParam);
  log.trace("sortParam: ", sortParam);
  return({
    type:ACTION_TYPES.GET_FILTERED_SORTED_OBJS,
    async payload() {
      const payload = await LocalDB.getFilteredSortedObjs(
        curUserObj, collectionName, findParam, sortParam);
      log.trace("updateStoreStateObj() returned payload: ", payload);
      return payload;
    }
  });
}
*/

function cA_SetSidebarVisibility(visible = true) {
  log.trace("cA_SetSidebarVisibility("+visible+")");

  const payload = {visible};
  return({
    type:ACTION_TYPES.SET_SIDEBAR_VISIBILITY,
    payload
  });
}

function cA_SetFilterAndSortVisibility(visible = true) {
  const payload = {visible};
  return({
    type:ACTION_TYPES.SET_FILTER_AND_SORT_VISIBILITY,
    payload
  });
}

// NOTE: filterAndSort is just one subset of the state.ui.filterAndSort.
// e.g. state.ui.filterAndSort.users
function cA_SetFilterAndSort(curUser = {}, filterAndSort = {},
  collectionName) {
  const payload = {curUser, filterAndSort, collectionName};
  return({
    type:ACTION_TYPES.SET_FILTER_AND_SORT,
    payload
  });
}

function cA_SetFooterVisibility(visible = true) {
  log.trace("cA_SetFooterVisibility("+visible+")");

  const payload = {visible};
  return({
    type:ACTION_TYPES.SET_FOOTER_VISIBILITY,
    payload
  });
}

function cA_SetLoader(visible = true, message = "Loading...") {
  log.trace("cA_SetLoader("+visible+", "+message+")");

  const payload = {visible, message};
  return({
    type:ACTION_TYPES.SET_LOADER,
    //type:ACTION_TYPES.DO_NOTHING,
    payload
  });
}

/**
 * Increment numTasksToDo: cA_SetProgressBar(true, {numTasksToDo:1});
 * Increment numTasksCompleted: cA_SetProgressBar(true, {numTasksCompleted:1});
 * Set values:
 *    cA_SetProgressBar(true, null,
 *      {
 *        numTasksToDo:23,
 *        numTasksCompleted:0
 *      });
 */
//function cA_SetProgressBar(visible = true, deltaProps = null, props = null) {
function cA_SetProgressBar(visible = true) {
  log.trace("cA_SetProgressBar("+visible+")");

  const payload = {visible};
  log.trace("payload:", payload);
  return({
    type:ACTION_TYPES.SET_PROGRESS_BAR,
    payload
  });
}

/**
 * @param {string} name - The identifier of the contents
 * that should be displayed.  E.g. "users", "acts", "act", "signup",
 * "home", "about", "contact", "curUser", "user".
 *
 * @param {Object} props - Any args/params that the UI will need.
 * Usually the props parameter is null/undefined.  When it is needed,
 * it will be a value like one of these:
 *
 *    {Act} to be displayed/edited.
 *    {User}, {CurrentUser}, or {user._id} to be displayed/edited.
 *
 * TODO: Eventually delete this function and then rename
 * cA_SetShellContentsProps() to something shorter.
 * E.g. cA_SetTempProps(), cA_SetShellData, cA_Set??? 
 */
/*
function cA_SetShellContents(name, props) {
  log.trace("cA_SetShellContents("+name+"), props:", props);

  const shellContents = {name, props};
  const payload = {shellContents};
  return({
    type:ACTION_TYPES.SET_SHELL_CONTENTS,
    payload
  });
}
*/

function cA_SetShellContentsProps(shellContentsProps) {
  log.trace("cA_SetShellContentsProps(), shellContentsProps:",
    shellContentsProps);

  const payload = {shellContentsProps};
  return({
    type:ACTION_TYPES.SET_SHELL_CONTENTS_PROPS,
    payload
  });
}

/**
 * @param {Array} actions - An array of action "descriptions".
 * The caller can specify the Redux action object, or pass us
 * an action "wrapped" in the Redux connect "dispatch()" function.
 * A hypothetical action description would look something like
 * this, but would define an action OR dispatch property, but not
 * both:
 *
 *  {
 *    displayName:"Home Page",
 *    dispatch:() => cA_SetShellContents("home", contentsProps),
 *    action:{
 *      type:"setShellContents",
 *      payload:{shellContents:"home", contentsProps}
 *    },
 *    iconName:"home"
 *  }
 *
 * The "action" property is a Redux "action".
 *
 * If you want to cancel the MultiAction Modal, pass null for
 * the actions parameter.
 *
 * @param {string} headerText - Header text displayed in the Modal.
 * Pass "" if you want no header displayed.  null/undefined defaults
 * to the text "Actions".
 */
function cA_MultiAction(actions, headerText) {
  log.trace("cA_MultiAction(), actions:", actions);

  const immediatelyExecSingleAction = false;
  if (actions && actions.length == 1 &&
      immediatelyExecSingleAction) {
    // There is only one action, so execute it.
    const action = actions[0];
    if (action.dispatch) {
      // Maybe we need to do this outside/after this
      // cA_MultiAction is processed, so use a setTimeout()?
      // I don't think so, and either way works.
      //setTimeout(action.dispatch(), 1);
      action.dispatch();
    }
    else if (action.action) {
      // Return the Redux action that will be processed by
      // the reducers.
      return action.action;
    }
    else {
      const msg = "cA_MultiAction() passed an action without dispatch or action defined.";
      log.bug(msg);
      //const props = {msg};
      //const error = MyError.createBugError(props);
      //cA_SetError(error);
    }

    // Returning undefined is supposed to do the same thing as this,
    // but the redux-debounce is not happy when it tries to get
    // a value for action.meta
    // (i.e. Cannot read property 'meta' of undefined)
    return({type:ACTION_TYPES.DO_NOTHING, payload:null});
  }

  // If we get here, typically there is more than one action
  // in the passed in array of actions.  Or, the passed in array
  // was null or had no items, indicating that the MultiAction
  // should be dismissed.
  // So display the choices to the user.
  // TODO: Perhaps have a confirmation flag that asks "Are you sure?"
  // before executing the action after the user selects the action?.
  const payload = {actions, headerText};
  return({
    type:ACTION_TYPES.MULTI_ACTION,
    payload
  });
}

/**
 * @param {Object} action - A Redux action.  E.g. 
 *
 *    {
 *      type:ACTION_TYPES.SET_LOADER,
 *      payload:{visible:true, message:"Contacting server..."}
 *    }
 */
function cA_DispatchAction(action) {
  log.trace("cA_DispatchAction(), action:", action);

  const payload = action;
  return({
    type:ACTION_TYPES.DISPATCH_ACTION,
    payload
  });
}

function cA_NotYetImplemented(text) {
  log.trace("cA_NotYetImplemented(), text:", text);

  const payload = {text};
  return({
    type:ACTION_TYPES.NOT_YET_IMPLEMENTED,
    payload
  });
}

/**
 * Set the attributes of device we are on.
 * E.g. phone, tablet, touch, mouse, etc.
 */
function cA_SetDevice(device) {
  log.trace("cA_SetDevice(), device:", device);

  const payload = {device};
  return({
    type:ACTION_TYPES.SET_DEVICE,
    payload
  });
}

function cA_SetShellType(shellType) {

  const payload = {shellType};
  return({
    type:ACTION_TYPES.SET_SHELL_TYPE,
    payload
  });
}

/**
 * @param {string} func - "push", "back", "replace", "reload".
 * @param {string} url - The URL, or null/undefined if func == "back".
 * @param {string} tab - Browser tab name.  Used when opening URLs.
 */
function cA_History(func, url, tab) {
  log.trace("cA_History(), url:", url);

  const payload = {func, url, tab};
  return({
    type:ACTION_TYPES.HISTORY,
    payload
  });
}

/*
function cA_SetNavbarAndSidebar(location, curUser) {
  log.trace("cA_SetNavbarAndSidebar(), location:", location);

  const payload = {location, curUser};
  return({
    type:ACTION_TYPES.SET_NAVBAR_AND_SIDEBAR,
    payload
  });
}
*/

/*
function cA_SetActionHeader(hasBackButton, title, multiAction) {
  const payload = {hasBackButton, title, multiAction};
  return({
    type:ACTION_TYPES.SET_ACTION_HEADER,
    payload
  });
}
*/

/**
 * NOTE: Calling this function with no arguments dismisses the
 * Confirmation dialog.
 *
 * @param delaySeconds Number of seconds the OK button will be
 * "busy" before the user is allowed to press it.
 *
 * @param doubleOkConfirmation Pass true if your okAction displays
 * another confirmation dialog by calling cA_Confirmation() again.
 * NOTE: If your okAction displays another confirmation
 * dialog you MUST pass true for the doubleOkConfirmation param.
 * If you don't do that the second confirmation will not be
 * displayed.
 *
 * @param okButton Your own custom Button component.
 *
 * @param cancelButton Your own custom Button component.
 */
function cA_Confirmation(title, message1, message2, okAction, okLabel,
  cancelAction, cancelLabel, delaySeconds,
  doubleOkConfirmation, doubleCancelConfirmation,
  okButton, cancelButton, okButtonProps, okButtonIconName) {
  log.trace("cA_Confirmation(), delaySeconds:", delaySeconds);

  const payload = {title, message1, message2, okAction, okLabel,
    cancelAction, cancelLabel, doubleOkConfirmation,
    doubleCancelConfirmation, okButton, cancelButton,
    okButtonProps, okButtonIconName};
  log.trace("payload:", payload);
  if (delaySeconds) {
    payload.okDisabled = true;
    setTimeout(() => 
      store.dispatch(cA_EnableConfirmationOkButton()),
      delaySeconds * 1000);
  }
  else {
    payload.okDisabled = false;
  }
  log.trace("payload.okDisabled:", payload.okDisabled);
  return({
    type:ACTION_TYPES.CONFIRMATION,
    payload
  });
}

function cA_UsageConfirmation(curUser, usedObj,
  deleteAction, orphanAction, cancelAction) {
  return({
    type:ACTION_TYPES.USAGE_CONFIRMATION,
    async payload() {
      const usage = await ApiUtils.isUsedByAnyObjInAnyCollection(
        curUser, usedObj);
      log.trace("cA_UsageConfirmation usage: ", usage);
      const payload = {
        usedObj,
        usage,
        deleteAction,
        orphanAction,
        cancelAction,
      };
      log.trace("returning payload: ", payload);
      return payload;
    }
  });
}

function cA_EnableConfirmationOkButton() {
  return({
    type:ACTION_TYPES.ENABLE_CONFIRMATION_OK_BUTTON,
    payload:{},
  });
}

// Passing no parameters dismisses the dialog.
function cA_PropsEditor(propsToEdit, dialogProps = {}) {
  const payload = {
    propsEditor:{
      propsToEdit, dialogProps,
    }
  };
  return({
    type:ACTION_TYPES.PROPS_EDITOR,
    payload
  });
}

export {cA_SetAuth0State}
export {cA_SetDBState}
export {cA_SetSidebarVisibility}
export {cA_SetFooterVisibility}
export {cA_SetFilterAndSortVisibility}
export {cA_SetFilterAndSort}
export {cA_SetShellContentsProps}
export {cA_SetLoader}
export {cA_SetProgressBar}
export {cA_MultiAction}
export {cA_DispatchAction}
export {cA_NotYetImplemented}
export {cA_SetDevice}
export {cA_SetShellType}
export {cA_History}
//export {cA_SetNavbarAndSidebar}
//export {cA_SetActionHeader}
export {cA_Confirmation}
export {cA_UsageConfirmation}
export {cA_PropsEditor}
