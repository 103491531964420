import {log} from 'concierge-common';
import * as ACTION_TYPES from '../actions/types';
import {cA_SetError} from '../actions/error';
import ObjUtils from './utils/obj-utils';


/**
 * This reducer manages the value of the application's
 * store.state.itns property.
 *
 * @param {itns} itns The current store.state.itns object.
 * We should never modify this value.
 *
 * @param {Object} action The current action to be performed.
 * @param {String} action.type This tells us "what" we need to do.
 * @param {Object} action.payload This is the data that we will use to
 * complete the action.
 * @param {Object} action.payload.itns The new values that we are
 * going to use to alter the state.itns property.
 */
function itnsReducer(itns = ObjUtils.EMPTY_ITNS, action) {
  log.trace("itnsReducer("+action.type+") itns:", itns);
  log.trace("itnsReducer(), payload:", action.payload);
  log.trace("itnsReducer(), action:", action);

  const {payload = {}} = action;
  if (!payload) {
    // Redux "interal" action types such as @@redux/INIT
    // don't have payloads, and we should ignore them
    // anyway.  But, "_P" pending actions have no payload either,
    // so we can't ignore actions with empty payloads.
    return itns;
  }
  let newItns = itns;
  switch(action.type) {

    case ACTION_TYPES.SET_ITNS: {
      log.trace("SET_ITNS itnsReducer(), action:", action);
      if (payload.error) {
        action.asyncDispatch(cA_SetError(payload.error));
        return itns;
      }
      newItns = Object.assign({}, itns, payload);
      return newItns;
    }

    case ACTION_TYPES.SIGN_OUT_S:
      if (payload.error) {
        return itns;
      }
      newItns = ObjUtils.EMPTY_ITNS;
      return newItns;

    default:
      log.trace("itns-reducer default");
      return itns;
  }
};

export default itnsReducer;
