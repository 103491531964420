/**
 */
import _ from 'lodash';
import React from 'react';
import {Button} from 'semantic-ui-react';
import {log} from 'concierge-common';
import {DEFAULT_USER_ID} from '../global-constants';
import Obj from '../client-db/api/obj';
import ObjUtils from '../client-db/api/obj-utils';
import ApiUtils from '../client-db/api/api-utils';
import ObjsPanel from '../containers/objs-panel';


/**
 */
class SelectObjPanel extends React.Component {

  handleSelect = (selected, selectCallbackData) => {
    const {props, state} = this;
    log.trace("SelectObjPanel.handleSelect(), props:", props);
    log.trace("SelectObjPanel.handleSelect(), state:", state);
    log.trace("SelectObjPanel.handleSelect(), selected:", selected);
    log.trace("SelectObjPanel.handleSelect(), selectCallbackData:",
      selectCallbackData);
    let {task, multiselect} = props;
    multiselect = multiselect == "true";
    const obj = selectCallbackData;

    const selectedObjs = state.selectedObjs;
    log.trace("state.selectedObjs:", state.selectedObjs);

    if (selected) {
      selectedObjs.push(obj);
    }
    else {
      _.pull(selectedObjs, obj);
    }

    log.trace("selectedObjs:", selectedObjs);
    this.setState({selectedObjs});

    if (multiselect) {
      // Do nothing.  User will eventually hit the "Add Selected"
      // or "Cancel" button.
      return;
    }

    // User is only allowed to select one object, so if they
    // selected one, we are done.
    if (selectedObjs.length > 0) {
      if (task == "add") {
        this.handleClickAdd();
      }
      else if (task == "selectParkWaypoint") {
        this.handleClickSelectParkWaypoint();
      }
    }
  }

  // TODO: This should be a utility.
  createNewProps(obj, objPropName, newArray) {
    let newProps;
    if (objPropName.indexOf(".") >= 0) {
      // objPropName was something like: access.userIds
      // So we need to make a copy of all the other props
      // in the access object before we set the new value
      // for the userIds.
      const parentPropName = "access";  // Hack
      let parentProp = _.get(obj, parentPropName, {});
      parentProp = _.cloneDeep(parentProp);
      newProps = {[parentPropName]:parentProp};
    }
    else {
      // props are direct children of obj, so no extra
      // work needed.  E.g. obj.children, obj.favorites
      newProps = {};
    }
    // Now we can set the new value inside newProps.
    _.set(newProps, objPropName, newArray);
    return newProps;
  }

  /**
   * Add selected objects to the objId's objPropName array.
   */
  handleClickAdd = () => {
    const {props, state} = this;
    log.trace("SelectObjPanel.handleClickAdd(), props: ", props);
    const {objId, objPropName, selectCn, direction,
      curUser, cA_History, preventDuplicates} = props;
    const {selectedObjs} = state;
    log.trace("selectedObjs:", selectedObjs);
    if (selectedObjs.length < 1) {
      log.bug("No Object has been selected to add.");
      return;
    }

    log.trace("obj: ", state.obj);

    if (direction == "toObj") {
      // Add selected objects to obj[objPropName] array.
      const {obj} = state;
      if (obj == null) {
        // TODO: Don't enable Add button until obj has been read in.
        const objCn = Obj.getCnFromId(objId);
        log.bug("SelectObjPanel was not able to read in objId: ",
          props.objId);
        log.bug("objCn: ", objCn);
        return;
      }
      //const objs = obj[objPropName] ? obj[objPropName] : [];
      const objs = _.get(obj, objPropName, []);
      const objsLength = objs.length;
      log.trace("Existing objs:", objs);
      for (let i = 0; i < selectedObjs.length; i++) {
        const selectedObj = selectedObjs[i];
        // If preventDuplicates == true, don't allow an Obj to
        // be added twice.
        if (!preventDuplicates || objs.indexOf(selectedObj._id) === -1) {
          // Duplicates are allowed, or selectedObj is not already
          // in the list, so add it.
          objs.push(selectedObj._id);
        }
      }
      if (objs.length != objsLength) {
        // Value was changed.
        //const newProps = {[objPropName]:objs};
        const newProps = this.createNewProps(obj, objPropName, objs);
        //const objs = _.get(obj, objPropName, []);
        //_.set(newProps, objPropName, objs);
        ApiUtils.editRxDBObj(curUser, obj._id, newProps);
      }
    }
    else if (direction == "toSelected") {
      // Add objId to the selected objects' [objPropName] array.
      const {objId} = props;

      for (let i = 0; i < selectedObjs.length; i++) {
        const selectedObj = selectedObjs[i];
        //const objs = selectedObj[objPropName] ? selectedObj[objPropName] : [];
        const objs = _.get(selectedObj, objPropName, []);
        log.trace("Existing objs:", objs);
        const objsLength = objs.length;

        // If preventDuplicates == true, don't allow an Obj to
        // be added twice.
        if (!preventDuplicates || objs.indexOf(objId) === -1) {
          objs.push(objId);
        }
        if (objs.length != objsLength) {
          // Value was changed.
          //const newProps = {};
          //_.set(newProps, objPropName, objs);
          const newProps = this.createNewProps(selectedObj, objPropName, objs);
          ApiUtils.editRxDBObj(curUser, selectedObj._id, newProps);
        }
      }
    }
    else {
      log.bug("In SelectObjPanel.handleClickAdd(), unhandled direction: ",
        direction);
    }

    cA_History("back");
  }

  handleClickSelectParkWaypoint() {
    const selectedObjs = this.state.selectedObjs;
    if (selectedObjs.length < 1) {
      log.bug("No Waypoint has been selected for the parkWaypoint.");
      return;
    }

    const {cache, objId, curUser, cA_History} = this.props;
    const obj = ObjUtils.get(curUser, objId, undefined, cache);

    const way = selectedObjs[0];
    log.trace("selected way: ", way);
    const objProps = {parkWaypoint:way._id};
    ApiUtils.editRxDBObj(curUser, obj._id, objProps);

    cA_History("back");
  }

  handleClickCancel = () => {
    log.trace("SelectObjPanel.handleClickCancel()");
    const {cA_History} = this.props;
    cA_History("back");
  }

  constructor(props) {
    super(props);
    log.trace("SelectObjPanel.constructor(), props:", props);
    // "obj" is the one object we are adding to all the selectedObjs,
    // OR we are adding all the selectedObjs to the one "obj" object.
    this.state = {obj:null, selectedObjs:[]};
    const {curUser, cache, cA_GetObj} = props;
    if (!curUser || !curUser._id) {
      log.bug("SelectObjPanel.constructor(), curUser:", curUser);
      return;
    }

    //const curUserObj = ObjUtils.getUser(curUser, curUser._id);
    const curUserId = curUser && curUser._id ? curUser._id :
      DEFAULT_USER_ID;
    const curUserObj = ObjUtils.get(curUser, curUserId,
      undefined, cache, cA_GetObj);
    if (!curUserObj) {
      log.bug("SelectObjPanel.constructor(), curUserObj:", curUserObj);
      return;
    }

    this.state.curUserObj = curUserObj;
  };

  handleBeforeBack = (goBackFunc) => {
    const {curUserObj, selectedObjs} = this.state;
    const {objPropName, cA_Confirmation} = this.props;
    const {obj} = this.state;

    if (!selectedObjs || selectedObjs.length < 1) {
      // NOTE: We could display an info dialog saying we did nothing.
      goBackFunc();
      return false;
    }

    // Create the Confirmation that will be displayed.
    const {confTitle, confMessage1, confMessage2} = this.getLabels();
    const okAction = this.handleClickAdd;
    const okLabel = objPropName == "recommendations" ? "Recommend" : "Add";
    const cancelAction = goBackFunc;
    const cancelLabel = undefined;
    const okButtonProps = {positive:true, negative:false};
    const iconName = objPropName == "favorites" ?
      "heart" : objPropName == "recommendations" ? "star" : undefined ;
    cA_Confirmation(confTitle, confMessage1, confMessage2,
      okAction, okLabel, cancelAction, cancelLabel,
      0, undefined, undefined, undefined, undefined,
      okButtonProps, iconName);
    return false;
  }

  getLabels = () => {
    const {props, state} = this;
    const {objId, selectCn, direction, objPropName} = props;
    let {multiselect} = props;
    log.trace("multiselect: ", multiselect);
    multiselect = multiselect == "true";  // convert string to boolean.
    const {obj, selectedObjs} = state;
    const objDisplayName = obj ? obj.displayName : "Object";
    const objCn = Obj.getCnFromId(objId);

    const objType = Obj.DISPLAY_NAME(objCn);
    const objTypes = Obj.DISPLAY_NAME_PLURAL(objCn);
    const selectType = Obj.DISPLAY_NAME(selectCn);
    const selectTypes = Obj.DISPLAY_NAME_PLURAL(selectCn);
    const theSelectType = multiselect ? Obj.DISPLAY_NAME_PLURAL(selectCn) :
      Obj.DISPLAY_NAME(selectCn);
    log.trace("theSelectType: ", theSelectType);
    let title;
    let confTitle;
    let confMessage1 = 'Click "Add" to add ';
    let confMessage2 = 'Click "Cancel" if you don\'t want to add ';
    if (direction == "toObj") {
      confMessage2 += (selectedObjs && selectedObjs.length > 1) ?
        "them." : "it.";
      /*
      if (multiselect) {
        title = 'Add '+selectTypes+' To "'+objDisplayName+'"';
        confTitle = "Add Selected "+selectTypes;
        confMessage1 += 'the selected '+selectTypes+
          ' to "'+objDisplayName+'".';
      }
      else {
        title = 'Add '+selectType+' To "'+objDisplayName+'"';
        confTitle = "Add Selected "+selectType;
        confMessage1 += 'the selected '+selectType+
          ' to "'+objDisplayName+'".';
      }
      */
      title = 'Add '+theSelectType+' To "'+objDisplayName+'"';
      confTitle = "Add Selected "+theSelectType;
      confMessage1 += 'the selected '+theSelectType+
        ' to "'+objDisplayName+'".';
    }
    else if (direction == "toSelected") {
      confTitle = 'Add "'+objDisplayName+'"';
      confMessage2 += "it.";
      if (selectedObjs && selectedObjs.length > 1) {
        title = 'Add "'+objDisplayName+'" To '+selectTypes;
        confMessage1 += '"'+objDisplayName+
          '" to the selected '+selectTypes+".";
      }
      else {
        title = 'Add "'+objDisplayName+'" To '+selectType;
        confMessage1 += '"'+objDisplayName+
          '" to the selected '+selectType+".";
      }

      // User is selecting User's who will get Obj added
      // to their favorites list.
      if (objPropName == "favorites") {
        // Remove "." at end.
        confMessage1 = confMessage1.slice(0, -1);
        confMessage1 += " Favorites.";

        if (title) {
          title += " Favorites";
        }
      }
      else if (objPropName == "recommendations") {
        // Remove "." at end.
        //confMessage1 = confMessage1.slice(0, -1);
        //confMessage1 += " Recommendations.";

        confMessage1 = 'Recommend "'+objDisplayName+
          '" to selected '+theSelectType+".";
        confMessage2 = 'Click "Cancel" if you don\'t want to recommend it.';

        if (title) {
          title += " Recommendations";
          title = 'Recommend "'+objDisplayName+'" To Selected '+theSelectType;
        }
      }
    }
    else {
      // No direction given when selecting an object that
      // will be used for an object's property.  E.g. parkWaypoint.
      //log.bug("In SelectObjPanel.getLabels(), unhandled direction: ",
      //  direction);
    }

    if (props.title) {
      // If the caller gave us a title, use that.
      title = props.title;
    }
    // Worst case fallback.  Should never happen.
    title = title ? title :
      "Select Object"+(multiselect ? "s" : "");

    const labels = {
      title,
      confTitle,
      confMessage1,
      confMessage2,
    };
    return labels;
  }

  static createStateFromProps(props) {
    log.trace("SelectObjPanel.createStateFromProps(), props:", props);
    const {cache, curUser, objId, cA_GetObj} = props;
    log.trace("SelectObjPanel.createStateFromProps(), objId:", objId);

    // "obj" is the one object we are adding to all the selectedObjs,
    // OR we are adding all the selectedObjs to the one "obj" object.
    const obj = ObjUtils.get(curUser, objId,
      undefined, // collectionName
      cache, cA_GetObj);
    log.trace("SelectObjPanel.createStateFromProps(), obj:", obj);
    const newState = {
      obj,
    };
    return newState;
  }

  /**
   * Description from React documentation (Apr 14, 2018):
   * The new static getDerivedStateFromProps lifecycle is
   * invoked after a component is instantiated as well as
   * when it receives new props. It can return an object
   * to update state, or null to indicate that the new
   * props do not require any state updates.
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    log.trace("getDerivedStateFromProps(), nextProps:", nextProps);
    log.trace("getDerivedStateFromProps(), prevState:", prevState);
    let nextState = SelectObjPanel.createStateFromProps(nextProps);

    nextState = _.isEqual(prevState, nextState) ? null : nextState;
    log.trace("getDerivedStateFromProps(), return:", nextState);
    return nextState;
  }

  /*
  componentDidMount() {
    const state = SelectObjPanel.createStateFromProps(this.props);
    this.setState(state);
  }
  */

  render() {
    log.trace("SelectObjPanel.render() this.props:", this.props);
    const {curUserObj} = this.state;
    log.trace("SelectObjPanel.render(), userIds.length: ",
      curUserObj && curUserObj.userIds && curUserObj.userIds.length);
    const props = this.props;
    // task = add, selectParkWaypoint
    const {task, selectCn, objPropName} = props;
    let {multiselect} = props;
    multiselect = multiselect == "true";
    const {obj} = this.state;
    const {selectedObjs} = this.state;

    if (!curUserObj || curUserObj._id == DEFAULT_USER_ID) {
      //return <div>No user is logged in.</div>
      log.trace("SelectObjPanel.render(), No user is logged in.");
    }

    const labels = this.getLabels();
    const {title} = labels;

    let addButtonDisabled = selectedObjs.length < 1;
    let addButton;
    let addButtonLabel = objPropName == "recommendations" ?
      "Recommend Selected" : "Add Selected";
    if (multiselect) {
      addButton = <Button primary disabled={addButtonDisabled}
          onClick={this.handleClickAdd}>
          {addButtonLabel}
        </Button>
    }
    return (
      <div>
        <ObjsPanel title={title}
          collectionName={selectCn}
          selectable={true}
          multiselect={multiselect}
          onSelect={this.handleSelect}
          onBeforeBack={(goBackFunc)=>this.handleBeforeBack(goBackFunc)}
          {...props} />
        <div className="stackable">
          {addButton}
          <Button
            onClick={this.handleClickCancel}>
            Cancel
          </Button>
          </div>
      </div>
    );
  }
}

export default SelectObjPanel;
