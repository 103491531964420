import {log} from 'concierge-common';
import {DEFAULT_USER_ID} from '../../global-constants';
import {isEqualIds} from '../../utils/string-utils';


class RedactUtils {

  /**
   * Return true if obj.visibility is equal to passed
   * in visibility.  Default no value to isPublic.
   */
  static isVisibility(obj, visibility) {
    // Default to "isPublic" if no value currently.
    const objVisibility = obj && obj.visibility ?
      obj.visibility : "isPublic";
    return obj.visibility == visibility;
  }

  static isPrivate(obj) {
    return RedactUtils.isVisibility(obj, "isPrivate");
  }

  static isProtected(obj) {
    return RedactUtils.isVisibility(obj, "isProtected");
  }

  /**
   * NOTE: An object which does not have a visibility.visibility
   * explicitly defined is considered public by default.
   * NOTE: A null/undefined obj is public.
   */
  static isPublic(obj) {
    return (!obj || !obj.visibility) ||
      RedactUtils.isVisibility(obj, "isPublic");
  }

  /**
   * @param {Object} curUser - An object which might be a User
   * object, or might be just an object with an _id property.
   */
  static isSignedIn(curUser) {
    if (curUser && curUser.token) {
      if (typeof curUser.token == "string") {
        curUser.token = curUser.token.trim();
        curUser.token = curUser.token == "null" ||
          curUser.token == "undefined" ||
          curUser.token == "" ?
          null :
          curUser.token;
      }
    }
    return(!!(curUser && curUser.token));
  }

  /**
   * Returns true if curUserObj is owner of "obj", or curUserObj
   * has admin priviledge.  (Has role admin or root.)
   */
  static isOwnerOrAdmin(curUserObj, obj) {
    log.trace("RedactUtils.isOwnerOrAdmin()");
    if (curUserObj == null) {
      return false;
    }
    log.trace("RedactUtils.isOwnerOrAdmin(), curUserObj:",
      curUserObj);
    log.trace("RedactUtils.isOwnerOrAdmin(), obj:", obj);

    // When this function is called on the server side, the
    // "id" properties are not necessarily String objects or
    // string literals.  They can be Mogoose "ObjectID" objects.
    // Because of that, the operator "==" will not cast them
    // to Strings.  Which means two ids, that would be "equal"
    // if they were both represented as strings, will not be
    // equal as far as the "==" operator is concerned.
    // We fix this by explicitly casting them to strings.
    //const userId = ""+curUserObj._id;
    //const ownerId = ""+this.owner;
    const userId = curUserObj._id;
    const ownerId = obj.owner;
    log.trace("userId:", userId);
    log.trace("ownerId:", ownerId);

    if (isEqualIds(obj._id, userId)) {
      return true;
    }
    if (isEqualIds(ownerId, userId)) {
      // The "obj" is owned by curUserObj.
      return true;
    }
    if (curUserObj.hasAdminPriv()) {
      return true;
    }

    return false;
  }

  /**
   * Returns true if curUserObj is owner of "obj", or curUserObj
   * has admin priviledge.  (Has role admin or root.)
   *
   * NOTE: Also returns true if "obj" does not have an owner.
   * I.e. obj.owner is the null/DEFAULT_USER_ID.
   */
  static isOwnerOrAdminOrOwnerless(curUserObj, obj) {
  debugger;
    log.trace("RedactUtils.isOwnerOrAdminOrOwnerless(), curUserObj:",
      curUserObj);
    log.trace("RedactUtils.isOwnerOrAdminOrOwnerless(), obj:", obj);

    if (RedactUtils.isOwnerOrAdmin(curUserObj, obj)) {
      return true;
    }

    // When this function is called on the server side, the
    // "id" properties are not necessarily String objects or
    // string literals.  They can be Mogoose "ObjectID" objects.
    // Because of that, the operator "==" will not cast them
    // to Strings.  Which means two ids, that would be "equal"
    // if they were both represented as strings, will not be
    // equal as far as the "==" operator is concerned.
    // We fix this by explicitly casting them to strings.
    //const userId = ""+curUserObj._id;
    //const ownerId = ""+this.owner;
    const ownerId = obj.owner;
    log.trace("ownerId:", ownerId);

    if (ownerId == null ||
        isEqualIds(ownerId, DEFAULT_USER_ID)) {
      // The "obj" is ownerless.
      return true;
    }
    return false;
  }
}

export default RedactUtils;
