/**
 */
import React from 'react';
import {Card, Icon, Button} from 'semantic-ui-react';
import {RIEInput} from 'riek';
import {log} from 'concierge-common';

class MyCardContent extends React.Component {

  /**
   * Called by the RIEInput once the field looses focus.
   * How is this different from handleAfterFinish?
   */
  handleChange = (task) => {
    log.trace("handleChange, task:", task);
    this.setState({...task});
    const {change} = this.props;
    change({...task});
  }
  handleValidationFail = (result, value, cancelEditing) => {
    log.trace("handleValidationFail(), value:", value);
    const {handleValidationFail} = this.props;
    handleValidationFail(result, value/*, cancelEditing*/);
    cancelEditing();
  }

  constructor(props) {
    super(props);
    const {text} = props;
    this.state = {text};
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const {text} = newProps;
    this.setState({text});
  }

  /**
   * Description from React documentation (Apr 14, 2018):
   * The new static getDerivedStateFromProps lifecycle is
   * invoked after a component is instantiated as well as
   * when it receives new props. It can return an object
   * to update state, or null to indicate that the new
   * props do not require any state updates.
   */
  /*
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.text == prevState.text) {
      return null;
    }
    return {text: nextProps.text};
  }
  */

  render() {
    log.trace("MyCardContent.render() this.props:", this.props);
    const props = this.props;

    let {iconName, iconLink, size, className, editable,
      propName, text, validate, inputType,
      endIconName, endIconLabel, endIconOnClick, maxLength} = props;
    size = size ? size : "large";

    // className for "outer" Card.Content component.
    className = className ? ("my-card-content "+className) : "my-card-content";

    // <input> type attribute for "inner" RIEInput component,
    // if it is used, i.e. editable==true.
    // Possible values include: "text", "password", "number", "time".
    const type = inputType;
    const editProps = {type, maxLength};
    log.trace("editProps:", editProps);

    // Don't show this component at all if the text is null.
    // I.e. this component won't take up any space and its
    // faint divider line won't be shown either.
    //
    // If text is "" (the empty string) we will show this
    // component's icon with a blank area next to the icon.
    //this.state.text = text;
    if (text == null) {
      return null;
    }

    let textComponent;
    if (editable) {
      textComponent = <RIEInput editProps={editProps}
        value={this.state.text} change={this.handleChange}
        handleValidationFail={this.handleValidationFail}
        propName={propName} validate={validate}
        classInvalid="invalid" className="ellipsis rie-input editable" />
    }
    else {
      textComponent = <span className="ellipsis">{this.state.text}</span>
    }

    let iconComponent;
    if (iconLink) {
      iconComponent =
        <a href={iconLink} target="_newtab" className="ellipsis">
          <Icon name={iconName} size={size} />
        </a>
    }
    else {
      iconComponent = <Icon name={iconName} size={size} />
    }

    return(
      <Card.Content extra className={className}>
        {iconComponent}
        {textComponent}
        {endIconName &&
          <div className="end-icon-container">
            <div className="width-enforcer">{endIconLabel}</div>
            <Button animated='fade' onClick={endIconOnClick} className="superbasic">
              <Button.Content hidden>{endIconLabel}</Button.Content>
              <Button.Content visible>
                <Icon name={endIconName} size={size} />
              </Button.Content>
            </Button>
          </div>
        }
      </Card.Content>
    );
  };
}

export default MyCardContent;
