// babel-polyfill is needed so we can use async/await in our application.
// We include it here, before the rest of the code executes.
import 'babel-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Route, Redirect, Switch} from 'react-router-dom';

import 'semantic-ui-css/semantic.min.css';
// package.json line for the above   "semantic-ui-css": "^2.4.1",
import {Provider} from 'react-redux';
//import {connect} from 'react-redux';
//import Auth0Provider from './components/utils/auth0/contexts/auth0-provider';
import {log} from 'concierge-common';
import App from './containers/app';
//import Auth0ContextWrapper from './components/auth0-context-wrapper';
//import UI from './db/models/ui';
import store from './store';

// Below serviceWorker used by RxDB and Auth0.
import * as serviceWorker from './serviceWorker';
// Above serviceWorker used by RxDB and Auth0.

import {cA_SwSetInstalled} from './actions/service-worker';
import {cA_SwPossiblyActivateUpdate} from './actions/service-worker';

// Google Firebase Stuff Below
import firebase from 'firebase/app';
import /*thisNotUsed1 from*/ 'firebase/auth';
import /*thisNotUsed2 from*/ 'firebase/storage';
const config = {
  apiKey: "AIzaSyD9vudCWlqeIahgXJNTPuotxMHtCDfPwt8",
  authDomain: "imagestorage-85cfa.firebaseapp.com",
  databaseURL: "https://imagestorage-85cfa.firebaseio.com",
  projectId: "imagestorage-85cfa",
  storageBucket: "imagestorage-85cfa.appspot.com",
  messagingSenderId: "461199366635"
};
firebase.initializeApp(config);
// Google Firebase Stuff Above

// This line, and the call to registerServiceWorker() below are commented out.
//import registerServiceWorker from './registerServiceWorker';

const rootContainer = document.querySelector('.root-container');
const rootClassname = process.env.REACT_APP_ROOT_CONTAINER_CLASSNAME;
log.info("process.env:", process.env);
log.trace("rootClassname:", rootClassname);
if (rootClassname) {
  rootContainer.className += " "+rootClassname;
}

/*
class RenderAuth0 extends React.Component {
  render() {
    log.trace("props.dbState: ", this.props.dbState);
    const {dbState, children} = this.props;
    if (dbState == UI.DB_STATE_STARTED) {
      return (
        <Auth0Provider>
          {children}
        </Auth0Provider>
      );
    }
    else {
      return (
        <>
          {children}
        </>
      );
    }
  }
}

function mapStateToProps(state) {
  return({
    dbState:state.ui.dbState
  });
}
const RenderAuth0Con = connect(mapStateToProps, null)(RenderAuth0);
*/
    //<Auth0Provider dbState={store && store.getState() && store.getState().ui && store.getState().ui.dbState}>

//              '/selectWay', '/selectAct', '/selectItn',

ReactDOM.render(
  <Provider store={store}>
      <BrowserRouter>
        <div>
          <Switch>
            <Route exact path={
              ['/', '/home', '/contact', '/about', '/terms',
              '/obj', '/objs', '/usage', '/anys', '/objProp',
              '/locationMap', '/createMessage', '/viewMessages',
              '/imageUpload', '/imageCrop', '/selectObj',
              '/map', '/urlProp',
              '/signedIn', '/signedOut',
              ]} component={App} />
            <Redirect from='*' to='/home' />
          </Switch>
        </div>
      </BrowserRouter>
  </Provider>
  , rootContainer);
/*
          <Route exact path='/signUp' component={App} />
          <Route exact path='/signIn' component={App} />
*/
// This line, and the import/require of registerServiceWorker above are
// commented out.  I did not use it originally, and now with the adding
// of RxDB in May 2020 I am using an updated version.
//registerServiceWorker();

// Service worker configuration added Oct 2020 in order to have the
// app update itself when the code has changed.  If we don't do this
// the service worker will just keep giving the browser the old code
// when requests are made by the app.
const swConfig = {
  onInstalled: reg => {
    store.dispatch(cA_SwSetInstalled(reg));
  },
  onUpdateFound: reg => {
    store.dispatch(cA_SwPossiblyActivateUpdate(reg));
  } 
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
serviceWorker.register(swConfig);

