// This module defines the Obj *container*.
// NOT the Obj *component*.
import {connect} from 'react-redux';

// Import the ObjComponent from which this ObjContainer is created.
import ObjComponent from '../components/obj';

// Import action creators that this component will use.
import {cA_History} from '../actions/ui';
import {cA_SetError} from '../actions/error';
import {cA_GetObj} from '../client-db/api/obj-utils';
import {cA_Notify} from '../actions/error';
import {cA_MultiAction} from '../actions/ui';
import {cA_Confirmation} from '../actions/ui';
import {cA_UsageConfirmation} from '../actions/ui';

function mapStateToProps(state) {
  return({
    curUser:state.curUser,
    cache:state.cache,
  });
}
const mapDispatchToProps = {cA_SetError, cA_Notify, cA_History,
  cA_MultiAction, cA_Confirmation, cA_UsageConfirmation, cA_GetObj};
const ObjContainer = connect(mapStateToProps, mapDispatchToProps)
  (ObjComponent);
export default ObjContainer;
