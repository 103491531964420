import React from 'react';
import {Header, Button} from 'semantic-ui-react';
import {log} from 'concierge-common';
import {SW_VERSION} from '../global-constants';
//import {APP_VERSION} from '../global-constants';


class HomePanel extends React.Component {

  render() {
    log.trace("HomePanel.render() this.props:", this.props);
    const {cA_SwCheckForUpdate, swState} = this.props;
    const {checkingForUpdate:loading, swRegistration} = swState;

    return (
      <div className="home-panel">
        <Header as='h2'>Home Panel</Header>
        <p>
        SW_VERSION: {SW_VERSION}
        </p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sagittis massa quis urna lacinia scelerisque non id erat. Quisque elementum hendrerit finibus. Donec suscipit consequat ipsum nec aliquet. In hac habitasse platea dictumst. Vestibulum dui elit, consequat at quam quis, congue viverra orci. Pellentesque imperdiet sapien lectus, non blandit metus tincidunt nec. Aliquam maximus fringilla tortor, tincidunt egestas lectus. Vestibulum egestas libero sit amet risus rutrum, eu imperdiet magna mollis. Donec risus dui, bibendum nec nisl et, lacinia porttitor nulla. Donec turpis orci, egestas a purus id, ornare cursus eros. Nulla ac augue aliquam, volutpat felis eu, venenatis metus. Aliquam viverra posuere nunc, in placerat sapien mollis laoreet. Nam vitae sem viverra, semper purus vitae, commodo erat. Maecenas eu leo leo.
        </p>
        <p>
Duis posuere vulputate sem in sodales. Nullam placerat tortor eget egestas laoreet. Vivamus eros justo, congue id eros a, aliquam convallis nibh. Suspendisse posuere turpis at felis finibus mollis. Etiam sagittis dolor ac nibh pretium, quis lobortis felis rutrum. In quis tellus ex. Vestibulum tempus in velit quis semper.
        </p>
        <p>
Ut blandit enim eros, id convallis erat hendrerit sed. Donec volutpat leo et lacus semper, quis luctus ligula convallis. Sed posuere sapien non erat iaculis malesuada. Morbi eros massa, euismod nec maximus vel, hendrerit eu dolor. Integer id augue porta, tristique justo sit amet, tempor ex. Praesent auctor molestie est. Nam et pellentesque tortor. Fusce arcu eros, volutpat in placerat sit amet, scelerisque ut tellus. Morbi sagittis dapibus lacus, et ornare magna ultrices id. Mauris sit amet finibus nibh.
        </p>
        {/*
        <p>
        APP_VERSION: {APP_VERSION}
        </p>
        */}
        <p>
          <Button primary onClick={()=>cA_SwCheckForUpdate(swRegistration)}
            loading={loading}>
            Check For Update
          </Button>
        </p>
      </div>
    );
  }
}

export default HomePanel;
