import _ from 'lodash';
import React from 'react';
import {Button, Image, Icon, Popup} from 'semantic-ui-react';
import {log} from 'concierge-common';
import {ANYS} from '../global-constants';
import ObjUtils from '../client-db/api/obj-utils';
import ActionUtils from './utils/action-utils';
import Obj from '../client-db/api/obj';
import MyHeader from '../containers/my-header';
//import Layout from './objects-layout';
import Layout from './masonry-layout';
import FilterAndSortButton from '../containers/filter-and-sort-button';
import {add, toggleFilterAndSortVisibility} from './utils/obj-actions';
import {addSelectedUsersToUserIds} from './utils/obj-actions';
//import LocationUtils from './utils/location-utils';
import FilterSort from '../utils/filter-sort';


class AnysPanel extends React.Component {

  createRightButton = () => {
    log.trace("AnysPanel.createHeader(), props: ", this.props);
    const {props, state} = this;
    let {objPropName, title/*, cA_History*/} = props;
    const {obj, curUserObj, objs} = state;

    let rightButton = undefined;
    if (objPropName == "userIds" &&
        (obj.edit == true || curUserObj.hasAdminPriv())) {
      // The curUserObj can add users in addition to being
      // able to filter/sort.
      // NOTE:  We are assuming if the curUser can't edit the
      // userIds list, the curUser can't view it either.
      let actions = []; 
      add(addSelectedUsersToUserIds(obj, curUserObj), actions);
      //add(createObj(collectionName, curUserObj, cA_History), actions);
      // Display page to allow curUserObj to select users and add
      // them user.userIds.
      // NOTE: curUserObj === user if a user is editing themselves.
      // If curUserObj !== user, an admin is editing another user.
      add(toggleFilterAndSortVisibility(props,
        (e, choice, propToEdit)=>alert("TODO: Write handleChangeFilter"),
        //this.sort),
        (e, choice, propToEdit)=>alert("TODO: Write handleChangeSort")),
        actions);
      actions = ActionUtils.sortActions(actions);
      title = title ? title : "Users Actions";
      const multiAction = ActionUtils.createMultiAction(props, actions, title);

      rightButton =
        <Button className="actions-button" size="huge" primary
          onClick={multiAction.dispatch}
        >
          <Icon name="ellipsis vertical" />
        </Button>
    }
    else {
      rightButton = <FilterAndSortButton collectionName={ANYS} />
    }

    return rightButton;
  }

  createHeader() {
    log.trace("AnysPanel.createHeader(), props: ", this.props);
    const {curUser, cA_History, onBeforeBack, shellType} = this.props;
    let {title} = this.props;
    title = title ? title : "Selected Objects";

    const rightButton = this.createRightButton();

    const myHeaderProps = {
      hasBackButton:true,
      onBeforeBack,
      title,
      rightButton,
      cA_History,
      shellType,
      collectionName:ANYS,
    };

    return <MyHeader {...myHeaderProps} />
  }

  /*
  componentDidMount() {
    const {cA_Notify, cA_Confirmation} = this.props;
    LocationUtils.possiblyInitLocationServices(cA_Notify,
      cA_Confirmation);
  }
  */
  static createStateFromProps(props) {
    log.trace("AnysPanel.createStateFromProps() props:", props);
    const {objId, objPropName, cache, curUser, cA_GetObj} = props;

    // E.g. objId is the ID of a user whose property values we are
    // going to show.

    const obj = ObjUtils.get(curUser, objId, undefined,
      cache, cA_GetObj);
    const curUserObj = ObjUtils.getUser(curUser, curUser._id,
      cache, cA_GetObj);

    // Not get the specified property value from the obj.
    // E.g. if obj is a User object, we might be getting the
    // obj.userIds list or obj.favorites.
    //
    // NOTE: Eventually, we will need more URL parameters
    // in order to specify how the objPropName should be
    // unpacked, OR perhaps the parameter specifies the name
    // of a utility function we should call to unpack the
    // the desired value(s) from the obj.
    let objIds = [];
    if (obj) {
      switch(objPropName) {

        case "followers":
          if (curUserObj && obj) {
            objIds = ObjUtils.getFollowerIds(curUserObj,
              obj, cache, cA_GetObj);
          }
          break;

        default:  // userIds, guides, favorites, recommendations
          objIds = _.get(obj, objPropName, []);
      }
    }
    objIds = objIds && Array.isArray(objIds) ? objIds : [];

    log.trace("objIds: ", objIds);

    // Convert the objIds into ObjC objects.
    // Filter out "default" objects and non-viewable objects.
    let objs = [];
    objIds.forEach(objId => {
      const obj = ObjUtils.get(curUserObj, objId, undefined,
        cache, cA_GetObj);
      if (!obj) {
        log.bug("In AnysPanel.createStateFromProps, objId not found: ",
          objId);
        debugger;
        return;
      }
      if (Obj.isDefaultObj(obj)) {
        return;
      }
      /* Not needed yet.
      if (showOnly == "viewable" && !obj.view) {
        return;
      }
      if (showOnly == "editable" && !obj.edit) {
        return;
      }
      if (showOnly == "deletable" && !obj.deleteObj) {
        return;
      }
      */
      objs.push(obj);
    });

    // Filter and sort objs array.
    // NOTE: FilterSort.filter() returns a new array.
    // FilterSort.sort sorts the passed in array "in place".
    const {filterAndSort} = props;
    const filterProp = filterAndSort && filterAndSort.anys &&
      filterAndSort.anys.filter;
    if (filterProp) {
      objs = FilterSort.filter(filterProp, objs);
    }
    const sortProp = filterAndSort && filterAndSort.anys &&
      filterAndSort.anys.sort;
    if (sortProp) {
      FilterSort.sort(sortProp, objs);
    }

    // NOTE: objs might really be called "desiredObj(s)" or
    // specifiedObjs?
    const state = {curUserObj, obj, objs};
    return state;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    log.trace("AnysPanel.getDerivedStateFromProps() nextProps:", nextProps);
    const newState = AnysPanel.createStateFromProps(nextProps);
    if (_.isEqual(prevState, newState)) {
      return null;
    }
    else {
      return newState;
    }
  }

  constructor(props) {
    super(props);
    this.state = AnysPanel.createStateFromProps(props);
  }

  render() {
    const {props, state} = this;
    log.trace("AnysPanel.render() props:", props);
    log.trace("AnysPanel.render() state:", state);
    const {collectionName, cache, busy, filterAndSortVisible,
      objPropName} = props;
    const {curUserObj, obj, objs} = state;
    
    const header = this.createHeader();

    const objsType = collectionName ? 
      Obj.DISPLAY_NAME_PLURAL(collectionName) :
      "Objects";
    const emptyMessage = busy ?
      "Looking for "+objsType+"..." :
      collectionName ? "No "+objsType+" found" :
      "Nothing found";

    const cardContentsProps = {
      parentObj:obj,
      objPropName,
    };

    return (
      <div className="cards-panel">
        {header}
        <Layout cardContentsProps={cardContentsProps} objects={objs}
          emptyMessage={emptyMessage}
          filterAndSortVisible={filterAndSortVisible} />
      </div>
    );
  }
}

export default AnysPanel;
