// This module defines the ObjsPanel *container*.
// NOT the ObjsPanel *component*.

import {connect} from 'react-redux';

// Import the ObjsPanelComponent from which this ObjsPanelContainer is created.
import ObjsPanelComponent from '../components/objs-panel';
import {cA_History} from '../actions/ui';
import {cA_PropsEditor} from '../actions/ui';
import {cA_SetFilterAndSortVisibility} from '../actions/ui';
import {cA_SetFilterAndSort} from '../actions/ui';
import {cA_SetError} from '../actions/error';
import {cA_GetObj} from '../client-db/api/obj-utils';


function mapStateToProps(state) {
  return({
    curUser:state.curUser,
    // TODO: Remove the three obj lists being passed in and
    // just use objs.
    // The filtered/sorted list of Objs we will display.
    //objs:state.objs,
    users:state.users,
    ways:state.ways,
    acts:state.acts,
    itns:state.itns,
    cache:state.cache,
    shellType:state.ui.shellType,
    filterAndSort:state.ui.filterAndSort,
    //filterAndSortVisible:state.ui.filterAndSort.visible,
    busy:!!state.ui.progressBar.timer,
  });
}

const mapDispatchToProps = {cA_History, cA_SetError, cA_GetObj,
  cA_PropsEditor, cA_SetFilterAndSort, cA_SetFilterAndSortVisibility};
const ObjsPanelContainer = connect(mapStateToProps, mapDispatchToProps)
  (ObjsPanelComponent);
export default ObjsPanelContainer;
