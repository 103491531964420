import {log} from 'concierge-common';
import * as ACTION_TYPES from '../actions/types';
import {cA_SetError} from '../actions/error';
import ObjUtils from './utils/obj-utils';


/**
 * This reducer manages the value of the application's
 * store.state.anys property.
 *
 * @param {anys} anys The current store.state.anys object.
 * We should never modify this value.
 *
 * @param {Object} action The current action to be performed.
 * @param {String} action.type This tells us "what" we need to do.
 * @param {Object} action.payload This is the data that we will use to
 * complete the action.
 * @param {Object} action.payload.anys The new values that we are
 * going to use to alter the state.anys property.
 */
function anysReducer(anys = ObjUtils.EMPTY_ANYS, action) {
  log.trace("anysReducer("+action.type+") anys:", anys);
  log.trace("anysReducer(), payload:", action.payload);
  log.trace("anysReducer(), action:", action);

  const {payload = {}} = action;
  if (!payload) {
    // Redux "interal" action types such as @@redux/INIT
    // don't have payloads, and we should ignore them
    // anyway.  But, "_P" pending actions have no payload either,
    // so we can't ignore actions with empty payloads.
    return anys;
  }

  let newAnys = anys;
  switch(action.type) {

    case ACTION_TYPES.SET_ANYS: {
      log.trace("SET_ANYS anysReducer(), action:", action);
      if (payload.error) {
        action.asyncDispatch(cA_SetError(payload.error));
        return anys;
      }
      newAnys = Object.assign({}, anys, payload.anys);
      return newAnys;
    }

    case ACTION_TYPES.SIGN_OUT_S:
      if (payload.error) {
        return anys;
      }
      newAnys = ObjUtils.EMPTY_ANYS;
      return newAnys;

    default:
      log.trace("anys-reducer default");
      return anys;
  }
};

export default anysReducer;
