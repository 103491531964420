import {log} from 'concierge-common';
import * as ACTION_TYPES from '../actions/types';
import {cA_Notify} from '../actions/error';

/**
 * This reducer manages the value of the application's store
 * state.error property.
 *
 * @param {error} error The current store state.error object.
 * We should never modify this value.
 *
 * @param {Object} action The current action to be performed.
 * @param {String} action.type This tells us "what" we need to do.
 * @param {Object} action.payload This is the data that we will use to
 * complete the action.
 * @param {Object} action.payload.error The object that we are
 * going to act upon.
 */
function errorReducer(error = null, action) {
  log.trace("errorReducer("+action.type+") error:", error);
  log.trace("payload:", action.payload);

  const {payload} = action;
  if (!payload) {
    // Redux "interal" action types such as @@redux/INIT
    // don't have payloads, and we should ignore them
    // anyway.
    return error;
  }
  log.trace("payload.error.time: ", payload.error && payload.error.time);
  if (action.type == ACTION_TYPES.DO_NOTHING) {
    return error;
  }

  // payload.error of null or false mean the same thing.
  if (action.type == ACTION_TYPES.SET_ERROR && !payload.error) {
    return payload.error;
    // TODO: Perhaps dismiss notification popup in here also?
  }

  if (payload.error && error) {
    // NOTE: This is a bit of a hack.
    // Basically, if it is the same error,
    // AND
    // the previous error is only a few seconds old,
    // don't display it again.
    if (payload.error.msg == error.msg &&
        ((payload.error.handle && payload.error.handle.notify) ==
         (error.handle && error.handle.notify)) &&
        payload.error.handle.report == error.handle.report &&
        ((payload.error.time == error.time) || 
         ((Date.now() - error.time) < 2000))) {
      // No change in the error, so just return the original error. 
      return error;
    }
  }

  /*
  if (action.type == ACTION_TYPES.SET_ERROR) {
    return payload.error;
  }
  */

  // For just UI actions, e.g. show/hide footer or sidebar,
  // we don't update the error.  Because, updating the error
  // would mean clearing the error in the case of these UI
  // actions which never cause errors.
  if (payload.error && // and is NOT a UI-only action...
      action.type != ACTION_TYPES.SET_SIDEBAR_VISIBILITY &&
      action.type != ACTION_TYPES.SET_FOOTER_VISIBILITY) {

    if (payload.error.handle && payload.error.handle.notify) {
      log.trace("Dispatch a notify with error:", payload.error);
      action.asyncDispatch(cA_Notify(payload.error));
    }

    // Update the store's state.error property with the
    // new value in payload.error.
    return payload.error;
  }
  else {
    // Just leave the store's state.error property alone.
    return error;
  }
};

export default errorReducer;
