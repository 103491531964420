import React from 'react';
import {Container, Header} from 'semantic-ui-react';
//import {log} from 'concierge-common';


class TermsConditionsPanel extends React.Component {

  render() {
    return (
      <Container text className="about-panel">
        <Header as='h2'>Terms And Conditions</Header>
        <p>
          You acknowledge that the use of this website and/or application
          involves any or all of the following risks to you: financial loss,
          injury, and/or death.
        </p>
      </Container>
    );
  }
}

export default TermsConditionsPanel;
