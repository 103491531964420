/**
 * This contains the functions that listen for database changes.
 * The functions update the application's store.
 */
import moment from 'moment';
import {log, MyError} from 'concierge-common';
import store from '../store';
import {USERS} from '../global-constants';
import ImageUtils from '../utils/image-utils';
import * as ACTION_TYPES from '../actions/types';
import {cA_SetError} from '../actions/error';
import {cA_UpdateCache, cA_DeleteCache} from '../actions/cache';
import LocalDB from './local-db';
import UserC from './api/user';
import ApiUtils from './api/api-utils';


class ChangeHandlers {

  static handleChangeReplicationState(replication) {
    log.trace("handleChangeReplicationState(), replication: ", replication);
    const payload = replication;
    const action = {
      type:ACTION_TYPES.SET_REPLICATION,
      payload,
    };
    log.trace("store.dispatch() action: ", action);
    store.dispatch(action);

    if (replication.change && replication.change.change) {
      const change = replication.change;
      log.trace("change: ", change);
      //const timeStr = moment().fromNow();
      const timeStr = moment().format('LTS'); // LT or LTS
      let msg = "Replication changed at "+timeStr+".";
      let severity = MyError.INFO;  // SUCCESS?
      let notify = false;
      if (change.change.ok) {
        if (change.direction == "pull") {
          msg = "We successfully received updates at "+timeStr+".";
        }
        else if (change.direction == "push") {
          msg = "We successfully sent our latest updates at "+timeStr+".";
        }
        else {
          // Should never happen?
          msg = "We successfully connected at "+timeStr+".";
        }
      }
      else {
        severity = MyError.WARNING;  // INFO?
        notify = true;
        msg = "We were unable to send/receive data at "+timeStr+".";
      }
      log.trace("msg: ", msg);
      const props = {msg, severity};
      //const error = MyError.createSubmitError(props);
      const error = MyError.createTransitoryError(props);
      error.handle.notify = notify;
      store.dispatch(cA_SetError(error));
    }
  }

  /*
  static getDeleteActionType(collectionName) {
    switch(collectionName) {
      case "users":
        return {type:ACTION_TYPES.DELETE_USER_IN_STORE_STATE};
      case "ways":
        return {type:ACTION_TYPES.DELETE_WAY_IN_STORE_STATE};
      default:
        const msg = "In ChangeHandlers.getDeleteActionType(), "+
          "collectionName "+collectionName+" not handled in "+
          "switch statement.";
        log.bug(msg);
        const error = MyError.createBugError({msg});
        debugger;
        return {type:ACTION_TYPES.SET_ERROR, payload:{error}};
    }
    // We should never get here.
  }
  */

  static handleDeleteObj(changedObj, curUser, collectionName) {
    //const objC = store.getState().cache.objs[changedObj._id];
    //log.trace("handleDeleteObj(), objC: ", objC);

    // Remove the object's _id from store.state.users.objIds,
    // and then remove object from the store.state.cache.objs
    //const action = ChangeHandlers.getDeleteActionType(collectionName);
    //action.payload = {objIds:changedObj._id};
    //log.trace("handleDeleteObj() dispatching action: ", action);

    const filterAndSort = store.getState().ui.filterAndSort;
    LocalDB.initStoreState(curUser, filterAndSort);

    store.dispatch(cA_DeleteCache(changedObj._id));

    // Remove any images associated with this object from
    // Firebase.  Currently only imageSrc and avatarSrc.
    log.trace("imageSrc: ", changedObj.imageSrc);
    log.trace("avatarSrc: ", changedObj.avatarSrc);
    // TODO: The function we call returns a promise,
    // so we could display a notifier after the image is deleted.
    ImageUtils.possiblyDeleteFromFirebase(changedObj.imageSrc);
    // avatarSrc defaults to imageSrc if no avatarSrc was
    // specified, so test for null and don't try to delete the
    // same image url twice.
    if (changedObj.avatarSrc &&
        (changedObj.avatarSrc != changedObj.imageSrc)) {
      log.trace("imageSrc: ", changedObj.imageSrc);
      log.trace("avatarSrc: ", changedObj.avatarSrc);
      ImageUtils.possiblyDeleteFromFirebase(changedObj.avatarSrc);
    }
  }

  static async handleUpdateObj(changedObj, curUser, collectionName) {

    // Remove any conflicts also.
    // NOTE: If we do this on every update, then we can remove
    // the call to removeConflictsCollection() from the
    // LocalDB.handleSyncCompleted().
    log.trace("changedObj: ", changedObj);
    log.trace("changedObj.deleted: ", changedObj.deleted);
    await LocalDB.removeConflictsObj(collectionName, changedObj._id);

    // Create the updated object that we will dispatch in the
    // action payload.
    // NOTE: If this is a DELETE operation, we are unnecessarily
    // creating an object even though it will be deleted.
    const objC = ApiUtils.objRxDBToObjC(changedObj,
      curUser, collectionName);

    //if (changedObj._id == curUser._id) {
    //  //debugger;
    //  objC.token = "someSortOfToken";
    //}

    store.dispatch(cA_UpdateCache(objC));

    // TODO: Also update permissions.  Need to reinit
    // all the collections if the curUser's role has changed,
    // an objects privacy has changed, another user's role
    // has changed, etc.
    //
    // If the waypoints are sorted by name, and the user
    // changes the name of a waypoint, then we need to reinit
    // the store.state.ways.  Same for others.
    //
    // Reinitializing everything is a blunt force approach.
    // I could be more clever and only reinitialize the store.state.???
    // that could have been affected by the update/change.
    log.todo("Don't reinitialize ALL the data if not necessary.");

    const filterAndSort = store.getState().ui.filterAndSort;
    LocalDB.initStoreState(curUser, filterAndSort);
  }

  static async handleInsertObj(changedObj, curUser, collectionName) {
    const {filterAndSort} = store.getState().ui;
    const objFilterAndSort = filterAndSort[collectionName];
    LocalDB.initStoreStateObjs(curUser, objFilterAndSort,
      collectionName);
  }

  /**
   * If the change event is due to a user being updated/changed,
   * and the user being changed is the current user, return the
   * updated version of the user.
   */
  static getCurUser(changeEvent) {
    const {operation, collectionName} = changeEvent;
    const {rxDocument, documentData, previousData} = changeEvent;
    const changedObj = rxDocument ? rxDocument : documentData;

    let curUser = store.getState().curUser;

    // Handle us being called when no user is currently logged in.
    if (!curUser || !curUser._id) {
      if (collectionName == USERS && operation == "INSERT") {
        // This is a user signing up.  Is that really true???
        log.info("changedObj is new user signing up?  changedObj: ",
          changedObj);
        curUser = new UserC(changedObj);
        log.info("ChangeHandlers.getCurUser(), new curUser: ", curUser);
      }
      else {
        curUser = UserC.DEFAULT_USER;
      }
    }
    else {
      // Handle the special case of an UPDATE event where the
      // object being updated is the current user.
      // NOTE: I don't like this special case.
      if ((collectionName == USERS) &&
          (curUser && (curUser._id == changedObj._id))) {
        curUser = new UserC(changedObj);
      }
    }
    return curUser;
  }

  /**
   * The browser's local RxDB database has been changed.
   * E.g. a new waypoint was added.
   * Dispatch an action that will add the waypoint to the
   * application's store state.ways array.
   */
  static handleChangeObj(changeEvent) {
    log.trace("handleChangeObj(), changeEvent: ", changeEvent);
    // operation = INSERT, UPDATE, DELETE
    // collectionName = users, ways, acts, itns
    const {operation, collectionName} = changeEvent;
    const {rxDocument, documentData, previousData} = changeEvent;
    log.trace("handleChangeObj(), rxDocument: ", rxDocument);
    if (rxDocument) {
      log.trace("handleChangeObj(), rxDocument.deleted: ", rxDocument.deleted);
    }
    if (documentData) {
      log.trace("handleChangeObj(), documentData.deleted: ",
        documentData.deleted);
    }
    if (previousData) {
      log.trace("handleChangeObj(), previousData.deleted: ",
        previousData.deleted);
    }
    log.trace("handleChangeObj(), documentData: ", documentData);
    log.trace("handleChangeObj(), previousData: ", previousData);

    // propName = user, way, act, itn
    const propName = collectionName ? collectionName.slice(0, -1) : "";

    // When "this" browser does the delete, handleChangeObj()
    // gets called twice.  The first RxChangeEvent has
    // RxChangeEvent.rxDocument defined and
    // RxChangeEvent.documentData defined.
    // The second time rxDocument is not defined but
    // documentData is always defined.
    //
    // When the operation was done by another browser, it is
    // called once.  RxChangeEvent.rxDocument is undefined,
    // but RxChangeEvent.documentData is always defined.
    //
    // NOTE: If the operation is an update, it appears that
    // the browser who did the update gets rxDocument
    // documentData, and previousData.
    // The other browsers get documentData but not rxDocument
    // or previousData.
    //
    if (operation == "DELETE" && rxDocument) {
      // Ignore it.  Handle the second delete event.
      return;
    }
    const changedObj = rxDocument ? rxDocument : documentData;
    log.trace("handleChangeObj(), changedObj: ", changedObj);
    log.trace("handleChangeObj(), changedObj._id: ", changedObj._id);

    const curUser = ChangeHandlers.getCurUser(changeEvent);

    if (operation == "DELETE") {
      ChangeHandlers.handleDeleteObj(changedObj, curUser, collectionName);
      return;
    }
    else if (operation == "UPDATE") {
      ChangeHandlers.handleUpdateObj(changedObj, curUser, collectionName);
      return;
    }
    else if (operation == "INSERT") {
      ChangeHandlers.handleInsertObj(changedObj, curUser, collectionName);
      return;
    }

    log.bug("We should never get here.");

    let action = {};
    switch(operation) {
      default:
        const type = ACTION_TYPES.SET_ERROR;
        const msg = "In ChangeHandlers.handleChangeObj(), operation "+
          operation+" not handled in switch statement.";
        log.bug(msg);
        const payload = {error:MyError.createBugError({msg})};
        action = {type, payload};
    }

    if (action.type == ACTION_TYPES.SET_ERROR) {
      // There was an error, so dispatch the SET_ERROR action.
      store.dispatch(action);
    }
    
    // Create the inserted/updated UserC, WayC object that
    // we will dispatch in the action payload.
    // NOTE: If this is a DELETE operation, we are unnecessarily
    // creating an object even though it will be deleted.
    const objC = ApiUtils.objRxDBToObjC(changedObj, curUser, collectionName);
    const objs = [objC];
    action.payload = {objs};

    // Dispatch the add, edit, delete action.
    log.trace("dispatch action.type: ", action.type);
    store.dispatch(action);
  }
};

export default ChangeHandlers;
