// This module defines the Avatar *container*.
// NOT the Avatar *component*.
import {connect} from 'react-redux';

// Import the AvatarComponent from which this AvatarContainer is created.
import AvatarComponent from '../components/avatar';

// Import action creators that this component will use.
import {cA_SetError} from '../actions/error';
import {cA_MultiAction, cA_Confirmation} from '../actions/ui';
//import {cA_GetObj} from '../client-db/api/obj-utils';


function mapStateToProps(state) {
  return({
    curUser:state.curUser,
    cache:state.cache,
    device:state.ui.device,
  });
}

const mapDispatchToProps = {cA_MultiAction, cA_SetError,
  cA_Confirmation};
const AvatarContainer = connect(mapStateToProps, mapDispatchToProps)
  (AvatarComponent);
export default AvatarContainer;
