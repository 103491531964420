import React from 'react';
import {Button, Icon} from 'semantic-ui-react';
import {log, MyError} from 'concierge-common';
import store from '../../store';


class LocationUtils {

  static get SECOND() {return 1000};

  static get DEFAULT_LOCATION()
    {return {lat:-41, lng:173, accuracy:undefined}};

  static isDefaultLocation(latLng) {
    const {lat, lng, accuracy} = latLng;
    return LocationUtils.DEFAULT_LOCATION.lat == lat &&
           LocationUtils.DEFAULT_LOCATION.lng == lng &&
           LocationUtils.DEFAULT_LOCATION.accuracy == accuracy;
  }

  static getMostRecentLocation() {
    log.trace("Enter getMostRecentLocation(), LocationUtils.mostRecentLocation: ", LocationUtils.mostRecentLocation);
    return LocationUtils.mostRecentLocation || LocationUtils.DEFAULT_LOCATION;
  }
  static setMostRecentLocation(mostRecentLocation) {
    if (!mostRecentLocation) {
      return;
    }
    LocationUtils.mostRecentLocation = mostRecentLocation;
    if (!LocationUtils.mostRecentLocationInitialized) {
      log.trace("LocationUtils mostRecentLocation to:",
        LocationUtils.mostRecentLocation);
    }
    LocationUtils.mostRecentLocationInitialized = true;
  }

  /**
   * Called when we successfully get the user's location.
   *
   * @param {Position} pos - The position.
   */
  static success(pos, callback) {
    const {latitude:lat, longitude:lng, accuracy} = pos.coords;
    log.trace("Accuracy: "+accuracy+"(metres).");

    const latLng = {lat, lng, accuracy};
    log.trace("latLng:", latLng);
    log.trace("point:", LocationUtils.pointFromLatLng(latLng));
    LocationUtils.setMostRecentLocation(latLng);
    if (callback) {
      callback(latLng);
    }
  }

  /**
   * Called when we fail to get the user's location.
   *
   * @param {PositionError} err - The error.
   */
  static failure(err, callback) {
    /*
    switch(err.code) {
        case error.PERMISSION_DENIED:
            msg = "User denied the request for Geolocation."
            break;
        case error.POSITION_UNAVAILABLE:
            msg = "Location information is unavailable."
            break;
        case error.TIMEOUT:
            msg = "The request to get user location timed out."
            break;
        case error.UNKNOWN_ERROR:
            x.innerHTML = "An unknown error occurred."
            break;
    }
    */
    const msg = err.message ? err.message :
      err ? err : "Geolocation failed for an unknown reason.";
    const props = {msg};
    const error = MyError.createSubmitError(props);
    const latLng = {error};
    log.trace("latLng:", latLng);
    log.trace("point:", LocationUtils.pointFromLatLng(latLng));
    if (callback) {
      callback(latLng);
    }
  }

  static getCurrentLocation(callback) {

    // Allow use of cached position this millisecs old.
    let maximumAge = Infinity;
    let timeout = 15 * LocationUtils.SECOND;
    let enableHighAccuracy = false;

    // TODO: Get isMobile value properly.
    const isMobile = !!store.getState().ui.device.touch;
    if (isMobile) {
      maximumAge = 15 * LocationUtils.SECOND;
      timeout = 10 * LocationUtils.SECOND;
      enableHighAccuracy = true;
    }

    const options = {
      enableHighAccuracy,
      timeout,
      maximumAge,
    };

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (pos)=>LocationUtils.success(pos, callback),
        (err)=>LocationUtils.failure(err, callback),
        options);
    }
    else {
      const err = "Geolocation is not supported by this browser/device.";
      setTimeout(()=>LocationUtils.failure(err, callback), 0);
    }

    // TODO: Promisify all this code and have this function
    // return a Promise.
  } 

  static pointFromLatLng(latLng) {
    if (latLng.lat === null || latLng.lat === undefined ||
        latLng.lng === null || latLng.lng === undefined) {
      return null;
    }
    const point = {
      coordinates:[latLng.lng, latLng.lat],
    };
    return point;
  }

  static possiblyInitMostRecentLocation() {
    log.trace("Enter possiblyInitMostRecentLocation");
    if (!LocationUtils.mostRecentLocationInitialized) {
      LocationUtils.getCurrentLocation();
    }
  }

  static locationServicesPrompt(cA_Confirmation) {
    if (LocationUtils.locationServicesPromptNotified) {
      return;
    }
    LocationUtils.locationServicesPromptNotified = true;

    const title = "Location Services";
    const message1 = "This application will ask you for permission "+
      "to use your computer's geolocation/GPS services.";
    const message2 = "If you choose Block/Deny, then Navigation and "+
      "Waypoint features will not work.";
    const okButton =
      <Button positive key="okKey"
        onClick={()=>{
          LocationUtils.possiblyInitMostRecentLocation();
          cA_Confirmation();}}>
        <Icon name='checkmark' />
        Allow
      </Button>
    const cancelButton =
      <Button negative key="cancelKey"
        onClick={()=>cA_Confirmation()}>
        <Icon name='cancel' />
        Block
      </Button>

    cA_Confirmation(title, message1, message2,
      //okAction, okLabel, cancelAction, cancelLabel,
      null, null, null, null,
      null, null, null, okButton, cancelButton);
  }

  static locationServicesGranted(cA_Notify) {
    const notification = {
      title:"Location Services",
      message:"We have permission to use location services.",
      status:"success",
      dismissible:true,
      dismissAfter:4000
    };
    cA_Notify(notification);

    // Initialize "most recent location" if not already initialized.
    if (!LocationUtils.mostRecentLocationInitialized) {
      LocationUtils.getCurrentLocation();
    }
  }

  static locationServicesDenied(cA_Confirmation) {
    if (LocationUtils.locationServicesDeniedNotified) {
      return;
    }
    LocationUtils.locationServicesDeniedNotified = true;
    //alert('You have denied us geolocation permission, so Navigation and Waypoint features will not work.  If you want to change that, Google "enable location services chrome", (replacing "chrome" with your browser\'s name), to find out how to enable it.');
    const title = "Location Services";
    const message1 = 'You have denied us geolocation permission, '+
      'so Navigation and Waypoint features will not work.';
    const message2 = 'If you want to change that, Google '+
      '"enable location services chrome", (replacing "chrome" with your '+
      'browser\'s name), to find out how to enable it.';
    cA_Confirmation(title, message1, message2);
  }

  static initLocationServices(cA_Notify, cA_Confirmation) {
    if (navigator && navigator.permissions && navigator.permissions.query) {
      navigator.permissions.query({name:'geolocation'}).then(result=>{
          if (result.state == 'granted') {
            //alert("User has previously granted us geolocation permission.");
            LocationUtils.locationServicesGranted(cA_Notify);
          } else if (result.state == 'prompt') {
            LocationUtils.locationServicesPrompt(cA_Confirmation);
          } else if (result.state == 'denied') {
            // Tell user how to unblock us.
            LocationUtils.locationServicesDenied(cA_Confirmation);
          }
          result.onchange = ()=>{
            if (result.state == "granted") {
              //alert("Location services permission granted");
              LocationUtils.locationServicesGranted(cA_Notify);
            }
            else if (result.state == "prompt") {
              LocationUtils.locationServicesPrompt(cA_Confirmation);
            }
            else if (result.state == "denied") {
              LocationUtils.locationServicesDenied(cA_Confirmation);
            }
            else {
              alert("New geolocation permission. result.state: "+
                result.state);
            }
          }
        });
    }
    // Initialize "most recent location".
    //if (!LocationUtils.mostRecentLocationInitialized) {
    //  LocationUtils.getCurrentLocation();
    //}
  }

  static possiblyInitLocationServices(cA_Notify, cA_Confirmation) {
    if (LocationUtils.mostRecentLocationInitialized) {
      return;
    }
    if (!LocationUtils.weHaveNotifiedUser) {
      LocationUtils.initLocationServices(cA_Notify, cA_Confirmation);
    }
    LocationUtils.weHaveNotifiedUser = true;
  }

}

export default LocationUtils;
