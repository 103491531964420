import _ from 'lodash';
import {log, MyError} from 'concierge-common';
import {createErrorFromException} from './exception-handler';
import store from '../../store';
import {USERS, WAYS, ACTS, ITNS} from '../../global-constants';
import {cA_History} from '../../actions/ui';
import {cA_SetError} from '../../actions/error';
import {cA_UpdateCache} from '../../actions/cache';
import * as ACTION_TYPES from '../../actions/types';
import LocalDB from '../local-db';
import ObjC from './obj';
import UserC from './user';
import ObjUtils from './obj-utils';


class ApiUtils {

  //static getNewIdWay() {
  //  return ApiUtils.getNewId(WAYS);
  //}

  static getNewId(collectionName) {

    // Random 5 character string.  e.g. z7Yji, 8dChe, kQwVI
    const random = new Array(5).join().replace(/(.|$)/g, () => {
        return (
          (Math.random()*36)|0).toString(36)[Math.random() < 0.5 ?
            "toString" : "toUpperCase"]();
        }
    );

    // Number of milliseconds elapsed since January 1,
    // 1970 00:00:00 UTC.
    const time = Date.now();

    const id = collectionName+"_"+time+"_"+random;

    return id;  // e.g. ways_16006613793253_i7SvY
  }

  // This takes either collectionName or singular doc name.
  // E.g. pass in either "users" or "user".
  static getObjClass(propName) {
    switch(propName) {
      case "way":
      case "ways":
      case "act":
      case "acts":
      case "itn":
      case "itns":
        return ObjC;

      case "user":
        // Fall through
      case "users":
        return UserC;

      default:
        log.bug("ApiUtils.getObjClass(), Unknown propName:", propName);
        debugger;
    }
  }

  // This returns the singular of collectionName.
  // E.g. Return "user" for the "users" collection.
  static getDocName(collectionName) {
    if (!collectionName) {
      log.bug("ApiUtils.getDocName(), illegal collectionName:",
        collectionName);
      debugger;
      return "";
    }
    return collectionName.slice(0, -1);
  }

  /**
   *
   * Files that need to be changed when changing the structure of a
   * User object/schema:
   *
   *    contexts/auth0-provider.js
   *    db/api/utils/api-utils.js  dataRxDBToPayload()
   *    concierge-common/src/user.js  DEFAULT_PROPS
   *
   * @param {string} docName - "way", "act", "itn", "user".
   *
   * @param {string} collectionName - "ways", "acts", "itns", "users".
   *
   * @param objClass - The concierge-common class constructor.
   * E.g. Way, Act, Itn, User.
   */
  static dataRxDBToPayload(data, docName, collectionName, curUserObj) {
    log.trace("dataRxDBToPayload(), data:", data);

    const objC = ApiUtils.objRxDBToObjC(data, curUserObj, collectionName);
    const payload = {};
    if (!objC) {
      return payload;
    }

    if (objC) {
      payload.obj = objC;
      //payload.objC = objC;

      // e.g. payload.way = objC;
      // e.g. payload.act = objC;
      // TODO: Delete this when no longer needed?
      // In the future, it will not used for Way/Act/Itn
      // but will be used by User, I expect.
      payload[docName] = objC;
    }
    else {
      // TODO: display error.
      debugger;
    }

    // Only used by ways-reducer 2020 Aug 11
    payload.curUserId = curUserObj && curUserObj._id;

    return payload;
  }

  static objRxDBToObjC(objRxDB, curUserObj, collectionName) {
    log.trace("Enter objRxDBToObjC(), objRxDB: ", objRxDB);
    log.trace("Enter objRxDBToObjC(), objRxDB.created: ", objRxDB.created);
    log.trace("Enter objRxDBToObjC(), objRxDB._created: ", objRxDB._created);

    const objClass = ApiUtils.getObjClass(collectionName);
    if (!objClass) {
      log.bug("ApiUtils.objRxDBToObjC(), unhandled collectionName: ",
        collectionName);
      debugger;
    }

    if (!objRxDB) {
      //return ObjUtils.getDefaultObj(collectionName);
      return ObjC.DEFAULT(collectionName);
    }

    let objProps = null;
    let messagesLastReceived;
    let messagesLastRead;
    switch(collectionName) {

      case LocalDB.USERS:
        objProps = ObjC.rxdbPropsToObjCProps(objRxDB, collectionName);
        messagesLastReceived = objRxDB.messagesLastReceived ?
           objRxDB.messagesLastReceived :
           Date.now(); //(new Date()).toISOString();
        messagesLastRead = objRxDB.messagesLastRead ?
           objRxDB.messagesLastRead :
           Date.now(); //(new Date()).toISOString();
        // User specific data beyond Obj data.
        //
        // NOTE: What is done here needs to be kept in sync with
        // what the UserC object constructor does.  Refactor code
        // so this stuff is only done in one place.
        //
        // UPDATE_THIS_WHEN_ADDING_PROPS
        objProps.owner = objRxDB.owner ? objRxDB.owner : objRxDB._id;
        objProps.email = objRxDB.email;
        objProps.password = objRxDB.password;
        objProps.acceptedTerms = objRxDB.acceptedTerms;
        objProps.roles = objRxDB.roles;
        objProps.flags = objRxDB.flags;
        objProps.adventures = objRxDB.adventures;
        objProps.favorites = objRxDB.favorites;
        objProps.fellowTravelers = objRxDB.fellowTravelers;
        objProps.messages = objRxDB.messages;
        objProps.messagesLastReceived = messagesLastReceived;
        objProps.messagesLastRead = messagesLastRead;

        // NOTE: This is a hack until we get authorization working
        // in the new version.  If the curUser is being updated from
        // RxDB we need to "copy over" the currently logged in
        // user's token.
        if (curUserObj && objProps &&
            (objProps._id == curUserObj._id)) {
          if (curUserObj.token == null) {
            curUserObj.token = "someSortOfToken";
          }
          objProps.token = curUserObj.token;
        }

        // If curUserObj is not set to a real object yet,
        // use whatever properties we have for it to continue.
        if (!curUserObj || !curUserObj._id) {
          curUserObj = objProps;
        }
        break;

      case WAYS:
      case ACTS:
      case ITNS:
        //objProps = ObjUtils.rxdbPropsToObjCProps(objRxDB, collectionName);
        objProps = ObjC.rxdbPropsToObjCProps(objRxDB, collectionName);
        break;

      default:
        log.bug("ApiUtils.objRxDBToObjC(), unhandled collectionName: ",
          collectionName);
        debugger;
    }

    let objC;
    if (objProps) {
      objC = new objClass(objProps, curUserObj);
    }
    else {
      // TODO: display error.
      log.bug("ApiUtils.objRxDBToObjC(), unhandled collectionName: ",
        collectionName);
      debugger;
    }

    return objC;
  }


  /**
   * @param {string} propName - "way", "act", "itn", "user".
   *
   * @param {string} propsName - "ways", "acts", "itns", "users".
   *
   * @param objClass - The concierge-common class constructor.
   * E.g. Way, Act, Itn, User.
   */
  static dataToPayload(data, propName, propsName/*, objClass*/) {
    log.trace("dataToPayload(), data:", data);
    const payload = Object.assign({}, data);
    const objClass = ApiUtils.getObjClass(propName);
    if (data[propName]) {
      payload[propName] = new objClass(data[propName]);
    }
    if (data[propsName]) {
      payload[propsName] = _.map(data[propsName], obj => new objClass(obj));
    }
    if (data.error) {
      payload.error = new MyError(data.error);
    }
    if (data.dirtyObjs) {
      _.forOwn(data.dirtyObjs, (value, propName)=>{
        const objClass = ApiUtils.getObjClass(propName);
        if (!objClass) {
          log.bug("ApiUtils.dataToPayload(), unhandled propName:", propName);
          return;
        }
        payload.dirtyObjs[propName] = _.map(data.dirtyObjs[propName],
          obj => new objClass(obj));
      });
    }

    payload.totalCount = payload.totalCount ?
      parseInt(payload.totalCount, 10) :
      0;

    log.trace("dataToPayload(), Returning payload:", payload);
    return payload;
  }

  // Remove properties that are NOT saved to the RxDB/PouchDB.
  static deleteNonRxDBProps(props) {
    // TODO: Remove these two lines when cn property completely
    // removed from code.
    delete props.cn;
    delete props._cn;

    // This value does not exist in the schema.  It is added
    // when we create an ObjC object out of an RxDB object.
    delete props.childrenCn;
    delete props.parentCn;

    // Does not get stored in the DB.
    delete props.token;

    // Old schema.
    delete props.attributes;

    if (props.avatarSrc === null) {
      delete props.avatarSrc;
    }
    if (props.owner === null) {
      delete props.owner;
    }
    if (props.parkWaypoint === null) {
      delete props.parkWaypoint;
    }
  }

  static async createRxDBObj(curUser = {},
    props = {}, collectionName) {
    log.trace("createObj(), curUser:", curUser);

    if (!collectionName) {
      if (!props._id) {
        log.bug("ApiUtils.createRxDBObj() called with no "+
          "props._id or collectionName.");
        debugger;
      }

      if (props._id) {
        collectionName = ObjC.getCnFromId(props._id);
      }
      else {
        collectionName = ObjUtils.getCollectionName(props);
      }
    }
    if (!collectionName) {
      log.bug("ApiUtils.createRxDBObj() could not figure out collectionName.");
      debugger;
    }

    // Clone the passed in props and then remove any props
    // not stored in RxDB.
    props = _.cloneDeep(props);
    ApiUtils.deleteNonRxDBProps(props);

    // Get the class.  E.g. UserC, WayC
    const objClass = ApiUtils.getObjClass(collectionName);
    const docName = ApiUtils.getDocName(collectionName);

    if (collectionName != LocalDB.USERS) {
      // A User object does not have a "creator".
      props.creator = curUser._id;
    }
    props.created = Date.now(); //(new Date()).toISOString();

    
    if (props._id) {
      if (collectionName != LocalDB.USERS) {
        log.bug("createRxDBObj() called with props._id set.");
        debugger;
      }
      // We are creating a User object.  The Auth0 website
      // provided us with a user ID.  We just want to prepend
      // the collectionName to it.
      // e.g. "users_auth0|5f6830d597a01d006991150f"
      //props._id = LocalDB.USERS+"_"+props._id;
    }
    else {
      // Way, Act, Itn object.
      props._id = ApiUtils.getNewId(collectionName);
    }

    // Initialize payload.user, payload.way to the passed
    // in props so we send something useful back to the
    // caller to help figure out what the problem was.
    let payload = {
      [docName]:props,
    };
    try {
      log.trace("Calling Local.db[collectionName].insert(), props: ", props);
      const res = await LocalDB.db[collectionName].insert(props);
      log.trace("Way.create() Local.db[collectionName].insert() res:", res);
      payload = ApiUtils.dataRxDBToPayload(res, docName, collectionName,
        curUser);
      if (!payload.obj) {
        payload.obj = payload.user || payload.way || payload.act || payload.itn;
        debugger;
      }
      //payload.objC = payload.obj;
    }
    catch(err) {
      log.bug("createRxDBObj() exception: ", err);
      // Unknown kind of error.
      let whenTryingTo = "insert a "+docName+" object in the "+
        collectionName+" collection.";
      payload.error = createErrorFromException(err, whenTryingTo);
      store.dispatch(cA_SetError(payload.error));
    }
    log.trace("createRxDBObj() returning payload:", payload);

    return payload;
  }

  /**
   * Create the object and then navigate to the object specific
   * page for the new object.  E.g. create a waypoint and then
   * show that new waypoint in the waypoint page.
   */
  static async createRxDBObjAndShow(curUser, props, collectionName) {
    const payload = await ApiUtils.createRxDBObj(
      curUser, props, collectionName);
    const docName = ApiUtils.getDocName(collectionName);
    //const url = "/"+docName+"?"+docName+"Id="+payload[docName]._id;
    //const url = "/"+docName+"?"+docName+"Id="+payload.obj._id;
    const url = "/"+docName+"?"+docName+"Id="+payload.objC._id;
    store.dispatch(cA_History("push", url));
  }

  /**
   * @param collectionName Should be "users", "ways", etc.
   * As a temporary measure it accepts strings like
   * "cA_EditUser", "cA_EditWay".
   */
  static async editRxDBObj(curUser, objId, props, collectionName) {
    log.trace("Enter editRxDBObj(), collectionName: ", collectionName);
    log.trace("editRxDBObj(), props: ", props);

    const cn = ObjC.getCnFromId(objId);
    if (collectionName && cn != collectionName) {
      log.bug("In editRxDBObj(), collectionName does not match objId: ", objId);
      log.bug("In editRxDBObj(), collectionName: ", collectionName);
      debugger;
    }
    if (collectionName) {
      log.todo("Remove collectionName from editRxDBObj() calls.");
    }
    collectionName = cn;

    // TODO: Delete this if-block.
    if (collectionName && collectionName.startsWith("cA")) {
      log.todo("Replace editAction("+collectionName+
        ") prop with collectionName");
      debugger;
      switch(collectionName) {
        case "cA_EditUser":
          collectionName = "users";
          break;
        case "cA_EditWay":
          collectionName = "ways";
          break;
        case "cA_EditAct":
          collectionName = "acts";
          break;
        case "cA_EditItn":
          collectionName = "itns";
          break;
        default:
          log.bug("Unhandled collectionName/editAction");
          debugger;
      }
    }

    const docName = ApiUtils.getDocName(collectionName);
    let payload;
    try {
      let newObjRxDB = null;
      if (LocalDB.db && LocalDB.db[collectionName]) {
        /*
        if (collectionName == LocalDB.USERS) {
          // NOTE: This is a kludge to remove the userDetails field.
          // This is a User RxDB object that is being updated.
          // Before version 5, the user-schema.js contained a
          // field called "userDetails".  At version 5 that changed
          // to "details".  But, old RxDocuments in the RxDB/PouchDB
          // contain the "userDetails" field.  If I try to update
          // a user document RxDB complains that the user document
          // contains an illegal field.  So, we need to remove the
          // "userDetails" field.
          // The value we pass for the userDetails is irrelevant.
          // The $unset deletes it anyway.
          newObjRxDB = await
            LocalDB.db[collectionName].find().where("userDetails").gt(null).update({
              $unset:{userDetails:""},
            });
        }
        */

        // Props removed from old schema versions.
        // We must remove these when doing an update() or
        // RxDB complains.
        const unsetProps = {
          attributes:[],
        };

        log.trace("Calling RxDB update(), props: ", props);
        newObjRxDB = await
          LocalDB.db[collectionName].findOne().where("_id").eq(objId).update({
            $set:props,
            $unset:unsetProps,
          });
      }
      payload = ApiUtils.dataRxDBToPayload(
        newObjRxDB, docName, collectionName, curUser);
      //payload.curUser = curUser;
      //payload.filterAndSort = store.getState().ui.filterAndSort;
      log.trace("payload: ", payload);
      log.trace("payload.obj.isScenic: ", payload.obj && payload.obj.isScenic);
    }
    catch(err) {
      // Unknown error of some sort.
      log.info("err: ", err);
      const errorObj = Object.assign({}, props);
      errorObj._id = objId;
      payload = {
        [docName]:errorObj,
      };
      let whenTryingTo = "edit a "+docName+"'s property";
      payload.error = createErrorFromException(err, whenTryingTo);
      store.dispatch(cA_SetError(payload.error));
    }
  }

  /**
   * Used for removing a single property such as Act.parkWaypoint.
   */
  static async removeObjIdFromProp(curUser, idToRemove, fromObj, propName) {

    if (!fromObj[propName] ||
        (fromObj[propName] != idToRemove)) {
      return;
    }

    const newProps = {
      [propName]:null,
    }
    /*
    const collectionName = ObjUtils.getCollectionName(fromObj);
    await ApiUtils.editRxDBObj(curUser, fromObj._id, newProps,
      collectionName);
    */
    await ApiUtils.editRxDBObj(curUser, fromObj._id, newProps);
  }

  /**
   * @param idToRemove This will be a User id.
   *
   * @param fromObj An RxDocument or User/Way/Act/Itn.
   */
  static async removeObjIdFromOwner(curUser, idToRemove, fromObj) {

    if (fromObj.owner != idToRemove) {
      return;
    }

    // NOTE: Because this is an RxDocument object, the special
    // code in User, Way, Act, etc., objects that returns the
    // creator if the owner is null does not apply!
    let {creator, owner} = fromObj;
    if (creator == idToRemove) {
      creator = UserC.DEFAULT_USER_ID;
      if (owner == null) {
        owner = UserC.FOSTER_USER_ID;
      }
    }
    if (owner == idToRemove) {
      owner = UserC.FOSTER_USER_ID;
    }

    let newProps = {
      creator,
      owner,
    }
    //const collectionName = ObjUtils.getCollectionName(fromObj);
    await ApiUtils.editRxDBObj(curUser, fromObj._id, newProps);
    //  collectionName);
  }

  /**
   * @param idToRemove This will be a User id.
   *
   * @param fromObj An RxDocument or User/Way/Act/Itn.
   */
  static async removeObjIdFromCreatorOwner(curUser, idToRemove, fromObj) {
    log.trace("removeObjIdFromCreatorOwner(), idToRemove: ", idToRemove);
    log.trace("removeObjIdFromCreatorOwner(), fromObj: ", fromObj);

    if (fromObj.creator != idToRemove &&
        fromObj.owner != idToRemove) {
      // Neither creator nor owner match idToRemove, so there
      // is nothing for us to do.
      return;
    }

    // NOTE: Because this is an RxDocument object, the special
    // code in User, Way, Act, etc., objects that returns the
    // creator if the owner is null does not apply!
    let {creator, owner} = fromObj;
    if (creator == idToRemove) {
      creator = UserC.DEFAULT_USER_ID;
      if (owner == null) {
        owner = UserC.FOSTER_USER_ID;
      }
    }
    if (owner == idToRemove) {
      owner = UserC.FOSTER_USER_ID;
    }

    let newProps = {
      creator,
      owner,
    }
    await ApiUtils.editRxDBObj(curUser, fromObj._id, newProps);
  }

  /**
   * NOTE: This only modifies the passed in objIds array.
   * It does NOT edit the database.  The caller will do that.
   * 
   * @param replaceWithId Pass undefined if you want the property
   * to be deleted from the Object/Array.  Pass null or an ID to
   * set it to a new value.
   */
  static removeObjIdFromArray(idToRemove, objIds, replaceWithId) {
    if (!objIds) {
      debugger;
    }

    const newObjIds = [];
    objIds.forEach(objId => {
      if (objId == idToRemove) {
        if (replaceWithId === undefined) {
          // "Remove" the value from the array.
          return;
        }
        else {
          // Replace the objId with the new replaceWithId.
          newObjIds.push(replaceWithId);
        }
      }
      else {
        // objId did not match the idToRemove, so leave
        // it in the array.
        newObjIds.push(objId);
      }
    });

    return newObjIds;
  }

  /**
   * @param fromObj An RxDocument.
   *
   * @param replaceWithId Pass undefined if you want the property
   * to be deleted from the Object/Array.  Pass null or an ID to
   * set it to a new value.
   */
  static async removeObjId(curUser, idToRemove, fromObj, propName,
    replaceWithId) {
    if (!fromObj) {
      debugger;
    }
    if (!propName) {
      debugger;
    }

    const objIds = fromObj[propName];
    if (!Array.isArray(objIds)) {
      log.bug("In removeObjId(), objIds is not an Array."+
        "  objIds: ", objIds);
      debugger;
      return;
    }

    const newObjIds = ApiUtils.removeObjIdFromArray(idToRemove, objIds,
      replaceWithId);
    const newProp = {
      [propName]:newObjIds,
    }
    if (_.isEqual(newObjIds, objIds)) {
      // No change.
      return;
    }

    const collectionName = fromObj.collection.name;
    await ApiUtils.editRxDBObj(curUser, fromObj._id, newProp,
      collectionName);
  }

  /**
   * @param fromObj An RxDocument.
   */
  static async removeObjIdFromAccess(curUser, idToRemove, fromObj) {

    if (!fromObj.userIds ||
        fromObj.userIds.length == 0) {
      return;
    }

    const userIds = fromObj.userIds;
    const newUserIds = ApiUtils.removeObjIdFromArray(
      idToRemove, userIds);
    if (newUserIds.length == userIds.length) {
      // There was no change.
      return;
    }

    const collectionName = fromObj.collection.name;
    const newProps = {userIds:newUserIds};
    await ApiUtils.editRxDBObj(curUser, fromObj._id, newProps,
      collectionName);
  }


  /**
   * Remove all instances of idToRemove from fromObj.
   *
   * NOTE: This code could be more clever about NOT looking
   * for the idToRemove in lists where it would never exist.
   * For example, we won't find a Waypoint id in a User's
   * list of fellow travelers.  Similarly, we won't find a
   * User id in an an Adventure's list of Waypoints.
   *
   * If we want to do that, I think we will need to pass in
   * the collectionName of the idToRemove object also.
   *
   * @param fromObj An RxDocument.
   */
  static async removeObjIdFromObj(curUser, idToRemove, fromObj) {
    const collectionName = fromObj.collection.name;

    switch(collectionName) {
      case LocalDB.USERS:
        // We are removing idToRemove from User fromObj.
        // TODO: Only do this if idToRemove is a Way/Act/Itn ID.
        await ApiUtils.removeObjId(curUser, idToRemove, fromObj,
          "adventures");
        // TODO: Only do this if idToRemove is a User ID.
        await ApiUtils.removeObjId(curUser, idToRemove, fromObj,
          "fellowTravelers");
        await ApiUtils.removeObjId(curUser, idToRemove, fromObj,
          "favorites");
        // TODO: Only do this if idToRemove is a User ID.
        await ApiUtils.removeObjIdFromAccess(curUser, idToRemove,
          fromObj);
        break;
      case WAYS:
        // We are removing idToRemove from Way fromObj.
        // TODO: Only do this if idToRemove is a User ID.
        await ApiUtils.removeObjIdFromCreatorOwner(curUser,
          idToRemove, fromObj);
        // TODO: Only do this if idToRemove is a User ID.
        await ApiUtils.removeObjIdFromAccess(curUser,
          idToRemove, fromObj);
        break;
      case ACTS:
        // We are removing idToRemove from Act fromObj.
        await ApiUtils.removeObjId(curUser, idToRemove, fromObj, "children");
        await ApiUtils.removeObjIdFromProp(curUser, idToRemove,
          fromObj, "parkWaypoint");
        await ApiUtils.removeObjIdFromCreatorOwner(curUser,
          idToRemove, fromObj);
        await ApiUtils.removeObjIdFromAccess(curUser,
          idToRemove, fromObj);
        break;
      case ITNS:
        // We are removing idToRemove from Itn fromObj.
        await ApiUtils.removeObjId(curUser, idToRemove, fromObj,
          "children");
        await ApiUtils.removeObjIdFromCreatorOwner(curUser,
          idToRemove, fromObj);
        await ApiUtils.removeObjIdFromAccess(curUser, idToRemove, fromObj);
        break;
      default:
        log.bug("In ApiUtils.removeObjIdFromObj(), "+
          "unhandled collectionName: ", collectionName);
        debugger;
    }
  }

  static async removeAllOccurrencesOfObjIdFromAllObjs(
    curUser, idToRemove, collectionName) {
    const rxdbObjs = await LocalDB.db[collectionName].find().exec();
    log.trace("rxdbObjs: ", rxdbObjs);
    //await removeObjIdFromObj(curUser, objId, obj, collectionName);
    await Promise.all(rxdbObjs.map(async (rxdbObj) => {
      await ApiUtils.removeObjIdFromObj(curUser, idToRemove, rxdbObj);
    }));
  }

  /**
   * Remove the passed in objId from all objects in all
   * collections in the database.
   * For example, a Way that is going to be deleted
   * needs to be removed from all Acts that have it in
   * their list of Waypoints.  Also, the Way needs to
   * be removed from any Acts that use the Way as their
   * parkingLocation.  And so on...
   */
  static async removeAllOccurrencesOfObjIdFromAllObjsInAllCollections(
    curUser, idToRemove) {
    // Nothing to remove from Globals?
    await ApiUtils.removeAllOccurrencesOfObjIdFromAllObjs(
      curUser, idToRemove, LocalDB.USERS);
    await ApiUtils.removeAllOccurrencesOfObjIdFromAllObjs(
      curUser, idToRemove, WAYS);
    await ApiUtils.removeAllOccurrencesOfObjIdFromAllObjs(
      curUser, idToRemove, ACTS);
    await ApiUtils.removeAllOccurrencesOfObjIdFromAllObjs(
      curUser, idToRemove, ITNS);
  }

  /**
   * Checks if usedObj is used by byObj.
   * Modifies the passed in usage Object.
   *
   * @param usedObj is of type User/Way/Act/Itn
   *
   * @param byObj is an RxDocument directly out of the browser's
   * local RxDB/PouchDB.
   */
  static isUsedBy(curUser, usedObj, byObj, usage) {
    log.trace("Enter isUsedBy(), usedObj.displayName: ", usedObj.displayName);
    log.trace("Enter isUsedBy(), usage: ", usage);
    const collectionName = byObj.collection.name;
    const usedId = usedObj._id;
    const byObjId = byObj._id;
    const {usedBy} = usage;

    // Switch based on whether byObj is a User/Way/Act/Itn.
    switch(collectionName) {
      case LocalDB.USERS:  // byObj is a User.
        // We are testing whether object usedObj is used by User byObj.
        if ((usedObj.cn == WAYS) ||
            (usedObj.cn == ACTS) ||
            (usedObj.cn == ITNS)) {
          // We are testing whether Way/Act/Itn usedObj is used
          // in User byObj's adventures list.
          if (_.includes(byObj.adventures, usedObj._id)) {
            usedBy.users[byObjId] = Object.assign({}, usedBy.users[byObjId],
              {asAdventure:true});
          }
        }
        if ((usedObj.cn == USERS) || 
            (usedObj.cn == WAYS) ||
            (usedObj.cn == ACTS) ||
            (usedObj.cn == ITNS)) {
          // We are testing whether User/Way/Act/Itn usedObj is used
          // in User byObj's favorites list.
          if (_.includes(byObj.favorites, usedObj._id)) {
            usedBy.users[byObjId] = Object.assign({}, usedBy.users[byObjId],
              {asFavorite:true});
          }
        }
        if (usedObj.cn == USERS) {
          // We are testing whether User usedObj is used in
          // User byObj's fellowTravelers list.
          if (_.includes(byObj.fellowTravelers, usedObj._id)) {
            usedBy.users[byObjId] = Object.assign({}, usedBy.users[byObjId],
              {asFellowTraveler:true});
          }
        }
        break;
      case WAYS:  // byObj is a Waypoint.
        // We are testing whether object usedObj is used by Way byObj.
        if (usedObj.cn == USERS) {
          // We are testing whether User usedObj is used as the
          // creator or owner of byObj.
          if (byObj.creator == usedId) {
            usedBy.ways[byObjId] = Object.assign({}, usedBy.ways[byObjId],
              {asCreator:true});
          }
          if (byObj.owner == usedId) {
            usedBy.ways[byObjId] = Object.assign({}, usedBy.ways[byObjId],
              {asOwner:true});
          }
        }
        break;
      case ACTS:  // byObj is an Act.
        log.trace("byObj is an Act.  byObj.displayName: ", byObj.displayName);
        log.trace("usedObj: ", usedObj);
        // We are testing whether object usedObj is used by Act byObj.
        if (usedObj.cn == USERS) {
          // We are testing whether User usedObj is used as the
          // creator or owner by Act byObj.
          if (byObj.creator == usedId) {
            usedBy.acts[byObjId] = Object.assign({}, usedBy.acts[byObjId],
              {asCreator:true});
          }
          if (byObj.owner == usedId) {
            usedBy.acts[byObjId] = Object.assign({}, usedBy.acts[byObjId],
              {asOwner:true});
          }
        }
        if (usedObj.cn == WAYS) {
          log.trace("usedObj.cn == WAYS");
          log.trace("byObj: ", byObj);
          // We are testing whether Way usedObj is used by Act byObj
          // as a Waypoint or as a parking location.
          if (_.includes(byObj.children, usedObj._id)) {
            usedBy.acts[byObjId] = Object.assign({}, usedBy.acts[byObjId],
              {asChild:true});
          }
          if (byObj.parkWaypoint == usedId) {
            usedBy.acts[byObjId] = Object.assign({}, usedBy.acts[byObjId],
              {asParkWaypoint:true});
          }
        }
        break;
      case ITNS:  // byObj is an Itn.
        // We are testing whether object usedObj is used by Itn byObj.
        if (usedObj.cn == USERS) {
          // We are testing whether User usedObj is used as the
          // creator or owner by Itn byObj.
          if (byObj.creator == usedId) {
            usedBy.itns[byObjId] = Object.assign({}, usedBy.itns[byObjId],
              {asCreator:true});
          }
          if (byObj.owner == usedId) {
            usedBy.itns[byObjId] = Object.assign({}, usedBy.itns[byObjId],
              {asOwner:true});
          }
        }
        if (usedObj.cn == ACTS) {
          // We are testing whether Act usedObj is used by Itn byObj
          // as an Act in the Itn's list of Acts.
          if (_.includes(byObj.children, usedObj._id)) {
            usedBy.itns[byObjId] = Object.assign({}, usedBy.itns[byObjId],
              {asChild:true});
          }
        }
        break;
      default:
        log.bug("In ApiUtils.isUsedBy(), "+
          "unhandled collectionName: ", collectionName);
        debugger;
    }
    log.trace("Exiting isUsedBy(), usage: ", usage);
  }

  /**
   * Checks if usedObj is used by any object in the specified
   * collection.
   * Modifies the passed in usage Object.
   *
   * @param usedObj A User/Way/Act/Itn object.
   */
  static async isUsedByAnyObjInCollection(
    curUser, usedObj, collectionName, usage) {
    log.trace("Enter isUsedByAnyObjInCollection(), usage: ", usage);
    const rxdbObjs = await LocalDB.db[collectionName].find().exec();
    // NOTE: Do NOT use Promise.all() with an Array.map() function.
    // We need the calls to isUsedBy() to execute sequentially, not
    // in parallel.
    //await Promise.all(rxdbObjs.map(async (rxdbObj) => {
    //  await ApiUtils.isUsedBy(curUser, usedObj, rxdbObj, usage);
    for (let index = 0; index < rxdbObjs.length; index++) {
      ApiUtils.isUsedBy(curUser, usedObj, rxdbObjs[index], usage);
    };
    log.trace("Exiting isUsedByAnyObjInCollection(), usage: ", usage);
  }

  /**
   * Returns false if usedObj is not used by any object in any
   * collection.  If usedObj is used by at least one object,
   * this function returns an Object giving details about its
   * use.
   *
   * @param usedObj A User/Way/Act/Itn object.
   */
  static async isUsedByAnyObjInAnyCollection(curUser, usedObj) {
    let usage = {
      usedObj,
      usedBy:{
        users:{},ways:{},acts:{},itns:{}
      },
      counts:{},
    };
    log.trace("In isUsedByAnyObjInAnyCollection(users), usage: ", usage);
    /*
    let usage = {
      usedObj,
      usedBy:{
        users:{
          // Multiple properties like this created if multiple
          // User objects are using usedObj.
          ["idOfUserUsingUsedObj"]:{
            asAdventure:true,
            asFavorite:true,
            asFellowTraveler:true,  // Only if usedObj is a User.
          },
        },
        ways:{
          ["idOfWayUsingUsedObj"]:{
            asCreator:true,  // Only if usedObj is a User.
            asOwner:true,  // Only if usedObj is a User.
          },
        },
        acts:{
          ["idOfActUsingUsedObj"]:{
            asCreator:true,  // Only if usedObj is a User.
            asOwner:true,  // Only if usedObj is a User.
            asChild:true,  // True if usedObj is Way and in Act.children list.
            asParkWaypoint:true,  // True if usedObj is Way and is Act.parkWaypoint.
          },
        },
        itns:{
          ["idOfItnUsingUsedObj"]:{
            asCreator:true,  // Only if usedObj is a User.
            asOwner:true,  // Only if usedObj is a User.
            asChild:true,  // True if usedObj is Act and in Itn.children list.
          },
        },
        counts:{
          asAdventure:0, // User's completed adventures.
          asFavorite:0, // User's favorites.
          asFellowTraveler:0, // User's fellowTravelers.
          asCreator:0,
          asOwner:0,
          asParkWaypoint:0, // Act's parking Waypoint.
          asChild:0, // Act's list of Ways, or Itn's list of Acts.
        },
      },
    };
    */

    // Globals never references any object as of Sep 2020.
    log.trace("Calling isUsedByAnyObjInCollection(users), usage: ", usage);
    await ApiUtils.isUsedByAnyObjInCollection(
      curUser, usedObj, LocalDB.USERS, usage);
    log.trace("Calling isUsedByAnyObjInCollection(ways), usage: ", usage);
    await ApiUtils.isUsedByAnyObjInCollection(
      curUser, usedObj, WAYS, usage);
    await ApiUtils.isUsedByAnyObjInCollection(
      curUser, usedObj, ACTS, usage);
    await ApiUtils.isUsedByAnyObjInCollection(
      curUser, usedObj, ITNS, usage);

    // Remove any empty usedBy properties.
    const {usedBy} = usage;
    for (const prop in usedBy) {
      if (_.isEmpty(usedBy[prop])) {
        delete usedBy[prop];
      }
    }
    // If usedBy contains nothing, i.e. usedObj was not used
    // by any other object, remove the usedBy property.
    if (_.isEmpty(usedBy)) {
      delete usage.usedBy;
    }

    log.trace("After removing unused props, usage: ", usage);
    if (usage.usedBy) {
      const {usedBy} = usage;
      for (const collectionName in usedBy) {
        const usedByCollection = usedBy[collectionName];
        // usedByCollection example: usage.usedBy.acts
        for (const byObjId in usedByCollection) {
          const usedAsList = usedByCollection[byObjId];
          // usedAsList example: usage.usedBy.acts["kwl23dlk23"]
          for (const usedAs in usedAsList) {
            // usedAs is something like: "asCreator", "asChild"
            //log.trace("_.size(usedBy[prop]): ", _.size(usedBy[prop]));
            if (!usage.counts[usedAs]) {
              usage.counts[usedAs] = 0;
            }
            usage.counts[usedAs]++;
          }
        }
      }
    }
    let count = 0;
    for (const usedAs in usage.counts) {
      count += usage.counts[usedAs];
    }
    log.trace("Final count: ", count);
    usage.count = count;
    log.trace("final usage: ", usage);
    return count ? usage : false;
  }

  /**
   * @param objToOrphan The RxDocument OR Way/Act/Itn
   * that will be "orphaned".
   */
  static async orphanObj(curUser, objToOrphan) {
    const newProps = {
      owner:UserC.FOSTER_USER_ID,
    }
    await ApiUtils.editRxDBObj(curUser, objToOrphan._id, newProps);
  }

  static async deleteRxDBObj(curUser, objC, collectionName) {
    if (collectionName) {
      log.todo("Remove collectionName from call to deleteRxDBObj()");
    }
    // NOTE:  objC.cn property should always exist, but just in case
    // old data is sitting around in a DB, use the object id.
    //collectionName = objC.cn ? objC.cn :
    //  ObjUtils.getCollectionName(objC);
    if (!objC.cn) {
      log.bug("In ApiUtils.deleteRxDBObj(), obj.cn not set.  objC: ", objC);
      debugger;
    }
    collectionName = objC.cn;

    const docName = ApiUtils.getDocName(collectionName);
    let payload;
    try {
      let deletedObjRxDB = null;
      if (LocalDB.db && LocalDB.db[collectionName]) {
        await LocalDB.removeConflictsObj(collectionName, objC._id);
        deletedObjRxDB = await
          LocalDB.db[collectionName].findOne().where("_id").eq(
            objC._id).remove();
      }
      payload = ApiUtils.dataRxDBToPayload(
        deletedObjRxDB, docName, collectionName, curUser);
    }
    catch(err) {
      // Unknown error of some sort.
      payload = {
        [docName]:objC,
      };
      let whenTryingTo = "delete a "+docName;
      payload.error = createErrorFromException(err, whenTryingTo);
      store.dispatch(cA_SetError(payload.error));
    }
  }

  //static async createRxDBUser(curUser, userProps) {
  //  return await ApiUtils.createRxDBObj(curUser, userProps, LocalDB.USERS);
  //}
  static async editRxDBUser(curUser, userId, userProps) {
    await ApiUtils.editRxDBObj(curUser, userId, userProps, LocalDB.USERS);
  }
  static async deleteRxDBUser(curUser, userC) {
    //await ApiUtils.deleteRxDBObj(curUser, userC, LocalDB.USERS);
    await ApiUtils.deleteRxDBObj(curUser, userC);
  }

  static async createRxDBWay(curUser, wayProps) {
    return await ApiUtils.createRxDBObj(curUser, wayProps, WAYS);
  }
  static async editRxDBWay(curUser, wayId, wayProps) {
    await ApiUtils.editRxDBObj(curUser, wayId, wayProps, WAYS);
  }
  static async deleteRxDBWay(curUser, wayC) {
    //await ApiUtils.deleteRxDBObj(curUser, wayC, WAYS);
    await ApiUtils.deleteRxDBObj(curUser, wayC);
  }

  static async createRxDBAct(curUser, actProps) {
    return await ApiUtils.createRxDBObj(curUser, actProps, ACTS);
  }
  static async editRxDBAct(curUser, actId, actProps) {
    await ApiUtils.editRxDBObj(curUser, actId, actProps, ACTS);
  }
  static async deleteRxDBAct(curUser, actC) {
    //await ApiUtils.deleteRxDBObj(curUser, actC, ACTS);
    await ApiUtils.deleteRxDBObj(curUser, actC);
  }

  static async createRxDBItn(curUser, itnProps) {
    return await ApiUtils.createRxDBObj(curUser, itnProps, ITNS);
  }
  static async editRxDBItn(curUser, itnId, itnProps) {
    await ApiUtils.editRxDBObj(curUser, itnId, itnProps, ITNS);
  }
  static async deleteRxDBItn(curUser, itnC) {
    //await ApiUtils.deleteRxDBObj(curUser, itnC, ITNS);
    await ApiUtils.deleteRxDBObj(curUser, itnC);
  }

  // User Message Functions BELOW
  /**
   * @param userToMessage The user to whom curUser is sending a message.
   */
  static async sendMessage(curUser, userToMessage, messageObj) {
    log.trace("sendMessage() curUser:", curUser);
    log.trace("sendMessage() userToMessage:", userToMessage);
    log.trace("sendMessageObj() messageObj:", messageObj);

    delete messageObj.ownerId;
    messageObj._id = ""+Date.now();

    let payload = {
      user:userToMessage,  // Will be replaced after success.
      messageObj,
    };

    try {
      let editedUserRxDB = null;
      if (LocalDB.db && LocalDB.db.users) {
        editedUserRxDB = await
          LocalDB.db.users.findOne().where("_id").eq(userToMessage._id).update({
            $push:{messages:messageObj},
            $set:{messagesLastReceived:Date.now()},//(new Date()).toISOString()},
          });
      }
      log.trace("editedUserRxDB: ", editedUserRxDB);
      payload = ApiUtils.dataRxDBToPayload(editedUserRxDB, "user", "users", curUser);
      log.trace("dataRxDBToPayload() returned:", payload);
    }
    catch(err) {
      log.trace("err:", err);
      let whenTryingTo = "send a message to "+
        userToMessage.displayName;
      payload.error = createErrorFromException(err, whenTryingTo);
    }
    log.trace("UserC.sendMessage() returning payload:", payload);
    return payload;
  }

  /**
   * @param messageOwnerId The _id of the User that contains the message.
   */
  static async deleteMessage(curUser, messageOwnerId, messageObj) {
    log.trace("deleteMessage() curUser:", curUser);
    log.trace("deleteMessage() messageOwnerId:", messageOwnerId);
    log.trace("deleteMessageObj() messageObj:", messageObj);

    let payload = {
      messageObj,
    };
    try {
      //let editedUserRxDB = null;
      let userToEdit;
      if (LocalDB.db && LocalDB.db.users) {
        // $pull is not yet implemented.
        //editedUserRxDB = await
        //  LocalDB.db.users.findOne().where("_id").eq(userId).update({
        //    $pull:{messages:{_id:messageObj._id}},
        //  });
        userToEdit = ObjUtils.getUser(curUser, messageOwnerId);

        //const userToEdit = await
        //  LocalDB.db.users.findOne().where("_id").eq(messageOwnerId).exec();
        log.trace("userToEdit: ", userToEdit);

        const messages = userToEdit.messages;
        let editedMessages;
        editedMessages = messages.filter(obj => {
          return obj._id !== messageObj._id;
        });
        log.trace("editedMessages: ", editedMessages);

        //editedUserRxDB = await userToEdit.update(
        //  {$set:{messages: editedMessages}});
        userToEdit.messages = editedMessages;
        await ApiUtils.editRxDBUser(curUser, messageOwnerId,
          {messages:editedMessages});
      }
      payload = {
        user:userToEdit, // NOTE: Will still be the "old" version.
      };
      log.trace("dataRxDBToPayload() returned:", payload);
    }
    catch(err) {
      log.trace("err:", err);
      let whenTryingTo = "delete a message";
      payload.error = createErrorFromException(err, whenTryingTo);
    }
    log.trace("UserC.deleteMessage() returning payload:", payload);
    //return payload;
  }

  /**
   * @param acceptor UserC object who is accepting the connection request.
   */
  static async acceptConnection(curUser, acceptor, requestor) {
    log.trace("acceptConnection(), acceptor: ", acceptor);
    log.trace("acceptConnection(), requestor: ", requestor);
    const acceptorId = acceptor._id;
    const requestorId = requestor._id;
    try {
      // Add requestorId to acceptor.fellowTravelers.
      const editedAcceptor = await
        LocalDB.db.users.findOne().where("_id").eq(acceptorId).update({
          $push:{fellowTravelers:requestorId}
        });
      log.trace("editedAcceptor: ", editedAcceptor);

      // Add acceptorId to requestor.fellowTravelers.
      const editedRequestor = await
        LocalDB.db.users.findOne().where("_id").eq(requestorId).update({
          $push:{fellowTravelers:acceptorId}
        });
      log.trace("editedRequestor: ", editedRequestor);

      // Send a message to the requestor of the connection.
      let text = acceptor.displayName+
        " has accepted your connection request and is now "+
        "one of your fellow travelers.";
      let messageObj = {
        text,
        //task:"acceptConnection",
        task:"noreply",
        senderId:UserC.SUPPORT_USER_ID,
      };
      ApiUtils.sendMessage(curUser, requestor, messageObj);

      // Send a message to the acceptor of the connection.
      text = "You have accepted the connection request from "+
        requestor.displayName+", and they are now one of your "+
        "fellow travelers.";
      messageObj = {
        text,
        //task:"acceptConnection",
        task:"noreply",
        senderId:UserC.SUPPORT_USER_ID,
      };
      ApiUtils.sendMessage(curUser, acceptor, messageObj);

      // Change the connection request message's task from
      // "connect" to "message".  That way we will no longer
      // display the Connect button, nor will we display
      // the "pending" ribbon if either user disconnects in
      // the future.
      ApiUtils.removeConnectTaskFromMessage(curUser,
        acceptorId, requestorId);
    }
    catch(err) {
      log.trace("err:", err);
      let whenTryingTo = "have "+acceptor.displayName+
        " accept connection to requestor "+requestor.displayName;
      const error = createErrorFromException(err, whenTryingTo);
      const action = {type:ACTION_TYPES.SET_ERROR, payload:{error}};
      store.dispatch(action);
    }
  }

  static async possiblyDeleteConnection(otherUser, curUserObj,
    cA_Confirmation) {

    const deleteConnection = async () => {
      ApiUtils.deleteConnection(curUserObj, curUserObj._id, otherUser._id);
    }

    const title = "Disconnect?";
    const message1 = "Are you sure you want to disconnect from "+
      otherUser.displayName+"?";
    const message2 = undefined;
    const okAction = deleteConnection;
    const okLabel = "Disconnect";
    cA_Confirmation(title, message1, message2, okAction, okLabel);
  }

  static async deleteConnection(curUser, user1Id, user2Id) {
    log.trace("deleteConnection() curUser:", curUser);
    log.trace("deleteConnection() user1Id:", user1Id);
    log.trace("deleteConnection() user2Id:", user2Id);

    try {
      const editedUser1 = await
        LocalDB.db.users.findOne().where("_id").eq(user1Id).update({
          $pullAll:{fellowTravelers:[user2Id]}
        });
      log.trace("editedUser1: ", editedUser1);
      const editedRequestor = await
        LocalDB.db.users.findOne().where("_id").eq(user2Id).update({
          $pullAll:{fellowTravelers:[user1Id]}
        });
      log.trace("editedRequestor: ", editedRequestor);
    }
    catch(err) {
      log.trace("err:", err);
      let whenTryingTo = "delete a connection between "+
        user1Id+" and "+user2Id;
      const error = createErrorFromException(err, whenTryingTo);
      const action = {type:ACTION_TYPES.SET_ERROR, payload:{error}};
      store.dispatch(action);
    }
  }

  /**
   * Change the connection request message's task from
   * "connect" to "message" so the Connect button is no
   * longer displayed and we don't think a connection is
   * pending.  (The original connection message might have
   * been deleted by the receiver/acceptor of it.)
   *
   * receiverId == acceptorId
   * senderId == requestorId
   */
  static async removeConnectTaskFromMessage(curUser, receiverId, senderId) {
    const messageOwner = await
      LocalDB.db.users.findOne().where("_id").eq(receiverId).exec();
    const messages = messageOwner.messages;
    if (messages && messages.length > 0) {
      const connectionRequest = messages.find(message => {
        return message.task == "connect" &&
          message.senderId == senderId;
        });
      log.trace("connectionRequest:", connectionRequest);
      if (connectionRequest) {
        connectionRequest.task = "message";
        await ApiUtils.editRxDBUser(curUser, receiverId, {messages});
      }
    }
  }
  // User Message Functions ABOVE

  // Possibly remove obj parameter from user.favorites array AFTER
  // displaying a confirmation dialog.
  static async possiblyRemoveFromFavorites(obj, user, curUserObj,
    cA_Confirmation) {
    if (!user || !user.favorites || !obj || !obj._id ||
      !Array.isArray(user.favorites) ||
      user.favorites.indexOf(obj._id) < 0) {
      // obj is not in user.favorites list, so the
      // removeFromFavorites action is not possible.
      log.bug("In possiblyRemoveFromFavorites(), action not possible.");
      log.bug("Object not in favorites list?");
      return;
    }

    const removeFromFavorites = async () => {
      // Shallow copy of user.favorites array.
      const favorites = user.favorites.slice(0);
      _.pull(favorites, obj._id);  // Removes all favorites with matching IDs.
      const newProps = {favorites};
      ApiUtils.editRxDBObj(curUserObj, user._id, newProps);
    }

    const owners = user._id == curUserObj._id ?
      "your" : user.displayName+"'s";

    const title = "Remove From Favorites?";
    const message1 = "Are you sure you want to remove "+
      obj.displayName+" from "+owners+" Favorites?";
    const message2 = undefined;
    const okAction = removeFromFavorites;
    const okLabel = "Remove";
    cA_Confirmation(title, message1, message2, okAction, okLabel);
  }

  /**
   * Possibly remove obj parameter from user.recommendations array AFTER
   * displaying a confirmation dialog.
   */
  static async possiblyRemoveFromRecommendations(obj, user, curUserObj,
    cA_Confirmation) {
    if (!user || !user.recommendations || !obj || !obj._id ||
      !Array.isArray(user.recommendations) ||
      user.recommendations.indexOf(obj._id) < 0) {
      // obj is not in user.recommendations list, so the
      // removeFromRecommendations action is not possible.
      log.bug("In possiblyRemoveFromRecommendations(), action not possible.");
      log.bug("Object not in recommendations list?");
      return;
    }

    const removeFromRecommendations = async () => {
      // Shallow copy of user.recommendations array.
      const recommendations = user.recommendations.slice(0);
      // Removes all recommendations with matching IDs.
      _.pull(recommendations, obj._id);
      const newProps = {recommendations};
      ApiUtils.editRxDBObj(curUserObj, user._id, newProps);
    }

    const owners = user._id == curUserObj._id ?
      "your" : user.displayName+"'s";

    const title = "Remove From Recommendations?";
    const message1 = "Are you sure you want to remove "+
      obj.displayName+" from "+owners+" Recommendations?";
    const message2 = undefined;
    const okAction = removeFromRecommendations;
    const okLabel = "Remove";
    cA_Confirmation(title, message1, message2, okAction, okLabel);
  }

  // Possibly remove guide from user.guides array AFTER
  // displaying a confirmation dialog.
  static async possiblyRemoveFromGuides(guide, user, curUserObj,
    cA_Confirmation) {
    if (!user || !user.guides || !guide || !guide._id ||
      !Array.isArray(user.guides) ||
      user.guides.indexOf(guide._id) < 0) {
      // guide is not in user.guides list, so the
      // removeFromGuides action is not possible.
      log.bug("In possiblyRemoveFromGuides(), action not possible.");
      log.bug("Object not in guides list?");
      return;
    }

    const removeFromGuides = async () => {
      // Shallow copy of user.guides array.
      const guides = user.guides.slice(0);
      _.pull(guides, guide._id);  // Removes all guides with matching IDs.
      const newProps = {guides};
      ApiUtils.editRxDBObj(curUserObj, user._id, newProps);
    }

    const owners = user._id == curUserObj._id ?
      "your" : user.displayName+"'s";

    const title = "Remove From Guides?";
    const message1 = "Are you sure you want to remove "+
      guide.displayName+" from "+owners+" Guides?";
    const message2 = undefined;
    const okAction = removeFromGuides;
    const okLabel = "Remove";
    cA_Confirmation(title, message1, message2, okAction, okLabel);
  }

  /**
   * Possibly add obj parameter to user.favorites array AFTER
   * displaying a confirmation dialog.
   */
  static async possiblyAddToFavorites(obj, user, curUserObj,
    cA_Confirmation) {
    if (!user || !obj || !obj._id ||
      (Array.isArray(user.favorites) &&
       user.favorites.indexOf(obj._id) >= 0)) {
      // obj is already in user.favorites list, so the
      // addToFavorites action is not possible.
      log.bug("In possiblyAddToFavorites(), action not possible.");
      log.bug("Object already in favorites list?");
      return;
    }

    const addToFavorites = async () => {
      // Shallow copy of user.favorites array.
      const favorites = user.favorites ?
        user.favorites.slice(0) : [];
      favorites.push(obj._id);
      const newProps = {favorites};
      ApiUtils.editRxDBObj(curUserObj, user._id, newProps);
    }

    const title = "Add To Favorites?";
    const message1 = "Are you sure you want to add "+
      obj.displayName+" to your Favorites?";
    const message2 = undefined;
    const okAction = addToFavorites;
    const okLabel = "Add";
    const cancelAction = undefined;
    const cancelLabel = undefined;
    const delaySeconds = 0;
    const doubleOkConfirmation = undefined;
    const doubleCancelConfirmation = undefined;
    const okButton = undefined;
    const cancelButton = undefined;
    const okButtonProps = {
      positive:true,
      negative:false};
    const okButtonIconName = "heart";
    cA_Confirmation(title, message1, message2, okAction, okLabel,
      cancelAction, cancelLabel, delaySeconds, doubleOkConfirmation,
      doubleCancelConfirmation, okButton, cancelButton,
      okButtonProps, okButtonIconName);
  }

  /**
   */
   /*
  static async possiblyAddToRecommendations(obj, user, curUserObj,
    cA_Confirmation) {
    alert("asdf");
  }
  */

  // Possibly add guide parameter to user.guides array AFTER
  // displaying a confirmation dialog.
  static async possiblyAddToGuides(guide, user, curUserObj,
    cA_Confirmation) {
    if (!user || !guide || !guide._id ||
      (Array.isArray(user.guides) &&
       user.guides.indexOf(guide._id) >= 0)) {
      // guide is already in user.guides list, so the
      // addToGuides action is not possible.
      log.bug("In possiblyAddToGuides(), action not possible.");
      log.bug("Object already in guides list?");
      debugger;
      return;
    }

    const addToGuides = async () => {
      // Shallow copy of user.guides array.
      const guides = user.guides ?
        user.guides.slice(0) : [];
      guides.push(guide._id);
      const newProps = {guides};
      ApiUtils.editRxDBObj(curUserObj, user._id, newProps);
    }

    const title = "Add To Guides?";
    const message1 = "Are you sure you want to add "+
      guide.displayName+" to your Guides?";
    const message2 = undefined;
    const okAction = addToGuides;
    const okLabel = "Add";
    const cancelAction = undefined;
    const cancelLabel = undefined;
    const delaySeconds = 0;
    const doubleOkConfirmation = undefined;
    const doubleCancelConfirmation = undefined;
    const okButton = undefined;
    const cancelButton = undefined;
    const okButtonProps = {
      positive:true,
      negative:false};
    const okButtonIconName = "map signs";
    cA_Confirmation(title, message1, message2, okAction, okLabel,
      cancelAction, cancelLabel, delaySeconds, doubleOkConfirmation,
      doubleCancelConfirmation, okButton, cancelButton,
      okButtonProps, okButtonIconName);
  }

  // These cA_* functions that return DO_NOTHING should be
  // removed and the callers changed to calling the non-cA_
  // versions of these functions.
  // NOTE: As of 15 Sep 2020 I think they are no longer called.
  static cA_CreateRxDBObjAndShow(curUser, props, collectionName) {
    log.bug("Change call to ApiUtils.cA_CreateRxDBObj() to createRxDBXXX()");
    debugger;
    ApiUtils.createRxDBObjAndShow(curUser, props, collectionName);
    const action = {
      type:ACTION_TYPES.DO_NOTHING,
      payload:{},
    }
    return action;
  }
  static cA_EditRxDBObj(curUser, objId, props, collectionName) {
    log.bug("Change call to ApiUtils.cA_EditRxDBObj() to editRxDBXXX()");
    debugger;
    ApiUtils.editRxDBObj(curUser, objId, props, collectionName);
    const action = {
      type:ACTION_TYPES.DO_NOTHING,
      payload:{},
    }
    return action;
  }
  static cA_DeleteRxDBObj(curUser, objId, props, collectionName) {
    log.bug("Change call to ApiUtils.cA_DeleteRxDBObj() to deleteRxDBXXX()");
    debugger;
    ApiUtils.deleteRxDBObj(curUser, objId, props, collectionName);
    const action = {
      type:ACTION_TYPES.DO_NOTHING,
      payload:{},
    }
    return action;
  }

  /**
   * Update all the user Ids of the passed in guide's followers.
   */
  static async updateFollowerIds(curUserObj, guide/*, cache, cA_GetObj*/) {
    const guideId = guide._id;
    // TODO: Seems wasteful to get the entire object when we only want
    // the Ids.  Use a PouchDB function instead of RxDB function?
    const rxdbObjs = await LocalDB.db[USERS].find(
      {selector:{"guides":{$in:[guideId]}}}).exec();
    log.trace("rxdbObjs: ", rxdbObjs);

    const followerIds = rxdbObjs.map(rxdbObj => rxdbObj._id);
    guide.followerIds = followerIds;
    store.dispatch(cA_UpdateCache(guide));
  }

};

export default ApiUtils;
