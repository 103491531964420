import $ from 'jquery';

// Add dotdotdot to the jquery library.
// I.e. this allows us to call dotdotdot on a jquery element.
// E.g. $element.dotdotdot(OPTIONS);
import /*dotdotdot from*/ 'jquery.dotdotdot';

import {log} from 'concierge-common';

const DEFAULT_SELECTOR = ".multi-line-ellipsis > span";
const OPTIONS = {ellipsis:" \u27bd", watch:true};

/* After addEllipsis() is called, we do NOT
 * immediately/synchronously do the calculations.
 * We instead, let the event queue flush out and
 * React make its changes to the elements before
 * we make the call to the dotdotdot utility.
 * A longer delay is safer.  1 is definitely not
 * long enough.  20 seems to be enough,
 * so I am using 100 to "be sure".
 * This is a hack.
 */
const DELAY_IN_MS = 100;

function addEllipsis(selector = DEFAULT_SELECTOR, ancestor = null) {
  log.trace("addEllipsis()");

  setTimeout(() => {
    log.trace("addEllipsis()");
    let $elements;
    if (ancestor) {
      $elements = $(ancestor).find(selector);
    }
    else {
      $elements = $(selector);
    }
    log.trace("$elements:", $elements);
    $elements.dotdotdot(OPTIONS);
  }, DELAY_IN_MS);
}

/**
 * Raise a card, (in z-order), above all its syblings.
 * This is so when the resizable textarea editor is
 * displayed and the user expands it outside the bounds
 * of its parent card, the text area is not below, (in
 * z-order), a sybling card to its right.
 *
 * Note, this appears to work without a delay.
 * I assume because the React tree is already
 * fully rendered when this is called.
 */
function raiseCard(selector, raise = true) {
  //const $card = $(selector).closest(".ui.card");
  const $card = $(selector).closest(".ui-card-container");
  log.trace("raiseCard() raise:", raise);
  log.trace("raiseCard() $card:", $card);
  if (raise) {
    $card.addClass("raised");
  }
  else {
    $card.removeClass("raised");
  }
}

export {addEllipsis, raiseCard};
