// This module defines the NavbarDesktop *container*.
// NOT the NavbarDesktop *component*.

import {connect} from 'react-redux';

// Import the NavbarDesktopComponent from which this
// NavbarDesktopContainer is created.
import NavbarDesktopComponent from '../components/navbar-desktop';

import {cA_History} from '../actions/ui';
//import {cA_SignOut} from '../actions/user';
import {cA_SetShellType} from '../actions/ui';

function mapStateToProps(state) {
  return({
    curUser:state.curUser,
    navbar:state.ui.navbar,
  });
}

const mapDispatchToProps = {cA_History, cA_SetShellType};

const NavbarDesktopContainer = connect(mapStateToProps, mapDispatchToProps)(NavbarDesktopComponent);

export default NavbarDesktopContainer;
