import _ from 'lodash';
import React from 'react';
import {Button, Image, Icon, Popup} from 'semantic-ui-react';
import {log} from 'concierge-common';
import LocalDB from '../client-db/local-db';
import ObjUtils from '../client-db/api/obj-utils';
import ApiUtils from '../client-db/api/api-utils';
import Obj from '../client-db/api/obj';
import ObjComponent from '../containers/obj';
import MyHeader from '../containers/my-header';
import Layout from '../containers/ordered-layout';
//import Layout from './objects-layout';


/**
 * Show the list of items in an Obj's property.
 * E.g. Obj.children, which could be an Act's list of Waypoints,
 * or an Itn's list of Adventures.
 */
class ObjPropPanel extends React.Component {

  /**
   * Add an object of the specified type, (objPropCn), to
   * the object specified by objId's property specified
   * by propName.
   */
  addProp = () => {
    log.trace("ObjPropPanel.addProp(), props:", this.props);
    const {objId, objPropName, objPropCn, cA_History} = this.props;

    let url = "/selectObj?task=add&objId="+objId+
      "&direction=toObj"+
      "&objPropName="+objPropName+
      "&selectCn="+objPropCn+
      "&multiselect=false";
    cA_History("push", url);
  }

  createHeader() {
    const {objId, objPropCn, cA_History} = this.props;
    const {obj} = this.state;
    const cn = Obj.getCnFromId(objId);
    const objType = //cn == LocalDB.ACTS ?
      //"Adventure" : "Itinerary";
      Obj.DISPLAY_NAME(cn);
    const propType = objPropCn == LocalDB.WAYS ?
      Obj.DISPLAY_NAME(LocalDB.WAYS) :
      Obj.DISPLAY_NAME(LocalDB.ACTS);
    const propTypes = objPropCn == LocalDB.WAYS ?
      Obj.DISPLAY_NAME_PLURAL(LocalDB.WAYS) :
      Obj.DISPLAY_NAME_PLURAL(LocalDB.ACTS);
    const objDisplayName = obj && obj.displayName ?
      obj.displayName : objType;

    // e.g. "Beach Walk Waypoints"
    // e.g. "Nelson Trip Adventures"
    const title = objDisplayName + " "+propTypes;

    // "Add a Waypoint to this Adventure"
    const popupContent = "Add a "+propType+" to this "+objType;

    const imageSrc = objPropCn == LocalDB.WAYS ?
      "../img/navbar/ways.png" :
      "../img/navbar/acts.png";

    let rightButton;
    if (obj.edit) {
      rightButton =
        <Popup content={popupContent}
          position="left center"
          trigger={
            <Button primary floated="right" className="icon-and-image"
              onClick={() => this.addProp()} >
              <Icon size="large" name="plus" />
              <Image size="mini" src={imageSrc} />
            </Button>
          } />
    }
    const hasBackButton = true;
    const myHeaderProps = {
      hasBackButton,
      title,
      rightButton,
      cA_History,
    };

    return <MyHeader {...myHeaderProps} />
  }

  static createStateFromProps(props) {
    const {curUser, objId, objPropName, cache, cA_GetObj} = props;
    const obj = ObjUtils.get(curUser, objId, undefined, cache, cA_GetObj);

    if (!objId || !obj) {
      const state = {
        obj:null,
        propObjs:[],
      };
      return state;
    }

    const propIds = obj[objPropName];  // obj.children
    const propObjs = _.map(propIds, propId =>
      ObjUtils.get(curUser, propId, undefined, cache, cA_GetObj));

    const state = {
      obj,
      propObjs,  // e.g. obj.children array
    };
    return state;
  }

  /**
   * Description from React documentation (Apr 14, 2018):
   * The new static getDerivedStateFromProps lifecycle is
   * invoked after a component is instantiated as well as
   * when it receives new props. It can return an object
   * to update state, or null to indicate that the new
   * props do not require any state updates.
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    log.trace("getDerivedStateFromProps(), nextProps:", nextProps);
    log.trace("getDerivedStateFromProps(), prevState:", prevState);
    let nextState = ObjPropPanel.createStateFromProps(nextProps);

    //nextState = _.isEqual(prevState, nextState) ? null : nextState;
    if (_.isEqual(prevState.obj, nextState.obj)) {
      delete nextState.obj;
    }
    if (_.isEqual(prevState.propObjs, nextState.propObjs)) {
      delete nextState.propObjs;
    }
    nextState = _.size(nextState) ? nextState : null;
    log.trace("getDerivedStateFromProps(), return:", nextState);
    return nextState;
  }

  /*
  componentDidMount() {
    const stateProps = ObjPropPanel.createStateFromProps(this.props);
    this.setState(stateProps);
  }
  */

  constructor(props) {
    super(props);
    /*
    this.state = {
      obj:null,
      propObjs:[],
    };
    */
    this.state = ObjPropPanel.createStateFromProps(props);
  }

  render() {
    log.trace("ObjPropPanel.render() this.props:", this.props);
    const {objId, curUser, objPropName, objPropCn} = this.props;
    const {obj, propObjs} = this.state;

    const cn = Obj.getCnFromId(objId);
    const objType = //cn == LocalDB.ACTS ?
      //"Adventure" : "Itinerary";
      Obj.DISPLAY_NAME(cn);
    const propTypes = objPropCn == LocalDB.WAYS ?
      "Waypoints" : "Adventures";
    //const objDisplayName = obj && obj.displayName ?
    //  obj.displayName : objType;

    if (!obj) {
      // NOTE: This should never happen.
      const msg = "The "+objType+" with id("+objId+") cannot be found.";
      return <div>{msg}</div>
    }
    if (!obj.view) {
      // NOTE: This should never happen.
      const msg = "You are not allowed to view the "+objType+" with id("+
        objId+").";
      return <div>{msg}</div>
    }

    const header = this.createHeader();

    const cardContentsProps = {
      parentObj:obj,
      objPropName,  // e.g. "children"
    };
    log.trace("ObjPropPanel.render(), cardContentsProps:", cardContentsProps);
    log.trace("ObjPropPanel.render(), propObjs:", propObjs);

    const emptyMessage="This "+objType+" does not contain any "+propTypes;
    return (
      <div className="cards-panel">
        {header}
        <Layout objects={propObjs} CardContents={ObjComponent}
          curUser={curUser}
          cardContentsProps={cardContentsProps}
          emptyMessage={emptyMessage} />
      </div>
    );
  }
}

export default ObjPropPanel;
