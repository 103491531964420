/**
 * This component is really just a switch statement that decides which
 * content to display based on the passed in shellContents prop.
 */
import React from 'react';
import {log} from 'concierge-common';

import SignedOutPanel from '../containers/signed-out-panel';
//import UsersPanel from '../containers/users-panel';
//import UserPanel from '../containers/user-panel';
import ObjPanel from '../containers/obj-panel';
import ObjsPanel from '../containers/objs-panel';
import ObjPropPanel from '../containers/obj-prop-panel';
import UsagePanel from '../containers/usage-panel';
import AnysPanel from '../containers/anys-panel';
import ImageUploadPanel from '../containers/image-upload-panel';
import ImageCropPanel from '../containers/image-crop-panel';
import CreateMessagePanel from '../containers/create-message-panel';
import SelectObjPanel from '../containers/select-obj-panel';
import MessagesPanel from '../containers/messages-panel';
import LocationMapPanel from '../containers/location-map-panel';
import UrlPropPanel from '../containers/url-prop-panel';
import HomePanel from '../containers/home-panel';

import AnonymousPanel from './anonymous-panel';
import TermsPanel from './terms-panel';
import SignedInPanel from './signed-in-panel';
import ShellContentsInner from './shell-contents-inner';


class ShellContents extends React.Component {

  /*
  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.ui.loader.visible != 
  }
  */

  // Called by React when this Component has loaded in the DOM.
  //componentDidMount() {
  //  log.trace("ShellContents.componentDidMount(), this.props: ", this.props);
  //}

  // TODO: Delete this hack.
  /*
  shouldComponentUpdate(nextProps, nextState) {
    const update = (!this.state) ? true : !this.state.bugShown;
    return update;
  }
  */

  render() {
    log.trace("ShellContents.render(), this.props: ", this.props);
    const props = this.props;
    const {name, ...restProps} = props;
    const collectionName = props.collectionName ?
      props.collectionName :
      props.cn;  // Is cn ever defined?
    log.trace("ShellContents.render(), collectionName: ", collectionName);
    log.trace("ShellContents.render(), restProps: ", restProps);

    // Pull in development settings, if they exist.
    // These are declared in .env.local
    let {REACT_APP_SIGNUP_EMAIL:email,
      REACT_APP_SIGNUP_PASSWORD:password,
      REACT_APP_SIGNUP_ACCEPTED_TERMS:acceptedTerms,
      REACT_APP_SIGNUP_HIDDEN:hidden} = process.env;
    email = email && email.length > 0 ? email : "";
    password = password && password.length > 0 ? password : "";
    acceptedTerms = acceptedTerms == "true" ? true : false;
    hidden = hidden == "false" ? false : true;
    const signUpPanelEnvProps = {
      email,
      password,
      acceptedTerms,
      hidden,
    };

    // NOTE: I might want to change from sending down all props
    // except ones that cause problems when they change, to
    // explicitly sending down the props each XXXPanel wants.
    // That would be more "correct" I think, but more longwinded.
    const redactedProps = Object.assign({}, restProps);
    // No panel needs "notifications", besides App, which is an
    // ancestor of this ShellContents, so it already has it.
    delete redactedProps.notifications;
    // Fallback needs "error", but it is a child of App, so it already
    // has it.
    delete redactedProps.error;

    const fluidProps = {
      className:"fluid",
      textAlign:"center"
    };
    let panel;
    let shellContentsInnerProps;
    log.trace("switch("+name+")");
    log.trace("collectionName: "+collectionName+")");
    log.trace("restProps: ", restProps);
    switch(name) {
      //case "signUp":
      //case "signIn":
      //  panel = <SignUpPanel panelType={name}
      //    {...signUpPanelEnvProps} />
      //  break;

      case "signedIn":
        panel = <SignedInPanel />
        break;

      case "signedOut":
        //panel = <SignedOutPanel {...props} />
        panel = <SignedOutPanel />
        break;

      case "home":
        panel = <HomePanel />
        break;

      case "obj":
        panel = <ObjPanel {...restProps} noDrillDown={true} />
        break;

      case "objs":
        log.trace("ShellContents.render(), collectionName: ", collectionName);
        if (!collectionName) {
          debugger;
        }
        shellContentsInnerProps = fluidProps;
        panel = <ObjsPanel {...restProps} collectionName={collectionName} />
        break;

      case "anys":
        shellContentsInnerProps = fluidProps;
        panel = <AnysPanel {...restProps} />
        break;

      case "usage":
        shellContentsInnerProps = fluidProps;
        panel = <UsagePanel {...restProps} />
        break;

      case "objProp":
        shellContentsInnerProps = fluidProps;
        //panel = <ActWaysPanel {...redactedProps} />
        panel = <ObjPropPanel {...redactedProps} />
        break;

      case "locationMap":
        shellContentsInnerProps = fluidProps;
        panel = <LocationMapPanel {...redactedProps} />
        break;

      case "imageUpload":
        shellContentsInnerProps = fluidProps;
        panel = <ImageUploadPanel {...restProps} />
        break;

      case "imageCrop":
        shellContentsInnerProps = fluidProps;
        panel = <ImageCropPanel {...restProps} />
        break;

      case "selectObj":
        shellContentsInnerProps = fluidProps;
        panel = <SelectObjPanel {...restProps} />
        break;

      case "viewMessages":
        shellContentsInnerProps = fluidProps;
        panel = <MessagesPanel {...restProps} />
        break;

      case "createMessage":
        panel = <CreateMessagePanel {...restProps} />
        break;

      /*
      case "contact":
        panel = <ContactPanel />
        break;

      case "about":
        panel = <AboutPanel />
        break;
      */

      case "terms":
        panel = <TermsPanel />
        break;

      case "anonymous":
        panel = <AnonymousPanel />
        break;

      case "urlProp":
        panel = <UrlPropPanel {...restProps} />
        break;

      default:
        //log.bug("ShellContents.render() default case because "+
        //  "props.name of ("+name+") is not handled.");
        panel = <HomePanel />
        break;
    }

    return(
      <ShellContentsInner {...shellContentsInnerProps}>
        {panel}
      </ShellContentsInner>
    );
  };
}

export default ShellContents;
