// This module defines the LocationMapPanel *container*.
// NOT the LocationMapPanel *component*.
import {connect} from 'react-redux';

import LocationMapPanelComponent from '../components/location-map-panel';

// Import action creators that this component will use.
import {cA_SetLoader} from '../actions/ui';
import {cA_History} from '../actions/ui';
import {cA_MultiAction} from '../actions/ui';
import {cA_GetObj} from '../client-db/api/obj-utils';

function mapStateToProps(state) {

  const props = {
    curUser:state.curUser,
    cache: state.cache,
  };

  return props;
}
const mapDispatchToProps = {cA_MultiAction, cA_History, cA_SetLoader, cA_GetObj};
const LocationMapPanelContainer = connect(mapStateToProps, mapDispatchToProps)
  (LocationMapPanelComponent);
export default LocationMapPanelContainer;
