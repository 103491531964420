import {log} from 'concierge-common';
import * as ACTION_TYPES from '../actions/types';
import {cA_SetError} from '../actions/error';
import ObjUtils from './utils/obj-utils';


/**
 * This reducer manages the value of the application's
 * store.state.acts property.
 *
 * @param {acts} acts The current store.state.acts object.
 * We should never modify this value.
 *
 * @param {Object} action The current action to be performed.
 * @param {String} action.type This tells us "what" we need to do.
 * @param {Object} action.payload This is the data that we will use to
 * complete the action.
 * @param {Object} action.payload.acts The new values that we are
 * going to use to alter the state.acts property.
 */
function actsReducer(acts = ObjUtils.EMPTY_ACTS, action) {
  log.trace("actsReducer("+action.type+") acts:", acts);
  log.trace("actsReducer(), payload:", action.payload);
  log.trace("actsReducer(), action:", action);

  const {payload = {}} = action;
  /*
  if (!payload) {
    // Redux "interal" action types such as @@redux/INIT
    // don't have payloads, and we should ignore them
    // anyway.  But, "_P" pending actions have no payload either,
    // so we can't ignore actions with empty payloads.
    return acts;
  }
  */
  let newActs = acts;
  switch(action.type) {

    case ACTION_TYPES.SET_ACTS: {
      log.trace("SET_ACTS actsReducer(), action:", action);
      if (payload.error) {
        action.asyncDispatch(cA_SetError(payload.error));
        return acts;
      }
      newActs = Object.assign({}, acts, payload);
      return newActs;
    }

    case ACTION_TYPES.SIGN_OUT_S:
      if (payload.error) {
        return acts;
      }
      newActs = ObjUtils.EMPTY_ACTS;
      return newActs;

    default:
      log.trace("acts-reducer default");
      return acts;
  }
};

export default actsReducer;
