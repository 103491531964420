// This module defines the FilterAndSortButton *container*.
// NOT the FilterAndSortButton *component*.
import {connect} from 'react-redux';

// Import the FilterAndSortButtonComponent from which this
// FilterAndSortButtonContainer is created.
import FilterAndSortButtonComponent from '../components/filter-and-sort-button';

// Import action creators that this component will use.
import {cA_SetFilterAndSortVisibility} from '../actions/ui';
import {cA_SetFilterAndSort} from '../actions/ui';
import {cA_PropsEditor} from '../actions/ui';

function mapStateToProps(state) {
  const props = {
    curUser:state.curUser,
    filterAndSort:state.ui.filterAndSort,
    shellType:state.ui.shellType,
  };
  return props;
}
const mapDispatchToProps = {cA_SetFilterAndSortVisibility,
  cA_PropsEditor, cA_SetFilterAndSort};
const FilterAndSortButtonContainer = connect(mapStateToProps, mapDispatchToProps)
  (FilterAndSortButtonComponent);
export default FilterAndSortButtonContainer;
