import _ from 'lodash';
import React from 'react';
import {Button, Image, Icon, Popup} from 'semantic-ui-react';
import {log} from 'concierge-common';
import {USAGE} from '../global-constants';
import ObjUtils from '../client-db/api/obj-utils';
import MyHeader from '../containers/my-header';
//import Layout from './objects-layout';
import Layout from './masonry-layout';
import FilterAndSortButton from '../containers/filter-and-sort-button';
//import LocationUtils from './utils/location-utils';


class UsagePanel extends React.Component {

  createHeader() {
    log.trace("UsagePanel.createHeader(), props: ", this.props);
    const {curUser, cA_History, onBeforeBack, usage, shellType} = this.props;
    let {title} = this.props;
    title = title ? title : usage && usage.title ? usage.title :
      "Used By:";

    const rightButton = <FilterAndSortButton collectionName={USAGE} />
  
    const myHeaderProps = {
      hasBackButton:true,
      onBeforeBack,
      title,
      rightButton,
      cA_History,
      shellType,
      collectionName:USAGE,
    };

    return <MyHeader {...myHeaderProps} />
  }

  getObjsForUsage() {
    log.trace("Enter getObjsForUsage(): props", this.props);
    const {cache, usage, curUser, cA_GetObj} = this.props;
    if (!usage) {
      return [];
    }

    const objs = [];
    for (const collectionName in usage.usedBy) {
      // collectionName is one of: "users", "ways", "acts", "itns".
      const collection = usage.usedBy[collectionName];
      // collection is something like usage.usedBy.acts
      for (const objId in collection) {
        // objId is one "property" name in the collection.
        // E.g. "ID1234" in the example line below:
        // usage.usedBy.ways["ID1234"]
        const objInfo = collection[objId];
        // objInfo is info about the object.  Might be null.
        // E.g. {asChild:true}
        // NOTE: Below we are checking "usedAs", NOT "usedBy".
        if (objInfo[usage.usedAs]) {
          log.trace("objId: ", objId);
          let obj = ObjUtils.get(curUser, objId, undefined, cache, cA_GetObj);
          if (!obj || obj._id.startsWith("default")) {
            cA_GetObj(curUser, objId);
          }
          else {
            objs.push(obj);
          }
        }
      }
    }
    return objs;
  }

  /*
  componentDidMount() {
    const {cA_Notify, cA_Confirmation} = this.props;
    LocationUtils.possiblyInitLocationServices(cA_Notify,
      cA_Confirmation);
  }
  */

  render() {
    log.trace("UsagePanel.render() this.props:", this.props);
    const props = this.props;
    const {cache, usage, curUser, selectable, onSelect, busy,
      filterAndSortVisible} = props;

    //if (!usage) {
    //  return <div>usage is not set.</div>
    //}

    //const {objs} = this.state;
    const objs = this.getObjsForUsage();
    
    const header = this.createHeader();
    log.trace("UsagePanel.render() header:", header);

    const emptyMessage = busy ?
      "Looking for Objects..." :
      "No Objects found";

    return (
      <div className="cards-panel">
        {header}
        <Layout objects={objs}
          selectable={selectable}
          onSelect={onSelect}
          emptyMessage={emptyMessage}
          filterAndSortVisible={filterAndSortVisible} />
      </div>
    );
  }
}

export default UsagePanel;
