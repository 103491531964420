/**
 */
import React from 'react';
import {Label, Button, Icon} from 'semantic-ui-react';
import {log, MyError} from 'concierge-common';
import MyPopup from '../containers/my-popup';

class UrlButton extends React.Component {

  handleClickEdit(e) {
    if (e) {
      e.stopPropagation();
    }
    const {obj, collectionName, propName,
      index, cA_History} = this.props;

    // Index within the "urls" array for this UrlButton.
    const propIndexParam = index != undefined ?
      "&propIndex="+index :
      "";

    const url = "/urlProp?objId="+obj._id+
      "&collectionName="+collectionName+
      "&propName="+propName+  // E.g. "urls"
      propIndexParam;
    cA_History("push", url);
  }

  handleClickUrl(displayName, description, url, internal) {
    const {cA_History, cA_Confirmation, cA_SetError} = this.props;
    log.trace("UrlButton.handleClickUrl(), url:", url);
    log.trace("UrlButton.handleClickUrl(), internal:", internal);

    if (!url) {
      const msg = url ?
        'The Link button\'s URL is not valid.  URL: "'+url+'"' :
        "The Link button's URL is blank/empty.";
      const props = {msg};
      const error = MyError.createSubmitError(props);
      cA_SetError(error);
      return;
    }

    if (internal) {
      // url is an "internal" link to a page in our application.
      // E.g. "/acts", "/act?actId=5a8d351e8f31fd5957c94406",
      // or "/actWays?actId=5a8d351e8f31fd5957c94406&propName=
      // ways&propDisplayName=Waypoints"
      log.trace("Calling cA_History() with url:", url);
      cA_History("push", url);
    }
    else {
      // This is an "external" link to a website outside of ours.

      // Prefix the url if it does not already start with "http:"
      // NOTE: Actually, we look for several special strings at the
      // the start of the URL although we don't expect others to
      // be used.  ???
      if (!url.startsWith("http:") &&
          !url.startsWith("https:") &&
          !url.startsWith("ftp:") &&
          !url.startsWith("gopher:") &&
          !url.startsWith("news:") &&
          !url.startsWith("telnet:") &&
          !url.startsWith("mailto:") &&
          !url.startsWith("file:") &&
          !url.startsWith("WAIS:")) {
        url = "http://"+url;
      }

      // Warn user about opening an external url.
      // TODO: Switch to Confirmation.
      //const msg = 'Opening external URL('+displayName+'): "'+
      //  url+"\"  Creator's description: "+description;

      const message1 = description ? 
        'Do you want to open the external website('+
        displayName+')? Description: "'+description+'"' :
        'Do you want to open the external website('+
        displayName+'?';
      const message2 = 'The URL:"'+url+'" will open in its own browser tab.';
      const okLabel = "Open Website";
      const okButtonProps = {positive:false, negative:false, primary:true,
        inverted:false};
      const iconName = "external";  // "linkify"
      cA_Confirmation("Open External Website?", message1, message2,
        ()=>cA_History("openUrlInTab", url, "_externalUrl"),
        okLabel, undefined, undefined, 0,
        undefined, undefined, undefined, undefined,
        okButtonProps, iconName);
    }
  }

  createComponent() {
    const {props} = this;
    const {displayName, description, obj, url, internal} = props;
    log.trace("UrlButton.createComponent(), internal:", internal);

    const editable = obj.edit;
    /*
    const deleteUrlIcon = editable ?
      //<Icon className="delete-button" name="cancel" /> :
      <Icon className="delete-button" name="ellipsis vertical" /> :
      undefined;
    */
    // Set button color to look the same as the normal semantic
    // ui "primary" button color, same as other buttons, if
    // the url points to an "internal" application page, e.g. to
    // an Adventure.
    // Set button color to something special/different if
    // the url points to an "external" website.
    const primary = internal;
    const color = !internal ? "violet" : undefined;
    const subButtonColor = !internal ? "violet" : "blue";

    // Create a child "sub button" that is displayed "inside" the
    // UrlButton.
    /*
    let editButton = editable ?
      <Button icon basic
        className="superbasic sub-button"
          onClick={(e)=>this.handleClickEdit(e)}
      >
        <Icon name="ellipsis vertical" />
        <Label color={subButtonColor} basic className="superbasic">
          Edit/Delete
        </Label>
      </Button> :
      undefined;
    */
    const subButtonClassName =
        "button ui basic superbasic sub-button "+subButtonColor;
    let editButton = editable ?
      <div className={subButtonClassName}
          onClick={(e)=>this.handleClickEdit(e)}
      >
        <Icon name="ellipsis vertical" />
        <Label color={subButtonColor} basic className="superbasic">
          Edit/Delete
        </Label>
      </div> :
      undefined;
    editButton = editButton ?
      <MyPopup trigger={editButton}
        content={"Edit/Delete this button."} /> :
      undefined;

    let className = "prop-button ellipsis";
    className += editable ? " has-sub-button" : "";

    const button =
      <Button basic primary={primary} color={color} className={className}
        onClick={()=>this.handleClickUrl(displayName,
          description, url, internal)}
      >
        {displayName}
        {editButton}
      </Button>

    // If the user gave us a description for the URL, wrap it in
    // a popup that will be displayed on desktop machines.
    const component = description && description.trim().length > 0 ?
      <MyPopup trigger={button} content={description} /> :
      button;

    return component;
  }

  render() {
    log.trace("UrlButton.render() this.props:", this.props);
    const component = this.createComponent();
    return component;
  };
}

export default UrlButton;
