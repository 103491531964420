/**
 * E.g. When a user is editing a UrlProp.
 */
import _ from 'lodash';
import React from 'react';
import {Input, TextArea, Select, Card} from 'semantic-ui-react';
import {log, MyError} from 'concierge-common';
import LocalDB from '../client-db/local-db';
import Obj from '../client-db/api/obj';


class UrlProp extends React.Component {

  handleChangeDisplayName(e, data) {
    let value = data.value ? data.value : "";
    //value = value.trim();
    log.trace("handleChangeDisplayName(), value:", value);

    const urlProp = Object.assign({}, this.state.urlProp);
    urlProp.displayName = value;
    const stateProps = {urlProp};
    this.setState(stateProps);
  }

  handleChangeSelectIntExt(e, data) {
    log.trace("handleChangeSelectIntExt(), e.target:", e.target);
    log.trace("handleChangeSelectIntExt(), data.value:", data.value);
    const internal = data.value == "internal";
    const urlProp = Object.assign({}, this.state.urlProp);
    urlProp.internal = internal;

    let errors = Object.assign({}, this.state.errors);
    log.trace("handleChangeSelectIntExt(), internal:", internal);
    log.trace("handleChangeSelectIntExt(), urlProp.url:", urlProp.url);
    // Clear the url field if it is not the right sort of value
    // for the new internal/external setting.
    if ((!internal && !urlProp.url.startsWith("http://")) ||
        (!internal && !urlProp.url.startsWith("https://")) ||
        (internal && !urlProp.url.startsWith("/"))) {
      // internal/external value does not agree with current
      // url value.  (NOTE: This just a crude approximation.)
      // Clear out the url field.
      urlProp.url = "";
      log.trace("handleChangeSelectIntExt(), clear out url");
    }
    else {
      errors.url = false;
    }

    const stateProps = {urlProp, errors};
    this.setState(stateProps);

    this.handleChangeUrl(this.state.urlProp.url);

    // Save it out to the server.
    this.setUrlPropIfAllFieldsValid(errors, urlProp);
  }

  addUrlProp(urlProp) {
    const {props} = this;
    const {cA_SetError, obj, propName} = props;
    
    log.trace("obj:", obj);
    if (!obj || obj._id.startsWith("default")) {
      const msg = "UrlProp.addUrlProp() could not get "+
        "the parent object that contains, or will contain, the "+
        "Url property.  Parent obj: "+obj;
      const props = {msg, severity:MyError.BUG};
      const error = MyError.createSubmitError(props);
      cA_SetError(error);
      return;
    }

    //this.trimUrlProp(urlProp);

    // Add the urlProp to the end of the array.
    let urlPropArray = obj[propName];
    urlPropArray = urlPropArray ? urlPropArray : [];
    urlPropArray.push(urlProp);

    // Save it out to the server.
    this.saveUrlPropArray(urlPropArray);
  }

  /**
   * Replace the current url property in the array of urls.
   * Save the whole array out to the Act.urls on the server.
   */
  setUrlProp(urlProp) {
    log.trace("UrlProp.setUrlProp(), urlProp:", urlProp);
    const {props} = this;
    log.trace("props: ", props);
    const {cA_SetError, obj, propName} = props;
    log.trace("obj:", obj);
    if (!obj || obj._id.startsWith("default")) {
      const msg = "UrlProp.setUrlProp() could not get "+
        "the parent object that contains, or will contain, the "+
        "Url property.  Parent obj: "+obj;
      const props = {msg, severity:MyError.BUG};
      const error = MyError.createSubmitError(props);
      cA_SetError(error);
      return;
    }

    // TODO: Clean up validation.
    UrlProp.trimUrlProp(urlProp);
    log.trace("After trimUrlProp(), urlProp: ", urlProp);

    let urlPropArray = obj[propName];
    urlPropArray = urlPropArray ? urlPropArray : [];
    let {propIndex} = props;
    log.trace("propIndex: ", propIndex);
    log.trace("urlPropArray: ", urlPropArray);
    /*
    if (urlPropArray instanceof Array &&
        propIndex === null || propIndex === undefined ||
        propIndex < 0) {
      // This UrlProp was called to create a new prop,
      // so the one we are editing is the last one in the
      // array.  NOTE: This functionality is not currently used.
      propIndex = urlPropArray.length - 1;
    }
    else */if (propIndex >= urlPropArray.length) {
      // propIndex is invalid.
      const msg = "UrlPropPanel.setUrlProp() propIndex("+
        propIndex+") is not valid for array length: "+
        urlPropArray.length;
      const props = {msg, severity:MyError.BUG};
      const error = MyError.createSubmitError(props);
      cA_SetError(error);
      return;
    }
    else {
      // Replacing the urlProp at index.
      // Create a copy of the array and replace the element
      // in the copy of the array.
      urlPropArray = urlPropArray.slice(0);
      urlPropArray.splice(propIndex, 1, urlProp);
    }

    log.trace("UrlProp.setUrlProp(), calling saveUrlPropArray() with urlPropArray:", urlPropArray);
    this.saveUrlPropArray(urlPropArray);
  }

  /**
   * Modifies the passed in urlProp.
   */
  static trimUrlProp(urlProp) {
    urlProp.displayName = urlProp.displayName ?
      urlProp.displayName.trim() : "";
    urlProp.description = urlProp.description ?
      urlProp.description.trim() : "";
    urlProp.url = urlProp.url ?
      urlProp.url.trim() : "";

    if (urlProp.displayName.length < 1) {
      urlProp.displayName = "New Link";
    }
  }

  /**
   * Save the passed in url property, which could be an array
   * or a single url, out to the server.
   * NOTE: I don't think the above comment about the passed in
   * value being a single URL is true.
   */
  saveUrlPropArray(urlPropArray) {
    const {props} = this;
    const {curUser, obj, propName} = props;
    log.trace("UrlProp.saveUrlPropArray(), urlPropArray:", urlPropArray);

    if (!Array.isArray(urlPropArray)) {
      log.bug("url-prop.js saveUrlPropArray() passed non-array urlPropArray: ",
        urlPropArray);
    }
    //const collectionName = Obj.getCnFromId(obj._id);
    LocalDB.db[obj.cn].findOne().where("_id").eq(obj._id).update({
      $set:{urls:urlPropArray}
    });
  }

  handleBlurDisplayName(e) {
    log.trace("UrlProp.handleBlurDisplayName(), e:", e);
    log.trace("UrlProp.handleBlurDisplayName(), value:", e.target.value);
    log.trace("UrlProp.handleBlurDisplayName(), this.state.urlProp:",
      this.state.urlProp);
    let displayName = e && e.target && e.target.value;
    displayName = displayName && displayName.length ?
      displayName : "New Link";
    log.trace("handleBlurDisplayName(), displayName:", displayName);
    //const {urlProp} = this.props;
    const urlProp = Object.assign({}, this.state.urlProp);
    urlProp.displayName = displayName;
    //this.setUrlProp(urlProp);  // Causes RxDB update conflict.
    this.setState({urlProp});
    const {errors} = this.state;
    this.setUrlPropIfAllFieldsValid(errors, urlProp);
  }

  handleBlurDescription(e) {
    const description = e && e.target && e.target.value;
    //const {urlProp} = this.props;
    const {urlProp} = this.state;
    urlProp.description = description;
    const {errors} = this.state;
    this.setUrlPropIfAllFieldsValid(errors, urlProp);
  }

  handleChangeUrl(newValue) {
    log.trace("this.state.urlProp.internal:",
      this.state.urlProp.internal);
    /*setTimeout(()=>{*/
      const errors = Object.assign({}, this.state.errors);
      if (this.state.urlProp.internal) {
        // Internal URL, so no validate.  TODO: Add validation.
        errors.url = false;
      }
      else {
        // External URL, so use the HTML input "url" element's
        // error checking to validate the value.
        //const parentElement = this.urlInput.inputRef.parentElement;
        try {
          const parentElement = this.urlInputRef.current.inputRef
            .current.parentElement;
          const invalid = parentElement.querySelectorAll(":invalid");
          errors.url = invalid && invalid.length > 0;
        }
        catch(err) {
          log.bug("UrlProp.handleChangeUrl() got exception when"+
            "trying to access urlInputRef's parentElement"); 
        }
      }
      log.trace("handleChangeUrl(), errors:", errors);
      const urlProp = Object.assign({}, this.state.urlProp);
      urlProp.url = newValue;
      this.setState({urlProp, errors});
    /*}, 1);*/
  }

  handleBlurUrl(e) {
    //log.trace("e:", e);
    //log.trace("e.target:", e.target);
    //log.trace("e.target.valid:", e.target.valid);
    const parentElement = e.target.parentElement;
    //log.trace("parentElement:", parentElement);
    const invalid = parentElement.querySelectorAll(":invalid");
    log.trace("handleBlurUrl(), invalid:", invalid);
    /*
    const valid = e.target.parentElement.querySelectorAll(":valid");
    log.trace("valid:", valid);
    */

    const errors = Object.assign({}, this.state.errors);
    errors.url = invalid && invalid.length > 0;
    log.trace("handleBlurUrl(), errors:", errors);
    this.setState({errors});

    let url = e && e.target && e.target.value;
    url = url && url.length > 3 ? url : "http://doc.govt.nz";
    //const {urlProp} = this.props;
    const {urlProp} = this.state;
    urlProp.url = url;
    this.setUrlPropIfAllFieldsValid(errors, urlProp);
  }

  setUrlPropIfAllFieldsValid(errors, urlProp) {
    const {cA_SetError} = this.props;
    if (errors.displayName || errors.description || errors.url) {
      const msg = "Values are not legal.";
      const props = {msg};
      const error = MyError.createSubmitError(props);
      cA_SetError(error);
      return;
    }
    this.setUrlProp(urlProp);
  }

  static get options() {return [
    { key: 'internal', text: 'Internal', value: 'internal' },
    { key: 'external', text: 'External', value: 'external' },
  ]};

  // TODO: This same value is copied elsewhere.  Put it in one place.
  // src/utils/obj-utils.js
  static get DEFAULT_URL_PROP() {
    return {
      displayName:"New Link",
      description:"",
      //url:"http://doc.govt.nz",
      url:"",
      internal:false,
    };
  };

  static createStateFromProps(props) {
    let {urlProp} = props;
    urlProp = urlProp ? urlProp : {};
    UrlProp.trimUrlProp(urlProp);
    const state = {
      //obj:props.obj,  Needed for getDerivedStateFromProps() hack
      urlProp,
      errors:{
        displayName:false,
        description:false,
        url:false,
      }
    };
    return state;
  }

  // NOTE: We could do this, but it is really a hack.
  // Instead, we are having our parent UrlPropPanel not
  // mount/construct us until it has retrieved the actual
  // obj from the RxDB/PouchDB.
  /*
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState && !Obj.idDefaultObj(prevState.obj)) {
      // Our parent component UrlPropPanel finally passed
      // us the actual object, so no let this UrlProp
      // component be a "controlled" component.
      return null;
    }
    log.trace("getDerivedStateFromProps(), nextProps:", nextProps);
    log.trace("getDerivedStateFromProps(), prevState:", prevState);
    let nextState = UrlProp.createStateFromProps(nextProps);
    nextState = _.isEqual(prevState, nextState) ? null : nextState;
    log.trace("getDerivedStateFromProps(), return:", nextState);
    return nextState;
  }
  */

  constructor(props) {
    super(props);
    log.trace("UrlProp.constructor(), props: ", props);
    this.state = UrlProp.createStateFromProps(props);
    this.urlInputRef = React.createRef();
  }

  render() {
    log.trace("UrlProp.render(), this.props:", this.props);
    const {urlProp, errors} = this.state;
    log.trace("render(), errors:", errors);

    const displayNameValue = urlProp && urlProp.displayName ?
      urlProp.displayName : "";
    const descriptionValue = urlProp && urlProp.description ?
      urlProp.description : undefined;  // uncontrolled component
    const urlValue = urlProp && urlProp.url ?
      urlProp.url : undefined;  // uncontrolled component
    const internalValue = urlProp && urlProp.internal ?
      "internal" : "external";

      /*
          <Label basic className="superbasic display-name-label">Label:</Label>
          <Label basic className="superbasic">
            Description:
          </Label>
          <Label basic className="superbasic">URL:</Label>
      */
    const classNameUrl = "url" + (errors.url ? " error" : "");
    log.trace("classNameUrl:", classNameUrl);
    const urlType = urlProp.internal ? "text" : "url";
    log.trace("urlType:", urlType);
    const urlPlaceholder = urlProp.internal ?
      "/act?actId=5a8d258156ddb74c56deecc8" :
      "http://doc.govt.nz"
    return (
      <Card className="url-prop">
        <Card.Content>
          <Input className="display-name" value={displayNameValue}
            placeholder='Button Label'
            maxLength={20}
            autoCapitalize="none"
            onChange={this.handleChangeDisplayName.bind(this)} 
            onBlur={this.handleBlurDisplayName.bind(this)} />
        </Card.Content>
        <Card.Content>
          <TextArea className="description" defaultValue={descriptionValue}
            placeholder='Helpful description...'
            maxLength={100}
            autoCapitalize="sentences"
            onBlur={this.handleBlurDescription.bind(this)} />
        </Card.Content>
        <Card.Content>
          <Select compact options={UrlProp.options}
            defaultValue={internalValue}
            onChange={this.handleChangeSelectIntExt.bind(this)} />
          <Input className={classNameUrl} defaultValue={urlValue}
            placeholder={urlPlaceholder}
            autoCapitalize="off"
            type={urlType}
            ref={this.urlInputRef}
            onChange={(e, data)=>this.handleChangeUrl(data.value)}
            onBlur={this.handleBlurUrl.bind(this)} />
        </Card.Content>
      </Card>
    );
  }
}

export default UrlProp;


