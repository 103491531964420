// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://bit.ly/CRA-PWA

import store from './store';
import {cA_SwSetRegistered} from './actions/service-worker';


const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export function register(config) {
  console.log("process.env.NODE_ENV: ", process.env.NODE_ENV);
  //console.log("navigator: ", navigator);
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    console.log("In process.env.NODE_ENV");
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    //console.log("publicUrl: ", publicUrl);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets;
      // see https://github.com/facebook/create-react-app/issues/2374
      console.log("ERROR: publicUrl.origin !== window.location.origin");
      //console.log("publicUrl.origin: ", publicUrl.origin);
      //console.log("window.location.origin: ", window.location.origin);
      return;
    }

    //console.log("Calling window.addEventListener()");
    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      if (isLocalhost) {
        // This is running on localhost.
        // Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, config);

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready.then(() => {
          console.log(
            'This web app is being served cache-first by a service ' +
              'worker. To learn more, visit https://bit.ly/CRA-PWA'
          );
        });
      } else {
        // Is not localhost. Just register service worker
        registerValidSW(swUrl, config);
      }
    });

    // This was added as part of the service worker update
    // code.  Code to call skipWaiting() already exists in
    // service-worker.js???
    // This listener is not needed I think.
    /*
    window.addEventListener('message', (event) => {
      if (!event || !event.data || !event.data.type) {
        // Event does not have the values we need to test,
        // so ignore it.
        return;
      }

      if (event.data.source == "react-devtools-bridge") {
        // We get alot of these.  Ignore them?
        return;
      }

      const {type} = event.data;
      console.log("window message type: ", type);
      if (type === 'INIT_INSTANCE') {
        console.log("Got INIT_INSTANCE message.  Call window.skipWaiting()");
        //window.skipWaiting();
      }
      if (event.data && event.data.type === 'SKIP_WAITING') {
        console.log("Got SKIP_WAITING message");
        // I don't think I need to do this because service-worker.js
        // does it.
        //window.skipWaiting();
      }
    });
    */

    /*
    window.addEventListener('install', function(event) {
      // Perform any other actions required for your
      // service worker to install, potentially inside
      // of event.waitUntil();
    });
    window.addEventListener('activate', function(event) {
      // Perform any other actions required for your
      // service worker to install, potentially inside
      // of event.waitUntil();
    });
    */
  }
  else {
    console.log("Running locally, so NOT installing serviceWorker.");
  }
}

/**
 * config:{onInstalled:<someFunc1>, onUpdateFound:<someFunc2>}
 */
function registerValidSW(swUrl, config) {
  navigator.serviceWorker
    .register(swUrl)
    .then(registration => {
      //console.log("In then(registration..., registration: ", registration);
      store.dispatch(cA_SwSetRegistered(registration));
      registration.onupdatefound = (updateEvent) => {
        //console.log("In onupdatefound, updateEvent: ", updateEvent);
        // NOTE:  Look at updateEvent.currentTarget.active and .waiting
        // updateEvent.type == "updatefound" and other values?
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          //console.log("installingWorker == null");
          return;
        }
        installingWorker.onstatechange = () => {
          //console.log("In installingWorker.onstatechange callback");
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // At this point, the updated precached content has been fetched,
              // but the previous service worker will still serve the older
              // content until all client tabs are closed.
              console.log(
                'New content is available and will be used when all ' +
                  'tabs for this page are closed. See https://bit.ly/CRA-PWA.'
              );

              // Execute callback
              if (config && config.onUpdateFound) {
                config.onUpdateFound(registration);
              }
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              console.log('Content is cached for offline use.');

              // Execute callback
              if (config && config.onInstalled) {
                config.onInstalled(registration);
              }
            }
          }
        };
      };
    })
    .catch(error => {
      console.error('Error during service worker registration:', error);
    });
}

function checkValidServiceWorker(swUrl, config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, {
    headers: { 'Service-Worker': 'script' },
  })
    .then(response => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type');
      if (response.status === 404 ||
          (contentType != null && contentType.indexOf('javascript') === -1)) {
        console.log("No service worker found.");
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        console.log("Service worker found.  Calling registerValidSW()");
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log(
        'No internet connection found. App is running in offline mode.'
      );
    });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then(registration => {
        registration.unregister();
      })
      .catch(error => {
        console.error(error.message);
      });
  }
}

// Call this function when the user presses the "Install Now" button
// in the alert we displayed when dispatched the SW_UPDATE action.
// Or, just have the serviceWorkerReducer call it without asking
// the user.
export function updateServiceWorker(swRegistration) {
  //console.log("In updateServiceWorker(), swRegistration: ",
  //  swRegistration);
  const registrationWaiting = swRegistration.waiting;
  if (registrationWaiting) {
    //console.log("In updateServiceWorker(), postMessage(SKIP_WAITING)");
    registrationWaiting.postMessage({type: 'SKIP_WAITING'});
    registrationWaiting.addEventListener('statechange', e => {
      if (e.target.state === 'activated') {
        // Service worker has been updated, now reload the page with
        // the new app/data.
        window.location.reload();
      }
    });
  }
};

