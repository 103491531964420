/**
 */
import _ from 'lodash';
//import $ from 'jquery';
import React from 'react';
import {Card} from 'semantic-ui-react';
import {RIETextArea} from 'riek';
import {log} from 'concierge-common';
import {addEllipsis, raiseCard} from './utils/dot-dot-dot';
import MyPopup from './my-popup';


class MyCardDescription extends React.Component {

  /*
  setTextAreaHeight() {
    log.trace("MyCardDescription.setTextAreaHeight()");
    log.trace("this.selector:", this.selector);
    const test = "span"+this.selector;
    const $element = $(test);
    log.trace("$element:", $element);
    if (!$element || $element.length < 1) {
      log.bug("MyCardDescription.setTextAreaHeight(), $element:",
        $element);
      return;
    }

    const element = $element[0];
    if (!element) {
      log.bug("MyCardDescription.setTextAreaHeight(), element:",
        element);
      return;
    }

    const clientHeight = element.clientHeight;
    log.trace("clientHeight:", clientHeight);
    const offsetHeight = element.offsetHeight;
    log.trace("offsetHeight:", offsetHeight);
    const scrollHeight = element.scrollHeight;
    log.trace("scrollHeight:", scrollHeight);

    setTimeout(()=>{
      const test = "textarea"+this.selector;
      const $element = $(test);
      const element = $element[0];
      element.height = 300;
    }, 1000);
  }
  */

  /**
   * Called by the RIETextArea every time the user enters/deleted a
   * character in the field.
   */
  handleChange = (task) => {
    log.trace("MyCardDescription.handleChange(), task:", task);
    this.setState({...task});
    const {change} = this.props;
    change({...task});
  }
  handleValidationFail = (result, value, cancelEditing) => {
    log.trace("handleValidationFail(), value:", value);
    const {handleValidationFail} = this.props;
    handleValidationFail(result, value/*, cancelEditing*/);
    cancelEditing();
  }

  /*
  handleBeforeStart = () => {
    log.trace("MyCardDescription().handleBeforeStart()");
    raiseCard(this.selector);
    //this.setTextAreaHeight();
  }
  */

  handleAfterStart = () => {
    log.trace("MyCardDescription().handleAfterStart()");
    raiseCard(this.selector);
    //this.setTextAreaHeight();
  }


  handleAfterFinish = () => {
    log.trace("MyCardDescription.handleAfterFinish()");
    raiseCard(this.selector, false);
    addEllipsis(this.selector);
    //this.updateStateTextIsTruncatedLater();
  }

  constructor(props) {
    super(props);
    const {text} = props;
    this.state = {text};
    this.id = _.uniqueId("id_");
    this.selector = ".rie-text-area.multi-line-ellipsis."+this.id;
  }

  /**
   * Description from React documentation (Apr 14, 2018):
   * The new static getDerivedStateFromProps lifecycle is
   * invoked after a component is instantiated as well as
   * when it receives new props. It can return an object
   * to update state, or null to indicate that the new
   * props do not require any state updates.
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    log.trace("getDerivedStateFromProps(), nextProps:", nextProps);
    log.trace("getDerivedStateFromProps(), prevState:", prevState);
    let nextState = Object.assign({}, prevState);
    nextState.text = nextProps.text;
    nextState = _.isEqual(prevState, nextState) ? null : nextState;
    log.trace("getDerivedStateFromProps(), return:", nextState);
    return nextState;
  }

  componentDidUpdate() {
    log.trace("this.selector:", this.selector);
    addEllipsis(this.selector);
    //this.updateStateTextIsTruncatedLater(); Can't do this here.
  }

  componentDidMount() {
    addEllipsis(this.selector);
    this.updateStateTextIsTruncatedLater();
  }

  /**
   * The RieTextArea will "eventually" display the "dot dot dot"
   * indicator if the text is too long.  When it does this, it
   * adds the class "ddd-truncated" to the rie-text-area element.
   * We use the existence of "ddd-truncated" to decided whether to
   * set the "this.state.textIsTruncated" flag that render() uses
   * to decided whether to use a MyPopup to display the full text.
   *
   * Because RieTextArea takes "some time" to do that, we have to
   * wait for that to happen.  I don't know of an RieTextArea callback
   * we can use to "wait" for that.  So, we just use a timeout.
   * 
   * NOTE: This seems like a hack.
   */
  updateStateTextIsTruncatedLater() {
    // 100ms seems to be enough, but using larger number to be sure.
    setTimeout(()=>this.updateStateTextIsTruncated(), 1000);
  }

  updateStateTextIsTruncated() {
    const {spanElement} = this;
    if (!spanElement) {
      return;
    }
    const truncatedElements = spanElement.getElementsByClassName(
      "ddd-truncated");
    const textIsTruncated = truncatedElements.length > 0;
    if (this.state.textIsTruncated != textIsTruncated) {
      // The if test I do above is unneeded.  React should only
      // update the state if it actually changed.
      this.setState({textIsTruncated});
    }
  }

  /*
  handleClick() {
    log.trace("handleClick()");
    const {textIsTruncated} = this.state;
    if (textIsTruncated) {
      this.setState({showPopup:true});
    }
  }
  */

  render() {
    log.trace("MyCardDescription.render() this.props:", this.props);
    const props = this.props;
    const {propName, text, validate, maxLength, isEditable} = props;
    let {className} = props;
    className = className ? ("my-card-description "+className) :
      "my-card-description";
    className += this.raiseCard ? " "+this.raiseCard : "";
    const {textIsTruncated} = this.state;

    // Don't show this component at all if the text is null.
    // I.e. this component won't take up any space and its
    // faint divider line won't be shown either.
    //
    // If text is "" (the empty string) we will show this
    // component's icon with a blank area next to the icon.
    if (text == null) {
      return null;
    }

    const editProps = {maxLength};

    let rieTextAreaClassName =
      "rie-text-area multi-line-ellipsis "+this.id;
    rieTextAreaClassName += isEditable ? " editable" : "";

    // If it is editable, let RIETextArea handle the click.
    // If it is not editable, we can display the overflowing
    // text in a popup.
    //const onClick = isEditable ? undefined :
    //  this.handleClick.bind(this);

            //beforeStart={this.handleBeforeStart}
          //onClick={onClick}
    let component =
      <Card.Description className={className}>
        <span ref={node=>this.spanElement = node}
        >
          <RIETextArea value={this.state.text}
            isDisabled={!isEditable}
            change={this.handleChange}
            afterStart={this.handleAfterStart}
            afterFinish={this.handleAfterFinish}
            handleValidationFail={this.handleValidationFail}
            propName={propName}
            validate={validate}
            editProps={editProps}
            classInvalid="invalid"
            className={rieTextAreaClassName}
          />
        </span>
      </Card.Description>

    component = textIsTruncated ?
      <MyPopup on="click" content={text} trigger={component} /> :
      component;
    /*
    //component = textIsTruncated ?
    //  <MyPopup content={text} trigger={component} /> :
    //  <MyPopup content={null} trigger={component} />;
    */
    /*
    const showPopup = !!this.state.showPopup;
    const popupContent = this.state.showPopup ? text : null;
    log.trace("this.state.showPopup:", this.state.showPopup);
    log.trace("popupContent:", popupContent);
    component = <MyPopup on="click" content={popupContent}
      trigger={component} />;
    */

    return component;
  };
}

export default MyCardDescription;
