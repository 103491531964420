import _ from 'lodash';
import React from 'react';
import {Popup, Segment, Header, Button, Icon} from 'semantic-ui-react';
import {log} from 'concierge-common';
import LocalDB from '../../client-db/local-db';
import {USERS, WAYS, ACTS, ITNS, ANYS, USAGE} from '../../global-constants';
import {PENDING, APPROVED, FLAGGED} from '../../global-constants';
import MyPopup from '../../containers/my-popup';


class FilterAndSortUtils {

  // propName = "roles" | "statusAttribute" |
  //
  // value = "root" | "admin" | ... | "pending", "approved"
  //
  static handleChangeFilter = (collectionName, propName, value,
    curUser, cA_SetFilterAndSort, filterAndSort) => {

    const myFilterAndSort = _.cloneDeep(filterAndSort[collectionName]);

    // Add or remove the value from filter.roles,
    // filter.statusAttributes, etc.
    if (_.includes(myFilterAndSort.filter[propName], value)) {
      // Remove it.  NOTE: second "filter" is Array.filter();
      myFilterAndSort.filter[propName] =
        myFilterAndSort.filter[propName].filter(label => label != value);
    }
    else {
      // Add it.
      myFilterAndSort.filter[propName].push(value);
    }

    cA_SetFilterAndSort(curUser, myFilterAndSort, collectionName);
    if ((collectionName == ANYS) || (collectionName == USAGE)) {
      return;
    }
    LocalDB.updateFilteredSortedObjs(curUser, myFilterAndSort,
      collectionName);
  }

  // propName = "created" | "name"
  //
  // value = "asc" | "desc"
  //
  // TODO: Put this code, if possible, into props-editor.
  // Don't know if it is generic enough.
  //
  //static handleChangeSort = (collectionName, propName,// value,
  //  curUser, cA_SetFilterAndSort, filterAndSort) => {
  static handleChangeSort = (collectionName, propToEdit, choice,
    curUser, cA_SetFilterAndSort, filterAndSort) => {

    const myFilterAndSort = _.cloneDeep(filterAndSort[collectionName]);

    // "sort"."displayName"
    const path = propToEdit.propName+"."+choice.propName;
    const defaultValue = null;
    const curValue = _.get(myFilterAndSort, path, defaultValue);
    let newValue = choice.values[0];
    if (curValue) {
      // Only toggle/increment the value if this togglingRadioBox
      // was the currently selected togglingRadioBox.
      const curValueIndex = choice.values.indexOf(curValue);
      const newIndex = (curValueIndex + 1) % (choice.values.length);
      newValue = choice.values[newIndex];
    }

    // Delete all choice properties, and then set the one being
    // clicked.
    propToEdit.choices.forEach(choice =>
      delete myFilterAndSort[propToEdit.propName].[choice.propName]
    );
    myFilterAndSort[propToEdit.propName] = {
      [choice.propName]:newValue,
    };

    cA_SetFilterAndSort(curUser, myFilterAndSort, collectionName);
    if ((collectionName == ANYS) || (collectionName == USAGE)) {
      return;
    }
    LocalDB.updateFilteredSortedObjs(curUser, myFilterAndSort,
      collectionName);
  }
}

export default FilterAndSortUtils;
