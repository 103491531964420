// This module defines the MyMap *container*.
// NOT the MyMap *component*.
import {connect} from 'react-redux';

import MyMapComponent from '../components/my-map';

// Import action creators that this component will use.
//import {cA_EditWay} from '../actions/way';
//import {cA_EditAct} from '../actions/act';
import {cA_History} from '../actions/ui';
import {cA_SetError} from '../actions/error';
import {cA_MultiAction} from '../actions/ui';
import {cA_SetLoader} from '../actions/ui';
import {cA_GetObj} from '../client-db/api/obj-utils';
import {cA_Confirmation} from '../actions/ui';
import {cA_UsageConfirmation} from '../actions/ui';

function mapStateToProps(state) {

  const props = {
    curUser:state.curUser,
    cache:state.cache,
  };

  return props;
}
const mapDispatchToProps = {cA_History, cA_SetError, cA_MultiAction,
  cA_SetLoader, cA_GetObj, cA_Confirmation, cA_UsageConfirmation};
const MyMapContainer = connect(mapStateToProps, mapDispatchToProps)
  (MyMapComponent);
export default MyMapContainer;
