import _ from 'lodash';
import * as React from 'react';
import {Link} from 'react-router-dom';
import {log} from 'concierge-common';
import {USERS, WAYS, ACTS, ITNS} from '../../global-constants';
import UserC from '../../client-db/api/user';
import {cA_Confirmation} from '../../actions/ui';
import * as ACTION_TYPES from '../../actions/types';


const getUIForUserUsageConfirmation = (newUI, action) => {
  const {payload} = action;
  log.trace("Enter getUIForUserUsageConfirmation(), payload:", payload);
  const {usage, usedObj, deleteAction, orphanAction,
    cancelAction} = payload;
  const objType = usedObj.constructor.DISPLAY_NAME;
  let title = "Are you sure?";
  const messages = [];
  const message2 = undefined; 
  let okLabel = "Delete";
  let okAction = deleteAction;
  let cancelLabel = "Don't Delete";
  if (usage) {
    const message = "Are you sure you want to delete this User?  "+
      usedObj.displayName+" is the owner, creator, or in some way "+
      "used by "+usage.count+" object(s) in the system.";
    messages.push(message);
    messages.push('You cannot "undo" the deletion of a User.');
    log.info("usage: ", usage);
  }
  else {
    // This User is not anyone's fellowTraveler/favorite,
    // did not create any Way/Act/Itn objects, nor does
    // s/he own any Way/Act/Itn objects.
    const message = "This User is not the creator or owner of anything, "+
      "nor is s/he on any favorites or fellow traveler list.  "+
      "So it is safe to delete this User.";
    messages.push(message);
    messages.push("Are you sure you want to delete this User?");
  }
  newUI.confirmation = {
    title, message1:messages, message2,
    okAction, okLabel,
    cancelAction, cancelLabel,
    doubleCancelConfirmation:true,
  };
  log.trace("Final newUI: ", newUI);
  return newUI;
}

const getUIForWayUsageConfirmation = (newUI, action) => {
  const {payload} = action;
  log.trace("payload:", payload);
  const {usage, usedObj, deleteAction, orphanAction,
    cancelAction} = payload;
  let title = "Are you sure?";
  const messages = [];
  const message2 = undefined; 
  let okLabel = "Delete";
  let okAction = deleteAction;
  let cancelLabel = "Don't Delete";
  if (usage) {
    //if (usage.usedBy && usage.usedBy.acts) {
    if (usage.counts.asChild) {
      const act_s = _.size(usage.usedBy.acts) == 1 ?
        "Adventure" : "Adventures";
      
      let start = "This Waypoint is being used for navigation by ";
      start += usage.counts.asChild+" ";
      const url = "/usage";
      const link = <Link to={url}
        // Dismiss the confirmation dialog.
        onClick={()=>{
          // Dismiss the confirmation dialog.
          action.asyncDispatch(cA_Confirmation());
          const setUsedByAction = {
            type:ACTION_TYPES.SET_USAGE,
            payload:{
              usage:{
                title:"Usage of Waypoint: "+usedObj.displayName,
                usedBy:usage.usedBy,
                usedAs:"asChild",
              },
            },
          };
          action.asyncDispatch(setUsedByAction);
        }}>
        {act_s}
      </Link>
      const end = ".";
      const message = <h3 key="unusedKey1">
        {start}
        {link}
        {end}
      </h3>
      messages.push(message);
    }
    if (usage.counts.asParkWaypoint) {
      const act_s = usage.counts.asParkWaypoint == 1 ?
        "Adventure" : "Adventures";
          
      const url = "/usage";
      const link = <Link to={url}
        // Dismiss the confirmation dialog.
        onClick={()=>{
          // Dismiss the confirmation dialog.
          action.asyncDispatch(cA_Confirmation());
          const setUsedByAction = {
            type:ACTION_TYPES.SET_USAGE,
            payload:{
              usage:{
                title:"Usage of Waypoint: "+usedObj.displayName,
                usedBy:usage.usedBy,
                usedAs:"asParkWaypoint",
              },
            },
          };
          action.asyncDispatch(setUsedByAction);
        }}>
        {act_s}
      </Link>
      let start;
      let end;
      if (messages.length) {
        start = "It is also used as a parking location by "+
          usage.counts.asParkWaypoint+" ";
        end = ".";
      }
      else {
        start = "This Waypoint is being used by "+
          usage.counts.asParkWaypoint+" ";
        end = " as a parking location.";
      }
      const message = <h3 key="unusedKey1">
        {start}
        {link}
        {end}
      </h3>
      messages.push(message);
    }
    
    if (usedObj.owner == UserC.FOSTER_USER_ID) {
      if (usage.counts.asChild || usage.counts.asParkWaypoint) {
        messages.push('Are you really sure you want to delete '+
          'this "orphaned" Waypoint?');
      }
    }
    else if (usage.counts.asChild || usage.counts.asParkWaypoint) {
      const message = "Because it is in use, you cannot delete "+
        'this Waypoint, but you can "orphan" it, letting someone '+
        'else take responsibility for keeping it up to date.';
      messages.push(message);
      title = 'Do you want to "orphan" this Waypoint?';
      okLabel = "Orphan It";
      okAction = orphanAction;
      cancelLabel = "Don't Orphan";
    }
    if (!messages.length) {
      messages.push("Are you sure you want to delete this Waypoint?");
    }
  }
  else {
    messages.push("This Waypoint is not included in any Adventure, "+
      "so it is safe to delete it.");
    messages.push("Are you sure you want to delete this Waypoint?");
  }
  newUI.confirmation = {
    title, message1:messages, message2,
    okAction, okLabel,
    cancelAction, cancelLabel,
    doubleCancelConfirmation:true,
  };
  //action.asyncDispatch(cA_SetProgressBar(false));
  //action.asyncDispatch(cA_SetLoader(false));
  log.trace("returning newUI: ", newUI);
  return newUI;
}

const getUIForActUsageConfirmation = (newUI, action) => {
  const {payload} = action;
  log.trace("payload:", payload);
  const {usage, usedObj, deleteAction, orphanAction,
    cancelAction} = payload;
  let title = "Are you sure?";
  const messages = [];
  const message2 = undefined; 
  let okLabel = "Delete";
  let okAction = deleteAction;
  let cancelLabel = "Don't Delete";
  if (usage) {
    if (usage.counts.asChild) {
      const itn_s = _.size(usage.usedBy.itns) == 1 ?
        "Itinerary" : "Itineraries";
      
      let start = "This Adventure is part of ";
      start += usage.counts.asChild+" ";
      const url = "/usage";
      const link = <Link to={url}
        // Dismiss the confirmation dialog.
        onClick={()=>{
          // Dismiss the confirmation dialog.
          action.asyncDispatch(cA_Confirmation());
          const setUsedByAction = {
            type:ACTION_TYPES.SET_USAGE,
            payload:{
              usage:{
                title:"Usage of Adventure: "+usedObj.displayName,
                usedBy:usage.usedBy,
                usedAs:"asChild",
              },
            },
          };
          action.asyncDispatch(setUsedByAction);
        }}>
        {itn_s}
      </Link>
      const end = ".";
      const message = <h3 key="unusedKey1">
        {start}
        {link}
        {end}
      </h3>
      messages.push(message);
    }
    // TODO: Test usage.counts.favorites, adventures.
    if (usedObj.owner == UserC.FOSTER_USER_ID) {
      if (usage.counts.asChild) {
        messages.push('Are you really sure you want to delete '+
          'this "orphaned" Adventure?');
      }
    }
    else if (usage.counts.asChild) {
      const message = "Because it is in use, you cannot delete "+
        'this Adventure, but you can "orphan" it, letting someone '+
        'else take responsibility for keeping it up to date.';
      messages.push(message);
      title = 'Do you want to "orphan" this Adventure?';
      okLabel = "Orphan It";
      okAction = orphanAction;
      cancelLabel = "Don't Orphan";
    }
    if (!messages.length) {
      messages.push("Are you sure you want to delete this Adventure?");
    }
  }
  else {
    messages.push("This Adventure is not included in any Itinerary, "+
      "so it is safe to delete it.");
    messages.push("Are you sure you want to delete this Adventure?");
  }
  newUI.confirmation = {
    title, message1:messages, message2,
    okAction, okLabel,
    cancelAction, cancelLabel,
    doubleCancelConfirmation:true,
  };
  log.trace("returning newUI: ", newUI);
  return newUI;
}

const getUIForItnUsageConfirmation = (newUI, action) => {
  const {payload} = action;
  log.trace("payload:", payload);
  const {usage, usedObj, deleteAction, orphanAction,
    cancelAction} = payload;
  let title = "Are you sure?";
  const messages = [];
  const message2 = undefined; 
  let okLabel = "Delete";
  let okAction = deleteAction;
  let cancelLabel = "Don't Delete";
  if (usage) {
    if (usage.counts.asFavorite) {
      const itn_s = _.size(usage.usedBy.users) == 1 ?
        "User" : "Users";
      
      let start = "This Itinerary has been Favorited by ";
      start += usage.counts.asFavorite+" ";
      const url = "/usage";
      const link = <Link to={url}
        // Dismiss the confirmation dialog.
        onClick={()=>{
          // Dismiss the confirmation dialog.
          action.asyncDispatch(cA_Confirmation());
          const setUsedByAction = {
            type:ACTION_TYPES.SET_USAGE,
            payload:{
              usage:{
                title:"Usage of Itinerary: "+usedObj.displayName,
                usedBy:usage.usedBy,
                usedAs:"asFavorite",
              },
            },
          };
          action.asyncDispatch(setUsedByAction);
        }}>
        {itn_s}
      </Link>
      const end = ".";
      const message = <h3 key="unusedKey1">
        {start}
        {link}
        {end}
      </h3>
      messages.push(message);
    }
    // TODO: Test usage.counts.favorites, adventures.
    if (usedObj.owner == UserC.FOSTER_USER_ID) {
      if (usage.counts.asFavorite) {
        messages.push('Are you really sure you want to delete '+
          'this "orphaned" Itinerary?');
      }
    }
    else if (usage.counts.asFavorite) {
      const message = "Because it has been Favorited, you cannot delete "+
        'this Itinerary, but you can "orphan" it, letting someone '+
        'else take responsibility for keeping it up to date.';
      messages.push(message);
      title = 'Do you want to "orphan" this Itinerary?';
      okLabel = "Orphan It";
      okAction = orphanAction;
      cancelLabel = "Don't Orphan";
    }
    if (!messages.length) {
      messages.push("Are you sure you want to delete this Itinerary?");
    }
  }
  else {
    messages.push("This Itinerary is not included in any Favorites list, "+
      "so it is safe to delete it.");
    messages.push("Are you sure you want to delete this Itinerary?");
  }
  newUI.confirmation = {
    title, message1:messages, message2,
    okAction, okLabel,
    cancelAction, cancelLabel,
    doubleCancelConfirmation:true,
  };
  log.trace("returning newUI: ", newUI);
  return newUI;
}

class ConfMessageUtils {

  /**
   * @param newUI The caller should have cloned the ui object passed
   * into the reducer and passed that to us.
   *
   * @param action The action that was passed into the UI reducer
   * for the USAGE_CONFIRMATION_S action type.
   *
   * @return Returns the same newUI parameter that was passed into
   * this method.  (But, the newUI parameter has been modified.)
   */
  static getUIForUsageConfirmation(newUI, action) {
    const {usedObj} = action.payload;
    //const {usage, usedObj, deleteAction, orphanAction,
    //  cancelAction} = payload;
    //const objType = usedObj.constructor.DISPLAY_NAME;
    //let title = "Are you sure?";
    //let message1;
    //let message2; 
    //let okLabel = "Delete";
    //let okAction = deleteAction;
    //let cancelLabel = "Don't Delete";
    //if (usedObj instanceof UserC) {
    if (usedObj.cn == USERS) {
      return getUIForUserUsageConfirmation(newUI, action);
    }
    else if (usedObj.cn == WAYS) {
      return getUIForWayUsageConfirmation(newUI, action);
    }
    else if (usedObj.cn == ACTS) {
      return getUIForActUsageConfirmation(newUI, action);
    }
    else if (usedObj.cn == ITNS) {
      return getUIForItnUsageConfirmation(newUI, action);
      //return newUI;
    }
    else {
      log.bug("ConfMessageUtils.getUIForUsageConfirmation() action: ",
        action);
      debugger;
      return newUI;
    }
  }
}

export default ConfMessageUtils;
