import {log, MyError} from 'concierge-common';
import store from '../store';
import {cA_SetError} from '../actions/error';
import {cA_SetLoader} from '../actions/ui';

class ErrorUtils {

  /**
   */
  static showNotification(msg) {
    log.trace("showNotification("+msg+")");
    const severity = MyError.INFO;
    const props = {msg, severity};
    const error = MyError.createSubmitError(props);

    // Is calling store.dispatch() "safe"?
    // We could require the caller to pass in the container
    // from which we would get the wrapper function
    // container.props.cA_SetError
    store.dispatch(cA_SetError(error));
  }

  /**
   */
  static showError(msg) {
    log.trace("showError("+msg+")");
    const props = {msg};
    const error = MyError.createSubmitError(props);

    // Is calling store.dispatch() "safe"?
    // We could require the caller to pass in the container
    // from which we would get the wrapper function
    // container.props.cA_SetError
    store.dispatch(cA_SetError(error));
  }

  static setLoader(visible, msg) {
    // Is calling store.dispatch() "safe"?
    // We could require the caller to pass in the container
    // from which we would get the wrapper function
    // container.props.cA_SetLoader
    store.dispatch(cA_SetLoader(visible, msg));
  }
}

export default ErrorUtils;
