/**
 * This is used to send a message to a user.  Either as just
 * a message, or as part of a "task" such as connecting to another
 * user.
 */
import _ from 'lodash';
import React from 'react';
import {Container, Header, Segment, Button, TextArea} from 'semantic-ui-react';
import {log, MyError} from 'concierge-common';
import ObjUtils from '../client-db/api/obj-utils';
import ApiUtils from '../client-db/api/api-utils';
import {DEFAULT_USER_ID} from '../global-constants';


/**
 * The task parameter can be one of the following:
 *
 *    task=undefined/null
 *    task="connect"
 */
class CreateMessagePanel extends React.Component {

  handleClickSend() {
    log.trace("CreateMessagePanel.handleClickSend(), props:", this.props);
    const {cA_History, cA_SetError, task} = this.props;
    const {curUserObj, user} = this.state;
    let {message} = this.state;
    log.trace("curUserObj("+curUserObj.displayName+"):", user);
    log.trace("user("+user.displayName+"):", user);

    message = message ? message : "";
    message = message.trim();

    if (!message || message.length < 1) {
      const msg = "You didn't enter a message.";
      const props = {msg};
      const error = MyError.createSubmitError(props);
      cA_SetError(error);
      return;
    }

    const messageObj = {
      text:message,
      senderId:curUserObj._id,
      task,
    };

    //cA_SendMessage(curUserObj, user, messageObj);
    ApiUtils.sendMessage(curUserObj, user, messageObj);

    // TODO: Only navigate if successful.
    const {pathname} = window.myHistory.location;
    if (_.includes(pathname, "createMessage")) {
      cA_History("back");
    }
  }

  handleClickCancel() {
    const {cA_History} = this.props;
    cA_History("back");
  }

  constructor(props) {
    super(props);
    log.trace("CreateMessagePanel.constructor(), props:", props);
    this.state = {};

    //const {curUserObj, user, task} = props.contentsProps;
    const {curUser, userId, task} = props;
    if (!curUser || !curUser._id) {
      log.bug("CreateMessagePanel.constructor(), curUser:", curUser);
      return;
    }
    const curUserObj = ObjUtils.getUser(curUser, curUser._id);
    if (!curUserObj) {
      log.bug("CreateMessagePanel.constructor(), curUserObj:", curUserObj);
      return;
    }
    const user = ObjUtils.getUser(curUser, userId);
    if (!user) {
      log.bug("CreateMessagePanel.constructor(), user:", user);
      return;
    }

    //let {message} = props.contentsProps;
    let {message} = props;
    if (task == "connect") {
      message = (message && message.length > 0) ?  message :
        "Hi "+user.displayName+"!  I am a traveler who would like to connect with you.\n\nThanks,\n\n"+curUserObj.displayName;
    }

    const buttonDisabled = !(message && message.length > 0);
    this.state = {message, buttonDisabled, curUserObj, user};
  };

  handleChange(e, data) {
    log.trace("CreateMessagePanel.handleChange(), data:", data);
    const message = data.value;
    const buttonDisabled = !(message && message.length > 0);
    const newProps = {
      message,
      buttonDisabled,
    };
    this.setState(newProps);
  };

  render() {
    log.trace("CreateMessagePanel.render() this.props:", this.props);
    const {message, buttonDisabled, curUserObj, user} = this.state;
    const props = this.props;
    //const {curUserObj, user, task} = props.contentsProps;
    const {task} = props;
    let {title} = props;
    //let {title} = props.contentsProps;

    if (!curUserObj || curUserObj._id == DEFAULT_USER_ID) {
      return <div>No user is logged in.</div>
    }
    if (!user || user._id == DEFAULT_USER_ID) {
      return <div>Cannot send a message to the default/anonymous user.</div>
    }

    if (task == "connect") {
      title = title ? title : "Connect With: "+user.displayName;
    }
    else {
      title = title ? title : "Send Message To: "+user.displayName;
    }

    //const placeholder = message ? undefined : "Enter message here...";

    const buttonLabel = task == "connect" ? "Connect" : "Send";

    return (
      <Container text textAlign="center" className="message-panel">
        <Header textAlign="left" as='h2'>{title}</Header>

        <Segment padded basic>
          <TextArea rows={10} placeholder='Enter text here...'
            value={message} onChange={this.handleChange.bind(this)} />
          <div className="stackable">
            <Button positive disabled={buttonDisabled}
              onClick={this.handleClickSend.bind(this)}>
              {buttonLabel}
            </Button>
            <Button
              onClick={this.handleClickCancel.bind(this)}>
              Cancel
            </Button>
          </div>
        </Segment>

      </Container>
    );
  }
}

export default CreateMessagePanel;
