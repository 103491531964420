// This module defines the MyHeader *container*.
// NOT the MyHeader *component*.
import {connect} from 'react-redux';

// Import the MyHeaderComponent from which this MyHeaderContainer is created.
import MyHeaderComponent from '../components/my-header';

// Import action creators that this component will use.
import {cA_SetFilterAndSort} from '../actions/ui';

function mapStateToProps(state) {
  const props = {
    curUser:state.curUser,
    filterAndSort:state.ui.filterAndSort,
  };

  return props;
}
const mapDispatchToProps = {cA_SetFilterAndSort};
const MyHeaderContainer = connect(mapStateToProps, mapDispatchToProps)
  (MyHeaderComponent);
export default MyHeaderContainer;
