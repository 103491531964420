// This module defines the Message *container*.
// NOT the Message *component*.
import {connect} from 'react-redux';

//import {log} from 'concierge-common';

// Import the MessageComponent from which this MessageContainer is created.
import MessageComponent from '../components/message';

// Import action creators that this component will use.
import {cA_GetObj} from '../client-db/api/obj-utils';


function mapStateToProps(state) {

  const props = {
    curUser:state.curUser,
    cache:state.cache,
  };

  return props;
}
const mapDispatchToProps = {cA_GetObj};
const MessageContainer = connect(mapStateToProps, mapDispatchToProps)
  (MessageComponent);
export default MessageContainer;
