/**
 */
import React from 'react';
import {Card, Icon} from 'semantic-ui-react';
import {log, isPassword, validatePassword} from 'concierge-common';
import User from '../client-db/api/user';
import MyCardContent from './my-card-content';

const PASSWORD_FILLER = "\u25CF\u25CF\u25CF\u25CF\u25CF\u25CF\u25CF\u25CF";

class Password extends React.Component {

  handleClickEndIcon = () => {
    const hidden = !this.state.hidden;
    this.setState({hidden});
    log.trace("Password.handleClickEndIcon(), final hidden:", hidden);
  }

  constructor(props) {
    super(props);
    const {hidden} = props;
    this.state = {hidden};
  }

  /*
  isPassword = (password) => {
    log.trace("Password.isPassword(), password:", password);
    let error = MyError.createTransitoryError();
    error = isPassword(password, error);
    log.trace("isPassword() returned error:", error);
    const {cA_SetError} = this.props;
    return error == null;
  }
  */
  handleValidationFail = (result, password) => {
    log.trace("handleValidationFail(), password:", password);
    if (password == PASSWORD_FILLER) {
      return;  // User did not change it.
    }
    const {cA_SetError} = this.props;
    const error = validatePassword(password);
    cA_SetError(error);
  }

  render() {
    log.trace("Password.render() this.props:", this.props);
    const props = this.props;
    const {editable, password, change} = props;
    let {propName} = props;
    propName = propName ? propName : "password";

    // hidden flag does NOT come from this.props because the user
    // can click on the endIcon to hide/unhide the password.
    // So, the value is in this.state.hidden.
    //
    // NOTE: "hidden" says whether the password is CURRENTLY hidden.
    // So, if the password is currently hidden, the icon we will
    // display, the "unhide" icon, is the OPPOSITE of hidden.
    // I.e. it is showing what the hidden state will become if
    // the user clicks the icon to toggle it.
    const {hidden} = this.state;
    const endIconName = hidden ? "unhide" : "hide";
    const endIconLabel = hidden ? "Show" : "Hide";
    const inputType = hidden ? "password" : "";
    log.trace("inputType:", inputType);
    let passwordComponent;
    //const text = hidden ? "XXXXXXX" : password;
    const text = "\u25CF\u25CF\u25CF\u25CF\u25CF\u25CF\u25CF\u25CF";

    if (editable) {
      passwordComponent = <MyCardContent iconName="key" size="large"
        endIconName={endIconName} endIconLabel={endIconLabel}
        endIconOnClick={this.handleClickEndIcon}
        inputType={inputType}
        className="password"
        change={change} propName={propName}
        validate={isPassword}
        handleValidationFail={this.handleValidationFail}
        text={text} editable={true}
        maxLength={User.PASSWORD_MAX_LENGTH} />
    }
    else {
      // As of Feb 2018 we will never get here because we don't
      // show the password field if it is not editable.
      passwordComponent = 
        <Card.Content extra>
          <Icon name="key" size="big" />
          {password}
        </Card.Content>
    }

    return passwordComponent;
  };
}

export default Password;
