import _ from 'lodash';
import React from 'react';
import {Segment, Popup, Button, Icon, Image} from 'semantic-ui-react';
import {log} from 'concierge-common';
import {isEqualIds} from '../utils/string-utils';
import ObjUtils from '../client-db/api/obj-utils';
import ApiUtils from '../client-db/api/api-utils';
import Message from '../containers/message';
import MyHeader from '../containers/my-header';
import {DEFAULT_USER_ID} from '../global-constants';
import CardsLayout from './cards-layout';


class MessagesPanel extends React.Component {

  createHeader(curUser, user) {
    const {cA_History} = this.props;
    let title;
    if (curUser._id == user._id) {
      title = "Your Messages";
    }
    else {
      title = user.displayName+"'s Messages";
    }

    const rightButton =
      <Popup content={"Send a message."} position="left center"
        trigger={
          <Button primary className="icon-and-image right mail"
            onClick={() => alert('Sending messages from here is not yet implemented.  Instead, find the desired user and select the "Send Message" action.')} >
            <Icon size="large" name="mail outline" />
            <Image size="mini" src="../img/navbar/user.png" />
          </Button>
        } />
    const hasBackButton = true;
    const myHeaderProps = {
      hasBackButton,
      title,
      rightButton,
      cA_History,
    };

    return <MyHeader {...myHeaderProps} />
  }

  componentDidMount() {
    const {curUser, userId} = this.props;
    if (!curUser || !userId ||
        isEqualIds(userId, DEFAULT_USER_ID) ||
        !isEqualIds(curUser._id, userId)) {
      // We are displaying messages for someone other than the
      // curUser, so just return.
      return;
    }

    // Consider any messages in the user's list of messages as read.
    const userProps = {
      messagesLastRead:Date.now(),//(new Date()).toISOString(),
    };
    ApiUtils.editRxDBUser(curUser, curUser._id, userProps);
  }

  componentWillUnmount() {
    const {curUser, userId} = this.props;
    if (!curUser || !userId ||
        isEqualIds(userId, DEFAULT_USER_ID) ||
        !isEqualIds(curUser._id, userId)) {
      // We are displaying messages for someone other than the
      // curUser, so just return.
      return;
    }

    // Consider any messages in the user's list of messages as read.
    const userProps = {
      messagesLastRead:Date.now(),//(new Date()).toISOString(),
    };
    ApiUtils.editRxDBUser(curUser, curUser._id, userProps);
  }

  static createStateFromProps(props) {
    const {curUser, userId, cache, cA_GetObj} = props;
    //const curUserObj = ObjUtils.get(curUser, curUser._id,
    //  undefined, cache, cA_GetObj);
    const user = ObjUtils.get(curUser, userId,
      undefined, cache, cA_GetObj);
    const state = {/*curUserObj, */user};
    return state;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const newState = MessagesPanel.createStateFromProps(nextProps);

    //if (_.isEqual(prevState.curUserObj, newState.curUserObj)) {
    //  delete newState.curUserObj;
    //}
    if (_.isEqual(prevState.user, newState.user)) {
      delete newState.user;
    }
    if (_.size(newState)) {
      return newState;
    }
    else {
      // Nothing added to newState.
      return null;
    }
  }

  constructor(props) {
    super(props);
    this.state = MessagesPanel.createStateFromProps(props);
  }

  render() {
    log.trace("MessagesPanel.render() this.props:", this.props);
    const {props, state} = this;
    const {curUser, userId} = props;
    const {user} = state;
    const messages = user.messages;
    const header = this.createHeader(curUser, user);

    const objects = _.map(messages, messageObj => {
      messageObj = messageObj ? messageObj : {};
      messageObj.ownerId = userId;
      return messageObj;
    });
    log.trace("objects:", objects);

    if (messages && messages.length >= 1) {
      return (
        <div className="cards-panel">
          {header}
          <CardsLayout objects={objects} CardContents={Message} 
            {...props} messageOwner={user}
          />
        </div>
      );
    }
    else {
      // User has no messages.  Display message to that effect.
      let msg;
      if (curUser._id == userId) {
        msg = "You don't have any messages.";
      }
      else {
        msg = user.displayName+" does not have any messages.";
      }
      return (
        <div className="messages-panel">
          <Segment compact padded style={{"margin":"auto"}}>{msg}</Segment>
        </div>
      );
    }
  }
}

export default MessagesPanel;
