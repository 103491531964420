/**
 */
//import _ from 'lodash';
import React from 'react';
//import {Popup} from 'semantic-ui-react';
import {GoogleMap} from 'react-google-maps';
import {log} from 'concierge-common';

class MyGoogleMap extends React.Component {

  /*
  constructor(props) {
    super(props);
  }
  */
  componentDidMount() {
    log.trace("MyGoogleMap.componentDidMount(), this.googleMap:",
      this.googleMap);
    // No such function this.googleMap.setMyLocationEnabled(true);
    const {component} = this.props;
    if (component && component.googleMapDidMount) {
      component.googleMapDidMount(this.googleMap);

      //component.googleMap.showUserLocation=true;  Does nothing
      //component.googleMap.followUserLocation=true;  Does nothing
    }
  }

  render() {
    log.trace("MyGoogleMap.render() props:", this.props);
    const {props} = this;
    const {defaultOptions, markers, component,
      onCenterChanged, onIdle, onBoundsChanged} = props;

    const gMap = 
      <GoogleMap defaultOptions={defaultOptions}
        onCenterChanged={onCenterChanged}
        onIdle={onIdle}
        onBoundsChanged={onBoundsChanged}
        ref={node=>{this.googleMap = node; component.googleMap = node;}}
      >
        {markers}
      </GoogleMap>

    return gMap;
  };
}

export default MyGoogleMap;
