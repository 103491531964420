/**
 * This code is mostly a copy of the code on the Google Help page.
 * I have pulled some of the code out into a separate function,
 * and allowed parameters to be passed in.
 */
import _ from 'lodash';
import React from 'react';
import {compose, withProps} from 'recompose';
//import {withScriptjs, withGoogleMap, GoogleMap, Marker} from 'react-google-maps';
import {withScriptjs, withGoogleMap, Marker} from 'react-google-maps';
import {MarkerWithLabel} from 'react-google-maps/lib/components/addons/MarkerWithLabel';
import {Icon, Button} from 'semantic-ui-react';
import {log, MyError} from 'concierge-common';
import LocalDB from '../client-db/local-db';
import Obj from '../client-db/api/obj';
import ObjUtils from '../client-db/api/obj-utils';
import ApiUtils from '../client-db/api/api-utils';
import ActionUtils from './utils/action-utils';
import ObjActions from './utils/obj-actions';
import LocationUtils from './utils/location-utils';
import ActionHeader from './action-header';
import MyGoogleMap from './my-google-map';

const GOOGLE_MAPS_API_KEY = "AIzaSyDHmG3BX9SWTUXtPU67KnPPMx-pG0nTUSA";

// Although this is not read/written from/to the local DB, we
// give it an ID that looks like a normal Way object ID.
const USER_LOCATION = "ways_userLocation";

/*
API Key: AIzaSyA0x7G1wNOwi7s3CJyVyj7_4GmE6InFOgA

To improve your app's security, restrict this key's usage in the API Console.

This API key can be used in this project and with any API that supports it. To use this key in your application, pass it with the key=API_KEY parameter.
Creation date
Mar 18, 2018, 7:59:41 PM
Created by
steven.robert.ford@gmail.com (you)
*/

// TODO: This should probably become a module of its own.
// It is in the action-image.js, action-carousel.js modules also.
function createActionsButton(multiAction) {
  log.trace("my-map.js createActionsButton(), multiAction:",
    multiAction);

  const actionsButton =
    <Button primary icon className="show-actions-map"
      onClick={()=>log.trace("XXXX Never Gets Called XXXX")}
    >
      <Icon name="ellipsis vertical" size="big" />
    </Button>
  return actionsButton;
}

function createGoogleMap(props) {
  log.trace("createGoogleMap(), props:", props);
  const {markers, actionsButton, component,
    onCenterChanged, onIdle, onBoundsChanged} = props;
  let {center} = props;
  log.trace("center:", center);
  log.trace("markers:", markers);
  log.trace("actionsButton:", actionsButton);

  // TODO: Put this somewhere global.  concierge-common  
  /*
  const defaultCenter = {
    lat:-41.32,
    lng:174.82,
  };
  */
  //center = center ? center : defaultCenter;
  center = center ? center : LocationUtils.DEFAULT_LOCATION;
  log.trace("center:", center);

  const defaultOptions = {
    zoom:10,
    center,
    streetViewControl:false,
    //zoomControl:false,
    // No effect on size of zoom buttons?
    zoomControlOptions: {style: window.google.maps.ZoomControlStyle.LARGE},

    myLocation:true,
    myLocationEnabled:true,
    myLocationControl:true,
  }

  // Either marker or markerWithLabel is displayed.  Not both.
  // NOTE: The "label" is actually the multiAction button.
  //const theMarker = marker ? marker : markerWithLabel;
  const gMap = <MyGoogleMap defaultOptions={defaultOptions}
    onCenterChanged={onCenterChanged}
    onIdle={onIdle}
    onBoundsChanged={onBoundsChanged}
    markers={markers}
    component={component} />

  return gMap;
}

const MyMapComponent = compose(
  withProps({
    //googleMapURL: "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places",
    //googleMapURL: "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyA0x7G1wNOwi7s3CJyVyj7_4GmE6InFOgA",
    //googleMapURL: "https://maps.googleapis.com/maps/api/js?v=3.31exp&libraries=geometry,drawing,places&key=AIzaSyA0x7G1wNOwi7s3CJyVyj7_4GmE6InFOgA",
    //googleMapURL: "https://maps.googleapis.com/maps/api/js?v=3&libraries=geometry,drawing,places&key=AIzaSyA0x7G1wNOwi7s3CJyVyj7_4GmE6InFOgA",
    googleMapURL: "https://maps.googleapis.com/maps/api/js?v=3&libraries=geometry,drawing,places&key="+GOOGLE_MAPS_API_KEY,
    loadingElement: <div style={{ height: `100%` }} />,
    //containerElement: <div style={{ height: `400px` }} />,
    //containerElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{position:"absolute",
      top:"0", bottom:"0", left:"0", right:"0"}} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)(createGoogleMap)

//class MyMap extends React.PureComponent {
class MyMap extends React.Component {

  handleClickMarker(e, multiAction) {
    log.trace("MyMap.handleClickMarker(), e:", e);
    log.trace("MyMap.handleClickMarker(), multiAction:", multiAction);

    // NOTE: On Mar 31, 2018 I attached a multiAction to both the
    // editable and non-editable markers, (i.e. we always  display
    // a MultiAction page when a marker is clicked on).  Because of
    // that, this if-statement should always be true.
    if (multiAction && multiAction.dispatch) {
      multiAction.dispatch();
    }
  }
  
  possiblySaveOriginalWaypointState(objId) {
    const {cache, curUser, cA_GetObj} = this.props;
    const obj = ObjUtils.get(curUser, objId, undefined, cache, cA_GetObj);
    if (!obj || obj._id == Obj.DEFAULT_ID(obj.cn)) {
      // Should never happen.
      log.bug("saveOriginalWaypointState() called when Waypoint not loaded.");
      return;
    }

    const {markerStates} = this.state.originalState;
    let markerState = _.find(markerStates, {objId});
    if (markerState) {
      // Original state already saved.
      return;
    }

    markerState = this.createMarkerState(obj);
    if (markerState) {
      // Modify this.state directly because we do NOT want
      // to update the UI because of this change to this.state.
      markerStates.push(markerState);
    }
  }

  handleDragEndMarker(e, objId) {
    log.trace("MyMap.handleDragEndMarker(), e:", e);
    log.trace("MyMap.handleDragEndMarker(), objId:", objId);
    if (!e) {
      // Does this ever happen?
      return;
    }

    this.possiblySaveOriginalWaypointState(objId);

    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    log.trace("Marker dragEnd Lat/Lng("+lat+", "+lng+")");

    // Update this waypoint's markerState in the component's
    // state.

    const markerStates = _.cloneDeep(this.state.markerStates);
    for (let i = 0; i < markerStates.length; i++) {
      const markerState = markerStates[i];
      if (markerState.objId == objId) {
        markerState.lat = lat;
        markerState.lng = lng;
        markerState.changed = true;
        break;
      }
    }
    this.setState({markerStates, changed:true})

    // Update the Waypoint's locationPoint in the database on the server.
    this.setPointProp(objId, lat, lng);
  }

  /*
  handleMapDrag(e) {
    log.trace("handleMapDrag(), e:", e);
    log.trace("e.latLng.lat", e.latLng.lat());
    const centerLat = e.latLng.lat();
    const centerLng = e.latLng.lng();
    //this.setState({centerLat, centerLng});
  }
  handleMapDragEnd(e) {
    log.trace("handleMapDragEnd(), e:", e);
    log.trace("e.latLng.lat", e.latLng.lat());
    const centerLat = e.latLng.lat();
    const centerLng = e.latLng.lng();
    //this.setState({centerLat, centerLng});
  }
  */
  handleIdle() {
    log.trace("handleIdle()");
    const bounds = new window.google.maps.LatLngBounds();
    //this.setState({bounds:this.getMapLatLngBounds()});
    this.setState({bounds});

    // Center and zoom when we are first displayed.
    if (this.state.didCenterAndZoom) {
      return;
    }
    this.centerAndZoomMap();
    this.setState({didCenterAndZoom:true});
    /*
    const {latLngToMakeVisible} = this.state;
    //if (!latLngToMakeVisible) {
    //  return;
    //}
    this.makeLatLngVisible(latLngToMakeVisible);
    */
  }

  handleBoundsChanged() {
    log.trace("handleBoundsChanged()");
    //const bounds = this.googleMap.getBounds();
    const bounds = new window.google.maps.LatLngBounds();
    //this.setState({bounds:this.getMapLatLngBounds()});
    this.setState({bounds});
  }

  handleCenterChanged(e) {
    log.trace("handleCenterChanged(), e:", e);
    const latLng = this.googleMap.getCenter();
    log.trace("this.googleMap:", this.googleMap);
    log.trace("this.googleMap.setCenter:", this.googleMap.setCenter);
    log.trace("this.googleMap.panTo:", this.googleMap.panTo);
    log.trace("this.googleMap.getCenter:", this.googleMap.getCenter);
    log.trace("this.state:", this.state);
    log.trace("latLng.lat", latLng.lat());
    log.trace("latLng.lng", latLng.lng());
    const centerLat = latLng.lat();
    const centerLng = latLng.lng();

    this.setState({centerLat, centerLng, changed:true});
  }

  getPoint(objId) {
    const {markerStates} = this.state;
    const markerState = _.find(markerStates, {objId});
    const {lat, lng} = markerState;
    const point = {
      coordinates:[lng, lat],
    };
    return point;
  }

  resetPoint(objId) {
    log.trace("MyMap.resetPoint(), objId:", objId);
    log.trace("MyMap.resetPoint(), this.state:", this.state);
    log.trace("MyMap.resetPoint(), this.state.originalState:",
      this.state.originalState);
    //log.trace("this.googleMap:", this.googleMap);
    const {cA_SetError} = this.props;

    let {markerStates} = this.state.originalState;
    let markerState = _.find(markerStates, {objId});
    if (!markerState) {
      const msg = "Did not find objId("+objId+
        ") in this.state.originalState.markerStates.";
      log.bug(msg);
      const props = {msg};
      const bug = MyError.createSubmitError(props);
      bug.severity = MyError.BUG;
      cA_SetError(bug);
      return;
    }
    const {lat, lng} = markerState;

    const props = {msg:"Resetting marker to location: "+lat+", "+lng};
    const info = MyError.createSubmitError(props);
    info.severity = MyError.INFO;
    cA_SetError(info);

    // Update this waypoint's state in the UI.
    // Because the location has already been updated when
    // the drag ended, all we are really doing here is setting
    // the changed flag back to false.
    //this.setState(this.state.originalState);
    markerStates = this.state.markerStates;
    markerState = _.find(markerStates, {objId});
    if (!markerState || !markerState.changed) {
      log.bug("Calling resetPoint() on unchanged Waypoint");
      return;
    }
    markerState.changed = false;
    this.setState(markerStates);

    //this.setMapCenter(lat, lng);

    // Update the object's property in the database on the server.
    this.setPointProp(objId, lat, lng);
  }

  /*
  resetMapCenter() {
    log.trace("resetMapCenter()");
    const {centerLat, centerLng} = this.state;
    if (centerLat && centerLng) {
      this.setMapCenter(centerLat, centerLng);
    }
    this.setState({changed:false});
  }
  */

  /**
   * Set the lat/lng of a Waypoint in the UI and the database.
   *
   * Display a message to the user telling him/her that we are
   * setting the marker's location and how accurate the location
   * is.
   *
   * @param {boolean} makeVisible - If this is true, we will
   * pan/move the map to make sure the Waypoint is visible at
   * the new lat/lng.  Default is false.
   */
  setPoint(objId, latLng, makeVisible = false) {
    log.trace("MyMap setPoint(), makeVisible:", makeVisible);
    const {cA_SetError} = this.props;
    const {lat, lng, error} = latLng;
    if (error) {
      cA_SetError(error);
      return;
    };

    let {accuracy} = latLng;
    accuracy = accuracy ? Math.round(accuracy) : "?";

    const msg = "Setting marker to current location: "+lat+", "+lng+
      ".  "+this.getAccuracyString(accuracy);
    const props = {msg, severity:MyError.INFO};
    const info = MyError.createSubmitError(props);
    //info.severity = MyError.INFO;
    cA_SetError(info);

    // Update this component's state.
    //this.setState({lat, lng});

    // Update this waypoint's state in the UI and set
    // the changed flag back to false.
    //this.setState(this.state.originalState);
    const markerStates = this.state.markerStates;
    const markerState = _.find(markerStates, {objId});
    markerState.lat = lat;
    markerState.lng = lng;
    markerState.changed = false;
    this.setState(markerStates);

    //this.setMapCenter(lat, lng);

    // Update the object's property in the database on the server.
    this.setPointProp(objId, lat, lng);

    if (makeVisible) {
      this.makeLatLngVisible(latLng);
      //this.makeLatLngVisibleLater(latLng);
      //this.setState({onIdle:(e)=>this.makeLatLngVisible(latLng)});
      //log.trace("Setting latLngToMakeVisible");
      //this.setState({latLngToMakeVisible:latLng});
    }
  }

  /**
   * Set the object's property in the database on the server.
   */
  setPointProp(objId, lat, lng) {
    const {props} = this;
    const {curUser} = props;

    //const point = {
    const locationPoint = {
      coordinates:[lng, lat],
    };
    const wayProps = {locationPoint};
    ApiUtils.editRxDBObj(curUser, objId, wayProps);
  }

  setMapCenter(lat, lng) {
    log.trace("setMapCenter("+lat+", "+lng+")");
    const latLng = {lat, lng};
    //this.googleMap.setCenter(latLng);  setCenter() not a function!?
    this.googleMap.panTo(latLng);
  }

  constructor(props) {
    log.trace("MyMap.constructor(), props:", props);
    super(props);

    // Create a "state" object that will hold this component's
    // state as the user changes it by moving markers on the map.
    const stateProps = this.createStateProps(props);
    this.state = stateProps;
    this.state.changed = false;  // This is the original state.
    for (let i = 0; i < this.state.markerStates.length; i++) {
      const markerState = this.state.markerStates[i];
      markerState.changed = false;
    }

    // Save a copy of this component's original state so we
    // can go back to it if the user clicks a "Reset" button/link.
    //
    // Create a deep copy of state.markerStates to save
    // in our "originalState".
    /*
    const markerStates = _.cloneDeep(this.state.markerStates);
    this.state.originalState = {
      markerStates,
      changed:this.state.changed,  // I.e. "false".
    };
    */
    this.state.originalState = _.cloneDeep(this.state);

    if (window.performance &&
        window.performance.navigation.type ==
        window.performance.navigation.TYPE_BACK_FORWARD) {
      //alert('Got here using the browser "Back" or "Forward" button.');
      // TODO: This is a terrible hack, and logs the user out.
      //props.cA_History("reload");
    }
    log.trace("MyMap.constructor(), At exit this.state:", this.state);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    log.trace("MyMap.componentWillReceiveProps(), nextProps:",
      nextProps);
    const stateProps = this.createStateProps(nextProps);
    log.trace("New stateProps:", stateProps);

    // NOTE: stateProps does NOT contain a value for the
    // "changed" property.
    // So, we need to copy whatever the changed values are
    // in the current state.
    stateProps.changed = this.state.changed;
    const {markerStates:newWPSs} = stateProps;
    const {markerStates:oldWPSs} = this.state;
    for (let i = 0; i < newWPSs.length; i++) {
      const newWPS = newWPSs[i];
      for (let j = 0; j < oldWPSs.length; j++) {
        const oldWPS = oldWPSs[j];
        if (newWPS.objId == oldWPS.objId) {
          newWPS.changed = oldWPS.changed;
        }
      }
    }

    this.setState(stateProps);
  }

  /**
   * Get a list of unique object ids from the passed in
   * list of ids.
   *
   * We do this because we do not add the same waypoint
   * twice to the map.
   * A Waypoint can exist twice in an Adventure, e.g.
   * some sort of out-and-back or loop hike, but we
   * don't want it to appear on the map twice.
   */
  getUniqueWayIds(objIds) {
    const uniqueObjIds = _.uniqBy(objIds);
    log.trace("uniqueObjIds:", uniqueObjIds);
    return uniqueObjIds;
  }

  createMarkerState(obj) {
    if (obj._id == Obj.DEFAULT_ID(obj.cn)) {
      log.bug("createMarkerState() called with obj:", obj);
      return;
    }

    const {locationPoint, _id:objId, displayName} = obj;
    let lat;
    let lng;
    if (locationPoint) {
      lat = locationPoint.coordinates[1];
      lng = locationPoint.coordinates[0];
    }
    else {
      log.trace("No lat/lng for Waypoint, so set to a recent location.");
      const latLng = LocationUtils.getMostRecentLocation();
      log.trace("latLng:", latLng);
      lat = latLng.lat;
      lng = latLng.lng;
    }
    const markerState = {
      objId,
      displayName,
      lat,
      lng,
      //changed,  NOTE: We do NOT set the changed flag.
    };
    return markerState;
  }

  /**
   * this.props.objPropName tells us whether we are showing the
   * location of this.props.obj.objPropName or multiple locations
   * of the items in this.props.obj[objPropName].  e.g. obj.children
   *
   * NOTE: This function does NOT set the "changed" flag.
   * That is up to the caller to do, or not do.
   */
  createStateProps(props) {
    log.trace("createStateProps(), props:", props);
    const {cache, curUser, obj, objPropName, cA_SetError, cA_GetObj} = props;

    if (!objPropName) {
      log.bug("objPropName: ", objPropName);
      debugger;
    }
    //if (objPropName) {
      if (!obj ||
        obj._id == Obj.DEFAULT_ID(obj.cn) ||
        obj._id == Obj.PRIVATE_ID(obj.cn)) {
        return {markerStates:[]};
      }
    //}

    let objIds;
    // It is not an array of multiple ids.
    // Is the caller asking us to display this object's
    // locationPoint?
    if (objPropName == "locationPoint") {
      // Make an array with just the one entry.
      objIds = [obj._id];
    }
    else {
      const objProp = objPropName ? obj[objPropName] : undefined;
      if (objProp && Array.isArray(objProp)) {
        // Get the list of all "unique" object Ids in this list.
        // E.g. a list of unique waypoints.
        objIds = this.getUniqueWayIds(objProp);
      }
      else {
        // Assume the property is the id of a single object
        // such as an object's parkWaypoint property.
        // Make an array with just the one entry.
        objIds = [objProp._id];
      }
    }

    let privateCount = 0;
    log.trace("objIds:", objIds);
    const markerStates = [];
    for (let i = 0; i < objIds.length; i++) {
      const objId = objIds[i];
      const obj = ObjUtils.get(curUser, objId, undefined, cache, cA_GetObj);
      log.trace("obj: ", obj);
      if (obj && obj._id != Obj.DEFAULT(obj.cn) && obj.view) {
        const markerState = this.createMarkerState(obj);
        if (markerState) {
          markerStates.push(markerState);
        }
      }
      else {
        privateCount += (obj.visibility == "isPrivate") ? 1 : 0;
      }
    };

    if (privateCount) {
      let msg = privateCount == 1 ?
        "We are not displaying one of the locations "+
        ' because its visibility is set to "Private".' :
        "We are not displaying "+privateCount+" locations"+
        ' because they have their visibility set to "Private".';
      const props = {msg, severity:MyError.INFO};
      log.trace("props:", props);
      const error = MyError.createSubmitError(props);
      log.trace("error:", error);
      cA_SetError(error);
    }

    let {centerLat, centerLng} = props;
    centerLat = centerLat ? parseFloat(centerLat) :
      LocationUtils.DEFAULT_LOCATION.lat;
    centerLng = centerLng ? parseFloat(centerLng) :
      LocationUtils.DEFAULT_LOCATION.lng;

    //const zoom = 9;

    const stateProps = {
      markerStates,
      centerLat,
      centerLng,
      //zoom,
      //changed,  NOTE: We do NOT set "changed" property.
    };

    return stateProps;
  }

  getAccuracyString(accuracy) {
    return accuracy ?
      "Accuracy: \u00B1"+accuracy+"metres." :
      //"Accuracy: \u207A\u2044\u207B"+accuracy+"metres." :
      "Accuracy: unknown.";
  }

  // Create the user location marker if we have a "most recent location"
  // for the user.  We don't care how recent it is, just that at
  // some point we were able to actually get the user's location.
  //
  // Return undefined if we don't have location for the user.
  possiblyCreateUserLocationMarker(keyIndex) {
    log.trace("possiblyCreateUserLocationMarker("+keyIndex+")");
    const latLng = LocationUtils.getMostRecentLocation();
    log.trace("latLng: ", latLng);
    log.trace("isDefaultLocation: ", LocationUtils.isDefaultLocation(latLng));
    // TODO: Need a better concept of location not set than testing
    // whether it is equal to the default location.
    if (!latLng || LocationUtils.isDefaultLocation(latLng)) {
      return undefined;
    }
    return this.createUserLocationMarker(keyIndex);
  }

  createUserLocationMarker(keyIndex) {
    log.trace("createUserLocationMarker("+keyIndex+")");
    const latLng = LocationUtils.getMostRecentLocation();
    const {lat, lng, accuracy} = latLng;
    // NOTE: If you change the "Your Location" string below
    // you also need to change the CSS selector that uses
    // it in style.css
    let displayName = "Your Location";
    displayName += ".  "+this.getAccuracyString(accuracy);
    const markerState = {
      objId:USER_LOCATION,
      displayName,
      //lat:33.681297,
      //lng:-112.243986,
      lat,
      lng,
    };
    const marker = this.createMarker(markerState, keyIndex);
    return marker;
  }

  createMarkers(markerStates) {
    log.trace("createMarkers(), markerStates:", markerStates);
    this.clearMarkers();
    const markers = [];
    for (let i = 0; i < markerStates.length; i++) {
      const markerState = markerStates[i];
      const marker = this.createMarker(markerState, i);
      if (marker) {
        markers.push(marker);
      }
    };
    return markers;
  }

  addMarker(marker) {
    if (!marker) {
      return;
    }
    this.markers = this.markers ? this.markers : [];
    this.markers.push(marker);
  }
  clearMarkers() {
    delete this.markers;
    this.markers = [];
  }

  createMarker(markerState, keyIndex) {
    log.trace("Enter createMarker(keyIndex "+keyIndex+
      "), markerState:", markerState);
    const {props} = this;
    log.trace("Enter createMarker(), props:", props);
    const {cache, curUser, parentObj, cA_GetObj,
      objPropName, parentObjPropName} = props;

    const {lat, lng, objId, displayName, changed} = markerState;
    const objCn = Obj.getCnFromId(objId);
    const obj = objId == USER_LOCATION ?
      // This is the "special" user's current location "waypoint".
      //WayUtils.USER_LOCATION_WAY :  Maybe do something like this?
      Obj.DEFAULT(objCn) :
      // This is an ordinary waypoint, so get the info from the database.
      ObjUtils.get(curUser, objId, undefined, cache, cA_GetObj);

    log.trace("In createMarker(), obj: ", obj);
    if (!obj) {
      log.bug("In createMarker(), obj is null");
    }

    // Only pass in the reset function if the user has moved
    // the marker.
    const resetFunc = changed ?
      ()=>this.resetPoint(objId) : undefined;

    const setPointFunc = (latLng, makeVisible)=>
      this.setPoint(objId, latLng, makeVisible);
    const getPointFunc = ()=>this.getPoint(objId);
    //const showDirections = true;

    const objType = Obj.DISPLAY_NAME(objCn);
    const multiAction = ActionUtils.calcObjOnMapMultiAction(props,
      getPointFunc, resetFunc, setPointFunc, /*showDirections,*/
      curUser, obj, objPropName, parentObj, parentObjPropName,
      objType+" Actions", keyIndex);

    // NOTE: I do not use key for anything, but React requires
    // components in an array/list of components to have a unique key.
    // Add a unique prefix so we are sure that the key is unique even
    // if we are passed the "defaultWay" wayId of the default Way
    // object.
    /*
    let key = wayId;
    if (wayId == Way.DEFAULT_WAY_ID) {
      key = _.uniqueId("wayId")+"_"+key;
    }
    */
    const key = "key"+keyIndex+"_"+objId;

    const position = {lat, lng};
    let title = objId == USER_LOCATION ?
      displayName :
      'Waypoint "'+displayName+'".';
    title += multiAction ?
      changed ? 
      "  Click the [ \u22EE ] button for more actions." :
      "  Click the marker for more actions." :
      "";
    //const draggable = true;
    const draggable = obj.edit;
    //const onClick = (e)=>{this.handleClickMarker(e, multiAction);};
    const onClick = (e)=>this.handleClickMarker(e, multiAction);
    //const onDragEnd = this.handleDragEndMarker.bind(this);
    const onDragEnd = (e)=>this.handleDragEndMarker(e, objId);
    //const onCenterChanged = this.handleCenterChanged.bind(this);
    const ref = (node) => {
      this.addMarker(node);
    };
    const icon = objId == USER_LOCATION ?
      "https://www.google.com/intl/en_us/mapfiles/ms/micons/blue-dot.png" :
      undefined;
    const markerProps = {
      position,
      title,
      //onCenterChanged,
      onClick,
      onDragEnd,
      changed,
      draggable,
      key,
      icon,
      ref,
      //optimized: false,  // Possibly needed for zIndex to work? 
      //zIndex:keyIndex,  // If we want to set zIndex explicitly.
    };
    log.trace("markerProps:", markerProps);
    log.trace("createMarker(), multiAction:", multiAction);
    const actionsButton = multiAction ?
      createActionsButton(multiAction) : undefined;
    log.trace("actionsButton:", actionsButton);

    const labelStyle = {
      paddingTop:"1em",
      transform:"translateX(-50%)",
    };

    let marker;
    if (markerProps.changed && actionsButton) {
      marker = <MarkerWithLabel
          position={markerProps.position}
          labelAnchor={{x:0,y:0}}
          labelStyle={labelStyle}
          {...markerProps}
        >
          {actionsButton}
        </MarkerWithLabel>
    }
    else {
      marker = <Marker {...markerProps} />;
    }
    return marker;
  }

  resetAllPoints() {
    const {markerStates} = this.state.originalState;
    log.trace("resetAllPoints(), markerStates:", markerStates);
    for (let i = 0; i < markerStates.length; i++) {
      const markerState = markerStates[i];
      this.resetPoint(markerState.objId);
    }
  }

  resetAllPointsAndMapCenter() {
    log.trace("resetAllPointsAndMapCenter():");
    const {centerLat, centerLng} = this.state.originalState;
    //this.setState(this.state.originalState);
    this.resetAllPoints();

    //this.resetMapCenter();
    if (centerLat && centerLng) {
      this.setMapCenter(centerLat, centerLng);
    }
  }

  createActionHeader() {
    const {props} = this;
    const {cache, curUser,
      obj, objPropName, parentObj, parentObjPropName, cA_GetObj} = props;
    const {changed, markerStates} = this.state;
    log.trace("createActionHeader(), state:", this.state);

    // NOTE: I haven't decided if we want to show a MultiAction
    // button at the right side of the ActionHeader if there is
    // more than one Waypoint, so this code is a bit redundant at
    // the moment.
    let multiAction;
    //let showDirections = false;
    let getPointFunc;
    let resetFunc;
    let setPointFunc;
    //let obj = undefined;
    /*
    // Only add Waypoint edit functions if there is only a single
    // Waypoint.
    if (markerStates.length == 1) {
      showDirections = true;
      const markerState = markerStates[0];
      const objId = markerState.objId;
      obj = ObjUtils.get(curUser, objId, undefined, cache, cA_GetObj);
      setPointFunc = (latLng, makeVisible)=>
        this.setPoint(objId, latLng, makeVisible);
      getPointFunc = ()=>this.getPoint(objId);
    }
    */
    resetFunc = changed ? ()=>this.resetAllPointsAndMapCenter() : undefined;

    const title = Obj.DISPLAY_NAME(obj.cn)+" Actions";
    // Not displaying action button at top right of header any more.
    // Just too many ways it can be configured depending on what is
    // displayed.
    //multiAction = ActionUtils.calcMapHeaderMultiAction(props,
    //  getPointFunc, resetFunc, setPointFunc, /*showDirections,*/
    //  curUser, obj, objPropName, parentObj, parentObjPropName, title);

    const {headerTitle, cA_History} = props;
    const actionHeaderProps = {
      hasBackButton:true,
      title:headerTitle,
      multiAction,
      cA_History,
    };

    log.trace("MyMap.render(), multiAction:", multiAction);
    const actionsButton = multiAction ?
      createActionsButton(multiAction) : undefined;
    log.trace("actionsButton:", actionsButton);

    return <ActionHeader {...actionHeaderProps} />
  }

  getMapLatLngBounds() {
    /*
    const bounds = new window.google.maps.LatLngBounds();
    log.trace("getMapLatLngBounds() returning bounds:", bounds);
    const bounds2 = this.googleMap.getBounds();
    log.trace("bounds2:", bounds2);
    return bounds;
    */
    return this.state.bounds;
  }

  isLatLngVisible(latLng) {
    log.trace("isLatLngVisible(), latLng:", latLng);
    const bounds = this.getMapLatLngBounds();
    log.trace("bounds:", bounds);
    return bounds.contains(latLng);
  }

  /*
  makeLatLngVisibleLater(latLng) {
    setTimeout(()=>this.makeLatLngVisible(latLng), 2000);
  }
  */

  makeLatLngVisible(latLng) {
    log.trace("makeLatLngVisible(), latLng:", latLng);
    if (!latLng) {
      return;
    }
    //this.setState({latLngToMakeVisible:null});
    if (this.isLatLngVisible(latLng)) {
      log.trace("Already visible.  Do nothing.");
      // The location is already visible.
      return;
    }

    log.trace("Not visible, so pan map.");
    this.centerAndZoomMap();
  }

  /**
   * Center the map to the geometric center of all the markers
   * it is currently displaying, and zoom in/out so that all
   * markers are visible.
   *
   * See: https://stackoverflow.com/questions/10268033/google-maps-api-v3-method-fitbounds
   */
  centerAndZoomMap() {
    log.trace("centerAndZoomMap(), this.googleMap:", this.googleMap);
    const map = this.googleMap;
    if (!map) {
      return;
    }
    const markers = this.markers ? this.markers : [];
    log.trace("centerAndZoomMap(), markers:", markers);

    if (!markers || markers.length < 1) {
      return;
    }

    const bounds = this.getMapLatLngBounds();
    log.trace("bounds:", bounds);
    for(let i = 0; i < markers.length; i++) {
      const marker = markers[i];
      log.trace("centerAndZoomMap(), marker:", marker);
      if (marker.getPosition) {
        bounds.extend(marker.getPosition());
      }
      else {
        log.trace("getPosition() does not exist");
      }
    }
    log.trace("bounds:", bounds);

    // Center the map to a specific spot (city)
    // NOTE: setCenter() is NOT a function in API 3.  Use panTo().
    //map.setCenter(center); 

    const usePanToBoundsFunc = false;
    if (usePanToBoundsFunc) {
      map.panToBounds(bounds);
    }
    else {
      //center the map to the geometric center of all markers
      const latLng = bounds.getCenter();
      map.panTo(latLng);

      // TODO: This is a hack to prevent the state.changed flag
      // from being set to true when we "automatically" pan
      // the map when first displayed.  Need a better solution.
      //
      // We could set some flag telling handleCenterChanged() to
      // ignore map center changes until after we have called panTo()?
      //
      // componentWillReceiveProps() is being deprecated in React 17,
      // so maybe I can switch to the new getDerivedStateFromProps()
      // and have it use a (state.changed = false) flag I pass into
      // it?
      //
      const centerLat = latLng.lat();
      const centerLng = latLng.lng();
      const useHack = false;
      if (useHack) {
        // eslint-disable-next-line
        this.state.centerLat = centerLat;
        // eslint-disable-next-line 
        this.state.centerLng = centerLng;
        // eslint-disable-next-line 
        this.state.changed = false;
      }
      else {
        const stateProps = {
          centerLat,
          centerLng,
          changed:false,
        };
        this.setState(stateProps);
      }

      /*
      if (bounds.b == null || bounds.f == null) {
        log.trace("Returning without doing map fit.");
        return;
      }
      // Set zoom level to fit all the markers.
      if ((bounds.b.b != bounds.b.f) && 
          (bounds.f.b != bounds.f.f)) {
        map.fitBounds(bounds);
      }
      */
      if (bounds.j == null || bounds.l == null) {
        log.trace("Returning without doing map fit.");
        return;
      }
      // Set zoom level to fit all the markers.
      // NOTE: It seems at some point in time Google maps changed
      // the name of the b and f props to be j and l.  Need to look
      // into this.
      if ((bounds.j.j != bounds.j.l) && 
          (bounds.l.j != bounds.l.l)) {
        map.fitBounds(bounds);
      }
    }

    /*
    window.google.maps.event.addListenerOnce(map, 'idle', function() {
      log.trace("In idle callback.");
      map.fitBounds(bounds);
      map.setZoom(map.getZoom()-1); 
    });
    */

    //remove one zoom level to ensure no marker is on the edge.
    //map.setZoom(map.getZoom() - 1); 

    // set a minimum zoom 
    // if you got only 1 marker or all markers are on the same
    // address map will be zoomed too much.
    //if(map.getZoom() > 15){
    //  map.setZoom(15);
    //}
  }

  /**
   * This function is called by MyGoogleMap when its
   * componentDidMount() function is called.
   * I.e. when the googleMap component has been initialized.
   * (Or, at least I hope thats what it means.)
   */
  googleMapDidMount(googleMap) {
    //const {cA_SetLoader} = this.props;
    log.trace("googleMapDidMount(), googleMap:", googleMap);
    // NOTE: Passed in googleMap and this.googleMap should be ===
    if (googleMap !== this.googleMap) {
      log.bug("In googleMapDidMount(), googleMap !== googleMap");
    }

    /*
    const centerAndZoomHack = true;
    if (!centerAndZoomHack) {
      // I would like to do just this, but I can't.
      // See else block below.
      this.centerAndZoomMap();
    }
    else {
      // TODO: Temporary hack.  Need to figure out when we can
      // do the map center and zoom.  After all waypoints have
      // be retrieved?  What about when user adds a new waypoint?
      // Don't do if user has manually zoomed/panned?
      //
      cA_SetLoader(true, "Centering and Zooming...");
      setTimeout(()=>{
        cA_SetLoader(false);
        this.centerAndZoomMap()
        }, 2500);
    }
    */

    const latLng = this.googleMap.getCenter();
    const centerLat = latLng.lat();
    const centerLng = latLng.lng();

    const useHack = false;
    if (useHack) {
      // TODO: Fix this "anti-pattern" setting of state directly.
      // Don't put the "original values", (original AFTER google
      // map does it center and zoom), into state?  Keep them
      // directly in "this", as opposed to in this.state?

      // eslint-disable-next-line
      this.state.originalState.centerLat = centerLat;
      // eslint-disable-next-line
      this.state.originalState.centerLng = centerLng;
    }
    else {
      // If this breaks, use the "anti-pattern" two lines of
      // code above.
      const originalState = {centerLat, centerLng};
      this.setState(originalState);
    }
  }

  render() {
    const {props} = this;
    log.trace("MyMap.render(), props:", props);
    log.trace("MyMap.render(), this.state:", this.state);
    const {markerStates} = this.state;
    log.trace("MyMap.render(), markerStates:", markerStates);

    // Create the markers.
    // TODO: Move this so it is not done on every render.
    const markers = this.createMarkers(markerStates);

    // Display user's current location.
    // Because we do this AFTER we create the other markers,
    // it is "above" (in Z-order) the location markers.
    // Alternatively, we could set the zIndex in our
    // createMarker() function.
    // 
    //  optimized: false,  // Might not be needed?
    //  zIndex:99999999
    //
    // NOTE: We have CSS in style.css that sets pointer-events:none
    // on the userLocationMarker.  We want he events to go down to the
    // moveable marker.
    // 
    const userLocationMarker = this.possiblyCreateUserLocationMarker(
      markers.length);
    log.trace("MyMap.render(), userLocationMarker:", userLocationMarker);
    if (userLocationMarker) {
      markers.push(userLocationMarker);
    }

    log.trace("After createMarkers(), markers:", markers);
    //this.markers = markers;

    const actionHeader = this.createActionHeader();

    let center;
    const {centerLat, centerLng} = this.state;
    if (centerLat && centerLng) {
      center = {lat:centerLat, lng:centerLng};
    }
    const onCenterChanged = this.handleCenterChanged.bind(this);
    const onIdle = this.handleIdle.bind(this);
    const onBoundsChanged = this.handleBoundsChanged.bind(this);

    const mapProps = {
      center,
      onCenterChanged,
      onIdle,
      onBoundsChanged,
      markers,

      // Pass ourselves to the google map component so that we
      // can get a "ref" to it so we can call its panTo() function.
      // Seems hackey.
      component:this,
    };

    return(
      <div className="my-map">
        {actionHeader}
        <div className="my-map-component-holder">
          <MyMapComponent {...mapProps} />
        </div>
      </div>
    );
  }
}


export default MyMap;
