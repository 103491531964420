import React from 'react';
import {Container, Header, Segment, Grid, Label} from 'semantic-ui-react';
import {log} from 'concierge-common';
//import store from '../store';
//import * as ACTION_TYPES from '../actions/types';


class SignedOutPanel extends React.Component {

  setLoader() {
    const {curUser, cA_SetLoader} = this.props;
    if (curUser.token) {
      //cA_SetLoader(true, 'Signing you out...');
      cA_SetLoader(false, 'Click "Sign Out" in the top right corner');
    }
    else {
      cA_SetLoader(false, "You have been signed out.");
    }
  }

  componentDidUpdate(nextProps) {
    this.setLoader();
  }

  componentDidMount() {
    const {curUser} = this.props;
    this.setLoader();
    log.trace("curUser: ", curUser);

    // Sign out curUser if s/he is currently signed in.
    if (curUser.token) {
      // TODO: Timeout is unneeded.  Just for testing loader.
      //setTimeout(() => {
      //  log.trace("SignedOutPanel.componentDidMount() calling cA_SignOut()");
      //  cA_SignOut(curUser);
      //}, 1000);

      //cA_SignOut(curUser);
      /*
      const returnTo = window.location.origin+"/signedOut";
      if (auth0Context && auth0Context.auth0Client) {
        auth0Context.logout({returnTo});
      }
      const action = {
        type:ACTION_TYPES.SIGN_OUT_S,
        payload:{user:{id_:null, token:null}},
      };
      store.dispatch(action);
      */
    }
  }

  render() {
    log.trace("SignedOutPanel.render() this.props:", this.props);
    const {curUser} = this.props;

    let title;
    let label;
    if (curUser.token) {
      title = "Sign Out";
      label = 'Click "Sign Out" in the top right corner';
    }
    else {
      title = "Signed Out";
      label = "You have been signed out.";
    }

    return (
      <Container text className="signed-out-panel">
        <Header as='h2'>{title}</Header>
        <Segment padded inverted>
          <Grid centered>
            <Grid.Row>
              <Label basic size="huge">{label}</Label>
            </Grid.Row>
          </Grid>
        </Segment>
      </Container>
    );
  }
}

export default SignedOutPanel;
