/**
 * This component displays a Card with information about
 * a single Way/Act/Itn object.
 */
import _ from 'lodash';
import React from 'react';
import Moment from 'react-moment';
import {Card, Button, Checkbox, Popup} from 'semantic-ui-react';

// Used for Accordion (my adventures) functionality.  BELOW
import {Accordion, Transition, Menu} from 'semantic-ui-react';
import MyCardContent from './my-card-content';
// Used for Accordion (my adventures) functionality.  ABOVE

//import {isWayDisplayName, isWayDetails} from 'concierge-common';
import {validateLength} from 'concierge-common';
import {validateEmail, validatePassword} from 'concierge-common';
import {log, trimDeep, ImageUtils} from 'concierge-common';
import Obj from '../client-db/api/obj';
import User from '../client-db/api/obj';

import {USERS, WAYS, ACTS, ITNS} from '../global-constants';
import {REDACTED} from '../global-constants';
import UserLine from '../containers/user-line';
import ObjUtils from '../client-db/api/obj-utils';
import ApiUtils from '../client-db/api/api-utils';
import UrlButtonUtils from './utils/url-button-utils';
import TimeUtils from './utils/time-utils';
import ActionUtils from './utils/action-utils';
import Selector from './selector';
import MyCardDisplayName from './my-card-display-name';
import UserNameAndFlags from './user-name-and-flags';
import FellowTravelers from './fellow-travelers';
import MyCardDescription from './my-card-description';
import EmailAddress from './email-address';
import Password from './password';
import ActionImage from './action-image';
import ActionCarousel from './action-carousel';
import MyLocation from './my-location';
import Visibility from './visibility';


class ObjComponent extends React.Component {

  static _VISIBILITY_OPTIONS = {};
  static VISIBILITY_OPTIONS(cn) {
    if (ObjComponent._VISIBILITY_OPTIONS[cn] == null) {
      const name = Obj.DISPLAY_NAMES[cn]; // e.g. "Waypoint"
      ObjComponent._VISIBILITY_OPTIONS[cn] = [
        {key:'isPublic', text:'Public',
          value:'isPublic', icon:'eye',
          description:name+"'s attributes visible to everyone."},
        {key:'isProtected',text:'Protected',
          value:'isProtected', icon:'protect',
          description:name+"'s Location NOT visible to anyone."},
        {key:'isPrivate',text:'Private',
          value:'isPrivate', icon:'hide',
          description:name+' NOT visible to anyone.'},
      ];
    }
    return ObjComponent._VISIBILITY_OPTIONS[cn];
  };

  // Called if user clicks a "Start", "End", or "Start/End"
  // button/link.  Show only a single Waypoint.  I.e. show
  // the "WayPanel", not the "ActWaysPanel".
  // This is a read-only view of the Waypoint.  If the curUser
  // had edit privileges on the Act's list of Ways, we would
  // not be displaying the "Start", "End", or "Start/End" buttons.
  handleClickStartOrEnd = (objId) => {
    const {cA_History} = this.props;
    const childDocName = this.getChildDocName();
    const url = "/"+childDocName+"?objId="+objId;
    cA_History("push", url);
  }

  // Called if user clicks the "Waypoints/Adventures" button/link.
  // Show the list of obj.children
  handleClickChildren = (e_NotUsed) => {
    const {obj, cA_History} = this.props;
    const url = "/objProp?objId="+obj._id+
      "&objPropName=children"+
      "&objPropCn="+obj.childrenCn;
    cA_History("push", url);
  }

  // Called if user clicks a "Map" button/link.
  handleClickChildrenMap = (e) => {
    const {obj, cA_History} = this.props;
    const headerTitle = obj.cn == ACTS ?
      Obj.DISPLAY_NAME_PLURAL(WAYS) :
      Obj.DISPLAY_NAME_PLURAL(ACTS);
    const url = "/locationMap?objId="+obj._id+
      //"&propName=children"+  /// TODO: Delete this.
      "&objPropName=children"+
      "&objPropCn="+obj.childrenCn+
      "&headerTitle="+headerTitle;
    cA_History("push", url);
  }

  // Return "way", "act", "itn".
  getDocName = () => {
    const {obj} = this.props;
    return obj.cn.slice(0, -1);  // E.g. "way"
  }

  // Return "way", "act".
  getChildName = () => {
    const {obj} = this.props;

    if (!obj || !obj.childrenCn) {
      log.bug("In ObjComponent.getChildName(), unhandled obj: ",
        obj);
      debugger;
      return WAYS.slice(0, -1);
    }
    // Return "way" for "ways", "act" for "acts".
    return obj.childrenCn.slice(0, -1);
    /*
    if (this.getDocName() == ACTS) {
      return WAYS.slice(0, -1);
    }
    else if (this.getDocName() == ITNS) {
      return ACTS.slice(0, -1);
    }
    else {
      log.bug("In ObjComponent.getChildName(), unhandled getDocName(): ",
        this.getDocName());
      debugger;
      return WAYS.slice(0, -1);
    }
    */
  }

  handleClickImage = (e) => {
    if (e) {
      e.stopPropagation();
    }

    const {obj, noDrillDown, cA_History} = this.props;
    let url;
    if (noDrillDown) {
      // TODO: If we change the DB code to always have at least
      // an empty children array for objects that can have children,
      // we could could simply check for (obj.children !== undefined)
      if (obj.cn == ACTS || obj.cn == ITNS) {
        // Clicking the image is treated like clicking the
        // Waypoints or Adventures button.
        this.handleClickChildren();
      }
    }
    else {
      url = "/obj?objId="+obj._id;
      cA_History("push", url);
    }
  }

  /**
   * Called by MyCardDisplayName when the user is finished editing the
   * the Obj's displayName property and wants to send the new
   * value to the server.  This is also called by other RIE fields
   * when the user changes their value.
   *
   * @param {Object} props - An Object containing the new displayName.
   * (This is the "task" parameter that the RIEInput sends us.)
   * e.g. {displayName:"Mt. Arthur Hike"}
   * @param {String} props.displayName - The new displayName value.
   */
  handleChange = (props) => {
    log.trace("handleChange(), props:", props);
    const {obj} = this.props;
    const objType = Obj.DISPLAY_NAME(obj.cn);
    trimDeep(props);
    let error;
    if (props.displayName) {
      //error = validateWayDisplayName(props.displayName);
      error = validateLength(props.displayName,
        Obj.DISPLAY_NAME_MIN_LENGTH, Obj.DISPLAY_NAME_MAX_LENGTH,
          objType+"'s name");
    }
    else if (props.details) {
      error = this.possiblyEditObj(props, error);
    }
    else {
      log.error("Unhandled "+objType+" property change:", props);
      return;
    }
    this.possiblyEditObj(props, error);
  }

  handleValidationFail = (result, displayName) => {
    log.trace("ObjComponent.handleValidationFail(), displayName:", displayName);
    const {obj, cA_SetError} = this.props;
    const objType = Obj.DISPLAY_NAME(obj.cn);
    const error = validateLength(displayName,
      Obj.DISPLAY_NAME_MIN_LENGTH, Obj.DISPLAY_NAME_MAX_LENGTH,
        objType+"'s name");
    cA_SetError(error);
  }

  /**
   * Called when the user is finished editing the email and wants
   * to send the new value to the server.
   *
   * @param {Object} userProps - An Object containing the new email address.
   * (This is the "task" parameter that the RIEInput sends us.)
   * e.g. {email:"john@gmail.com"}
   * @param {String} userProps.email - The new email address.
   */
  handleChangeEmail = (userProps) => {
    log.trace("handleChangeEmail(), userProps:", userProps);
    userProps.email = _.trim(userProps.email);
    const error = validateEmail(userProps.email);
    const {obj:user, cA_SetError} = this.props;
    const {curUserObj} = this.state;
    ActionUtils.possiblyEditUser(userProps, error, curUserObj,
      user._id, cA_SetError);
  }

  /**
   * Called when the user is finished editing the password and wants
   * to send the new value to the server.
   *
   * @param {Object} userProps - An Object containing the new password.
   * (This is the "task" parameter that the RIEInput sends us.)
   * e.g. {password:"myPassw0rd!"}
   * @param {String} userProps.password - The new password.
   */
  handleChangePassword = (userProps) => {
    log.trace("handleChangePassword(), userProps:", userProps);
    log.trace("this.props.user:", this.props.user);
    userProps.password = _.trim(userProps.password);
    const error = validatePassword(userProps.password);
    const {curUser, obj:user, cA_SetError} = this.props;
    ActionUtils.possiblyEditUser(userProps, error, curUser, user._id,
      cA_SetError);
  }

  handleClickAccordion = (e, titleProps) => {
    if (this.props.noExpand) {
      // Ignore it.
      return;
    }
    const {index} = titleProps;
    const {activeIndex} = this.state;
    const newIndex = activeIndex == index ? 0 : index;
    this.setState({activeIndex:newIndex});
  }

  handleClickScenicCheckbox = () => {
    // Toggle the checkbox.
    const {obj} = this.props;
    this.possiblyEditObj({isScenic:!obj.isScenic}); 
  }

  /**
   * The user has edited a value in this Obj, create and
   * dispatch an edit action.  The server might veto the
   * edit.
   */
  possiblyEditObj = (props, error) => {
    log.trace("possiblyEditObj(), props:", props);
    const {curUser, obj, cA_SetError} = this.props;
    if (error) {
      log.trace("Calling cA_SetError() with error:", error);
      cA_SetError(error);
      return;
    }
    else {
      cA_SetError(null);
    }
    ApiUtils.editRxDBObj(curUser, obj._id, props, obj.cn);
  }
  
  createCreatorOwnerLines = () => {
    const {props, state} = this;
    const {obj} = props;
    const {creatorObj, ownerObj} = state;
    const objType = Obj.DISPLAY_NAME(obj.cn);

    log.trace("createCreatorOwnerLines(), state: ", state);

    let popupContent = "";
    if (creatorObj == ownerObj && ownerObj != null) {
      popupContent = ownerObj.displayName+
        " is creator and owner of this "+objType+".";
    }
    else if (creatorObj != null && ownerObj._id != User.FOSTER_USER_ID) {
      if (creatorObj._id == User.DEFAULT_USER_ID) {
        popupContent = "An ";
      }
      popupContent += creatorObj.displayName+
        " created this "+objType+", but "+ownerObj.displayName+
        " is now the owner, and can answer any questions about it.";
    }
    else if (creatorObj != null && ownerObj._id == User.FOSTER_USER_ID) {
      if (creatorObj._id == User.DEFAULT_USER_ID) {
        popupContent = "An ";
      }
      popupContent += creatorObj.displayName+
        ' created this '+objType+', but it has been "orphaned".  '+
        "If you want to become the new owner of this "+objType+" and "+
        "keep its details and photos up to date, please contact the "+
        "Jasiri team!";
    }

    let user = creatorObj ? creatorObj : User.DEFAULT_USER;
    let userLineProps = {
      user,
      userPanelTitle:'Creator of '+obj.displayName,
      popupContent,
    };
    let creatorLine;
    if (creatorObj != ownerObj) {
      creatorLine = creatorObj ? <UserLine {...userLineProps} /> : undefined;
    }

    user = ownerObj ? ownerObj : User.DEFAULT_USER;
    userLineProps.user = user;
    userLineProps.userPanelTitle = 'Owner of '+obj.displayName;
    const ownerLine = ownerObj ? <UserLine {...userLineProps} /> : undefined;

    return {creatorLine, ownerLine};
  }

  handleClickParking = () => {
    log.trace("handleClickParking()");
    const props = this.props;
    const {obj, cA_History} = props;

    let url;
    if (obj.edit) {
      const title = obj.parkWaypoint ?
        "Change Parking" : "Select Parking";
      // Select the Waypoint that will be the Adventure's parkWaypoint.
      url = "/selectObj?task=selectParkWaypoint"+
        "&objId="+obj._id+
        "&selectCn=ways"+
        "&multiselect=false"+
        "&title="+title;
    }
    else {
      const title = obj.displayName+" Parking";
      const propNameParam = obj.edit ?
        "&propName=parkWaypoint" : "";
      const propDisplayNameParam = obj.edit ?
        "&propDisplayName=Parking" : "";
      url = "/obj?objId="+obj.parkWaypoint+
        "&parentId="+obj._id+
        propNameParam+  // Not needed?
        propDisplayNameParam+  // Not needed?
        "&title="+title;
    }
    log.trace("url:", url);
    cA_History("push", url);
  }

  /**
   * Returns the "Parking" button/link component if the
   * current user can view or edit the Act's parkWaypoint
   * property.
   *
   * Returns undefined if no "Parking" button/link should be
   * shown.
   */
  createParkingButton = () => {
    const {obj} = this.props;
    log.trace("createParkingButton(), obj:", obj);
    log.trace("obj.parkWaypoint:", obj.parkWaypoint);
    if (obj.parkWaypoint == REDACTED) {
      return undefined;
    }

    // We do NOT display the "Parking" button/link if
    // the any of the following are true:
    //
    //    1) Act object has not yet been retrieved.
    //
    //    2) Act object is not editable by the current user
    //       nor does the Act have a parkWaypoint to display.
    // 
    // NOTE: If a parkWaypoint has not been set, but the current
    // user can edit this Act, then we need to display the
    // "Parking" button/link so the user can set the location
    // if s/he wants to.
    //
    if (!obj || (!obj.edit && !obj.parkWaypoint)) {
      return undefined;
    }

    const label = (obj.edit && !obj.parkWaypoint) ?
      "Select Parking" : (obj.edit) ?
      "Change Parking" : "Parking";
      //"Edit Parking" : "Parking";
    // Display the (possibly editable) "Parking" button/link.
    const parkingButton = 
      <Button basic primary className="prop-button ellipsis"
        onClick={this.handleClickParking}>
        {label}
      </Button>

    return parkingButton;
  }

  createChildrenButton() {
    const {obj} = this.props;
    if (obj.children == REDACTED) {
      return undefined;
    }
    //const cn = Obj.getCnFromId(obj._id);
    //const label = cn == ACTS ? "Waypoints" : "Adventures";
    const label = Obj.DISPLAY_NAME_PLURAL(obj.childrenCn);

    return(
      <Button basic primary className="prop-button ellipsis"
        onClick={this.handleClickChildren}>
        {label}
      </Button>
    );
  }

  /**
   * Returns zero or more the "Waypoint" buttons/links if the
   * current user can view or edit the Act's ways property.
   * If the list of Waypoints is NOT editable, then we display
   * different buttons/links depending upon the number of Waypoints.
   * If the act.ways array contains one waypoint, it is called
   * "Start/End".
   * If the act.ways array contains two waypoints, two buttons/links
   * are created:  act.ways[0] is called "Start" and act.ways[1]
   * is called "End".
   * If the act.ways array contains more than two waypoints,
   * one button/link is created called "Waypoints".
   *
   * Returns undefined if no "Waypoints" buttons/links should be
   * shown.
   */
  createChildrenComponents() {
    const props = this.props;
    const {obj} = props;
    log.trace("obj:", obj);
    log.trace("obj.children:", obj.children);

    // We do NOT display the "Waypoints/Adventures" button/link if
    // the any of the following are true:
    //
    //    1) The Act/Itn object has not yet been retrieved.
    //
    //    2) The Act/Itn object is not editable by the current user
    //       nor does the Act/Itn have children to display.
    //
    // NOTE: If no waypoints have been set, but the current
    // user can edit this Act/Itn, then we need to display the
    // "Waypoints/Adventures" button/link so the user can add some
    // children if s/he wants to.
    //
    if (!obj || (!obj.edit && (!obj.children || obj.children.length < 1))) {
      return undefined;
    }

    if (obj.edit) {
      // If the user can edit obj.children, we always display
      // the Waypoints/Adventures button/link.
      // We never display the "Start/End" or "Start" and "End"
      // buttons if the user can edit this obj.
      return this.createChildrenButton();
    }

    // Display the non-editable "Waypoints/Adventures" buttons/links.

    const numChildren = obj.children.length;
    switch(numChildren) {
      case 0:
        // No waypoints. E.g. an Adventure that does not have
        // a specific location, or perhaps the creator of the
        // Adventure only specified a parking location because
        // the start/end location is obviouse.  E.g. a museum.
        // Nothing to show a user who cannot edit act.ways.
        return undefined;

      case 1:
        // The single Waypoint will be a combined start and end
        // of the Adventure.  E.g. a loop hike.
        const startEndChildId = obj.children[0];
        return <Button basic primary className="prop-button ellipsis"
            onClick={()=>this.handleClickStartOrEnd(startEndChildId)}>
            Start/End
          </Button>

      case 2:
        const startChildId = obj.children[0];
        const endChildId = obj.children[1];
        // The two Waypoints/Adventures will be separate start
        // and end points of the Itinerary/Adventure.
        // key property is to make React happy.
        return [
            <Button basic primary className="prop-button ellipsis"
              onClick={()=>this.handleClickStartOrEnd(startChildId)}
              key={0}>
              Start
            </Button>,
            <Button basic primary className="prop-button ellipsis"
              onClick={()=>this.handleClickStartOrEnd(endChildId)}
              key={1}>
              End
            </Button>
          ]

      default:
        // Three or more Waypoints, so show all of them.
        return this.createChildrenButton();
    }

    // Should never get here.
  }

  /**
   * Create the "Map" button if there are any Waypoints in this
   * Act.
   *
   * NOTE: Do we want to show parking on this map?
   * Maybe once we label the waypoints.
   */
  createChildrenMapButton() {
    const props = this.props;
    const {obj} = props;
    log.trace("ObjComponent.createChildrenMapButton(), obj:", obj);
    if (!obj) {
      // Act/Itn has not been retrieved.
      return undefined;
    }
    log.trace("obj.children:", obj.children);
    log.trace("obj.edit:", obj.edit);
    if (obj.children == REDACTED) {
      // The current user is not allowed to see the waypoints/adventures.
      return undefined;
    }

    if (!obj.edit) {
      // Act/Itn is NOT editable by the current user.
      if (!obj.children || obj.children.length < 1) {
        // Act/Itn does NOT have any waypoints/adventures to display.
        return undefined;
      }
      // Act/Itn is NOT editable, but there are waypoints/adventures
      // to show.
    }

    return <Button basic primary className="prop-button ellipsis"
        onClick={this.handleClickChildrenMap}>
        Map
      </Button>
  }

  /**
   * Returns the "Location" button/link component if the
   * current user can view or edit the Way's locationPoint
   * property.
   *
   * Returns undefined if no "Location" button/link should be
   * shown.
   */
  createLocationButton() {
    const props = this.props;
    const {obj, cA_History} = props;
    log.trace("obj:", obj);
    log.trace("obj.locationPoint:", obj.locationPoint);
    if (obj.locationPoint == REDACTED) {
      return undefined;
    }

    // We do NOT display the "Location" button/link if
    // the any of the following are true:
    //
    //    1) The object has not yet been retrieved.
    //
    //    2) The object is not editable by the current user
    //       nor does the object have a locationPoint to display.
    // 
    // NOTE: If a locationPoint has not been set, but the current
    // user can edit this Way, then we need to display the
    // "Location" button/link so the user can set the location
    // if s/he wants to.
    //
    if (!obj || (!obj.edit && !obj.locationPoint)) {
      return undefined;
    }

    // Display the (possibly editable) "Location" button/link.
    // If the location can be edited, display "Edit Location"
    // if a location has been set.  Display "Set Location" if
    // it has never been set.
    //
    // If the locationPoint is not editable, i.e. it is not ours,
    // display just "Location".  A non-editable not-set location
    // would have caused this createLocationButton() function to
    // return from the code above.
    const label = obj.edit ?
      obj.locationPoint ? "Edit Location" : "Set Location" :
      "Location";

    const objId = obj && obj._id;
    const myLocationProps = {
      label,
      cA_History,
      objId,
    }

    log.trace("myLocationProps:", myLocationProps);
    const locationButton =
      <MyLocation {...myLocationProps} />

    return locationButton;
  }

  getSrcFallback = () => {
    const {obj} = this.props;

    const isPrivate = !!(obj && obj.visibility == "isPrivate");
    const srcFallback = isPrivate ?
      Obj.PLACEHOLDER_PRIVATE_IMAGE(obj.cn) :
      Obj.PLACEHOLDER_IMAGE(obj.cn);
    return srcFallback;
  }

  createActionImage = (imageSrc, multiAction, popupContent, onClick) => {
    const {obj, cA_Confirmation, cA_SetError, cA_History} = this.props;
    const {curUserObj} = this.state;
    const srcFallback = this.getSrcFallback();
    const imageProps = {
      src:imageSrc,
      srcFallback,
      obj,  // Used to calc ribbons.
      multiAction,
      popupContent,
      onClick,
      curUserObj,
      cA_Confirmation,
      cA_SetError,
      cA_History,
    };
    const component = 
      <ActionImage {...imageProps} />
    return component;
  }

  createActionCarousel = (images, multiAction, popupContent, onClick) => {
    const {obj, cA_Confirmation, cA_SetError, cA_History} = this.props;
    const {curUserObj} = this.state;
    //const {childrenImageSrcs:srcs} = this.state;
    log.trace("images:", images);

    const srcFallback = this.getSrcFallback();
    const carouselProps = {
      //src:imageSrc,
      srcs:images,
      srcFallback,
      obj,  // Used to calc ribbons.
      //imageErrorHandler,
      multiAction,
      popupContent,
      onClick,
      curUserObj,
      cA_Confirmation,
      cA_SetError,
      cA_History,
    };
    const component =
      <ActionCarousel {...carouselProps} />
    return component;
  }

  createImageActionComponent = (multiAction, popupContent,
    onClick) => {
    const {props, state} = this;
    const {obj, cache, cA_GetObj} = props;
    const {curUserObj} = state;

    // This should be a user setting.
    // If showChildren is true, we will show as many scenic
    // descendant images as are available.
    const showChildren = true;
    const images = obj.getCarouselImages(curUserObj,
      cache, cA_GetObj, showChildren);
    log.trace("images: ", images);

    /*
    // An ObjComponent can display either a single image
    // in an ActionImage component, or it can display a
    // "carousel" slideshow of images in an ActionCarousel
    // component.
    // Only show the carousel of children images if the user
    // has NOT specified a specific imageSrc for this object
    // and there are more than 1 children images to display.
    // Otherwise, just display the single imageSrc.
    log.trace(obj.displayName+" childrenImageSrcs: ",
      state.childrenImageSrcs);
    let actionComponent;
    if (obj.hasUserSetImageSrc()) {
      // Display the single imageSrc the user specified
      // for this object.
      actionComponent = this.createActionImage(obj.imageSrc, multiAction,
        popupContent, onClick);
    }
    else if (!state.childrenImageSrcs ||
             state.childrenImageSrcs.length < 1) {
      // The user did not specify an image, but there are
      // no children images either, so just show the
      // placeholder that is in imageSrc.
      actionComponent = this.createActionImage(obj.imageSrc, multiAction,
        popupContent, onClick);
    }
    else if (state.childrenImageSrcs &&  
             state.childrenImageSrcs.length == 1) {
      // The user did not specify an image, but there is
      // only a single child image, so just show the
      // single child image.  Don't bother with the carousel.
      const imageSrc = state.childrenImageSrcs[0];
      actionComponent = this.createActionImage(imageSrc, multiAction,
        popupContent, onClick);
    }
    else {
      // The user did not specify an image, and there is
      // more than one child image to display, so use
      // a carousel.
      actionComponent = this.createActionCarousel(multiAction,
        popupContent, onClick);
    }
    */
    let actionComponent;
    if (images.length == 1) {
      // Display the single image available.
      // The image could be directly from this object's imageSrc
      // property, or it could be from one of its descendants.
      // E.g. an Itn might be displaying an image of one of its
      // Act's Waypoints.
      actionComponent = this.createActionImage(images[0], multiAction,
        popupContent, onClick);
    }
    else if (images.length > 1) {
      // There is more than one child image to display, so use
      // a carousel.
      actionComponent = this.createActionCarousel(images, multiAction,
        popupContent, onClick);
    }

    return actionComponent;
  }

  /*
  static getChildrenImageSrcs(curUserObj, props) {
    const {obj, cache, cA_GetObj} = props;

    const childrenImageSrcs = [];
    obj.children.forEach(childId => {
      log.trace("childId: ", childId)
      const child = ObjUtils.get(curUserObj, childId, undefined,
        cache, cA_GetObj);
      // Only put "scenic" images into the slideshow.
      if (child && child.imageSrc && child.isScenic &&
          child.hasUserSetImageSrc()) {
        log.trace("child.imageSrc: ", child.imageSrc);
        childrenImageSrcs.push(child.imageSrc);
      }
    });
    log.trace("childrenImageSrcs.length: ", childrenImageSrcs.length);
    return childrenImageSrcs;
  }
  */

  createScenicCheckbox = () => {
    const {obj} = this.props;
    log.trace("In createScenicCheckbox, "+obj.displayName+
      " obj: ", obj);
 
    // The Scenic checkbox is only shown for Waypoints.
    // Maybe should show it for other objects?
    //
    // Don't show a Scenic checkbox if:
    // 1) The current user cannot edit this object.
    // 2) There is no image specified for this object.
    if (!obj || obj.cn != WAYS || !obj.edit ||
        !obj.hasUserSetImageSrc()) {
      return undefined;
    }

    const popupMessage = 'Check if this is a "scenic" image '+
      'to be shown in slideshows.  Uncheck if just a '+
      '"navigational" image.'
    const scenicCheckbox = 
      <Card.Content extra className="">
        <Popup
          content={popupMessage}
          trigger={
            <Checkbox label="Scenic"
              onChange={this.handleClickScenicCheckbox}
              checked={obj.isScenic}
            />
          } />
      </Card.Content>
    return scenicCheckbox;
  }

  static createStateFromProps(props) {
    const {curUser, obj, cache, cA_GetObj} = props;
    const state = {};

    let curUserObj = User.DEFAULT_USER;
    if (curUser._id) {
      curUserObj = ObjUtils.get(curUser, curUser._id,
        undefined, cache, cA_GetObj);
    }
    /*
    else {
      curUserObj = ObjUtils.get(curUser, User.DEFAULT_USER_ID,
        undefined, cache);
    }
    */
    state.curUserObj = curUserObj;

    //if (curUserObj && obj && obj.children) {
    //  state.childrenImageSrcs = ObjComponent.getChildrenImageSrcs(
    //    curUserObj, props);
    //}

    const {creator:creatorId, owner:ownerId} = obj;
    state.creatorObj = ObjUtils.getUser(curUser, creatorId,
      cache, cA_GetObj);
    if (creatorId == ownerId) {
      state.ownerObj = state.creatorObj;
    }
    else {
      state.ownerObj = ObjUtils.getUser(curUserObj, ownerId, cache, cA_GetObj);
    }

    // Accordion
    /*
    if (props.noExpand) {
      state.activeIndex = 0;
    }
    else {
      state.activeIndex = this.state.activeIndex ?
        this.state.activeIndex :
        (props.activeIndex ? props.activeIndex : 0);
    }
    */

    return state;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const newState = ObjComponent.createStateFromProps(nextProps);
    if (_.isEqual(prevState, newState)) {
      return null;
    }
    else {
      return newState;
    }
  }

  constructor(props) {
    super(props);
    /*
    this.state = {
      curUserObj:User.DEFAULT_USER,
      creatorObj:User.DEFAULT_USER,
      ownerObj:User.DEFAULT_USER,
    };
    */
    this.state = ObjComponent.createStateFromProps(props);
  }

  render() {
    log.trace("ObjComponent.render() this.props:", this.props);
    log.trace("ObjComponent.render() objId:",
      this.props.obj && this.props.obj._id);
    const {props, state} = this;
    const {cache, curUser, obj,
      objPropName, parentObj, parentObjPropName,
      selectable, onSelect, noDrillDown,
      propDisplayName, cA_SetError, cA_GetObj,
      cA_History, cA_Confirmation, index:orderedObjIndex} = props;
    const {curUserObj} = state;
    log.trace("ObjComponent.render() curUserObj: ", curUserObj);
    let {className} = props;
    log.trace("obj:", obj);
    const docName = this.getDocName();
    className = className ? docName+" "+className : docName;
    //className += obj && (obj.statusAttribute == PENDING ||
    //  obj.statusAttribute == FLAGGED) ?
    //  " "+obj.statusAttribute :
    //  "";

    log.trace("parentObj:", parentObj);

    let {imageSrc, displayName, details, created, creator, owner} = obj;

    let creatorLine = undefined;
    let ownerLine = undefined;
    if (obj.cn != USERS) {
      const lines = this.createCreatorOwnerLines();
      creatorLine = lines.creatorLine;
      ownerLine = lines.ownerLine;
    }

    const objType = Obj.DISPLAY_NAME(obj.cn);
    const multiAction = ActionUtils.calcObjMultiAction(props,
      curUserObj, obj, objPropName, objType+" Actions", parentObj,
      parentObjPropName, propDisplayName, noDrillDown, orderedObjIndex);
    log.trace("ObjComponent.render(), multiAction:", multiAction);

    const isEditable = !!obj.edit;

    let onClick;
    let popupContent;
    if (noDrillDown &&
      (obj.cn == ACTS || obj.cn == ITNS)) {
      // User cannot drilldown into this object anymore
      // because we are already showing the individual
      // object page with just this one object.
      // But, because the object has children, we will
      // treat clicking the image like clicking the
      // Waypoints or Adventures button in an Act or Itn.
      const seeWhat = obj.cn == ACTS ? "Waypoints" : "Adventures";
      popupContent = "Click image to see "+objType+" "+seeWhat;
      if (multiAction) {
        popupContent += ", or click the [ \u22EE ] button for other actions.";
      }
      else {
        popupContent += ".";
      }
      onClick = e => this.handleClickImage(e);
    }
    else if (multiAction && noDrillDown) {
      // User cannot drill down into this Obj.  (We are already
      // showing the details page for this Obj.)
      popupContent = "Click the [ \u22EE ] button for actions.";
    }
    else if (!noDrillDown) {
      // Use can drill down into this Obj.  (i.e. view details)
      popupContent = "Click image to see "+objType+" details";
      if (multiAction) {
        popupContent += ", or click the [ \u22EE ] button for other actions.";
      }
      else {
        popupContent += ".";
      }
      onClick = e => this.handleClickImage(e);
    }
    else {
      // Nothing can be done.  Probably a "Private" object.
    }

    let displayNameAndFlagsComponent = undefined;
    if (obj.cn != USERS) {
      const displayNameProps = {
        text:displayName,
        propName:"displayName",
        //isDisabled:!isEditable,
        isEditable:isEditable,
        change:this.handleChange,
        handleValidationFail:this.handleValidationFail,
        //validate:isWayDisplayName,
        maxLength:Obj.DISPLAY_NAME_MAX_LENGTH
      }
      displayNameAndFlagsComponent =
        <MyCardDisplayName {...displayNameProps} />
    }

    let createdAtComponent = undefined;
    const showCreatedAt = obj.cn == USERS ?
      ActionUtils.showJoined(obj) : true;
    if (showCreatedAt) {
      const momentLabel = obj.cn == USERS ?
        "Joined" : "Created";
      const momentProps = TimeUtils.getMomentProps(created, momentLabel+": ");
      createdAtComponent = 
        <Popup on="hover"
          content={momentProps.text}
          trigger={
            <Card.Meta className="ellipsis">
              {momentLabel} <Moment {...momentProps}>{created}</Moment>
            </Card.Meta>
          }
        />
    }

    const descriptionProps = {
      text:details,
      propName:"details",
      //isDisabled:!isEditable,
      isEditable:isEditable,
      change:this.handleChange,
      handleValidationFail:this.handleValidationFail,
      //validate:isWayDetails,
      maxLength:Obj.DETAILS_MAX_LENGTH
    }

    const locationButton = this.createLocationButton();
    log.trace("locationButton:", locationButton);

    let parkingButton;
    let childrenButton;  // Show Waypoint cards.
    let childrenMapButton;  // Show map of Waypoint locations.
    if (obj.cn == ACTS || obj.cn == ITNS) {
      parkingButton = this.createParkingButton();
      childrenButton = this.createChildrenComponents();
      childrenMapButton = this.createChildrenMapButton();
      log.trace("childrenMapButton:", childrenMapButton);
    }

    const urlsPropName = "urls";
    const urlButtons = UrlButtonUtils.createUrlButtons(obj,
      urlsPropName, cA_History, cA_Confirmation,
      cA_SetError);

    let visibilitySelect;
    if (isEditable) {
      const visibilityProps = {
        curUserObj,
        obj,
        //visibility:obj.visibility,
        cA_SetError,
        cA_History,
        collectionName:obj.cn,
        popupContent:"Set the visibility of this "+objType+".",
        options:ObjComponent.VISIBILITY_OPTIONS[obj.cn],
      }
      visibilitySelect = <Visibility {...visibilityProps} />
    }

    const imageActionComponent = this.createImageActionComponent(
      multiAction, popupContent, onClick);

    const scenicCheckbox = this.createScenicCheckbox();

    let adventuresComponent = undefined;
    let passwordComponent = undefined;
    let emailComponent = undefined;
    let fellowTravelersSection = undefined;
    if (obj.cn == USERS) {

      // Index of active/expanded accordian.
      const {activeIndex} = this.state;
      let {email, password, fellowTravelersText, adventuresText,
        completedAdventuresText, plannedAdventuresText,
        consideredAdventuresText} = obj;

      log.trace("fellowTravelers:", obj.fellowTravelers);
      log.trace("fellowTravelersText:", fellowTravelersText);
      log.trace("adventuresText:", adventuresText);

      // Only show the password field if it is editable.
      passwordComponent = isEditable ?
        <Password password={password} editable={true} hidden={false}
          cA_SetError={cA_SetError}
          change={this.handleChangePassword} />
        : undefined;

      // Only show the email field if curUser is allowed to see it.
      emailComponent = email ?
        <EmailAddress email={email} editable={isEditable}
          cA_SetError={cA_SetError}
          change={this.handleChangeEmail} propName="email" />
        : undefined;

      // This user's name and flags.
      const displayNameAndFlagsProps = {
        curUser,
        user:obj,
        cA_SetError,
      };
      displayNameAndFlagsComponent =
        <UserNameAndFlags {...displayNameAndFlagsProps} />

      // Fellow Travelers
      const fellowTravelersProps = {
        curUser,
        user:obj,
        fellowTravelersText,
      };
      if (ActionUtils.showFellowTravelers(obj)) {
        fellowTravelersSection =
          <Card.Content extra>
            <FellowTravelers {...fellowTravelersProps} />
          </Card.Content>
      }

      // TODO: implement this.
      const adventuresImplemented = false;
      let adventuresComponent;
      if (adventuresImplemented) {
        adventuresComponent =
          <Accordion as={Menu} fluid vertical>
            <Menu.Item>
              <Accordion.Title
                active={activeIndex == 1}
                index={1}
                onClick={this.handleClickAccordion}
                content={
                  <MyCardContent iconName="compass"
                    text={adventuresText} editable={false} />
                } />
              <Transition animation="slide down" visible={activeIndex == 1}>
                <Accordion.Content active={activeIndex == 1}
                  content={
                    <Card>
                      <MyCardContent iconName="checkmark"
                        text={completedAdventuresText} editable={false} />
                      <MyCardContent iconName="calendar"
                        text={plannedAdventuresText} editable={false} />
                      <MyCardContent iconName="question"
                        text={consideredAdventuresText} editable={false} />
                    </Card>
                  } />
              </Transition>
            </Menu.Item>
          </Accordion>
      }
    }

    // We are now ready to assemble this ObjComponent.
    // First create the ObjComponent, then if it is
    // selectable, e.g. because it will be displayed in
    // a SelectObjPanel, wrap it in a Selector component.
    //
    let component =
      <Card className={className}>
        {imageActionComponent}
        {scenicCheckbox}
        <Card.Content className="column">
          {displayNameAndFlagsComponent}
          {creatorLine}
          {ownerLine}
          {createdAtComponent}
          {locationButton}
          <MyCardDescription {...descriptionProps} />
          <div className="prop-button-container">
            {parkingButton}
            {childrenButton}
            {childrenMapButton}
            {urlButtons}
          </div>
        </Card.Content>
        {adventuresComponent}
        {fellowTravelersSection}
        {emailComponent}
        {/*passwordComponent*/}
        {visibilitySelect}
      </Card>

    if (selectable) {
      component = <Selector onSelect={onSelect}
        selectCallbackData={obj}>
        {component}
        </Selector>
    }

    return component;
  };
}

export default ObjComponent;
