/**
 */
//import $ from 'jquery';
import React from 'react';
//import ReactDOM from 'react-dom';
import {Card} from 'semantic-ui-react';
import {RIEInput} from 'riek';
//import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';
import {log} from 'concierge-common';

class MyCardDisplayName extends React.Component {

  /**
   * Called by the RIEInput once the field looses focus.
   * How is this different from handleAfterFinish?
   */
  handleChange = (task) => {
    log.trace("handleChange, task:", task);
    this.setState({...task});
    const {change} = this.props;
    change({...task});
  }
  handleValidationFail = (result, value, cancelEditing) => {
    log.trace("handleValidationFail(), value:", value);
    const {handleValidationFail} = this.props;
    handleValidationFail(result, value/*, cancelEditing*/);
    cancelEditing();
  }

  /**
   * handleBeforeStart and handleAfter finish are only used to
   * hide/show the has-action highlighting.
   */
  /*
  handleBeforeStart = () => {
    log.trace("handleBeforeStart(), this.node:", this.node);
    //scrollIntoViewIfNeeded(this.node, {duration:500});
    const node = ReactDOM.findDOMNode(this.myCardHeader);
    node.classList.add("hide-has-action");
  }
  handleAfterFinish = (task) => {
    const node = ReactDOM.findDOMNode(this.myCardHeader);
    node.classList.remove("hide-has-action");
  }
  */

  checkForOverflow() {
    if (!this.span || !this.span.parentElement) {
      return;
    }
    const el = this.span.parentElement;
    log.trace("el.offsetWidth:", el.offsetWidth);
    //log.trace("$(el).outerWidth:", $(el).outerWidth());
    //log.trace("$(el).outerWidth(true):", $(el).outerWidth(true));
    log.trace("el.clientWidth:", el.clientWidth);
    log.trace("el.scrollWidth:", el.scrollWidth);
    if (el.scrollWidth > el.offsetWidth) {
      el.classList.add("overflows");
    }
    else {
      el.classList.remove("overflows");
    }
  }

  constructor(props) {
    super(props);
    const {text} = props;
    this.state = {text};
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const {text} = newProps;
    this.setState({text})
  }

  componentDidMount() {
    log.trace("componentDidMount(), this.span:", this.span);
    this.checkForOverflow();
  }
  componentDidUpdate() {
    log.trace("componentDidUpdate(), this.span:", this.span);
    this.checkForOverflow();
  }

  render() {
    log.trace("MyCardDisplayName.render() this.props:", this.props);
    const props = this.props;

    const {propName, text, validate, maxLength, isEditable} = props;
    let {className} = props;

    const defaultClassName = "my-card-display-name ellipsis";
    className = className ? (defaultClassName+className) : defaultClassName;

    /*
    if (!isDisabled) {
      className += " has-action";
    }
    */

    // Don't show this component at all if the text is null.
    // I.e. this component won't take up any space and its
    // faint divider line won't be shown either.
    //
    // If text is "" (the empty string) we will show this
    // component's icon with a blank area next to the icon.
    //this.state.text = text;
    log.trace("MyCardDisplayName this.state.text:", this.state.text);
    if (text == null) {
      return null;
    }

    const editProps = {maxLength};

    let rieInputClassName = "rie-input ellipsis";
    rieInputClassName += isEditable ? " editable" : "";


          //beforeStart={this.handleBeforeStart}
          //afterFinish={this.handleAfterFinish}
        //ref={node => this.myCardHeader = node}>
    return(
      <Card.Header className={className}>
        <span className="name"
          ref={span => this.span = span}
        >
          <RIEInput value={this.state.text}
            isDisabled={!isEditable}
            change={this.handleChange}
            handleValidationFail={this.handleValidationFail.bind(this)}
            propName={propName}
            validate={validate}
            classInvalid="invalid"
            editProps={editProps}
            ref={(node)=>{this.node = node}}
            className={rieInputClassName}
            />
        </span>
        {props.children}
      </Card.Header>
    );
  };
}

export default MyCardDisplayName;
