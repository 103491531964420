import React from 'react';
//import {Responsive} from 'semantic-ui-react';
import {createMedia} from '@artsy/fresnel';
import {Menu, Sidebar} from 'semantic-ui-react';
//import Loader from 'react-loader-advanced';
//import ProgressBar from 'react-progress-bar-plus';
//import thisVariableNotUsed from 'react-progress-bar-plus/lib/progress-bar.css';
import {log, MyError} from 'concierge-common';
//import Headroom from 'react-headroom';
import Headroom from '../customized-modules/headroom';
import NavbarDesktop from '../containers/navbar-desktop';
import NavbarMobile from '../containers/navbar-mobile';
import MyLoader from '../containers/my-loader';
import MultiAction from '../containers/multi-action';
import Confirmation from '../containers/confirmation';
import PropsEditor from '../containers/props-editor';
import ShellContents from './shell-contents';
import Footer from './footer';
import SidebarContents from '../containers/sidebar-contents';

import UrlUtils from './utils/url-utils';

/*
function parseQuery(queryString) {
  var query = {};
  var pairs = (queryString[0] === '?' ?
    queryString.substr(1) : queryString).split('&');
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}
*/

// NOTE: React semantic UI Responsive component has been deprecated.
// Use @artsey/fresnel now.
// Copied from https://github.com/Semantic-Org/Semantic-UI-React/pull/4008
//
// NOTE: We need to define "mobile:0" even though it is not used in a
// Media component.  We use lessThan="desktop" to display the shell-mobile.
// But, if we do not give a setting for 0, then nothing is displayed
// when value is less than desktop size.
const AppMedia = createMedia({
  breakpoints: {
    //mobile: 320,
    mobile:0,  // See note above.
    //tablet: 768,
    //computer: 992,
    //largeScreen: 1200,
    //widescreen: 1920
    desktop: 1101, // This and above, shell-desktop.  Below this shell-mobile.
  }
});
const {Media, MediaContextProvider} = AppMedia;


class Shell extends React.Component {

  /**
   * Called when user clicks anywhere in the "shell contents"
   * that are to the right of the sidebar in order to hide
   * the Sidebar without clicking a menu item in the Sidebar
   * or the hamburger button.
   */
  handleClickPusher = () => {
    const {visible} = this.props.ui.sidebar;
    if (visible) this.props.cA_SetSidebarVisibility(false);
  };

  /**
   * Called when user clicks the "hamburger button" to show
   * or hide the Sidebar.  I.e. toggle the "sidebarVisible" state.
   */
  handleClickHamburgerButton = () => {
    const {visible} = this.props.ui.sidebar;
    this.props.cA_SetSidebarVisibility(!visible);
  }

  // Hack to get around pushable not extending to bottom of body.
  handleClickBelowPushable = (clickEvent) => {
    if ((clickEvent.srcElement && clickEvent.srcElement.tagName)
        == "BODY") {
      this.handleClickPusher();
    };
  }

  /**
   * Hack
   * Every time this.render() is called we call this function to add
   * or remove the "pushed" class from the headroom-wrapper div.
   * The pushed class determines whether the headroom component is
   * shifted over to the right to make room for the Sidebar.
   */
  updateHeadroomPosition(sidebarVisible) {
    // Push the headroom header over to the right to make room for
    // the Sidebar by changing the headroom-wrapper's margin-left.
    const headroom = document.querySelector(".headroom-wrapper");
    if (headroom == null) return;
    sidebarVisible ? headroom.classList.add("pushed") :
      headroom.classList.remove("pushed");
  }

  // Called by React when this Component has been loaded into the DOM.
  componentDidMount() {
    // Hack to get around pushable not extending to bottom of body.
    document.body.addEventListener("click", this.handleClickBelowPushable);
  }

  componentWillUnmount() {
    // Hack to get around pushable not extending to bottom of body.
    document.body.removeEventListener("click", this.handleClickBelowPushable);
  }

  render() {
    log.trace("Shell.render() this.props:", this.props);
    const props = this.props;
    let {ui, error, match, location} = props;
    const message = error && error.handle &&
      error.handle.report && error.msg;

    let messageType = "status";
    if (error) {
      switch(props.error.severity) {
        case MyError.ERROR:
          messageType = "error";
          break;
        case MyError.SUCCESS:
          messageType = "success";
          break;
        default:
          messageType = "status";
      }
    };

    const footerProps = {
      visible:ui.footer.visible,
      message,
      messageType,
      //hideTime:ui.footer.hideTime,
      cA_SetFooterVisibility:props.cA_SetFooterVisibility
    };

    log.trace("match:", match);
    //const shellContentsProps = this.getShellContentsFromMatch(match, location);
    const shellContentsProps = UrlUtils.getUrlParameters(match, location);
    log.trace("shellContentsProps:", shellContentsProps);
    const combinedProps = Object.assign({}, props, shellContentsProps);
    
    // We need to remove the state.ui property.  The loader/dimmer
    // is turned on/off and that is considered a change in the properties
    // for child components, which will cause them to redraw.
    // If the user is not signed in, then we will end up repeatedly
    // trying to get any users displayed in the child components.
    // E.g. an owner of an Act.
    //combinedProps.ui = undefined;
    delete combinedProps.ui;

    // Initialize props for SidebarContents.
    //const sidebarContentsProps = {
      //items:ui.sidebar.items,
      //cA_SetSidebarVisibility:props.cA_SetSidebarVisibility,
    //};
    const sidebarVisible = ui.sidebar.visible;
    this.updateHeadroomPosition(sidebarVisible);

    // Render shell-mobile or shell-desktop.
    // Both components are wrapped in a single MyLoader
    // component, so we don't need to put them in an array.

          //minWidth={Responsive.onlyTablet.minWidth}>
          //{...Responsive.onlyMobile}>

    let className = location && location.pathname &&
      (location.pathname.startsWith("/map") ||
       location.pathname.startsWith("/locationMap")) ?
       "map-layout" : "not-map-layout";
    className += ui.footer.visible ? " footer-show" : " footer-hide";

    //log.trace("Responsive.onlyMobile:", Responsive.onlyMobile);
    //log.trace("Responsive.onlyTablet:", Responsive.onlyTablet);
    //log.trace("Responsive:", Responsive);

    return (
      <MyLoader className={className}>
      
        {/* This is the MultiAction Modal popup dialog. */}
        <MultiAction />

        {/* This is the Confirmation Modal popup dialog. */}
        <Confirmation />

        {/* This is the PropsEditor Modal popup dialog. */}
        <PropsEditor />

        <MediaContextProvider>

          {/* Mobile Shell */}
          <Media lessThan="desktop">
          {/*<Media at="mobile">*/}
            <div className="shell-mobile">
              <Headroom>
                <NavbarMobile 
                  onClick={this.handleClickHamburgerButton} />
              </Headroom>

              <div className="shell-contents-mobile">
                <Sidebar as={Menu} animation='overlay'
                  width='thin' visible={sidebarVisible}
                  icon='labeled' vertical inverted>
                  {/*<SidebarContents {...sidebarContentsProps} />*/}
                  <SidebarContents/>
                </Sidebar>

                <Sidebar.Pushable as="div">
                  <Sidebar.Pusher dimmed={sidebarVisible}
                    onClick={this.handleClickPusher}>
                    <div className="shell-contents-holder">
                      <ShellContents {...combinedProps}
                        shellType="shell-mobile" />
                    </div> 
                  </Sidebar.Pusher>
                </Sidebar.Pushable>
              </div>
            </div>
          </Media>

          {/* Desktop Shell */}
          <Media greaterThanOrEqual="desktop">
            <div className="shell-desktop">
              <Headroom>
                <NavbarDesktop />
              </Headroom>
              <div className="shell-contents-and-footer-spacer-holder">
                <ShellContents {...combinedProps}
                  shellType="shell-desktop" />
              </div>
              <Footer {...footerProps} />
            </div>
          </Media>

        </MediaContextProvider>
      </MyLoader>
    );
  }
}

export default Shell;
