// This module defines the Confirmation *container*.
// NOT the Confirmation *component*.
import {connect} from 'react-redux';

// Import the ConfirmationComponent from which this
// ConfirmationContainer is created.
import ConfirmationComponent from '../components/confirmation';

import {cA_Confirmation} from '../actions/ui';

function mapStateToProps(state) {
  return({
    //ui:state.ui,
    confirmationProps:state.ui.confirmation,
  });
}

const mapDispatchToProps = {cA_Confirmation};
const ConfirmationContainer = connect(mapStateToProps, mapDispatchToProps)
  (ConfirmationComponent);
export default ConfirmationContainer;
