// This module defines the MyPopup *container*.
// NOT the MyPopup *component*.
import {connect} from 'react-redux';

// Import the MyPopupComponent from which this MyPopupContainer is created.
import MyPopupComponent from '../components/my-popup';

function mapStateToProps(state) {
  return({
    // Maybe change behavior based on curUser settings?
    //curUser:state.curUser,
    device:state.ui.device,
  });
}
const mapDispatchToProps = null;
const MyPopupContainer = connect(mapStateToProps, mapDispatchToProps)(MyPopupComponent);
export default MyPopupContainer;
