// This module defines the ObjPanel *container*.
// NOT the ObjPanel *component*.
import {connect} from 'react-redux';

// Import the ObjPanelComponent from which this ObjPanelContainer is created.
import ObjPanelComponent from '../components/obj-panel';

// Import action creators that this component will use.
import {cA_History} from '../actions/ui';
import {cA_GetObj} from '../client-db/api/obj-utils';

function mapStateToProps(state) {
  return({
    curUser:state.curUser,
    cache:state.cache,
  });
}
const mapDispatchToProps = {cA_History};
const ObjPanelContainer = connect(mapStateToProps, mapDispatchToProps)
  (ObjPanelComponent);
export default ObjPanelContainer;
