import {log} from 'concierge-common';
import * as ACTION_TYPES from '../actions/types';
import {cA_SetLoader} from '../actions/ui';


/**
 * This reducer manages the value of the application's store
 * state.auth0State property.
 *
 * @param auth0State The current store state.auth0State object.  We should
 * never modify this value.
 */
function auth0Reducer(auth0State = {isLoading:true}, action) {

  const {payload} = action;
  log.trace("auth0Reducer(), action:", action);
  log.trace("auth0Reducer(), payload:", payload);
  if (!payload) {
    // If payload is null/undefined, that means there was
    // no payload attached to this action.  Redux "interal"
    // action types such as "@@redux/INIT" don't have payloads,
    // and we should ignore Redux internal actions anyway.
    return auth0State;
  }

  if (payload.error) {
    // payload.error means that there was an error, so we
    // don't want to make any changes to the UI besides
    // canceling the loader/spinner.
    // We could just set ui.loader.visible = false right here,
    // but I don't think that is really the proper way to
    // do things.  E.g. what if some other reducer wants to
    // do something when the loader is fiddled with.
    //
    // TODO: Eventually write a proper handler for these
    // sorts of asyn actions that have three states:
    // request sent, request succeeded, request failed.
    //
    action.asyncDispatch(cA_SetLoader(false));
    return auth0State;
  }

  switch(action.type) {
    case ACTION_TYPES.SET_AUTH0_STATE_S:
      log.trace("auth0-reducer(), SET_AUTH0_STATE_S: ", payload.auth0State);
      return payload.auth0State;

    default:
      return auth0State;
  }
};

export default auth0Reducer;
