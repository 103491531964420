import React from 'react';
import {Menu, Icon} from 'semantic-ui-react';
import {log} from 'concierge-common';


class ActionHeader extends React.Component {

  handleClickBackButton() {
    this.props.cA_History("back");
  }

  createBackButton() {
    const backButton = 
      <Menu.Item className="back-button"
        onClick={this.handleClickBackButton.bind(this)}>
        {/*<Icon name="chevron left" />*/}
        <Icon name="angle left" />
        Back
      </Menu.Item>
    return backButton;
  }

  createActionsButton(multiAction) {
    const actionsButton =
      <Menu.Item className="actions-button" position="right"
        onClick={multiAction.dispatch}
      >
        <Icon name="ellipsis vertical" />
      </Menu.Item>
    return actionsButton;
  }

  render() {
    log.trace("ActionHeader.render() this.props:", this.props);
    const {props} = this;
    const {hasBackButton, title, multiAction} = props;

    const backButton = hasBackButton ? this.createBackButton() :
      undefined;
    const actionsButton = multiAction ?
      this.createActionsButton(multiAction) :
      undefined;

        //<MyProgressBar />
    return (
      <Menu className="action-header" inverted>
        {backButton}
        <Menu.Item className="title">{title}</Menu.Item>
        {actionsButton}
      </Menu>
    );
  }
}

export default ActionHeader;
