import {combineReducers} from 'redux';
import {reducer as notificationsReducer} from 'reapop';
import cacheReducer from './cache-reducer';
import usersReducer from './users-reducer';
//import users2Reducer from './users2-reducer';
import waysReducer from './ways-reducer';
import actsReducer from './acts-reducer';
import itnsReducer from './itns-reducer';
import anysReducer from './anys-reducer';
import usageReducer from './usage-reducer';
//import filteredReducer from './filtered-reducer';
import uiReducer from './ui-reducer';
import replicationReducer from './replication-reducer';
import auth0Reducer from './auth0-reducer';
import errorReducer from './error-reducer';
import curUserReducer from './cur-user-reducer';
import serviceWorkerReducer from './service-worker-reducer';
//import curUserObjReducer from './cur-user-obj-reducer';


/**
 * Here we create the "root reducer".  This is the reducer used
 * by the application to manage the parts of the application's
 * data/state "store".
 *
 * The "rootReducer" object created here "manages" the ONE AND
 * ONLY Redux "state" object for the ENTIRE Concierge UI
 * application.
 *
 * The Redux combineReducers() function that we call here turns an
 * object whose properties are a list of reducing functions into a
 * single reducing function we can pass to the Redux createStore()
 * function.
 *
 * Every time the application creates an action, (usually due to an
 * event generated by the user, e.g. clicking a button), Redux will
 * use the rootReducer to call EVERY child reducer regardless
 * of what action was created by our action creators.  The Redux
 * framework that calls the "list" of reducers gathers their results
 * into a single state object.  So, I think we could have multiple
 * reducers doing something for the same action, but the state
 * properties that would be set by the multiple reducers would be
 * different.
 *
 * The "shape" of the state object matches the keys of the passed
 * reducers.  So, the "users" property in the state object
 * is assigned the value returned by the usersReducer.
 *
 * If we had a redux form... The "form" property is assigned the value
 * returned by the reduxFormReducer.
 */
const rootReducer = combineReducers({

  // Notifications reducer must be mounted as `notifications`
  // Notice that it is a call to a function that returns the
  // actual notificationsReducer function we want.
  notifications: notificationsReducer(),

  // Set to null if there is no error to display to the user.
  // TODO: Define an Error object.  Right now it is just a String.
  //error:errorReducer,
  auth0State:auth0Reducer,
  curUser:curUserReducer,
  cache:cacheReducer,
  users:usersReducer,
  ways:waysReducer,
  acts:actsReducer,
  itns:itnsReducer,
  anys:anysReducer,
  usage:usageReducer,
  //filtered:filteredReducer,
  ui:uiReducer,
  replication:replicationReducer,
  error:errorReducer,
  swState:serviceWorkerReducer,

  // form: Redux form utility stores its data in this.  The
  // property must be named "form".
  //form:reduxFormReducer
});

export default rootReducer;
