import _ from 'lodash';
import React from 'react';
import {Label, Segment, Grid} from 'semantic-ui-react';
import Masonry from 'react-masonry-component';
import {log} from 'concierge-common';
import {PENDING, FLAGGED} from '../global-constants';
import ComponentUtils from './utils/component-utils';
import {strId} from '../utils/string-utils';


class MasonryLayout extends React.Component {

  // 5000 shows the bug consistently.
  // 0 Will not show the bug.
  static get TRANSITION_DURATION_MS() {return 500};

  /**
   * Hacky fix to make the react-stonecutter-layout relayout when the
   * image that is displayed does not exist and the UploadableImage
   * component changes the image.src to a "fallback" image.
   * We need to do the same monkey business as we do when we upload
   * a new image.  I.e. changing the key used for a card so the
   * stonecutter layout thinks the cards it contains have changed, so
   * it relays itself out.
   */
  /*
  imageErrorHandler(imageId) {
    log.trace("MasonryLayout.imageErrorHandler(), imageId:", imageId);
  }
  */

  handleImagesLoaded() {
    log.trace("MasonryLayout.handleImagesLoaded()");
  }
  handleLayoutComplete(laidOutItems) {
    log.trace("MasonryLayout.handleLayoutComplete(), laidOutItems:",
      laidOutItems);

    // Force children to rerender.  Doesn't help.
    //this.setState(this.state);
  }
  handleRemoveComplete(removedItems) {
    log.trace("MasonryLayout.handleRemoveComplete(), removedItems:",
      removedItems);
  }

  createCards() {
    const props = this.props;
    log.trace("MasonryLayout.createCards() props:", props);
    const {objects, selectable, onSelect, cardContentsProps} = props;

    //let key = 0;
    const cards = _.map(objects, obj => {
      log.trace("obj: ", obj);
      // If CardContents was passed in this.props, use it.
      // If it was not passed in, calculate the component to
      // use based on the obj's type.
      // E.g. Use a UserComponent to display a UserC obj.
      const CardContents = props.CardContents ? props.CardContents :
        ComponentUtils.getComponentForObj(obj);
      const key = strId(obj);
      //key++;

      const className = obj && (obj.statusAttribute == PENDING ||
        obj.statusAttribute == FLAGGED) ?
        "ui-card-container "+obj.statusAttribute :
        "ui-card-container";

      // NOTE: I don't use key={key} for anything.
      // It must be unique to make React happy.
      return(
        <div className={className} key={key}>
          <CardContents obj={obj} 
            selectable={selectable}
            onSelect={onSelect}
            {...cardContentsProps} />
        </div>
      )}
    );

    log.trace("MasonryLayout.render(), cards.length:", cards.length);
    return cards;
  }

  render() {
    const {emptyMessage, filterAndSortVisible} = this.props;

    // Options for the masonry-layout on which the
    // react-masonry-component is built.
    const masonryOptions = {
      // NOTE: ActionCarousel "resize" timeout must be greater.
      transitionDuration:MasonryLayout.TRANSITION_DURATION_MS,
      columnWidth:".ui-card-container",
      itemSelector:".ui-card-container",
      percentPosition:true,
    };

    const masonryProps = {
      className:"masonry-layout"+(filterAndSortVisible ? " faded" : ""),
      options:masonryOptions,

      // TODO: Any reason why we would want to set component="ul" and
      // make the card elements be "li" instead of "div"?
      elementType:"div",

      // default false
      disableImagesLoaded:false,

      // default false, and works only if disableImagesLoaded is false
      updateOnEachImageLoad:false,
      onImagesLoaded:this.handleImagesLoaded.bind(this),
      onLayoutComplete:this.handleLayoutComplete.bind(this),
      onRemoveComplete:this.handleRemoveComplete.bind(this),
    };

    // Create the list of cards displayed in the Masonry component.
    const cards = this.createCards();

    // Check whether we have any Waypoint cards to display.
    let component;
    if (cards && cards.length >= 1) {
      component = 
        <Masonry {...masonryProps}>
          {cards}
        </Masonry>
    }
    else {
      // createCards() returned an empty list, so display a message
      // instead of an empty Masonry component.
      component =
        <Segment padded inverted>
          <Grid centered>
            <Grid.Row>
              <Label className="text-align-left" basic size="huge">
                {emptyMessage}
              </Label>
            </Grid.Row>
          </Grid>
        </Segment>
    }

    return component;
  }
}

export default MasonryLayout;
