import _ from 'lodash';
import React from 'react';
import {Button, Card, Select} from 'semantic-ui-react';
import {log, MyError} from 'concierge-common';
import MyPopup from '../containers/my-popup';
import ApiUtils from '../client-db/api/api-utils';
//import ObjActions from './utils/obj-actions';
import {viewUsers} from './utils/obj-actions';


class Visibility extends React.Component {

  static get DEFAULT_OPTIONS() {return [
    {key:'isPublic', text:'Public',
     value:'isPublic', icon:'eye',
     description:'All attributes visible to everyone.'},
    {key:'isProtected',text:'Protected',
     value:'isProtected', icon:'protect',
     description:'Nothing visible to anyone except Fellow Travelers and your Circle.'},
    {key:'isPrivate',text:'Private',
     value:'isPrivate', icon:'hide',
     description:'Nothing visible to anyone except your Circle.'},
  ]};

  /*
  static get TOOLTIPS() {return {
    isPublic:"Public: Visible to everyone.",
    //isProtected:"Protected: Visible to everyone, but Parking/Location/Waypoints will only be visible to users you select.  (Selection of users not yet implemented.)",
    isProtected:"Protected: Visible to everyone, "+
      "but Parking/Location/Waypoints not visible.",
    isPrivate:"Private: Only visible to you."
  }};
  */

  /**
   */
  handleChange = (e, data) => {
    log.trace("handleChange(), data.value:", data.value);
    const {props} = this;
    const {cA_SetError} = props;
    let {options} = props;
    options = options || Visibility.DEFAULT_OPTIONS;
    const visibility = data.value;
    this.saveVisibility(visibility);

    const option = _.find(options, {value:visibility});
    log.trace("handleChange(), option:", option);
    if (option) {
      const msg = option.description;
      const props = {msg, severity:MyError.INFO};
      const error = MyError.createSubmitError(props);
      cA_SetError(error);
    }
  }

  saveVisibility(visibility) {
    const {props} = this;
    const {curUserObj, obj, collectionName} = props;
    if (!curUserObj) {
      return;
    }
    visibility = visibility ? visibility : "isPublic"; 
    const newProps = {
      visibility,
    };
    ApiUtils.editRxDBObj(curUserObj, obj._id, newProps, collectionName);
  }

  /**
   * Returns the value of this.props.visibility if that
   * value is in the list of this.props.options.
   * Fix the visibility value if it is not a legal value.
   * E.g. we expect "isPublic" or "isPrivate".  If the value
   * is not one of those, return the default legal value "isPublic".
   */ 
  getVisibilityFromProps() {
    const {cA_SetError} = this.props;
    let {obj, options} = this.props;
    let visibility = obj && obj.visibility;
    visibility = visibility ? visibility : "isPublic";

    options = options || Visibility.DEFAULT_OPTIONS;
    const defaultOption = options[0].value || "N/A";
    visibility = visibility || defaultOption;

    if (!_.find(options, (option)=>option.value == visibility)) {
      const msg = 'Illegal value of visibility "'+
        visibility+'".  Defaulting to "isPublic".';
      const props = {msg, severity:MyError.BUG};
      const error = MyError.createSubmitError(props);
      setTimeout(()=>cA_SetError(error), 1);
      visibility = defaultOption;
      debugger;
    }
    return visibility;
  }

  handleClickUsersButton = () => {
    const {curUserObj, obj, cA_History} = this.props;
    const action = viewUsers(obj, curUserObj);
    if (!action) {
      log.bug("In Visibility.handleClickUsersButton(), no action.");
      return;
    }
    cA_History("push", action.url);
  }

  render() {
    log.trace("Visibility.render() this.props:", this.props);
    const {props} = this;
    const {obj, disabled} = props;
    let {options, popupContent} = props;
    options = options || Visibility.DEFAULT_OPTIONS;
    const visibility = this.getVisibilityFromProps();

    // NOTE: We have to display upward when on mobile.
    // There is a problem with Sidebar (pusher/pushable)
    // clipping the dropdown if it is displayed at the bottom
    // of the browser.
    //
    // NOTE: Due to the way Select lays out the icon,
    // text (i.e. label), and description, we need to make sure
    // that all descriptions
    // are "long enough" to be displayed below the icon and text.
    // If they are not "long enough", then the text gets displayed
    // below the icon.
    // This is not an issue if the "fluid" property is not set.
    //
    // For whatever reason, Select puts the description BEFORE the
    // text/label in the option div, causing this problem.
    // TODO: Rewrite to use Dropdown directly, and create my own
    // Dropdown.Item objects?
        //defaultValue={visibility}
    const visibilitySelect = 
      <Select fluid upward options={options}
        disabled={disabled}
        value={visibility}
        onChange={this.handleChange} />

    /*
    popupContent = popupContent ||
      <div>
        <div>Public: Visible to everyone.</div>
        <div>Protected: Visible to Fellow Travelers and selected users.</div>
        <div>Private: Visible only to selected users.</div>
      </div>
    */

    let viewUsers = undefined;
    if (visibility != "isPublic" && obj.viewUsers) {
      const buttonLabel =
        (obj && Array.isArray(obj.userIds) &&
        obj.userIds.length > 0) ?
        "Edit Circle" :
        "Select Circle";
      const viewUsersButton =
        <Button onClick={this.handleClickUsersButton}>
          {buttonLabel}
        </Button>
      const content = visibility == "isProtected" ?
        "View/Remove the users who can see you.  "+
        "(Users in your list of Fellow Travelers also see you.)" :
        "View/Remove the only users who can see you.";
      viewUsers = <MyPopup trigger={viewUsersButton}
        content={content} />
    }
    return(
      <Card.Content className="visibility-select column">
        <MyPopup trigger={visibilitySelect}
          content={popupContent} />
        {viewUsers}
      </Card.Content>
    );
  };
}

export default Visibility;
