/**
 */
import React from 'react';
import {log} from 'concierge-common';
import {isEqualIds} from '../utils/string-utils';
import LocalDB from '../client-db/local-db';
import MyPopup from '../containers/my-popup';
import ObjUtils from '../client-db/api/obj-utils';
import {DEFAULT_USER_ID} from '../global-constants';
import ActionUtils from './utils/action-utils';


/**
 * Returns a multiAction that contains the avaible actions for this avatar.
 * E.g. Show the user's profile, send the user a message, upload a new
 * avatar image, upload a new profile image.
 */
class Avatar extends React.Component {

  handleClick(e, action) {
    log.trace("Avatar.handleClick(), action:", action);
    if (!action) {
      return;
    }
    if (e) {
      e.stopPropagation();
    }
    log.trace("Avatar.handleClick() calling action.dispatch()");
    action.dispatch();

    // Can't hurt to call this.  Only uncomment if needed.
    //cA_MultiAction(null);
  }
  /*
  handleClickAvatar(e) {
    log.trace("handleClickAvatar(), e:", e);
    if (e) {
      // Prevent the event from bubbling up to the avatar's parent
      // element, e.g. the UserLine that contains it.
      e.stopPropagation();
    }
    this.uploader.requestFile(e);

    // Can't hurt to call this.  Only uncomment if needed.
    //cA_MultiAction(null);
  }
  */

  /**
   * The user's avatar is an ordinary HTML span element with the
   * user's image used for the span element's background-image.
   *
   * TODO: Delete the use of ref in uploader.  We no longer use
   * uploader, and the ref was so we could call the requestFile
   * function.  Delete uploader too.
   */
  createAvatar(user, multiAction) {
    const style = {
      backgroundImage:"url("+user.avatarSrc+")",
    };

    let onClick;
    let className = "avatar";
    if (multiAction) {
      className += " has-action"
      //onClick = e => this.handleClickAvatar(e);
      onClick = e => this.handleClick(e, multiAction);
      //onClick = e => this.setState({requestFile:true});
    }

    const avatarProps = {
      className,
      style,
      onClick
    };

    //return(
    //  <span {...avatarProps}>
    //    {uploader}
    //  </span>
    //);
    return(
      <span {...avatarProps}></span>
    );
  }

  render() {
    log.trace("Avatar.render() this.props:", this.props);
    const props = this.props;
    //let {popupProps} = props;
    const {cache, curUser, user, noDrillDown} = props;
    log.trace("Avatar.render(), user:", user);
    log.trace("Avatar.render(), curUser:", curUser);

    // curUser is the user who is currently signed in who
    // is LOOKING at this avatar.  The curUser and the
    // user whose avatar this is may or may not be the
    // same user.
    //
    // user is the user whose avatar this is.

    if (!user) {
      return <div>No user</div>
    }
    
    //const multiAction = ActionUtils.calcUserMultiAction(
    //  this.props, curUser, user, "Avatar Actions", noDrillDown);

    const multiAction = ActionUtils.calcObjMultiAction(
      this.props, curUser, user, "Avatar Actions", noDrillDown);

    log.trace("Avatar.render(), multiAction:", multiAction);

    // Create the actual user avatar image.
    const avatar = this.createAvatar(user, multiAction);

    // Calculate the popup information.
    const reason = !curUser || !curUser.isSignedIn || !curUser.isSignedIn() ?
      "you are not signed in." :
      isEqualIds(user, DEFAULT_USER_ID) ?
        'this is the default "anonymous" user.' :
        "you do not have admininistrator priviledges";
    const actionsString = ActionUtils.getActionsString(multiAction);
    let content = actionsString ? actionsString :
      "This is the user's avatar.  You can't do anything with "+
      "it because "+reason;
    const popupProps = {
      content,
      trigger:avatar,
      //popupRule:MyPopup.ALWAYS,
    };

    return <MyPopup {...popupProps} />
  };
}

export default Avatar;
