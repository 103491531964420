// This module defines the MessagesPanel *container*.
// NOT the MessagesPanel *component*.
import {connect} from 'react-redux';

// Import the MessagesPanelComponent from which this
// MessagesPanelContainer is created.
import MessagesPanelComponent from '../components/messages-panel';
import {cA_History} from '../actions/ui';
import {cA_GetObj} from '../client-db/api/obj-utils';


function mapStateToProps(state) {

  const props = {
    curUser:state.curUser,
    cache:state.cache,
  };

  return props;
}

const mapDispatchToProps = {cA_History, cA_GetObj};
const MessagesPanelContainer = connect(mapStateToProps, mapDispatchToProps)
  (MessagesPanelComponent);
export default MessagesPanelContainer;
