/**
 * Create the Redux "store state".
 *
 * Note: I currently have redux-devtools-extension wired in.
 * Remove this for production.  To remove it, comment out the line
 * with the COMMENT_OUT comment above it, and uncomment the
 * lines with UNCOMMENT.
 */

import {createStore, applyMiddleware} from 'redux';

// Chrome extension Redux inspector, only during development.
import {composeWithDevTools} from 'redux-devtools-extension';

// We need the redux-promise package so we can set action payload
// to a Promise, and have Redux do the magic of waiting for the
// response and setting the value of the payload to the value returned
// by the Promise.
//import reduxPromiseMiddleware from 'redux-promise';

// TODO: Explain this.
//import reduxPromiseMiddleware from 'redux-promise-action-middleware';
//import reduxPromiseMiddleware from 'redux-promise-middleware';
import {createPromise} from 'redux-promise-middleware';

// The notifications module that we are using needs redux-thunk.
import reduxThunkMiddleware from 'redux-thunk';

//import createDebounce from 'redux-debounced';

import {log} from 'concierge-common';

// My own middleware that adds an "asyncDispatch" function to
// actions that pass through it.
import asyncDispatchMiddleware from './middleware/async-dispatch';

// This is my "root" reducer that combines all my reducers
// into a single reducer.
import rootReducer from './reducers/root-reducer';
import {SW_STATE_DEFAULT} from './reducers/service-worker-reducer';

// This is the "basic" way to create the app's "store" which
// holds the "state" property.  It does NOT insert any middleware
// into the event handling chain, so it does NOT have the ability
// to handle an action.payload that is "Promise".  It can only
// handle an action.payload that is an ordinary object.
//const createStoreWithMiddleware = applyMiddleware()(createStore);

// Here we do the same as above, but pass the reduxPromiseMiddleware
// into the reduxApplyMiddleware function which means that our
// action creators can now return a Promise as an action.payload property
// and the reduxPromiseMiddleware will listen for the Promise to resolve
// and then send the action.payload, (which now contains actual data),
// on to the reducer.
// We also insert another middleware that adds an "asyncDispatch" function
// to actions that pass through it.  This allows our actions to
// asynchronously dispatch additional actions.
// UNCOMMENT const createStoreWithMiddleware = applyMiddleware(reduxPromiseMiddleware,
// UNCOMMENT   asyncDispatchMiddleware)(createStore);

// Long form of above.
//const storeEnhancer = applyMiddleware(reduxPromiseMiddleware,
//  asyncDispatchMiddleware);
//const createStoreWithMiddleware = storeEnhancer(createStore);

import UI from './utils/ui-state';
//import {CurrentUser as CurUser} from 'concierge-common';
import * as ACTION_TYPES from './actions/types';

/*
// Initialize the browser's localStorage so we can save/restore
// the curUser's _id and token to allow the user to stay logged
// in between browser sessions.
if (localStorage) {
  localStorage.name = "conciergeLocalStorage";
}
else {
  log.info("Your browser does not support localeStorage.");
}

// TODO: This is also used in auth0-provider.  Put in utility.
function getCurUserFromLocalStorage() {
  let curUser = {_id:null, token:null};
  if (!localStorage) {
    log.info("Your browser does not support localeStorage.");
    return curUser;
  }

  try {
    const curUserJSON = localStorage.getItem("curUser");
    log.trace("localStorage curUserJSON:", curUserJSON);
    curUser = JSON.parse(curUserJSON);
  }
  catch(err) {
  }
  log.info("In store.js, localStorage curUser:", curUser);
  return curUser;
}

// Get the curUser value out of the browser's localStorage,
// if it was saved there during a previous session.
let curUser = getCurUserFromLocalStorage();
if (curUser && curUser._id && curUser.token) {
  log.info("Get the saved curUser:", curUser);
  // NOTE: This seems a bit of a hack.  Maybe we should
  // instead send something that creates a SIGN_IN_S action
  // that the users-reducer.js case will see and put a
  // UserC object into the store state?
  //setTimeout(()=>
  //UserUtils.get(curUser, curUser._id), 1);
}
else if (curUser == null) {
  curUser = {_id:null, token:null};
}
//curUser = curUser && curUser.token ? curUser : new CurUser(curUser);
log.trace("curUser:", curUser);
curUser = curUser && curUser.token ? curUser : new UserC(curUser);
log.trace("After construction curUser:", curUser);

// Initialize the Navbar to the signed in version of if we
// were able to extract a curUser.token from the browser's
// local storage.
let uiProps = {};
if (curUser.token) {
  uiProps.navbar = {
    leftItems:UI.LEFT_ITEMS_SIGNED_IN,
    rightItems:UI.RIGHT_ITEMS_SIGNED_IN,
  };
  uiProps.sidebar = {
    visible:false,
    items:UI.LEFT_ITEMS_SIGNED_IN,
  };
}
*/
let uiProps = {};

// The initial values of the store's "state" property.
const INITIAL_STATE = {
  swState:Object.assign({}, SW_STATE_DEFAULT),
  auth0State:{
    auth0Client:null,
    isLoading:true,
    isAuthenticated:false,
    userAuth0:null,
    userRxDB:null,
    userC:null,
    idTokenClaims:null,
  },
  ui:new UI(uiProps),
  replication:{
    completed:false,
  },
  cache:{
    //objs:{},
    objs:new Map(),
  },
  users:{
    //debugName:"users", // TODO: delete this.
    collectionName:"users",
    //objs:[],  // TODO: delete this.
    objIds:[],
    //nextSkip:0, // Delete this.
    //totalCount:undefined, // Delete this.

    // At startup, findParam and sortParam are overwritten by
    // what is in src/utils/ui-state.js UI.filterAndSort
    // NOTE: Do we need/want to save these values here?
    // They are a version of the ones in the
    // store.state.ui.filterAndSort
    findParam:{ 
      selector:{
        //created:{$exists:true},
        created:{$gte:null},
      },
    },
    sortParam:{"created":"desc"},
  },
  ways:{
    collectionName:"ways",
    //debugName:"ways",
    objIds:[],
    findParam:{ 
      selector:{
        //created:{$exists:true},
        created:{$gte:null},
      },
    },
    sortParam:{"created":"desc"},
  },
  acts:{
    collectionName:"acts",
    //debugName:"acts",
    objIds:[],
    findParam:{ 
      selector:{
        //created:{$exists:true},
        created:{$gte:null},
      },
    },
    //sortParam:{"expires":"desc"},
    sortParam:{"created":"desc"},
  },
  itns:{
    collectionName:"itns",
    objIds:[],
    findParam:{ 
      selector:{
        //created:{$exists:true},
        created:{$gte:null},
      },
    },
    //sortParam:{"expires":"desc"},
    sortParam:{"created":"desc"},
  },

  // Heterogenious list of objects that will be displayed
  // in the AnysPanel.  For example a user's access.userIds
  // list, or user's favorites list.
  // I.e. "any" type of object:  User/Way/Act/Itn.
  //
  // NOTE: This is not currently used.
  anys:{
    title:"Page Title",
    objIds:[],
  },

  // Heterogenious list of objects that will be displayed
  // in the UsedByPanel.  For example, "usage" list.
  // I.e. "any" type of object:  User/Way/Act/Itn.
  //
  // Each "property" in a usage[collectionName]
  // object uses the object ID as the property name and
  // the property value is an object containing information
  // about hw the object was used.
  usage:{
    /*
    title:"Page Title",
    usedAs:"asChild",
    usedBy:{
      users:{
      },
      ways:{
      },
      acts:{
        ["o7owduevqn"]:{
          asChild:true
        }
      },
      itns:{
      },
    },
    */
  },

  curUser:{_id:null, token:null},

  error:null,

  // Currently displayed list of ways/acts/itns/users that
  // is filtered based on editability/etc.
  // Later, we might want this to be an object that
  // contains multiple lists.
  /*
  filtered:{
    debugName:"filtered",
    objs:[],
    nextSkip:0,
    totalCount:undefined,
  },
  */

  // notificaitons module reducer requires that this
  // property be called "notifications".
  notifications:[]
};

// This is the value that will be passed to our app's outer
// <Provider> store attribute.  E.g. <Provider store={store}>
// UNCOMMENT const store=createStoreWithMiddleware(rootReducer, INITIAL_STATE)

// Insert the Chrome extension Redux devtool hook.
// COMMENT_OUT for production.
/*
const store = createStore(rootReducer, INITIAL_STATE, composeWithDevTools(
  applyMiddleware(reduxThunkMiddleware,
    reduxPromiseMiddleware({promiseTypeSuffixes: ['P', 'S', 'F']}
  ), asyncDispatchMiddleware)
));
*/
/*
const store = createStore(rootReducer, INITIAL_STATE, composeWithDevTools(
  applyMiddleware(
    //createDebounce(),
    reduxThunkMiddleware,
    reduxPromiseMiddleware({promiseTypeSuffixes: ['P', 'S', 'F']}
    ),
    asyncDispatchMiddleware
  )
));
*/
// Upgrade to Redux 6.0 and later.  Use createPromise().
const store = createStore(rootReducer, INITIAL_STATE, composeWithDevTools(
  applyMiddleware(
    reduxThunkMiddleware,
    createPromise({promiseTypeSuffixes: ['P', 'S', 'F']}),
    asyncDispatchMiddleware
  )
));


/*
// ************
log.trace("curUser: ", curUser);
if (curUser && curUser._id && curUser.token) {
  // Send a SIGN_IN_S action that will cause the users-reducer
  // to put the curUserObj object into the store's state.users
  // property.
  let curUserObj = curUser;
  if (curUserObj.constructor && curUserObj.constructor.name === "User") {
    // Do nothing to it.  It is already a UserC object.
  }
  else {
    curUserObj = new UserC(curUserObj);
  }
  log.trace("curUserObj: ", curUserObj);
  const payload = {
    user:curUserObj,
    //users:[curUserObj],  // Don't think this is necessary.
  };
  store.dispatch({type:ACTION_TYPES.SIGN_IN_S, payload});
}
// ************
*/

export default store;
export {INITIAL_STATE};

