import React from 'react';
import NavbarUtils from './utils/navbar-utils';
import {log} from 'concierge-common';

/**
 * Note, the Sidebar that contains us is displayed as a Menu
 * component.  I.e. it has the "as={Menu}" property.  This
 * allows SidebarContents.render() to simply return an array
 * of Menu.Item components that go inside Sidebar.
 */
class SidebarContents extends React.Component {

  hideSidebar = () => {
    const {cA_SetSidebarVisibility} = this.props;
    cA_SetSidebarVisibility(false);
  };

  render() {
    log.trace("SidebarContents.render(), props:", this.props);
    const {curUser, sidebar} = this.props;
    const items = NavbarUtils.getMenuItems(curUser, sidebar.items/*, props*/);
    const menuItems = NavbarUtils.createMenuItems(curUser, items,
      this.hideSidebar);

    // React16 can handle render() returning an array of
    // elements.
    return menuItems;
  }
};

export default SidebarContents;
