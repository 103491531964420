/**
 * This is used to display a single Way/Act/Itn component in the shell.
 * E.g. When a user is editing a new Way/Act/Itn, or viewing an
 * existing one.
 */
import _ from 'lodash';
import React from 'react';
import {log} from 'concierge-common';
import {DEFAULT_USER_ID} from '../global-constants';
import Obj from '../client-db/api/obj';
import ObjUtils from '../client-db/api/obj-utils';
import ObjComponent from '../containers/obj';
import MyHeader from '../containers/my-header';


class ObjPanel extends React.Component {

  createHeader(obj) {
    const {cA_History} = this.props;
    let {title} = this.props;
    title = title ? title :
      obj ? obj.displayName : "Selected Object";

    const hasBackButton = true;
    const myHeaderProps = {
      hasBackButton,
      title,
      //rightButton,
      cA_History,
    };

    return <MyHeader {...myHeaderProps} />
  }

  static createStateFromProps(props) {
    const {curUser, objId, parentId, cache, cA_GetObj} = props;
    let obj;
    if (objId == null) {
      // NOTE: This is a bit of a hack.  We assume if objId is
      // null, we are getting the "default" user during
      // initialization.
      obj = ObjUtils.get(curUser, DEFAULT_USER_ID,
        undefined, cache, cA_GetObj);
    }
    else {
      obj = ObjUtils.get(curUser, objId, undefined,
        cache, cA_GetObj);
    }
    let parentObj = undefined;
    if (parentId) {
      parentObj = ObjUtils.get(curUser, parentId, undefined,
        cache, cA_GetObj);
    }
    const state = {obj, parentObj};
    return state;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const newState = ObjPanel.createStateFromProps(nextProps);
    
    if (_.isEqual(prevState.obj, newState.obj)) {
      delete newState.obj;
    }
    if (_.isEqual(prevState.parentObj, newState.parentObj)) {
      delete newState.parentObj;
    }
    if (_.size(newState)) {
      return newState;
    }
    else {
      // Nothing added to newState.
      return null;
    }
  }

  constructor(props) {
    super(props);
    /*
    const {objId} = props;
    const collectionName = Obj.getCnFromId(objId);

    const obj = Obj.DEFAULT(collectionName);
    this.state = {
      obj,
    };
    */
    this.state = ObjPanel.createStateFromProps(props);
  }

  render() {
    log.trace("ObjPanel.render(), this.props:", this.props);
    const {props, state} = this;
    const {noDrillDown} = props;
    const {obj, parentObj} = state;
    const header = this.createHeader(obj);

    return (
      <div>
        {header}
        <div className="card-panel">
          <div>
            <ObjComponent className="centered" noDrillDown={noDrillDown}
              obj={obj} parentObj={parentObj} {...props} />
          </div>
        </div>
      </div>
    );
  }
}

export default ObjPanel;
