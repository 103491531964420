// This module defines the App *container*.
// NOT the App *component*.

import {connect} from 'react-redux';

// Import the AppComponent from which this AppContainer is created.
import AppComponent from '../components/app';

// Import action creators that this component will use.
import {cA_Notify} from '../actions/error';
import {cA_SetDevice} from '../actions/ui';
import {cA_MultiAction} from '../actions/ui';
import {cA_SetFilterAndSortVisibility} from '../actions/ui';

// Tell Redux to add the store state.app property to this
// App container's list of props so they will be available
// in the App component's render() method.
function mapStateToProps(state) {
  return({
    curUser:state.curUser,
    // This property MUST be called "notificaitons" according
    // to the notifications module documentation.
    notifications:state.notifications,
    dbState:state.ui.dbState,
  });
}

// Add these action creators to this App container's list of props
// so they will be available in the App component's render() method.
const mapDispatchToProps = {cA_Notify, cA_SetDevice, cA_MultiAction,
  cA_SetFilterAndSortVisibility};

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(AppComponent);
export default AppContainer;
