import _ from 'lodash';
import {log} from 'concierge-common';
import * as ACTION_TYPES from '../actions/types';
import {cA_Confirmation} from '../actions/ui';
import {cA_SetError} from '../actions/error';
import {cA_Notify} from '../actions/error';
import {cA_SwPossiblyActivateUpdate} from '../actions/service-worker';
import * as serviceWorker from '../serviceWorker';
import {SW_VERSION} from '../global-constants';


// NOTE: We can probably get rid of askUserToConfirmUpdate because
// I have never setting that to false right now.  See where it
// is commented out in SW_CHECK_FOR_UPDATE_S case.
//
const SW_STATE_DEFAULT = {
  //swInstalled:false,
  //swUpdateFound:false,
  swRegistration:null,
  askUserToConfirmUpdate:true,
  checkingForUpdate:false,  // Make "Check For Update" button busy.
};

/*
const SW_ACTIONS = [
  ACTION_TYPES.SW_SET_REGISTERED,
  ACTION_TYPES.SW_SET_INSTALLED,
  ACTION_TYPES.SW_CHECK_FOR_UPDATE,
  ACTION_TYPES.SW_CHECK_FOR_UPDATE_P,
  ACTION_TYPES.SW_CHECK_FOR_UPDATE_S,
  ACTION_TYPES.SW_CHECK_FOR_UPDATE_F,
  ACTION_TYPES.SW_POSSIBLY_ACTIVATE_UPDATE,
];
*/

function serviceWorkerReducer(swState = SW_STATE_DEFAULT, action) {

  /*
  if (!_.includes(SW_ACTIONS, action.type)) {
    // We don't care about this action.type
    return swState;
  }
  */

  const {payload = {}} = action;
  const {swRegistration} = payload;
  const newSwState = Object.assign({}, swState);

  /*
  if (payload.error) {
    action.asyncDispatch(cA_SetError(error));
    newSwState.checkingForUpdate = false;
    return newSwState;
  }
  else if (action.type != ACTION_TYPES.SW_CHECK_FOR_UPDATE_P) {
    // We don't care if swRegistration is null.
  }
  else if (!swRegistration) {
    log.bug("In serviceWorkerReducer(), swRegistration: ", swRegistration);
    const notification = {
      title:"Error/Bug In serviceWorkerReducer",
      message:"In serviceWorkerReducer(), swRegistration: "+swRegistration,
      status:"error",
      dismissAfter:15000,
    };
    action.asyncDispatch(cA_Notify(notification));
    newSwState.checkingForUpdate = false;
    return newSwState;
  }
  */

  switch(action.type) {

    case ACTION_TYPES.SW_SET_REGISTERED:
      // NOTE: I don't think this action really helps anything.
      // Before we do anything to update our serviceWorker, the
      // SW_SET_INSTALLED action is called.
      // Or maybe not?  If the serviceWorker was already installed?
      log.trace("In serviceWorkerReducer SW_SET_REGISTERED, swState: ",
        swState);
      log.trace("In serviceWorkerReducer SW_SET_REGISTERED, payload: ",
        payload);
      log.trace("In serviceWorkerReducer SW_SET_REGISTERED, swRegistration: ",
        swRegistration);
      //newSwState.swInstalled = false;
      newSwState.swRegistration = swRegistration;
      newSwState.askUserToConfirmUpdate = true;
      newSwState.checkingForUpdate = false;
      log.trace("In serviceWorkerReducer SW_SET_REGISTERED, newSwState: ",
        newSwState);
      return newSwState;

    case ACTION_TYPES.SW_SET_INSTALLED:
      log.trace("In serviceWorkerReducer SW_SET_INSTALLED, swState: ", swState);
      log.trace("In serviceWorkerReducer SW_SET_INSTALLED, swRegistration: ",
        swRegistration);
      //newSwState.swInstalled = true;
      newSwState.swRegistration = swRegistration;
      newSwState.askUserToConfirmUpdate = true;
      newSwState.checkingForUpdate = false;
      log.trace("In serviceWorkerReducer SW_SET_INSTALLED, newSwState: ",
        newSwState);
      return newSwState;

    case ACTION_TYPES.SW_CHECK_FOR_UPDATE:
      // We should never get this action.type.
      return swState;

    case ACTION_TYPES.SW_CHECK_FOR_UPDATE_P:
      log.trace("In serviceWorkerReducer SW_CHECK_FOR_UPDATE_P, swState: ",
        swState);
      // Disable the "Check For Update" button while we
      // await the async update() call.
      newSwState.checkingForUpdate = true;
      return newSwState;

    case ACTION_TYPES.SW_CHECK_FOR_UPDATE_S:
      log.trace("In serviceWorkerReducer SW_CHECK_FOR_UPDATE_S, swState: ",
        swState);
      // Reenable the "Check For Update" button now that
      // the call to update() has returned.
      newSwState.checkingForUpdate = false;
      newSwState.swRegistration = swRegistration;
      if (payload.error) {
        action.asyncDispatch(cA_SetError(payload.error));
        return newSwState;
      }

      // Might want to do this.
      // Currently, we never set this flag to false, so this
      // functionality can be removed.
      //newSwState.askUserToConfirmUpdate = false;

      // NOTE: If there is an update, other event handling code
      // will install the update and reload the application
      // possibly before this Promise returns.
      if (swRegistration.installing) {
        //alert("Current version: "+SW_VERSION);
        const notification = {
          title:"Downloading Update",
          message:"Current version is: "+SW_VERSION+
            ".  A new version is downloading now.",
          status:"info",
          dismissAfter:15000,
        };
        action.asyncDispatch(cA_Notify(notification));
        // New version is available.  Other code that is
        // listening for serviceWorker.onUpdateFound event
        // will handle it, so we don't need to do anything here.
      }
      else if (swRegistration.waiting) {
        // There is not a "new" update, but an existing already
        // downloaded update is "waiting" to be installed.
        // For example, we are running version 1, downloaded
        // version 2 yesterday, and have just now checked if there
        // is a "new" version, which would be version 3.  There
        // is no version 3, but version 2 is still "waiting" to
        // be installed.  So, send a SKIP_WAITING message or
        // reload the page.
        action.asyncDispatch(cA_Confirmation(
          "Latest Version Waiting To Install",
          'No "new" version is available, but the latest version was '+
          'already downloaded and is waiting to be installed.',
          'You are running version: '+SW_VERSION,
          () => action.asyncDispatch(cA_SwPossiblyActivateUpdate(swRegistration)),
          "Ok",
          null, // cancelAction
          null, // cancelLabel
          0, // delaySeconds
          true, // doubleOkConfirmation
          ));
      }
      else if (swRegistration.active) {
        const notification = {
          title:"No New Update",
          message:"You are already running the most recent version: "+
            SW_VERSION,
          status:"success",
          dismissAfter:5000,
        };
        action.asyncDispatch(cA_Notify(notification));
      }
      else {
        const notification = {
          title:"Check For Update Error",
          message:"Current version: "+SW_VERSION,
          status:"error",
          dismissAfter:15000,
        };
        action.asyncDispatch(cA_Notify(notification));
      }

      log.trace("In serviceWorkerReducer SW_CHECK_FOR_UPDATE, newSwState: ",
        newSwState);
      return newSwState;

    case ACTION_TYPES.SW_CHECK_FOR_UPDATE_F:
      // Reenable the "Check For Update" button now that
      // the call to update() has returned.
      newSwState.checkingForUpdate = false;
      if (payload.error) {
        action.asyncDispatch(cA_SetError(payload.error));
      }
      else {
        log.bug("Some sort of error while checking for update.");
        const notification = {
          title:"Check For Update Failed",
          message:"Some sort of error while checking for update.",
          status:"error",
          dismissAfter:15000,
        };
        action.asyncDispatch(cA_Notify(notification));
      }
      return newSwState;

    case ACTION_TYPES.SW_POSSIBLY_ACTIVATE_UPDATE:
      // A service worker update was found, so install it
      // after asking (or informing) the user.
      log.trace("In serviceWorkerReducer SW_POSSIBLY_ACTIVATE_UPDATE, swState: ",
        swState);
      //newSwState.swUpdateFound = true;
      newSwState.swRegistration = swRegistration;

      // This shouldn't be needed here, but just in case the
      // check for update code throws an exception somewhere,
      // set this to false.
      // newSwState.checkingForUpdate = false;

      if (swState.askUserToConfirmUpdate) {
        newSwState.askUserToConfirmUpdate = true;
        const okButtonProps = {negative:false, primary:true, inverted:false};
        // TODO: Instead of calling this directly, display a
        // confirmation dialog/alert to the user letting
        // him/her decide whether to update now or later.
        //serviceWorker.updateServiceWorker(swRegistration);

        // Display confirmation dialog letting the user decide
        // whether to update now or later.
        //
        // TODO: If user chooses "Update Later", tell user that
        // the update will happen after the user closes all browser
        // tabs that have the app.  What about desktop version?
        action.asyncDispatch(cA_Confirmation("Update Available",
          'The latest version of Jasiri has been downloaded.  '+
          'To install the update now, click the "Install Now" button.',
          'Click "Install Later" in order to continue using '+
          'version: '+SW_VERSION+'.  The update will be installed '+
          'next time you sign in.',
          () => serviceWorker.updateServiceWorker(swRegistration),
          "Install Now",
          null, // cancelAction
          "Install Later", // cancelLabel
          0, // delaySeconds
          true, // doubleOkConfirmation
          false, // doubleCancelConfirmation
          null, // okButton
          null, // cancelButton
          okButtonProps,
          ));
      }
      else {
        // Inform the user we are going to update the app.
        action.asyncDispatch(cA_Confirmation("Jasiri Will Be Updated",
          'The latest version of Jasiri will be installed now.'));
         serviceWorker.updateServiceWorker(swRegistration);
      }
      log.trace("In serviceWorkerReducer SW_POSSIBLY_ACTIVATE_UPDATE, newSwState: ",
        newSwState);
      return newSwState;

    //case ACTION_TYPES.SW_SET_CHECKING_FOR_UPDATE:
    //  newSwState.checkingForUpdate = action.payload;
    //  return newSwState;

    default:
      return swState;
  }
}

export {SW_STATE_DEFAULT};
export default serviceWorkerReducer;
