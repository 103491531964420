import _ from 'lodash';
import {isRxDocument} from 'rxdb';
import {log, ImageUtils} from 'concierge-common';
import {ConciergeRestApi} from 'concierge-common';
import {USERS, WAYS, ACTS, ITNS} from '../../global-constants';
import {PENDING, EXPIRES_NEVER} from '../../global-constants';
import {DEFAULT_CREATED, DEFAULT_USER_ID} from '../../global-constants';
import {PRIVATE_ID} from '../../global-constants';
import {REDACTED} from '../../global-constants';
import {strId, isEqualIds} from '../../utils/string-utils';
import ObjUtils from './obj-utils';
import RedactUtils from './redact-utils';


class Obj {

  static DEFAULT_IDS = {
    [USERS]:"users_defaultUserId",
    [WAYS]:"ways_defaultWayId",
    [ACTS]:"acts_defaultActId",
    [ITNS]:"itns_defaultItnId",
  };
  static PRIVATE_IDS = {
    [USERS]:"users_privateUserId",
    [WAYS]:"ways_privateWayId",
    [ACTS]:"acts_privateActId",
    [ITNS]:"itns_privateItnId",
  };
  static DISPLAY_NAMES = {
    [USERS]:"User",
    [WAYS]:"Waypoint",
    [ACTS]:"Adventure",
    [ITNS]:"Itinerary",
  };
  static DISPLAY_NAME_PLURALS = {
    [USERS]:"Users",
    [WAYS]:"Waypoints",
    [ACTS]:"Adventures",
    [ITNS]:"Itineraries",
  };
  static PLACEHOLDER_IMAGES = {
    [USERS]:ImageUtils.PLACEHOLDER_USER_IMAGE,
    [WAYS]:ImageUtils.PLACEHOLDER_WAY_IMAGE,
    [ACTS]:ImageUtils.PLACEHOLDER_ACT_IMAGE,
    [ITNS]:ImageUtils.PLACEHOLDER_ITN_IMAGE,
  };
  static PLACEHOLDER_PRIVATE_IMAGES = {
    [USERS]:ImageUtils.PLACEHOLDER_USER_PRIVATE_IMAGE,
    [WAYS]:ImageUtils.PLACEHOLDER_WAY_PRIVATE_IMAGE,
    [ACTS]:ImageUtils.PLACEHOLDER_ACT_PRIVATE_IMAGE,
    [ITNS]:ImageUtils.PLACEHOLDER_ITN_PRIVATE_IMAGE,
  };

  static get DISPLAY_NAME_MIN_LENGTH() {return 2};
  static get DISPLAY_NAME_MAX_LENGTH() {return 40};
  static get DETAILS_MIN_LENGTH() {return 4};
  static get DETAILS_MAX_LENGTH() {return 500};

  static DEFAULT_ID(cn) {return Obj.DEFAULT_IDS[cn]};
  static PRIVATE_ID(cn) {return Obj.PRIVATE_IDS[cn]};
  static DISPLAY_NAME(cn) {return Obj.DISPLAY_NAMES[cn]};
  static DISPLAY_NAME_PLURAL(cn)
    {return Obj.DISPLAY_NAME_PLURALS[cn]};
  static PLACEHOLDER_IMAGE(cn) {return Obj.PLACEHOLDER_IMAGES[cn]};
  static PLACEHOLDER_PRIVATE_IMAGE(cn)
    {return Obj.PLACEHOLDER_PRIVATE_IMAGES[cn]};

  static isDefaultObj(obj) {
    return obj ? obj._id == Obj.DEFAULT_ID(obj.cn) : false;
  };

  static DEFAULT_PROPS(cn) {
    const props = {
      _id:cn+"_",
      displayName:"Unknown Object",
      imageSrc:undefined,
      avatarSrc:undefined,
      isScenic:undefined,
      details:"No details for this unknown object.",
      creator:DEFAULT_USER_ID,
      owner:undefined,
      created:DEFAULT_CREATED,
      locationPoint:undefined,
      expires:EXPIRES_NEVER,
      //attributes:["approved"],
      statusAttribute:PENDING,
      visibility:"isPublic",
      userIds:[],  // Used if visibility is not isPublic.
    };
    if (!cn) {
      log.bug("No cn passed to DEFAULT_PROPS(cn)");
      debugger;
      return props;
    }
    //props._cn = cn;

    switch(cn) {
      case USERS:
        //props.isScenic = true; ???
        break;
      case WAYS:
        // A Way can go in an Act.children list.
        props.parentCn = ACTS;
        // By default, Waypoints are NOT scenic.  They
        // are assumed to be "navigational".
        props.isScenic = false;
        break;
      case ACTS:
        props.children = [];
        // An Act.children list contains Way objects.
        props.childrenCn = WAYS;
        // An Act can go in an Itn.children list.
        props.parentCn = ITNS;
        props.isScenic = true;
        break;
      case ITNS:
        props.children = [];
        // An Itn.children list contains Act objects.
        props.childrenCn = ACTS;
        props.isScenic = true;
        break;
      default:
        log.bug("In Obj.DEFAULT_PROPS(), unhandled cn: ", cn);
        debugger;
    }

    switch(cn) {
      case USERS:
      case WAYS:
      case ACTS:
      case ITNS:
        props._id = Obj.DEFAULT_ID(cn);
        props.displayName = "Unknown "+Obj.DISPLAY_NAME(cn);
        props.imageSrc = Obj.PLACEHOLDER_IMAGE(cn);
        props.details = "No details for this unknown "+Obj.DISPLAY_NAME(cn);
        return props;
      default:
        log.bug("In Obj.DEFAULT_PROPS(), unhandled cn: ", cn);
        debugger;
    }
  };

  static _DEFAULT_OBJS = {};
  static DEFAULT(cn) {
    if (Obj._DEFAULT_OBJS[cn] == null) {
      Obj._DEFAULT_OBJS[cn] = new Obj(Obj.DEFAULT_PROPS(cn));
    }
    return Obj._DEFAULT_OBJS[cn];
  }

  static _PRIVATE_OBJS = {};
  static PRIVATE(cn) {
    if (Obj._PRIVATE_OBJS[cn] == null) {
      const props = _.clone(Obj.DEFAULT_PROPS(cn));
      props._id = Obj.PRIVATE_ID(cn);
      props.displayName = "Private "+Obj.DISPLAY_NAME(cn);
      props.details =
        'The owner of this '+Obj.DISPLAY_NAME(cn)+
        ' has set its visibility '+
        'to "Private".  It is not publicly visible.';
      props.imageSrc = Obj.PLACEHOLDER_PRIVATE_IMAGE(cn);
      props.visibility = "isPrivate";
      Obj._PRIVATE_OBJS[cn] = new Obj(props);
    }
    return Obj._PRIVATE_OBJS[cn];
  }

  static getCnFromId(id) {
    if (!id) {
      debugger;
      return "error";
    }
    if (id.startsWith("auth0")) {
      // Temp hack while old data floating around.
      id = USERS+"_"+id;
      log.todo("Old user objects in DB with ids that start with 'auth0' instead of 'users_auth0'.");
    }
    const collectionName = id.substring(0, id.indexOf("_"));
    return collectionName;
  }

  // UPDATE_THIS_WHEN_ADDING_PROPS
  // Keep in sync with ObjC and UserC constructors.
  // Keeyp in sync with ApiUtils.objRxDBToObjC()
  static rxdbPropsToObjCProps(rxdbProps, collectionName) {
    const objCProps = _.cloneDeep(Obj.DEFAULT_PROPS(collectionName));
    log.trace("Cloned objCProps:", objCProps);
    objCProps._id = rxdbProps._id;
    objCProps.displayName = rxdbProps.displayName;
    objCProps.imageSrc = rxdbProps.imageSrc;
    objCProps.avatarSrc = rxdbProps.avatarSrc;
    objCProps.isScenic = rxdbProps.isScenic;
    objCProps.details = rxdbProps.details;
    objCProps.creator = rxdbProps.creator;
    objCProps.owner = rxdbProps.owner;
    objCProps.created = rxdbProps.created;
    objCProps.locationPoint = rxdbProps.locationPoint;
    objCProps.children = rxdbProps.children;
    objCProps.parkWaypoint = rxdbProps.parkWaypoint;
    objCProps.expires = rxdbProps.expires;
    objCProps.urls = rxdbProps.urls;
    objCProps.isTestData = rxdbProps.isTestData;
    objCProps.statusAttribute = rxdbProps.statusAttribute;
    objCProps.visibility = rxdbProps.visibility;
    objCProps.userIds = rxdbProps.userIds;
    log.trace("Final objCProps:", objCProps);
    return objCProps;
  }

  isInUserIds(userId) {
    if (!this.userIds ||
        !Array.isArray(this.userIds)) {
      return false;
    }
    return _.includes(this.userIds, userId);
  }

  get cn() {
    //const collectionName = this._id.substring(0, this._id.indexOf("_"));
    //return collectionName ? collectionName : this._cn;
    return Obj.getCnFromId(this._id);
  };

  getChildrenImageSrcs(curUserObj, cache, cA_GetObj, showChildren) {
    let childrenImageSrcs = [];
    if (!this.children || !Array.isArray(this.children)) {
      return childrenImageSrcs;
    }

    this.children.forEach(childId => {
      log.trace("childId: ", childId)
      const child = ObjUtils.get(curUserObj, childId, undefined,
        cache, cA_GetObj);
      if (!child || !child.imageSrc) {
        return;
      }
      // Only put "scenic" Waypoint images into the slideshow.
      // We don't want to show navigational images.
      // Only put images a user has uploaded, not placeholders.
      const isScenic = child.cn == WAYS ? child.isScenic : true;
      if (isScenic && child.hasUserSetImageSrc()) {
        log.trace("child.imageSrc: ", child.imageSrc);
        // Don't add duplicates.
        if (childrenImageSrcs.indexOf(child.imageSrc) < 0) {
          childrenImageSrcs.push(child.imageSrc);
        }
      }
    });

    if (childrenImageSrcs.length < 1 || showChildren) {
      // Did not find any direct children images,
      // or the callers wants to see many images,
      // so look in the children's children.
      this.children.forEach(childId => {
        log.trace("childId: ", childId)
        const child = ObjUtils.get(curUserObj, childId);
        if (!child) {
          return;
        }
        const images = child.getChildrenImageSrcs(curUserObj,
          cache, cA_GetObj);
        childrenImageSrcs = Array.from(
          new Set(childrenImageSrcs.concat(images)));
      });
    }

    log.trace("Final childrenImageSrcs.length: ", childrenImageSrcs.length);
    return childrenImageSrcs;
  }

  getCarouselImages = (curUserObj, cache, cA_GetObj, showChildren) => {
    const images = [];

    // An ObjComponent can display either a single image
    // in an ActionImage component, or it can display a
    // "carousel" slideshow of images in an ActionCarousel
    // component.
    //
    // Only show the carousel of children images if the user
    // has NOT specified a specific imageSrc for this object
    // and there are more than 1 children images to display.
    // Otherwise, just display the single imageSrc.
    if (this.hasUserSetImageSrc()) {
      // Display the single imageSrc the user specified
      // for this object.
      images.push(this.imageSrc);
      if (!showChildren) {
        return images;
      }
    }

    // User has not specified an image for this object,
    // (or caller is asking us to show all images),
    // so get children images.
    let childrenImageSrcs = this.getChildrenImageSrcs(
      curUserObj, cache, cA_GetObj, showChildren);

    if (childrenImageSrcs.length >= 1) {
      if (images.length >= 1) {
        childrenImageSrcs = Array.from(
          new Set(childrenImageSrcs.concat(images)));
      }
      return childrenImageSrcs;
    }
    else {
      // No descendents have a scenic image either, so 
      // return whatever the placeholder image is.
      return[this.imageSrc];
    }
  };

  /**
   * Given props, usually an RxDB object, create an ObjC
   * object.
   *
   * @param {User} curUserObj - If this parameter is set,
   * this constructor will set any properties that curUserObj
   * is not allowed to see to the value "redacted".
   * See this.redact().
   */
  constructor(props, curUserObj, callRedact = true) {
    log.trace("Obj.constructor()");
    log.trace("Obj.constructor(), curUserObj:", curUserObj);
    //if (!curUserObj) debugger;

    const collectionName = props && props._id ?
      Obj.getCnFromId(props._id) : "noPropsOrNoId";
    if (!collectionName || collectionName == "noPropsOrNoId") {
      log.bug("In Obj.constructor(), collectionName not set.");
      debugger;
    }
    if (props && isRxDocument(props)) {
      //props = ObjUtils.rxdbPropsToObjCProps(props, collectionName);
      props = Obj.rxdbPropsToObjCProps(props, collectionName);
      //props = Object.assign({}, props, collectionName);
    }
    else {
      // props is probably a "default" object of some type.
    }
    log.trace("Obj props:", props);

    // NOTE: We could move this into the else above.
    // Not needed if we are using the value returned by
    // rxdbPropsToObjCProps().
    Object.assign(this, Obj.DEFAULT_PROPS(collectionName));

    // Override default props with passed in props.
    Object.assign(this, props);
    log.trace("this:", this);

    // Redaction is done on the server side, before the object
    // is sent to the client.  If curUserObj is not null/undefined,
    // we are on the server, so do the redaction.  If curUserObj is
    // null/undefined, that means we are on the client, redaction
    // has already been done before the object was sent to us, so
    // don't do try to do the redaction again.
    // 
    // TODO: above comments are out of date.
    // Also, if curUserObj is not a real logged in user we should
    // call this.redact() but pass a User object with no privileges.
    // E.g. the User.DEFAULT_USER
    // 
    // NOTE: I would like to do curUserObj instanceof User, but
    // that means I would have to include user.js which includes
    // this obj.js file.  Circular include caused problems.
    if (curUserObj && curUserObj.cn == USERS && callRedact) {
      log.trace("Obj constructor calling this.redact()");
      this.redact(curUserObj);
    }
    log.trace("Obj.constructor(), returning this:", this);
  }

  get displayName() {
    return this._displayName = this._displayName ?
      this._displayName : "No Name Yet";
  }
  set displayName(displayName) {
    this._displayName = displayName;
  }

  get details() {
    return this._details = this._details ? this._details : "Enter a summary of this object.  If you are using a computer, (e.g. not a phone), you can temporarily expand this text field while editing it by dragging the bottom right corner.";
  }
  set details(details) {
    this._details = details;
  }

  static convertAnythingToTime(value, defaultValue) {
    if (value === undefined || value === null) {
      return defaultValue ? defaultValue : Date.now();
    }
    else if (typeof value == "number") {
      return value;
    }
    else if (value instanceof Number) {
      return value.valueOf();
    }
    else if (_.isString(value)) {
      return Date.parse(value);
    }
    else if (value instanceof Date) {
      return value.getTime();
    }
    else {
      log.error("Bad parameter type passed to Obj.convertAnythingToTime("+
        value+").  "+
        "Should be a number, Number, string, String, or Date.  "+
        "It is a: "+(typeof value));
      return Date.now();
    }
  }

  /*
  get userIds() {
    if (this._userIds === undefined || this._userIds === null) {
      this._userIds = [];
    }
    return this._userIds;
  }
  set userIds(userIds) {
    if (userIds === undefined || userIds === null) {
      userIds = [];
    }
    this._userIds = userIds;
  }
  */

  get created() {
    log.trace("Enter get created(), this._created", this._created);
    if (this._created === undefined || this._created === null) {
      this._created = Date.now();
    }
    return this._created;
  }
  set created(created) {
    this._created = Obj.convertAnythingToTime(created);
  }

  get expires() {
    log.trace("Enter get expires(), this._expires", this._expires);
    if (this._expires === undefined || this._expires === null) {
      this._expires = EXPIRES_NEVER;
    }
    return this._expires;
  }
  set expires(expires) {
    log.trace("Obj set expires("+expires+")");
    this._expires = Obj.convertAnythingToTime(expires, EXPIRES_NEVER);
  }

  get imageSrc() {
    if (!this._imageSrc) {
      this._imageSrc = Obj.PLACEHOLDER_IMAGE(this.cn);
    }
    return this._imageSrc;
  }
  set imageSrc(imageSrc) {
    this._imageSrc = imageSrc;
  }

  /**
   * Avatar image.
   */
  get avatarSrc() {
    if (!this._avatarSrc) {
      // Avatar image defaults to "normal" image.
      return this.imageSrc;
    }
    return this._avatarSrc;
  }
  set avatarSrc(avatarSrc) {
    this._avatarSrc = avatarSrc;
  }

  // Returns true if the user specified an image,
  // as opposed to no image specified or a placeholder.
  hasUserSetImageSrc() {
    return !!(this.imageSrc && !_.includes(this.imageSrc, "placeholders"));
  }

  hasUserSetAvatarSrc() {
    return !!(this.avatarSrc && this._avatarSrc &&
              !_.includes(this._avatarSrc, "placeholders"));
  }

  /**
   * The _id of the User who created this Obj.
   */
  get creator() {
    return this._creator;
  }
  set creator(creator) {
    // If creator is not null, make sure it is a string not a
    // Mongoose ObjectID object.
    this._creator = creator ? ""+creator : creator;
  }

  /**
   * The _id of the User who "owns" this Obj.
   * Owner defaults to creator if owner has not be specifically set.
   */
  get owner() {
    return this._owner = this._owner ? this._owner : this.creator;
  }
  set owner(owner) {
    // If owner is not null, make sure it is a string not a
    // Mongoose ObjectID object.
    this._owner = owner ? ""+owner : owner;
  }

  get statusAttribute() {
    return this._statusAttribute ? this._statusAttribute : PENDING;
  }
  set statusAttribute(statusAttribute) {
    this._statusAttribute = statusAttribute ? statusAttribute : PENDING;
  }

  /**
   * Set the "action flags" in this Obj object to true if that
   * action is available to the current user.  If the action is NOT
   * available to the current user, leave the action flag
   * undefined/falsy.  E.g. if the passed in curUserObj
   * is not a root or admin, and is not the owner of this Obj, then
   * this redact() function will NOT set the value of this.deleteObj
   * to true.
   *
   * @param {User} curUserObj - The User object that is the currently
   * logged in user.
   */
  redact(curUserObj) {
    log.trace("Obj.redact()");
    //log.trace("Obj.redact(), curUserObj:", curUserObj);
    this.redactActions(curUserObj);
    this.redactProps(curUserObj);
  }

  redactActions(curUserObj) {
    log.trace("Obj.redactActions() this.displayName: ", this.displayName);
    log.trace("Obj.redactActions(), this:", this);

    const isPrivate = RedactUtils.isPrivate(this);
    const signedIn = RedactUtils.isSignedIn(curUserObj);
    //const isOwnerOrAdminOrOwnerless = 
    //  RedactUtils.isOwnerOrAdminOrOwnerless(curUserObj, this);
    const isOwnerOrAdmin = 
      RedactUtils.isOwnerOrAdmin(curUserObj, this);

    log.trace("isPrivate:", isPrivate);
    log.trace("signedIn:", signedIn);
    log.trace("isOwnerOrAdmin:", isOwnerOrAdmin);

    // The owner or admin can view it.
    // If this is not private, anyone can view it.
    //this.view = (signedIn && isOwnerOrAdminOrOwnerless) ||
    /*
    if (signedIn && isOwnerOrAdmin) {
      this.view = true;
    }
    else if (!isPrivate) {
      this.view = true;
    }
    else {
      this.view = false;
    }
    */
    this.view = (signedIn && isOwnerOrAdmin) || !isPrivate;
    // NOTE: Hack for private users.
    this.view = this._id == PRIVATE_ID ? false : this.view;

    log.trace("this.view: ", this.view);

    // A signed in user who is the owner or admin can edit it.
    //this.edit = signedIn && isOwnerOrAdminOrOwnerless;
    this.edit = signedIn && isOwnerOrAdmin;

    // A signed in user who is the owner or admin can delete it.
    //this.deleteObj = signedIn && isOwnerOrAdminOrOwnerless;
    this.deleteObj = signedIn && isOwnerOrAdmin;

    // You cannot flag your own content.
    this.flag = signedIn && !(this.owner == curUserObj._id);

    // Can curUser view this object's userIds list?
    this.redactViewCircle(curUserObj);

    // Can this object be favorited?
    this.redactFavorite(curUserObj);

    // Can this object be recommended to a user?
    this.redactRecommend(curUserObj);
  }

  /**
   * Set this.favorite to true if this object can be favorited
   * to a user by curUserObj.
   */
  redactFavorite(curUserObj) {
    this.favorite = false;

    if (this._id == curUserObj._id) {
      // User cannot favorite themselves.
      return;
    }

    if (this.owner == curUserObj._id) {
      // User can favorite anything they own.
      this.favorite = true;
      return;
    }

    if (this.statusAttribute != "approved") {
      // Only approved objects can be favorited.
      // Even admins cannot add an unapproved object to a
      // user's favorites.
      // TODO: If an object is flagged or moved back
      // to "pending", we need to remove it from favorites lists.
      return;
    }
    this.favorite = true;
  }

  /**
   * Set this.recommend to true if this object can be recommended
   * to a user by curUserObj.
   */
  redactRecommend(curUserObj) {
    this.recommend = false;

    if (this.statusAttribute != "approved") {
      // Only approved objects can be recommended.
      // TODO: If an object is flagged or moved back
      // to "pending", we need to remove it from recommendation lists.
      return;
    }
    if (!curUserObj || !curUserObj.hasAdminPriv || !curUserObj.hasRole) {
      return;
    }
    if (!curUserObj.hasAdminPriv() && !curUserObj.hasRole("guide")) {
      // curUserObj is not an admin or a guide, so s/he can't
      // recommend anything.
      return;
    }
    this.recommend = true;
  }
 
   // Only an admin or the user him/herself can view the userIds list.
  redactViewCircle(curUserObj) {
    const viewerId = strId(curUserObj);  // User who wants to view userIds.
    const userId = this._id;  // This User who "owns" the userIds list.

    this.viewUsers = false;

    //if (viewerId == null || viewerId == User.DEFAULT_USER_ID) {
    if (viewerId == null || isEqualIds(curUserObj, DEFAULT_USER_ID)) {
      // An anonymous user cannot view anyone's userIds.
      return;
    }
    if (userId == null || isEqualIds(this, DEFAULT_USER_ID)) {
      // The default/anonymous user has no userIds list.
      return;
    }
    /*
    if (isEqualIds(curUserObj, this) && this.userIds &&
        this.userIds.length > 0) {
      // This user has userIds in their own userIds array.
      this.viewUsers = true;
      return;
    }
    */
    if (!isEqualIds(curUserObj, this) && !curUserObj.hasAdminPriv()) {
      // The curUserObj is not this user AND curUserObj does NOT
      // have admin privileges, so s/he can't view another user's
      // userIds.
      return;
    }
    /*
    if (this.hasRole("root")) {
      // No other user can view the root user's userIds.
      return;
    }
    */
    if (this.visibility == "isPublic"/* ||
        !this.userIds || !Array.isArray(this.userIds) ||
        this.userIds.length < 1*/) {
      // User's visibility is public (default) or
      // there are no userIds to view.
      return;
    }
    this.viewUsers = true;
  }

  redactProps(curUserObj) {
    log.trace("Obj.redactProps()");
    const visibility = this.visibility;
    log.trace("Obj.redactProps(), visibility:", visibility);

    const signedIn = RedactUtils.isSignedIn(curUserObj);
    const isOwnerOrAdmin = RedactUtils.isOwnerOrAdmin(curUserObj, this);
    log.trace("Obj.redactProps(), isOwnerOrAdmin:", isOwnerOrAdmin);
    if (isOwnerOrAdmin) {
      // Don't redact anything.
      return;
    }

    // UPDATE_THIS_WHEN_ADDING_PROPS
    switch (visibility) {
      case "isPrivate":
        /*
        log.bug('Obj.redactProps() called on Obj that has '+
          'visibility == "isPrivate" and curUser '+
          'is not the owner.  Should never happen. curUserObj._id('+
          curUserObj._id+'), Obj/this:', this);
        */
        this.imageSrc = Obj.PRIVATE(this.cn).imageSrc;
        this.avatarSrc = undefined;
        this.displayName = Obj.PRIVATE(this.cn).displayName;
        this.details = Obj.PRIVATE(this.cn).details;
        //this.created = REDACTED;
        this.children = undefined;
        this.parkWaypoint = undefined;
        this.locationPoint = undefined;
        this.urls = undefined;
        this.favorites = undefined;
        this.recommendations = undefined;
        this.guides = undefined;
        this.urls = undefined;
        this.owner = REDACTED;
        this.creator = REDACTED;
        this.userIds = undefined;
        break;

      case "isProtected":
        this.locationPoint = REDACTED;
        this.children = REDACTED;
        this.parkWaypoint = REDACTED;
        this.urls = REDACTED;
        break;

      case "isPublic":
      default:
        // Public, so do not redact any props.
    }
  }

  /**
   * Get an object that is this object's properties, but
   * convert "_" getter/setter properties into their 
   * getter/setter names.
   *
   * TODO: I don't think this is used any more.
   */
  props() {
    const props = {};
    for (let name in this) {
      if (_.startsWith(name, "_")) {
        name = name.slice(1);
      }
      log.trace("name:", name);
      props[name] = this[name];
    }
    //return JSON.stringify(props);
    log.trace("Returning props:", props);
    return props;
  }
}

export default Obj;
