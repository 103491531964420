
import _ from 'lodash';
import React from 'react';
import {log, ConciergeRestApi} from 'concierge-common';
import UrlButton from '../url-button';

const {REDACTED} = ConciergeRestApi;


/**
 * Utilities associated with the "url buttons" display on
 * UI components in the app.
 *
 * TODO: When/if I make the Way/Act/Itn/User objects all be
 * a single UI object, these functions should just be member
 * functions of that object.
 */
class UrlButtonUtils {

  static createUrlButton(displayName, description, url, internal,
    obj, propName, index,
    cA_History, cA_Confirmation, cA_SetError) {
    const urlButtonProps = {
      displayName,
      description,
      url,
      internal,
      cA_History,
      cA_Confirmation,
      cA_SetError,
      obj,  // The Way/Act/Itn obj that contains the urls.
      propName,
      index,  // Index of this url in the Act.urls array.
    };

    // UrlButton does not use key.  It is just to keep React
    // happy.
    return <UrlButton {...urlButtonProps} key={index} />
  }

  static createUrlButtons(obj, propName,
    cA_History, cA_Confirmation, cA_SetError) {
    const urls = obj[propName];
    log.trace("UrlButtonUtils.createUrlButtons(), urls:", urls);
    if (urls == REDACTED) {
      return undefined;
    }

    let urlButtons = _.map(urls,
      (url, index)=>this.createUrlButton(
      url.displayName, url.description, url.url,
      url.internal, obj, propName, index,
      cA_History, cA_Confirmation, cA_SetError));
    urlButtons = urlButtons && urlButtons.length > 0 ?
      urlButtons : undefined;
    return urlButtons;
  }
}

export default UrlButtonUtils;
