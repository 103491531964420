import _ from 'lodash';
import React from 'react';
import {Link} from 'react-router-dom';
import {log, MyError} from 'concierge-common';
import {ANYS} from '../global-constants';
import * as ACTION_TYPES from '../actions/types';
import UI from '../utils/ui-state';
import {cA_SetSidebarVisibility, cA_SetLoader} from '../actions/ui';
import {cA_SetProgressBar, cA_History, cA_Confirmation} from '../actions/ui';
import {cA_SetError} from '../actions/error';
import ConfMessageUtils from './utils/conf-message-utils';

const DEFAULT_UI = new UI();

function startOrContinueProgress(action, newUI) {
  let {timer} = newUI.progressBar;
  
  if (timer) {
    clearTimeout(timer);
  }
  timer = setTimeout(()=>action.asyncDispatch(cA_SetProgressBar(false)),
    1000);

  newUI.progressBar.timer = timer;
}

function finishProgress(newUI) {
  let {timer} = newUI.progressBar;
  
  if (timer) {
    clearTimeout(timer);
  }
  timer = null;

  newUI.progressBar.timer = timer;
}

function getSortMessage(filterAndSort) {
  const {visible, filter, sort} = filterAndSort;
  let msg;
  if (sort.displayName) {
    msg = "Sorted by name, ";
    if (sort.displayName == "asc") {
      msg += "A to Z.";
    }
    else {
      msg += "Z to A.";
    }
  }
  else {// if (sort.created) {
    msg = "Sorted by creation time, ";
    if (sort.created == "asc") {
      msg += "oldest first.";
    }
    else {
      msg += "newest first.";
    }
  }
  return msg;
}

// TODO: This is a kludge.  Eventually put information like this
// into the user's info, or current session info.
const messagesShown = [];

/**
 * This reducer manages the value of the application's store
 * state.ui property.
 *
 * @param {ui} ui The current store state.ui object.  We should
 * never modify this value.
 *
 * @param {Object} action The current action to be performed.
 * @param {String} action.type This tells us "what" we need to do.
 * @param {Object} action.payload This is the data that we will use to
 * complete the action.
 * @param {Object} action.payload.users The object that we are
 * going to act upon.
 */
function uiReducer(ui = DEFAULT_UI, action) {
  log.trace("uiReducer("+action.type+") ui:", ui);
  log.trace("action:", action);

  const {payload} = action;
  log.trace("payload:", payload);
  if (!payload) {
    // If payload is null/undefined, that means there was
    // no payload attached to this action.  Redux "interal"
    // action types such as "@@redux/INIT" don't have payloads,
    // and we should ignore Redux internal actions anyway.
    return ui;
  }

  if (payload.error) {
    // payload.error means that there was an error, so we
    // don't want to make any changes to the UI besides
    // canceling the loader/spinner.
    // We could just set ui.loader.visible = false right here,
    // but I don't think that is really the proper way to
    // do things.  E.g. what if some other reducer wants to
    // do something when the loader is fiddled with.
    //
    // TODO: Eventually write a proper handler for these
    // sorts of asyn actions that have three states:
    // request sent, request succeeded, request failed.
    //
    action.asyncDispatch(cA_SetLoader(false));
    return ui;
  }

  let newUI;
  switch(action.type) {

    case ACTION_TYPES.SET_DB_STATE:
      log.trace("ui-reducer(), SET_DB_STATE: ", payload.dbState);
      newUI = _.cloneDeep(ui);
      newUI.dbState = payload.dbState;
      return newUI;

    case ACTION_TYPES.SET_SIDEBAR_VISIBILITY:
      newUI = _.cloneDeep(ui);
      newUI.sidebar.visible = payload.visible;
      return newUI;

    case ACTION_TYPES.SET_FILTER_AND_SORT_VISIBILITY:
      newUI = _.cloneDeep(ui);
      newUI.filterAndSort.visible = payload.visible;
      return newUI;

    case ACTION_TYPES.SET_FILTER_AND_SORT:
      newUI = _.cloneDeep(ui);
      const {curUser, filterAndSort, collectionName} = payload;
      log.trace("SET_FILTER_AND_SORT, payload: ", payload);
      //newUI.filterAndSort = Object.assign({}, payload.filterAndSort);
      log.trace("Before setting newUI: ", newUI);
      // cloneDeep should not be needed here?
      newUI.filterAndSort[collectionName] = _.cloneDeep(filterAndSort);
      log.trace("After setting, newUI: ", newUI);

      // If the user changed the way things are sorted,
      // show a message telling the user how things are now
      // being sorted.  Only show each message once.
      if ((filterAndSort.sort.displayName !=
          ui.filterAndSort[collectionName].sort.displayName) ||
          (filterAndSort.sort.created !=
          ui.filterAndSort[collectionName].sort.created)) {
        const msg = getSortMessage(filterAndSort);
        if (!_.includes(messagesShown, msg)) {
          messagesShown.push(msg);
          const props = {msg, severity:MyError.INFO};
          const error = MyError.createSubmitError(props);
          action.asyncDispatch(cA_SetError(error));
        }
      }

      if (collectionName == ANYS) {
        //alert("Filtering/Sorting of heterogeneous objects not yet implemented.");
      }
      return newUI;

    case ACTION_TYPES.SET_FOOTER_VISIBILITY:
      newUI = _.cloneDeep(ui);
      newUI.footer.visible = payload.visible;
      newUI.footer.hideTime = new Date().getTime();
      log.trace("appReducer()          old ui:", ui);
      log.trace("appReducer() returning newUI:", newUI);
      return newUI;

    case ACTION_TYPES.SET_SHELL_CONTENTS_PROPS:
      newUI = _.cloneDeep(ui);
      newUI.shellContents = newUI.shellContents ?
        newUI.shellContents : {};
      newUI.shellContents.props = payload.shellContentsProps;
      return newUI;

    case ACTION_TYPES.SET_LOADER:
      newUI = _.cloneDeep(ui);
      log.trace("Setting loader.visible:", payload.visible);
      log.trace("Setting loader.message:", payload.message);
      newUI.loader = newUI.loader ? newUI.loader : {};
      newUI.loader.visible = payload.visible;
      newUI.loader.message = payload.message;
      return newUI;

    case ACTION_TYPES.SET_PROGRESS_BAR:
      newUI = _.cloneDeep(ui);
      log.trace("SET_PROGRESS_BAR, visible:", payload.visible);
      const {visible} = payload;
      if (visible) {
        startOrContinueProgress(action, newUI);
      }
      else {
        log.trace("SET_PROGRESS_BAR, calling finishProgress().");
        finishProgress(newUI);
      }
      return newUI;

    case ACTION_TYPES.MULTI_ACTION:
      newUI = _.cloneDeep(ui);
      log.trace("case MULTI_ACTION, payload:", payload);
      const actions = payload && payload.actions &&
        payload.actions.length > 0 ? payload.actions : null;
      newUI.multiAction = {actions, headerText:payload.headerText};
      log.trace("newUI.multiAction:", newUI.multiAction);
      return newUI;

    case ACTION_TYPES.DISPATCH_ACTION:
      newUI = _.cloneDeep(ui);
      log.trace("case DISPATCH_ACTION, payload.action:", payload.action);
      action.asyncDispatch(payload.action);
      return ui;

    case ACTION_TYPES.NOT_YET_IMPLEMENTED:
      newUI = _.cloneDeep(ui);
      log.trace("case NOT_YET_IMPLEMENTED, payload.text:", payload.text);
      const props = {msg:payload.text, severity:MyError.INFO};
      const error = MyError.createSubmitError(props);
      action.asyncDispatch(cA_SetError(error));
      return ui;

    case ACTION_TYPES.SET_DEVICE:
      newUI = _.cloneDeep(ui);
      log.trace("case SET_DEVICE, payload.action:", payload.action);
      // Copy over existing props.  Don't replace device object.
      Object.assign(newUI.device, payload.device);

      // Add classes to document HTML element.
      let msg = "";
      if (payload.device.touch === true) {
        document.documentElement.classList.add("has-touch");
        document.documentElement.classList.remove("no-touch");
        msg = "Device is a touch device like a phone/tablet.";
      }
      else if (payload.device.touch === false) {
        // We'll never get here.
        document.documentElement.classList.add("no-touch");
        document.documentElement.classList.remove("has-touch");
        msg = "Device is not a touch device like a phone/tablet.";
      }
      if (payload.device.hover === true) {
        document.documentElement.classList.add("has-hover");
        document.documentElement.classList.remove("no-hover");
        msg = "Device has a mouse, and is probably not a phone/tablet.";
      }
      else if (payload.device.hover === false) {
        // We'll never get here.
        document.documentElement.classList.add("no-hover");
        document.documentElement.classList.remove("has-hover");
        msg = "Device does not have a mouse, and is probably a phone/tablet.";
      }
      {
        const props = {msg, severity:MyError.INFO};
        const error = MyError.createSubmitError(props);
        action.asyncDispatch(cA_SetError(error));
      }
      return newUI;

    case ACTION_TYPES.SET_SHELL_TYPE:
      newUI = _.cloneDeep(ui);
      newUI.shellType = payload.shellType;

      // NOTE: This is a hack while I am still using both the
      // button drawer version of the filter/sort UI and this
      // propsEditor modal dialog.
      // Dismiss the button drawer if it is currently shown.
      if (newUI.filterAndSort.visible) {
        newUI.filterAndSort.visible = false;
      }
      return newUI;

    /*
    // Show/hide loader when getting waypoint(s).
    case ACTION_TYPES.GET_WAY_P:
      action.asyncDispatch(cA_SetProgressBar(true));
    //case ACTION_TYPES.GET_WAY_S:
    //case ACTION_TYPES.GET_WAY_F:
      return ui;
    */

    // Show/hide loader when getting act(s).
    case ACTION_TYPES.GET_ACT_P:
      action.asyncDispatch(cA_SetProgressBar(true));
    //case ACTION_TYPES.GET_ACT_S:
    //case ACTION_TYPES.GET_ACT_F:
      return ui;

    case ACTION_TYPES.GET_ITN_P:
      action.asyncDispatch(cA_SetProgressBar(true));
    //case ACTION_TYPES.GET_ITN_S:
    //case ACTION_TYPES.GET_ITN_F:
      return ui;

    case ACTION_TYPES.DELETE_ACT_S:
      if (payload.error) {
        return ui;
      }
      // If we were displaying a single Act, navigate to
      // the Acts page.  (We just deleted the Act we were
      // displaying.)
      if (window.myHistory.location.pathname == "/act") {
        //action.asyncDispatch(cA_History("push", "/acts"));
        action.asyncDispatch(cA_History("back"));
      }
      return ui;

    case ACTION_TYPES.DELETE_ITN_S:
      if (payload.error) {
        return ui;
      }
      // If we were displaying a single Itn, navigate to
      // the Itns page.  (We just deleted the Itn we were
      // displaying.)
      if (window.myHistory.location.pathname == "/itn") {
        //action.asyncDispatch(cA_History("push", "/itns"));
        action.asyncDispatch(cA_History("back"));
      }
      return ui;

    case ACTION_TYPES.HISTORY:
      log.trace("payload.url:", payload.url);
      switch(payload.func) {
        case "push":
          // NOTE: I can call push without the setTimeout() wrapper,
          // but if the url is bad and we would normally display an
          // error message by dispatching a cA_SetError action, the
          // system complains that reducers should not dispatch actions.
          setTimeout(() => window.myHistory.push(payload.url), 0);
          break;

        case "replace":
          // Replace the current URL with the new one.
          // Kind of like "push", but instead of pushing the
          // new URL onto the top of the history, we replace
          // the previous URL with this new URL.  I.e. the old
          // URL is not part of the history any more.  If the user
          // hits the back button, the browser will not go to
          // the "old" URL, but instead go to the URL before
          // the "old" URL.
          setTimeout(() => window.myHistory.replace(payload.url), 0);
          break;

        case "back":
          setTimeout(() => window.myHistory.goBack(), 0);
          break;

        case "reload":
          // -2 = back 2 pages.  2 = forward 2 pages.
          // 0 = reload current page.
          const pageDelta = 0;
          setTimeout(() => window.myHistory.go(pageDelta), 0);
          break;

        /*
        case "location":
          // NOTE: I don't need to use the setTimeout() wrapper for
          // this, but maybe it lets React "clean up" stuff associated
          // the dipatch of the action?
          setTimeout(() => window.location = payload.url, 0);
          break;
        */
        case "openUrlInTab":
          // NOTE: It is possible that the browser might not let
          // us open a new tab unless we are calling the window.open()
          // function from within the onClick() callback.
          // But, it appears to work even if I do it in a timeout.
          // The only advantage I see for doing it in a timeout is that
          // React updates the current page, dismissing the MultiAction
          // panel, BEFORE we go to the new tab.
          // If we immediately call window.open(), the user MultiAction
          // page won't be updated, i.e. removed, until the user displays
          // the current page again.
          setTimeout(() => window.open(payload.url, payload.tab), 0);
          //window.open(payload.url, payload.tab);
          break;
          
        default:
          const msg = "Unhandled HISTORY func in ui-reducer.  payload.func("+
            payload.func+")";
          log.bug(msg);
          const error = MyError.createSubmitError({msg});
          action.asyncDispatch(cA_SetError(error));
      }
      //???action.asyncDispatch(cA_SetNavbarAndSidebar(location, curUser);
      return ui;

    case ACTION_TYPES.SIGN_OUT_S:
      newUI = _.cloneDeep(ui);
      log.trace("ui-reducer, SIGN_OUT_S, payload: ", payload);
      if (payload.user && payload.user.token) {
        // User has signed out, so this should never happen.
        log.trace("Setting navbar to signed in items.");
        newUI.navbar.rightItems = UI.RIGHT_ITEMS_SIGNED_IN;
        //newUI.navbar.leftItems = UI.LEFT_ITEMS_SIGNED_IN;
        newUI.navbar.leftItems = UI.getLeftItemsSignedIn(payload.user);
        newUI.sidebar.items = newUI.navbar.leftItems;
      }
      else {
        log.trace("Setting navbar to anonymous items.");
        newUI.navbar.rightItems = UI.RIGHT_ITEMS_ANONYMOUS;
        newUI.navbar.leftItems = UI.LEFT_ITEMS_ANONYMOUS;
        newUI.sidebar.items = newUI.navbar.leftItems;
      }
      action.asyncDispatch(cA_SetSidebarVisibility(false));
      // Clear any errors.  Probably want to do this?
      action.asyncDispatch(cA_SetError(null));
      return newUI;

    //case ACTION_TYPES.SET_NAVBAR_AND_SIDEBAR:
    case ACTION_TYPES.SIGN_UP_S:
    // Fall through
    case ACTION_TYPES.SIGN_IN_S:
      newUI = _.cloneDeep(ui);
      log.trace("ui-reducer, SIGN_IN_S, payload:", payload);
      if (payload.user && payload.user.token) {
        log.trace("Setting navbar to signed in items.");
        newUI.navbar.rightItems = UI.RIGHT_ITEMS_SIGNED_IN;
        //newUI.navbar.leftItems = UI.LEFT_ITEMS_SIGNED_IN;
        newUI.navbar.leftItems = UI.getLeftItemsSignedIn(payload.user);
        newUI.sidebar.items = newUI.navbar.leftItems;
      }
      else {
        log.trace("Setting navbar to anonymous items.");
        newUI.navbar.rightItems = UI.RIGHT_ITEMS_ANONYMOUS;
        newUI.navbar.leftItems = UI.LEFT_ITEMS_ANONYMOUS;
        newUI.sidebar.items = newUI.navbar.leftItems;
      }

      // Create an action that hides the sidebar,
      // and then asynchronously dispatch it using the
      // function the asyncDispatchMiddleware adds to
      // all our actions as they pass through the Redux
      // framework.
      action.asyncDispatch(cA_SetSidebarVisibility(false));

      // Clear any errors.  Probably want to do this?
      action.asyncDispatch(cA_SetError(null));
    return newUI;

    case ACTION_TYPES.CONFIRMATION:
      newUI = _.cloneDeep(ui);
      log.trace("action CONFIRMATION, payload:", payload);
      const {title, message1, message2,
        okAction, okLabel, okDisabled, cancelAction, cancelLabel,
        doubleOkConfirmation, doubleCancelConfirmation,
        okButton, cancelButton, okButtonProps, okButtonIconName} = payload;
      newUI.confirmation = {
        title, message1, message2,
        okAction, okLabel, okDisabled,
        cancelAction, cancelLabel,
        doubleOkConfirmation, doubleCancelConfirmation,
        okButton, cancelButton, okButtonProps, okButtonIconName,
      };
      log.trace("Returning newUI.confirmation:", newUI.confirmation);
      return newUI;

    case ACTION_TYPES.USAGE_CONFIRMATION_P:
      //action.asyncDispatch(cA_SetProgressBar(true));
      //action.asyncDispatch(cA_SetLoader(true, "Checking to see if this is being used..."));
      return ui;

    case ACTION_TYPES.USAGE_CONFIRMATION_F:
      //action.asyncDispatch(cA_SetProgressBar(false));
      //action.asyncDispatch(cA_SetLoader(false));
      return ui;

    case ACTION_TYPES.USAGE_CONFIRMATION_S:
      newUI = _.cloneDeep(ui);
      return ConfMessageUtils.getUIForUsageConfirmation(newUI, action);

    case ACTION_TYPES.ENABLE_CONFIRMATION_OK_BUTTON:
      newUI = _.cloneDeep(ui);
      newUI.confirmation.okDisabled = false;
      log.trace("Returning newUI: ", newUI);
      return newUI;

    case ACTION_TYPES.PROPS_EDITOR:
      newUI = _.cloneDeep(ui);
      // NOTE: This is a hack while I am still using both the
      // button drawer version of the filter/sort UI and this
      // propsEditor modal dialog.
      // Dismiss the button drawer if it is currently shown.
      /*
      if (newUI.filterAndSort.visible) {
        newUI.filterAndSort.visible = false;
      }
      else {
        newUI.propsEditor = Object.assign({}, payload.propsEditor);
      }
      */
      newUI.propsEditor = Object.assign({}, payload.propsEditor);
      return newUI;

    default:
      log.trace("ui-reducer default");
      return ui;
  }
};

export default uiReducer;
