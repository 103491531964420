import * as ACTION_TYPES from './types';


/**
 * Update object(s) in the store.state.cache.objs array.
 *
 * @param objs an array of User/Way/Act/Itn objects or
 * a single object.
 */
function cA_UpdateCache(objs) {
  // Caller might have passed us a single object.
  // If so, create an array with that one object.
  // TODO: Switch to using reducers/utils/ObjUtils.createArray()
  if (!Array.isArray(objs)) {
    const newArray = [];
    if (objs) {
      newArray.push(objs);
    }
    objs = newArray;
  }

  return({
    type: ACTION_TYPES.UPDATE_CACHE,
    payload: {
      objs,
    },
  });
}

/**
 * Delete object(s) from the store.state.cache.objs array.
 *
 * @param objIds Either an array of IDs or a single ID.
 */
function cA_DeleteCache(objIds) {
  // Caller might have passed us a single object.
  // If so, create an array with that one object.
  if (!Array.isArray(objIds)) {
    const newArray = [];
    if (objIds) {
      newArray.push(objIds);
    }
    objIds = newArray;
  }

  return({
    type: ACTION_TYPES.DELETE_CACHE,
    payload: {
      objIds,
    },
  });
}

export {cA_UpdateCache};
export {cA_DeleteCache};
