import moment from 'moment';
class TimeUtils {

  // TODO: Move these to global-constants.
  static get SECOND() {return 1000};
  static get MINUTE() {return TimeUtils.SECOND * 60};
  static get HOUR() {return TimeUtils.MINUTE * 60};
  static get DAY() {return TimeUtils.HOUR * 24};
  static get WEEK() {return TimeUtils.DAY * 7};

  static get OPTIONS_LONG() {
    return {
      weekday:'long',
      year:'numeric',
      month:'long',
      day:'numeric'
    }
  };

  static getStringLong(date = new Date()) {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    const theDate = moment(date);
    return theDate.format("LLLL");
    //return date.toLocaleDateString(undefined, TimeUtils.OPTIONS_LONG);
  } 

  static getMomentProps(date = Date.now(), prefix = "", postfix = "") {
    if (date instanceof Date) {
      date = date.getTime();
    }
    let format = "ll";
    let fromNow = false;
    if ((Date.now() - date) < (2 * TimeUtils.WEEK)) {
      fromNow = true;
      format = "";
    }
    const text = prefix+TimeUtils.getStringLong(date)+postfix; 
    return {fromNow, format, text, interval:0};
  }
}

export default TimeUtils;
