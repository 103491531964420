import _ from 'lodash';
import {isRxDocument} from 'rxdb';
import {log, MyError} from 'concierge-common';
import {ConciergeRestApi} from 'concierge-common';
import {WAYS, ACTS, ITNS, USERS} from '../../global-constants';
import {isEqualIds} from '../../utils/string-utils';
import {cA_SetError} from '../../actions/error';
import * as ACTION_TYPES from '../../actions/types';
import store from '../../store';
import LocalDB from '../local-db';
import ObjComponent from '../../containers/obj';
import ApiUtils from '../../client-db/api/api-utils';
import ObjC from './obj';
import UserC from './user';


const {REDACTED} = ConciergeRestApi;

/**
 * This module contains:
 *
 * Functions to get a UserC/WayC/ActC/ItnC object
 * from the store.state.cache.objs
 *
 * Functions to get RxDB objects/documents from the
 * browser's RxDB/PouchDB database.
 * Functions to create an action to do that.
 */


/**
 * Get the RxDocument from the browser's local RxDB/PouchDB
 * using the passed in objId.  Create a UserC/WayC/ActC/ItnC
 * object from it, and return that object in a payload along
 * with the collectionName and possibly and error object.
 *
 * NOTE: Instead of this, you should probably be calling
 * one of the following functions.  If you know the obj
 * with a matching ID is in the store.state.cache.objs,
 * you should call:
 *
 *    ObjUtils.get()
 *
 * If you don't need the value immediately, and don't
 * know whether it is in the cache, call:
 *
 *    ObjUtils.cA_GetObj()
 *
 * to cause the object to be gotten from the browser's
 * local RxDB/PouchDB and put into the cache.
 */
async function getRxDBObj(curUser, objId, collectionName) {
  log.trace("Enter getRxDBObj("+objId+") "+collectionName);
  if (!collectionName){ debugger;}
  const findOneProp = {_id:objId};
  const collection = LocalDB.db[collectionName];
  let rxdbObj;
  if (collection) {
    const rxQuery = LocalDB.db[collectionName].findOne().where("_id").eq(objId);
    log.trace("rxQuery: ", rxQuery);
    rxdbObj = await rxQuery.exec();
  }
  else {
    log.bug("In cache getRxDBObj("+collectionName+") collection null: ", collection);
    //debugger;
  }

  // User readable type.  e.g. "Waypoint", "Adventure".
  const objType = ObjC.DISPLAY_NAME(collectionName);

  log.trace("findOne() returned: ", rxdbObj);
  let objProps = rxdbObj ? rxdbObj : null;
  let obj = null;
  let error;
  switch(collectionName) {
    case USERS:
      if (objProps) {
        // NOTE: This is a hack until we get authorization working
        // in the new version.  If the curUser is being updated from
        // RxDB we need to "copy over" the currently logged in
        // user's token.
        if (curUser && objProps &&
            (objProps._id == curUser._id) &&
            !objProps.token) {
          //debugger;
          if (curUser.token == null) {
            //debugger;
            //curUser.token = "someSortOfToken";
          }
          objProps.token = curUser.token;
        }

        // Create a UserC object from the rxdbObj.
        obj = new UserC(objProps, curUser);
      }
      else {
        // Object with the specified id was not in the DB.
        // Possibly this is a user or object that
        // has been deleted in the past.  E.g. A user was deleted
        // but a Waypoint still exists whose creator was that
        // user.  So, create some sort of
        // placeholder for the DB.
        objProps = Object.assign({}, UserC.DEFAULT_USER);
        objProps._id = objId;
        objProps.displayName = "Missing User";
        obj = new UserC(objProps, curUser);

        // Redact actions from Missing User.
        obj.view = false;
        obj.edit = false;
        obj.sendMessage = false;
        obj.viewMessages = false;
        obj.connect = false;
        obj.acceptConnection = false;
        obj.disconnect = false;
        obj.deleteUser = false;
        obj.deleteObj = false;
      }
      break;

    case WAYS:
    case ACTS:
    case ITNS:
      if (objProps == null) {
        objProps = Object.assign({}, ObjC.DEFAULT(collectionName));
        objProps._id = objId;
        objProps.displayName = "Missing "+objType;  // e.g. "Waypoint"
      }
      obj = new ObjC(objProps, curUser);
      break;

    default:
      const msg = ("Unhandled collectionName("+collectionName+
        ") in ObjUtils.getDefault()");
      const props = {msg, severity:MyError.BUG};
      error = MyError.createSubmitError(props);
      debugger;
  }

  const payload = {
    obj, // UserC, WayC, ActC, ItnC
    rxdbObj,
    collectionName,
    error,
  };
  return payload;
}

/**
 * Get a user/way/act/itn object from the browser's local
 * RxDB/PouchDB and create an Redux action that will put
 * it in the store.state.cache.objs
 *
 * @param {User} curUser - The currently logged in user who is
 * doing the getting.
 */
function cA_GetObj(curUser, objId, collectionName) {
  log.trace("cA_GetObj("+objId+", "+collectionName+")");
  if (collectionName) {
    log.todo("Remove collectionName from cA_GetObj() in obj-utils.js");
    const stack = new Error().stack;
    log.todo("STACK FOLLOWS:");
    log.todo(stack);
  }
  collectionName = ObjC.getCnFromId(objId);
  if (!collectionName) {debugger;}

  return({
    type: ACTION_TYPES.GET_OBJ,
    payload: {
      async promise() {
        const payload = await getRxDBObj(curUser, objId, collectionName);
        log.trace("getRxDBObj() returned payload:", payload);
        //let error = payload && payload.error;
        //error = error ? error : null;
        //payload.error = error;
        return payload;
      }
    },
  });
}

/**
 * Functions to get objects (User, Way, Act, Itn) from the
 * store.state.cache.users/ways/acts/itns.  If the object
 * was not found there, the function will send a request
 * to get it from the local RxDB/PouchDB and put it in the
 * store.state.cache.users/ways/acts/itns.
 *
 * Also other utility functions for dealing with objects and
 * RxDocument objects.
 */
class ObjUtils {

  static getComponentClass(collectionName) {
    switch(collectionName) {
      case WAYS:
      case ACTS:
      case ITNS:
        return ObjComponent;
      default:
        log.bug("In getComponentClass(), unhandled collectionName: ",
          collectionName);
        debugger;
    }
  }

  static getCollectionName(obj) {

    if (isRxDocument(obj)) {
      return obj.collection.name;
    }

    if (obj instanceof UserC) {
      if (!obj.cn) {
        log.bug("In ObjUtils.getCollectionName(UserC), "+
          "obj.cn undefined: ", obj.cn);
      }
      return obj.cn || USERS;
    }
    else if (obj.cn) {
      return obj.cn;
    }
    else if (obj._id) {
      return ObjC.getCnFromId(obj._id);
    }
    else {
      log.bug("ObjUtils.getCollectionName() cannot handle obj: ", obj);
      debugger;
    }
  }

  static getDefaults(collectionName) {

    let obj = null;
    let objId = "";  // Maybe return null when error instead?
    switch(collectionName) {

      case "users":
        objId = UserC.DEFAULT_USER_ID;
        obj = UserC.DEFAULT_USER;
        break;

      case "ways":
      case "acts":
      case "itns":
        objId = ObjC.DEFAULT_ID(collectionName);
        obj = ObjC.DEFAULT(collectionName);
        break;

      default:
        const msg = ("Unhandled collectionName("+collectionName+
          ") in ObjUtils.getDefaults()");
        debugger;
        const props = {msg, severity:MyError.BUG};
        const error = MyError.createSubmitError(props);
        store.dispatch(cA_SetError(error));
    }

    return {objId, obj};
  }

  static getDefaultObjId(collectionName) {
    return ObjUtils.getDefaults(collectionName).objId;
  }

  static getDefaultObj(collectionName) {
    return ObjUtils.getDefaults(collectionName).obj;
  }

  /**
   * Get a single Way/Act/Itn/User object.
   *
   * First look for the object in our store.state.cache.objs.
   * If we don't find it there, return the "default" object.
   *
   * If dispatchGetObj is passed in we will call it if we do not
   * find the object in the cache.  As of 16 Sep 2020, the
   * only value passed in would be the cA_GetObj() function
   * wrapped in the Redux dispatch() function.
   */
  static get(curUser, objId, collectionName, cache, dispatchGetObj) {
    if (collectionName) {
      log.todo("Remove collectionName parameter from ObjUtils.get()");
      const stack = new Error().stack;
      log.todo("STACK FOLLOWS:");
      log.todo(stack);
    }
    if (!objId) {
      log.bug("In ObjUtils.get(), objId: ", objId);
      debugger;
    }
    collectionName = collectionName ? collectionName : ObjC.getCnFromId(objId);
    // If caller did not pass us the cache, get it
    // from the store.state.
    // NOTE: Calling store.getState() is not allowed if
    // we are called from inside of a reducer.
    cache = cache ? cache : store.getState().cache;
    const {objs/*=new Map()*/} = cache;
    log.trace("objs:", objs);

    const {objId:defaultObjId, obj:defaultObj} =
      ObjUtils.getDefaults(collectionName);
    let obj;
    if (!objId || isEqualIds(objId, defaultObjId) ||
        isEqualIds(objId, REDACTED)) {
      obj = defaultObj;
    }
    else {
      //obj = objs[objId];
      obj = objs.get(objId);
    }
    log.trace("obj:", obj);

    // If we didn't find the way/act/itn/user in the store.state.cache
    // use the defaultObj so the UI can display something.
    if (!obj) {
      log.trace("Did not find objId:", objId);
      log.trace("curUser:", curUser);
      if (curUser && (curUser._id === objId) && (curUser instanceof UserC)) {
        log.trace("curUser._id matches objId, so using curUser:", curUser);
        obj = curUser;
      }
      else {
        log.trace("Using defaultObj:", obj);
        obj = defaultObj;
      }
      if (dispatchGetObj) {
        dispatchGetObj(curUser, objId/*, collectionName*/);
      }
    }
    else {
      log.trace("Found obj._id:", obj._id);

      // Special stuff put the "token" into the User object
      // if obj we retrieved was a User object and it was
      // the curUser.
      if (obj && curUser && isEqualIds(obj._id, curUser._id)) {
        obj.token = curUser.token;
      }
    }

    return obj;
  }

  /**
   * Create a new url object and append it to the Way/Act/Itn/User's
   * obj.urls property.
   *
   * NOTE: This code is not safe if the same user is logged in two
   * places at the same time and editing the list of URLs of an object.
   *
   * @param objId User._id, Way._id, etc.
   * @param collectionName "users", "ways", etc.
   */
  static createUrlProp(curUser, objId/*, collectionName*/) {
    const collectionName = ObjC.getCnFromId(objId);
    const obj = ObjUtils.get(curUser, objId, collectionName);

    if (!obj) {
      const msg = "ObjUtils.createUrlProp() could not get "+
        "the "+collectionName+" object.  objId: "+objId;
      const props = {msg, severity:MyError.BUG};
      const error = MyError.createSubmitError(props);
      cA_SetError(error);
      return -1;
    }

    // TODO: This same value is copied elsewhere.  Put it in one place.
    // components/url-prop.js
    const url = {
      displayName:"New Link",
      description:"",
      //url:"http://doc.govt.nz",
      url:"",
      internal:false,
    };

    // Make copy of the array, and append the url.
    /*
    const urls = obj.urls ? obj.urls.slice(0) : [];
    urls.push(url);

    const objProps = {
      urls,
    };
    */

    let indexOfNewlyAddedUrl;
    try {
      if (LocalDB.db && LocalDB.db[collectionName]) {
          LocalDB.db[collectionName].findOne().where("_id").eq(objId).update({
            $push:{urls:url},
            //$set:{messagesLastReceived:Date.now(),//(new Date()).toISOString()},
          });
      }
      indexOfNewlyAddedUrl = obj.urls.length;
    }
    catch(err) {
      log.bug("err:", err);
      //let whenTryingTo = "create a Url for objId: "+objId;
      //payload.error = createErrorFromException(err, whenTryingTo);
      return -1;
    }

    // Return the index of the newly added url.
    return indexOfNewlyAddedUrl;
  }

  static getFollowerIds(curUserObj, guide, cache, cA_GetObj) {
    let followerIds = [];

    //const guide = ObjUtils.get(curUserObj, guideId, undefined,
    //  cache, cA_GetObj);
    if (guide) {
      if (guide.followerIds) {
        return guide.followerIds;
      }

      ApiUtils.updateFollowerIds(curUserObj, guide/*, cache, cA_GetObj*/);
    }

    return followerIds;
  }

  /*
  static rxdbPropsToObjCProps(rxdbProps, collectionName) {
    const objCProps = _.cloneDeep(ObjC.DEFAULT_PROPS(collectionName));
    log.trace("Cloned objCProps:", objCProps);
    objCProps._id = rxdbProps._id;
    objCProps.displayName = rxdbProps.displayName;
    objCProps.imageSrc = rxdbProps.imageSrc;
    objCProps.avatarSrc = rxdbProps.avatarSrc;
    objCProps.isScenic = rxdbProps.isScenic;
    objCProps.details = rxdbProps.details;
    objCProps.creator = rxdbProps.creator;
    objCProps.owner = rxdbProps.owner;
    objCProps.created = rxdbProps.created;
    objCProps.locationPoint = rxdbProps.locationPoint;
    objCProps.children = rxdbProps.children;
    objCProps.parkWaypoint = rxdbProps.parkWaypoint;
    objCProps.expires = rxdbProps.expires;
    objCProps.urls = rxdbProps.urls;
    //objCProps.attributes = rxdbProps.attributes;
    objCProps.isTestData = rxdbProps.isTestData;
    objCProps.statusAttribute = rxdbProps.statusAttribute;
    objCProps.access = rxdbProps.access;
    log.trace("Final objCProps:", objCProps);
    return objCProps;
  }
  */

  /**********************************************/
  /*************** DELETE THESE BELOW ***********/
  /* Call generic functions above instead or create
  a new function getObj() that takes curUser, objId
  and collectionName parameters. */
  /**********************************************/

  /**
   * Get a single Way object.
   *
   * First look for the Way in our store's state.ways list.
   * If we don't find it there, get it from the server.
   * We return the "default way" placeholder if the actual
   * way is not found.
   *
   * @param {CurrentUser} curUser - The current (signed in?) user.
   * @param {string} userId - The ID of the desired user.
   */
  //static getWay(curUser, wayId) {
  // return ObjUtils.get(curUser, wayId, "ways");
  //}
  static getUser(curUser, userId, cache, cA_GetObj) {
    if (userId == REDACTED) {
      // TODO: Need to figure out how to handled redacted info.
      userId = UserC.DEFAULT_USER_ID;
    }
    userId = userId ? userId : UserC.DEFAULT_USER_ID;
    if (userId.startsWith("auth0") ||
        userId == "defaultUserId") {
      // Hack to deal with old data.
      userId = USERS+"_"+userId;
    }
    return ObjUtils.get(curUser, userId, undefined, cache, cA_GetObj);
  }
  //static getAct(curUser, actId) {
  //  return ObjUtils.get(curUser, actId, "acts");
  //}
  //static getItn(curUser, itnId) {
  //  return ObjUtils.get(curUser, itnId, "itns");
  //}

  /*************** DELETE THESE ABOVE ***********/
}

export default ObjUtils;
export {getRxDBObj};
export {cA_GetObj};
