// This module defines the UsagePanel *container*.
// NOT the UsagePanel *component*.

import {connect} from 'react-redux';

// Import the UsagePanelComponent from which this UsagePanelContainer is created.
import UsagePanelComponent from '../components/usage-panel';
import {cA_History} from '../actions/ui';
import {cA_GetObj} from '../client-db/api/obj-utils';


function mapStateToProps(state) {
  return({
    curUser:state.curUser,
    usage:state.usage,
    cache:state.cache,
    shellType:state.ui.shellType,
    filterAndSort:state.ui.filterAndSort,
    //filterAndSortVisible:state.ui.filterAndSort.visible,
    busy:!!state.ui.progressBar.timer,
  });
}

const mapDispatchToProps = {cA_History, cA_GetObj};
const UsagePanelContainer = connect(mapStateToProps, mapDispatchToProps)
  (UsagePanelComponent);
export default UsagePanelContainer;
