import {log} from 'concierge-common';
import * as ACTION_TYPES from '../actions/types';
import {cA_SetError} from '../actions/error';
import ObjUtils from './utils/obj-utils';


/**
 * This reducer manages the value of the application's
 * store.state.usage property.
 *
 * @param {usage} usage The current store.state.usage object.
 * We should never modify this value.
 *
 * @param {Object} action The current action to be performed.
 * @param {String} action.type This tells us "what" we need to do.
 * @param {Object} action.payload This is the data that we will use to
 * complete the action.
 * @param {Object} action.payload.usage The new values that we are
 * going to use to alter the state.usage property.
 */
function usageReducer(usage = ObjUtils.EMPTY_USAGE, action) {
  log.trace("usageReducer("+action.type+") usage:", usage);
  log.trace("usageReducer(), payload:", action.payload);
  log.trace("usageReducer(), action:", action);

  const {payload = {}} = action;
  if (!payload) {
    // Redux "interal" action types such as @@redux/INIT
    // don't have payloads, and we should ignore them
    // anyway.  But, "_P" pending actions have no payload either,
    // so we can't ignore actions with empty payloads.
    return usage;
  }

  let newUsage = usage;
  switch(action.type) {

    case ACTION_TYPES.SET_USAGE: {
      log.trace("SET_USAGE usageReducer(), action:", action);
      if (payload.error) {
        action.asyncDispatch(cA_SetError(payload.error));
        return usage;
      }
      newUsage = Object.assign({}, usage, payload.usage);
      return newUsage;
    }

    case ACTION_TYPES.SIGN_OUT_S:
      if (payload.error) {
        return usage;
      }
      newUsage = ObjUtils.EMPTY_USAGE;
      return newUsage;

    default:
      log.trace("used-by-reducer default");
      return usage;
  }
};

export default usageReducer;
