import React, { Component } from 'react';
import PropTypes from 'prop-types'

// This is object whose property names are country codes,
// e.g. "US", and property values are country names,
// e.g. "United States".
import allCountries from './countries';

class ReactFlagsSelect extends Component {
	constructor(props){
		super(props);
		this.selectedFlagRef = React.createRef();
    this.flagOptionsRef = React.createRef();
    this.filterTextRef = React.createRef();

		//let fullCountries = Object.keys(allCountries);

    // If the caller specified a "countries" property, turn that
    // list into "country" objects that map country codes, e.g. "NZ",
    // into country names, "New Zealand", 
    //
    // NOTE: At this point, we don't know whether these are the
    // countries the consumer wants displayed in the choicebox,
    // or whether they are the "blacklisted" countries the user does
    // NOT want displayed.
    /*
		let selectCountries = this.props.countries &&
      this.props.countries.filter(country => {
			  return allCountries[country] ;
		  });
    */

    // Now, either use the passed in list of countries, or remove
    // the blacklisted countries.  (Depending on the "blackList" prop.)
    /*
		selectCountries = !this.props.blackList ? selectCountries : fullCountries.filter(countryKey =>{
				return selectCountries.filter(country =>{
					return countryKey === country;
				}).length === 0
		});
    */

    this.state = this.createStateProps(props);
    this.state.openOptions = false;
    this.state.filteredCountries = [];
    this.state.defaultCountry = allCountries[props.defaultCountry] &&
      props.defaultCountry;
    /*
		this.state = {
			openOptions: false,
			countries: selectCountries || fullCountries,
			defaultCountry: defaultCountry,
			filteredCountries: []
		}
    */

		this.toggleOptions = this.toggleOptions.bind(this);
		this.closeOptions = this.closeOptions.bind(this);
		this.onSelect = this.onSelect.bind(this);
		this.filterSearch = this.filterSearch.bind(this);
	}

  createStateProps(props) {
    const {blackList, countries} = props;

    // The full list of all available country codes.
    // E.g. "AF", "AX", "AL", "DZ"...
		const fullCountries = Object.keys(allCountries);

    // If the caller specified a "countries" property, turn that
    // list into "country" objects that map country codes, e.g. "NZ",
    // into country names, "New Zealand", 
    //
    // NOTE: At this point, we don't know whether these are the
    // countries the consumer wants displayed in the choicebox,
    // or whether they are the "blacklisted" countries the user does
    // NOT want displayed.
		let selectCountries = countries &&
      countries.filter(country => {
        return allCountries[country] ;
      });

		selectCountries = !blackList ?
      selectCountries :
      fullCountries.filter(countryKey =>{
				return selectCountries.filter(country =>{
					return countryKey === country;
				}).length === 0
      });

    const stateProps = {
			countries: selectCountries || fullCountries,
    }

    return stateProps;
  }

	toggleOptions() {
		!this.state.disabled && this.setState({
			openOptions: !this.state.openOptions
		});
	}

	closeOptions(event) {
		if (event.target !== this.selectedFlagRef.current &&
        event.target !== this.flagOptionsRef.current &&
        event.target !== this.filterTextRef.current ) {
			this.setState({
				openOptions: false
			});
		}
	}

	onSelect(countryCode) {
		this.setState({
			selected: countryCode,
			filter : ''
		})
		this.props.onSelect && this.props.onSelect(countryCode);
	}

	updateSelected(countryCode) {
		let isValid = allCountries[countryCode];

		isValid && this.setState({
			selected: countryCode
		})
	}

	filterSearch(evt) {
		let filterValue = evt.target.value;
		let filteredCountries = filterValue && this.state.countries.filter(key => {
			let label = this.props.customLabels[key] || allCountries[key];
			return  label && label.match(new RegExp(filterValue, 'i'))
		}) ;

		this.setState({filter : filterValue, filteredCountries : filteredCountries });
	}

	componentDidMount() {
		!this.props.disabled && window.addEventListener("click", this.closeOptions);
	}

	componentWillUnmount() {
		!this.props.disabled && window.removeEventListener("click", this.closeOptions);
	}

  UNSAFE_componentWillReceiveProps(nextProps) {
    const stateProps = this.createStateProps(nextProps);
    this.setState(stateProps);
  }

	render() {
		let isSelected = this.state.selected || this.state.defaultCountry;
		let selectedSize = this.props.selectedSize;
		let optionsSize = this.props.optionsSize;
		let alignClass = this.props.alignOptions.toLowerCase() === 'left' ? 'to--left' : '';

    const openOptionsClass = this.state.openOptions ?
      "open-options" : "not-open-options";

		return (
			<div className={`flag-select ${openOptionsClass} ${this.props.className ? this.props.className :  ""}`}>
				<div ref={this.selectedFlagRef} style={{fontSize: `${selectedSize}px`}} className={`selected--flag--option ${this.props.disabled ? 'no--focus' : ''}`} onClick={this.toggleOptions}>
					{isSelected &&
						<span className="country-flag" style={{width: `${selectedSize}px`, height: `${selectedSize}px`}} >
							<img src={`../../flags/${isSelected.toLowerCase()}.svg`}
                alt={isSelected} />
							{this.props.showSelectedLabel &&
								<span className="country-label">{ this.props.customLabels[isSelected] || allCountries[isSelected] }</span>
							}
						</span>
					}

					{!isSelected &&
						<span className="country-label">{this.props.placeholder}</span>
					}
					<span className={`arrow-down ${this.props.disabled ? 'hidden' : ''}`}>▾</span>
				</div>

				{this.state.openOptions &&
					<div ref={this.flagOptionsRef} style={{fontSize: `${optionsSize}px`}} className={`flag-options ${alignClass}`}>
						{this.props.searchable &&
							<div className="filterBox">
								<input type="text" placeholder="Search" ref={this.filterTextRef}  onChange={this.filterSearch}/>
							</div>
						}
						{(this.state.filter ? this.state.filteredCountries : this.state.countries).map( countryCode =>

							<div className={`flag-option ${this.props.showOptionLabel ? 'has-label' : ''}`} key={countryCode} onClick={() => this.onSelect(countryCode)}>
								<span className="country-flag" style={{width: `${optionsSize}px`, height: `${optionsSize}px`}} >
									<img src={`../../flags/${countryCode.toLowerCase()}.svg`}
                    alt={countryCode}/>
									{this.props.showOptionLabel &&
										<span className="country-label">{ this.props.customLabels[countryCode] || allCountries[countryCode] }</span>
									}
								</span>
							</div>
						)}
					</div>
				}
			</div>
		)
	}
}

ReactFlagsSelect.defaultProps = {
	selectedSize: 16,
	optionsSize: 14,
	placeholder: "Select a country",
	showSelectedLabel: true,
	showOptionLabel: true,
	alignOptions: "right",
	customLabels: [],
	disabled: false,
	blackList: false,
	searchable: false
}

ReactFlagsSelect.propsType = {
	countries: PropTypes.array,
	blackList: PropTypes.bool,
	customLabels: PropTypes.array,
	selectedSize: PropTypes.number,
	optionsSize: PropTypes.number,
	defaultCountry: PropTypes.string,
	placeholder: PropTypes.string,
	className: PropTypes.string,
	showSelectedLabel: PropTypes.bool,
	showOptionLabel: PropTypes.bool,
	alignOptions: PropTypes.string,
	onSelect: PropTypes.func,
	disabled: PropTypes.bool,
	searchable: PropTypes.bool
}

export default ReactFlagsSelect;
