// This module defines the SelectObjPanel *container*.
// NOT the SelectObjPanel *component*.
import {connect} from 'react-redux';

import {log} from 'concierge-common';

// Import the SelectObjPanelComponent from which this
// SelectObjPanelContainer is created.
import SelectObjPanelComponent from '../components/select-obj-panel';

import {cA_History} from '../actions/ui';
import {cA_Confirmation} from '../actions/ui';
import {cA_GetObj} from '../client-db/api/obj-utils';


function mapStateToProps(state) {
  const props = {
    curUser:state.curUser,
    ways:state.ways,
    acts:state.acts,
    itns:state.itns,
    //users:state.users,
    //filtered:state.filtered,
    cache:state.cache,
  };
  return props;
}
const mapDispatchToProps = {cA_History, cA_Confirmation, cA_GetObj};
const SelectObjPanelContainer = connect(mapStateToProps, mapDispatchToProps)
  (SelectObjPanelComponent);
export default SelectObjPanelContainer;
