/**
 * This is our special "fallback" component that we display when
 * a thrown Error bubbles up the React component stack.
 * See where it is used in component/App.js
 */
import React from 'react';
import {log} from 'concierge-common';


class Fallback extends React.Component {

  //constructor({componentStack, error}) {
  //  super();
  /*
  constructor(props) {
    super(props);
    const {componentStack, error} = props;
  };
  */

  // Called by React when this Component has loaded in the DOM.
  /*
  componentDidMount() {
    log.trace("Fallback.componentDidMount() this.props: ", this.props);
  };
  */

  render() {
    log.trace("Enter Fallback.render() this.props: ", this.props);
    const {message, stack} = this.props.error;
    log.error("message: ", message+"\n  "+stack);
    //log.error("stack: ", stack);
    return(
      <div className='fallback'>
        <div>Fallback Component</div>
        <pre>Stack: {stack}</pre>
      </div>
    );
  };
}

export default Fallback;
