/**
 */
import _ from 'lodash';
import {log, MyError} from 'concierge-common';
import {WAYS, DEFAULT_USER_ID} from '../../global-constants';
import Obj from '../../client-db/api/obj';
import User from '../../client-db/api/user';
import ObjUtils from '../../client-db/api/obj-utils';
import ApiUtils from '../../client-db/api/api-utils';
import LocationUtils from './location-utils';
import ConfirmationUtils from './confirmation-utils';
import ObjActions from './obj-actions';


/**
 * Functions called externally:
 *
 *  calcObjMultiAction - Calc "card" actions.
 *  Called by ObjComponent.
 *
 *  calcObjOnMapMultiAction - Calc map "marker" actions.
 *  Called by MyMap.
 *
 *  calcMapHeaderMultiAction - Calc map header actions.
 *  Called by MyMap.
 */
class ActionUtils {

  /**
   * Returns a new array of sorted actions.
   */
  static sortActions(actions) {
    const actionOrder = [

      "Set Is Test Data",
      "Set Not Test Data",

      "Approve",
      "Set Pending",

      "View User",
      "View Waypoint",
      "View Adventure",
      "View Itinerary",
      "Add To Favorites",
      "Add To User Favorites",
      "Add To Users Favorites",
      "Add To Guides",
      "Recommend To User",
      "Recommend To Users",
      "Remove Recommendation",

      "Remove Parking",
      "Select Parking",
      "Change Parking",
      "Edit Parking",

      "Directions",
      "Use Current Location",
      "Reset Location",
      "Upload Image",
      //"Upload Profile Image",
      "Upload Avatar Image",
      "Remove Image",
      "Remove Avatar Image",

      "Itinerary Adventures",

      "Create Waypoint",
      "Create Adventure",
      "Create Itinerary",

      "Add User",
      "Add Users",
      "Filter And Sort",
      "Show Filter And Sort",
      "Hide Filter And Sort",

      "Add Waypoint",
      "Add Waypoints",
      "Add To Adventure",
      "Add To Adventures",
      "Add To Itinerary",
      "Add To Itineraries",
      "Add Adventure",
      "Add Adventures",
      "Add Link",
      "Remove From Adventure",
      "Remove From Itinerary",

      "Remove From Favorites",
      "Remove From Guides",

      "Send Message",
      "View Messages",
      "Connect",
      "Connection Pending",
      "Disconnect",
      "Add To Circle",
      "Remove From Circle",
      "View Circle",

      "View Favorites",
      "View Guides",
      "View Followers",
      "View Recommendations",

      "Delete Waypoint",
      "Delete Adventure",
      "Delete Itinerary",
      "Delete User",

      "Flag As Offensive",
    ];

    const sortedActions = [];
    for (let i = 0; i < actions.length; i++) {
      const action = actions[i];
      let actionIndex = actionOrder.indexOf(action.displayName);
      if (actionIndex < 0) {
        log.bug("Unhandled action.displayName("+
          action.displayName+") in ActionUtils.sortActions()");
        actionIndex = 0;
      }
      let j = 0;
      for (; j < sortedActions.length; j++) {
        const sortedAction = sortedActions[j];
        let sortedIndex = actionOrder.indexOf(sortedAction.displayName);
        if (sortedIndex < 0) {
          log.bug("Unhandled sortedAction.displayName("+
            sortedAction.displayName+") in ActionUtils.sortActions()");
          sortedIndex = 0;
        }
        if (actionIndex < sortedIndex) {
          break;
        }
      }
      sortedActions.splice(j, 0, action);
    }

    //return sortedActions;
    // Hack!!!  Should not be needed.
    return _.uniqBy(sortedActions, "displayName");
  }

  /**
   * Get a "user friendly" list of action names for a MultiAction
   * or array of actions.  E.g. "Upload Image, Upload Avatar, or
   * Delete User".
   *
   * @param actions - Either an array or a multiAction object.
   */
  static getActionsString(actions) {
    log.trace("actions:", actions);
    if (!actions) {
      return null;
    }
    let actionsString = "";
    if (actions.dispatch) {
      // The passed in object was a "multiAction" object.
      const {displayName} = actions;
      actionsString += displayName ? displayName : "";
      if (actionsString.length > 0) {
        actionsString += ": ";
      }
      actions = actions.actions;
    }

    // Do all the punctuation stuff.
    for (let i = 0; i < actions.length; i++) {
      if (i > 0) {
        if (actions.length == 2) {
          actionsString += " or ";
        }
        else if (actions.length > 2) {
          if (i < actions.length - 1) {
            actionsString += ", ";
          }
          else {
            actionsString += ", or ";
          }
        }
      }

      // Now append the name.  E.g. "Upload Image".
      actionsString += actions[i].displayName;
    }

    return actionsString;
  }

  static calcObjActions(props, curUserObj,
    obj, objPropName, parentObj, parentObjPropName,
    propDisplayName, noDrillDown = false,
    orderedObjIndex = undefined) {
    const {cA_History, cA_Confirmation, cA_UsageConfirmation,
      cA_SetError} = props;

    let actions = ObjActions.calcObjActions(obj, objPropName,
      parentObj, parentObjPropName, curUserObj, cA_History,
      props.cA_GetObj, props.cache, noDrillDown, orderedObjIndex,
      cA_Confirmation, cA_UsageConfirmation, cA_SetError);

    if (!actions || actions.length < 1) {
      return undefined;
    }
    //actions = ActionUtils.sortActions(actions);
    return actions;
  }

  static createMultiAction(props, actions, title,
    displayName = title, iconName = null) {
    log.trace("createMultiAction(), title:", title);
    const cA_MultiAction = props.cA_MultiAction;
    const action = {
      displayName,
      dispatch:() => cA_MultiAction(actions, title),
      iconName,

      // Only needed so getActionsString() can pick it apart.
      actions,
    };
    return action;
  }

  /**
   * Calculate the multiAction for an object
   * currently displayed on a Map.
   * (As opposed to when an object is displayed as a Card.)
   *
   * @param objPropName If we are displaying obj on a map,
   * then this tells us which property of obj we are displaying.
   * E.g. obj.locationPoint
   *
   * @param parentObjPropName If we are displaying children of
   * parentObj on a map this tells us which property of parentObj
   * we are displaying.  E.g. parentObj.children
   */
  static calcObjOnMapMultiAction(props, getPoint, resetPoint, setPoint, 
    curUserObj, obj, objPropName, parentObj, parentObjPropName,
    title, orderedObjIndex) {
    log.trace("Enter calcObjOnMapMultiAction(), props: ", props);
    log.trace("Enter calcObjOnMapMultiAction(), obj: ", obj);
    log.trace("Enter calcObjOnMapMultiAction(), parentObj: ", parentObj);
    if (!obj) {
      log.bug("In calcObjOnMapMultiAction(), obj is null");
    }

    const {cA_History, noDrillDown, cA_Confirmation,
      cA_UsageConfirmation, cA_SetLoader, cA_GetObj,
      cache, cA_SetError} = props;
    let actions = ObjActions.calcObjOnMapActions(
      obj, objPropName, parentObj, parentObjPropName,
      curUserObj, cA_History, cA_GetObj, cache, noDrillDown,
      orderedObjIndex, cA_Confirmation, cA_UsageConfirmation,
      setPoint, resetPoint, cA_SetLoader, cA_SetError);

    if (!actions || actions.length < 1) {
      return undefined;
    }
    actions = ActionUtils.sortActions(actions);
    const multiAction = ActionUtils.createMultiAction(props, actions,
      title, orderedObjIndex);
    return multiAction;
  }

  static calcObjMultiAction(props, curUserObj, obj, objPropName,
    title, parentObj = null, parentObjPropName,
    propDisplayName, noDrillDown = false,
    orderedObjIndex = undefined) {

    const {cA_History, cA_Confirmation, cA_UsageConfirmation,
      cA_SetLoader, cA_GetObj, cache, cA_SetError} = props;
    let actions = ObjActions.calcObjActions(obj, objPropName,
      parentObj, parentObjPropName,
      curUserObj, cA_History, cA_GetObj, cache, noDrillDown,
      orderedObjIndex, cA_Confirmation, cA_UsageConfirmation,
      cA_SetError);

    if (!actions || actions.length < 1) {
      return undefined;
    }
    actions = ActionUtils.sortActions(actions);
    const multiAction = ActionUtils.createMultiAction(props, actions, title);
    return multiAction;
  }

  /**
   * @param {number} aspect - Aspect ratio that will be enforced on
   * the cropped image.  Pass undefined for no restrictions.
   * @param {string} displayName - The menu item name displayed for the action.
   * @param {string} iconName - The icon displayed in the menu item.
   * @param {string} title - Panel title.  E.g. "Upload User Avatar Image".
   * @param {string} filename - Desired filename without extension.
   * E.g. "userAvatarImage_1234", not "userAvatarImage_1234.jpg".
   * @param {string} propName - The name of the property in the User
   * object.  E.g. "imageSrc", "avatarSrc".
   */
   /*
  static createUploadAction(props, aspect, className, displayName,
    iconName, title, filename, propName) {
    log.trace("ActionUtils.createUploadAction(), title:", title);
    log.trace("ActionUtils.createUploadAction(), className:", className);
    const {cA_SetShellContents} = props;
    const contentsProps = {
      aspect,
      className,
      title,
      filename,
      uploadSuccessHandler:imageURL => ActionUtils.uploadSuccessHandler(
        props, imageURL, propName),
    };
    const action = {
      displayName,
      dispatch:() => cA_SetShellContents("imageUpload", contentsProps),
      iconName,
    };
    log.trace("createUploadAction() return action:", action);
    return action;
  }
  */

  /**
   * Image upload succeeded.  E.g User Profile/Avatar, Act.
   * Show a notification message and update the User/Act object's
   * image src property to point to the newly uploaded
   * image.
   *
   * This function is called whether the server or the client does
   * the upload to Firebase.
   *
   * @param {string} propName - The name of the property we are
   * going to change.  E.g. "imageSrc", "avatarSrc".
   */
/*
  static uploadSuccessHandler(props) {
    log.trace("ActionUtils.uploadSuccessHandler(), props:", props);
    const {curUser, objId, imageURL, propName, collectionName} = props;

    // Create the property object that will tell mongoose over
    // on the concierge-rest server the new property value.  E.g.
    // objProps = {
    //   imageSrc:"https://firebasestorage.googleapis.com/image/123..."
    // }
    const objProps = {[propName]:imageURL};

    ApiUtils.editRxDBObj(curUser, objId, objProps, collectionName);

    log.bug("TODO: Navigate to /act?actId="+objId);
  }
*/
  static possiblyEditUser(userProps, error, curUser, userId,
    cA_SetError) {
    log.trace("possiblyEditUser(), userProps:", userProps);
    if (error) {
      log.trace("Calling cA_SetError() with error:", error);
      cA_SetError(error);
      return;
    }
    else {
      cA_SetError(null);
    }
    ApiUtils.editRxDBUser(curUser, userId, userProps);
  }

  static setPointToCurrentLocation(setPoint, cA_SetLoader) {
    cA_SetLoader(true, "Getting current location...");
    LocationUtils.getCurrentLocation(latLng=>{
      cA_SetLoader(false);
      log.trace("latLng:", latLng);
      const makeVisible = true;
      setPoint(latLng, makeVisible);
    });
  }

  static showDirections(lat, lng, cA_History) {
    const url = "https://www.google.com/maps/dir/?api=1&destination="+
      lat+"%2C"+lng;  // %2C is a comma character ','
    //cA_History("location", url);

    // NOTE: It is possible that the browser might not let
    // us open a new tab unless we are calling the window.open()
    // function from within the onClick() callback.
    // Calling window.open() directly here worked.
    // But, I have switched to adding a cA_History call to keep
    // that sort of stuff all in one place.
    //window.open(url, "_map");
    cA_History("openUrlInTab", url, "_map");
  }

  static calcMapHeaderMultiAction(props, getPoint, resetPoint, setPoint, 
    showDirections, curUserObj,
    obj, objPropName, parentObj, parentObjPropName, title) {
    let actions = ActionUtils.calcMapHeaderActions(props, getPoint,
      resetPoint, setPoint, showDirections, curUserObj,
      obj, objPropName, parentObj, parentObjPropName);

    // Calculate the actions of an object displayed
    // as a Card, and add them to the actions we've already
    // created above.
    const noDrillDown = false;
    // Only used for deciding which actions to show.
    const propName = "children";
    const propDisplayName = "Waypoints";  // Not ever shown.
    const actions2 = ActionUtils.calcObjActions(props, curUserObj,
      obj, objPropName, parentObj, parentObjPropName,
      propDisplayName, noDrillDown);
    log.trace("actions2:", actions);
    if (actions2 && actions2.length >= 1) {
      actions.push(...actions2);
    }

    if (!actions || actions.length < 1) {
      return undefined;
    }
    actions = ActionUtils.sortActions(actions);
    const multiAction = ActionUtils.createMultiAction(props, actions, title);
    return multiAction;
  }

  static calcMapHeaderActions(props, getPoint, resetPoint,
    setPoint, /*showDirections,*/ curUserObj,
    obj, objPropName, parentObj, parentObjPropName) {
    log.trace("ActionUtils.calcMapHeaderActions(), props:", props);
    log.trace("ActionUtils.calcMapHeaderActions(), setPoint:", setPoint);
    log.trace("ActionUtils.calcMapHeaderActions(), setPoint:", setPoint);
    log.trace("ActionUtils.calcMapHeaderActions(), obj:", obj);
    log.trace("ActionUtils.calcMapHeaderActions(), parentObj:", parentObj);

    const {cA_History, cA_SetLoader} = props;
    let actions = [];

    if (obj && obj.view) {
      /*
      if (showDirections) {
        //// Get directions to the location using Google Maps.
        //actions.push({
        //  displayName:"Directions",
        //  dispatch:()=>ActionUtils.showDirections(getPoint, cA_History),
        //  iconName:"car",
        //});
        // Show Google Map Directions
        const {locationPoint} = obj;
        const coordinates = locationPoint ?
          locationPoint.coordinates : null;
        const lat = coordinates && coordinates.length >=2 ?
          coordinates[1] : null;
        const lng = coordinates && coordinates.length >=1 ?
          coordinates[0] : null;
        if (lat !== null && lng !== null) {
          actions.push({
            displayName:"Directions",
            dispatch:()=>ActionUtils.showDirections(lat, lng, cA_History),
            iconName:"car",
          });
        }
      }
      */

      const objType = Obj.DISPLAY_NAME(obj.cn);
      actions.push({
        displayName:"View "+objType,
        iconName:"map outline",
        url:"/obj?objId="+obj._id,
      });
    }

    if (obj && obj.edit) {
      // The obj is editable, so add the edit actions.

      if (setPoint) {
        actions.push({
          displayName:"Use Current Location",
          dispatch:()=>ActionUtils.setPointToCurrentLocation(setPoint,
            cA_SetLoader),
          //iconName:"compass",
          iconName:"location arrow",
        });
      }

      if (resetPoint) {
        actions.push({
          displayName:"Reset Location",
          dispatch:resetPoint,
          iconName:"repeat"
        });
      }
    }

    if (obj && parentObj && parentObj.edit && parentObjPropName) {
      // Shallow copy of the children array.
      const children = parentObj[parentObjPropName].slice(0);
      _.pull(children, obj._id);
      const  parentProps = {children};
      log.trace("Edited children:", children);

      // Remove Waypoint from Adventure that contains it.
      const parentType = Obj.DISPLAY_NAME(parentObj.cn);
      const imageSrc = obj.cn == WAYS ?
        "../../img/wayRemove.png" :
        "../../img/removeAct.png";
      actions.push({
        displayName:"Remove From "+parentType,
        dispatch:() => ApiUtils.editRxDBObj(curUserObj, parentObj._id,
          parentProps),
        //imageSrc:"../../img/actRemoveWay.png",  // TODO: Proper icon image
        imageSrc,
      });
    }

    //actions = ActionUtils.sortActions(actions);
    return actions;
  }

  // Returns true if the Joined component should
  // be shown for the passed in user.
  static showJoined(user) {
    return user._id != User.SUPPORT_USER_ID &&
           user._id != User.FOSTER_USER_ID &&
           user._id != User.ADMIN_USER_ID &&
           user._id != User.ROOT_USER_ID &&
           user._id != User.DEFAULT_USER_ID &&
           user._id != User.PRIVATE_ID;
  }

  // Returns true if the FellowTravelers component should
  // be shown for the passed in user.
  static showFellowTravelers(user) {
    return user._id != User.SUPPORT_USER_ID &&
           user._id != User.FOSTER_USER_ID &&
           user._id != User.ADMIN_USER_ID &&
           user._id != User.ROOT_USER_ID &&
           user._id != User.DEFAULT_USER_ID &&
           user._id != User.PRIVATE_ID;
  }

  // Returns true if the Visibilty component should
  // be shown for the passed in user.
  static showVisibility(user) {
    return user._id != User.SUPPORT_USER_ID &&
           user._id != User.FOSTER_USER_ID &&
           user._id != User.ADMIN_USER_ID &&
           user._id != User.ROOT_USER_ID &&
           user._id != User.DEFAULT_USER_ID &&
           user._id != User.PRIVATE_ID;
  }
}

export default ActionUtils;
