// This module defines the ObjPropPanel *container*.
// NOT the ObjPropPanel *component*.

import {connect} from 'react-redux';

// Import the ObjPropPanelComponent from which this
// ObjPropPanelContainer is created.
import ObjPropPanelComponent from '../components/obj-prop-panel';

import {cA_History} from '../actions/ui';
import {cA_GetObj} from '../client-db/api/obj-utils';

function mapStateToProps(state) {
  return({
      curUser:state.curUser,
      cache:state.cache,
  });
}
const mapDispatchToProps = {cA_History, cA_GetObj};
const ObjPropPanelContainer = connect(mapStateToProps, mapDispatchToProps)
  (ObjPropPanelComponent);
export default ObjPropPanelContainer;
