// This module defines the ImageCropPanel *container*.
// NOT the ImageCropPanel *component*.
import {connect} from 'react-redux';

//import {log} from 'concierge-common';

// Import the ImageCropPanelComponent from which this ImageCropPanelContainer is created.
import ImageCropPanelComponent from '../components/image-crop-panel';

// Import action creators that this component will use.
import {cA_SetError} from '../actions/error';
import {cA_SetLoader} from '../actions/ui';
import {cA_History} from '../actions/ui';


function mapStateToProps(state) {

  const props = {
    // Used to get the temporary image file that was uploaded by
    // the ImageUploadPanel, and the uploadSuccessHandlerFunction.
    // These are used by the ImageCropPanel, and are accessed via:
    // this.props.contentsProps.file 
    // this.props.contentsProps.uploadSuccessHandler 
    contentsProps:state.ui.shellContents.props,
  };

  return props;
}
const mapDispatchToProps = {cA_SetError, cA_SetLoader, cA_History};
const ImageCropPanelContainer = connect(mapStateToProps, mapDispatchToProps)(ImageCropPanelComponent);
export default ImageCropPanelContainer;
