import _ from 'lodash';
import {log, MyError} from 'concierge-common';
import * as ACTION_TYPES from '../actions/types';
import {cA_SetSidebarVisibility} from '../actions/ui';
import {cA_SetLoader, cA_SetProgressBar, cA_History} from '../actions/ui';
import {cA_SetError} from '../actions/error';

const DEFAULT_REPLICATION = {
  completed:false,
};

/**
 * This reducer manages the value of the application's store
 * state.replication property.
 *
 * @param replication The current store state.replication object.
 * We should never modify this value.
 *
 * @param {Object} action The current action to be performed.
 * @param {String} action.type This tells us "what" we need to do.
 * @param {Object} action.payload This is the data that we will use to
 * complete the action.
 * @param {Object} action.payload.replication The (possibly new)
 * replication values.
 */
function replicationReducer(replication = DEFAULT_REPLICATION, action) {
  log.trace("replicationReducer("+action.type+") replication:", replication);

  const {payload} = action;
  if (!payload) {
    // If payload is null/undefined, that means there was
    // no payload attached to this action.  Redux "interal"
    // action types such as "@@redux/INIT" don't have payloads,
    // and we should ignore Redux internal actions anyway.
    return replication;
  }
  log.trace("action:", action);
  log.trace("payload:", payload);

  /*
  if (payload.error) {
    // payload.error means that there was an error, so we
    // don't want to make any changes to the UI besides
    // canceling the loader/spinner.
    // We could just set ui.loader.visible = false right here,
    // but I don't think that is really the proper way to
    // do things.  E.g. what if some other reducer wants to
    // do something when the loader is fiddled with.
    //
    // TODO: Eventually write a proper handler for these
    // sorts of asyn actions that have three states:
    // request sent, request succeeded, request failed.
    //
    action.asyncDispatch(cA_SetLoader(false));
    return ui;
  }
  */


  switch(action.type) {

    case ACTION_TYPES.SET_REPLICATION:
      const newReplication = _.cloneDeep(replication);
      Object.assign(newReplication, payload);
      //if (replication.complete) {
      //  newReplication.complete = replication.complete;
      //}
      log.trace("replicationReducer() returning newReplication: ",
        newReplication);
      return newReplication;

    default:
      log.trace("replication-reducer default");
      return replication;
  }
};

export default replicationReducer;
