import _ from 'lodash';
import * as ACTION_TYPES from '../actions/types';
import {log} from 'concierge-common';
import LocalDB from '../client-db/local-db';
import UserC from '../client-db/api/user';
//import store from '../store';
import {cA_History} from '../actions/ui';
import {cA_SetLoader} from '../actions/ui';

const DEFAULT_CUR_USER = {_id:null, token:null};

//let globalCurUserObj = DEFAULT_CUR_USER;


// NOTE: This is a hack.  We should instead fire off an action that
// causes a reducer to run that gets the entire store.state.  It can
// then get the User object out of store.state.cache Map without a
// search being involved.
// 
// This code fires searches through the list of updated objects looking
// to see if any of them have an _id that matches curUser._id.
async function updateCurUser(curUser, updatedObjs, asyncDispatch) {
  if (!curUser || !curUser._id) {
    return;
  }
  const newCurUser = _.find(updatedObjs,
    obj => obj._id == curUser._id);

  if (!newCurUser) {
    return;
  }

  // We have to copy over the token from store.state.curUser because
  // the token is not saved in the DB.
  newCurUser.token = curUser.token;

  const signInAction = {
    type:ACTION_TYPES.SIGN_IN_S,
    payload:{user:newCurUser}
  };
  asyncDispatch(signInAction);
}

/*
function saveCurUserToLocalStorage(curUser) {
  if (!localStorage) {
    log.info("Your browser does not support localeStorage.");
    return;
  }
  //if (!curUser) {
  //  localStorage.removeItem("curUser");
  //  return;
  //}
  const curUserJSON = JSON.stringify(curUser);
  log.trace("Saving curUser to localStorage. curUserJSON:", curUserJSON);
  localStorage.setItem("curUser", curUserJSON);

  //const temp = localStorage.getItem("curUser");
  //log.trace("TEST SAVED VALUE localStorage curUserJSON:", temp);
}
*/

/**
 * This reducer manages the value of the application's store
 * state.curUserId property.
 *
 * @param {Object} curUser The current store state.curUser Object.
 * We should never modify this value.
 *
 * @param {Object} action - The current action to be performed.
 * @param {String} action.type - This tells us "what" we need to do.
 * @param {Object} action.payload - This is the data that we will use to
 * complete the action.
 * @param {Object} action.payload.curUser - An Object that contains
 * the _id and token of the newly signed up/in/out user that will
 * become the application's "current user".
 * @param {MyError} action.payload.error - If non-null, then it is the
 * error that occurred during this action.
 */
function curUserReducer(curUser = DEFAULT_CUR_USER, action) {
  log.trace("curUserReducer("+action.type+") curUser:", curUser);
  log.trace("action:", action);

  const {payload} = action;
  if (!payload || payload.error) {
    // Redux "interal" action types such as @@redux/INIT
    // don't have payloads, and we should ignore them
    // anyway.
    // Also, if there was an error, ignore this action.
    return curUser;
  }

  let newCurUser;
  switch(action.type) {

    case ACTION_TYPES.SIGN_UP_S:
      log.trace("cur-user-reducer ACTION_TYPES.SIGN_UP_S, payload: ", payload);

      if (!payload || !payload.user) {
        log.bug("In curUserReducer() SIGN_UP_S, empty payload.user: ",
        payload);
        return curUser;
      }

      newCurUser = payload.user;
      //saveCurUserToLocalStorage(newCurUser);
      //globalCurUserObj = newCurUser;

      // We succeeded in signing up, so display the "newly signed up"
      // content.  Currently, that is simply the new/current user's
      // profile page.
      const url = "/curUser";

      action.asyncDispatch(cA_History("push", url));
//      action.asyncDispatch(cA_SetLoader(false));

      if (LocalDB.db) {
        log.info("cur-user-reducer calling LocalDB.initStoreState()");
        LocalDB.initStoreState(newCurUser, payload.filterAndSort);
      }

      log.trace("Returning newCurUser:", newCurUser);
      return newCurUser;

    case ACTION_TYPES.SIGN_IN_S:
      log.trace("cur-user-reducer ACTION_TYPES.SIGN_IN_S, payload: ", payload);
      newCurUser = payload.user;
      //globalCurUserObj = newCurUser;
      //saveCurUserToLocalStorage(newCurUser);

      // We succeeded in signing in, so display the "signedIn" content.
      //const url = "/signedIn";
      //action.asyncDispatch(cA_History("push", url));

      action.asyncDispatch(cA_SetLoader(false));

      if (LocalDB.db && newCurUser && payload.filterAndSort) {
        setTimeout(()=>
          LocalDB.initStoreState(newCurUser, payload.filterAndSort), 0);
      }

      log.trace("Returning newCurUser:", newCurUser);
      return newCurUser;

    case ACTION_TYPES.SIGN_OUT_S:
      log.info("cur-user-reducer, ACTION_TYPES.SIGN_OUT_S, user: ", payload.user);
      //if (payload.user._id != curUser._id) {
      //  log.error("In curUserReducer SIGN_OUT_S, payload.user._id != curUser._id");
      //}
      newCurUser = {
        _id:null, //payload.user._id,
        token:null, //payload.user.token
      };
      //globalCurUserObj = newCurUser;

      // Remove the saved curUser from the browser's local storage.
      // We can either set "curUser" key's value to the newCurUser
      // object that has a null _id and token, or set the "curUser"
      // key to null, or change the saveCurUserToLocalStorage()
      // function to call localStorage.removeItem("curUser") to remove
      // it completely.
      //saveCurUserToLocalStorage(null);
      //saveCurUserToLocalStorage(newCurUser);

      // No longer needed.  Being done by the Auth0 logout redirection.
      //action.asyncDispatch(cA_History("push", "/signedOut"));
      return newCurUser;

    case  ACTION_TYPES.UPDATE_CACHE:
      const {objs:updatedObjs} = payload;
      // Keep store.state.curUser in sync with the
      // User object for that that user that is in
      // store.state.cache.
      updateCurUser(curUser, updatedObjs, action.asyncDispatch);

      // Possible updating of curUser happens in the async
      // function above.  We just return the same curUser that
      // was passed in.
      return curUser;


    default:
      log.trace("cur-user-reducer default");
      return curUser;
  }
};

export default curUserReducer;
//export {globalCurUserObj};
