import React from 'react';
import {Menu, Icon} from 'semantic-ui-react';
import {log} from 'concierge-common';
import MyProgressBar from '../containers/my-progress-bar';
import NavbarUtils from './utils/navbar-utils';


class NavbarMobile extends React.Component {

  componentDidMount() {
    const {cA_SetShellType} = this.props;
    cA_SetShellType("shell-mobile");
  }

  render() {
    log.trace("NavbarMobile.render() this.props:", this.props);
    // onClick is the hamburger menu callback.
    const {/*navbar,*/ onClick} = this.props;
    
    // Create the login menu that appears on the far right
    // side of the navbar.
    const loginSignupLogoutMenu = NavbarUtils.createLoginSignupLogoutMenu();

    return (
      <Menu className="navbar-mobile" inverted>
        <Menu.Item onClick={onClick}>
          <Icon name="sidebar" />
        </Menu.Item>
        {loginSignupLogoutMenu}
        <MyProgressBar />
      </Menu>
    );
  }
}

export default NavbarMobile;
