/**
 */
import _ from 'lodash';
import React from 'react';
import {Image, Label} from 'semantic-ui-react';
import {log} from 'concierge-common';

class RibbonContainer extends React.Component {

  render() {
    log.trace("Ribbons.render() this.props:", this.props);
    const props = this.props;
    const {ribbons} = props;

    if (ribbons == null || ribbons.length < 1) {
      return null;
    }

    // NOTE: I don't use the key={index}.  It is just there
    // to stop React from complaining about not have a key property
    // set in an array of components.
    return(
      <Image className="ribbon-container">
        {_.map(ribbons, (ribbon, index) => {
            const clickable = ribbon.onClick ?
              "clickable" : undefined;

            // NOTE: ribbon object is either a set of properties for
            // a Label, or it is a component used to display 
            // a private/protected/public icon.
            return ribbon.component ||
              <Label {...ribbon} key={index} className={clickable} />
        })}
      </Image>
    );
  };
}

export default RibbonContainer;
