import {WAYS, ACTS, ITNS, USERS} from '../global-constants';
import {PENDING, FLAGGED, APPROVED} from '../global-constants';


/**
 * This is the state of the UI.  E.g. whether the sidebar is shown, what
 * menu items are displayed, whether a loading screen is shown, and so
 * forth.
 *
 * The concierge client application's store state.ui property is one of
 * these objects.
 */
class UI {

  /*
  static get RIGHT_ITEMS_ANONYMOUS() { return ["signUp", "signIn"]; };
  static get RIGHT_ITEMS_SIGNED_IN() { return ["signOut"]; };
  static get RIGHT_ITEMS_SIGNING_UP() { return ["signIn"]; };
  static get RIGHT_ITEMS_SIGNING_IN() { return ["signUp"]; };
  */
  static get RIGHT_ITEMS_ANONYMOUS() { return []; };
  static get RIGHT_ITEMS_SIGNED_IN() { return []; };
  static get RIGHT_ITEMS_SIGNING_UP() { return []; };
  static get RIGHT_ITEMS_SIGNING_IN() { return []; };

  static get DB_STATE_STARTING() { return "dbStarting"; };
  static get DB_STATE_STARTED() { return "dbStarted"; };
  static get DB_STATE_STOPPING() { return "dbStopping"; };
  static get DB_STATE_STOPPED() { return "dbStopped"; };

  static get LEFT_ITEMS_ANONYMOUS() {
    return["home"/*, "contact", "about"*/]
  };
  //static get LEFT_ITEMS_SIGNED_IN() {
  //  const items = ["home", "favorites", "itns", "acts", "ways", "curUser",
  //    "users", /*"contact", "about"*/ "viewMessages"];
  //  return items;
  //};
  static getLeftItemsSignedIn(curUserObj) {
    const items = ["home", "itns", "acts", "ways", "curUser",
      "users", /*"contact", "about"*/];
    if (curUserObj && curUserObj.favorites &&
        Array.isArray(curUserObj.favorites) &&
        curUserObj.favorites.length > 0) {
      // Insert "favorites" into list.
      items.splice(1,0,"favorites");
    }
    if (curUserObj && curUserObj.favorites &&
        Array.isArray(curUserObj.favorites) &&
        curUserObj.messages.length > 0) {
      // Insert "viewMessages" into list.
      items.push("viewMessages");
    }
    return items;
  };

  static get DEFAULT_PROPS() {
    return ({
      dbState:UI.DB_STATE_STOPPED,

      device:{
        touch:false,  // HTML classes: touch, no-touch
        hover:false,  // HTML classes: hover, no-hover
      },

      // NOTE: This is not the same as whether or not we are on
      // a touch device.  If the browser is narrow, we show the
      // mobile UI.  It should always reflext the value that
      // components/shell.js sets ShellContents shellType to.
      shellType:"shell-desktop",

      // TODO: name should no longer be used.  I SHOULD only be
      // using the props property to temporarily save the image
      // file being cropped/uploaded.  Refactor this to perhaps
      // just be "imageFile".
      shellContents: {
        name:"anonymous",  // anonymous, signUp, signIn, signedIn, signedOut
        props:{},  // Optional extra data such as: file, title, userId.
      },
      navbar:{
        leftItems:UI.LEFT_ITEMS_ANONYMOUS,
        rightItems:UI.RIGHT_ITEMS_ANONYMOUS,
      },
      sidebar:{
        visible:false,
        // TODO: Get rid of duplication of navbar:leftItems data.
        // I.e. this is a copy of the same information that is in
        // navbar:leftItems.  Refactor navbar/sidebar/actionheader?
        items:UI.LEFT_ITEMS_ANONYMOUS
      },
      // NOTE: This is the initial value for filtering and sorting
      // used by the LocalDB when it initializes itself.
      filterAndSort:{
        visible:false,
        users:{
          collectionName:USERS,
          filter:{
            //roles:["admin", "root", "guide", "traveler"],
            roles:[],
            //statusAttribute:[PENDING, FLAGGED, APPROVED],
            statusAttribute:[],
          },
          sort:{
            //displayName:"asc",
            created:"desc",
          },
        },
        ways:{
          collectionName:WAYS,
          filter:{
            //statusAttribute:[PENDING, FLAGGED, APPROVED],
            statusAttribute:[],
          },
          sort:{
            //displayName:"asc",
            created:"desc",
          },
        },
        acts:{
          collectionName:ACTS,
          filter:{
            //statusAttribute:[PENDING, FLAGGED, APPROVED],
            statusAttribute:[],
          },
          sort:{
            //displayName:"asc",
            //expires:"desc",
            created:"desc",
          },
        },
        itns:{
          collectionName:ITNS,
          filter:{
            //statusAttribute:[PENDING, FLAGGED, APPROVED],
            statusAttribute:[],
          },
          sort:{
            //displayName:"asc",
            //expires:"desc",
            created:"desc",
          },
        },
        anys:{
          collectionName:"anys",
          filter:{
            //roles:["admin", "root", "guide", "traveler"],
            roles:[],
            //statusAttribute:[PENDING, FLAGGED, APPROVED],
            statusAttribute:[],
            // Probably want to filter by owner, lastEdited, etc.
          },
          sort:{
            //displayName:"asc",
            //expires:"desc",
            created:"desc",
          },
        },
        usedBy:{
          collectionName:"usedBy",
          filter:{
            //roles:["admin", "root", "guide", "traveler"],
            roles:[],
            //statusAttribute:[PENDING, FLAGGED, APPROVED],
            statusAttribute:[],
            // Probably want to filter by owner, lastEdited, etc.
          },
          sort:{
            //displayName:"asc",
            //expires:"desc",
            created:"desc",
          },
        }
      },
      footer:{
        visible:true
      },
      multiAction:{
        // This is the info needed when the MultiAction component
        // is displayed.
        actions:null
      },
      confirmation:{
        // This is the info needed when the Confirmation Modal
        // component is displayed.
        title:"Confirmation",
        message1:null,
        message2:null,
        okAction:null,
        okLabel:null,
        okDisabled:true,
        cancelAction:null,
        cancelLabel:null,
      },
      propsEditor:{
        // This is the PropsEditor Modal dialog.
        title:"Properties Editor",
        propsToEdit:null,
        dialogProps:{},
      },
      loader:{
        visible:false,
        message:"Loading..."
        // Add some sort of id or ownerSelector property so we can
        // make just one component busy.
      },
      progressBar:{
        timer:null,
      },
    })
  };

  constructor(props) {
    //super(props);
    Object.assign(this, UI.DEFAULT_PROPS);

    // Override default props with passed in props.
    Object.assign(this, props);
  }
}

export default UI;
