// This module defines the NavbarMobile *container*.
// NOT the NavbarMobile *component*.

import {connect} from 'react-redux';

// Import the NavbarMobileComponent from which this
// NavbarMobileContainer is created.
import NavbarMobileComponent from '../components/navbar-mobile';

import {cA_History} from '../actions/ui';
import {cA_SetShellType} from '../actions/ui';
//import {cA_SignOut} from '../actions/user';

function mapStateToProps(state) {
  return({
    curUser:state.curUser,
    navbar:state.ui.navbar,
  });
}

const mapDispatchToProps = {cA_History, cA_SetShellType};

const NavbarMobileContainer = connect(mapStateToProps, mapDispatchToProps)(NavbarMobileComponent);

export default NavbarMobileContainer;
