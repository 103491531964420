// This module defines the ImageUploadPanel *container*.
// NOT the ImageUploadPanel *component*.
import {connect} from 'react-redux';

// Import the ImageUploadPanelComponent from which
// this ImageUploadPanelContainer is created.
import ImageUploadPanelComponent from '../components/image-upload-panel';

// Import action creators that this component will use.
import {cA_SetError} from '../actions/error';
import {cA_SetShellContentsProps} from '../actions/ui';
import {cA_SetLoader} from '../actions/ui';
import {cA_History} from '../actions/ui';

function mapStateToProps(state) {

  const props = {
    // state.ui.device.touch === true if we are a touch device.
    // state.ui.device.hover === true if we have a mouse.
    device:state.ui.device,
  };

  return props;
}
const mapDispatchToProps = {cA_SetError, cA_SetLoader,
  cA_SetShellContentsProps, cA_History};
const ImageUploadPanelContainer = connect(mapStateToProps, mapDispatchToProps)(ImageUploadPanelComponent);
export default ImageUploadPanelContainer;
