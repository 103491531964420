//'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function () {
  var lastKnownScrollY = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 0;
  var currentScrollY = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 0;
  var props = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {};
  var state = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : {};

  var scrollDirection = currentScrollY >= lastKnownScrollY ? 'down' : 'up';
  var distanceScrolled = Math.abs(currentScrollY - lastKnownScrollY);

  // We're disabled
  if (props.disable) {
    return {
      action: 'none',
      scrollDirection: scrollDirection,
      distanceScrolled: distanceScrolled
      // We're at the top and not fixed yet.
    };
  } else if (currentScrollY <= props.pinStart && state.state !== 'unfixed') {
    return {
      action: 'unfix',
      scrollDirection: scrollDirection,
      distanceScrolled: distanceScrolled
      // We're unfixed and headed down. Carry on.
    };
  } else if (currentScrollY <= state.height && scrollDirection === 'down' && state.state === 'unfixed') {
    return {
      action: 'none',
      scrollDirection: scrollDirection,
      distanceScrolled: distanceScrolled
      // We're past the header and scrolling down.
      // We transition to "unpinned" if necessary.
    };
  } else if (scrollDirection === 'down' && ['pinned', 'unfixed'].indexOf(state.state) >= 0 && currentScrollY > state.height + props.pinStart && distanceScrolled > props.downTolerance) {
    return {
      action: 'unpin',
      scrollDirection: scrollDirection,
      distanceScrolled: distanceScrolled
      // We're scrolling up, we transition to "pinned"
    };
  } else if (scrollDirection === 'up' && distanceScrolled > props.upTolerance && ['pinned', 'unfixed'].indexOf(state.state) < 0) {
    return {
      action: 'pin',
      scrollDirection: scrollDirection,
      distanceScrolled: distanceScrolled
      // We're scrolling up, and inside the header.
      // We transition to pin regardless of upTolerance
    };
  } else if (scrollDirection === 'up' && currentScrollY <= state.height && ['pinned', 'unfixed'].indexOf(state.state) < 0) {
    return {
      action: 'pin',
      scrollDirection: scrollDirection,
      distanceScrolled: distanceScrolled
    };
  } else {
    return {
      action: 'none',
      scrollDirection: scrollDirection,
      distanceScrolled: distanceScrolled
    };
  }
};
