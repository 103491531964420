/**
 */
import React from 'react';
import ProgressBar from 'react-progress-bar-plus';
import {log} from 'concierge-common';

class MyProgressBar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      running:false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.running && nextState.running) {
      return false;
    }

    // Just let the progress bar's animation of the
    // bar do the work.
    return true;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const timer = nextProps.progressBar.timer;
    const running = !!timer;
    const finishing = this.props.progressBar.timer && !timer;

    const newState = {
      running,
      finishing,
    }
    this.setState(newState);
  }

  render() {
    log.trace("MyProgressBar.render() props:", this.props);
    //const {props} = this;
    const {running, finishing} = this.state;
    
    let percent;
    const autoIncrement = true;
    if (running) {
      // Start the bar at some "small" percentage so it is
      // visible to the user.
      percent = 10;
    }
    else if (finishing) {
      // We "finish" by rapidly moving the progress to 100%.
      percent = 100;
    }
    else {
      // Don't show progressBar at all.
      percent = -1;
    }
    const progressBarProps = {
      percent,
      autoIncrement,
    };

    log.trace("progressBarProps:", progressBarProps);

    return <ProgressBar {...progressBarProps} />
  };
}

export default MyProgressBar;
