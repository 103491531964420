import React from 'react';
//import {Container, Header} from 'semantic-ui-react';
import {log} from 'concierge-common';


class Selector extends React.Component {

  handleClickCapture(e) {
    log.trace("Selector.handleClickCapture(), e:", e);
    e.stopPropagation();
    const selected = !this.state.selected;
    this.setState({selected});
    const {onSelect, selectCallbackData} = this.props;
    if (onSelect) {
      onSelect(selected, selectCallbackData);
    }
  }

  constructor(props) {
    super(props);
    const selected = props.selected ? !!props.selected : false;
    this.state = {selected};
  }

  render() {
    const {props} = this;
    log.trace("Selector.render() props:", props);
    const {selected} = this.state;
    log.trace("this.state:", this.state);

    let className = "selector";
    if (selected) {
      className += " selected";
    }

    return (
      <div className={className}
        onClickCapture={this.handleClickCapture.bind(this)}>
        {props.children}
        <div className="checkmark" />
      </div>
    );
  }
}

export default Selector;
