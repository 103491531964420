/**
 */
import React from 'react';
import {Button} from 'semantic-ui-react';
import {log} from 'concierge-common';

class MyLocation extends React.Component {

  /**
   * Display a map showing the object's locationPoint.
   */
  handleClick = () => {
    const {props} = this;
    log.trace("MyLocation.handleClick(), props:", props);
    const {cA_History, label, objId} = props;
    const headerTitle = label ? label : "Location";

    const url = "/locationMap?objId="+objId+
      "&objPropName=locationPoint"+
      "&headerTitle="+headerTitle;
    log.trace("url:", url);
    cA_History("push", url);
  }

  render() {
    log.trace("MyLocation.render() props:", this.props);
    const {props} = this;
    const {label} = props;

    const mapButton =
      <Button basic primary
        className="superbasic"
        onClick={this.handleClick}>
        {label}
      </Button>

    //<Label basic className="superbasic">{label}</Label>
    const component =
      <div className="my-location margin-vert">
        {mapButton}
      </div>

    return component;
  };
}

export default MyLocation;
