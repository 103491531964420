
/**
 * The "globals" collection should only contain one "global" document in
 * it.  It has settings such as what the next default user image will be.
 */
class GlobalSchema {
  static schema = {
    title:"Global Schema",
    description:"Global values used by the entire application",
    version:0,
    type:"object",
    properties:{
      _id:{
        primary:true,
        type:"string",
      },
      nextUserImageIndex:{
        type:"integer",
        default:0,  // Set to the count of existing User objects later.
      },
      nextWayImageIndex:{
        type:"integer",
        default:0,  // Set to the count of existing Way objects later.
      },
      nextActImageIndex:{
        type:"integer",
        default:0,  // Set to the count of existing Act objects later.
      },
      /*
      showTestData:{
        type:"boolean",
        default:true,  // Can be overridden by user setting.
      },
      theme:{
        type:"boolean",
        default:true,  // Can be overridden by user setting.
      },
      */
    },  // End of schema.properties

    required: ["_id"],
    additionalProperties:false,
    //indexes:["displayName", "access.status.[]"],
    //indexes:["displayName", "attributes.[]"],
    //"encrypted": ["someProperty"],
    //"attachments": {
    //  "encrypted": true
    //},
  };
};


export default GlobalSchema;
