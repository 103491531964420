// TODO: Change to only import functions/classes I need from rxdb.
import * as RxDB from 'rxdb';
import _ from 'lodash';

import PouchDB from 'pouchdb-core';
import PouchDBAuthentication from 'pouchdb-authentication';
import PouchDBAdapterIdb from 'pouchdb-adapter-idb';
import PouchDBAdapterHttp from 'pouchdb-adapter-http';

import {log, MyError, ImageUtils} from 'concierge-common';
import store from '../store';
import {GLOBALS, USERS, WAYS, ACTS, ITNS} from '../global-constants';
import {FLAGGED, PENDING, APPROVED} from '../global-constants';
import {DEFAULT_CREATED} from '../global-constants';
import {cA_SetDBState} from '../actions/ui';
import {cA_UpdateCache} from '../actions/cache';
import UI from '../utils/ui-state';
import * as ACTION_TYPES from '../actions/types';
import {cA_SetError} from '../actions/error';
import GlobalSchema from './schemas/global-schema';
//import UserSchema from './schemas/user-schema';
import ObjSchema from './schemas/obj-schema';
import ChangeHandlers from './change-handlers';
import Auth0Provider from './auth0-provider';
import ObjC from './api/obj';
import UserC from './api/user';
import ObjUtils from './api/obj-utils';
import ApiUtils from './api/api-utils';
import Migration from './migration';

//require('events').EventEmitter.prototype._maxListeners = 20;
require('events').EventEmitter.defaultMaxListeners = 20;

// Add the RxDB to PouchDB adapter.
//RxDB.addRxPlugin(require('pouchdb-adapter-indexeddb'));
//RxDB.addRxPlugin(require('pouchdb-adapter-idb'));
//RxDB.addRxPlugin(require('pouchdb-adapter-http'));
//RxDB.addRxPlugin(pouchdbAuthentication);

// NOTE: PouchDBAdapterIdb plugin needs to be added before call
// createRxDatabase() or createRxDatabase() throws an error.
//
// NOTE: addRxPlugin() eventually calls PouchDB.plugin().  Not
// sure what else it is doing.  Perhaps they have the concept
// of Rx specific plugins?
//
const useRxPluginFunc = true;  // Doesn't seem to make a difference.
if (useRxPluginFunc) {
  RxDB.addRxPlugin(PouchDBAuthentication);
  RxDB.addRxPlugin(PouchDBAdapterIdb);
  RxDB.addRxPlugin(PouchDBAdapterHttp);
}
else {
  PouchDB.plugin(PouchDBAuthentication);
  PouchDB.plugin(PouchDBAdapterIdb);
  PouchDB.plugin(PouchDBAdapterHttp);
}

// All our subscriptions.
const globalSubs = [];

log.info("process.env.REACT_APP_SYNC_URL: ", process.env.REACT_APP_SYNC_URL);

class LocalDB {

  static SYNC_URL = process.env.REACT_APP_SYNC_URL ?
    //process.env.REACT_APP_SYNC_URL : "http://localhost:5984/";  // Default to local.
    process.env.REACT_APP_SYNC_URL :
    "https://4bf95f56-56a8-430e-84cf-0c9135b58480-bluemix.cloudantnosqldb.appdomain.cloud/";

  static SYNC_URL_USER = process.env.REACT_APP_SYNC_URL_USER ?
    //process.env.REACT_APP_SYNC_URL_USER : "admin";  // Default to local.
    process.env.REACT_APP_SYNC_URL_USER :
    "4bf95f56-56a8-430e-84cf-0c9135b58480-bluemix";

  static SYNC_URL_PASS = process.env.REACT_APP_SYNC_URL_PASS ?
    //process.env.REACT_APP_SYNC_URL_PASS : "admin";  // Default to local.
    process.env.REACT_APP_SYNC_URL_PASS :
    "9e1ca0d9a4182790b9842287665f4f126291d06365ecf735ec02651eef260ff0";

  // This string makes up part of the name of the database.
  // For example, if DB_NAME="AAA", in Chrome browser the
  // "ways" collection might be called:
  // _pouch_AAA-rxdb-0-ways-mrview-f5dfae34582932423409f
  //
  static DB_NAME = "testdb41";

  // Remove these eventually.
  // Values in global-constants should be used instead.
  static GLOBALS = "globals";
  static USERS = "users";
  static WAYS = "ways";
  static ACTS = "acts";
  static ITNS = "itns";

  // Unlike users, ways, acts, itns, there is only a single
  // RxDocument in the globals collection.  This is its ID.
  static GLOBAL_ID = 'theOneAndOnlyGlobalDoc';

  // NOTE: syncCount relies on the number of properties in
  // this object.
  static syncTimers = {
    [GLOBALS]:false,  // Set to timer ID later.
    [USERS]:false,
    [WAYS]:false,
    [ACTS]:false,
    [ITNS]:false,
  };

  //static #db = null;
  static db = null;

  // NOTE: These get accessed via LocalDB[replicationStateName]
  /*
  static replicationStateGlobals = null;
  static replicationStateUsers = null;
  static replicationStateWays = null;
  static replicationStateActs = null;

  static REPLICATION_STATE_NAMES = {
    globals:"replicationStateGlobals",
    users:"replicationStateUsers",
    ways:"replicationStateWays",
  };
  */
  static replicationStates = {
    [GLOBALS]:null,
    [USERS]:null,
    [WAYS]:null,
    [ACTS]:null,
    [ITNS]:null,
  }

  // Our subscriptions.
  //static subs = [];
  static subs = globalSubs;

  // These values are overwritten by the values in the "global"
  // document in the "globals" collection.
  static nextUserImageIndex = 0;
  static nextWayImageIndex = 0;
  static nextActImageIndex = 0;

  /**
   * Set the values in store.state.users, store.state.ways...
   * TODO: Put LocalDB actions into their own file?
   */
  static cA_SetObjs(objIds, findParam, sortParam, collectionName) {
    let type;
    switch(collectionName) {
      case USERS:
        type = ACTION_TYPES.SET_USERS;
        log.trace("SET_USERS, objIds: ", objIds);
        break;
      case WAYS:
        type = ACTION_TYPES.SET_WAYS;
        break;
      case ACTS:
        type = ACTION_TYPES.SET_ACTS;
        break;
      case ITNS:
        type = ACTION_TYPES.SET_ITNS;
        break;
      default:
        log.bug("LocalDB.cA_SetObjs(), unhandled collectionName("+
          collectionName+")");
        debugger;
    }
    const payload = {
      objIds,
      findParam,
      sortParam,
    };
    return({
      type,
      payload,
    });
  }

  /**
   * Remove all conflicts for the specified object.
   *
   * @param collectionName A value like "users", "ways", "globals"
   *
   * @param objId The _id of the object (e.g. someUser._id, someWay._id)
   * whose conflicts we want to delete.
   */
  static async removeConflictsObj(collectionName, objId) {
    log.trace("removeConflictsObj("+collectionName+", "+objId+")");
    const replicationState = LocalDB.replicationStates[collectionName];

    const pouchDB = replicationState.collection.pouch;
    const pouchObj = await pouchDB.get(objId, {conflicts: true});
    log.trace("pouchObj: ", pouchObj);
    if (pouchObj._conflicts) {
      await Promise.all(pouchObj._conflicts.map(async (rev) => {
        const deletedPouchObj = await pouchDB.remove(objId, rev);
        log.trace("deletedPouchObj: ", deletedPouchObj);
      }));
    }
  }

  /**
   * Remove all conflicts for all objects in the collection.
   * NOTE: This will be slow for large databases, so we should
   * create a view/index that only has conflicts in it.
   *
   * @param collectionName A value like "users", "ways", "globals".
   */
  static async removeConflictsCollection(collectionName) {
    const replicationState = LocalDB.replicationStates[collectionName];

    const pouchDB = replicationState.collection.pouch;
    /* This works, but pulls in the entire collection data.
    // NOTE: Setting conflicts:true does not return _conflict data
    // unless include_docs:true also.
    const allDocs = await pouchDB.allDocs({include_docs:true, conflicts:true});
    await Promise.all(allDocs.rows.map(async (doc) => {
      log.trace("doc: ", doc);
      const conflicts = doc.doc._conflicts;
      log.trace("conflicts: ", conflicts);
      if (conflicts) {
        await Promise.all(conflicts.map(async (rev) => {
          log.trace("pouchDB.remove("+doc.id+", "+rev+")");
          const deletedPouchObj = await pouchDB.remove(doc.id, rev);
          log.trace("deletedPouchObj: ", deletedPouchObj);
        }));
      }
    }));
    */
    // Get all doc ids, then get each document one at a time and
    // deal with each document's conflicts.
    const allDocs = await pouchDB.allDocs();
    await Promise.all(allDocs.rows.map(async (doc) => {
      log.trace("doc: ", doc);
      // NOTE: doc.id but pouchObj._id
      const pouchObj = await pouchDB.get(doc.id, {conflicts:true});
      log.trace("pouchObj: ", pouchObj);
      const conflicts = pouchObj._conflicts;
      log.trace("conflicts: ", conflicts);
      if (conflicts) {
        await Promise.all(conflicts.map(async (rev) => {
          log.trace("pouchDB.remove("+pouchObj._id+", "+rev+")");
          const deletedPouchObj = await pouchDB.remove(pouchObj._id, rev);
          log.trace("deletedPouchObj: ", deletedPouchObj);
        }));
      }
    }));
  }

  static creationParams = {
    name: LocalDB.DB_NAME,
    //adapter: 'indexeddb',
    adapter: 'idb',
    //password: 'passwordAtLeast12CharsLong', // optional
    multiInstance: true,  // <- multiInstance (optional, default: true)

    // TODO: Set this to true.  false is for debugging.
    // eventReduce (optional, default: true)
    eventReduce: true,

    // queryChangeDetection (optional, default: false
    queryChangeDetection: true,

    // If true, this call to createRxDatabase will not throw an
    // error because we explicitly allow it. default: false
    ignoreDuplicate: false,

    // I don't think auto_compaction is used here, but I am putting
    // it here instead of pouchSettings to see if it makes a difference.
    //auto_compaction:true,  // has no effect as far as I can tell.
    pouchSettings: {
      auto_compaction:true,  // default is false
      //revs_limit:1,

      // auth.username + auth.password:
      // You can specify HTTP auth parameters either by using a
      // database with a name in the form http://user:pass@host/name
      // or via the auth.username + auth.password options.
      auth: {
        username: LocalDB.SYNC_URL_USER,
        password: LocalDB.SYNC_URL_PASS,
      },

      // Initially PouchDB checks if the database exists,
      // and tries to create it if it does not exist yet.
      // Set this to true to skip this setup.
      //skip_setup: false,

      //fetch: function (url, opts) {
      //  opts.headers.set('X-Some-Special-Header', 'foo');
      //  return PouchDB.fetch(url, opts);
      //}
    },
  };

  static async createDB() {
    log.trace("Enter LocalDB.createDB()");

    let ok = await RxDB.checkAdapter('idb');
    log.trace("RxDB.checkAdapter('idb') = "+ok);
    ok = await RxDB.checkAdapter('indexeddb');
    log.trace("RxDB.checkAdapter('indexeddb') = "+ok);

    if (RxDB.isRxDatabase(LocalDB.db)) {
      // Database already exists.
      // NOTE: We could remove it and recreate it.
      //await RxDB.removeRxDatabase('testdb1', 'idb');
    }
    else {
      // Create the DB.
      try {
        LocalDB.db = await RxDB.createRxDatabase(LocalDB.creationParams);
      }
      catch(err) {
        log.bug(err);
      }
    };
  }

  /**
   * Once the initial sync() with the remote CouchDB has completed,
   * connect with the remote CouchDB on a "live" basis so future
   * changes in the remote CouchDB or in our local RxDB/PouchDB sync
   * immediately.
   */
  static async handleSyncCompleted(completed, collectionName) {
    log.info("Enter handleSyncCompleted("+collectionName+")");
    log.info("Enter handleSyncCompleted(), completed: ", completed);
    if (!collectionName) {
      log.bug("In handleSyncCompleted(), collectionName: ", collectionName);
      debugger;
    }
    if (!completed) {
      // Sync has not yet completed.  We will be called again eventually.
      log.info("Sync not yet completed.  We will be called again.");
      return;
    }
    else if (completed === true) {
      log.info(collectionName+" timer expired before sync completed, so just continue init.");
      // This is not a "real" completed.
      // (completed === true instead of being an object.)
      // The timer expired, either because we couldn't connect
      // or because the app is open in another tab, so we will
      // carry on without doing the initial sync.
      if (collectionName == "users") {
        const msg = "We were not able to connect and get the latest data, "+
          "so we will be using the data from your most recent session.  "+
          "(Or you have Jasiri open in more than one tab.)";
        const props = {msg, severity:MyError.WARNING, dismissAfter:15000};
        const error = MyError.createSubmitError(props);
        store.dispatch(cA_SetError(error));
      }
    }
    else if (completed.push || completed.pull) {
      log.info(collectionName+" sync successfully completed.");
      // The value of completed is an object, so this
      // is a "real" completed.
      if (collectionName == "users") {
        const msg = "We were able to successfully connect and get "+
          "the latest data.";
        const props = {msg, severity:MyError.INFO};
        const error = MyError.createSubmitError(props);
        store.dispatch(cA_SetError(error));
      }
    }

    if (LocalDB.syncTimers[collectionName]) {
      // Clear the unexpired timer that was started elsewhere.
      // The normal case will be that we were able to connect to the
      // remote CouchDB and complete the sync.
      log.trace(collectionName+"clear timer");
      clearTimeout(LocalDB.syncTimers[collectionName]);
      LocalDB.syncTimers[collectionName] = false;
    }

    // Now that we are initialized with the current state of
    // the remote CouchDB, sync with it continuously.
    const live = true;
    LocalDB.replicationStates[collectionName] = await LocalDB.connectRemoteDB(
      collectionName, live);

    // Remove conflicts.
    await LocalDB.removeConflictsCollection(collectionName);

    // After all syncs have completed, call startDBFinish() to
    // finish intialization.
    //
    // NOTE: we can also be called because sync did not complete
    // before a timer ran out.  I.e. we are giving up trying to
    // sync, and will just run locally.
    //
    LocalDB.syncCount--;  // globals, users, ways.
    log.trace("LocalDB.syncCount: ", LocalDB.syncCount);
    if (LocalDB.syncCount === 0 &&
        store.getState().ui.dbState != UI.DB_STATE_STARTED) {
      await LocalDB.startDBFinish();
    }
  }

  static setStoreStateReplication(completed) {
    if (!completed) {
      // Sync has not yet completed.  We will be called again eventually.
      return;
    }
    // completed will be an object with props like completed.push
    // and completed.pull.
    const payload = {
      completed:Date.now(),
    };
    const action = {
      type:ACTION_TYPES.SET_REPLICATION,
      payload,
    };
    store.dispatch(action);
  }

  // TODO: This is a hack to fix problem with cached quiries not
  // being returned properly.
  /*
  static cacheReplacementPolicy(rxCollection, queryCache) {
    log.trace("queryCache: ", queryCache);
    log.trace("queryCache._map.size: ", queryCache._map.size);
    queryCache._map.forEach(query => {
      log.trace("query: ", query);
    });
    queryCache._map.clear();
  }
  */

  /**
   * Create the local RxDB/PouchDB collections.  This might mean
   * we are creating them local browser DB, or simply connecting
   * to it.
   *
   * Then connect to the remote CouchDB and sync() our local DB
   * with the remote DB.  This is a one-time live:false sync.
   *
   * @param collectionName The collectionName of the collection.
   * E.g. "globals", "users", "ways".
   *
   * @param schema The schema of the collection.  E.g. GlobalSchema.schema
   */
  static async createAndInitCollection(collectionName, schema,
    migrationStrategies = {}) {
    log.info("Enter createAndInitCollection("+collectionName+")");
    if (!collectionName) {
      log.bug("In createAndInitCollection(), collectionName: ",
        collectionName);
      debugger;
    }

    // Create collection with the passed in collectionName.
    await LocalDB.db.collection({
      name:collectionName,
      schema,
      migrationStrategies,
      //cacheReplacementPolicy:LocalDB.cacheReplacementPolicy,
    });

    log.trace("collectionName: ", LocalDB.db[collectionName]);
    log.trace("Migrating old collection version.");
    if (collectionName == WAYS || collectionName == ACTS ||
        collectionName == ITNS || collectionName == USERS) {
      await Migration.migrateCollection(LocalDB.db[collectionName],
        migrationStrategies);
    }

    // Sync collection with remote CouchDB.
    // This might be the first time ever this browser
    // is doing this, or the sync might happen
    // quickly because we have synced before.
    // Pass false for the "live" paramater because we
    // need to know when this initial sync has finished.
    // Later, in the handleSyncCompleted function we will set
    // "live" to true in order to stay continually synced.
    //
    const live = false;
    let replicationState;
    try {
      replicationState = await LocalDB.connectRemoteDB(collectionName, live);
      LocalDB.replicationStates[collectionName] = replicationState;
    }
    catch(err) {
      log.warning("LocalDB.createAndInitCollection("+collectionName+
        ") call to LocalDB.connectRemoteDB() threw exception: ", err);
    }

    // Subscribe to the "complete" event so that when the initial
    // sync completes, we will sync on a continuous basis from then on.
    try {
      if (replicationState) {
        replicationState.complete$.subscribe(completed => 
          LocalDB.handleSyncCompleted(completed, collectionName));
      }
    }
    catch(err) {
      log.warning("LocalDB.createAndInitCollection("+collectionName+
        ") call to replicationState.complete$.subscribe() threw exception: ",
        err);
    }

    // Set a timer so we will give up trying to sync with the
    // remote CouchDB after 10 or 20 seconds, and just run using
    // the local data.
    LocalDB.syncTimers[collectionName] = setTimeout(() => {
      if (!collectionName) {
        debugger;
      }
      log.trace(collectionName+"timer timed out");
      log.info("Unable to sync "+collectionName+" before timer expired.");
      LocalDB.syncTimers[collectionName] = false;
      LocalDB.handleSyncCompleted(true, collectionName);
    }, 10000);

    //replicationState.cancel();

    //return replicationState;  // Caller does not use this.
  }

  /**
   * This function is called twice for each collection.  The first
   * time it is called with live:false to get the initial database
   * state.  The second time it is called with live:true to stay
   * synced with the database.
   *
   * @param name The name of the collection.  E.g. "globals", "users".
   *
   * @param live Pass false if we just want to sync to the remote
   * CouchDB's current state.  Pass true if we want to be continuously
   * staying in sync.
   */
  static async connectRemoteDB(collectionName, live) {
    log.info("Enter connectRemoteDB("+collectionName+", "+live+")");
    if (live) {
      log.info("Sync "+collectionName+" continuously from now on.");
    }
    
    // Set up replication to CouchDB.
    const remoteDB = await LocalDB.createAndLoginToRemoteDB(collectionName);
    log.info(collectionName+" remoteDB: ", remoteDB);

    const replicationState = await LocalDB.db[collectionName].sync({
      remote: remoteDB,
      //remote:LocalDB.SYNC_URL+collectionName+"/",

      // (optional) [default=true] to help performance,
      // the sync starts on leader-instance only
      //waitForLeadership: true,
      // TODO: If I set this to true, then the second page's DB
      // initialization will not "completed" the sync because the
      // other web page is running it live.  How can I have only
      // one page do the sync?
      waitForLeadership: true,

      // direction (optional) to specify sync-directions
      direction: {
        pull: true, // default=true
        push: true  // default=true
      },
      // sync-options (optional) from https://pouchdb.com/api.html#replication
      options: {
        live,
        retry: true, // Only applies if live is true.
        back_off_function:(delay) => {
          if (delay === 0) {
            return 1000;
          }
          if (delay < (10 * 60 * 1000)) {
            return delay * 2;
          }
          else {
            return delay;
          }
        },
        // auth.username + auth.password:
        // You can specify HTTP auth parameters either by using a
        // database with a name in the form http://user:pass@host/name
        // or via the auth.username + auth.password options.
        auth: {
          username: LocalDB.SYNC_URL_USER,
          password: LocalDB.SYNC_URL_PASS,
        },
        //fetch: (url, opts) => {
        //  opts.headers.set("X-Auth-CouchDB-UserName","test")
        //  opts.headers.set("X-Auth-CouchDB-Token","token")
        //  opts.headers.set("X-Auth-CouchDB-Roles","couchroles");
        //  return fetch(url, opts);
        //},
      },
      // query (optional) only documents that match that query will
      // be synchronised
      //query: myCollection.find().where('age').gt(18)
      //pouchSettings:{},  // I am not sure if these are the same as options.
      //migrationStrategies:{},
      //autoMigrate:true,  // optional.  Default is true.
      // query (optional) only documents that match that query will
      // be synchronised
      //query: myCollection.find().where('age').gt(18)

      //Statics are Collection-wide and can be called on the collection.
      //Also has access to "this".
      //statics: {
      ////  whoAmI: function(){
      //      return this.name;
      //  }
      //}

      //Instance methods are defined collection-wide.
      //They can be called on the RxDocuments of the collection.
      //methods: {}, // (optional) ORM-functions for documents

      //Instance methods and data.
      //attachments: {}, // (optional) ORM-functions for attachments
    });
    //log.trace("replicationState: ", replicationState);

    log.trace("Returning "+collectionName+" replicationState: ",
      replicationState);
    return replicationState;
  }

  // collectionName = users, ways, globals
  static async createAndLoginToRemoteDB(collectionName) {
    
    // Set up replication to CouchDB.
    const remoteURL = LocalDB.SYNC_URL + collectionName + '/';

    const options = {
      auth: {
        username: LocalDB.SYNC_URL_USER,
        password: LocalDB.SYNC_URL_PASS,
      },
      auto_compaction:true,  // default is false.
      //revs_limit:1,
    };

    let remoteDB;
    try {
      remoteDB = await new PouchDB(remoteURL, options);
      log.trace("remoteDB: ", remoteDB);
    }
    catch(err) {
      log.info("Failed to create PouchDB with remoteURL: ", remoteURL);
      log.info("Returned error: ", err);
      return null;
    }

    if (!remoteDB) {
      log.info("Failed to create remote PouchDB with remoteURL: ", remoteURL);
      log.info("Call to new PouchDB(remoteURL) returned: ", remoteDB);
      return null;
    }

    try {
      const res = await remoteDB.login(LocalDB.SYNC_URL_USER,
        LocalDB.SYNC_URL_PASS);
      log.info("Collection("+collectionName+") login response: ", res);
      // res should be { ok: true, name: 'admin', roles: [ '_admin' ] }
      // NOTE: The "roles" array we get in the res has nothing to do
      // with concierge User.roles.  res.roles is the CouchDB role of the
      // concierge "user" who is using the database.

      //const compactRes = await remoteDB.compact();
      //log.trace("compactRes: ", compactRes);
    }
    catch(err) {
      log.warning("remoteDB.login() threw exception: ", err);
      log.warning("remoteDB: ", remoteDB);
      //return null;
      return remoteDB;
    }
    return remoteDB;
  }

  /**
   * Initialize the DB.  This is the function the React app should call.
   *
   * TODO: Don't export entire LocalDB class.
   * Just export the initDB() function and whatever
   * else is needed.
   */
  static async initDB() {
    log.info("Enter LocalDB.initDB(), LocalDB.db: ", LocalDB.db);
    log.info("LocalDB.SYNC_URL: ", LocalDB.SYNC_URL);
    log.info("LocalDB.SYNC_URL_USER: ", LocalDB.SYNC_URL_USER);
    log.info("LocalDB.SYNC_URL_PASS: ", LocalDB.SYNC_URL_PASS);

    const {dbState} = store.getState().ui;
    log.info("LocalDB.initDB(), dbState: ", dbState);
    if (dbState != UI.DB_STATE_STOPPED) {
      log.info("LocalDB is not stopped.  dbState: ", dbState);
      log.info("I will wait a few seconds and then try again to start it.");
      setTimeout(LocalDB.initDB, 5000);
      return;
    }

    try {
      log.info("LocalDB.initDB() calling LocalDB.startDB()");
      await LocalDB.startDB();
    }
    catch(err) {
      log.info("Call to LocalDB.startDB() threw error: ", err);
    }
  }

  /**
   * Monitor replication state of users collection on
   * remote CouchDB.  (Assume all other collections
   * will have same state.)
   */
  static subscribeReplication() {
    const replicationState = LocalDB.replicationStates[USERS];

    LocalDB.subs.push(replicationState.active$.subscribe(
      active => ChangeHandlers.handleChangeReplicationState({active})));

    LocalDB.subs.push(replicationState.alive$.subscribe(
      alive => ChangeHandlers.handleChangeReplicationState({alive})));

    //LocalDB.subs.push(replicationState.complete$.subscribe(
    //  complete => ChangeHandlers.handleChangeReplicationState({complete})));

    LocalDB.subs.push(replicationState.change$.subscribe(
      change => ChangeHandlers.handleChangeReplicationState({change})));

    LocalDB.subs.push(replicationState.denied$.subscribe(
      denied => ChangeHandlers.handleChangeReplicationState({denied})));

    LocalDB.subs.push(replicationState.error$.subscribe(
      error => ChangeHandlers.handleChangeReplicationState({error})));

    log.trace("subs.length: ", LocalDB.subs.length);
  }

  /**
   * This is called by LocalDB.initDB().
   *
   * TODO: Don't export entire LocalDB class.
   * Just export the initDB() function and whatever
   * else is needed.
   */
  static async startDB() {
    store.dispatch(cA_SetDBState(UI.DB_STATE_STARTING));
    if (!LocalDB.db) {
      // NOTE: I moved these plugin() calls out of the
      // LocalDB class and into the global scope in this
      // file.
      //PouchDB.plugin(pouchdbAuthentication);
      //PouchDB.plugin(PouchDBAdapterIdb);
      //PouchDB.plugin(PouchDBAdapterHttp);

      await LocalDB.createDB();

      // Keep track of how many syncs we start so when all have
      // completed we can carry on with initialization.
      // NOTE: Update this number as we add more collectsions.
      // globals, users, ways, acts, itns = 5
      LocalDB.syncCount = _.size(LocalDB.syncTimers);

      // First do the globals.
      await LocalDB.createAndInitCollection(
        GLOBALS, GlobalSchema.schema,
        Migration.migrationStrategiesGlobals);

      // Next do the users, and then subscribe to the users
      // collection replication state.  I.e. we want to
      // know when our local RxDB/PouchDB copy has finished
      // syning with the remote CouchDB.
      await LocalDB.createAndInitCollection(
        USERS, ObjSchema.schema,//UserSchema.schema,
        Migration.migrationStrategiesObjs);
      await LocalDB.createPermanentUsers();

      // Monitor replication state of users collection on
      // remote CouchDB.  (Assume all other collections
      // will have same state.)
      LocalDB.subscribeReplication();
      LocalDB.replicationStates[USERS].complete$.subscribe(
        completed => LocalDB.setStoreStateReplication(completed));

      // Now do the rest of the collections.  ways, acts, itns.
      await LocalDB.createAndInitCollection(
        WAYS, ObjSchema.schema,
        Migration.migrationStrategiesObjs);
      await LocalDB.createAndInitCollection(
        ACTS, ObjSchema.schema,
        Migration.migrationStrategiesObjs);
      await LocalDB.createAndInitCollection(
        ITNS, ObjSchema.schema,
        Migration.migrationStrategiesObjs);
      // NOTE: If you add more collections, update syncCount above.
    }
    else {
      log.info("LocalDB.db has already been created.");
    }
  }

  static async createPermanentUser(userId, email, displayName,
    imageSrc, roles, details) {
    log.info("Creating permanent user: ", displayName);
    const u = UserC.DEFAULT_USER;
    const propsRxDB = {
       _id:userId,
      //token:u.token,
      email:u.email,
      password:u.password,
      acceptedTerms:true,
      roles,
      created:DEFAULT_CREATED,
      displayName,
      imageSrc,
      avatarSrc:undefined,
      details,
      flags:u.flags,
      adventures:u.adventures,
      favorites:u.favorites,
      recommendations:u.recommendations,
      fellowTravelers:u.fellowTravelers,
      messages:u.messages,
      messagesLastReceived:UserC.DEFAULT_MESSAGES_LAST_RECEIVED,
      messagesLastRead:UserC.DEFAULT_MESSAGES_LAST_READ,
      urls:u.urls,
      tags:u.tags,
      isTestData:false,
      statusAttribute:APPROVED,
      visibility:"isPublic",
      userIds:[],
    };
    log.trace("propsRxDB: ", propsRxDB);
    // NOTE: possibly call ApiUtils.createRxDBObj() instead?
    const userRxDB = await LocalDB.db.users.insert(propsRxDB);
    log.trace("userRxDB: ", userRxDB);
  }

  static async createPermanentUsers() {
    let user;

    user = await LocalDB.db.users.findOne().where("_id").eq(
      UserC.FOSTER_USER_ID).exec();
    log.trace("user: ", user);
    if (!user) {
      await LocalDB.createPermanentUser(UserC.FOSTER_USER_ID,
        "foster@jasiri.com", "Foster Parent",
        "../img/placeholders/user/userFoster.jpg",
        ["traveler", "guide", "admin"],
        'I am the "Foster Parent" of '+
        'Waypoints, Adventures, and Itineraries that are no longer owned '+
        'by their orignal creators or anyone else.  If you see an '+
        'orphaned Waypoint, Adventure, or Itinerary that you '+
        'want to "adopt" and keep up to date with the latest details and '+
        'photos, send me a message and I will put '+
        'you in charge of it!'
        );
    }

    user = await LocalDB.db.users.findOne().where("_id").eq(
      UserC.SUPPORT_USER_ID).exec();
    log.trace("user: ", user);
    if (!user) {
      await LocalDB.createPermanentUser(UserC.SUPPORT_USER_ID,
        "support@jasiri.com", "Jasiri Team",
        "../img/placeholders/user/userSupport.jpg",
        ["traveler", "guide"],
        'We are your Jasiri Team '+
        'who can help you with any questions you might have about '+
        'the Concierge app.'
        );
    }

    user = await LocalDB.db.users.findOne().where("_id").eq(
      UserC.ADMIN_USER_ID).exec();
    log.trace("user: ", user);
    if (!user) {
      await LocalDB.createPermanentUser(UserC.ADMIN_USER_ID,
        "admin@jasiri.com", "Admin",
        "../img/placeholders/user/userAdmin.jpg",
        ["admin"],
        'This is the main administrator account.'
        );
    }

    user = await LocalDB.db.users.findOne().where("_id").eq(
      UserC.ROOT_USER_ID).exec();
    log.trace("user: ", user);
    if (!user) {
      await LocalDB.createPermanentUser(UserC.ROOT_USER_ID,
        "root@jasiri.com", "Root",
        "../img/placeholders/user/userRoot.jpg",
        ["root"],
        'This is the main root account.  Has "root" privileges, '+
        'above "admin" privileges.'
        );
    }
  }

  static async startDBFinish() {
    log.info("Enter startDBFinish().  All initial syncs completed.");
    log.trace("Enter startDBFinish() subs.length: ", LocalDB.subs.length);

    setTimeout(() => log.trace("Timer subs: ", LocalDB.subs), 10000);

    // Unsubscribe to RxDB changes that we subscribed to during
    // the initial sync.  We will subscribe again in this
    // function.
    await LocalDB.unsubscribeAll();

    // TODO: I just commented these two lines out Aug 14, 2020.
    //const curUserObj = await LocalDB.initCurUserObj();
    //await LocalDB.initGlobalsAndStoreState(curUserObj);

    // Our local RxDB/PouchDB collections have been synced with
    // the remote CouchDB.  Initialize the "global" values
    // before we start creating users or anything else.
    await LocalDB.initGlobals();

    // Subscribe to change events in the local RxDB/PouchDB.
    // This is different than subscribing to the "replication"
    // sync() with the remote CouchDB.
    await LocalDB.subscribeToOneAndOnlyGlobal();
    await LocalDB.subscribeToCollection(USERS);
    await LocalDB.subscribeToCollection(WAYS);
    await LocalDB.subscribeToCollection(ACTS);
    await LocalDB.subscribeToCollection(ITNS);

    log.info("startDBFinish() calling cA_SetDBState(UI.DB_STATE_STARTED)");
    store.dispatch(cA_SetDBState(UI.DB_STATE_STARTED));
    log.trace("Exit startDBFinish() subs.length: ", LocalDB.subs.length);
  }

  /**
   * There is only a single "global" document in the "globals"
   * collection, so we don't need the caller to pass in an id
   * parameter.
   */
  static async getGlobalRxDB() {
    const globalRxDB = await LocalDB.db.globals.findOne();
    if (!globalRxDB) {
      log.info("getGlobalRxDB() call to findOne() returned nothing.");
      log.info("This is an error or it is the first time we are being run.");
      return null;
    }
    return globalRxDB;
  }

  static async getUserRxDB(userId) {
    if (!LocalDB.db || !LocalDB.db.users) {
      log.bug("In LocalDB.getUserRxDB(), LocalDB.db not "+
        "properly initialized yet.");
      log.bug("LocalDB.db: ", LocalDB.db);
      log.bug("LocalDB.db.users: ", LocalDB.db && LocalDB.db.users);
      const stack = new Error().stack;
      log.bug("STACK FOLLOWS:");
      log.bug(stack);
      return null;
    }
    const userRxDB =
      await LocalDB.db.users.findOne().where("_id").eq(userId).exec();
    if (!userRxDB) {
      log.info("getUserRxDB() could not find userId: ", userId);
      return null;
    }
    return userRxDB;
  }

  static async initCurUserObj() {
    const curUser = store.getState() && store.getState().curUser;
    log.trace("initCurUserObj(), curUser: ", curUser);
    if (!curUser || !curUser._id || !curUser.token) {
      log.info("No signed in user in store.state.curUser: ", curUser);
      return null;
    }

    const userRxDB =
      await LocalDB.db.users.findOne().where("_id").eq(curUser._id).exec();
    if (!userRxDB) {
      // The user id stored in the browser's local storage did not
      // exist in the RxDB local storage.  This can happen if the
      // database was renamed while the user was signed in, or if the
      // administrator deleted the user's record from the couchDB/pouchDB/
      // RxDB database.
      //
      // So, log the user out.
      // TODO: Also log the user out of Auth0.  Current code leaves Auth0
      // data with the user info in the localStorage.
      const action = {
        type:ACTION_TYPES.SIGN_OUT_S,
        payload:{user:{id_:null, token:null}},
      };
      store.dispatch(action);

      //log.bug("initCurUserObj() could not find curUser._id: ", curUser._id);
      return null;
    }
    const curUserObj = Object.assign(curUser);
    curUserObj.roles = userRxDB.roles;
    const userC = new UserC(userRxDB, curUserObj);
    //store.dispatch({type:ACTION_TYPES.SET_CUR_USER_OBJ, payload:{
    //  props:userC, user:userC, /*users:[userC]*/}});
    return userC;
  }

  static handleChangeGlobals(changeEvent) {
    log.trace("Enter handleChangeGlobals(), changeEvent: ", changeEvent);

    if (changeEvent.operation == "UPDATE" ||
        changeEvent.operation == "INSERT") {
      const globalDoc = changeEvent.rxDocument || changeEvent.documentData;
      LocalDB.nextUserImageIndex = globalDoc.nextUserImageIndex;
      LocalDB.nextWayImageIndex = globalDoc.nextWayImageIndex;
      LocalDB.nextActImageIndex = globalDoc.nextActImageIndex;

      // Remove any conflicts.
      LocalDB.removeConflictsObj("globals", LocalDB.GLOBAL_ID);
    }
  }

  static prevRxQuery;

  /**
   * @param findParam An object giving the selection parameters
   * for the find() call.  We will add to the passed in values.
   *
   * @param sortParam An array giving the field on which to
   * sort and "asc" or "desc" order.  For example:
   * ["displayName", "asc"]
   * ["created", "desc"]
   *
   * NOTE: The findParam must contain the field(s?) used in the
   * the sortParam.
   */
  static async findAndSort(curUserObj, collectionName,
    findParam, sortParam) {
    log.trace("LocalDB.findAndSort(), findParam: ", findParam);
    log.trace("LocalDB.findAndSort(), sortParam: ", sortParam);
    log.trace("pouch: ", LocalDB.db[collectionName].pouch);

    // NOTE: This seems to be a hack.
    // If the user has specified a selector, I need to add
    // that selector in so that I do NOT show the user
    // pending/flagged/approved data if s/he has specified not 
    // to see it.
    // I should have addStatusAttributeSelector look at findParam and
    // adjust the selector it creates if the user is already
    // filtering which statusAttributes s/he wants to see.
    // $and[0] and $and[1] are hard coded to select on displayName
    // and created time simply existing.  We need to do this so
    // and index for those values is built.
    let savedSelector = {};
    if (findParam.selector.$and.length > 2) {
      savedSelector = findParam.selector.$and[2];
    };
    let savedSelector2 = {};
    if (findParam.selector.$and.length > 3) {
      savedSelector2 = findParam.selector.$and[3];
    };

    // NOTE: I don't think the RxDB code makes a copy of these values,
    // so we have to create unique objects that won't get changed later.
    // NOTE: This is not needed as of 30 Aug 2020, due to me changing
    // the way my code works.  Can comment out the lines.
    findParam = _.cloneDeep(findParam);
    sortParam = _.cloneDeep(sortParam);

    // Add the selector.isTestData property to findParam.
    // Whether it is true or false depends on the curUser's
    // settings.
    LocalDB.addIsTestDataSelector(curUserObj, findParam);

    // Don't show PENDING or FLAGGED objects unless curUserObj
    // is an admin.
    LocalDB.addStatusAttributeSelector(curUserObj, findParam);

    // NOTE: I have no idea why this hack is needed.
    // If I don't wrap the $and selector in an $or selector,
    // the query returns docs that have statusAttribute === FLAGGED
    // or PENDING if the value was changed within the UI.
    // Change it out of the UI, and the UI updates fine.
    // RxDB queryCache problem?  (Comment out where we add the
    // owner/creator selector below to see this behavior.)
    //
    // NOTE: I don't know if there is a bug, or I just don't
    // know how to use it, but I seem to have to surround every
    // $and selector with an $or selector in order for the $and
    // selector to return anything.
    // 
    findParam.selector = {$or:[findParam.selector]};

    // Regardless of the statusAttribute, a user should see all
    // the objects they own.  Show PENDING or FLAGGED objects if
    // they are owned by curUserObj.
    //
    // NOTE: We have to handle the case of owner being null
    // ourselves because owner defaulting to creator functionality
    // is part of the Obj class, not part of the RxDB obj schema.
    //
    // NOTE: This selector has the problem of selecting objects
    // regardless of their isTestData setting.  Need to add another
    // $and around it to handle that.
    /*
    findParam.selector.$or.push(
      {$or:[
        {owner:curUserObj._id},
        {$and:[{owner:{$exists:false}}, {creator:curUserObj._id}]}
      ]}
    );
    */
    // NOTE: This is the above selector modified to only get the
    // correct isTestData objects.
    findParam.selector.$or.push(
      {$or:[
        {$and:[
          {owner:curUserObj._id},
          {isTestData:curUserObj.isTestData ? {$eq:true} : {$ne:true}},
          savedSelector, savedSelector2,
        ]},
        {$and:[
          {owner:{$exists:false}},
          {creator:curUserObj._id},
          {isTestData:curUserObj.isTestData ? {$eq:true} : {$ne:true}},
          savedSelector, savedSelector2,
        ]}
      ]}
    );

    log.trace("LocalDB.findAndSort(), final findParam: ", findParam);
    const rxQuery = LocalDB.db[collectionName].find(findParam).sort(sortParam);

    //log.trace("rxQuery: ", rxQuery);
    //log.trace("rxQuery.mangoQuery.selector.roles.$in.length: ",
    //  rxQuery.mangoQuery.selector.roles.$in.length);

    //rxQuery.mangoQuery.selector.roles = findParam.selector.roles;
    //log.trace("rxQuery.mangoQuery.selector.roles: ",
    //  rxQuery.mangoQuery.selector.roles);

    const docs = await rxQuery.exec();
    //log.trace("rxQuery: ", rxQuery);
    //log.trace("docs: ", docs);

    return docs;
  }

  /**
   * Set the findParam's selector to NOT show FLAGGED and
   * PENDING objects if the user is not an admin.
   *
   * NOTE: I should have this code look at findParam and
   * adjust the selector it creates if the user is already
   * filtering which statusAttributes s/he wants to see.
   *
   * @param findParam This passed in parameter is modified.
   */
  static addStatusAttributeSelector(curUserObj, findParam) {
    log.trace("Enter addStatusAttributeSelector(), findParam: ", findParam);

    if (curUserObj.hasAdminPriv && curUserObj.hasAdminPriv()) {
      // Current user is an admin, so s/he can see everything.
      return;
    }

    // Make sure selector prop is defined.
    findParam.selector = findParam.selector ?
      findParam.selector : {};

    // Remove the statusAttribute selector if it is set.
    // NOTE: the UI should not provide a way for a non-admin
    // to set it.  I.e. this should never happen.
    // TODO: need to iterate through the findParam.selector.$and
    // array looking for the $or:[{statusAttribute:xxxx}] items
    // and remove them.

    const statusAttributeSelector = 
      // More direct, but misses statusAttribute === undefined
      //{statusAttribute:APPROVED};
      {$nor:[
        {statusAttribute:PENDING},
        {statusAttribute:FLAGGED}
      ]};
    findParam.selector.$and.push(statusAttributeSelector);
  }

  /**
   * Set the findParam's selector to either use or not use
   * objects whose isTestData property is set to true.
   *
   * @param findParam This passed in parameter is modified.
   */
  static addIsTestDataSelector(curUserObj, findParam) {
    log.trace("Enter addIsTestDataSelector(), findParam: ", findParam);

    // Make sure selector prop is defined.
    findParam.selector = findParam.selector ?
      findParam.selector : {};

    // Add the appropriate isTestData selector based
    // on whether the current user wants to see test data.
    // NOTE: isTestData is NOT defined in old data out there.
    // We want isTestData === undefined to be considered false.
    // I.e. an obj is only test data if we explicitly set
    // isTestData to true.
    //
    // If we use the $eq selector, we only match records that
    // have isTestData explicitly set.  isTestData being undefined
    // matches neither false or true.  I.e. $eq is a === operator.
    // So, we have to use the $ne operator to see all non test data.
    // TODO: Change so we use curUserObj.onlyUseTestData ?
    //const isTestDataSelector = curUserObj.onlyUseTestData ?
    const isTestDataSelector = curUserObj.isTestData ?
    //  {$eq:true} : {$ne:true};
      {isTestData:{$eq:true}} :
      {isTestData:{$ne:true}};
    findParam.selector.$and.push(isTestDataSelector);
  }

  /**
   * Initialize the application's global values from the
   * one and only "global" document stored in the "globals"
   * collection in the database.
   */
  static async initGlobals(curUserObj) {
    log.trace("initGlobals() curUserObj:", curUserObj);
    log.trace("db:", LocalDB.db.globals);

    if (!LocalDB.db || !LocalDB.db.globals) {
      log.info("In initStoreStateGlobals(), Local.db.globals is null");
      return;
    }

    // Just for testing/debugging.
    /*
    LocalDB.db.globals.pouch.getIndexes().then(function (result) {
      log.trace("pouch.getIndexes(), result: ", result);
    }).catch(function (err) {
      log.bug("pouch.getIndexes(), err: ", err);
    });
    */

    const globalRxDB = await LocalDB.db.globals.findOne().exec();
    log.trace("globalRxDB:", globalRxDB);
    log.trace("globalRxDB.nextUserImageIndex:",
      globalRxDB && globalRxDB.nextUserImageIndex);
    log.trace("globalRxDB.nextWayImageIndex:",
      globalRxDB && globalRxDB.nextWayImageIndex);

    let globalDoc = null;
    if (!globalRxDB || globalRxDB.length < 1) {
      // This is the first time we are being run.
      // Create the one and only global object in the globals collection.
      globalDoc = {
        _id:LocalDB.GLOBAL_ID,
        nextUserImageIndex:0,
        nextWayImageIndex:0,
        nextActImageIndex:0,
      };
      await LocalDB.db.globals.insert(globalDoc);
    }
    else {
      // The one and only global document already existed in the globals
      // collection.
      globalDoc = globalRxDB;
    }
    LocalDB.nextUserImageIndex = globalDoc.nextUserImageIndex;      
    LocalDB.nextWayImageIndex = globalDoc.nextWayImageIndex;      
    LocalDB.nextActImageIndex = globalDoc.nextActImageIndex;      
  }

  // objFilterAndSort should be a subset of store.state.ui.filterAndSort
  // E.g. store.state.ui.filterAndSort.users,
  // store.state.ui.filterAndSort.ways,
  static async updateFilteredSortedObjs(curUserObj, objFilterAndSort,
    collectionName) {
    log.trace("updateFilteredSortedObjs("+collectionName+")");
    log.trace("updateFilteredSortedObjs(), objFilterAndSort: ",
      objFilterAndSort);

    // Convert the filter/sort settings the user selected in
    // UI and saved in the store.state.ui into RxQuery values.
    const {findParam, sortParam} =
      LocalDB.convertFilterAndSortToFindParamAndSortParam(objFilterAndSort,
        collectionName);
    log.trace("findParam: ", findParam);
    log.trace("sortParam: ", sortParam);
    log.trace("curUserObj: ", curUserObj);

    /*
    if (!(curUserObj instanceof UserC)) {
      debugger;
    }
    if (curUserObj.token == null) {
      debugger;
    }
    */

    if (curUserObj == null || curUserObj._id == null ||
        curUserObj.token == null) {
      const objIds = [];
      // Is a timeout required if we are creating an user record for a
      // user who already had an Auth0 login?  I think timeout only
      // needed if we encountered an error, which then sends another
      // action to display the error?
      //
      /*setTimeout(()=>*/store.dispatch(cA_UpdateCache([]))/*, 0)*/;
      /*setTimeout(()=>*/store.dispatch(LocalDB.cA_SetObjs(
        objIds, findParam, sortParam, collectionName))/*, 0)*/;
      return;
    }

    if (!LocalDB.db || !LocalDB.db[collectionName]) {
      log.info("In updateFilteredSortedObjs(), Local.db["+
        collectionName+"] is null");
      return;
    }

    const objsRxDB = await LocalDB.findAndSort(curUserObj, collectionName,
      findParam, sortParam);
    log.trace(collectionName+" objsRxDB: ", objsRxDB);

    const objClass = ApiUtils.getObjClass(collectionName);

    const objsC = [];  // Array of UserC, WayC, 
    const objIds = [];
    objsRxDB.forEach(objRxDB => {
      log.trace("objRxDB: ", objRxDB);
      const objC = new objClass(objRxDB, curUserObj);
      if (!objC.view) {
        return;
      }
      if ((objC instanceof ObjC) && !objC.cn) {
        log.bug("In updateFilteredSortedObjs, objC.cn not set. objC: ", objC);
        debugger;
      }
      objsC.push(objC);
      objIds.push(objRxDB._id);
    });
    //setTimeout(() =>
    store.dispatch(cA_UpdateCache(objsC));
    //, 0);
    //setTimeout(()=>
    store.dispatch(LocalDB.cA_SetObjs(
      objIds, findParam, sortParam, collectionName));
    //, 0);
  }

  // objFilterAndSort should be a subset.  E.g. ui.filterAndSort.users
  static convertFilterAndSortToFindParamAndSortParam(objFilterAndSort,
    collectionName) {
    log.trace("convertFilterAndSortToFindParamAndSortParam(), "+
      "objFilterAndSort: ", objFilterAndSort);
    const {filter, sort} = objFilterAndSort;
    log.trace("filter: ", filter);
    log.trace("sort: ", sort);

    // Create initial findParam object.  We will push() more
    // items into the selector.$and property.
    // I.e. every condition in the $and property needs to be
    // satisfied for the object to be returned in the query.
    //
    // NOTE: we need the created and displayName selectors
    // otherwise RxDB complains:
    // 
    // "Cannot sort on field(s) "roles" when using the default index"
    //
    // Because we sort on the created and displayName fields, we
    // need to have created and displayName selctors as part of
    // the find, even if we are just checking those fields exist.
    const findParam = {
      selector:{
        $and:[
          {created:{$exists:true}},
          {displayName:{$exists:true}}
        ] 
      }
    };

    switch(collectionName) {
      case USERS:
        if (filter["roles"] && filter["roles"].length > 0) {
          findParam.selector.$and.push({"roles":{$in:filter["roles"]}});
        }
        //break;
        // Fall through
      case WAYS:
        // Fall through
      case ACTS:
        // Fall through
      case ITNS:
        if (filter["statusAttribute"] && filter["statusAttribute"].length > 0) {
          const orItems = filter["statusAttribute"].map(sAtr => {
            return {"statusAttribute":sAtr}});
          findParam.selector.$and.push({$or:orItems});
        }
        break;
      default:
        log.bug("In LocalDB.convertFilterAndSortToFindParamAndSortParam(), "+
          "unhandled collectionName: ", collectionName);
        debugger;
    }

    const field = sort.displayName ? "displayName" : "created";
    const direction = sort.displayName ? sort.displayName : sort.created;
    const sortParam = {[field]:direction};
    return {findParam, sortParam};
  }

  // objFilterAndSort should be a subset.
  // E.g. store.state.ui.filterAndSort.users
  static async initStoreStateObjs(curUserObj, objFilterAndSort,
    collectionName) {
    log.trace("initStoreStateObjs() curUserObj:", curUserObj);
    log.trace("initStoreStateObjs() db["+collectionName+"]: ",
      LocalDB.db[collectionName]);
    await LocalDB.updateFilteredSortedObjs(curUserObj, objFilterAndSort,
      collectionName);
  }

  // filterAndSort is the "full" store.state.ui.filterAndSort
  static async initStoreState(curUserObj = {_id:null, token:null},
    filterAndSort) {
    log.trace("initStoreState() curUserObj: ", curUserObj);
    //await LocalDB.initGlobals(curUserObj);
    await LocalDB.initStoreStateObjs(curUserObj,
      filterAndSort[USERS], USERS);
    await LocalDB.initStoreStateObjs(curUserObj,
      filterAndSort[WAYS], WAYS);
    await LocalDB.initStoreStateObjs(curUserObj,
      filterAndSort[ACTS], ACTS);
    await LocalDB.initStoreStateObjs(curUserObj,
      filterAndSort[ITNS], ITNS);
  }

  static async subscribeToOneAndOnlyGlobal() {
    if (!LocalDB.db.globals) {
      log.info("In LocalDB.subscribeToOneAndOnlyGlobal(), LocalDB.db.globals: ",
        LocalDB.db.globals);
      return;
    }

    //sub = LocalDB.db.globals.findOne().$.subscribe(
    //  globalRxDB => log.trace("globals globalRxDB: ", globalRxDB));
    //LocalDB.subs.push(sub);
    let sub;
    sub = LocalDB.db.globals.$.subscribe(
      changeEvent => LocalDB.handleChangeGlobals(changeEvent));
    LocalDB.subs.push(sub);
    log.trace("subs.length: ", LocalDB.subs.length);
  }

  static async subscribeToCollection(collectionName) {
    if (!LocalDB.db[collectionName]) {
      log.bug("In LocalDB.subscribeToCollection(), LocalDB.db["+
        collectionName+"]: ", LocalDB.db[collectionName]);
      return;
    }

    if (collectionName == USERS) {
      LocalDB.subscribeReplication();
    }

    //sub = LocalDB.db.users.find().$.subscribe(
    //sub = LocalDB.db.users.find().sort().$.subscribe(
    //sub = LocalDB.db.users.find().sort("displayName").$.subscribe(
    //  usersRxDB => ChangeHandlers.handleChangeUsers(usersRxDB));
    //LocalDB.subs.push(sub);
    let sub;
    sub = LocalDB.db[collectionName].$.subscribe(
      changeEvent => ChangeHandlers.handleChangeObj(changeEvent));
    LocalDB.subs.push(sub);
    log.trace("subs.length: ", LocalDB.subs.length);
    /*
    sub = LocalDB.db.users.insert$.subscribe(
      changeEvent => ChangeHandlers.handleChangeObj(changeEvent));
    LocalDB.subs.push(sub);
    sub = LocalDB.db.users.update$.subscribe(
      changeEvent => ChangeHandlers.handleChangeObj(changeEvent));
    LocalDB.subs.push(sub);
    sub = LocalDB.db.users.remove$.subscribe(
      changeEvent => ChangeHandlers.handleChangeObj(changeEvent));
    LocalDB.subs.push(sub);
    */
  }

  static unsubscribeAll() {
    log.info("Enter LocalDB.unsubscribeAll(), subs.length: ",
      LocalDB.subs.length);
    LocalDB.subs.forEach(sub => {
      log.trace("sub: ", sub);
      log.trace("sub.unsubscribe: ", sub.unsubscribe());
      }
    );
  }

  static async cancelAllReplication() {
    log.info("Enter LocalDB.cancelAllReplication()");
    // Calling this method will cancel the replication to the
    // remote CouchDB.  cancel() is async
    await Promise.all(Object.keys(LocalDB.replicationStates).map(
      async (replicationState) => {
        if (replicationState && replicationState.cancel) {
          try {
            await replicationState.cancel();
          }
          catch (err) {
            log.info("In cancelAllReplication(), err: ", err);
            log.info("replicationState: ", replicationState);
          }
        }
      }));

    for (const collectionName in LocalDB.replicationState) {
      LocalDB.replicationStates[collectionName] = null;
    }
  }

  static async closeDB() {
    log.info("Enter LocalDB.closeDB()");
    store.dispatch(cA_SetDBState(UI.DB_STATE_STOPPING));
    LocalDB.unsubscribeAll();
    await LocalDB.cancelAllReplication();

    // Destroy the in-memory copy of the data.
    // Does NOT destroy the browser's indexdb storage.
    // (I think.)
    if (LocalDB.db && LocalDB.db.acts) {
      await LocalDB.db.acts.destroy();
    }
    if (LocalDB.db && LocalDB.db.ways) {
      await LocalDB.db.ways.destroy();
    }
    if (LocalDB.db && LocalDB.db.users) {
      await LocalDB.db.users.destroy();
    }

    // Destroy the in-memory copy of the data.
    // Does NOT destroy the browser's indexdb storage.
    if (LocalDB.db) {
      LocalDB.db = await LocalDB.db.destroy();
      store.dispatch(cA_SetDBState(UI.DB_STATE_STOPPED));
    }
    else {
      // Not sure if we can get here, but this is a hack for it.
      // Wait a few seconds and then set the state to stopped.
      log.bug("LocalDB.db is null in LocalDB.closeDB()");
      setTimeout(() => store.dispatch(cA_SetDBState(UI.DB_STATE_STOPPED)),
        4000);
    }

    LocalDB.db = null;
  }

  static async getNextUserImageSrc() {
    const imageSrc = ImageUtils.getUserImageSrc(LocalDB.nextUserImageIndex);
    // Increment, and then check whether we need to start back at the
    // beginning of the array for the next call.
    LocalDB.nextUserImageIndex++;
    LocalDB.nextUserImageIndex =
      (LocalDB.nextUserImageIndex === ImageUtils.NUM_USER_IMAGES) ? 0 :
      LocalDB.nextUserImageIndex;

    if (LocalDB.db && LocalDB.db.globals) {
      const props = {
        nextUserImageIndex:LocalDB.nextUserImageIndex,
      };
      const newGlobalRxDB =
        await LocalDB.db.globals.findOne().update({$set:props});
    }
    else {
      log.bug("LocalDB.db.globals does not exist: ", LocalDB.db);
    }

    log.trace("getNextUserImageSrc(), returning:", imageSrc);
    return imageSrc;
  };

  static async getNextWayImageSrc() {
    const imageSrc = ImageUtils.getWayImageSrc(LocalDB.nextWayImageIndex);
    // Increment, and then check whether we need to start back at the
    // beginning of the array for the next call.
    LocalDB.nextWayImageIndex++;
    LocalDB.nextWayImageIndex =
      (LocalDB.nextWayImageIndex === ImageUtils.NUM_WAY_IMAGES) ? 0 :
      LocalDB.nextWayImageIndex;

    if (LocalDB.db && LocalDB.db.globals) {
      const props = {
        nextWayImageIndex:LocalDB.nextWayImageIndex,
      };
      const newGlobalRxDB =
        await LocalDB.db.globals.findOne().update({$set:props});
    }
    else {
      log.bug("LocalDB.db.globals does not exist: ", LocalDB.db);
    }

    log.trace("getNextWayImageSrc(), returning:", imageSrc);
    return imageSrc;
  };

  static async getNextActImageSrc() {
    const imageSrc = ImageUtils.getActImageSrc(LocalDB.nextActImageIndex);
    // Increment, and then check whether we need to start back at the
    // beginning of the array for the next call.
    LocalDB.nextActImageIndex++;
    LocalDB.nextActImageIndex =
      (LocalDB.nextActImageIndex === ImageUtils.NUM_ACT_IMAGES) ? 0 :
      LocalDB.nextActImageIndex;

    if (LocalDB.db && LocalDB.db.globals) {
      const props = {
        nextActImageIndex:LocalDB.nextActImageIndex,
      };
      const newGlobalRxDB =
        await LocalDB.db.globals.findOne().update({$set:props});
    }
    else {
      log.bug("LocalDB.db.globals does not exist: ", LocalDB.db);
    }

    log.trace("getNextActImageSrc(), returning:", imageSrc);
    return imageSrc;
  };

};

export default LocalDB;
