import $ from 'jquery';
import _ from 'lodash';
import {log, MyError} from 'concierge-common';
import ObjC from '../../client-db/api/obj';
//import UserC from '../../client-db/api/user';
//import UserComponent from '../../containers/user';
import ObjComponent from '../../containers/obj';


class ComponentUtils {

  /**
   * TODO: Delete this now unneeded function.
   *
   * @param objC A UserC/WayC/ActC/ItnC object.
   *
   * @return Returns the React component that should be
   * used to display objC.
   * E.g. UserComponent from containers/user.js,
   * or the ObjComponent from containers/obj.js
   */
  static getComponentForObj(objC) {
    /*
    if (objC instanceof UserC) {
      return UserComponent;
    }
    else*/ if (objC instanceof ObjC) {
      return ObjComponent;
    }
    else {
      log.bug("MasonryLayout.getComponentForObj(), can't handle objC: ", 
        objC);
      debugger;
    }
  }

  static get LIMIT() {return 8};

  static getDocHeight() {
    var D = document;
    return Math.max(
        D.body.scrollHeight, D.documentElement.scrollHeight,
        D.body.offsetHeight, D.documentElement.offsetHeight,
        D.body.clientHeight, D.documentElement.clientHeight
    );
  }

  static installInfiniteScroll() {

    const waitMS = 300;  // Milliseconds
    /*
    function testAtBottom() {
      log.trace("testAtBottom()");
       if ($(window).scrollTop() + $(window).height() ==
          ComponentUtils.getDocHeight()) {
           log.trace("At bottom!");
          if (ComponentUtils.infiniteScrollListener) {
            ComponentUtils.infiniteScrollListener();
          }
       }
    }
    const testAtBottomDebounced =
      _.debounce(testAtBottom, waitMS, {leading: true});

    $(window).scroll(testAtBottomDebounced);
    */

    const listenerThrottled = _.throttle(()=>{
      log.trace("Call callback.");
      if (ComponentUtils.infiniteScrollListener) {
        ComponentUtils.infiniteScrollListener();
      }
    }, 500, {leading:true});

    function testNearBottom() {
      const nearBottomThresholdPx = 500;
      log.trace("testNearBottom()");
      if($(window).scrollTop() + $(window).height() >
         ComponentUtils.getDocHeight() - nearBottomThresholdPx) {
         log.trace("Near bottom.");
         listenerThrottled();
      }
    }

    //const testNearBottomDebounced =
    //  _.debounce(testNearBottom, waitMS, {leading: false});
    //$(window).scroll(testNearBottomDebounced);

    const testNearBottomThrottled =
      _.throttle(testNearBottom, waitMS, {leading: true});
    $(window).scroll(testNearBottomThrottled);
  }

  static setInfiniteScrollListener(listener) {
    ComponentUtils.infiniteScrollListener = listener;
  }

  static unsetInfiniteScrollListener(/*listener*/) {
    ComponentUtils.infiniteScrollListener = undefined;
  }
  /*
  static removeInfiniteScrollListener(listener) {
    if (ComponentUtils.infiniteScrollListener != listener &&
        ComponentUtils.infiniteScrollListener != undefined) {
      log.bug("ComponentUtils.removeInfiniteScrollListener(), "+
        "ComponentUtils.infiniteScrollListener != listener, "+
        "ComponentUtils.infiniteScrollListener: "+
        ComponentUtils.infiniteScrollListener);
      return;
    }
    ComponentUtils.infiniteScrollListener = undefined;
  }
  */

  static haveRetrievedAllObjs(obj) {

    if (obj.totalCount === undefined) {
      // We have never made any requests for this type of object,
      // so we don't know how many exist.
      // Even if there are 0 objects in the database, after we
      // make the first request, obj.totalCount will be set to 0,
      // so a later call to this function will return true.
      return false;
    }

    const numObjsRetrieved = obj && obj.objs && obj.objs.length ?
      obj.objs.length : 0;
    if (numObjsRetrieved == obj.totalCount) {
      return true;
    }
    else if (numObjsRetrieved > obj.totalCount) {
      // Should never happen.
      // I think this actually might happen if some parts of the UI
      // have requested "private" non-visible objects, and then
      // we request all the normally visible objects, we will end up
      // with more objects than we expect?
      log.bug("ComponentUtils.haveRetrievedAllObjs(), "+
        "numObjsRetrieved("+numObjsRetrieved+") > obj.totalCount("+
        obj.totalCount+") obj:", obj);
      return true;
    }
    return false;
  }

  static getXXXs(curUser, xxxs, cA_GetXXX, filterProps = {}) {

    // See if the caller has specified a particular "block" of
    // objects using skip and limit properties.
    const {skip, limit} = filterProps;
    log.trace("getXXXs(), skip("+skip+")  limit("+limit+")");
    if (skip && limit) {
      cA_GetXXX(curUser, filterProps);
      return;
    }

    // The caller did NOT specify a skip and limit, so do so
    // ourselves.

    // Figure out if we have made even a single request for the
    // "start" of the list of objects.  If not, then request the
    // first block of objects.
    const {objs, totalCount} = xxxs;
    let {nextSkip} = xxxs;
    log.trace("nextSkip("+nextSkip+")  totalCount("+totalCount+")");

    if (nextSkip === undefined || nextSkip === null) {
      log.bug("XXXXXXXXXXXXXXX ComponentUtils.getXXXs(), nextSkip:",
        nextSkip);
      log.bug("XXXXXXXXXXXXXXX xxxs:", xxxs);
      nextSkip = 0;
    }

    filterProps.skip = nextSkip;
    filterProps.limit = filterProps.limit !== undefined ?
      filterProps.limit : ComponentUtils.LIMIT;

    if (filterProps.skip + filterProps.limit > totalCount) {
      filterProps.limit = totalCount - filterProps.skip;
    }

    log.trace("filterProps.skip("+filterProps.skip+
      ")  limit("+filterProps.limit+")");

    if (totalCount != undefined && totalCount == nextSkip &&
        totalCount != 0) {
      // We have all the objects.
      log.bug("ComponentUtils.getXXXs(), We already have all the objects.");
      return;
    }
    else if (nextSkip > totalCount) {
      // Should never happen.  Possible math error in my code.
      log.bug("ComponentUtils.getXXXs(), nextSkip > totalCount. "+
        "nextSkip("+nextSkip+")  totalCount("+totalCount+")");
      log.bug("xxxs:", xxxs);
      // Hack fix.
      nextSkip = objs && objs.length ? objs.length : 0;
    }

    cA_GetXXX(curUser, filterProps);
  }

  /**
   * Function to simulate the user clicking on the image, (which
   * displays the file selector to select an image to upload).
   * This simulator is called when the user selects the
   * "Upload Image" actions from the MultiAction Modal.
   *
   * @param {Function} cA_SetError - This is called with a
   * MyError object as its first/only parameter.
   * You can pass any function, but most likely the consumer is
   * a smart container that is passing us this.props.cA_SetError.
   */
  static simulateClick(dropzoneKey, cA_SetError) {
    log.trace("ComponentUtils.simulateClick(), dropzoneKey:", dropzoneKey);
    const dropzones = document.querySelectorAll('[dropzonekey="'+dropzoneKey+'"]');
    if (dropzones.length < 1) {
      const msg = "Unable to find dropzone for image upload.  dropzoneKey("+
        dropzoneKey+").  This is probably a bug in the client web app.";
      log.bug(msg);
      const props = {msg};
      const error = MyError.createSubmitError(props);
      if (cA_SetError) {
        cA_SetError(error);
      }
      return;
    }
    const dropzone = dropzones[0];
    dropzone.click();
  }

  /*
  static createObserver(callback) {
    const gettingMore = document.querySelector(".getting-more-loader");
    log.trace("gettingMore:", gettingMore);
    const options = {
      //root:document.querySelector(".app"),
      root:null,
      rootMargin:"0px",
      threshold:0.5,
    };
    const observer = new IntersectionObserver(callback, options);
    observer.observe(gettingMore);
  }
  */

  static isElementInViewport (el) {
    //special bonus for those using jQuery
    //if (typeof jQuery === "function" && el instanceof jQuery) {
    //    el = el[0];
    //}

    var rect = el.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight ||
          document.documentElement.clientHeight) && /*or $(window).height() */
        rect.right <= (window.innerWidth ||
          document.documentElement.clientWidth) /*or $(window).width() */
    );
  }

  static hasVerticalScroll(node){
    if(node == undefined){
      if(window.innerHeight){
        return document.body.offsetHeight> window.innerHeight;
      }
      else {
        return document.documentElement.scrollHeight >
          document.documentElement.offsetHeight ||
          document.body.scrollHeight>document.body.offsetHeight;
      }
    }
    else {
      return node.scrollHeight> node.offsetHeight;
    }
  }
  /*
  static hasVerticalScroll() {
    // Get the computed style of the body element
    const cStyle = document.body.currentStyle ||
      window.getComputedStyle(document.body, "");

    log.trace("cStyle:", cStyle);
    // Check the overflow and overflowY properties for "auto" and
    // "visible" values
    const hasVScroll = cStyle.overflow == "visible"
                 || cStyle.overflowY == "visible"
                 || (hasVScroll && cStyle.overflow == "auto")
                 || (hasVScroll && cStyle.overflowY == "auto");
    return hasVScroll;
  }
  */
}

if (ComponentUtils.infiniteScrollInstalled != true) {
  ComponentUtils.installInfiniteScroll();
  ComponentUtils.infiniteScrollListener = undefined;
  ComponentUtils.infiniteScrollInstalled = true;
}

export default ComponentUtils;
