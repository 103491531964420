import _ from 'lodash';
import {log} from 'concierge-common';
import ObjUtils from '../client-db/api/obj-utils';

class FilterSort {

  /**
   * Returns a NEW array of objs.  Original passed in
   * objs array is not changed.
   */
  static filter(filterProp, objs) {

    const propNames = Object.keys(filterProp);
    if (propNames.size < 1) {
      // Not filtering.
      return objs;
    }

    let newObjs = objs;
    propNames.forEach(propName => {
      if (propName == "roles") {
        newObjs = newObjs.filter(obj => {
          const {roles:filterRoles = []} = filterProp;
          if (filterRoles.length < 1) {
            // Not filtering based on roles.
            return true;
          }
          const {roles:objRoles = []} = obj;
          return filterRoles.some(role => objRoles.includes(role));
        });
      }
      else if (propName == "statusAttribute") {
        // NOTE:  obj.statusAttriute is a single status value.
        // e.g. "pending", "flagged", "approved".
        // But filterStatusAttribues is an array of status values
        // that we are using for the search.
        newObjs = newObjs.filter(obj => {
          const {statusAttribute:filterStatusAttributes = []} = 
            filterProp;
          if (filterStatusAttributes.length < 1) {
            // Not filtering based on statusAttribute.
            return true;
          }
          // Assume pending if no value.  Should never happen.
          const {statusAttribute:objStatusAttribute = "pending"} = obj;
          return filterStatusAttributes.includes(objStatusAttribute);
        });
      }
      else {
        log.bug("In FilterSort.filter(), unhandled filterProp: ",
          filterProp);
        debugger;
      }
    });

    return newObjs;
  }

  static compare(obj1, obj2, propName, ascDesc) {

    /*
    const obj1 = ObjUtils.get(curUserObj, id1);//, undefined,
      //cache, dispatchGetObj);
    const obj2 = ObjUtils.get(curUserObj, id2);//, undefined, 
      //cache, dispatchGetObj);
    */

    let value = 0;
    if (obj1 && obj2) {
      // obj1 < obj2 = -1
      // obj1 > obj2 = 1
      // obj1 == obj2 = 0
      value = obj1[propName] < obj2[propName] ? -1 :
        obj1[propName] > obj2[propName] ? 1 : 0;
    }
    else if (obj1) {
      value = 1;
    }
    else if (obj2) {
      value = -1;
    }

    if (value != 0 && ascDesc != "asc") {
      // Descending order.
      value = -value;
    }
    return value;
  }

  /**
   * Sorts (in place) the passed in array object according to
   * sortProp.  By "in place" we mean that it returns the same
   * array object that was passed in.
   *
   * NOTE: Any object specified by an ID in the objIds
   * that does not exist in the cache will be put at the
   * end of the array.
   */
  static sort(sortProp, objs) {

    const propNames = Object.keys(sortProp);
    if (propNames.size < 1) {
      // Not sorting.
      return objs;
    }
    if (propNames.size > 1) {
      log.bug("In FilterSort.sort(), more than one sort "+
        "property.  sortProp: ", sortProp);
      debugger;
      return objs;
    }
    objs.sort((obj1, obj2) => FilterSort.compare(
      obj1, obj2, propNames[0], sortProp[propNames[0]]));

    return objs;
  }
}

export default FilterSort;
