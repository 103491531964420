import {log} from 'concierge-common';
import * as ACTION_TYPES from '../actions/types';
import {cA_SetError} from '../actions/error';

// NOTE: Hack
//import {globalCurUserObj} from './cur-user-reducer';

/**
 * This reducer manages the value of the application's store
 * state.users property.
 *
 * @param users The current store state.users object.
 * We should never modify this value.
 *
 * @param {Object} action The current action to be performed.
 * @param {String} action.type This tells us "what" we need to do.
 * @param {Object} action.payload This is the data that we will use to
 * complete the action.
 * @param {Object} action.payload.users The object that we are
 * going to act upon.
 */
function cacheReducer(cache = {objs:new Map()}, action) {
  log.trace("cacheReducer("+action.type+") cache:", cache);
  log.trace("cacheReducer(), action:", action);
  //action.type = _.endsWith(action.type, "_") ?
  //  action.type.slice(0,-1) : action.type;

  const {payload} = action;
  if (!payload) {
    // Redux "interal" action types such as @@redux/INIT
    // don't have payloads, and we should ignore them
    // anyway.
    return cache;
  }
  const {obj} = payload;

  let newCache;
  switch(action.type) {

    case ACTION_TYPES.GET_OBJ_S:
      log.trace("GET_OBJ_S payload: ", payload);
      if (payload.error) {
        action.asyncDispatch(cA_SetError(payload.error));
      }

      log.trace("cache-reducer GET_OBJ_S");
      newCache = Object.assign({}, cache);
      //newCache.objs[obj._id] = obj;
      newCache.objs.set(obj._id, obj);
      log.trace("cache-reducer GET_OBJ_S returning newCache: ", newCache);
      return newCache;

    case ACTION_TYPES.UPDATE_CACHE:
      const {objs:objsToUpdate} = payload;
      log.trace("cache-reducer UPDATE_CACHE, objsToUpdate: ", objsToUpdate);
      newCache = Object.assign({}, cache);

      objsToUpdate.forEach(objToUpdate => {
        newCache.objs.set(objToUpdate._id, objToUpdate);

        /*
        // NOTE: This is a hack to keep curUser
        // in sync with the value in cache.
        // Ideally, store.state.curUser would "point"
        // to the object in cache.
        // I could change cacheReducer to take the whole
        // store.state as its input parameter instead of just 
        // store.state.cache
        if (globalCurUserObj &&
            (globalCurUserObj._id == objToUpdate._id)) {
          objToUpdate.token = globalCurUserObj.token;
          const signInAction = {
            type:ACTION_TYPES.SIGN_IN_S,
            payload:{
              user:objToUpdate,
            },
          };
          action.asyncDispatch(signInAction);
        }
        */
      });
      log.trace("cache-reducer UPDATE_CACHE returning newCache: ", newCache);
      return newCache;

    case ACTION_TYPES.DELETE_CACHE:
      // Delete zero or more objects from the store.state.cache.
      // NOTE: We are not making a deep copy of cache, so not
      // sure if editing newCache in here is OK.
      log.trace("cache-reducer DELETE_CACHE, payload.objs: ", payload.objIds);
      newCache = Object.assign({}, cache);
      //payload.objIds.forEach(objId => delete newCache.objs[objId]);
      payload.objIds.forEach(objId => newCache.objs.delete(objId));
      log.trace("cache-reducer DELETE_CACHE returning newCache: ", newCache);
      return newCache;

    default:
      //log.trace("cache-reducer default");
      return cache;
  }
};

export default cacheReducer;
