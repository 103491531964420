import _ from 'lodash';
import React from 'react';
import {Icon, Image, Modal, Button, Menu} from 'semantic-ui-react';
import {log, MyError} from 'concierge-common';

/**
 * A MultiAction is a Modal dialog that is displayed showing
 * one or more actions the user can click.
 */
class MultiAction extends React.Component {

  handleClose() {
    log.trace("MultiAction.handleClose()");
    // Hack to deal with onClose being called when we are opened.
    const now = Date.now();
    if (now - this.openedTime < 500) {
      return;
    }
    const {cA_MultiAction} = this.props;
    cA_MultiAction(null);
  }

  /**
   * @param {Object} action - Action "description".
   * It will have one of the following properties set:
   *  action = {
   *    url:"/act/23dfwer233",
   *    dispatch:An action wrapped in a dispatch() call.
   *    action:An action that we need to pass to a call to dispatch().
   *  }
   */
  handleClickMenuItem(e, param, action) {
    log.trace("MultiAction.handleClickMenuItem(), e:", e);
    log.trace("MultiAction.handleClickMenuItem(), param:", param);
    log.trace("MultiAction.handleClickMenuItem(), action:", action);
    const {history, cA_DispatchAction, cA_SetError} = this.props;
    log.trace("handleClickMenuItem.handleClickMenuItem(), history:", history);

    if (action.url) {
      log.trace("url:", action.url);
      history.push(action.url);
    }
    else if (action.dispatch) {
      // The action is already wrapped in a dispatch call, (e.g.
      // a container's this.props.cA_XXXX property, so we just
      // need to call it.
      log.trace("Calling action.dispatch(), action:", action);
      action.dispatch();
    }
    else if (action.action) { // action.action
      // The action isn't a dispatch call, but is an action object,
      // (created with a src/actions/XXXX.jscA_* call), that we
      // need to dispatch.
      log.trace("Calling cA_DispatchAction() with action:", action);
      cA_DispatchAction(action);
    }
    else {
      const msg = "MultiAction.handleClickMenuItem() action with no dispatch or action property.";
      log.bug(msg);
      const props = {msg};
      const error = MyError.createBugError(props);
      cA_SetError(error);
    }

    // Close the MultiAction Modal.  Call this.handleClose() instead?
    //cA_MultiAction(null);
    this.handleClose();
  }

  render() {
    log.trace("MultiAction.render() this.props:", this.props);
    this.openedTime = Date.now();
    const {ui} = this.props;
    const {multiAction} = ui;
    let {headerText} = multiAction;

    let header;
    if (headerText === null || headerText === undefined) {
      headerText = "Actions";
    }
    if (headerText.trim() != "") {
      header = <Modal.Header>{headerText}</Modal.Header>
    }
    else {
      // Consumer passed "" for headerText, so it is explicity
      // telling us to NOT display a header.
    }

    const open = multiAction && multiAction.actions &&
      (multiAction.actions.length > 0);
    const modalProps = {
      className:"multi-action",
      open,
      onClose:this.handleClose.bind(this),
      //closeIcon:true,
      size:"mini"
    };

    const menuItems = (
      _.map(multiAction.actions, action => {

        let iconOrImage;
        if (action.iconName) {
          if (action.iconCornerName) {
            iconOrImage =
              <Icon.Group>
                <Icon name={action.iconName} />
                <Icon corner="top right" name={action.iconCornerName} />
              </Icon.Group>
          }
          else {
            iconOrImage = <Icon name={action.iconName} />
          }
        }
        else if (action.imageSrc) {
          iconOrImage = <Image src={action.imageSrc} />
        }

        return(
        <Menu.Item name={action.displayName} key={action.displayName}
          onClick={(e, param) =>
            this.handleClickMenuItem(e, param, action, this.props.history)}>
          {action.displayName}
          {iconOrImage}
        </Menu.Item>
      )})
    );

    return(
      <Modal {...modalProps}>
        {header}
        <Modal.Content>
          <Menu vertical>
            {menuItems}
          </Menu>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleClose.bind(this)}>Cancel</Button>
        </Modal.Actions>
      </Modal>
    );
  };
}

export default MultiAction;

