// This module defines the HomePanel *container*.
// NOT the HomePanel *component*.
import {connect} from 'react-redux';

// Import the HomePanelComponent from which this HomePanelContainer is created.
import HomePanelComponent from '../components/home-panel';

// Import action creators that this component will use.
import {cA_SwCheckForUpdate} from '../actions/service-worker';
//import {cA_SwSetCheckingForUpdate} from '../actions/service-worker';

function mapStateToProps(state) {
  return({
    swState:state.swState,
  });
}
const mapDispatchToProps = {cA_SwCheckForUpdate/*, cA_SwSetCheckingForUpdate*/};
const HomePanelContainer = connect(mapStateToProps, mapDispatchToProps)
  (HomePanelComponent);
export default HomePanelContainer;
