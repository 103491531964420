import _ from 'lodash';
import React from 'react';
import {Button, Image, Icon, Popup} from 'semantic-ui-react';
import {log} from 'concierge-common';
import {USERS, WAYS, ACTS, ITNS, IMG} from '../global-constants';
import {PRIVATE_ID, DEFAULT_USER_ID} from '../global-constants';
import {FOSTER_USER_ID, SUPPORT_USER_ID} from '../global-constants';
import {ADMIN_USER_ID, ROOT_USER_ID} from '../global-constants';
import LocalDB from '../client-db/local-db';
import ObjUtils from '../client-db/api/obj-utils';
import Obj from '../client-db/api/obj';
import ApiUtils from '../client-db/api/api-utils';
import FilterAndSortButton from '../containers/filter-and-sort-button';
import ObjComponent from '../containers/obj';
import MyHeader from '../containers/my-header';
//import Layout from './objects-layout';
import Layout from './masonry-layout';
import LocationUtils from './utils/location-utils';
import ActionUtils from './utils/action-utils';
import FilterAndSortUtils from './utils/filter-and-sort-utils';
import {add, createObj} from './utils/obj-actions';
import {toggleFilterAndSortVisibility} from './utils/obj-actions';


class ObjsPanel extends React.Component {

  handleChangeFilter = (e, choice, propToEdit) => {
    const {collectionName, curUser, cA_SetFilterAndSort,
      filterAndSort} = this.props;
    const {propName} = propToEdit;

    FilterAndSortUtils.handleChangeFilter(collectionName,
      propName, choice.value, curUser, cA_SetFilterAndSort,
      filterAndSort);
  }

  handleChangeSort = (e, choice, propToEdit) => {
    const {collectionName, curUser, cA_SetFilterAndSort,
      filterAndSort} = this.props;

    // NOTE: propToEdit.propName is "sort".
    // choice.propName is "displayName" or "created",
    // and choice.values is ["asc", "desc"],
    FilterAndSortUtils.handleChangeSort(collectionName,
      propToEdit, choice, curUser,
      cA_SetFilterAndSort, filterAndSort);
  }

  componentDidMount() {
    //LocationUtils.initLocationServices(this.props.cA_Notify,
    LocationUtils.possiblyInitLocationServices(this.props.cA_Notify,
      this.props.cA_Confirmation);
  }

  // Return "way", "act", "itn".
  /*
  getDocName = () => {
    const {collectionName} = this.props;
    return collectionName.slice(0, -1);  // E.g. "way"
  }
  */

  /**
   * Figure out how many actions are available for this ObjsPanel.
   * For example, "Create New Waypoint", "Filter And Sort".
   * If only one action is available, display that action in the
   * right button.  If multiple actions are available, display
   * an ActionButton that will display a multi-action panel with
   * all the available actions.
   */
  createRightButton = () => {
    const {props, state} = this;
    const {collectionName, cA_History, shellType, filterAndSort,
      cA_PropsEditor, cA_SetFilterAndSortVisibility} = props;
    const {curUserObj} = state;
    const imageSrc = IMG[collectionName];

    let rightButton = undefined;

    if (collectionName != USERS &&
        (curUserObj.hasAdminPriv() || curUserObj.hasRole("guide"))) {
      // The curUser can create objects in addition to being
      // able to filter/sort.
      let actions = []; 
      add(createObj(collectionName, curUserObj, cA_History), actions);

      add(toggleFilterAndSortVisibility(this.props,
        this.handleChangeFilter, this.handleChangeSort), actions);

      actions = ActionUtils.sortActions(actions);
      const title = Obj.DISPLAY_NAME_PLURAL(collectionName)+" Actions";
      const multiAction = ActionUtils.createMultiAction(props, actions, title);

      rightButton =
        <Button className="actions-button" size="huge" primary
          onClick={multiAction.dispatch}
        >
          <Icon name="ellipsis vertical" />
        </Button>
    }
    else { //if (collectionName == USERS) {
      // Users are displayed, so all any user can do is filter/sort.
      // OR
      // The user is only a traveler, so can only filter/sort.
      rightButton = <FilterAndSortButton collectionName={collectionName} />
    }

    return rightButton;
  }

  createHeader =() => {
    log.trace("ObjsPanel.createHeader(), props: ", this.props);
    const {cA_History, onBeforeBack, collectionName, shellType} = this.props;
    let {title} = this.props;

    // e.g. "Waypoint" and "Waypoints"
    //const objType = Obj.DISPLAY_NAME(collectionName);
    const objsType = Obj.DISPLAY_NAME_PLURAL(collectionName);
    title = title ? title : objsType;
    const rightButton = this.createRightButton();
    const myHeaderProps = {
      hasBackButton:true,
      onBeforeBack,
      title,
      rightButton,
      cA_History,
      shellType,
      collectionName,
    };

    return <MyHeader {...myHeaderProps} />
  }

  static createStateFromProps(props) {
    log.trace("ObjsPanel.createStateFromProps() props:", props);
    // showOnly = "editable", "viewable".  Currently only used
    // when being used to select an object.
    const {collectionName, cache, curUser, cA_GetObj, showOnly,
      preventDuplicates} = props;
    log.trace("props["+collectionName+"] = ", props[collectionName]);;
    // Get the objIds that are in the store.state.ways,
    // store.state.acts, store.state.itns, store.state.users, etc.
    // TODO: Figure out whether we want the filtering attribute to
    // affect this.
    const storeStateObjs = props[collectionName];
    const {objIds:storeStateObjIds} = storeStateObjs;

    const curUserObj = ObjUtils.getUser(curUser, curUser._id,
      cache, cA_GetObj);

    let existingIds = [];
    if (preventDuplicates) {
      const {objId, objPropName} = props;
      const obj = ObjUtils.get(curUserObj, objId, undefined,
        cache, cA_GetObj);
      if (obj) {
        existingIds = _.get(obj, objPropName, []);
        if (existingIds && Array.isArray(existingIds)) {
          existingIds = existingIds.slice(0);
        }
      }

      if (objPropName != "recommendations") {
        // Don't allow user to add themselves to userIds
        // or to their favorites list.
        existingIds.push(objId);
      }

      // Don't allow selection of permanent/staff objects/users.
      // Not sure if this should be a parameter.  Is there ever
      // a case of needing to select one of these?
      existingIds.push(PRIVATE_ID);
      existingIds.push(DEFAULT_USER_ID);
      existingIds.push(FOSTER_USER_ID);
      existingIds.push(SUPPORT_USER_ID);
      existingIds.push(ADMIN_USER_ID);
      existingIds.push(ROOT_USER_ID);
    }

    // Convert the storeStateObjIds into ObjC objects.
    // Filter out "default" objects and non-viewable objects.
    // NOTE: storeStateObjId and objId are different things!
    const objs = [];
    storeStateObjIds.forEach(storeStateObjId => {
      const {objId} = props;
      const obj = ObjUtils.get(curUserObj, storeStateObjId, undefined,
        cache, cA_GetObj);
      if (!obj) {
        debugger;
        return;
      }
      if (Obj.isDefaultObj(obj)) {
        return;
      }
      if (showOnly == "viewable" && !obj.view) {
        return;
      }
      if (showOnly == "editable" && !obj.edit) {
        return;
      }
      if (showOnly == "followers") {
        // Show only followers of the curUser, or
        // if curUser is an admin, show all users.
        if (obj.cn != USERS) {
          return;
        }
        if (curUserObj && curUserObj.hasAdminPriv &&
          !curUserObj.hasAdminPriv()) {
          if (!obj.guides || !Array.isArray(obj.guides) ||
              !obj.guides.includes(curUserObj._id)) {
            // curUserObj is not an admin and is not
            // one of obj's guides.
            return;
          }
        }
        if (obj.recommendations &&
            Array.isArray(obj.recommendations) &&
            obj.recommendations.includes(objId)) {
          // This obj/user already has objId in its list
          // of recommendations.
          return;
        }
      }
      /* Not needed yet.
      if (showOnly == "deletable" && !obj.deleteObj) {
        return;
      }
      */
      if (preventDuplicates) {
        if (existingIds.indexOf(storeStateObjId) >= 0) {
          // Already exists in the list.
          return;
        }
      }

      // Passed all the tests, so add the obj.
      objs.push(obj);
    });

    const state = {curUserObj, objs, existingIds};
    return state;
  }


  static getDerivedStateFromProps(nextProps, prevState) {
    log.trace("ObjsPanel.getDerivedStateFromProps() nextProps:", nextProps);
    const newState = ObjsPanel.createStateFromProps(nextProps);
    if (_.isEqual(prevState, newState)) {
      return null;
    }
    else {
      return newState;
    }
  }

  constructor(props) {
    super(props);
    this.state = ObjsPanel.createStateFromProps(props);
  }

  render() {
    log.trace("ObjsPanel.render() this.props:", this.props);
    const {props, state} = this;
    const {collectionName, selectable, onSelect, busy,
      filterAndSort, showOnly} = props;
    const {objs} = state;

    const header = this.createHeader();

    // Create the message to display if we are busy looking
    // for objects or no objects were found.
    // e.g. "Waypoints", "Adventures", "Itineraries".
    const objsType = Obj.DISPLAY_NAME_PLURAL(collectionName);
    const modifier = showOnly ? showOnly+" " : "";
    const emptyMessage = busy ?
      "Looking for "+objsType+"..." : "No "+modifier+objsType+" found";

    return (
      <div className="cards-panel">
        {header}
        <Layout objects={objs} CardContents={ObjComponent}
          collectionName={collectionName}
          selectable={selectable}
          onSelect={onSelect}
          emptyMessage={emptyMessage}
          filterAndSortVisible={filterAndSort.visible} />
      </div>
    );
  }
}

export default ObjsPanel;
