// This module defines the MyProgressBar *container*.
// NOT the MyProgressBar *component*.

import {connect} from 'react-redux';

// Import the MyProgressBarComponent from which this ShellContainer is created.
import MyProgressBarComponent from '../components/my-progress-bar';

// Import action creators that this component will use.
import {cA_SetProgressBar} from '../actions/ui';

function mapStateToProps(state) {
  return({
    progressBar:state.ui.progressBar,
    //error:state.error
  });
}

const mapDispatchToProps = {cA_SetProgressBar};
//const mapDispatchToProps = null;

const MyProgressBarContainer = connect(mapStateToProps, mapDispatchToProps)(MyProgressBarComponent);
export default MyProgressBarContainer;
