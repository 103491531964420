/**
 * This defines the "types" of actions the application can generate.
 * This file is useful as an import in modules that create and a
 * process actions.  For example:
 *
 *    import * as ACTION_TYPES from '../actions/types';
 *
 *    if (myAction == ACTION_TYPES.SIGNUP) {...}
 */ 

const FETCH_APP_STATE = "fetchAppState";
const FETCH_FILTERED_ACTS = "fetch_filtered_acts";
const USER_WANTS_TO_SIGNUP = "signUpDesired";
const USER_WANTS_TO_SIGNIN = "signInDesired";
const SET_ERROR = "setError";
const NOTIFY = "notify";
const SET_DB_STATE = "setDBState";
const SET_REPLICATION = "setReplication";
const SET_AUTH0_STATE = "setAuth0State";
const SET_AUTH0_STATE_S = "setAuth0State_S";
const SET_SIDEBAR_VISIBILITY = "setSidebarVisibility";
const SET_FOOTER_VISIBILITY = "setFooterVisibility";
const SET_FILTER_AND_SORT_VISIBILITY = "setFilterAndSortVisibility";
const SET_FILTER_AND_SORT = "setFilterAndSort";
const SET_SHELL_CONTENTS_PROPS = "setShellContentsProps";
const SIGN_UP_S = "signUp_S";
const SIGN_IN_S = "signIn_S";
const SIGN_OUT = "signOut";
const SIGN_OUT_S = "signOut_S";

const GET_OBJ = "getObj";
const GET_OBJ_P = "getObj_P";
const GET_OBJ_S = "getObj_S";
const GET_OBJ_F = "getObj_F";

const UPDATE_CACHE = "updateCache";
const DELETE_CACHE = "deleteCache";

const CREATE_ACT = "createAct";
const CREATE_ACT_P = "createAct_P";
const CREATE_ACT_S = "createAct_S";
const CREATE_ACT_F = "createAct_F";
const GET_ACT = "getAct";
const GET_ACT_P = "getAct_P";
const GET_ACT_S = "getAct_S";
const GET_ACT_F = "getAct_F";
const EDIT_ACT = "editAct";
const EDIT_ACT_P = "editAct_P";
const EDIT_ACT_S = "editAct_S";
const EDIT_ACT_F = "editAct_F";
const DELETE_ACT = "deleteAct";
const DELETE_ACT_P = "deleteAct_P";
const DELETE_ACT_S = "deleteAct_S";
const DELETE_ACT_F = "deleteAct_F";

const CREATE_ITN = "createItn";
const CREATE_ITN_P = "createItn_P";
const CREATE_ITN_S = "createItn_S";
const CREATE_ITN_F = "createItn_F";
const GET_ITN = "getItn";
const GET_ITN_P = "getItn_P";
const GET_ITN_S = "getItn_S";
const GET_ITN_F = "getItn_F";
const EDIT_ITN = "editItn";
const EDIT_ITN_P = "editItn_P";
const EDIT_ITN_S = "editItn_S";
const EDIT_ITN_F = "editItn_F";
const DELETE_ITN = "deleteItn";
const DELETE_ITN_P = "deleteItn_P";
const DELETE_ITN_S = "deleteItn_S";
const DELETE_ITN_F = "deleteItn_F";

const DO_NOTHING = "doNothing";
const SET_LOADER = "setLoader";
const SET_PROGRESS_BAR = "setProgressBar";
const MULTI_ACTION = "multiAction";
const DISPATCH_ACTION = "dispatchAction";
const NOT_YET_IMPLEMENTED = "notYetImplemented";
const SET_DEVICE = "setDevice";
const SET_SHELL_TYPE = "setShellType";
const DELETE_CONNECTION = "deleteConnection";
const DELETE_CONNECTION_P = "deleteConnection_P";
const DELETE_CONNECTION_S = "deleteConnection_S";
const DELETE_CONNECTION_F = "deleteConnection_F";

const HISTORY = "history";
const CONFIRMATION = "confirmation";
const ENABLE_CONFIRMATION_OK_BUTTON = "enableConfirmationOkButton";
const USAGE_CONFIRMATION = "usageConfirmation";
const USAGE_CONFIRMATION_P = "usageConfirmation_P";
const USAGE_CONFIRMATION_S = "usageConfirmation_S";
const USAGE_CONFIRMATION_F = "usageConfirmation_F";
const PROPS_EDITOR = "propsEditor";

const SET_USERS = "setUsers";
const SET_WAYS = "setWays";
const SET_ACTS = "setActs";
const SET_ITNS = "setItns";
const SET_ANYS = "setAnys";
const SET_USAGE = "setUsage";

const SW_SET_REGISTERED = "swSetRegistered";
const SW_SET_INSTALLED = "swSetInstalled";
const SW_CHECK_FOR_UPDATE = "swCheckForUpdate";
const SW_CHECK_FOR_UPDATE_P = "swCheckForUpdate_P";
const SW_CHECK_FOR_UPDATE_S = "swCheckForUpdate_S";
const SW_CHECK_FOR_UPDATE_F = "swCheckForUpdate_F";
const SW_POSSIBLY_ACTIVATE_UPDATE = "swPossiblyActivateUpdate";


export {
  FETCH_APP_STATE, FETCH_FILTERED_ACTS,
  USER_WANTS_TO_SIGNUP, USER_WANTS_TO_SIGNIN, SET_ERROR, NOTIFY,
  SET_AUTH0_STATE, SET_AUTH0_STATE_S,
  SET_DB_STATE, SET_REPLICATION,
  SET_SIDEBAR_VISIBILITY, SET_FOOTER_VISIBILITY,
  SET_FILTER_AND_SORT_VISIBILITY,
  SET_FILTER_AND_SORT,
  SET_SHELL_CONTENTS_PROPS,
  SIGN_IN_S, SIGN_UP_S,
  SIGN_OUT, SIGN_OUT_S,
  GET_OBJ, GET_OBJ_P, GET_OBJ_S, GET_OBJ_F,
  UPDATE_CACHE, DELETE_CACHE,

  CREATE_ACT, CREATE_ACT_P, CREATE_ACT_S, CREATE_ACT_F,
  GET_ACT, GET_ACT_P, GET_ACT_S, GET_ACT_F,
  EDIT_ACT, EDIT_ACT_P, EDIT_ACT_S, EDIT_ACT_F,
  DELETE_ACT, DELETE_ACT_P, DELETE_ACT_S, DELETE_ACT_F,

  CREATE_ITN, CREATE_ITN_P, CREATE_ITN_S, CREATE_ITN_F,
  GET_ITN, GET_ITN_P, GET_ITN_S, GET_ITN_F,
  EDIT_ITN, EDIT_ITN_P, EDIT_ITN_S, EDIT_ITN_F,
  DELETE_ITN, DELETE_ITN_P, DELETE_ITN_S, DELETE_ITN_F,

  DO_NOTHING, SET_LOADER, SET_PROGRESS_BAR,
  MULTI_ACTION, DISPATCH_ACTION,
  NOT_YET_IMPLEMENTED, SET_DEVICE, SET_SHELL_TYPE,
  DELETE_CONNECTION,
  DELETE_CONNECTION_P, DELETE_CONNECTION_S, DELETE_CONNECTION_F,
  HISTORY, CONFIRMATION, PROPS_EDITOR,
  ENABLE_CONFIRMATION_OK_BUTTON,
  USAGE_CONFIRMATION,
  USAGE_CONFIRMATION_P, USAGE_CONFIRMATION_S, USAGE_CONFIRMATION_F,

  SET_USERS, SET_WAYS, SET_ACTS, SET_ITNS, SET_ANYS, SET_USAGE,
  SW_SET_REGISTERED, SW_SET_INSTALLED,
  SW_CHECK_FOR_UPDATE,
  SW_CHECK_FOR_UPDATE_P, SW_CHECK_FOR_UPDATE_S, SW_CHECK_FOR_UPDATE_F,
  SW_POSSIBLY_ACTIVATE_UPDATE,
  //SW_SET_CHECKING_FOR_UPDATE,
};
