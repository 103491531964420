import _ from 'lodash';
//import $ from 'jquery';
import React from 'react';
import {Icon, Button, Card, Label, Segment, Grid} from 'semantic-ui-react';
import {log} from 'concierge-common';
import {PENDING, FLAGGED} from '../global-constants';
import ApiUtils from '../client-db/api/api-utils';


class OrderedLayout extends React.Component {

  // Keep this value in sync with selector
  // .ordered-layout .ui-card-container.animate
  // in style.css
  static get SWAP_TRANSITION_MS() {return 500;}

  animateSwap(index) {
    const selectorLeft = '.ui-card-container[index="'+(index-1)+'"]';
    const selectorRight = '.ui-card-container[index="'+index+'"]';
    const leftCard = document.querySelector(selectorLeft);
    const rightCard = document.querySelector(selectorRight);
    const arrows = rightCard.querySelector(".arrows-container");

    const leftRect = leftCard.getBoundingClientRect();
    const rightRect = rightCard.getBoundingClientRect();
    const x = rightRect.left - leftRect.left;
    const y = rightRect.top - leftRect.top;
    const translateLeft = "translate("+x+"px, "+y+"px)";
    const translateRight = "translate("+(-x)+"px, "+(-y)+"px)";

    leftCard.classList.add("animate");
    rightCard.classList.add("animate");
    arrows.classList.add("animate");
    leftCard.style.transform = translateLeft;
    rightCard.style.transform = translateRight;
    arrows.style.transform = translateLeft;

    setTimeout(()=>{
      leftCard.classList.remove("animate");
      rightCard.classList.remove("animate");
      arrows.classList.remove("animate");
      leftCard.style.transform = "";
      rightCard.style.transform = "";
      arrows.style.transform = "";
    }, OrderedLayout.SWAP_TRANSITION_MS);
  }

  handleClickArrows = (index) => {
    log.trace("handleClickArrows(), index:", index);
    log.trace("handleClickArrows(), props:", this.props);
    const {curUser, cardContentsProps} = this.props;
    const {parentObj, objPropName} = cardContentsProps;
    const parentObjId = parentObj._id;


    const newArray = parentObj[objPropName].slice(0);
    const temp = newArray[index];
    newArray[index] = newArray[index-1];
    newArray[index-1] = temp;

    const objProps = {};
    objProps[objPropName] = newArray;
    log.trace("oldArray[0]:", parentObj[objPropName][0]);
    log.trace("newArray[0]:", newArray[0]);

    const animateIt = true;
    if (animateIt) {
      this.animateSwap(index);
      log.trace("objProps.children: ", objProps.children);
      setTimeout(()=>ApiUtils.editRxDBObj(curUser, parentObjId, objProps/*,
        parentObj.cn*/),
        OrderedLayout.SWAP_TRANSITION_MS);
    }
    else {  // Don't animate.
      ApiUtils.editRxDBObj(curUser, parentObjId, objProps, /*parentObj.cn*/);
    }
  }

  /**
   * @param {class} CardContents - The concierge/src/component class
   * that will be used to render the card.  E.g. Way, Act, Itn, User.
   */
  createCard(obj, parentObj, objPropName, index, CardContents, editable) {
    const arrowButton = editable ?
      <span>
        <Button primary basic className="arrows-container left superbasic"
          onClick={()=>this.handleClickArrows(index)}>
          <div className="arrow-border">
            <Icon size="large" name="arrow left" />
            <Icon size="large" name="arrow right" />
          </div>
        </Button>
        <Button primary basic className="arrows-container top superbasic"
          onClick={()=>this.handleClickArrows(index)}>
          <div className="arrow-border">
            <Icon size="large" name="arrow up" />
            <Icon size="large" name="arrow down" />
          </div>
        </Button>
      </span>
      :
      <span>
        <Button primary basic className="arrows-container left superbasic">
          <div className="arrow-border">
            <Icon size="large" name="arrow right" />
          </div>
        </Button>
        <Button primary basic className="arrows-container top superbasic">
          <div className="arrow-border">
            <Icon size="large" name="arrow down" />
          </div>
        </Button>
      </span>

    const className = obj && (obj.statusAttribute == PENDING ||
      obj.statusAttribute == FLAGGED) ?
      "ui-card-container "+obj.statusAttribute :
      "ui-card-container";

    //const key = index;
    const key = obj._id+"_"+index;

    // NOTE: I don't use key={index} for anything.
    // It just needs to be unique to keep React happy.
    const card = 
      <div className={className} key={key} index={index}>
        {arrowButton}
          <CardContents obj={obj} parentObj={parentObj}
            objPropName={objPropName} index={index} />
      </div>

    return card;
  }

  createCards(editable) {
    const props = this.props;
    log.trace("OrderedLayout.createCards() props:", props);
    const {objects, CardContents, cardContentsProps} = props;
    const {parentObj, objPropName} = cardContentsProps;

    let index = 0;
    const cards = _.map(objects, obj => {
      const card = this.createCard(obj, parentObj, objPropName, index,
        CardContents, editable);
      index++;
      return card;
    });

    log.trace("MasonryLayout.render(), cards.length:", cards.length);
    return cards;
  }

  render() {
    log.trace("OrderedLayout.render() this.props:", this.props);
    const {emptyMessage, cardContentsProps} = this.props;
    const {parentObj} = cardContentsProps;
    const editable = parentObj && parentObj.edit;

    let cards = this.createCards(editable);
    if (!cards || cards.length < 1) {
      cards =
        <Segment className="empty-message" padded inverted>
          <Grid centered>
            <Grid.Row>
              <Label basic size="huge">{emptyMessage}</Label>
            </Grid.Row>
          </Grid>
        </Segment>
    }

    const className = "centered ordered-layout" +
      (editable ? " editable" : "");

    return (
      <Card.Group className={className}>
        {cards}
      </Card.Group>
    );
  }
}

export default OrderedLayout;
