/**
 */
import _ from 'lodash';
import React from 'react';
import {Icon, Button} from 'semantic-ui-react';
import Slider from "react-slick";

import {log} from 'concierge-common';

import MyPopup from '../containers/my-popup';

import ActionUtils from './utils/action-utils';
import RibbonUtils from './utils/ribbon-utils';
import RibbonContainer from './ribbon-container';

// Just needed for TRANSITION_DURATION_MS layout hack.
import MasonryLayout from './masonry-layout';

class ActionCarousel extends React.Component {

  /**
   * Image upload succeeded.  Show a notification message.
   * and then call the consumer supplied uploadSuccessHandler
   * callback.  If a User object was the consumer, the callback
   * it supplied use will update update the User object's profile
   * image src property to point to the newly uploaded image.
   * The Act object's callback would do something similar.
   *
   * This function is called whether the server or the client did
   * the upload to Firebase, assuming the upload succeeded.
   */
  /* 
  uploadSuccessHandler(imageURL) {
    log.trace("ActionCarousel.uploadSuccessHandler(), imageURL:", imageURL);
    const {curUser, cA_SetError, cA_SetLoader,
      uploadSuccessHandler} = this.props;
    log.trace("uploadSuccessHandler:", uploadSuccessHandler);
    log.trace("typeof uploadSuccessHandler:", typeof uploadSuccessHandler);
    const props = {
      msg:"Image upload succeeded.",
      severity:MyError.SUCCESS
    };
    const error = MyError.createSubmitError(props);
    cA_SetError(error);

    // Image upload succeeded.  Now call the consumer supplied
    // uploadSuccessHandler.  For example, a User object's
    // uploadSuccessHandler will change the User's image "src"
    // property to point to the newly uploaded image.
    log.trace("Calling uploadSuccessHandler");
    uploadSuccessHandler(imageURL);
    log.trace("Returned from uploadSuccessHandler");

    // TODO: Perhaps make the call above an async call so we can
    // wait until it returns before setting the loader to false?
    cA_SetLoader(false);
  }
  */

  /**
   * Error handler if this client does the upload to Firebase.
   * (As opposed to the concierge-rest server doing the upload to Firebase.)
   */
   /*
  uploadErrorHandlerClient(err) {
    log.trace("Error during upload, err:", err);
    let msg = "Image upload failed.  ";
    try {
      const serverResponse = JSON.parse(err.serverResponse);
      const {code} = serverResponse.error;
      if (code == HttpStatus.FORBIDDEN) {
        const maxSize = ConciergeRestApi.MAX_IMAGE_SIZE_STRING;
        msg += "Image too large.  Maximum allowed size: "+maxSize;
      }
      else {
        msg += "Firebase ref.put(file) threw error.  err.serverResponse: "+err.serverResponse;
      }
    }
    catch(errNotUsed) {
      // Exception thrown while trying to parse the "err".
      // So simply try to display it as a string.
      msg += "Possible code bug?  Details: err: "+err;
    }
    const props = {msg};
    const error = MyError.createSubmitError(props);
    const {cA_SetError, cA_SetLoader} = this.props;
    cA_SetError(error);
    cA_SetLoader(false);
  }
  */

  /**
   * Called when the image does not exist at the specified URL.
   * Display the fallback image.
   */
   /*
  handleImageError(e) {
    log.trace("ActionCarousel.handleImageError(), e:", e);
    let {srcFallback, imageErrorHandler, imageId} = this.props;
    srcFallback = srcFallback ? srcFallback :
      ImageUtilsCom.UPLOAD_PLACEHOLDER_IMAGE;
    log.trace(ImageUtilsCom.UPLOAD_PLACEHOLDER_IMAGE);
    log.trace(srcFallback);

    const imageElement = e.target;

    // Remove error callback just in case the srcFallback is not
    // there either.  Don't want an infinite loop.
    // Doesn't work.  Still get infinite loop.
    //imageElement.onError = null;
    //delete imageElement.onError;

    imageElement.src = srcFallback;

    if (imageErrorHandler) {
      imageErrorHandler(imageId);
    }
  }
  */

  /*
  createImage() {
    const props = this.props;
    const {src, srcFallback, imgClassName, ribbons, onClick} = props;

    const onError = this.handleImageError.bind(this);

    let className = "ui image fluid";
    className = imgClassName ? className+" "+imgClassName : className;
    const imageProps = {
      src,
      className,
      //WORKS onLoad:alert('image loaded successfully'),
      //WORKS onError:alert('error'),
      onError,
      onClick,
    };

    const image = <img {...imageProps} />
    return image;
  }
  */
  createCarousel() {
    const props = this.props;
    const {srcs, srcFallback, imgClassName, onClick} = props;

    const autoplaySpeed = 6000 + (3000 * Math.random());
    log.trace("autoplaySpeed:", autoplaySpeed);

    //const onError = this.handleImageError.bind(this);

    //let className = "ui image fluid";
    //let className = "ui image";
    let className = "";
    className = imgClassName ? className+" "+imgClassName : className;
    const carouselProps = {
      dots: false,
      autoplay:true,
      autoplaySpeed,

      draggable:false,
      swipeToSlide:false,
      swipe:false,
      touchMove:false,

      //adaptiveHeight:true,
      lazyLoad:true,
      //lazyLoad:false,  // Doesn't help width problem.

      arrows:false,  // We render our own arrow buttons.
      //draggable:false,

      //className,
      //WORKS onLoad:alert('image loaded successfully'),
      //WORKS onError:alert('error'),
      //onError,
      //onClick,

      //variableWidth:false,  // Default is false.
    };

    let slides = [<img className="fallback-img"
      key={0} src={srcFallback} onClick={onClick} alt="" />];
    if (srcs && srcs.length > 0) {
      //slides = _.map(srcs, src => <div><img src={src} /></div>);
      slides = _.map(srcs, (src, index) => <img key={index} src={src} onClick={onClick} alt="" />);
      //slides = _.map(srcs, src => <img src={src} onClick={onClick} />);
    }

    const carousel =
      <div className={className}>
        <Slider {...carouselProps} ref={slider=>this.carousel=slider} >
          {slides}
        </Slider>
      </div>

    return carousel;
  }

  // TODO: This should probably become a module of its own.
  // It is in the action-image.js and my-map.js modules also.
  createActionsButton(multiAction) {
    log.trace("ActionCarousel.createActionsButton(), multiAction:",
      multiAction);
    const content = ActionUtils.getActionsString(multiAction);
    const popupProps = {
      content,
      position:"bottom left",  // Use dependent.  Works for now.
    };
    const actionsButton =
      <MyPopup {...popupProps}
        trigger={
        <Button primary icon className="show-actions"
          onClick={multiAction.dispatch}>
          <Icon name="ellipsis vertical" size="large" />
        </Button>
        } />
    return actionsButton;
  }

  handleClickPrev(e) {
    if (e) {
      e.stopPropagation();
    }
    log.trace("ActionCarousel.handleClickPrev()");
    this.carousel.slickPrev();
  }
  handleClickNext(e) {
    if (e) {
      e.stopPropagation();
    }
    log.trace("ActionCarousel.handleClickNext()");
    this.carousel.slickNext();
  }

  createPrevAndNextButtons() {
      //icon="angle left"
      //icon="chevron left"
    const prev = 
      <Button basic inverted icon="angle left"
        className="superbasic carousel-image-button prev"
        onClick={e=>this.handleClickPrev(e)} />
    const next = 
      <Button basic inverted icon="angle right"
        className="superbasic carousel-image-button next"
        onClick={e=>this.handleClickNext(e)} />
    return {prev, next};
  }

  /**
   * NOTE: This is a hack workaround for a problem in react-slick.
   * react-slick does NOT recalculate the layout of the slider images
   * when the parent card in the masonry layout resizes.
   * See:
   * https://github.com/akiran/react-slick/issues/809#issuecomment-325807788
   * Nov 16, 2017 user: maxbel90
   *
   * Also:
   *
   * https://github.com/akiran/react-slick/issues/809
   * Aug 30, 2017 user: iamcam
   *
   * https://github.com/akiran/react-slick/issues/655
   * Jun 27, 2017 user: brownsmith
   */
  componentDidMount() {
    log.trace("ActionCarousel.componentDidMount()");

    // Force the slider to relayout.
    // Whichever call we make to force the slider to relayout
    // the images needs to happen AFTER Masonry layout has
    // finished "animating" the width of the cards.
    // So, add 100ms to the MasonryLayout's "transitionDuration",
    // or double it, etc.

    // This works.
    setTimeout(()=>window.dispatchEvent(new Event('resize')),
      MasonryLayout.TRANSITION_DURATION_MS * 2);

    // This works most of the time, but can screw up the
    // layout within the slider.  (Images stacked vertically?!)
    //setTimeout(()=>this.forceUpdate(),
    //  MasonryLayout.TRANSITION_DURATION_MS * 2);
  }

  createRibbonContainer() {
    const {obj, curUserObj, cA_Confirmation, cA_SetError, cA_History} = this.props;
    const ribbons = RibbonUtils.calcRibbons(obj, curUserObj,
      cA_Confirmation, cA_SetError, cA_History);
    if (!ribbons || ribbons.length < 1) {
      return undefined;
    }
    return <RibbonContainer ribbons={ribbons} />
  }

  render() {
    log.trace("ActionCarousel.render() this.props:", this.props);
    const props = this.props;
    const {multiAction} = props;
    let {popupContent} = props;

    log.trace("uploadSuccessHandler:", this.props.uploadSuccessHandler);

    //const image = this.createImage();
    const carousel = this.createCarousel();

    const ribbonContainer = this.createRibbonContainer();

    const actionsButton = multiAction ?
      this.createActionsButton(multiAction) : undefined;
    if (!popupContent && actionsButton) {
      popupContent = "Click the [ \u22EE ] button for actions.";
    }

    //const visibilityIcon = ComponentUtils.createVisibilityIcon(obj);
    //log.trace("visibilityIcon:", visibilityIcon);

    // Show prev and next arrow buttons if there is more than
    // one image to display.
    let prevNextButtons = {};
    if (props.srcs && props.srcs.length > 1) {
      prevNextButtons = this.createPrevAndNextButtons();
    }

    const imageAndButton =
      <div className="image-and-button">
        <div className="image-dropzone-container">
          {carousel}
          {ribbonContainer}
        </div>
        {actionsButton}
        {prevNextButtons.prev}
        {prevNextButtons.next}
      </div>

    //<Slider {...settings}>
    return(
      <MyPopup content={popupContent}
        position="bottom center"
        trigger={imageAndButton}
      />
    )
  }
}

export default ActionCarousel;
