// This module defines the AnysPanel *container*.
// NOT the AnysPanel *component*.

import {connect} from 'react-redux';

// Import the AnysPanelComponent from which this AnysPanelContainer is created.
import AnysPanelComponent from '../components/anys-panel';
import {cA_History} from '../actions/ui';
import {cA_GetObj} from '../client-db/api/obj-utils';
import {cA_PropsEditor} from '../actions/ui';


function mapStateToProps(state) {
  return({
    curUser:state.curUser,
    //anys:state.anys,
    cache:state.cache,
    //shellType:state.ui.shellType,
    filterAndSort:state.ui.filterAndSort,
    //filterAndSortVisible:state.ui.filterAndSort.visible,
    busy:!!state.ui.progressBar.timer,
  });
}

const mapDispatchToProps = {cA_History, cA_GetObj, cA_PropsEditor};
const AnysPanelContainer = connect(mapStateToProps, mapDispatchToProps)
  (AnysPanelComponent);
export default AnysPanelContainer;
