import _ from 'lodash';
import {isRxDocument} from 'rxdb';
import {log} from 'concierge-common';
import {ImageUtils} from 'concierge-common';
import {strId, isEqualIds} from '../../utils/string-utils';
import {USERS, PRIVATE_ID, DEFAULT_USER_ID, APPROVED}
  from '../../global-constants';
import {FOSTER_USER_ID, SUPPORT_USER_ID, ADMIN_USER_ID, ROOT_USER_ID}
  from '../../global-constants';
import Obj from './obj';

import {DEFAULT_MESSAGES_LAST_RECEIVED, DEFAULT_MESSAGES_LAST_READ} from
  '../../global-constants';

import RedactUtils from './redact-utils';

class Roles {
  static get TRAVELER() {return 'traveler';}
  static get GUIDE() {return 'guide';}
  static get ADMIN() {return 'admin';}
  static get ROOT() {return 'root';}
};


class User extends Obj {

  static get DEFAULT_MESSAGES_LAST_RECEIVED()
    {return DEFAULT_MESSAGES_LAST_RECEIVED};
  static get DEFAULT_MESSAGES_LAST_READ()
    {return DEFAULT_MESSAGES_LAST_READ};

  static get DISPLAY_NAME() {return "User"};
  static get DISPLAY_NAME_PLURAL() {return "Users"};
  static get COLLECTION_NAME() {return "users"};

  // These Users do not exist in the RxDB/PouchDB.
  static get PRIVATE_ID() {return PRIVATE_ID};
  static get DEFAULT_USER_ID() {return DEFAULT_USER_ID};

  static get FOSTER_USER_ID() {return FOSTER_USER_ID};
  static get SUPPORT_USER_ID() {return SUPPORT_USER_ID};
  static get ADMIN_USER_ID() {return ADMIN_USER_ID};
  static get ROOT_USER_ID() {return ROOT_USER_ID};

  static get PRIVATE() {
    if (User.privateUser == null) {
      User.privateUser = new User({
        _id:User.PRIVATE_ID,
        displayName:"Private User",
        details:"This traveler's visibility is "+
          '"Private".',
        imageSrc:ImageUtils.PLACEHOLDER_USER_PRIVATE_IMAGE,
        owner:User.PRIVATE_ID,
        creator:User.PRIVATE_ID,
        visibility:"isPrivate",
        userIds:[],
      });
    }
    return User.privateUser;
  }


  static get DEFAULT_FLAG_COUNTRY_CODE() {return "UN"};
  static get Roles() {return Roles};


  // UPDATE_THIS_WHEN_ADDING_PROPS
  static get DEFAULT_PROPS() {
    const defaultProps = Obj.DEFAULT_PROPS(USERS);
    // User specific props
    defaultProps.token = null;
    defaultProps.email = "name@company.com";
    defaultProps.password = "password1A";
    defaultProps.acceptedTerms = false;
    defaultProps.roles = [User.Roles.TRAVELER];
    defaultProps.flags = [User.DEFAULT_FLAG_COUNTRY_CODE];
    defaultProps.adventures = [];
    defaultProps.favorites = [];
    defaultProps.recommendations = [];
    defaultProps.guides = [];
    defaultProps.fellowTravelers = [];
    defaultProps.messages = [];
    defaultProps.messagesLastReceived = User.DEFAULT_MESSAGES_LAST_RECEIVED;
    defaultProps.messagesLastRead = User.DEFAULT_MESSAGES_LAST_READ;

    return (defaultProps);
  }

  static get DEFAULT_USER() {
    if (User._DEFAULT_USER == null) {
      User._DEFAULT_USER = new User(User.DEFAULT_PROPS);
    }
    return User._DEFAULT_USER;
  }

  static CALL_REDACT = false;

  constructor(userProps, curUserObj) {
    // Tell Obj constructor it should NOT call redact().
    // This User constructor will call redact().
    super(userProps, curUserObj, User.CALL_REDACT);
    log.trace("User constructor()");
    log.trace("User constructor() this: ", this);

    if (userProps && userProps.sub && userProps.nickname) {
      log.bug("User.constructor() called with an Auth0 object.");
      debugger;
    }
    else if (userProps && isRxDocument(userProps)) {
      // Constructor set the Obj props, now set the
      // User specific props.
      const rxdbUserProps = {};
      //rxdbUserProps.token = userProps.token;  // token is not in RxDB.

      // Users own themselves.  This makes RxQuery selectors
      // for creating a filtered list of users more simple.
      // Otherwise, a user whose status is pending or flagged
      // will not see themselves.
      rxdbUserProps.owner = userProps._id;

      // UPDATE_THIS_WHEN_ADDING_PROPS
      // Keep in sync with ApiUtils.objRxDBToObjC()
      rxdbUserProps.email = userProps.email;
      rxdbUserProps.password = userProps.password;
      rxdbUserProps.acceptedTerms = userProps.acceptedTerms;
      rxdbUserProps.roles = userProps.roles;
      rxdbUserProps.flags = userProps.flags;
      rxdbUserProps.adventures = userProps.adventures;
      rxdbUserProps.favorites = userProps.favorites;
      rxdbUserProps.recommendations = userProps.recommendations;
      rxdbUserProps.guides = userProps.guides;
      rxdbUserProps.fellowTravelers = userProps.fellowTravelers;
      rxdbUserProps.messages = userProps.messages;
      rxdbUserProps.messagesLastReceived = userProps.messagesLastReceived;
      rxdbUserProps.messagesLastRead = userProps.messagesLastRead;

      log.trace("Final rxdbUserProps:", rxdbUserProps);
      userProps = rxdbUserProps;
    }

    //Object.assign(this, User.DEFAULT_PROPS);

    // Override default props with passed in userProps.
    Object.assign(this, userProps);

    if (curUserObj && curUserObj instanceof User) {
      //log.trace("User constructor calling this.redact()");
      //this.redact(curUserObj);
      super.redact(curUserObj);
    }
    else {
      log.trace("NOT doing redaction on user");
    }
    log.trace("User.constructor(), returning User:", this);
  }

  get email() {
    return this._email ? this._email : "";
  }
  set email(email) {
    // Validation?
    this._email = email;
  }

  get password() {
    return this._password ? this._password : "";
  }
  set password(password) {
    // Validation?
    this._password = password;
  }

  get token() {
    return this._token ? this._token : null;
  }
  set token(token) {
    this._token = token;
  }

  // Returns a JavaScript Date object.
  get messagesLastReceived() {
    log.trace("get messagesLastReceived:", this._messagesLastReceived);
    if (this._messagesLastReceived === undefined ||
        this._messagesLastReceived === null) {
      this._messagesLastReceived = DEFAULT_MESSAGES_LAST_RECEIVED;
    }
    return this._messagesLastReceived;
  }
  set messagesLastReceived(messagesLastReceived) {
    this._messagesLastReceived =  Obj.convertAnythingToTime(
      messagesLastReceived, DEFAULT_MESSAGES_LAST_RECEIVED);
  }
  // Returns a JavaScript Date object.
  get messagesLastRead() {
    if (this._messagesLastRead === undefined ||
        this._messagesLastRead === null) {
      this._messagesLastRead = DEFAULT_MESSAGES_LAST_READ;
    }
    return this._messagesLastRead;
  }
  set messagesLastRead(messagesLastRead) {
    this._messagesLastRead =  Obj.convertAnythingToTime(
      messagesLastRead, DEFAULT_MESSAGES_LAST_READ);
  }

  get hasUnreadMessages() {
    if (this.messages && Array.isArray(this.messages) &&
        this.messages.length < 1) {
      // No messages at all.
      return false;
    }
    const messagesLastReceived = this.messagesLastReceived ?
      this.messagesLastReceived : 0;
    const messagesLastRead = this.messagesLastRead ?
      this.messagesLastRead : 0;
    const hasUnreadMessages = messagesLastReceived > messagesLastRead;
    return hasUnreadMessages;
  }

  get flags() {
    if (!this._flags || this._flags.length < 1) {
      this._flags = [User.DEFAULT_FLAG_COUNTRY_CODE];
    }
    return this._flags;
  }
  set flags(flags) {
    if (!flags || flags.length < 1) {
      flags = [User.DEFAULT_FLAG_COUNTRY_CODE];
    }
    this._flags = flags;
  }

  /**
   * @param {string} flag - A country code, usually two chars,
   * e.g. "US", but could be longer, e.g. "GB-ENG".
   */
  addFlag(flag) {
    const startIndex = this.flags.indexOf(flag);
    if (startIndex >= 0) {
      log.bug("User.addFlag("+flag+
        ") called when flag already exists in this.flags:",
        this.flags);
      return;
    }
    this.flags.push(flag);
  }

  replaceFlag(oldFlag, newFlag) {
    let startIndex = this.flags.indexOf(newFlag);
    if (startIndex >= 0) {
      log.bug("User.replaceFlag("+oldFlag+", "+newFlag+
        ") called when newFlag("+newFlag+") already exists in this.flags:",
        this.flags);
      return;
    }

    startIndex = this.flags.indexOf(oldFlag);
    if (startIndex < 0) {
      log.bug("User.replaceFlag("+oldFlag+", "+newFlag+
        ") could not find oldFlag("+oldFlag+") in this.flags:",
        this.flags);
      return;
    }
    const numElements = 1;
    this.flags.splice(startIndex, numElements, newFlag);
  }

  deleteFlag(flag) {
    const startIndex = this.flags.indexOf(flag);
    if (startIndex < 0) {
      log.bug("User.deleteFlag("+flag+
        ") called when flag does not exist in this.flags:",
        this.flags);
      return;
    }
    _.pull(this.flags, flag);
  }

  get details() {
    if (this._details) {return this._details}

    let typeString = "traveler";  // I18N
    if (this.hasRole(User.Roles.GUIDE)) {
      typeString += " and guide";
    }
    this._details = "No details about this "+typeString+" yet.";
    return this._details;
  }
  set details(details) {
    this._details = details;
  }

  get fellowTravelers() {
    return this._fellowTravelers ? this._fellowTravelers : [];
  }
  set fellowTravelers(fellowTravelers) {
    this._fellowTravelers = fellowTravelers;
  }
  get fellowTravelersText() {
    let fellowTravelersText = "No Fellow Travelers Yet"
    if (this._fellowTravelers && this._fellowTravelers.length == 1) {
      fellowTravelersText = this._fellowTravelers.length+" Fellow Traveler";
    }
    else if (this._fellowTravelers && this._fellowTravelers.length > 1) {
      fellowTravelersText = this._fellowTravelers.length+" Fellow Travelers";
    }
    return fellowTravelersText;
  }

  // isFellowTraveler
  isConnected(userId) {
    return _.includes(this.fellowTravelers, userId);
  }

  get adventures() {
    return this._adventures ? this._adventures : [];
  }
  set adventures(adventures) {
    this._adventures = adventures;
  }

  /*
  get completedAdventures() {
    const completedAdventures = _.filter(this.adventures,
      function(adventure) {
        if (adventure.completed) {
          return adventure;
        }
      });
    return completedAdventures;
  }

  get plannedAdventures() {
    const plannedAdventures = _.filter(this.adventures,
      function(adventure) {
        if (adventure.completed === false) {
          return adventure;
        }
      });
    return plannedAdventures;
  }

  get consideredAdventures() {
    const consideredAdventures = _.filter(this.adventures,
      function(adventure) {
        if (adventure.completed === undefined) {
          return adventure;
        }
      });
    return consideredAdventures;
  }
  */

  get completedAdventures() {
    if (this.adventures == null) {
      this.adventures = [];
    }
    return _.filter(this.adventures, adventure => adventure.completed === true);
  }

  get plannedAdventures() {
    if (this.adventures == null) {
      this.adventures = [];
    }
    return _.filter(this.adventures, adventure => adventure.completed === false);
  }

  get consideredAdventures() {
    if (this.adventures == null) {
      this.adventures = [];
    }
    return _.filter(this.adventures, adventure =>
      adventure.completed !== true && adventure.completed !== false);
  }

  get adventuresText() {
    log.trace("Enter get adventuresText()");
    let adventures = "No Adventures Yet"
    const count = this._adventures.length;
    if (count == 1) {
      adventures = count+" Adventure";
    }
    else if (count) {
      adventures = count+" Adventures";
    }
    log.trace("get adventuresText() return:", adventures);
    return adventures;
  }
  get completedAdventuresText() {
    let completed = "No Adventures Completed Yet"
    if (this.completedAdventures && this.completedAdventures.length == 1) {
      completed = this.completedAdventures.length+" Completed Adventure!";
    }
    else if (this.completedAdventures) {
      completed = this.completedAdventures.length+" Completed Adventures!";
    }
    return completed;
  }

  get plannedAdventuresText() {
    let planned = "No Adventures Planned Yet"
    if (this.plannedAdventures && this.plannedAdventures.length == 1) {
      planned = this.plannedAdventures.length+" Planned Adventure";
    }
    else if (this.plannedAdventures) {
      planned = this.plannedAdventures.length+" Planned Adventures";
    }
    return planned;
  }

  get consideredAdventuresText() {
    let considered = "No Adventures Considered Yet"
    if (this.consideredAdventures && this.consideredAdventures.length == 1) {
      considered = this.consideredAdventures.length+" Considered Adventure";
    }
    else if (this.consideredAdventures) {
      considered = this.consideredAdventures.length+" Considered Adventures";
    }
    return considered;
  }

  isSignedIn() {
    // This should check the validity of the token, but it really
    // doesn't matter because the server has to guard against illegal
    // calls anyway.
    return this.token != null && this.token.trim() != "";
  }

  hasRole(role) {
    if (!this.roles) {
      return false;
    }
    return this.roles.indexOf(role) >= 0;
  }

  /**
   * This is simply a short way of answering the question:
   * Does the user have role "admin" or "root"?
   */
  hasAdminPriv() {
    const roles = this.roles || [];
    if (roles.indexOf(Roles.ADMIN) >= 0 ||
        roles.indexOf(Roles.ROOT) >= 0) {
      return true;
    }
    return false;
  }

  /**
   * This user can create objects.  I.e. hasAdminPriv() or
   * is a guide.
   */
  canCreateObj() {
    if (this.hasAdminPriv() || this.hasRole("guide")) {
      return true;
    }
    return false;
  }

  isHardCodedUser() {
    return this._id == User.SUPPORT_USER_ID ||
      this._id == User.FOSTER_USER_ID ||
      this._id == User.ADMIN_USER_ID ||
      this._id == User.ROOT_USER_ID;
  }

  /**
   * Remove all properties from this User object that the passed in
   * user "curUserObj" cannot view.  E.g. if the passed in curUserObj
   * is not a root or admin, and is not this user, then this redact()
   * function will set the value of this.email to false.
   *
   * @param {User} curUserObj - The User object that is the currently
   * logged in user.  E.g. Can the passed in user see this user's email?
   */
   /*
  redact(curUserObj) {
    super.redact(curUserObj);
    log.trace("User.redact()");
    this.redactActions(curUserObj);
    this.redactProps(curUserObj);
  }
  */
  redactActions(curUserObj) {
    super.redactActions(curUserObj);
    log.trace("User.redactActions()");

    // We "redact" a function by setting it to false.
    // When the client side sees this.sendMessage == true, it
    // knows it can us the cA_SendMessage function, and if it
    // wants, set this.sendMessage to that action dispatcher.

    // Can curUser send a message to this user?
    this.redactSendMessage(curUserObj);
    // Can curUser accept a connection request from this user?
    this.redactAcceptConnection(curUserObj);
    if (!this.acceptConnection) {
      // Can curUser send a connection request to this user?
      this.redactConnect(curUserObj);
    }
    // Can curUser disconnect from this user?
    this.redactDisconnect(curUserObj);
    // Can curUser view this user's messages?
    this.redactViewMessages(curUserObj);
    // Can curUser send an email to this user?
    this.redactEmail(curUserObj);
    // Can curUser delete this user?
    this.redactDeleteUser(curUserObj);
    // Can curUser edit this user?
    this.redactEdit(curUserObj);
    // Can curUser view this user?
    this.redactView(curUserObj);
    // Can curUser view this user's favorites?
    this.redactViewFavorites(curUserObj);
    // Can curUser view this user's recommendations?
    this.redactViewRecommendations(curUserObj);
    
    // You cannot flag yourself or a staff member.
    if (this._id == curUserObj._id || this.isHardCodedUser()) {
      this.flag = false;
    }
    else {
      this.flag = true;
    }

    // Can curUser view this user's guides list?
    this.redactViewGuides(curUserObj);
    this.redactViewFollowers(curUserObj);

    // NOTE: You cannot call this function until you have already
    // called redactSendMessage() and redactConnect().  It uses
    // the values set by those functions.
    // Can curUser create a message?
    //this.redactCreateMessage(curUserObj);
  }
  redactProps(curUserObj) {
    super.redactProps(curUserObj);
    log.trace("User.redactProps()");
    const visibility = this.visibility;
    log.trace("User.redactProps(), visibility:", visibility);

    const signedIn = RedactUtils.isSignedIn(curUserObj);
    const isOwnerOrAdmin = RedactUtils.isOwnerOrAdmin(curUserObj, this);
    log.trace("User.redactProps(), isOwnerOrAdmin:", isOwnerOrAdmin);
    if (isOwnerOrAdmin) {
      // Don't redact anything.
      return;
    }

    switch (visibility) {
      case "isPrivate":
        this.imageSrc = User.PRIVATE.imageSrc;
        this.avatarSrc = undefined;
        this.displayName = User.PRIVATE.displayName;
        this.details = User.PRIVATE.details;
        this.email = undefined;
        //this.created = REDACTED;
        this.urls = [];
        this.tags = [];
        this.owner = User.PRIVATE.owner;
        this.creator = User.PRIVATE.creator;
        this.acceptedTerms = undefined;
        this.roles = [];
        this.flags = [];
        this.favorites = [];
        this.adventures = [];
        this.fellowTravelers = [];
        this.messages = [];
        // Fall through
      case "isProtected":
        this.locationPoint = undefined;//User.REDACTED;
        break;

      case "isPublic":
        // Fall through
      default:
        // Public, so do not redact any props.
    }
  }
  /**
   * Replace this User object's email property with false if
   * the passed in curUserObj cannot view it.  If the curUserObj
   * is allowed to view this user's email property, then this user
   * is not affected by this function.
   */
  redactEmail(curUserObj) {
    //if (User.strId(curUserObj) == this._id) {
    if (isEqualIds(curUserObj, this)) {
      // A user can see their own email address.
      //this.email = true;
      return;
    }
    if (curUserObj.hasAdminPriv()) {
      // Root/admin can see any user's email address.
      //this.email = true;
      return;
    }
    this.email = false;
  }

  /**
   * Can curUserObj send a message to this User object?
   * "Redacts" this object's sendMessage() function if curUserObj
   * is not allowed to call sendMessage().
   */
  redactSendMessage(curUserObj) {
    // When this function is called on the server side, the
    // "id" properties are not String objects or string literals.
    // They can be Mongoose "ObjectID" objects.  Because of that the
    // operator == will not cast them to Strings.  Which means two ids
    // that are the same string will not be "equal".  Fix this by
    // explicitly casting them to strings.
    // Might not be needed any more because I am "fixing" the _id
    // values in the objects themselves.
    //const senderId = ""+curUserObj._id;
    //const receiverId = ""+this._id;
    //const senderId = User.strId(curUserObj);
    const senderId = strId(curUserObj);
    const receiverId = this._id;
    log.trace("redactSendMessage(), senderId:", senderId);
    log.trace("redactSendMessage(), receiver:", this.displayName);
    log.trace("redactSendMessage(), this.view:", this.view);

    this.sendMessage = false;
    if (senderId == null || isEqualIds(curUserObj, User.DEFAULT_USER_ID)) {
      // An anonymous user cannot message anyone.
      return;
    }
    if (receiverId == null || isEqualIds(this, User.DEFAULT_USER_ID)) {
      // No one can message the default/anon user.
      return;
    }
    if (isEqualIds(curUserObj, this)) {
      // A user cannot message themselves.
      return;
    }
    if (curUserObj.statusAttribute != APPROVED &&
        !this.isHardCodedUser()) {
      // A user that is not approved, i.e. a flagged or pending user,
      // cannot send messages to non-hardcoded users.
      return;
    }
    if (!this.view) {
      // If curUser cannot even view this user, then
      // the curUser cannot message them.
      return;
    }

    log.trace("Calling this.sendMessage = true");
    this.sendMessage = true;
  }
  redactConnect(curUserObj) {
    //const senderId = User.strId(curUserObj);
    const senderId = strId(curUserObj);
    const receiverId = this._id;
    log.trace("redactConnect(), senderId:", senderId);
    log.trace("redactConnect(), receiverId:", receiverId);

    this.connect = false;
    //if (senderId == null || senderId == User.DEFAULT_USER_ID) {
    if (senderId == null || isEqualIds(curUserObj, User.DEFAULT_USER_ID)) {
      // An anonymous user cannot connect with anyone.
      return;
    }
    //if (receiverId == null || receiverId == User.DEFAULT_USER_ID) {
    if (receiverId == null || isEqualIds(this, User.DEFAULT_USER_ID)) {
      // No one can connect with the default/anon user.
      return;
    }
    //if (senderId == receiverId) {
    if (isEqualIds(curUserObj, this)) {
      // A user cannot connect with themselves.
      return;
    }
    if (curUserObj.isConnected && curUserObj.isConnected(receiverId)) {
      // This user is already connected to the receiver.
      // NOTE:  Why am I calling curUserObj.isConnected(receiverId)
      // instead of this.isConnected(receiverId)?
      return;
    }
    if (this.connectionPending(senderId)) {
      // curUser has already requested a connection with "this" user,
      // so curUser cannot send another connection request.
      // NOTE: If this user deletes the connection request, then
      // curUser can send another one.
      return;
    }
    if (curUserObj.statusAttribute != APPROVED) {
      // A user that is not approved, i.e. a flagged or pending user,
      // cannot connect with anyone.
      return;
    }
    if (this.isHardCodedUser() || curUserObj.isHardCodedUser()) {
      // No one can connect with the hardcoded users nor
      // can hardcoded users connect with anyone.
      return;
    }
    if (!this.view) {
      // If curUser cannot even view this user, then
      // the curUser cannot message them.
      return;
    }
    log.trace("Calling this.connect = true");
    this.connect = true;
  }

  /**
   * @param {string} senderId - The user who requested the connection
   * with "this" user.
   */
  connectionPending(senderId) {
    senderId = strId(senderId);
    log.trace("connectionPending("+senderId+"), this:", this);
    // See if we are already connected.
    if (this.isConnected(senderId)) {
      // This user is already connected to the "sender".
      return false;
    }

    // We are not already connected, see if there is a
    // connection request in our messages.
    // (A message with task == "connect")
    const messages = this.messages;
    if (!messages || messages.length < 1) {
      return false;
    }
    for (let i = 0; i < messages.length; i++) {
      const message = messages[i];
      log.trace("message.task:", message.task);
      log.trace("message.senderId:", message.senderId);
      if (message.task == "connect" &&
          isEqualIds(message.senderId, senderId)) {

        log.trace("connectionPending() returning: true");
        return true;
      }
    }
    log.trace("connectionPending() returning: false");
    return false;
  }

  redactAcceptConnection(curUserObj) {
    //const receiverId = User.strId(curUserObj);
    const receiverId = strId(curUserObj);
    const senderId = this._id;
    log.trace("redactAcceptConnection(), senderId:", senderId);
    log.trace("redactAcceptConnection(), receiverId:", receiverId);

    //if (senderId == receiverId) {
    if (isEqualIds(curUserObj, this)) {
      // A user cannot connect with themselves.
      this.acceptConnection = false;
      return;
    }
    if (curUserObj.isConnected && curUserObj.isConnected(senderId)) {
      // This user is already connected to the "sender".
      this.acceptConnection = false;
      return;
    }

    const messages = curUserObj.messages;
    if (!messages || messages.length < 1) {
      this.acceptConnection = false;
      return;
    }
    for (let i = 0; i < messages.length; i++) {
      const message = messages[i];
      log.trace("message.task:", message.task);
      log.trace("message.senderId:", message.senderId);
      if (message.task == "connect" &&
          //User.strId(message.senderId) == this._id) {
          isEqualIds(message.senderId, this)) {
        this.acceptConnection = true;
        return;
      }
    }

    this.acceptConnection = false;
  }
  redactDisconnect(curUserObj) {
    //const senderId = User.strId(curUserObj);
    const senderId = strId(curUserObj);
    const receiverId = this._id;
    log.trace("redactDisconnect(), senderId:", senderId);
    log.trace("redactDisconnect(), receiverId:", receiverId);

    if (curUserObj.isConnected && curUserObj.isConnected(receiverId)) {
      // This user is connected to the receiver.
      this.disconnect = true;
    }
    else {
      this.disconnect = false;
    }
  }

  redactViewMessages(curUserObj) {
    const viewerId = strId(curUserObj);  // User who wants to view messages.
    const userId = this._id;  // This User who "owns" the messages.
    log.trace("redactSendMessage(), viewerId:", viewerId);
    log.trace("redactSendMessage(), userId:", userId);

    this.viewMessages = false;
    //if (viewerId == null || viewerId == User.DEFAULT_USER_ID) {
    if (viewerId == null || isEqualIds(curUserObj, User.DEFAULT_USER_ID)) {
      // An anonymous user cannot view anyone's messages.
      return;
    }
    //if (userId == null || userId == User.DEFAULT_USER_ID) {
    if (userId == null || isEqualIds(this, User.DEFAULT_USER_ID)) {
      // The default/anonymous user has no messages.
      return;
    }
    if (isEqualIds(curUserObj, this) && this.messages &&
        this.messages.length > 0) {
      // This user has messages in their own message array.
      this.viewMessages = true;
      return;
    }
    //if (viewerId != userId && !curUserObj.hasAdminPriv()) {
    if (!isEqualIds(curUserObj, this) && !curUserObj.hasAdminPriv()) {
      // The curUserObj is not this user AND curUserObj does NOT
      // have admin privileges, so s/he can't view another user's
      // messages.
      return;
    }
    if (this.hasRole(User.Roles.ROOT)) {
      // No other user can read root messages.
      return;
    }
    if (!this.messages || this.messages.length < 1) {
      return;
    }
    this.viewMessages = true;
  }

  // Only an admin or the user him/herself can view the guides list.
  redactViewGuides(curUserObj) {
    const viewerId = strId(curUserObj);  // User who wants to view guides.
    const userId = this._id;  // This User who "owns" the guides list.
    this.viewGuides = false;

    if (viewerId == null || isEqualIds(curUserObj, User.DEFAULT_USER_ID)) {
      // An anonymous user cannot view anyone's guides list.
      return;
    }
    if (userId == null || isEqualIds(this, User.DEFAULT_USER_ID)) {
      // The default/anonymous user has no guides list.
      return;
    }
    if (!isEqualIds(curUserObj, this) && !curUserObj.hasAdminPriv()) {
      // The curUserObj is not this user AND curUserObj does NOT
      // have admin privileges, so s/he can't view another user's
      // guides.
      return;
    }
    if (this.hasRole(User.Roles.ROOT)) {
      // No other user can view the root user's guides.
      return;
    }
    /*
    if (!this.guides || this.guides.length < 1) {
      // There are no guides to view.
      return;
    }
    */
    this.viewGuides = true;
  }

  /**
   * Only an admin or the guide him/herself can view the
   * followers list.
   * NOTE: There is no followers "list".  We must search
   * through all users to find users who have this guide
   * as one of their guides.
   */
  redactViewFollowers(curUserObj) {
    const viewerId = strId(curUserObj);
    this.viewFollowers = false;

    if (!this.hasRole(User.Roles.GUIDE)) {
      // This user is not a guide, so s/he has no followers.
      return;
    }

    if (viewerId == null || isEqualIds(curUserObj, User.DEFAULT_USER_ID)) {
      // An anonymous user cannot view anyone's followers list.
      return;
    }
    if (this._id == null || isEqualIds(this, User.DEFAULT_USER_ID)) {
      // The default/anonymous user has no followers list.
      return;
    }
    if (!isEqualIds(curUserObj, this) && !curUserObj.hasAdminPriv()) {
      // The curUserObj is not this user AND curUserObj does NOT
      // have admin privileges, so s/he can't view another user's
      // followers.
      return;
    }
    if (this.hasRole(User.Roles.ROOT)) {
      // No other user can view the root user's followers.
      return;
    }
    /*
    const followers = function to get follower IDs
    if (!followers || followers.length < 1) {
      // There are no followers to view.
      return;
    }
    */
    this.viewFollowers = true;
  }

  // Only an admin or the user him/herself can view the userIds list.
  redactViewFavorites(curUserObj) {
    const viewerId = strId(curUserObj);  // User who wants to view favorites.
    const userId = this._id;  // This User who "owns" the favorites list.

    this.viewFavorites = false;

    if (viewerId == null || isEqualIds(curUserObj, User.DEFAULT_USER_ID)) {
      // An anonymous user cannot view anyone's favorites.
      return;
    }
    if (userId == null || isEqualIds(this, User.DEFAULT_USER_ID)) {
      // The default/anonymous user has no favorites list.
      return;
    }
    if (isEqualIds(curUserObj, this) || curUserObj.hasAdminPriv()) {
      this.viewFavorites = true;
      return;
    }
    if (this.hasRole(User.Roles.ROOT)) {
      // No other user can view the root user's favorites.
      return;
    }
    if (!this.favorites || this.favorites.length < 1) {
      // There are no favorites to view.
      return;
    }
    if (this.visibility &&
        (this.visibility == "isProtected" ||
        this.visibility == "isPrivate") &&
        !this.isConnected(curUserObj._id) &&
        !this.isInUserIds(curUserObj._id)) {
      return;
    }
    this.viewFavorites = true;
  }

  // Only an admin, a user's guide, or the user him/herself
  // can view the recommendations.
  redactViewRecommendations(curUserObj) {
    // User who wants to view recommendations.
    const viewerId = strId(curUserObj);
    // This User who "owns" the recommendations list.
    const userId = this._id;
    this.viewRecommendations = false;

    if (viewerId == null || isEqualIds(curUserObj, User.DEFAULT_USER_ID)) {
      // An anonymous user cannot view anyone's recommendations.
      return;
    }
    if (userId == null || isEqualIds(this, User.DEFAULT_USER_ID)) {
      // The default/anonymous user has no recommendations list.
      return;
    }
    if (isEqualIds(curUserObj, this) || curUserObj.hasAdminPriv() ||
        (this.guides && Array.isArray(this.guides) &&
         this.guides.includes(curUserObj._id))) {
      this.viewRecommendations = true;
      return;
    }
    if (this.hasRole(User.Roles.ROOT)) {
      // No other user can view the root user's recommendations.
      return;
    }
    if (!this.recommendations || this.recommendations.length < 1) {
      // There are no recommendations to view.
      return;
    }
    if (this.visibility &&
        (this.visibility == "isProtected" ||
        this.visibility == "isPrivate") &&
        !this.isConnected(curUserObj._id) &&
        !this.isInUserIds(curUserObj._id)) {
      return;
    }
    this.viewRecommendations = true;
  }

  redactDeleteUser(curUserObj) {
    log.trace("User.redactDeleteUser(), curUserObj: ", curUserObj);
    log.trace("User.redactDeleteUser(), this: ", this);
    //this.deleteUser = false;
    this.deleteObj = false;
    if (this._id == User.DEFAULT_USER_ID) {
      // The default user cannot be deleted.
      return;
    }
    //if (User.strId(curUserObj) == this._id) {
    if (isEqualIds(curUserObj, this)) {
      // A user cannot delete themselves, even if they are root/admin.
      return;
    }
    if (this.hasRole(User.Roles.ROOT)) {
      // No one can delete a root user, even another root user.
      return;
    }
    if (this.hasRole(User.Roles.ADMIN) &&
        !curUserObj.hasRole(User.Roles.ROOT)) {
      // Only a root user can delete admin users.
      return;
    }
    if (!curUserObj.hasAdminPriv()) {
      // Must be an admin to delete another user.
      return;
    }
    //this.deleteUser = true;  // Get rid of deleteUser flag.
    this.deleteObj = true;
  }

  redactEdit(curUserObj) {
    this.edit = false;
    //if (!this._id || this._id == User.DEFAULT_USER_ID) {
    if (!this._id || isEqualIds(this, User.DEFAULT_USER_ID)) {
      // The default user cannot be edited.
      return;
    }
    //if (User.strId(curUserObj) == this._id) {
    if (isEqualIds(curUserObj, this)) {
      // A user can always edit themselves.
      this.edit = true;
      return;
    }
    const hasAdminPriv = curUserObj.hasAdminPriv();
    if (hasAdminPriv && !this.hasRole(User.Roles.ROOT)) {
      // A user with admin privileges can edit another user/admin,
      // but cannot edit root.
      this.edit = true;
      return;
    }
    // Either the user is not an admin who is trying to edit another
    // user, or the user is an admin, but s/he is trying to edit root.
    this.edit = false;
  }

  redactView(curUserObj) {
    this.view = false;
    if (!this._id || this._id == User.DEFAULT_USER_ID) {
      // The default user can always be viewed.
      this.view = true;
      return;
    }
    if (!curUserObj._id) {
      // A user who is not logged in, e.g. anonymous/default, cannot
      // view any non-anonymous user.
      return;
    }
    const hasAdminPriv = curUserObj.hasAdminPriv();
    if (!hasAdminPriv &&
        (this._id == User.ADMIN_USER_ID || this._id == User.ROOT_USER_ID)) {
      return;
    }
    this.view = true;
  }

}

export default User;
