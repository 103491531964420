import _ from 'lodash';
import {log} from 'concierge-common';

/**
 * Utilities associated with the URL of the pages displayed by
 * the app.
 *
 * NOTE: Nothing to do with the "url buttons" displayed on a
 * Act component in the UI.
 */
class UrlUtils {

  static appendUrlParams(url = "", props = {}) {
    const params = [];
    _.forOwn(props, (value, key)=>{
      const prefix = params.length ? "&" : "?";
      const param = prefix+key+"="+value;
      params.push(param);
    });

    log.trace("params:", params);
    for (let i = 0; i < params.length; i++) {
      url += params[i];
    }
    log.trace("url:", url);
    return url;
  }

  static parseQuery(queryString) {
    var query = {};
    var pairs = (queryString[0] === '?' ?
      queryString.substr(1) : queryString).split('&');
    for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split('=');
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
  }

  /**
   * match = {
   *   params:{
   *     id:"1234"
   *   },
   *   path:"/act/:id",
   *   url:"/act/1234"
   * }
   */
  static getUrlParameters(match, location) {
    log.trace("match: ", match);
    log.trace("location: ", location);

    let urlParameters = {
      name:"home",
    };

    if (!match) {
      return urlParameters;
    };
    const parts = match.url.split("/");
    log.trace("parts:", parts);
    if (parts.length >= 2) {
      urlParameters.name = parts[1];
    }

    if (location) {
      const query = UrlUtils.parseQuery(location.search);
      log.trace("query:", query);
      Object.assign(urlParameters, query);
    }

    log.trace("UrlUtils.getUrlParameters() returning:", urlParameters);
    return urlParameters;
  }

}

export default UrlUtils;
