/**
 * This component displays information about a single User.
 */
//import $ from 'jquery';
import _ from 'lodash';
import React from 'react';
import {Popup} from 'semantic-ui-react';
import {log} from 'concierge-common';
import ObjUtils from '../client-db/api/obj-utils';
import MyCardContent from './my-card-content';
import Flag from './flag';


class FellowTravelersComponent extends React.Component {

  calcFellowTravelerUniqueFlags(fellowTravelers) {
    const {curUser} = this.props;
    let uniqueFlags = [];
    for (let i = 0; i < fellowTravelers.length; i++) {
      const fellowTraveler = ObjUtils.getUser(curUser, fellowTravelers[i]);
      if (fellowTraveler == null) {
        continue;
      }
      const flags = fellowTraveler.flags;
      if (!flags || flags.length < 1) {
        continue;
      }
      log.trace("fellowTraveler.flags:", flags);
      for (let j = 0; j < flags.length; j++) {
        uniqueFlags = _.union(uniqueFlags, [flags[j]]);
      }
    }
    return uniqueFlags;
  }

  createFellowTravelerFlags(uniqueFlags) {
    const component =
      <span className="flags">
        {_.map(uniqueFlags, (flag, index) => {
          return(
            <Flag
              countryCode={flag}
              disabled={true}
              key={index}
            />
          )
        })}
      </span>
    return component;
  }

  createFellowTravelersPopup(user, uniqueFlags) {
    let countries;
    if (uniqueFlags.length == 0 ||
        (uniqueFlags.length == 1 && uniqueFlags[0] == "NONE")) {
      countries = " an unknown country:";
    }
    else if (uniqueFlags.length == 1) {
      countries = " 1 country:";
    }
    else {
      countries = uniqueFlags.length+" countries:";
    }

    let text;
    if (user.fellowTravelers.length == 0) {
      text = user.displayName+" has no fellow travelers yet.";
    }
    else if (user.fellowTravelers.length == 1) {
      text = user.displayName+" has 1 fellow traveler from "+countries;
    }
    else {
      text = user.displayName+" has "+user.fellowTravelers.length+
        " fellow travelers from "+countries;
    }
    const flags = this.createFellowTravelerFlags(uniqueFlags);
    const fellowTravelersPopup = <div>{text}{flags}</div>
    return fellowTravelersPopup;
  }

  componentDidMount() {
    this.checkForOverflow();
  }
  componentDidUpdate() {
    this.checkForOverflow();
  }

  checkForOverflow() {
    const el = this.span;
    log.trace("el.offsetWidth:", el.offsetWidth);
    //log.trace("$(el).outerWidth:", $(el).outerWidth());
    //log.trace("$(el).outerWidth(true):", $(el).outerWidth(true));
    log.trace("el.clientWidth:", el.clientWidth);
    log.trace("el.scrollWidth:", el.scrollWidth);
    if (el.scrollWidth > el.offsetWidth) {
      el.parentElement.classList.add("overflows");
    }
    else {
      el.parentElement.classList.remove("overflows");
    }
  }

  render() {
    log.trace("FellowTravelersComponent.render() this.props:", this.props);
    const props = this.props;
    const {user, fellowTravelersText} = props;

    const uniqueFlags = this.calcFellowTravelerUniqueFlags(
      user.fellowTravelers);
    log.trace("uniqueFlags:", uniqueFlags);
    const fellowTravelerFlags = this.createFellowTravelerFlags(uniqueFlags);
    const fellowTravelersPopupContent = this.createFellowTravelersPopup(
      user, uniqueFlags);
    const overflowIndicator = <span className="overflow-indicator">...</span>

    return(
      <span className="fellow-travelers">
        <span className="fellow-travelers-inner"
          ref={span => this.span = span}
        >
          <MyCardContent iconName="users"
            text={fellowTravelersText}
            editable={false} />
          {fellowTravelerFlags}
        </span>
        <Popup on="hover" className="fellow-travelers-popup"
          content={fellowTravelersPopupContent}
          trigger={overflowIndicator}
        />
      </span>
    );
  };
}

export default FellowTravelersComponent;
