import _ from 'lodash';
import React from 'react';
import {Link} from 'react-router-dom';
import {Icon, Image, Menu} from 'semantic-ui-react';
import {log} from 'concierge-common';
import {DEFAULT_USER_ID} from '../../global-constants';
import {IMG_USER, IMG_USERS, IMG_WAYS, IMG_ACTS, IMG_ITNS} from
  '../../global-constants';
import LoginSignupLogoutButton from '../../containers/login-signup-logout-button';
import {isEqualIds} from '../../utils/string-utils';
import ObjUtils from '../../client-db/api/obj-utils';
//import User from '../../client-db/api/user';


/**
 * NOTE: Which items are shown in the navbar/sidebar are set by the
 * concierge/src/utils/ui-state.js module.  E.g. LEFT_ITEMS_SIGNED_IN
 */
class NavbarUtils {

  static getItems(curUser/*props*/) {
    const curUserId = curUser && curUser._id ?
      curUser._id : DEFAULT_USER_ID;

    log.trace("curUserId: ", curUserId);

    //const favoritesTitle = curUser && curUser.displayName ?
    //  curUser.displayName+"'s Favorites

    // TODO: This should return an ordered array, not an object.
    return({
      /*
      signUp:{as: "label", content: "Sign Up", key: "signUp",
        to:"/signUp"},
      signIn:{as: "label", content: "Sign In", key: "signIn",
        to:"/signIn"},
      signOut:{as: "label", content: "Sign Out", key: "signOut",
        to:"/signedOut"},
      */

      home:{
        menuItemProps:{as:"label", key:"home",
          to:"/home"
        },
        menuItemLabel:"Home",
        //imageProps:{src:"../../img/navbar/home.png", spaced:"left"}
        iconProps:{name:"home"}
      },
      favorites:{
        menuItemProps:{as:"label", key:"favorites",
          to:"/anys",
          search:"objId="+curUserId+
          "&curUserId="+curUserId+
          "&objPropName=favorites"+
          "&title=Your Favorites"
        },
        menuItemLabel:"Favorites",
        //imageProps:{src:"../../img/navbar/favorites.png", spaced:"left"}
        //imageProps:{src:"../../"+IMG_FAVS, spaced:"left"}
        iconProps:{name:"heart outline"}
      },
      ways:{
        menuItemProps:{as:"label", key:"ways",
          //to:"/objs?cn=ways"
          to:"/objs",
          search:"cn=ways",
        },
        menuItemLabel:"Waypoints",
        //imageProps:{src:"../../img/navbar/ways.png", spaced:"left"}
        imageProps:{src:"../../"+IMG_WAYS, spaced:"left"}
      },
      acts:{
        menuItemProps:{as:"label", key:"acts",
          to:"/objs",
          search:"cn=acts",
        },
        menuItemLabel:"Adventures",
        //imageProps:{src:"../../img/navbar/acts.png", spaced:"left"}
        imageProps:{src:"../../"+IMG_ACTS, spaced:"left"}
      },
      itns:{
        menuItemProps:{as:"label", key:"itns",
          to:"/objs",
          search:"cn=itns",
        },
        menuItemLabel:"Itineraries",
        //imageProps:{src:"../../img/navbar/itns.png", spaced:"left"}
        imageProps:{src:"../../"+IMG_ITNS, spaced:"left"}
      },
      curUser:{  // Current user editing themselves.
        menuItemProps:{as:"label", key:"curuser",
          //to:"/curUser"
          to:"/obj",
          search:"objId="+curUserId,
        },
        menuItemLabel:"My Profile",
        imageProps:{src:"../../"+IMG_USER, spaced:"left"}
      },
      users:{
        menuItemProps:{as:"label", key:"users",
          //to:"/users"
          to:"/objs",
          search:"cn=users",
        },
        menuItemLabel:"Users",
        //imageProps:{src:"../../img/navbar/users.png", spaced:"left"}
        imageProps:{src:"../../"+IMG_USERS, spaced:"left"}
        //iconProps:{name:"users"}
      },
      viewMessages:{
        menuItemProps:{as:"label", key:"viewMessages",
          to:"/viewMessages",
          search:"userId="+curUserId,
        },
        menuItemLabel:"Messages",
        //imageProps:{src:"../../img/navbar/contact.png", spaced:"left"}
        iconProps:{name:"mail outline"}
      },
      /*
      contact:{
        menuItemProps:{as:"label", key:"contact",
          to:"/contact"
        },
        menuItemLabel:"Contact",
        imageProps:{src:"../../img/navbar/contact.png", spaced:"left"}
      },
      about:{
        menuItemProps:{as:"label", key:"about",
          to:"/about"
        },
        menuItemLabel:"About",
        imageProps:{src:"../../img/navbar/about.png", spaced:"left"}
      },
      */

    })
  }

  /**
   * Create the array of menu items based on the passed in list
   * of names.
   *
   * @param {String[]} An array of names like: ["signUp", "signIn"]
   * or ["home", "contact", "users", "acts", "about"].
   */
  static getMenuItems(curUser, itemNames = []/*, props*/) {
    const selectedItems = [];
    itemNames.forEach((name) => {
      const selectedItem = this.getItems(curUser, /*props*/)[name];
      if (selectedItem != null) {
        selectedItems.push(selectedItem);
      }
      else {
        log.error("Unknown menu item name:", name);
      }
    });
    return selectedItems;
  }

  /**
   * Create left/sidebar menu items such as "Home", "Adventures",
   * "Users", "Messages".  
   * NOTE: everything except for the LoginSignupLogutButton.
   */
  static createMenuItems(curUser, items, hideSidebar) {
    log.trace("createMenuItems(), curUser:", curUser);
    const menuItems = _.map(items, item => {
      let className = "";
      if (curUser && curUser._id &&
          item.menuItemProps.key == "viewMessages") {
        // Check whether we need to display the "notify" version
        // of the Messages menu item.
        //const userC = ObjUtils.getUser(curUser, curUser._id);
        const userC = ObjUtils.get(curUser, curUser._id);
        log.trace("createMenuItems(), userC:", userC);
        if (userC && !isEqualIds(userC._id, DEFAULT_USER_ID)) {
          /*
          log.trace("userC.messagesLastReceived:", userC.messagesLastReceived);
          log.trace("userC.messagesLastRead:", userC.messagesLastRead);
          const messagesLastReceived = userC.messagesLastReceived ?
            userC.messagesLastReceived : 0;
          log.trace("userC.messagesLastRead:", userC.messagesLastRead);
          log.trace("typeof userC.messagesLastRead:",
            typeof userC.messagesLastRead);
          const messagesLastRead = userC.messagesLastRead ?
            userC.messagesLastRead : 0;
            
          log.trace("messagesLastReceived:", messagesLastReceived);
          log.trace("messagesLastRead:", messagesLastRead);
          const hasUnreadMessages = messagesLastReceived > messagesLastRead;
          */
          const hasUnreadMessages = userC.hasUnreadMessages;
          className = hasUnreadMessages ? "notify" : "";
          log.trace("createMenuItems(), className:", className);
        }
      }
      const image = item.imageProps ?
        <Image {...item.imageProps} /> :
        undefined;
      const icon = item.iconProps ?
        <Icon {...item.iconProps} /> :
        undefined;
      log.trace("item.menuItemProps.to: ", item.menuItemProps.to);
      return(
        <Link to={{
            pathname:item.menuItemProps.to,
            search:item.menuItemProps.search,
            /*
            state:{
              stateProp1:
                "LinkAddsThisToLocation_NothingToDoWithReduxStoreState"
            },
            */
            someOtherProp:{
              biz:"ThisAddedAlso_DontThink 'state:' IsSpecial"
            },
          }}
          key={item.menuItemProps.key}
          onClick={hideSidebar}
        >
          <Menu.Item {...item.menuItemProps} className={className}>
            {item.menuItemLabel}
            {image}
            {icon}
          </Menu.Item>
        </Link>
      );
    });
    log.trace("createMenuItems(), returning menuItems: ", menuItems);
    return menuItems;
  }

  /**
   * Create right menu item which contains just the
   * LoginSignupLogoutButton.
   */
  static createLoginSignupLogoutMenu() {
    log.trace("Enter createLoginSignupLogoutMenu()");
    
    const menuItems = [];

    // Auth0 button
    const loginSignupLogoutButton =
      <div key="loginSignupLogout">
        <LoginSignupLogoutButton />
      </div>
    menuItems.push(loginSignupLogoutButton);

    const rightMenu =
      <Menu.Menu position="right">
        {menuItems}
      </Menu.Menu>
    log.trace("createLoginSignupLogoutMenu(), returning rightMenu: ",
      rightMenu);
    return rightMenu;
  }
}

export default NavbarUtils;
