import _ from 'lodash';

// Google Firebase Stuff Below
// Only needed if uploadFrom == "client".
import firebase from 'firebase/app';
//import thisNotUsed1 from 'firebase/auth';
//import thisNotUsed2 from 'firebase/storage';
// Google Firebase Stuff Above

import loadImage from 'image-promise';

import {ImageUtils as ImageUtilsCom} from 'concierge-common';
import {log, ConciergeRestApi, MyError} from 'concierge-common';

//import {cA_SetError} from '../actions/error';
//import {cA_SetLoader} from '../actions/ui';

import ImageTools from './image-tools';
import ErrorUtils from './error-utils';

// Just for testing delay handling.
//function sleep(ms) {
//  return new Promise(resolve => setTimeout(resolve, ms));
//}

class ImageUtils {

  static readAsDataURLPromise(file) {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {

      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };

      temporaryFileReader.readAsDataURL(file);
    });
  };

  /**
   * @param {string} base64DataUrl - E.g. data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEBLAEsAAD/2...
   * @param {boolean} clockwise - Rotation direction.  I.e true or false.
   */
  static async rotate90Degrees(base64DataUrl, clockwise) {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const image = new Image();

    log.trace("base64DataUrl:", base64DataUrl);
    image.src = base64DataUrl;
    /*const loadedImage = */await loadImage(image);

    canvas.width = image.height;
    canvas.height = image.width;
    if (clockwise) {
      ctx.rotate(90 * Math.PI / 180);
      ctx.translate(0, -canvas.width);
    }
    else {
      ctx.rotate(-90 * Math.PI / 180);
      ctx.translate(-canvas.height, 0);
    }
    ctx.drawImage(image, 0, 0);

    const quality = ImageUtils.JPG_QUALITY_CANVAS;  // 0 to 1
    return canvas.toDataURL('image/jpeg', quality);
  }

  /**
   * @param {File} image - Image File Object
   * @param {Object} pixelCrop - pixelCrop Object provided by react-image-crop
   * @param {String} fileName - Name of the returned file in Promise
   */
  static getCroppedImg(image, pixelCrop, fileName) {

    const canvas = document.createElement('canvas');
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    // As Base64 string
    // const base64Image = canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise((resolve, reject) => {
      canvas.toBlob(file => {
        file.name = fileName;
        resolve(file);
      }, 'image/jpeg');
    });
  }

  // TODO: This function is pretty fast, but we could make it async.
  static dataURLtoBlob(dataurl) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type:mime});
  }
  static dataURLtoBlobDebug(dataurl) {
    const arr = dataurl.split(',');
    if (arr.length < 1) {
      const msg = "In ImageUtils.dataURLtoBlob(), (arr.length < 1), so we cannot call arr[0].match().  This is a bug.";
      log.bug(msg+", dataurl:", dataurl);
      const props = {msg};
      const error = MyError.createBugError(props);
      return error;
    }
    const temp = arr[0].match(/:(.*?);/);
    if (temp == null) {
      const msg = "In ImageUtils.dataURLtoBlob(), (temp == null), so we cannot set mime=temp[1].  This is a bug.";
      log.bug(msg+", dataurl:", dataurl);
      const props = {msg};
      const error = MyError.createBugError(props);
      return error;
    }

    if (temp.length < 2) {
      const msg = "In ImageUtils.dataURLtoBlob(), (temp.length < 2), so we cannot set mime=temp[1].  This is a bug.";
      log.bug(msg+", dataurl:", dataurl);
      const props = {msg};
      const error = MyError.createBugError(props);
      return error;
    }
    const mime = temp[1];

    if (arr.length < 2) {
      const msg = "In ImageUtils.dataURLtoBlob(), (arr.length < 2), so we cannot call atob(arr[1]).  This is a bug.";
      log.bug(msg+", dataurl:", dataurl);
      const props = {msg};
      const error = MyError.createBugError(props);
      return error;
    }
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type:mime});
  }

  /**
   * @return {Promise} The returned Promise resolves to an Object
   * like one of these:
   *
   *   {possiblyConvertedFile:fileOrBlob, fileWasConverted:false}
   *   {possiblyConvertedFile:convertedFile, fileWasConverted:true}
   */
  static convertPngToJpg(fileOrBlob) {
    log.trace("convertPngToJpg(), fileOrBlob:", fileOrBlob);
    return new Promise(function (resolve, reject) {
      log.trace("Enter Promise function");
      var reader = new FileReader();
      reader.onload = function(e) {
        var dataURL = reader.result;
        log.trace("dataURL:", dataURL);

        const url = URL.createObjectURL(fileOrBlob); // create an Object URL
        const img = new Image();
        img.onload = function() { // handle async image loading
          URL.revokeObjectURL(this.src);  // free memory held by Object URL
          //const canvas = document.getElementById("canvas");
          const canvas = document.createElement("canvas");
          //canvas.style.backgroundColor = "white";

          canvas.width = fileOrBlob.width;
          canvas.height = fileOrBlob.height;
          const ctx=canvas.getContext("2d");

          // Two ways to do this.  Fill canvas before drawing the image
          // image, or fill the canvas "behind/under" the image after
          // drawing the image.

          // Set background color.
          ctx.fillStyle = "#FFF";
          // Draw background / rect on entire canvas.
          ctx.fillRect(0,0,canvas.width,canvas.height);
          ctx.drawImage(this, 0, 0);  // draw image onto canvas (lazy method)

          /*
          ctx.drawImage(this, 0, 0);  // draw image onto canvas (lazy method)
          //set to draw behind current content
          const compositeOperation = ctx.globalCompositeOperation;
          ctx.globalCompositeOperation = "destination-over";
          //set background color
          ctx.fillStyle = "#FFF";
          //draw background / rect on entire canvas
          ctx.fillRect(0,0,canvas.width,canvas.height);
          */

          // Create jpeg image from canvas.
          const quality = ImageUtilsCom.JPG_QUALITY_CANVAS;  // 0 to 1
          // dataURL = canvas.toDataURL();  // Default format is image/png
          log.trace("canvas:", canvas);
          dataURL = canvas.toDataURL('image/jpeg', quality);
          log.trace("jpeg dataURL:", dataURL);
          //const convertedFile = ImageUtils.dataURItoBlob(dataURL);
          const convertedFile = ImageUtils.dataURLtoBlob(dataURL);
          if (convertedFile instanceof MyError) {
            reject();
            return;
          }
          convertedFile.name = "someFileName.jpeg"; //fileOrBlob.name;
          resolve({possiblyConvertedFile:convertedFile, fileWasConverted:true});
        };
        img.src = url;
      }
      log.trace("Calling readAsDataURL()");
      reader.readAsDataURL(fileOrBlob);
    });
  }

  /**
   * If the passed in fileOrBlob is a png file, convert it to
   * a jpg file.
   *
   * @return {Promise} The returned Promise resolves to an Object
   * like one of these:
   *
   *   {possiblyConvertedFile:fileOrBlob, fileWasConverted:false}
   *   {possiblyConvertedFile:convertedFile, fileWasConverted:true}
   */
  static async possiblyConvertPngToJpg(fileOrBlob) {
    log.trace("possiblyConvertPngToJpg(), fileOrBlob:", fileOrBlob);

    // getFileExt() does trim() and toLowerCase() on returned value.
    const fileExt = ImageUtilsCom.getFileExt(fileOrBlob.name, fileOrBlob.type);
    log.trace("fileExt:", fileExt);
    if (fileExt != "png") {
      ErrorUtils.showNotification(
        "Image is not in png format, so we do not need to convert it.");
      // No conversion was needed.
      return {possiblyConvertedFile:fileOrBlob, fileWasConverted:false};
    }

    // Conversion needed.
    ErrorUtils.showNotification(
      "Image is in png format, so we will convert to jpg format.");
    log.trace("Calling ImageUtils.convertPngToJpg()");
    const result = await ImageUtils.convertPngToJpg(fileOrBlob);
    log.trace("After ImageUtils.convertPngToJpg(), result:", result);
    return result;
  }

  /**
   * @param {string} filename - The name of the file BEFORE the file
   * extension part.  I.e. before the ".jpg" or ".jpeg" part.
   * E.g. If you want the file called "userProfileImage_1234.jpg",
   * pass "userProfileImage_1234".  Defaults to a unique name.
   *
   * @return Returns an object with this shape:
   *
   *    {error:null, downloadURL:"http://someURL.com/userProfileImage_1234.jpg"}
   */
  static async uploadToFirebase(fileOrBlob,
    filename = _.uniqueId("uploadedFile_")) {

    log.trace("ImageUtils.uploadToFirebase(), fileOrBlob:", fileOrBlob);

    const storageRef = firebase.storage().ref();
    const fileExt = ImageUtilsCom.getFileExt(fileOrBlob.name, fileOrBlob.type);
    filename += "."+fileExt;
    const ref = storageRef.child(filename);

    /*
    const metadata = {
      // "image/jpeg", "image/png", etc.
      contentType:file.mimetype ? file.mimetype : file.type;
    };
    */
    // metadata parameter, (mimetype, e.g. "image/jpeg"), is not really needed
    // because the fileOrBlob parameter is a real File object,
    // which contains that information anyway, or it is a Blob object
    // that I added a "type" property to.
    let result;
    try {
      const snapshot = await ref.put(fileOrBlob);
      log.trace("snapshot:", snapshot);

      // This is the "old" firbase@4.13.1 version way to get downloadURL.
      //const downloadURL = snapshot.downloadURL;

      // New firebase@7.15.5 version requires a call to getDownloadURL()
      // which returns a Promise that resolves the value.
      const downloadURL = await snapshot.ref.getDownloadURL();
      result = {error:null, downloadURL};
    }
    catch(err) {
      const msg = "Error while calling ref.put(fileOrBlob).  Details: "+err;
      log.info(msg);
      const props = {msg};
      const error = MyError.createSubmitError(props);
      result = {error};
    };
    return result;
  }

  /**
   * This function is used if we are uploading to Firebase "directly"
   * from this concierge client web app.
   *
   * This function does the following:
   *
   *    1) Resize - If image is to wide/tall, it resizes the image.
   *    2) Convert - If image is png format, it changes it to jpg.
   *    3) Upload - It uploads the image to Firebase storage.
   *
   * While doing the above, it sends notification messages to the UI.
   *
   * @param {string} filename - The name of the file BEFORE the file
   * extension part.  I.e. before the ".jpg" or ".jpeg" part.
   * E.g. If you want the file called "userProfileImage_1234.jpg",
   * pass "userProfileImage_1234".  Defaults to a unique name.
   *
   * @return Returns an object with this shape:
   *
   *    {error:null, downloadURL:"http://someURL.com/userProfileImage_1234.jpg"}
   */
  static async resizeConvertUpload(file, filename) {
    log.trace("ImageUtils.resizeConvertUpload(), file:", file);
    log.trace("ImageUtils.resizeConvertUpload(), filename:", filename);

    // Hack
    //filename += _.uniqueId();
    //log.trace("ImageUtils.resizeConvertUpload(), new filename:", filename);

    const maxDimensions = ConciergeRestApi.MAX_IMAGE_DIMENSIONS;

    let msg;
    let result;
    try {
      // First, resize the image if it is too big.
      ErrorUtils.setLoader(true, "Possibly resizing image...");
      //await sleep(5000);
      msg = "ImageTools.resizePromise() failed.  ";
      const resizeResult = await ImageTools.resizePromise(file, maxDimensions);
      msg = "ImageTools.resizePromise() succeeded, but other error occurred.  ";
      const {possiblyResizedFile, fileWasResized} = resizeResult;

      // Notify the user whether image was resized.
      if (fileWasResized) {
        ErrorUtils.showNotification("Image dimensions were larger than ("+
          ConciergeRestApi.MAX_IMAGE_DIMENSIONS_STRING+
          "), so we reduced its width and height.");
        // It has been changed to a "Blob" object.
      }
      else {
        ErrorUtils.showNotification(
          "Image dimensions were within the limits.  No resizing needed.");
        // It is unchanged, and is still a "File" object.
      }

      // Next, if the image is in png format, convert it to jpg format.
      ErrorUtils.setLoader(true, "Possibly converting image format...");
      //await sleep(5000);
      msg = "ImageTools.possiblyConvertPngToJpg() failed.  ";
      log.trace("Calling possiblyConvertPngToJpg()");
      result = await this.possiblyConvertPngToJpg(possiblyResizedFile);
      log.trace("After possiblyConvertPngToJpg(), result:", result);
      const {possiblyConvertedFile, fileWasConverted} = result;
      // Notify the user whether image was converted.
      if (fileWasConverted) {
        ErrorUtils.showNotification(
          "Image was in png format, so we converted it to jpg format.");
      }
      else {
        ErrorUtils.showNotification(
          "Image was not in png format, so we did not need to convert it.");
      }
      
      // Now finally upload the possibly resized/converted image.
      ErrorUtils.setLoader(true, "Uploading image...");
      //await sleep(5000);
      msg = "Image upload failed.  ";
      result = await ImageUtils.uploadToFirebase(possiblyConvertedFile, filename);
      log.trace("Returned from uploadToFirebase(), result:", result);
    }
    catch(err) {
      ErrorUtils.showError(msg+"Details: err("+err+")");
      const props = {msg};
      const error = MyError.createSubmitError(props);
      result = {error};
    }

    log.trace("resizeConvertUpload() returning result:", result);
    return result;
  }

  static async possiblyDeleteFromFirebase(url) {
    if (url && url.startsWith("http")) {
      await ImageUtils.deleteFromFirebase(url);
    }
    else {
      log.trace("url was not a Firebase image, url: ", url);
    }
  }

  static async deleteFromFirebase(url) {
    log.trace("ImageUtils.deleteFromFirebase(), url: ", url);
    // Don't delete the image if it is one of the placeholder
    // or stock images we provide.
    if (url && _.includes(url, "placeholders")) {
      log.trace("Image is a placeholder image, so don't delete it.");
      return;
    }

    const storageRef = firebase.storage().refFromURL(url);
    log.trace("storageRef: ", storageRef);
    await storageRef.delete();
  }

}

export default ImageUtils;
