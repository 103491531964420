/**
 * This is the outermost App component.  You will use the App container
 * that wraps this.
 */
//import _ from 'lodash';
import React from 'react';
//import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {ErrorBoundary} from 'react-error-boundary';

// Imports for using React Router for browser Forward/Back button
// navigation.
//import {Route, NavLink, HashRouter} from 'react-router-dom';

/******************** Notification System Stuff Below *************/
// import Font Awesome assuming we have npm installed it.
// Create React Application package does not like webpack directives,
// so we need to turn off eslint for that here.
/* eslint import/no-webpack-loader-syntax: off */
//import '!style-loader!css-loader!font-awesome/css/font-awesome.min.css';

// import it assuming we copied it to our source tree.
//import '/src/font-awesome-4.7.0/css/font-awesome.min.css';

import NotificationsSystem from 'reapop';
import theme from 'reapop-theme-wybo';
//import theme from 'reapop-theme-bootstrap';  // I have not npm installed this theme
/******************** Notification System Stuff Above *************/

import {log} from 'concierge-common';
import Fallback from '../components/fallback';
import Shell from '../containers/shell';
import {HELLO_NOTIFICATION} from '../actions/error';
import store from '../store';
//import LocationUtils from './utils/location-utils';

//import UrlUtils from './utils/url-utils';

import LocalDB from '../client-db/local-db';
import UI from '../utils/ui-state';


// TODO: Put this into some sort of error handling module.
//
const myErrorHandler = (error, componentStack) => {
  log.trace("Enter myErrorHandler");
  const message = "\n"+
  "**************************************************"+
  "******* Unhandled React error of some sort *******"+
  "**************************************************\n";
  log.error(message);
  log.error("error: ", error);
  log.error("componentStack: ", componentStack);
};


/* Doesn't seem to help on Chrome on iOS.
  // https://gist.github.com/JoostKiens/d26db0882a2b304ae2adc7b437e9f30a
  const target = window;
  let lastY = 0;
  target.addEventListener('touchmove', handleTouchMove);
  function handleTouchMove(e) {
    const { pageY } = e.changedTouches[0]
    const scrollY = target.pageYOffset || target.scrollTop || 0
    if (pageY > lastY && scrollY === 0) {
      e.preventDefault()
    }
    lastY = pageY
  }
*/

class App extends React.Component {

  constructor(props) {
    super(props);
    const {/*cA_SetNavbarAndSidebar,*/ cA_MultiAction/*, location*/} = props;

    // Keep a copy of the browser history object.
    // This seems like a hack.
    window.myHistory = props.history;

    // Setup a listener on the history that will update
    // the navbar/sidebar when the url changes.
    window.myHistory.listen((location, action) => {
      log.trace("location:", location);
      log.trace("action:", action);

      // This is not right.  How to set match?
      //const match = this.props.match;
      //log.trace("match:", match);
      //cA_SetNavbarAndSidebar(location, store.getState().curUser);

      // If the MultiAction modal is displayed when the user clicks
      // the browser's Back/Forward button, we want to cancel/hide
      // the MultiAction modal.
      cA_MultiAction(null);
    });

    //if (window.performance &&
    //    window.performance.navigation.type ==
    //    window.performance.navigation.TYPE_BACK_FORWARD) {
    //}

    //this.updateNavbarAndSidebar(props);
  }

  /**
   * Detect whether we are on a touch device like a phone/tablet,
   * or a desktop/laptop machine that has a mouse.
   */
  detectTouchDevice() {
    const props = this.props;
    const {cA_SetDevice} = props;

    const setTouch = () => {
      //alert("Touch Device");
      const device = {touch:true};
      cA_SetDevice(device);
      // Remove event listener once fired, otherwise it'll kill scrolling
      // performance
      window.removeEventListener('touchstart', setTouch);
      window.removeEventListener('mousemove', setHover);
    };
    const setHover = () => {
      //alert("Mouse Device");
      const device = {hover:true};
      cA_SetDevice(device);
      // Remove event listener once fired, otherwise it'll kill scrolling
      // performance
      window.removeEventListener('mousemove', setHover);
      window.removeEventListener('touchstart', setTouch);
    }

    window.addEventListener('touchstart', setTouch, false);
    window.addEventListener('mousemove', setHover, false);
  }

  /**
   * This function sets up event listeners that will prevent the
   * default "refresh" on "over scroll" in some browsers.
   * For example, in Chrome on a phone/tablet, if you swipe downward
   * "past" the furthest you can go, Chrome will normally refresh
   * the page.  We don't want our web app to reload, so we prevent
   * that in this function.  It calls e.preventDefault() if the
   * touchstart/touchmove combination would have resulted in a
   * refresh.
   *
   * NOTE: Does not seem to work on Chrome on iPhones.
   */
  preventScrollToRefresh() {
    let preventPullToRefresh = false;
    let lastTouchY = 0;
    const touchstartHandler = (e) => {
      log.trace("touchstartHandler(): touches.length("+
        e.touches.length+")");
      if (e.touches.length != 1) return;
      lastTouchY = e.touches[0].clientY;
      // Pull-to-refresh will only trigger if the scroll begins when the
      // document's Y offset is zero.
      preventPullToRefresh = window.pageYOffset == 0;
      log.trace("touchstartHandler(): lastTouchY("+
        lastTouchY+")  preventPullToRefresh("+
        preventPullToRefresh+")");
    }

    const touchmoveHandler = (e) => {
      var touchY = e.touches[0].clientY;
      var touchYDelta = touchY - lastTouchY;
      lastTouchY = touchY;

      preventPullToRefresh = preventPullToRefresh ?
        preventPullToRefresh : window.pageYOffset == 0;

      log.trace("touchmoveHandler(): preventPullToRefresh("+
        preventPullToRefresh+
        ")  touchY("+touchY+
        ")  lastTouchY("+lastTouchY+
        ")  touchYDelta("+touchYDelta+")");
      if (preventPullToRefresh) {
        // To suppress pull-to-refresh it is sufficient to preventDefault the
        // first overscrolling touchmove.
        preventPullToRefresh = false;
        if (touchYDelta > 0) {
          log.trace("Calling preventDefault()");
          e.preventDefault();
          return;
        }
      }

      // Set to true to prevent ALL scrolling.  Never need this.
      const preventScroll = false;
      if (preventScroll) {
        e.preventDefault();
        return;
      }

      // Set to true to prevent the browser from showing the
      // "glow" when user overscrolls.  This is the indicator
      // when the browser "normally" would have done the page
      // refresh, but now doesn't because of our code above.
      const preventOverscrollGlow = true;
      if (preventOverscrollGlow) {
        if (window.pageYOffset == 0 && touchYDelta > 0) {
          e.preventDefault();
          return;
        }
      }
    }
    
    // NOTE: passive:false is neccessary.
    document.addEventListener('touchstart', touchstartHandler,
      {passive:false});
    document.addEventListener('touchmove', touchmoveHandler,
      {passive:false});
  }

  // This is called AFTER all the child components have been rendered.
  componentDidMount() {
    log.trace("App.componentDidMount(), props:", this.props);
    const {cA_Notify, cA_SetFilterAndSortVisibility} = this.props;
    //this._notificationSystem = this.refs.notificationSystem;

    // Initialize the app.
    //const user = new User();  // TODO: get user from local.storage
    //this.props.cA_FetchAppState(user);

    cA_Notify(HELLO_NOTIFICATION);

    // I now do this later, when the user navigates to a 
    // page that uses location services.
    //LocationUtils.initLocationServices(this.props.cA_Notify);

    this.detectTouchDevice();
    this.preventScrollToRefresh();

    log.info("Calling LocalDB.initDB()");
    LocalDB.initDB();

    // We unlisten in unmount function.
    this.unlistenRouterHistory = window.myHistory.listen(
      (location, action) => {
        log.trace(`The current URL is ${location.pathname}${location.search}${location.hash}`)
        log.trace(`The last navigation action was ${action}`)
        cA_SetFilterAndSortVisibility(false);
      }
    );

    // Test unsubscribe.
    //setTimeout(() => LocalDB.unsubscribeAll(), 10000);
  }

  componentWillUnmount() {
    log.trace("App.componentWillUnmount(), this.props: ", this.props);
    this.unlistenRouterHistory();
    //log.trace("Calling LocalDB.unsubscribeAll()");
    //LocalDB.unsubscribeAll();
    log.info("Calling LocalDB.closeDB()");
    LocalDB.closeDB();
  }

  render() {
    log.trace("App.render(), this.props: ", this.props);
    const props = this.props;
    const {dbState, curUser} = props;
    log.trace("dbState:", dbState);

    const testProps = {from_app_prop1:"appProp1"};

    const className = curUser && curUser.hasAdminPriv &&
      curUser.hasAdminPriv() ?
      "app has-admin-priv" : "app";

    let theUI = undefined;
    if (dbState == UI.DB_STATE_STARTED) {
      theUI =
          <div className={className}>
            <NotificationsSystem theme={theme} />
            <Shell {...props} {...testProps} />
          </div>
    }
    else if (dbState == UI.DB_STATE_STARTING) {
      theUI =
        <h3>
          Display Picture While Database Is Starting
        </h3>
    }
    else if (dbState == UI.DB_STATE_STOPPING) {
      theUI =
        <h3>
          Display Picture While Database Is Stopping
        </h3>
    }
    else if (dbState == UI.DB_STATE_STOPPED) {
      // Probably never see this.
      theUI =
        <h3>
          Database Is Stopped, And Will Restart...
        </h3>
    }
    else {
      // This should never happen.
      theUI = <h3>Some Kind Of Bug</h3>
    }

    // Wrap the UI in a React ErrorBoundary.
    const wrappedUI =
      <ErrorBoundary onError={myErrorHandler} FallbackComponent={Fallback}>
        {theUI}
      </ErrorBoundary>
    return wrappedUI;
  };
}

/*
              <Route path="*" component={Shell} />
              <Route exact path="/signup" component={SignupPage} />
*/

/* How to pass parameters to Route, or any other child component.
Please note, I did read someone's comment that said that the component
would NOT be rerendered if the user used the browser's history, e.g.
the Back button, to navigate to the Route.

            <Route exact path="/act-list"
              render={(props) => <ActList {...props}
              someProp1={123} someProp2="456" />}
            />

 <Route path='/act-list/:id' render={childProps => <ActList {...childProps} some_prop1="someVal1"/>} />
 <Route path='*' render={childProps => <Shell {...childProps} some_prop1="someVal1"/>} />
*/
export default App;
