import React from 'react';
import {Menu} from 'semantic-ui-react';
import {log} from 'concierge-common';
import MyProgressBar from '../containers/my-progress-bar';
import NavbarUtils from './utils/navbar-utils';


/**
 * NOTE: Which items are shown in the navbar/sidebar are set by the
 * concierge/src/utils/ui-state.js module.  E.g. LEFT_ITEMS_SIGNED_IN
 */
class NavbarDesktop extends React.Component {

  componentDidMount() {
    const {cA_SetShellType} = this.props;
    cA_SetShellType("shell-desktop");
  }

  render() {
    log.trace("NavbarDesktop.render(), props:", this.props);
    const {curUser, navbar} = this.props;

    // NOTE: Which items are shown in the navbar/sidebar are set by the
    // concierge/src/db/models/ui.js module.  E.g. LEFT_ITEMS_SIGNED_IN
    //
    const leftItems = NavbarUtils.getMenuItems(curUser, navbar.leftItems);
    const leftMenuItems = NavbarUtils.createMenuItems(curUser, leftItems);

    // Create the login menu that appears on the far right
    // side of the navbar.
    const loginSignupLogoutMenu = NavbarUtils.createLoginSignupLogoutMenu();

    return(
      <Menu className="navbar-desktop" inverted>
        {leftMenuItems}
        {loginSignupLogoutMenu}
        <MyProgressBar />
      </Menu>
    );
  }
};

export default NavbarDesktop;
