import {ImageUtils} from 'concierge-common';
import {PENDING, EXPIRES_NEVER} from '../../global-constants';


// NOTE: Remember to look for UPDATE_THIS_WHEN_ADDING_PROPS
// in src/client-db/api when adding props to the schema.
//
class ObjSchema {
  static schema = {
    title:"Obj Schema",
    description:"Describes a Way/Act/Itn object",
    version:2,
    type:"object",
    properties:{
      _id:{
        primary:true,
        type:"string",
      },
      displayName:{
        type:"string",
        trim:true,
      },
      imageSrc:{
        type:"string",
      },
      avatarSrc:{
        type:"string",
      },
      isScenic:{
        // Means image should be shown in slideshow.
        // As opposed to a "navigational" waypoint's image that
        // probably should not be shown.
        type:"boolean",
      },
      details:{
        type:"string",
        trim:true,
      },
      creator:{
        type:"string",
      },
      owner:{
        type:"string",
      },
      created:{
        //type:"string",  // Cannot be null because used as index?
        //format:"date-time",
        // Milliseconds since the "epoch" Jan 1 1970
        type:"number",
      },
      // I don't know if I will use this for Acts.
      // Perhaps showing the center point of an adventure
      // that covers a large area?  E.g. tramping the Heaphy.
      locationPoint:{
        type:"object",
        properties:{
          // NOTE: Longitude comes first[0], Latitude comes second[1].
          coordinates: {
            "type": "array",
            "minItems": 2,
            "items": {
              "type": "number"
            }
          },
        },
      },
      parkWaypoint:{  // Parking location
        type:"string", // Way ID
      },
      children:{  // E.g. Way or Act IDs.
        type:"array",
        items:{
          type:"string",
        },
      },
      expires:{
        //type:"string",  // Cannot be null because used as index?
        //format:"date-time",
        //default:EXPIRES_NEVER,
        type:"number",
        default:EXPIRES_NEVER,
      },
      urls:{
        type:"array",
        items:{
          type:"object",
          properties:{
            displayName:{
              type:["string", "null"],
              //default:"A URL",
            },
            description:{
              type:["string", "null"],
              //default:"Optional description...",
            },
            url:{
              type:["string", "null"],
              //format:"uri",
            },
            internal:{
              type:"boolean",
              //default:false,
            },
          },
        },
      },  // End of schema.properties.urls
      tags:{ // User defined tags.
        type:"array",
        items:{
          type:"string",
          trim:true,
        },
      },
      /*
      attributes:{ // pending, approved, flagged
        type:"array",
        items:{
          type:"string",
          trim:true,
        },
        //default:["pending", "test"],
      },
      */
      statusAttribute:{
        type:"string",
        default:PENDING,
      },
      isTestData:{
        // This object is test data, not "real" data.
        type:"boolean",
      },
      visibility:{
        // isPublic, isProtected, isPrivate
        type:"string",
        default:"isPublic",
      },
      userIds:{
        // Users who have access to this object when its 
        // visibility is not public.
        type:"array",
        items:{
          type:"string",
        },
        default:[],
      },
      guides:{
        // IDs of users that are this user's guides.
        type:"array",
        items:{
          type:"string",
        },
      },
      /*
      followers:{
        // IDs of users that this user is a guide of.
        type:"array",
        items:{
          type:"string",
        },
      },
      */
      // User specific properties BELOW
      /*
      token:{
        //type:["string", "null"],
        type:"string",
      },
      */
      email:{
          // "primary" means this a unique, indexed, required field
          // which can be used to definitely find a single document.
          //primary: true,
          type:"string",
          format:"email",
          lowercase:true,
          unique:true,
          trim:true,
      },
      password:{
        type:"string",
      },
      acceptedTerms:{
        type:"boolean",
        //default:false,
      },
      roles:{ // "root", "admin", "guide", "traveler"
        type:"array",
        items:{
          type:"string",
          lowercase:true,  // first make strings in this array lowercase.
          trim:"true",
        },
        //default:[UserC.Roles.TRAVELER],
      },
      flags:{
        type:"array",
        //default:["UN"],
      },
      adventures:{  // Adventures they have done.
        type:"array",
        items:{
          type:"string",  // Ids of acts
        },
        //default:[],
      },
      favorites:{
        type:"array",
        items:{
          type:"string",  // Ids of ways, acts, itns, users
        },
        //default:[],
      },
      recommendations:{
        // A list of objects recommended to this user by guides.
        type:"array",
        items:{
          type:"string",  // Ids of ways, acts, itns
        },
        //default:[],
      },
      fellowTravelers:{
        type:"array",
        items:{
          type:"string",  // User ids
        },
        //default:[],
      },
      messages:{
        type:"array",
        items:{
          type:"object",
          properties:{
            _id:"string",  // Date.now() as a string.
            // NOTE: I think the only value that affects anything is
            // if task == "connect".  That causes the MessagePanel
            // to display a Connect button to accept the connection.
            // If task is anything besides "connect", then it is just
            // treated as a message.  task == "noreply" causes no Reply
            // button to be created.
            task:{  // "connect", "noreply", "message"
              type:"string",
            },
            senderId:{  // User id
              type:"string",
            },
            // TODO: This doesn't make sense.  Why would the ownerId
            // be needed given that this message is in the User object?
            // I think it might be leftover from when messages were
            // going to be documents in their own "messages" collection.
            // Delete ownerId field?
            //ownerId:{  // The recipient "owns" the message.  TODO: change term?
            //  type:"string",
            //},
            text:{  // The text of the message.
              type:"string",
            },
          },
        },
        //default:[],
      },
      messagesLastReceived:{  // Store as seconds since epoch instead?
        //type:"string",
        //format:"date-time",
        type:"number",
      },
      messagesLastRead:{  // Store as seconds since epoch instead?
        //type:"string",
        //format:"date-time",
        type:"number",
      },
      // User specific properties ABOVE
    },  // End of schema.properties

    required: ["_id"],
    indexes:["statusAttribute",
      "created", "expires", "displayName", "tags.[]", "roles.[]"],
    additionalProperties:false,
    //"encrypted": ["someProperty"],
    //"attachments": {
    //  "encrypted": true
    //},
  };
};


export default ObjSchema;
