
/**
 * Modifies, (possibly), the passed in obj parameter by
 * trimming whitespace off all properties recursively.
 *
 * @return A reference to the "same" obj parameter you
 * passed in, but now it has (possibly) been modified.
 * I.e. it is NOT a new object.
 */
const trimDeep = function(obj) {
  for (var prop in obj) {
    var value = obj[prop], type = typeof value;
    if (value != null &&
      (type == "string" || type == "object") &&
      obj.hasOwnProperty(prop)) {
      if (type == "object") {
        trimDeep(obj[prop]);
      } else if (obj[prop] && obj[prop].trim) {
        obj[prop] = obj[prop].trim();
      } else {
        // Do nothing.
      }
    }
  }
  return obj;
}

/* Other versions.
function trimDeep(o: any) {
  return JSON.parse(JSON.stringify(o).replace(/"\s+|\s+"/g, '"'));
}
function trimDeep(obj) {
  if (!Array.isArray(obj) && typeof obj != 'object') return obj;
  return Object.keys(obj).reduce(function(acc, key) {
    acc[key.trim()] = typeof obj[key] == 'string'? obj[key].trim() : trimDeep(obj[key]);
    return acc;
  }, Array.isArray(obj)? []:{});
}
*/

const strId = function(objOrId) {
  let id = "";
  if (objOrId && objOrId._id) {
    id += objOrId._id;
  }
  else if (objOrId) {
    id += objOrId;
  }
  return id;
}

const isEqualIds = function(objOrId1, objOrId2) {
  return strId(objOrId1) == strId(objOrId2);
}

export {trimDeep, strId, isEqualIds};
