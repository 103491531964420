// This module defines the SignedOutPanel *container*.
// NOT the SignedOutPanel *component*.

import {connect} from 'react-redux';
//import {log} from 'concierge-common';

// Import the SignedOutPanelComponent from which this SignedOutPanelContainer is created.
import SignedOutPanelComponent from '../components/signed-out-panel';

// If src/actions.js not found, defaults to src/actions/index.js file.
//import {cA_SignOut} from '../actions/user';
import {cA_SetLoader} from '../actions/ui';

/**
 * Given the application's global store state, create and return
 * a React component "props" object that the React framework will
 * copy into the SignedOutPanel component's "this.props" object.
 *
 * @param {Object} state - The application's global store state.
 */
function mapStateToProps(state) {
  return({
      curUser:state.curUser,
  });
}

const mapDispatchToProps = {cA_SetLoader};
const SignedOutPanelContainer = connect(mapStateToProps, mapDispatchToProps)(SignedOutPanelComponent);

export default SignedOutPanelContainer;

