/**
 */
import React from 'react';
import {Popup} from 'semantic-ui-react';
import {log} from 'concierge-common';
//import ApiUtils from '../client-db/api/api-utils';

class MyPopup extends React.Component {

  // Always create this popup.
  static get ALWAYS() {return "always"};

  // Never create this popup on a touch device.
  static get NOT_TOUCH() {return "notTouch"};

  // Functions below are an ugly hack to deal with popups
  // set on components that are nested inside each other.
  // If we don't do this, then if the user moves the cursor
  // into the parent component, displaying its popup, and
  // then into the child component, displaying the child's
  // popup, both popups are displayed at the same time.
  // This code closes the previously displayed popup when
  // a new popup is opened.
  static currentlyOpenPopup = null;
  handleOpen = () => {
    if (this != MyPopup.currentlyOpenPopup) {
      if (MyPopup.currentlyOpenPopup) {
        // Close previously open popup.
        MyPopup.currentlyOpenPopup.setState({isOpen:false});
      }
    }
    this.setState({isOpen:true});
    MyPopup.currentlyOpenPopup = this;
  }
  handleClose = () => {
    this.setState({isOpen:false});
    MyPopup.currentlyOpenPopup = null;
  }
  componentWillUnmount() {
    MyPopup.currentlyOpenPopup = this == MyPopup.currentlyOpenPopup ?
      MyPopup.currentlyOpenPopup : null;
  }
  constructor(props) {
    super(props);
    this.state = {
      isOpen:false,
    };
  }
  // Functions above are an ugly hack to deal with popups
  // set on components that are nested inside each other.

  render() {
    log.trace("MyPopup.render() this.props:", this.props);
    const props = this.props;

    // Set default values for some Popup props, and pass
    // the "rest" of the props into Popup unchanged.
    /*
    let {device = {},
      className,
      popupRule = MyPopup.NOT_TOUCH,
      position = "top center",
      ...rest} = props;
    log.trace("rest:", rest);
    delete rest.dispatch;
    */

    // Set default values for some Popup props, and pass
    // the semantic ui Popup component properties such as
    // trigger and content to the semantic ui Popup unchanged.
    let {device = {},
      className,
      popupRule = MyPopup.NOT_TOUCH,
      position = "top center",
      content,
      trigger,
      on} = props;
      
    className = className ? ("my-popup "+className) : "my-popup";

    let wrapInPopup = true;
    if (popupRule === MyPopup.ALWAYS) {
      wrapInPopup = true;
    }
    else if (popupRule === MyPopup.NOT_TOUCH && device.touch) {
      wrapInPopup = false;
    }

    if (!content) {
      log.bug("In MyPopup.render(), No content property in this.props.");
      wrapInPopup = false;
    }

    if (!trigger) {
      log.bug("In MyPopup.render(), No trigger property in this.props.");
      wrapInPopup = false;
      trigger = <span>Missing Popup Trigger</span>
    }

    let component;
    if (wrapInPopup) {
      component = <Popup className={className} position={position}
        content={content}
        trigger={trigger}
        on={on}
        open={this.state.isOpen}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
        />
    }
    else {
      component = trigger;
    }

    return component;
  };
}

export default MyPopup;
