/**
 * This component displays information about a single Act.
 */
import _ from 'lodash';
import React from 'react';
import {Card, Button, Segment} from 'semantic-ui-react';
import {log} from 'concierge-common';
import ObjUtils from '../client-db/api/obj-utils';
import ApiUtils from '../client-db/api/api-utils';
import {DEFAULT_USER_ID} from '../global-constants';


class MessageComponent extends React.Component {

  // Accept connection.
  handleClickConnect = () => {
    const {curUser} = this.props;
    const {receiver, sender} = this.state;

    // Update both User's fellowTravelers arrays.
    // receiver is the acceptor of sender's request.
    ApiUtils.acceptConnection(curUser, receiver, sender);
  }
  
  handleClickReply = () => {
    const {obj:messageObj, history} = this.props;
    //cA_DeleteMessage(curUser, messageObj.ownerId, messageObj);
    // We want to create a message that will be sent to the
    // sender of this message.
    const url = "/createMessage?userId="+messageObj.senderId+
        "&task=reply"+  // Or "connect"
        //"&message=This%20is%20a%20message."+
        "&thisNotUsed=somethingNotUsed";
    history.push(url); 
  }
  
  handleClickDelete = () => {
    const {curUser, messageOwner, obj:messageObj} = this.props;
    const payload = ApiUtils.deleteMessage(curUser,
      messageOwner._id, messageObj);
  }

  createButtons = (messageObj, sender, receiver) => {
    const {curUser} = this.props;
    log.trace("createButtons(), curUser:", curUser);
    log.trace("createButtons(), sender._id:", sender._id);

    let connectButton;
    if (messageObj.task == "connect") {
      const connected = receiver.isConnected(sender._id);
      const disabled = connected;
      const label = connected ? "Connected" : "Connect";
      connectButton =
        <Button positive disabled={disabled}
          onClick={this.handleClickConnect}>
          {label}
        </Button>
    }

    // NOTE: This is a hack.
    // If sender is a missing/deleted user, the
    // sender._id will be the "real" id the user
    // used to have.  A User object will exist in the
    // store.state.cache for them.
    // We check for this case be seeing if the sender
    // is the owner of themselves.  (A real user is their
    // own owner.
    const senderIsMissingUser = !sender ||
      sender.owner != sender._id;

    let replyButton;
    log.trace("sender: ", sender);
    if ((sender && sender._id != DEFAULT_USER_ID) &&
        (curUser._id == receiver._id) &&
        (curUser._id != sender._id) &&
        (messageObj.task != "noreply") &&
        !senderIsMissingUser) {
      replyButton =
        <Button primary
          onClick={this.handleClickReply}>
          Reply
        </Button>
    }

    const deleteButton = 
      <Button negative onClick={this.handleClickDelete}>
        Delete
      </Button>

    const buttons = 
      <div className="stackable">
        {connectButton}
        {replyButton}
        {deleteButton}
      </div>

    return buttons;
  }

  static createStateFromProps(props) {
    const {curUser, messageOwner, obj:messageObj,
      cache, cA_GetObj} = props;

    const sender = ObjUtils.get(curUser, messageObj.senderId,
      undefined, cache, cA_GetObj);
    const receiver = ObjUtils.get(curUser, messageOwner._id,
      undefined, cache, cA_GetObj);

    const state = {sender, receiver};
    return state;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const newState = MessageComponent.createStateFromProps(nextProps);

    if (_.isEqual(prevState.sender, newState.sender)) {
      delete newState.sender;
    }
    if (_.isEqual(prevState.receiver, newState.receiver)) {
      delete newState.receiver;
    }
    if (_.size(newState)) {
      return newState;
    }
    else {
      // Nothing added to newState.
      return null;
    }
  }

  constructor(props) {
    super(props);
    this.state = MessageComponent.createStateFromProps(props);
  }

  render() {
    log.trace("MessageComponent.render() this.props:", this.props);
    const {props, state} = this;
    const {sender, receiver} = state;
    const {messageOwner, obj:messageObj} = props;
    messageObj.text = messageObj.text && messageObj.text.trim();
    messageObj.text = messageObj.text ? messageObj.text :
      "No message content.";

    const buttons = this.createButtons(messageObj, sender, receiver);

    const senderDisplayName = sender && sender.displayName ?
        sender.displayName : "Unknown User";
    return(
      <Card className={"message"}>
        <Segment>
          <div className="sender">Sender: {senderDisplayName}</div>
          <div className="text">{messageObj.text}</div>
        </Segment>
        {buttons}
      </Card>
    );
  };
}

export default MessageComponent;
