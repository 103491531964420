// This module defines the OrderedLayout *container*.
// NOT the OrderedLayout *component*.
import {connect} from 'react-redux';

// Import the OrderedLayoutComponent from which this OrderedLayoutContainer is created.
import OrderedLayoutComponent from '../components/ordered-layout';

// Import action creators that this component will use.
//import {cA_SetError} from '../actions/error';
//import {cA_MultiAction, cA_Confirmation} from '../actions/ui';
import {cA_GetObj} from '../client-db/api/obj-utils';


function mapStateToProps(state) {
  return({
    curUser:state.curUser,
    cache:state.cache,
  });
}

const mapDispatchToProps = {cA_GetObj};
const OrderedLayoutContainer = connect(mapStateToProps, mapDispatchToProps)
  (OrderedLayoutComponent);
export default OrderedLayoutContainer;
