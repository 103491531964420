import _ from 'lodash';
import React from 'react';
import {log} from 'concierge-common';
//import ReactFlagsSelect from 'concierge-flags';
import ReactFlagsSelect from '../concierge-flags';


class Flag extends React.Component {

  handleSelect(countryCode) {
    log.trace("Flag.handleSelect(), countryCode:", countryCode);
    const {onAdd, onDelete, onSelect} = this.props;
    if (countryCode == "ADD") {
      if (onAdd) {
        onAdd(countryCode);
      }
      else {
        // Hack.
        this.setState({countryCode:this.state.countryCode});
      }
    }
    else if (countryCode == "DELETE") {
      if (onDelete) {
        onDelete(countryCode, this.state.countryCode);
      }
      else {
        // Hack.
        this.setState({countryCode:this.state.countryCode});
      }
    }
    else {
      onSelect(this.state.countryCode, countryCode);
      this.setState({countryCode});
    }
  }

  /*
  shouldComponentUpdate(nextProps, nextState) {
    log.trace("Flag.shouldComponentUpdate(), nextProps:", nextProps);
    log.trace("Flag.shouldComponentUpdate(), nextState:", nextState);
    return true;
  }
  */

  componentDidUpdate() {
    log.trace("Flag.componentDidUpdate(), state.countryCode:",
      this.state.countryCode);
    this.userFlag.updateSelected(this.state.countryCode)
  }

  /*
  constructor(props) {
    super(props);
    this.state = {};
    this.state.countryCode = props.countryCode ?
      props.countryCode :
      "NONE";
  }
  */
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {countryCode} = nextProps;
    this.setState({countryCode});
  }
  constructor(props) {
    super(props);
    const {countryCode} = props;
    this.state = {countryCode};
  }

  render() {
    log.trace("Flag.render() this.props:", this.props);
    const {props} = this;
    //const {countryCode, disabled, onAdd, onDelete, removeFlags} = props;
    const {disabled, onAdd, onDelete, removeFlags} = props;
    log.trace("Flag.render() removeFlags:", removeFlags);
    const {countryCode} = this.state;
    log.trace("Flag.render() countryCode:", countryCode);

    // TODO: This is not really correct, mutating state in here.
    // Should do this in constructor() and in componenWillReceiveProps().
    //this.state = {countryCode};

    /*
    if (this.userFlag) {
      // Hack
      //this.userFlag.updateSelected(this.state.countryCode)
    }
    */

    //const className = deletable ? "deletable" : "";
    const className = "";

    let blackListedCountries = [];
    if (!onAdd) {
      blackListedCountries.push("ADD");
    }
    if (!onDelete) {
      blackListedCountries.push("DELETE");
    }
    if (removeFlags) {
      blackListedCountries = _.concat(blackListedCountries, removeFlags);
    }

    log.trace("blackListedCountries:", blackListedCountries);

        //optionsSize={20}
    return(
      <ReactFlagsSelect
        ref={(userFlag) => { this.userFlag = userFlag; }}
        className={className}
        onSelect={this.handleSelect.bind(this)}
        defaultCountry={countryCode}
        showSelectedLabel={false}
        disabled={disabled}
        selectedSize={18}
        countries={blackListedCountries}
        blackList={true}
        searchable={true}
      />
    );
  };
}

export default Flag;

