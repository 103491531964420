import React from 'react';
//import ReactDOM from 'react-dom';
import {Container} from 'semantic-ui-react';
import {log} from 'concierge-common';


class ShellContentsInner extends React.Component {

  render() {
    log.trace("ShellContentsInner.render() this.props:", this.props);
    const props = this.props;
    let {className} = props;
    const {textAlign} = props;

    className = className ? className : "";
    className = ("shell-contents-inner "+className).trim();

    return (
      <Container className={className} textAlign={textAlign}>
        {props.children}
      </Container>
    );
  }
}

export default ShellContentsInner;
