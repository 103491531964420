/**
 */
import React from 'react';
import {Card, Icon} from 'semantic-ui-react';
import {log} from 'concierge-common';
import {isEmail, validateEmail} from 'concierge-common';
import MyCardContent from './my-card-content';
import UserC from '../client-db/api/user';


class EmailAddress extends React.Component {

  handleValidationFail = (result, email) => {
    log.trace("handleValidationFail(), email:", email);
    const {cA_SetError} = this.props;
    const error = validateEmail(email);
    cA_SetError(error);
  }

  render() {
    log.trace("EmailAddress.render() this.props:", this.props);
    const props = this.props;
    const {editable, email, change, propName} = props;

    const mailto = "mailto:"+email+"?Subject=Message%20From%20A%20Traveler";
    let emailComponent;
    if (editable) {
      emailComponent = <MyCardContent className="email"
        iconName="mail outline"
        iconLink={mailto}
        change={change} propName={propName}
        handleValidationFail={this.handleValidationFail}
        validate={isEmail}
        text={email} editable={true}
        maxLength={UserC.EMAIL_MAX_LENGTH} />
    }
    else {
      emailComponent = 
        <Card.Content extra>
          <a href={mailto} className="ellipsis">
            <Icon name="mail outline" size="large" />
            {email}
          </a>
        </Card.Content>
    }

    return emailComponent;
  };
}

export default EmailAddress;
