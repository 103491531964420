/**
 */
import _ from 'lodash';
import React from 'react';
import {Modal, Header, Button, Icon} from 'semantic-ui-react';
import {log} from 'concierge-common';
//import * as ACTION_TYPES from '../actions/types';
import TimeUtils from './utils/time-utils';


class Confirmation extends React.Component {

  /**
   * Called when the Confirmation Modal's "Ok" button is clicked.
   */
  handleClickOk = (syntheticEvent, data) => {
    log.trace("Confirmation.handleClickOk(), syntheticEvent:", syntheticEvent);
    log.trace("Confirmation.handleClickOk(), data:", data);
    const {cA_Confirmation, confirmationProps} = this.props;
    const {okAction, doubleOkConfirmation} = confirmationProps;

    if (okAction) {
      okAction();
    }
    if (doubleOkConfirmation) {
      // Do NOT dismiss the Confirmation Modal because we
      // are immediately displaying another one.
      // Probably another Confirmation Modal asking the user
      // whether s/he is really, reall sure s/he wants to
      // delete some precious item from the system.
    }
    else {
      // Calling with no args dismisses the dialog.
      cA_Confirmation();
    }
  }

  /**
   * Called when the Confirmation Modal's "Cancel" button is clicked.
   */
  handleClickCancel = (syntheticEvent, data) => {
    log.trace("Confirmation.handleClickCancel(), syntheticEvent:",
      syntheticEvent);
    log.trace("Confirmation.handleClickCancel(), data:", data);
    const {cA_Confirmation, confirmationProps} = this.props;
    const {cancelAction, doubleCancelConfirmation} = confirmationProps;

    if (cancelAction) {
      cancelAction();
    }
    if (doubleCancelConfirmation) {
      // Do NOT dismiss the Confirmation Modal because we
      // are immediately displaying another one informing
      // the user that we canceled the "risky" action.
      // E.g. we are going to display a Confirmation Modal
      // that says, "We did not delete your precious item."
    }
    else {
      // Calling with no args dismisses the dialog.
      cA_Confirmation();
    }
  }

  hasCancelButton = () => {
    const {confirmationProps} = this.props;
    const {okAction, cancelAction, cancelLabel} = confirmationProps;
    let hasCancelButton = okAction || cancelAction || cancelLabel;

    // If the caller is explicitly telling us not to display
    // the cancel button by setting the label to null or "",
    // then don't display it.  Note, this is different than
    // not passing the value, i.e. undefined.
    if (cancelLabel === null || cancelLabel == "") {
      hasCancelButton = false;
    }
    return hasCancelButton;
  }

  createMessages() {
    const {confirmationProps} = this.props;
    const {message1, message2} = confirmationProps;

    let messages = [];
    if (Array.isArray(message1)) {
      // message1 is actually an array of Components.
      message1.forEach((message, index) => {
        if (_.isString(message)) {
          messages.push(<h3 key={index}>{message}</h3>);
        }
        else {
          messages.push(<span key={index}>{message}</span>);
        }
      });
    }
    else {
      messages.push(<h3 key={0}>{message1}</h3>);
      messages.push(<h3 key={1}>{message2}</h3>);
    }

    return messages;
  }

  render() {
    log.trace("Confirmation.render() this.props:", this.props);
    const {confirmationProps} = this.props;
    let {title, okAction, okLabel, cancelLabel, cancelButton,
      okButton, okDisabled, okButtonProps:okButtonPropsOverride,
      okButtonIconName, message1} = confirmationProps;

    title = title ? title : "Confirmation";
    okLabel = okLabel ? okLabel : "Ok";
    okDisabled = okDisabled ? okDisabled : false;

    // Figure out whether we are displayed.
    // Use !! to convert it to a boolean.
    const open = !!(message1 || okAction);

    // See if caller passed in a Cancel button.
    if (!cancelButton) {
      if (this.hasCancelButton()) {
        cancelLabel = cancelLabel ? cancelLabel : "Cancel";
        cancelButton = <Button 
          onClick={this.handleClickCancel}
          key="cancelKey">
          <Icon name='cancel' />
          {cancelLabel}
        </Button>
      }
    }

    // See if caller passed in an Ok button.
    if (!okButton) {
      // If there is a Cancel button, the Ok button is
      // "negative" (red).  If there is not a Cancel button
      // then the Ok button is "positive" (green).
      const okButtonProps = {
        inverted:true,
        negative:!!cancelButton,
        positive:!cancelButton,
        disabled:okDisabled,
        loading:okDisabled,
        key:"okKey",
      }
      if (okButtonPropsOverride) {
        Object.assign(okButtonProps, okButtonPropsOverride);
      }

      let icon = undefined;
      if (okButtonIconName != "") {
        let iconName = cancelButton ? "trash" : "cancel";
        iconName = okButtonIconName ? okButtonIconName : iconName;
        icon = <Icon name={iconName} />
      }
      else {
        // Consumer has explicitly set the icon to nothing "".
      }

      okButton =
        <Button {...okButtonProps} 
          onClick={this.handleClickOk}>
          {icon}
          {okLabel}
        </Button>
    }

    const headerIcon = cancelButton ?
      "question circle outline" :
      "info";

    const messages = this.createMessages();

    return(
      <Modal className="confirmation-modal" open={open} size='small'>
        <Header icon={headerIcon} content={title} />
        <Modal.Content>
          {messages}
        </Modal.Content>
        <Modal.Actions>
          {okButton}
          {cancelButton}
        </Modal.Actions>
      </Modal>
    );
  };
}

export default Confirmation;
