import {log} from 'concierge-common';
import * as ACTION_TYPES from '../actions/types';
import {cA_SetError} from '../actions/error';
import ObjUtils from './utils/obj-utils';


/**
 * This reducer manages the value of the application's
 * store.state.ways property.
 *
 * @param {ways} ways The current store.state.ways object.
 * We should never modify this value.
 *
 * @param {Object} action The current action to be performed.
 * @param {String} action.type This tells us "what" we need to do.
 * @param {Object} action.payload This is the data that we will use to
 * complete the action.
 * @param {Object} action.payload.ways The new values that we are
 * going to use to alter the state.ways property.
 */
function waysReducer(ways = ObjUtils.EMPTY_WAYS, action) {
  log.trace("waysReducer("+action.type+") ways:", ways);
  log.trace("waysReducer(), payload:", action.payload);
  log.trace("waysReducer(), action:", action);

  const {payload = {}} = action;
  /*
  if (!payload) {
    // Redux "interal" action types such as @@redux/INIT
    // don't have payloads, and we should ignore them
    // anyway.  But, "_P" pending actions have no payload either,
    // so we can't ignore actions with empty payloads.
    return ways;
  }
  */
  let newWays = ways;
  switch(action.type) {

    case ACTION_TYPES.SET_WAYS: {
      log.trace("SET_WAYS waysReducer(), action:", action);
      if (payload.error) {
        action.asyncDispatch(cA_SetError(payload.error));
        return ways;
      }
      newWays = Object.assign({}, ways, payload);
      return newWays;
    }

    case ACTION_TYPES.SIGN_OUT_S:
      if (payload.error) {
        return ways;
      }
      newWays = ObjUtils.EMPTY_WAYS;
      return newWays;

    default:
      log.trace("ways-reducer default");
      return ways;
  }
};

export default waysReducer;
