import _ from 'lodash';
import {addNotification as notify} from 'reapop';
import {MyError, log} from 'concierge-common';
import store from '../store';
import * as ACTION_TYPES from './types';

function cA_SetError(error = null) {
  log.trace("Enter cA_SetError(), error:", error);
  // This check to see whether this error has already been reported
  // is a temporary hack.
  /*
  log.trace("store:", store);
  const state = store && store.getState();
  log.trace("state:", state);

  // TODO: This might not be needed anymore.
  if (state && state.error && error &&
      state.error.handle.notify == error.handle.notify &&
      state.error.handle.report == error.handle.report &&
      state.error.msg == error.msg) {
    log.trace("error already set.");
  }
  */

  if (error) {
    error = _.cloneDeep(error);
    error.time = Date.now();
  }
  const payload = {error};
  log.trace("cA_SetError returning payload.error:", payload.error);
  return({
    type:ACTION_TYPES.SET_ERROR,
    payload
  });
}

// My default notification object.
// Note: the notifcation module has its own DEFAULT_NOTIFICATION defined.
// We can override the module's default value by passing in a value when
// we create the notification reducer in our root-reducer.js.
//
// NOTE: Hovering over, but not clicking on, a notification popup prevents
// it from dismissing itself.
// http://louisbarranqueiro.github.io/reapop/
//
const DEFAULT_NOTIFICATION = {
  title: 'Notification',
  message: 'Default notification message.  You need to customize this by passing a MyError object, or other properties, into your call to cA_Notify().',
  //status: 'success', //default, info, success, warning, error
  status: 'info', //default, info, success, warning, error
  //dismissible: true,  // Dismissable by clicking on it.  Default is true.
  dismissAfter: 8000,  // Default is 5000.
  //closeButton:false,  // Default is false.
  //allowHTML:false, // Allow HTML in title and message of the notification.
};

// Notification shown at startup.
const HELLO_NOTIFICATION = {
  title: 'Notification System',
  message: 'Notification system is operating.',
  status: 'success',
  dismissible: true,
  dismissAfter: 2000
};

/**
 * @param {MyError} e - The MyError object whose values we will use to create
 * the notification object that the notify() function expects.
 */
function convertMyError(e) {

  //if (e.handle.notify) {
  //  log.error("convertMyError() was called with a non-notify error:", e);
  //}

  // Create a new notifcation that has default values
  const n = _.cloneDeep(DEFAULT_NOTIFICATION);  // Start with a default notification.
  n.message = e.msg ? e.msg : "An error occurred.";
  n.status = e.severity ? e.severity : "error";
  n.status = n.status == MyError.BUG ? "info" : n.status;
  switch(e.severity) {
    case MyError.INFO:
      n.title = "Information";
      break;
    case MyError.WARNING:
      n.title = "Warning";
      break;
    case MyError.ERROR:
      n.title = "Error";
      break;
    case MyError.SUCCESS:
      n.title = "Success";
      break;
    case MyError.BUG:
      n.title = "Error In The Application's Code";
      break;
    default:
      n.title = "Unhandled severity: "+e.severity;
  }

  log.trace("convertMyError(), returning n:", n);
  return n;
}

function cA_Notify(notification = null) {
  log.trace("cA_Notify(), notification:", notification);
  if (notification == null) {
    return notify(DEFAULT_NOTIFICATION);
  }
  
  // If we got here, the caller passed us an error/notification to display.
  // The passed in notification object might be a MyError
  // class instance, or it might be just an ordinary Object that
  // has notification-like properties.
  // If the caller passed us a MyError object as the notification parameter,
  // convert the MyError object into an Object with the properties that
  // the notifcation module's notify() function expects.
  const n = _.cloneDeep(DEFAULT_NOTIFICATION);
  if (notification instanceof MyError) {
    const convertedError = convertMyError(notification);
    Object.assign(n, convertedError);
  }
  else {
    Object.assign(n, notification);
  }

 
  // Fill in any missing properties.
  //notification.title = notification.title ? notification.title : "Error";
  //notification.message = notification.message ? notification.message :
  //  "I want to tell you something, but I don't know what it is.";

  // Call the notification module's notify() function with
  // the notification object we have initialized.  This will
  // cause the notification module's reducer to be called and
  // will display the notification.
  return notify(n);
}

export {cA_SetError};
export {cA_Notify};
export {HELLO_NOTIFICATION};
