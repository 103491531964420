import {log, MyError} from 'concierge-common';

import {SW_VERSION} from '../global-constants';

import * as ACTION_TYPES from './types';


/**
 * Update values in the store.state.swState
 * See service-worker-reducer.js for more info.
 */

function cA_SwSetRegistered(swRegistration) {
  return({
    type: ACTION_TYPES.SW_SET_REGISTERED,
    payload:{swRegistration},
  });
}

function cA_SwSetInstalled(swRegistration) {
  return({
    type: ACTION_TYPES.SW_SET_INSTALLED,
    payload:{swRegistration},
  });
}

function cA_SwCheckForUpdate(swRegistration) {
  if (!swRegistration) {
    const msg = "In cA_SwCheckForUpdate(), swRegistration: "+swRegistration+
      "  -- You should sign out, close all browser tabs running Jasiri, "+
      "and then sign back in.";
    const props = {msg};
    const error = MyError.createBugError(props);
    const payload = {
      swRegistration:swRegistration,  // null/undefined value
      error,
    };
    return({
      type: ACTION_TYPES.SW_CHECK_FOR_UPDATE_F,
      payload,
    });
  }

  return({
    type: ACTION_TYPES.SW_CHECK_FOR_UPDATE,
    async payload() {
      log.trace("Calling swRegistration.update(), swRegistration: ",
        swRegistration);

      let newSwRegistration = null;
      try {
        // See if there is an update.
        newSwRegistration = await swRegistration.update();
      }
      catch(err) {
        const msg = err.toString();
        const props = {msg, severity:MyError.ERROR};
        const error = MyError.createSubmitError(props);
        const payload = {
          swRegistration,
          error,
        };
        return payload;
      }

      const payload = {
        swRegistration:newSwRegistration ? newSwRegistration : swRegistration,
      };
      return payload;
    }
  });
}

function cA_SwPossiblyActivateUpdate(swRegistration) {
  return({
    type: ACTION_TYPES.SW_POSSIBLY_ACTIVATE_UPDATE,
    payload:{swRegistration},
  });
}

export {cA_SwSetRegistered};
export {cA_SwSetInstalled};
export {cA_SwCheckForUpdate};
export {cA_SwPossiblyActivateUpdate};
