// This module defines the MultiAction *container*.
// NOT the MultiAction *component*.

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

// Import the MultiActionComponent from which this
// MultiActionContainer is created.
import MultiActionComponent from '../components/multi-action';

// Import action creators that this component will use.
import {cA_SetError} from '../actions/error';
//import {cA_SetSidebarVisibility} from '../actions/ui';
//import {cA_SetFooterVisibility} from '../actions/ui';
//import {cA_SetShellContents} from '../actions/ui';
import {cA_MultiAction} from '../actions/ui';
import {cA_DispatchAction} from '../actions/ui';

function mapStateToProps(state) {
  return({
      ui:state.ui,
      //curUser:state.curUser,
      //error:state.error
  });
}

const mapDispatchToProps = {cA_MultiAction, cA_DispatchAction, cA_SetError};

const MultiActionContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(MultiActionComponent));
export default MultiActionContainer;
