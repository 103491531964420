import React from 'react';
import {Container, Label, Segment} from 'semantic-ui-react';
import {log} from 'concierge-common';


// TODO: Put this in a container so it has direct access to state.app?
class Footer extends React.Component {

  render() {
    log.trace("Footer.render() this.props:", this.props);
    const props = this.props;
    let {className, visible, message, messageType/*, hideTime*/} = props;

    if (message == null) {
      messageType = "status";
      message = "Nothing to report.";
    }

    // Override visible prop if the message is an error.
    // TODO: Let the curUser have a setting which overrides this.
    // TODO: Perhaps have this work instead of notification module
    // if the curUser has forced us to not show the footer.
    //const curTime = new Date().getTime();
    //const hideExpired = (curTime - hideTime) > 1000;
    //visible = (messageType == "error" && hideExpired) ? true : visible;

    // Possibly add "hide " to className, being careful with leading
    // and trailing space characters " ".
    className = className == null ? "" : className;
    className = visible ? className : "hide "+className;
    className = className == "" ? className : " "+className;

    let color = "";
    let prefix = "";
    switch (messageType) {
      case "error":
        color = "red";
        prefix = "Error";
        break;
      case "success":
        color = "green";
        prefix = "Success";
        break;
      default:
        color = "";
        prefix = "Status";
    }
    prefix = prefix+":";

    const segmentClassName = "ellipsis fluid "+color;
    const labelClassName = "basic large superbasic padright "+color;

    return(
      <Container className={"shell-footer fluid"+
        className} textAlign="left">
        <Segment basic className={segmentClassName}>
          <Label className={labelClassName}>{prefix}</Label>
          <Label basic className="superbasic">{message}</Label>
        </Segment>
        <Label as='a' icon="delete" size="big"
          onClick={() => props.cA_SetFooterVisibility(false)} />
      </Container>
    );
  };
}

export default Footer;

