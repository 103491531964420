/**
 */
import React from 'react';
import {Icon, Button} from 'semantic-ui-react';
//import ReactImage from 'react-image';
import {log} from 'concierge-common';
import {ImageUtils as ImageUtilsCom} from 'concierge-common';
import {HttpStatus, ConciergeRestApi, MyError} from 'concierge-common';

import MyPopup from '../containers/my-popup';

import ActionUtils from './utils/action-utils';
import RibbonUtils from './utils/ribbon-utils';
import RibbonContainer from './ribbon-container';


class ActionImage extends React.Component {

  /**
   * Image upload succeeded.  Show a notification message.
   * and then call the consumer supplied uploadSuccessHandler
   * callback.  If a User object was the consumer, the callback
   * it supplied use will update update the User object's profile
   * image src property to point to the newly uploaded image.
   * The Act object's callback would do something similar.
   *
   * This function is called whether the server or the client did
   * the upload to Firebase, assuming the upload succeeded.
   */
  uploadSuccessHandler(imageURL) {
    log.trace("ActionImage.uploadSuccessHandler(), imageURL:", imageURL);
    const {cA_SetError, cA_SetLoader, uploadSuccessHandler} = this.props;
    log.trace("uploadSuccessHandler:", uploadSuccessHandler);
    log.trace("typeof uploadSuccessHandler:", typeof uploadSuccessHandler);
    const props = {
      msg:"Image upload succeeded.",
      severity:MyError.SUCCESS
    };
    const error = MyError.createSubmitError(props);
    cA_SetError(error);

    // Image upload succeeded.  Now call the consumer supplied
    // uploadSuccessHandler.  For example, a User object's
    // uploadSuccessHandler will change the User's image "src"
    // property to point to the newly uploaded image.
    log.trace("Calling uploadSuccessHandler");
    uploadSuccessHandler(imageURL);
    log.trace("Returned from uploadSuccessHandler");

    // TODO: Perhaps make the call above an async call so we can
    // wait until it returns before setting the loader to false?
    cA_SetLoader(false);
  }

  /**
   * Error handler if this client does the upload to Firebase.
   * (As opposed to the concierge-rest server doing the upload to Firebase.)
   */
  uploadErrorHandlerClient(err) {
    log.trace("Error during upload, err:", err);
    let msg = "Image upload failed.  ";
    try {
      const serverResponse = JSON.parse(err.serverResponse);
      const {code} = serverResponse.error;
      if (code == HttpStatus.FORBIDDEN) {
        const maxSize = ConciergeRestApi.MAX_IMAGE_SIZE_STRING;
        msg += "Image too large.  Maximum allowed size: "+maxSize;
      }
      else {
        msg += "Firebase ref.put(file) threw error.  err.serverResponse: "+err.serverResponse;
      }
    }
    catch(errNotUsed) {
      // Exception thrown while trying to parse the "err".
      // So simply try to display it as a string.
      msg += "Possible code bug?  Details: err: "+err;
    }
    const props = {msg};
    const error = MyError.createSubmitError(props);
    const {cA_SetError, cA_SetLoader} = this.props;
    cA_SetError(error);
    cA_SetLoader(false);
  }

  /**
   * Called when the image does not exist at the specified URL.
   * Display the fallback image.
   */
  handleImageError(e) {
    log.trace("ActionImage.handleImageError(), e:", e);
    let {srcFallback, imageErrorHandler, imageId} = this.props;
    srcFallback = srcFallback ? srcFallback :
      ImageUtilsCom.UPLOAD_PLACEHOLDER_IMAGE;
    log.trace(ImageUtilsCom.UPLOAD_PLACEHOLDER_IMAGE);
    log.trace(srcFallback);

    const imageElement = e.target;

    // Remove error callback just in case the srcFallback is not
    // there either.  Don't want an infinite loop.
    // Doesn't work.  Still get infinite loop.
    //imageElement.onError = null;
    //delete imageElement.onError;

    imageElement.src = srcFallback;

    if (imageErrorHandler) {
      imageErrorHandler(imageId);
    }
  }

  createImage() {
    const props = this.props;
    const {src, imgClassName, onClick} = props;

    const onError = this.handleImageError.bind(this);

    let className = "ui image fluid";
    className = imgClassName ? className+" "+imgClassName : className;
    const imageProps = {
      src,
      className,
      //WORKS onLoad:alert('image loaded successfully'),
      //WORKS onError:alert('error'),
      onError,
      onClick,
    };

    const image = <img {...imageProps} alt="" />
    return image;
  }

  // TODO: This should probably become a module of its own.
  createActionsButton(multiAction) {
    log.trace("ActionImage.createActionsButton(), multiAction:",
      multiAction);
    const content = ActionUtils.getActionsString(multiAction);
    const popupProps = {
      content,
      position:"bottom left",  // Use dependent.  Works for now.
    };
    const actionsButton =
      <MyPopup {...popupProps}
        trigger={
        <Button primary icon className="show-actions"
          onClick={multiAction.dispatch}>
          <Icon name="ellipsis vertical" size="large" />
        </Button>
        } />
    return actionsButton;
  }

  createRibbonContainer() {
    const {obj, curUserObj, cA_Confirmation, cA_SetError, cA_History} = this.props;
    const ribbons = RibbonUtils.calcRibbons(obj, curUserObj,
      cA_Confirmation, cA_SetError, cA_History);
    if (!ribbons || ribbons.length < 1) {
      return undefined;
    }
    return <RibbonContainer ribbons={ribbons} />
  }

  render() {
    log.trace("ActionImage.render() this.props:", this.props);
    const props = this.props;
    const {multiAction} = props;
    let {popupContent} = props;

    log.trace("uploadSuccessHandler:", this.props.uploadSuccessHandler);

    const image = this.createImage();
    const ribbonContainer = this.createRibbonContainer();

    const actionsButton = multiAction ?
      this.createActionsButton(multiAction) : undefined;
    if (!popupContent && actionsButton) {
      popupContent = "Click the [ \u22EE ] button for actions.";
    }

    //const visibilityIcon = ComponentUtils.createVisibilityIcon(obj);
    //log.trace("visibilityIcon:", visibilityIcon);

    // TODO: If I am sure I don't want to have some sort of drop
    // area on an image, get rid of this.
    const dropzone = undefined;

    //const className = ribbonContainer ?
      //"image-and-button ribbons-shown" : "image-and-button";
    const className = "image-and-button";
    const imageAndButton =
      <div className={className}>
        <div className="image-dropzone-container">
          {image}
          {/*
          Note: ribbonContainer can be placed before or after
          the {dropZone}.  It will be positioned in the UI in
          the same place either way.
          */}
          {ribbonContainer}
          {dropzone}
        </div>
        {actionsButton}
      </div>

    return(
      <MyPopup content={popupContent}
        position="bottom center"
        trigger={imageAndButton}
      />
    )
  }
}

export default ActionImage;
