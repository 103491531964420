/**
 */
import React from 'react';
import {Card, Label, Icon} from 'semantic-ui-react';
import {log} from 'concierge-common';

import LocalDB from '../client-db/local-db';
import Avatar from '../containers/avatar';
import MyPopup from '../containers/my-popup';
import ObjUtils from '../client-db/api/obj-utils';
import ActionUtils from './utils/action-utils';


class UserLine extends React.Component {

  handleClickMessageIcon(e, userId) {
    if (e) {
      e.stopPropagation();
    }
    const url = "/createMessage?userId="+userId+
      "&task=message";  // message, connect, reply
    const {cA_History} = this.props;
    cA_History("push", url);
  }

  /**
   * User clicked somewhere other than the Avatar or the
   * "message icon", so display the MultiAction modal dialog.
   */
  handleClick(e, action) {
    if (!action) {
      return;
    }
    if (e) {
      e.stopPropagation();
    }
    action.dispatch();
  }

  createAvatar(user) {
    const avatarProps = {
      user,
      multiActionTitle:"Avatar Actions",
    };
    return <Avatar {...avatarProps} />;
  }

  /**
   * @param {Action} action - Action to be executed when clicked.
   */
  createMessageIcon(userId) {
    const iconProps = {
      size:"large",
      name:"mail outline",
      onClick:(e) => this.handleClickMessageIcon(e, userId),
    };
    return <Icon {...iconProps} />
  }

  calcContainerProps(curUser, user, noDrillDown) {

    //const multiAction = ActionUtils.calcUserMultiAction(
    //  this.props, curUser, user, "User Actions", noDrillDown);
    const multiAction = ActionUtils.calcObjMultiAction(
      this.props, curUser, user, "User Actions", noDrillDown);
    let as;
    let className = "no-border ellipse";
    let onClick;
    if (multiAction) {
      as = "a";
      className += " has-action";
      onClick = e => this.handleClick(e, multiAction);
    }
    else {
      // No actions are possible, so props remain undefined and
      // clicking on this component does nothing.
    }

    const containerProps = {
      className,
      basic:true,
      as,
      onClick,
    };
    return containerProps;
  }

  render() {
    log.trace("UserLine.render() this.props:", this.props);
    const props = this.props;
    const {curUser, user, popupContent:content,
      noDrillDown} = props;

    const containerProps = this.calcContainerProps(curUser,
      user, noDrillDown);

    let messageIcon;
    if (user.sendMessage) {
      messageIcon = this.createMessageIcon(user._id);
    }

    log.trace("Call createAvatar(), user: ", user);
    const avatar = this.createAvatar(user);

    // This is the actual "user line" that will be wrapped
    // in the MyPopup.
    const trigger = 
      <Label {...containerProps}>
        {avatar}
        <span className="user-name ellipsis">
          {user.displayName}
        </span>
        {messageIcon}
      </Label>

    const popupProps = {
      content,
      trigger
    };

    const component = 
      <Card.Content extra className="user-line">
        <MyPopup {...popupProps} />
      </Card.Content>

    return component;
  };
}

export default UserLine;
