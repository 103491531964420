// This module defines the Shell *container*.
// NOT the Shell *component*.

import {connect} from 'react-redux';

// Import the ShellComponent from which this ShellContainer is created.
import ShellComponent from '../components/shell';

// Import action creators that this component will use.
import {cA_SetError} from '../actions/error';
import {cA_SetSidebarVisibility} from '../actions/ui';
import {cA_SetFooterVisibility} from '../actions/ui';
//import {cA_SetShellContents} from '../actions/ui';
import {cA_Confirmation} from '../actions/ui';

function mapStateToProps(state) {
  return({
      ui:state.ui,
      curUser:state.curUser,
      error:state.error
  });
}

/*
import {bindActionCreators} from 'redux';
function mapDispatchToProps(dispatch) {
  //return bindActionCreators({createFetchFilteredActsAction: createFetchFilteredActsAction}, dispatch);
  return bindActionCreators({createFetchFilteredActsAction}, dispatch);
}
*/
// This is a short form of the above.
// Note: by using the ES6 syntax of creating an object whose properties
// have the same name as the values they are set to, the props argument
// that gets passed to the component's render() method will have the
// same name as the action creator function that we are passing to the
// mapDispatchToProps() function.  E.g. the SignOut button's onClick()
// handler would create and dispatch the signout action like this:
//
//    this.props.createSignOutAction()
//
// I.e. the above is a pointer to the action creator function named
// createSignOutAction() that is in our src/actions directory
// We could, if we wanted, give a different name for the property.
// For example:
//
//  const mapDispatchToProps = {crSgnOutAct:createSignOutAction,
//                              crSgnInAct:createSignInAction}
//
// would mean that the action creator function would be accessible
// in this component's render() method as this.props.crSgnOutAct()
// 
const mapDispatchToProps = {cA_SetSidebarVisibility,
                            cA_SetFooterVisibility,
                            //cA_SetShellContents,
                            cA_Confirmation,
                            cA_SetError};

const ShellContainer = connect(mapStateToProps, mapDispatchToProps)(ShellComponent);
export default ShellContainer;
