// This module defines the PropsEditor *container*.
// NOT the PropsEditor *component*.
import {connect} from 'react-redux';

// Import the PropsEditorComponent from which this
// PropsEditorContainer is created.
import PropsEditorComponent from '../components/props-editor';

import {cA_PropsEditor} from '../actions/ui';
import {cA_SetFilterAndSort} from '../actions/ui';
import {cA_SetFilterAndSortVisibility} from '../actions/ui';

function mapStateToProps(state) {
  return({
    propsEditor:state.ui.propsEditor,
    shellType:state.ui.shellType,
    storeState:state,
    //filterAndSort:state.ui.filterAndSort,
  });
}

const mapDispatchToProps = {cA_PropsEditor, cA_SetFilterAndSort,
  cA_SetFilterAndSortVisibility};
const PropsEditorContainer = connect(mapStateToProps, mapDispatchToProps)
  (PropsEditorComponent);
export default PropsEditorContainer;
