import _ from 'lodash';
import * as RxDB from 'rxdb';
import {log} from 'concierge-common';
import {USERS, PENDING, APPROVED} from '../global-constants';
//import {DEFAULT_MESSAGES_LAST_RECEIVED, DEFAULT_MESSAGES_LAST_READ}
//  from '../global-constants';


class Migration {  
  static getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    //The maximum is inclusive and the minimum is inclusive
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  static migrationStrategiesGlobals = undefined;
  /*
  static migrationStrategiesGlobals = {
    // 1 means, this transforms data from version 0 to version 1
    1: function(oldDoc){
      if (oldDoc.nextWayImageIndex === null ||
          oldDoc.nextWayImageIndex === undefined) {
        oldDoc.nextWayImageIndex = 0;
      }
      return oldDoc;
    },
  }
  */

  /** 
   * Property 1 transforms data from any version lower than
   * version 1, (i.e. version 0), into version 1.
   * Property 2 transforms data from any version lower than
   * version 2, (i.e. version 0 or 1), into version 2.
   * Property 3 transforms data from any version lower than
   * version 3, (i.e. version 0, 1, or 2), into version 3.
   */
  //static migrationStrategiesObjs = undefined;
  static migrationStrategiesObjs = {
    1: function(oldDoc) {
      // Changes from version 0 to 1
      // Added guides property.
      return oldDoc;
    },
    2: function(oldDoc) {
      // Changes from version 1 to 2
      // Moved visibility and userIds out of access.
      // Added recommdations property.
      if (oldDoc.access) {
        oldDoc.visibility = oldDoc.access.visibility ?
          oldDoc.access.visibility : "isPublic";
        oldDoc.userIds = oldDoc.access.userIds &&
          Array.isArray(oldDoc.access.userIds) ?
          oldDoc.access.userIds : [];
        delete oldDoc.access;
      }
      return oldDoc;
    },
  };
  static defaultMigrationStrategy = (oldDoc) => {
    if (oldDoc.created) {
      oldDoc.created = new Date(oldDoc.created).getTime();
    }
    if (oldDoc.expires) {
      oldDoc.expires = new Date(oldDoc.expires).getTime();
    }
    if (oldDoc.messagesLastReceived) {
      oldDoc.messagesLastReceived = new Date(
        oldDoc.messagesLastReceived).getTime();
    }
    if (oldDoc.messagesLastRead) {
      oldDoc.messagesLastRead = new Date(
        oldDoc.messagesLastRead).getTime();
    }

    if (oldDoc.avatarSrc === null) {
      oldDoc.avatarSrc = undefined;
    }
    if (oldDoc.token || oldDoc.token === null) {
      delete oldDoc.token;
    }
    if (oldDoc.attributes) {
      delete oldDoc.attributes;
    }
    if (oldDoc.access) {
      oldDoc.visibility = oldDoc.access.visibility ?
        oldDoc.access.visibility : "isPublic";
      oldDoc.userIds = oldDoc.access.userIds &&
        Array.isArray(oldDoc.access.userIds) ?
        oldDoc.access.userIds : [];
      delete oldDoc.access;
    }
    return oldDoc;
  };

  static async migrateCollection(collection, migrationStrategies) {

    const collectionSchemaVersion = collection.schema.version;
    const latestSchemaVersion = _.size(migrationStrategies);
    log.info("collectionSchemaVersion: ", collectionSchemaVersion);
    log.info("latestSchemaVersion: ", latestSchemaVersion);
    if (collectionSchemaVersion >= latestSchemaVersion) {
      log.info("Already at most recent version of collection: ",
        collection.name);
      //return;
    }
    const migrationStrategy = migrationStrategies &&
      _.size(migrationStrategies) > 0 ?
      migrationStrategies[latestSchemaVersion] :
      Migration.defaultMigrationStrategy;
      
    const docs = await collection.find().exec();
    log.trace("docs: ", docs);
    //docs.forEach(doc => {
    //  const newDoc = migrationStrategy(doc);
    await Promise.all(docs.map(async (doc) => {
      const newDoc = await doc.atomicUpdate(migrationStrategy);
      log.trace("newDoc: ", newDoc);
    }));
  };
}

export default Migration;
