// This module defines the CreateMessagePanel *container*.
// NOT the CreateMessagePanel *component*.
import {connect} from 'react-redux';

//import {log} from 'concierge-common';

// Import the CreateMessagePanelComponent from which this CreateMessagePanelContainer is created.
import CreateMessagePanelComponent from '../components/create-message-panel';

// Import action creators that this component will use.
//import {cA_SendMessage} from '../actions/user';
import {cA_SetError} from '../actions/error';
//import {cA_Notify} from '../actions/error';
//import {cA_SetShellContents} from '../actions/ui';
import {cA_History} from '../actions/ui';
//import {cA_NotYetImplemented} from '../actions/ui';


function mapStateToProps(state) {

  const props = {
    curUser:state.curUser,
  };

  return props;
}
const mapDispatchToProps = {cA_SetError, /*cA_SendMessage,*/cA_History};
const CreateMessagePanelContainer = connect(mapStateToProps, mapDispatchToProps)(CreateMessagePanelComponent);
export default CreateMessagePanelContainer;
