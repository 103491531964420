import React from 'react';
//import Auth0Context from '../contexts/auth0-context';
import {log} from 'concierge-common';
import * as ACTION_TYPES from '../actions/types';
import store from '../store';


class LoginSignupLogoutButton extends React.Component {


  handleLoginClick = async (auth0State) => {
    log.trace("Before loginWithRedirect");
    const redirect_uri = window.location.origin+"/signedIn";
    await auth0State.loginWithRedirect({redirect_uri});
    log.trace("After loginWithRedirect");
  }

  handleLogoutClick = async (auth0State) => {
    const {userC} = auth0State;
    log.trace("handleLogoutClick(), userC: ", userC);
    const returnTo = window.location.origin+"/signedOut";
    await auth0State.logout({returnTo});

    const action = {
      type:ACTION_TYPES.SIGN_OUT_S,
      payload:{user:{id_:null, token:null}},
    };
    store.dispatch(action);
  }

  componentDidMount() {
    log.trace("LoginButton componentDidMount(), this.props.cA_SetAuth0State:",
      this.props.cA_SetAuth0State);
    this.props.cA_SetAuth0State();
  }

  render() {
    const {auth0State} = this.props;
    log.trace("LoginButton render(), auth0State:", auth0State);

    if (!auth0State || !auth0State.auth0Client) {
      return(
        <div className="right menu">
          <button className="item">
            Loading DB...
          </button>
        </div>
      );
    }

    log.trace("LoginButton render(), auth0State.userAuth0:",
      auth0State.userAuth0);
    log.trace("LoginButton render(), auth0State.isAuthenticated:",
      auth0State.isAuthenticated);
    const {isLoading, isAuthenticated, userAuth0} = auth0State;
    //const {isAuthenticated, loginWithRedirect, logout} = auth0Context;
    //const {auth0Client} = auth0Context;
    log.trace("LoginButton render(), userAuth0:", userAuth0);
    log.trace("LoginButton render(), !userAuth0:", !userAuth0);
    log.trace("LoginButton render(), !isAuthenticated:", !isAuthenticated);

    let button;
    if (isLoading) {
      // Auth0 software loading...
      // TODO: Display a loading spinner instead?
      button =
        <button className="item">
          Loading Auth0...
        </button>
    }
    //else if (!userAuth0) {
    //else if (userAuth0 == null || userAuth0 == undefined) {
    else if (!isAuthenticated) {
      // User is not logged in.  Display Login/Signup button.
      button =
        <button className="item"
          onClick={() => this.handleLoginClick(auth0State)}>
          Sign In / Sign Up
        </button>
    }
    else {
      // User is logged in.  Display Logout button.
      button = 
        <button className="item"
          onClick={() => this.handleLogoutClick(auth0State)}>
          Sign Out
        </button>
    }

    return (
      <div className="right menu">
        {button}
      </div>
    );
  }
}

export default LoginSignupLogoutButton;
