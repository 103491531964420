import _ from 'lodash';
import React from 'react';
import {Label, Icon} from 'semantic-ui-react';
import {log, MyError} from 'concierge-common';
import {USERS, WAYS, ACTS, ITNS} from '../../global-constants';
import {FLAGGED, PENDING} from '../../global-constants';
import UserC from '../../client-db/api/user';
import ApiUtils from '../../client-db/api/api-utils';
import MyPopup from '../my-popup';
import {approve, viewMessages} from './obj-actions';
import TimeUtils from './time-utils';


class RibbonUtils {

  static calcVisibilityComponent(obj) {
  if (!obj) {
      return undefined;
    }
    const prototype = Object.getPrototypeOf(obj);
    log.trace("prototype:", prototype);
    const DISPLAY_NAME = prototype.constructor.DISPLAY_NAME; 

    const visibility = obj.visibility;
    let iconName;
    let popupContent;
    switch(visibility) {
      case "isPrivate":
        iconName = "hide";
        //iconName = "privacy";
        //iconName = "dont";
        popupContent = 'This '+DISPLAY_NAME+' is "Private".  '+
          'Only visible to the owner and administrators.';
        break;
      case "isProtected":
        iconName = "protect";
        popupContent = 'This '+DISPLAY_NAME+' is "Protected".  '+
          'Some of its attributes, e.g. its location, are not '+
          'publicly visible.';
        break;
      case "isPublic":
        // Fall through
      default:
        return undefined;
    }
    if (!iconName) {
      return undefined;
    }

    let component = <Label className="right ribbon visibility-ribbon">
        <div className="visibility-icon-container">
          <Icon size="large" name={iconName} />
        </div>
      </Label>

    component = <MyPopup content={popupContent} trigger={component}
      key="visIcon" />
    
    return {component};
  }

  /**
   * Calculates which Ribbons will be displayed.
   *
   * @param {Object} obj - An object such as User or Act.
   */
  static calcRibbons(obj, curUserObj, cA_Confirmation,
    cA_SetError, cA_History) {
    if (!obj || !curUserObj) {
      return [];
    }

    // Initialize the possibleRibbons array which tells us
    // which ribbons can POSSIBLY be displayed on an object.
    // E.g. Every object, (User, Way, Act, Itn), can get a
    // "New" ribbon if it has recently been created.
    // Both Way and Act objects can be "Near You", but only
    // Act and Itn objects can be "Expired".
    const possibleRibbons = [];
    possibleRibbons.push(FLAGGED);
    possibleRibbons.push(PENDING);
    possibleRibbons.push("new");
    possibleRibbons.push("favorite");
    if (obj.cn == WAYS) {
      //possibleRibbons.push("near");
    }
    else if (obj.cn == ACTS || obj.cn == ITNS) {
      //possibleRibbons.push("near");
      possibleRibbons.push("expired");
      possibleRibbons.push("expiring");
    }
    else if (obj.cn == ITNS) {
      //possibleRibbons.push("near");
    }
    else if (obj.cn == USERS) {
      //possibleRibbons.push("near");
      possibleRibbons.push("guide");
      possibleRibbons.push("staff");
      possibleRibbons.push("pendingConnection");
      possibleRibbons.push("acceptConnection");
      possibleRibbons.push("connected");
      possibleRibbons.push("unreadMessages");
    }

    // At this point we have filled possibleRibbons with the
    // ribbons that are possible to display on this object.
    // No go through and figure out which of these to display.

    // NOTE: Only some of these properties might exist in the obj.
    const {statusAttribute} = obj;

    const isAdmin = curUserObj && curUserObj.hasAdminPriv &&
      curUserObj.hasAdminPriv();
    //const isAdmin = true;
    const ribbons = [];
    if (_.includes(possibleRibbons, FLAGGED) &&
        statusAttribute == FLAGGED/* && isAdmin*/) {
      const action = approve(obj, curUserObj, cA_Confirmation);
      const onClick = action && action.dispatch ?
        action.dispatch : undefined;
      const ribbonProps = {
        //as:'a',
        color:'red', content:'Flagged', icon:'warning sign',
        ribbon:"right", onClick};
      ribbons.push(ribbonProps);
    }

    if (_.includes(possibleRibbons, PENDING) &&
        statusAttribute == PENDING/* && isAdmin*/) {
      // If curUserObj has the permission, add an onClick callback
      // to approve this pending object.
      const action = approve(obj, curUserObj, cA_Confirmation);

      // Display halt hand and label "Pending" normally,
      // but if the curUser is an admin, the admin can click on
      // this ribbon to approve the object.  So if onClick is
      // defined we display the thumbs up icon and label "Approve".
      // The approve() function returned an action that gives us
      // that information.
      const onClick = action && action.dispatch ?
        action.dispatch : undefined;
      const icon = onClick ?
        "thumbs up" :
        "hand paper outline";
      const content = onClick ? "Approve" : "Pending";
      const ribbonProps = {
        //as:'a', 
        color:'green', content, icon,
        ribbon:"right", onClick};
      ribbons.push(ribbonProps);
    }

    // If we displayed a Pending or Flagged ribbon, don't display
    // any other ribbons.
    if (!ribbons.length) {
      const {displayName, created, expires} = obj;
      if (_.includes(possibleRibbons, "new") && created &&
          Date.now() - created < (1 * TimeUtils.WEEK)) {
        const ribbonProps = {
          //as:'a',
          color:'green', content:'New', icon:'announcement',
          ribbon:"right"};
        ribbons.push(ribbonProps);
      }

      // If current time is greater than "expires" time, display
      // the "Expired" ribbon.
      if (_.includes(possibleRibbons, "expired") && expires &&
          Date.now() > expires) {
        const ribbonProps = {
          //as:'a', color:'red', content:'Expired', icon:'dont',
          color:'red', content:'Expired', icon:'dont',
          ribbon:"right"};
        ribbons.push(ribbonProps);
      }
      // If "expires" time minus current time is less than a few
      // days, display the "Expiring" ribbon.
      else if (_.includes(possibleRibbons, "expiring") && expires &&
               expires - Date.now() < (3 * TimeUtils.DAY)) {
        const ribbonProps = {
          //as:'a', color:'orange', content:'Expiring', icon:'clock',
          color:'orange', content:'Expiring', icon:'clock',
          ribbon:"right"};
        ribbons.push(ribbonProps);
      }

      if (_.includes(possibleRibbons, "staff") && 
          obj.isHardCodedUser && obj.isHardCodedUser()) {
        const ribbonProps = {
          //as:'a',
          color:'green', content:'Jasiri Staff',
          //icon:'smile outline',
          //icon:'user circle outline',
          //icon:'user outline',
          //icon:'inbox',
          icon:'address card outline',
          ribbon:"right"};
        ribbons.push(ribbonProps);
      }
      else if (_.includes(possibleRibbons, "guide") && 
          obj.hasRole && obj.hasRole(UserC.Roles.GUIDE)) {
        const ribbonProps = {
          //as:'a',
          color:'green', content:'Guide',
          //icon:'hand point left outline',
          //icon:'flag outline',
          icon:'map signs',
          ribbon:"right"};
        ribbons.push(ribbonProps);
      }

      // TODO: Make this use actual location.
      // TODO: Change icon based on how near it is.  car/bike/person
      //
      // Currently, just for testing UI, it displays "Near You"
      // if displayName starts with Near.
      // NOTE: possibleRibbons must be set to true for the obj.
      //
      if (_.includes(possibleRibbons, "near") && displayName &&
          displayName.startsWith("Near")) {
        const ribbonProps = {
          //as:'a', color:'blue', content:'Near You', icon:'location arrow',
          color:'blue', content:'Near You', icon:'location arrow',
          ribbon:"right"};
        ribbons.push(ribbonProps);
      }

      if (_.includes(possibleRibbons, "pendingConnection") &&
          obj.connectionPending(curUserObj._id)) {
        const msg = obj.displayName+" has not yet responded to your "+
          "previous connection request.";
        const error = MyError.createSubmitError({msg});
        error.severity = MyError.INFO;
        log.trace("error: ", error);
        const onClick = () => cA_SetError(error);
        const ribbonProps = {
          //as:'a', 
          color:'green', content:'Pending',
          icon:"connectdevelop",
          ribbon:"right", onClick};
        ribbons.push(ribbonProps);
      }
      else if (_.includes(possibleRibbons, "acceptConnection") &&
          obj.acceptConnection) {
        const onClick = () =>
          ApiUtils.acceptConnection(curUserObj, curUserObj, obj);
        const ribbonProps = {
          //as:'a', 
          color:'green', content:'Accept Connection',
          icon:"connectdevelop",
          ribbon:"right", onClick};
        ribbons.push(ribbonProps);
      }
      else if (_.includes(possibleRibbons, "connected") &&
          obj.isConnected(curUserObj._id)) {
        const ribbonProps = {
          //as:'a', 
          color:'green', content:'Connected',
          icon:"connectdevelop",
          ribbon:"right"};
        ribbons.push(ribbonProps);
      }

      if (_.includes(possibleRibbons, "favorite") && 
          curUserObj && Array.isArray(curUserObj.favorites) &&
          _.includes(curUserObj.favorites, obj._id)) {
        const onClick = () => {
          /*
          const favorites = curUserObj.favorites.slice(0);
          _.pull(favorites, obj._id);
          const newProps = {favorites};
          ApiUtils.editRxDBObj(curUserObj, curUserObj._id, newProps);
          */
          ApiUtils.possiblyRemoveFromFavorites(obj,
            curUserObj, curUserObj, cA_Confirmation);
        };
        const ribbonProps = {
          //as:'a', color:'blue', content:'Near You', icon:'location arrow',
          color:'teal', content:'', icon:'heart outline',
          ribbon:"right", onClick};
        ribbons.push(ribbonProps);
      }

      // If curUser is an admin, show users that have unread messages.
      // Use a red ribbon if it is a staff member that has unread messages.
      if (_.includes(possibleRibbons, "unreadMessages") &&
          isAdmin && obj.hasUnreadMessages) {
        const action = viewMessages(obj);
        const onClick = action && action.url ?
          () => cA_History("push", action.url) : undefined;
        const color = obj.isHardCodedUser() ? "red" : "blue";
        const ribbonProps = {
          color, content:'Unread', icon:'mail outline',
          ribbon:"right", onClick};
        ribbons.push(ribbonProps);
      }
    }

    // This isn't really a "ribbon".  We use CSS to cancel out
    // everything but the icon.
    // TODO: Make this its own kind of component.  Not just
    // a Label.  That way we can properly center the icon character
    // in its "box".
    //const visibilityIcon = RibbonUtils.calcVisibilityIcon(obj);
    const visibilityIcon = RibbonUtils.calcVisibilityComponent(obj);
    if (visibilityIcon) {
      ribbons.push(visibilityIcon);
    }

    return ribbons;
  }
}

export default RibbonUtils;
