/**
 */
import _ from 'lodash';
import React from 'react';
import {Modal, Label, Header, Button, Icon} from 'semantic-ui-react';
import {log} from 'concierge-common';


// A modal dialog to edit an object's properties.
class PropsEditor extends React.Component {

  /**
   * Called when the PropsEditor Modal's "Ok" button is clicked.
   */
  handleClickOk = (syntheticEvent, data) => {
    log.trace("PropsEditor.handleClickOk(), syntheticEvent:", syntheticEvent);
    log.trace("PropsEditor.handleClickOk(), data:", data);
    const {cA_PropsEditor, propsEditor} = this.props;
    //const {dialogProps = {}} = propsEditor;
    //const {okAction} = dialogProps;

    // Calling with no args dismisses the dialog.
    cA_PropsEditor();
  }

  // Create the component(s) to edit a single property.
  createPropEditor(propToEdit) {
    const {shellType, storeState} = this.props;
    const {displayName, propName, choices, propPath,
      onChange:onChangeProp} = propToEdit;

    const buttons = choices.map(choice => {
      // NOTE: onChange callback can either come from each
      // individual choice object OR it can come from the
      // propToEdit object that contains all the choices.
      // choice.onChange overrides propToEdit.onChange
      const {label, onChange = onChangeProp} = choice;

      // Whether this button is selected depends on the
      // the value in the store.state."propPath".  But, the
      // logic of how to interpret that property value depends
      // on the propToEdit.propType.  E.g. is the property an
      // array of values such as ["root", "admin", "guide", "traveler"]
      // that works like checkboxes, OR is it more complicated
      // like the sort.created and sort.displayName toggling
      // radioboxes.
      let key;
      let selected = false;
      let iconName = undefined;
      if (propToEdit.propType == "checkboxes") {
        const {value} = choice;
        const defaultValue = [];
        const initialValue = _.get(storeState, propPath, defaultValue);
        selected = _.includes(initialValue, value);
        key = value;
      }
      else if (propToEdit.propType == "togglingRadioBoxes") {
        const defaultValue = null;
        const fullPropPath = propPath+"."+choice.propName;
        const curValue = _.get(storeState, fullPropPath, defaultValue);
        selected = !!curValue;
        const index = curValue ? choice.values.indexOf(curValue) : 0;
        iconName = choice.iconNames[index];
        key = choice.propName;
      }
      const className = selected ? "prope-button selected" : "prope-button";
      const icon = iconName ? <Icon name={iconName} /> : undefined;
        
      const button =
        // NOTE: key is not used.  Keep React happy.
        <Button className={className} key={key}
          size="large" basic color="black"
          onClick={(e) => {onChange(e, choice, propToEdit)}}>
          {label}
          {icon} 
        </Button>
      return button;
    });
    
    const buttonsClassName = //"ui buttons button-drawer"+
      (shellType == "shell-mobile" ? "vertical" : "");
    const component = 
      // NOTE: key is not used.  Keep React happy.
      <div className="padding-container" key={propName}>
        <Button.Group className={buttonsClassName} key={propName} >
          {buttons}
        </Button.Group>
      </div>

    return component;
  }

  // Create multiple property editors.
  createPropEditors() {
    const {propsToEdit, dialogProps} = this.props.propsEditor;

    let propEditors = [];
    if (!propsToEdit || propsToEdit.length < 1) {
      return propEditors;
    }

    propsToEdit.forEach((propToEdit, index) => {
      const propEditor = this.createPropEditor(propToEdit);
      propEditors.push(propEditor);
    });
   
    return propEditors;
  }

  render() {
    const {propsEditor, cA_PropsEditor} = this.props;
    const {propsToEdit, dialogProps = {}} = propsEditor;
    const {
      title = "Properties Editor",
      headerIcon = "unordered list",
      okIcon = "checkmark",
      okLabel = "Ok",
    } = dialogProps ? dialogProps : {};


    // Figure out whether we are displayed.
    // Use !! to convert it to a boolean.
    const open = !!propsToEdit;
    log.trace("propsEditor: ", propsEditor);

    const okButtonProps = {
      //inverted:true,
      positive:true,
      //negative:true,
      //key:"okKey",
    }

    const iconName = "checkmark";
    const icon = <Icon name={iconName} />

    const okButton =
      <Button {...okButtonProps} 
        onClick={this.handleClickOk}>
        {icon}
        {okLabel}
      </Button>

    const propEditors = this.createPropEditors();

  /*
          onClose={(e,d) => {
            log.trace("e: ", e);
            log.trace("d: ", d);
            if (open) cA_PropsEditor();
          }}
          */
    return(
      <Modal className="props-editor" open={open} size="tiny" // "small"
        onClose={() => {if (open) cA_PropsEditor()}}
      >
        <Header icon={headerIcon} content={title} />
        <Modal.Content align="center">
          <div className="prope-button-container">
            {propEditors}
          </div>
        </Modal.Content>
        <Modal.Actions>
          {okButton}
        </Modal.Actions>
      </Modal>
    );
  };
}

export default PropsEditor;
