// This module defines the SidebarContents *container*.
// NOT the SidebarContents *component*.

import {connect} from 'react-redux';

// Import the SidebarContentsComponent from which this
// SidebarContentsContainer is created.
import SidebarContentsComponent from '../components/sidebar-contents';

import {cA_SetSidebarVisibility} from '../actions/ui';

function mapStateToProps(state) {
  return({
    curUser:state.curUser,
    sidebar:state.ui.sidebar,
  });
}

const mapDispatchToProps = {cA_SetSidebarVisibility};

const SidebarContentsContainer = connect(mapStateToProps, mapDispatchToProps)
  (SidebarContentsComponent);

export default SidebarContentsContainer;
