// This module defines the UserLine *container*.
// NOT the UserLine *component*.
import {connect} from 'react-redux';

// Import the UserLineComponent from which this UserLineContainer is created.
import UserLineComponent from '../components/user-line';

import {cA_SetError} from '../actions/error';
import {cA_History} from '../actions/ui';
import {cA_MultiAction} from '../actions/ui';
//import {cA_GetObj} from '../client-db/api/obj-utils';


function mapStateToProps(state) {
  return({
    curUser:state.curUser,
    // Although UserLine does not directly use the state.users
    // property, we want to be notified when it changes because
    // that is where the user we are displaying is stored.
    // TODO: A better solution where we only watch a single user?
    // Same comment for cache.
    // user:state.cache.objs[userId],
    //users:state.users,
    cache:state.cache,
  });
}
const mapDispatchToProps = {cA_MultiAction, cA_History,
  cA_SetError/*, cA_GetObj*/};
const UserLineContainer = connect(mapStateToProps, mapDispatchToProps)
  (UserLineComponent);
export default UserLineContainer;
