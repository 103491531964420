// This module defines the UrlPropPanel *container*.
// NOT the UrlPropPanel *component*.
import {connect} from 'react-redux';

// Import the UrlPropPanelComponent from which this UrlPropPanel is created.
import UrlPropPanelComponent from '../components/url-prop-panel';

// Import action creators that this component will use.
import {cA_SetError} from '../actions/error';
import {cA_History} from '../actions/ui';
import {cA_Confirmation} from '../actions/ui';
import {cA_GetObj} from '../client-db/api/obj-utils';


function mapStateToProps(state) {
  return({
    curUser:state.curUser,
    cache:state.cache,
  });
}
const mapDispatchToProps = {cA_SetError, cA_History, cA_Confirmation,
  cA_GetObj};
const UrlPropPanelContainer = connect(mapStateToProps, mapDispatchToProps)
  (UrlPropPanelComponent);
export default UrlPropPanelContainer;
