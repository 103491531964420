/**
 * This is used to display and edit a single UrlProp.
 *
 * TODO: Currently, it takes a propName, but can only handle
 * that property if it is an array of urls.  Need to handle
 * properties that are a single URL.  E.g. an "About Me" link
 * on a User card, although a list of links like Facebook, Twitter,
 * etc., probably makes sense.
 */
import _ from 'lodash';
import React from 'react';
import {Icon, Button} from 'semantic-ui-react';
import {log, MyError} from 'concierge-common';
import Obj from '../client-db/api/obj';
import ObjUtils from '../client-db/api/obj-utils';
import MyHeader from '../containers/my-header';
import MyPopup from './my-popup';
import UrlProp from './url-prop';
import LocalDB from '../client-db/local-db';


class UrlPropPanel extends React.Component {

  handleClickDelete() {
    const {props, state} = this;
    const {cache, curUser, cA_History, cA_SetError, objId,
      propName, propIndex} = props;
    const {obj} = state;
    log.trace("handleClickDelete(), propIndex:", propIndex);

    log.trace("obj:", obj);
    if (!obj || obj._id.startsWith("default")) {
      const msg = "UrlPropPanel.handleClickDelete() could not get "+
        "the parent object that contains, or will contain, the "+
        "Url property.  Parent objId: "+objId;
      const props = {msg, severity:MyError.BUG};
      const error = MyError.createSubmitError(props);
      cA_SetError(error);
      return;
    }

    let urlPropArray = obj[propName];
    urlPropArray = urlPropArray ? urlPropArray : [];
    if (propIndex < urlPropArray.length) {
      // Create a copy of the array and remove the element
      // from the copy of the array.
      urlPropArray = urlPropArray.slice(0);
      urlPropArray.splice(propIndex, 1);
    }
    else {
      // propIndex is invalid.
      const msg = "UrlPropPanel.handleClickDelete() propIndex("+
        propIndex+") is not valid for array length: "+
        urlPropArray.length;
      const props = {msg, severity:MyError.BUG};
      const error = MyError.createSubmitError(props);
      cA_SetError(error);
      return;
    }
    log.trace("After slice(), urlPropArray:", urlPropArray);

    LocalDB.db[obj.cn].findOne().where("_id").eq(objId).update({
      $set:{urls:urlPropArray}
    });
    cA_History("back");
  }

  getUrlProp() {
    const {propIndex} = this.props;
    const {urls} = this.state;
    let urlProp;
    if (urls && propIndex < urls.length) {
      urlProp = urls[propIndex];
    }
    else {
      urlProp = UrlProp.DEFAULT_URL_PROP;
    }
    return urlProp;
  }

  createHeader() {
    const props = this.props;
    const {cA_History} = props;
    const {obj} = this.state;
    const urlProp = this.getUrlProp();
    let title = obj.displayName+": ";
    title += urlProp ? urlProp.displayName : "Link";

    // Disable Delete button until we have retrieve the data we
    // are displaying/editing.
    const disabled = !obj;
    const rightButton =
      <MyPopup content={"Delete this Link."} position="left center"
        trigger={
          <Button size="large" negative className="icon-and-image right"
            disabled={disabled}
            onClick={this.handleClickDelete.bind(this)}>
            <Icon name="trash alternate" size="large" />
            <span className="label">Delete</span>
          </Button>
        } />

    const hasBackButton = true;
    const myHeaderProps = {
      hasBackButton,
      title,
      rightButton,
      cA_History,
    };

    return <MyHeader {...myHeaderProps} />
  }

  static createStateFromProps(props) {
    log.trace("UrlPropPanel.createStateFromProps(), props:", props);
    const {cache, curUser, objId, propName, cA_GetObj} = props;
    const obj = ObjUtils.get(curUser, objId, undefined, cache, cA_GetObj);
    log.trace("UrlPropPanel.createStateFromProps(), obj:", obj);

    log.trace("obj["+propName+"]:", obj[propName]);
    let urls = obj[propName];
    urls = urls ? urls : [];
    log.trace("urls:", urls);

    const state = {
      obj,
      urls,
    };
    return state;
  }

  /**
   * Description from React documentation (Apr 14, 2018):
   * The new static getDerivedStateFromProps lifecycle is
   * invoked after a component is instantiated as well as
   * when it receives new props. It can return an object
   * to update state, or null to indicate that the new
   * props do not require any state updates.
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    log.trace("getDerivedStateFromProps(), nextProps:", nextProps);
    log.trace("getDerivedStateFromProps(), prevState:", prevState);
    let nextState = UrlPropPanel.createStateFromProps(nextProps);

    nextState = _.isEqual(prevState, nextState) ? null : nextState;
    log.trace("getDerivedStateFromProps(), return:", nextState);
    return nextState;
  }

  /*
  componentDidMount() {
    const stateProps = UrlPropPanel.createStateFromProps(this.props);
    this.setState(stateProps);
  }
  */

  constructor(props) {
    super(props);
    this.state = {
      obj:null,
      urls:[],
    };
  }

  render() {
    log.trace("UrlPropPanel.render(), this.props:", this.props);
    const {curUser, propName, propIndex,
      cA_EditAct, cA_EditItn, cA_SetError} = this.props;
    const {obj} = this.state;
    log.trace("UrlPropPanel.render(), obj:", obj);

    // We don't want to initialize the UrlPropComponent until we
    // can give it the actual object it will use to initialize
    // itself.  Because UrlPropComponent is a controlled component,
    // we cannot change the "initial" props after it is displayed.
    // NOTE: This is kind of a hack and flashes briefly.
    if (Obj.isDefaultObj(obj)) {
      return(
        <div>Getting Object With ID: {obj._id}</div>
      );
    }

    const header = this.createHeader();

    const urlProp = this.getUrlProp();
    const urlPropProps = {
      curUser,
      obj,
      urlProp,
      propName, // Always "urls"????
      propIndex,
      cA_EditAct,
      cA_EditItn,
      cA_SetError,
    };

    return (
      <div className="url-prop-panel">
        {header}
        <UrlProp {...urlPropProps} />
      </div>
    );
  }
}

export default UrlPropPanel;
