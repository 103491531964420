import _ from 'lodash';
import React from 'react';
import {log, ImageUtils} from 'concierge-common';
import createAuth0Client from '@auth0/auth0-spa-js';
import store from '../store';
import {INITIAL_STATE} from '../store';
import {USERS, PENDING, SUPPORT_USER_ID} from '../global-constants';
import {cA_SetAuth0State} from '../actions/ui';
import * as ACTION_TYPES from '../actions/types';
import ApiUtils from './api/api-utils';
import UserC from './api/user';
import {getRxDBObj} from './api/obj-utils';
import LocalDB from './local-db';


const WELCOME_MESSAGE = {
  task:"message",
  text:"Welcome to the Jasiri adventure travel application.  "+
    "Search for adventures and add them to your list of "+
    "future adventures.  "+
    "Once your account is approved by a Jasiri team member "+
    "you will be able to connect with other travelers, post your own "+
    "adventures for other travelers to use, or maybe even "+
    "volunteer yourself as a virtual guide to an area you know well!",
  senderId:SUPPORT_USER_ID,
};

class Auth0Provider {

  // TODO: These values should not appear in the code.
  // Should come from environment or elsewhere.
  static AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN ?
    process.env.REACT_APP_AUTH0_DOMAIN :
    "jasiri.au.auth0.com";

  static AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID ?
    process.env.REACT_APP_AUTH0_CLIENT_ID :
    "w3J1Xz94lWaPS4lFxWiMvgPqIN62drNS";

  static state = {
    auth0Client:null,
    isLoading:true,
    isAuthenticated:false,
    userAuth0:null,
    userRxDB:null,
    userC:null,
    idTokenClaims:null,
  };

  static auth0ClientConfig = {
    // Initialized from .env.local file.
    // e.g. jasiri.au.auth0.com
    domain:Auth0Provider.AUTH0_DOMAIN,
    // e.g. w3z94l...more characters...PqIN62drNS
    client_id:Auth0Provider.AUTH0_CLIENT_ID,
    redirect_uri:window.location.origin,
    // The location to use when storing cache data. Valid
    // values are memory or localstorage. The default
    // setting is memory.
    // Where auth0's idea of current user is stored.
    cacheLocation: 'localstorage',
    // A maximum number of seconds to wait before declaring
    // background calls to /authorize as failed for timeout
    // Defaults to 60s.
    authorizeTimeoutInSeconds: 30,
    max_age: Number.MAX_SAFE_INTEGER,
  };

  static convertAuth0IdToRxDBId(sub) {
    if (sub && sub.startsWith(USERS)) {
      log.bug('In convertAuth0IdToRxDBId(), sub already starts with "'+
        USERS+'"');
      debugger;
      return sub;
    }
    return USERS+"_"+sub;
  }

  static convertUserAuth0ToUserRxDBProps(userAuth0) {
    let created_at = userAuth0["https://jasiri.com/created_at"];
    if (!created_at || created_at.length < 19 ||
      userAuth0["https://jasiri.com/loginsCount"] > 1) {
      // NOTE: loginsCount check is to handle the case of the
      // user logging in using an Auth0 account that s/he had
      // created for some other application in the past.  In that
      // case we do not want to use the Auth0 user created_at date.
      //const d = new Date();
      created_at = Date.now(); //d.toISOString();
    }
    const created = created_at;
    let displayName = userAuth0.name;
    if (!displayName || _.includes(displayName, "@")) {
      displayName = "Your Name";
    }
    //displayName += " ("+created.substring(11, 19)+")";
    const _id = Auth0Provider.convertAuth0IdToRxDBId(userAuth0.sub);
    const userRxDBProps = {
      _id,
      token:"someSortOfToken",
      email:userAuth0.email,
      password:"someSortOfPassword",
      acceptedTerms:false,
      roles:[UserC.Roles.TRAVELER],
      created,
      displayName,
      imageSrc:ImageUtils.PLACEHOLDER_USER_IMAGE,
      avatarSrc:undefined,
      details:"Some details about you...",
      creator:undefined,
      owner:_id,
      flags:["UN"],
      adventures:[],  // Adventures they have done.
      favorites:[],  // Ids of acts, ways, itns
      fellowTravelers:[],
      messages:[],
      messagesLastReceived:UserC.DEFAULT_MESSAGES_LAST_RECEIVED,
      messagesLastRead:UserC.DEFAULT_MESSAGES_LAST_READ,
      urls:[],
      tags:[],
      statusAttribute:PENDING,
      isTestData:false,
      visibility:"isPublic",
      userIds:[],
    };
    return userRxDBProps;
  };

  /**
   * Create and insert a new (just now signed up) RxDB user
   * document in the browser's RxDB users collection.
   * If the call to insert() works, we return the newly
   * created userRxDB document.
   *
   * NOTE: Files that need to be changed when changing the
   * structure of a User object/schema:
   *
   *    contexts/auth0-provider.js
   *    db/api/utils/api-utils.js  dataRxDBToPayload()
   *    concierge-common/src/user.js  DEFAULT_PROPS
   */
  static async createNewUserRxDB(userAuth0) {
    const userRxDBProps = Auth0Provider.convertUserAuth0ToUserRxDBProps(
      userAuth0);
    userRxDBProps.imageSrc = await LocalDB.getNextUserImageSrc();
    userRxDBProps.messages = [WELCOME_MESSAGE];
    userRxDBProps.messagesLastReceived = Date.now();//(new Date()).toISOString();

    // Create the new user in our browser's RxDB users collection.
    // Do this using a Redux action instead?
    log.trace("createNewUserRxDB(), userRxDBProps:", userRxDBProps);
    const curUser = null;
    //const payload = await ApiUtils.createRxDBUser(curUser, userRxDBProps);
    const payload = await ApiUtils.createRxDBObj(curUser, userRxDBProps);
    log.trace("payload: ", payload);

    return payload;
  }

  // Handle the authentication callback.
  // This is called for both Sign Up and Sign In.
  static async handleRedirectCallback() {
    log.trace("Enter handleRedirectCallback()");
    //this.setState({isLoading:true});
    Auth0Provider.state.isLoading = true;

    await Auth0Provider.state.auth0Client.handleRedirectCallback();
    Auth0Provider.state.userAuth0 =
      await Auth0Provider.state.auth0Client.getUser();
    log.trace("userAuth0:", Auth0Provider.state.userAuth0);
    if (!Auth0Provider.state.userAuth0) {
      log.bug("In handleRedirectCallback(), userAuth0: ",
      Auth0Provider.state.userAuth0);
      return;
    }

    log.trace("userAuth0.sub:", Auth0Provider.state.userAuth0.sub);
    Auth0Provider.state.idTokenClaims =
      await Auth0Provider.state.auth0Client.getIdTokenClaims();

    // Figure out how long ago the userAuth0 was created.
    // If it was only a few seconds ago, then this user just signed up.
    //
    // NOTE: The time the user waits before hitting the green check
    // button in the Authorize App dialog is part of this time.
    // So, if the user waits a long time at that dialog before clicking
    // the green check button, we won't create a new user!!!
    //
    // updated_at changes when user logs in again.
    // So using that time difference instead.
    // 
    const createdAtString = Auth0Provider.state.userAuth0[
      "https://jasiri.com/created_at"];
    const createdAtDate = new Date(createdAtString);
    log.trace("createdAtDate: ", createdAtDate);

    //const diffInSeconds = (Date.now() - createdAtDate.getTime()) / 1000;
    const updatedAtString = Auth0Provider.state.userAuth0[
      "https://jasiri.com/updated_at"];
    const updatedAtDate = new Date(updatedAtString);
    log.trace("updatedAtDate: ", updatedAtDate);
    const diffInSeconds = (updatedAtDate.getTime() -
      createdAtDate.getTime()) / 1000;

    log.trace("diffInSeconds: ", diffInSeconds);

    let userRxDB = null;
    let action = {
      type:ACTION_TYPES.DO_NOTHING,
      payload:{},
    };
    if (diffInSeconds < 15) {
      // User was created a few seconds ago so assume this
      // is a user signing up for the first time.
      log.trace("handleRedirectCallback() creating new user.");
      action.type = ACTION_TYPES.SIGN_UP_S;
      action.payload = await this.createNewUserRxDB(
        Auth0Provider.state.userAuth0);
    }
    else {
      // Get the user record out of the RxDB, if the user already exists.
      log.trace("See if user already exists in RxDB. sub: ",
        Auth0Provider.state.userAuth0.sub);
      const {sub} = Auth0Provider.state.userAuth0;
      const userId = Auth0Provider.convertAuth0IdToRxDBId(sub);
      const curUser = {_id:userId};
      action.payload = await getRxDBObj(curUser, userId, USERS);
      const userRxDB = action.payload && action.payload.rxdbObj;
      log.info("In handleRedirectCallback(), userRxDB: ", userRxDB);

      if (userRxDB) {
        // The existing userAuth0 is signing in.
        // User already existed in the RxDB users collection,
        // and has merely signed into his/her already existing
        // account.
        action.type = ACTION_TYPES.SIGN_IN_S;
        action.payload = ApiUtils.dataRxDBToPayload(userRxDB,
          "user", "users", curUser);
      }
      else if (Auth0Provider.state.userAuth0) {
        log.info("Creating RxDB user for already existing Auth0 account.");
        log.trace("loginsCount: ",
          Auth0Provider.state.userAuth0["https://jasiri.com/loginsCount"]);
        // Auth0 user account already existed before the user
        // came to the Concierge website for the first time.
        // NOTE: userAuth0["https://jasiri.com/loginsCount"] should be > 1.
        // We need to treat this user as though s/he was signing up
        // for the Concierge account just now.
        action.type = ACTION_TYPES.SIGN_UP_S;
        action.payload = await this.createNewUserRxDB(
          Auth0Provider.state.userAuth0);
      }
      else {
        log.bug("In Auth0Provider.handleRedirectCallback(), unhandled state.");
        log.bug("In Auth0Provider.handleRedirectCallback(), userAuth0: ",
          Auth0Provider.state.userAuth0);
        log.bug("In Auth0Provider.handleRedirectCallback(), userRxDB: ",
          userRxDB);
        action.type = ACTION_TYPES.DO_NOTHING;
      }
    }

    // At this point we are NOT guaranteed that userRxDB exists in the
    // browser's RxDB users collection.  (Either newly created above
    // because the user signed up, or the user already existed and 
    // we retrieved it using getUserRxDB() above.
    const userC = action.payload.user;

    // NOTE: This is a hack.  Code above should have dones something
    // like this.
    // TODO: create some sort of authentication system.
    action.payload.user.token = "someSortOfToken"; 

    Auth0Provider.state.userRxDB = userRxDB;
    Auth0Provider.state.userC = userC;
    Auth0Provider.state.isAuthenticated = true;
    Auth0Provider.state.isLoading = false;

    // Update the URL to remove the code=.
    // This code can only be used once, so we need to remove it
    // from the URL to prevent handleRedirectCallback() from
    // running again in the case that the user refreshes the page.
    window.history.replaceState({}, document.title, window.location.pathname);

    //store.dispatch(cA_SetAuth0State(Auth0Provider.state));
    const newState = Object.assign({}, Auth0Provider.state);
    store.dispatch(cA_SetAuth0State(newState));

    log.trace("action.payload: ", action.payload);
    if (action.type != ACTION_TYPES.DO_NOTHING) {
      // Dispatch the Sign In or Sign Up action.
      log.trace("Auth0Provider dispatching action: ", action);
      action.payload.filterAndSort = store.getState().ui.filterAndSort;
      store.dispatch(action);
    }
  };

  // Initialize the auth0 library
  static async initializeAuth0() {
    log.info("Enter initializeAuth0(), Auth0Provider.auth0ClientConfig: ",
      Auth0Provider.auth0ClientConfig);
    log.trace("window.location.origin: "+window.location.origin);
    Auth0Provider.state.auth0Client = await createAuth0Client(Auth0Provider.auth0ClientConfig);
    log.info("In initializeAuth0(), auth0Client: ",
      Auth0Provider.state.auth0Client);

    Auth0Provider.state.loginWithRedirect =
      (...p) => Auth0Provider.state.auth0Client.loginWithRedirect(...p);
    Auth0Provider.state.getTokenSilently =
      (...p) => Auth0Provider.state.auth0Client.getTokenSilently(...p);
    Auth0Provider.state.getIdTokenClaims =
      (...p) => Auth0Provider.state.auth0Client.getIdTokenClaims(...p);
    //logout: (...p) => auth0Client.logout(...p),
    Auth0Provider.state.logout =
      (...p) => Auth0Provider.myLogout(Auth0Provider.state.auth0Client, ...p);

    // Check to see if they have been redirected after login.
    // We are checking for code= in the URL. If that does exist,
    // then we are going to go straight to the handleRedirectCallback()
    // method. This will call Auth0's handleRedirectCallback() method 
    // and then go grab the user's information. We will setState() and
    // React will pass all this information down to our application.
    //if (window.location.search.includes('code=')) {
    if (_.includes(window.location.search, "code=")) {
      //return Auth0Provider.handleRedirectCallback();
      Auth0Provider.handleRedirectCallback();
    }

    Auth0Provider.state.isAuthenticated =
      await Auth0Provider.state.auth0Client.isAuthenticated();
    log.trace("In initializeAuth0(), isAuthenticated: ",
      Auth0Provider.state.isAuthenticated);
    Auth0Provider.state.userAuth0 = Auth0Provider.state.isAuthenticated ?
      await Auth0Provider.state.auth0Client.getUser() : null;
    log.trace("In initializeAuth0(), userAuth0: ",
      Auth0Provider.state.userAuth0);

    Auth0Provider.state.isLoading = false;

    if (Auth0Provider.state.isAuthenticated) {
      const action = {
        type:ACTION_TYPES.SIGN_IN_S,
      };
      const {sub} = Auth0Provider.state.userAuth0;
      const userId = Auth0Provider.convertAuth0IdToRxDBId(sub);
      const userRxDB = await LocalDB.getUserRxDB(userId);
      if (userRxDB) {
        // User already existed in the RxDB users collection,
        // and has merely signed into his/her already existing
        // account.
        action.payload = ApiUtils.dataRxDBToPayload(userRxDB,
          "user", "users");
        action.payload.user.token = "someSortOfToken";
      }
      else {
        // User was not found in the local RxDB users collection
        // because the RxDb collection has not finished intializing.
        // Create a temporary user that will be overwritten later?
        //action.payload.user = UserC.DEFAULT_USER;
        const userRxDBProps = Auth0Provider.convertUserAuth0ToUserRxDBProps(
          Auth0Provider.state.userAuth0);
        action.payload = ApiUtils.dataRxDBToPayload(userRxDBProps,
          "user", "users");
      }
      Auth0Provider.state.userRxDB = userRxDB;
      action.payload.filterAndSort = store.getState().ui.filterAndSort;
      log.trace("initializeAuth0(), store.dispatch() action: ", action);
      store.dispatch(action);
    }
    log.trace("initializeAuth0() returning state: ", Auth0Provider.state);
    return Auth0Provider.state;
  };

  static async myLogout(auth0Client, ...p) {
    log.trace("Auth0Provider.myLogout p: ", ...p);
    log.trace("Auth0Provider.state: ", Auth0Provider.state);
    auth0Client.logout(...p);

    const newState = Object.assign({}, INITIAL_STATE.auth0State);
    Auth0Provider.state = newState;

    store.dispatch(cA_SetAuth0State(newState));
  }

  /*
  static getConfigObject() {
    const {auth0Client, isLoading, isAuthenticated} = Auth0Provider.state;
    const {userAuth0, userRxDB, userC,
      idTokenClaims} = Auth0Provider.state;
    log.trace("In Auth0Provider.render(), userAuth0: ", userAuth0);
    log.trace("In Auth0Provider.render(), auth0Client: ", auth0Client);

    const configObject = {
      auth0Client,
      isLoading,
      isAuthenticated,
      userAuth0,
      userRxDB,
      userC,
      idTokenClaims,
      loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
      getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
      getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
      //logout: (...p) => auth0Client.logout(...p),
      logout: (...p) => Auth0Provider.myLogout(auth0Client, ...p),
    };

    return configObject;
  }
  */

  static async init() {
    log.info("Enter Auth0Provider.init()");
    return await Auth0Provider.initializeAuth0();
  }
}

export default Auth0Provider;
