import React from 'react';
import {Popup, Button, Image, Icon} from 'semantic-ui-react';
import {log} from 'concierge-common';
import FilterAndSortUtils from './utils/filter-and-sort-utils';
import {USERS, WAYS, ACTS, ITNS, ANYS, USAGE} from '../global-constants';


class FilterAndSortButton extends React.Component {

  handleChangeFilter = (e, choice, propToEdit) => {
    const {collectionName, curUser, cA_SetFilterAndSort,
      filterAndSort} = this.props;
    const {propName} = propToEdit;

    FilterAndSortUtils.handleChangeFilter(collectionName,
      propName, choice.value, curUser, cA_SetFilterAndSort,
      filterAndSort);
  }

  handleChangeSort = (e, choice, propToEdit) => {
    const {collectionName, curUser, cA_SetFilterAndSort,
      filterAndSort} = this.props;

    // NOTE: propToEdit.propName is "sort".
    // choice.propName is "displayName" or "created",
    // and choice.values is ["asc", "desc"],
    FilterAndSortUtils.handleChangeSort(collectionName,
      propToEdit, choice, curUser,
      cA_SetFilterAndSort, filterAndSort);
  }

  createPropsToEdit = (collectionName) => {
    const {curUser} = this.props;
    const propsToEdit = [];

    const roleChoices = [
      {label:"Guide", value:"guide"},
      {label:"Traveler", value:"traveler"}];
    if (curUser && curUser.isHardCodedUser &&
      curUser.isHardCodedUser()) {
      roleChoices.unshift({label:"Admin", value:"admin"});
      roleChoices.unshift({label:"Root", value:"root"});
    }

    // First do Filter properties.
    let propToEdit = {};
    switch(collectionName) {
      case USAGE:
        // Fall through
      case ANYS:
        // Fall through
      case USERS:
        propToEdit = {
          //displayName:"Roles";
          propName:"roles",
          propType:"checkboxes",
          choices:roleChoices,
          propPath:"ui.filterAndSort."+collectionName+".filter.roles",
          onChange:(e, choice, propToEd) =>
            this.handleChangeFilter(e, choice, propToEd),
        };
        propsToEdit.push(propToEdit);
        //break;
        // Fall through
      case WAYS:
        // Fall through
      case ACTS:
        // Fall through
      case ITNS:
        if (curUser && curUser.isHardCodedUser &&
          curUser.isHardCodedUser()) {
          propToEdit = {
            //displayName:"Status",
            propName:"statusAttribute",
            propType:"checkboxes",
            choices:[
              {label:"Pending", value:"pending"/*, onChange:()=>{}*/},
              {label:"Flagged", value:"flagged"},
              {label:"Approved", value:"approved"}],
            propPath:"ui.filterAndSort."+collectionName+
              ".filter.statusAttribute",
            onChange:(e, choice, propToEd) =>
              this.handleChangeFilter(e, choice, propToEd),
          };
          propsToEdit.push(propToEdit);
        }
        break;
      default:
        log.bug("In FilterAndSortButton.createPropsToEdit(), "+
          "unhandled collectionName: ", collectionName);
        debugger;
    }

    // Done with Filter properties, now do Sort properties.
    switch(collectionName) {
      case USAGE:
        // Fall through
      case ANYS:
        // Fall through
      case USERS:
        // Fall through
      case WAYS:
        // Fall through
      case ACTS:
        // Fall through
      case ITNS:
        propToEdit = {
          propName:"sort",
          choices:[
            {
              label:"Name", propName:"displayName",
              values:["asc", "desc"],
              iconNames:["sort alphabet down", "sort alphabet up"],
            },
            {
              label:"Created", propName:"created",
              values:["asc", "desc"],
              iconNames:["sort amount up", "sort amount down"],
            }
          ],
          propPath:"ui.filterAndSort."+collectionName+".sort",
        };
        break;
      default:
        log.bug("In FilterAndSortButton.createPropsToEdit(), "+
          "unhandled collectionName: ", collectionName);
        debugger;
    }
    if (propToEdit) {
      // Only one box is "selected", (i.e. you are sorting based on
      // that property, and its value toggles from "asc" to "desc as
      // the user clicks on it.
      //propToEdit.displayName = "Sort";
      propToEdit.propType = "togglingRadioBoxes";
      propToEdit.onChange = (e, choice, propToEdit) =>
        this.handleChangeSort(e, choice, propToEdit);
      propsToEdit.push(propToEdit);
    }

    return propsToEdit;
  }

  toggleFilterAndSortVisibility = () => {
    const {filterAndSort, shellType, collectionName} = this.props;
    const {cA_PropsEditor, cA_SetFilterAndSortVisibility} = this.props;
    const {visible} = filterAndSort;

    if (true) {
    //if (shellType == "shell-mobile") {
      const dialogProps = {
        title:"Filter And Sort",
        headerIcon:"filter",
        okIcon:"filter",
      };
      const propsToEdit = this.createPropsToEdit(collectionName);
      cA_PropsEditor(propsToEdit, dialogProps);
    }
    else {
      cA_SetFilterAndSortVisibility(!visible);
    }
  }

  render() {
    log.trace("FilterAndSortButton.render() this.props:", this.props);
    const props = this.props;
    const {visible, collectionName} = props.filterAndSort;

    const filterAndSort =
      <Popup content={"Filter and sort."} position="left center"
        trigger={
          <Button primary className="icon-and-image right"
            onClick={this.toggleFilterAndSortVisibility}>
            <Icon size="large" name="filter" />
            <Icon size="large" name="sort amount up" />
            {/*<Image size="mini" src="../img/filterAndSortWhite.png" />*/}
          </Button>
        } />

    return filterAndSort;
  };
}

export default FilterAndSortButton;

