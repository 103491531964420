import _ from 'lodash';
import React from 'react';
import {Popup, Segment, Header, Button, Icon} from 'semantic-ui-react';
import {log} from 'concierge-common';
import LocalDB from '../client-db/local-db';
import {USERS, WAYS, ACTS, ITNS, ANYS} from '../global-constants';
import {PENDING, APPROVED, FLAGGED} from '../global-constants';
import MyPopup from '../containers/my-popup';
import FilterAndSortUtils from './utils/filter-and-sort-utils';


class MyHeader extends React.Component {

  handleClickBackButton = () => {
    const {cA_History, onBeforeBack} = this.props;
    if (onBeforeBack) {
      const goBack = onBeforeBack(()=>cA_History("back"));
      if (!goBack) {
        return;
      }
    }
    cA_History("back");
  }

  createBackButton() {
    const backButton =
      <MyPopup content={"Go back to previous page."} position="right center"
        trigger={
          <Button primary className="left" size="big"
            onClick={this.handleClickBackButton}>
            {/*<Icon name="chevron left" />*/}
            <Icon size="large" name="angle left" />
            {/*<Image size="mini" src="../img/navbar/ways.png" />*/}
            <span className="label">Back</span>
          </Button>
        } />

    return backButton;
  }

  // Delete This
  static getFilterButtonInfo = (collectionName) => {
    switch(collectionName) {
      case USERS:
        return {
          field:"roles",
          labels:["root", "admin", "guide", "traveler"],
        };
      case WAYS:
        // Fall through
      case ACTS:
        // Fall through
      case ITNS:
        // Fall through
      case ANYS:
        return {
          field:"statusAttribute",
          labels:[PENDING, APPROVED, FLAGGED],
        };
      default:
        log.bug("In MyHeader.getFilterButtonInfo(), "+
          "unhandled collectionName: ", collectionName);
        debugger;
    }
  }

  createFilterAndSort() {
    const {shellType, collectionName, filterAndSort,
      cA_SetFilterAndSort, curUser} = this.props;
    const {visible} = filterAndSort;
    const {filter, sort} = this.props.filterAndSort[collectionName];

    let className;

    const {labels, field} = MyHeader.getFilterButtonInfo(
      collectionName);

    // Create Filter buttons: Root, Admin, Guide, Traveler
    // or Pending, Flagged, Test
    const filterButtons = labels.map(label => {
      const className = _.includes(filter[field], label) ? "selected" : "";
      const capitalizedLabel = label.charAt(0).toUpperCase()+label.slice(1);
      // NOTE: key is not used.
      const button =
        <Button className={className} key={label}
          size="large" basic color="black"
          onClick={() => {
            FilterAndSortUtils.handleChangeFilter(
              collectionName, field, label, curUser,
              cA_SetFilterAndSort, filterAndSort)}}>
        {capitalizedLabel}</Button>
      return button;
    });

    let iconName;

    // Create Sort buttons: Name, Created, Expired
    className = sort.displayName ? "selected" : "";
    iconName = sort.displayName && (sort.displayName == "asc") ? 
      "sort alphabet down" : "sort alphabet up";

    const propToEdit = {
      propName:"sort",
      choices:[
        {
          label:"Name", propName:"displayName",
          values:["asc", "desc"],
        },
        {
          label:"Created", propName:"created",
          values:["asc", "desc"],
        }
      ],
      propPath:"ui.filterAndSort."+collectionName+".sort",
    };
    const nameButton =
      <Button className={className} size="large" basic color="black"
        onClick={() => FilterAndSortUtils.handleChangeSort(
          collectionName, propToEdit, propToEdit.choices[0],
          curUser, cA_SetFilterAndSort, filterAndSort)}>
        {propToEdit.choices[0].label}
        <Icon name={iconName} />
      </Button>

    className = sort.created ? "selected" : "";
    iconName = sort.created && (sort.created == "asc") ? 
      "sort amount up" : "sort amount down";
    const createdButton =
        <Button className={className} size="large" basic color="black"
        onClick={() => FilterAndSortUtils.handleChangeSort(
          collectionName, propToEdit, propToEdit.choices[1],
          curUser, cA_SetFilterAndSort, filterAndSort)}>
        {propToEdit.choices[1].label}
          <Icon name={iconName} />
        </Button>

    const buttonsClassName = //"ui buttons "+
      (shellType == "shell-mobile" ? "vertical" : "");

    const drawer = <div className="button-drawer">
      <Button.Group className={buttonsClassName}>
        {filterButtons}
      </Button.Group>
      <Button.Group className={buttonsClassName}>
        {nameButton}
        {createdButton}
      </Button.Group>
    </div>

    return drawer;
  }

  render() {
    log.trace("MyHeader.render() this.props:", this.props);
    const {props} = this;
    const {hasBackButton} = props;
    let {className, title, rightButton, filterAndSort} = props;
    className = className ? "my-header " + className : "my-header";

    const backButton = hasBackButton ? this.createBackButton() : undefined;

    title = <div className="title ellipsis">{title}</div>

    rightButton = rightButton ? rightButton :
      <div className="right spacer" />

    const drawer = filterAndSort.visible ?
      this.createFilterAndSort() : undefined;

    return(
      <Header className={className} as='h2' textAlign="center">
        {backButton}
        {title}
        {drawer}
        {rightButton}
      </Header>
    );
  };
}

export default MyHeader;
