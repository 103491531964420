
// Version of the serviceWorker.js
export const SW_VERSION = "2020_11_16_1730";

// Version of the app.  Might not be needed.
//export const APP_VERSION = "2020_10_05_1415";

// LocalDB collection names
export const GLOBALS = "globals";
export const USERS = "users";
export const WAYS = "ways";
export const ACTS = "acts";
export const ITNS = "itns";
export const ANYS = "anys";
export const USAGE = "usage";

export const PENDING = "pending";
export const FLAGGED = "flagged";
export const APPROVED = "approved";

export const REDACTED = "redacted";

// NOTE: Consumers of these will need to prepend
// the relative path to the file.  E.g. "../.."
export const IMG_USER = "img/navbar/user.png";
export const IMG_USERS = "img/navbar/users.png";
export const IMG_WAYS = "img/navbar/ways.png";
export const IMG_ACTS = "img/navbar/acts.png";
export const IMG_ITNS = "img/navbar/itns.png";
//export const IMG_FAVS = "img/navbar/favorites.png";

// NOTE: Consumers of these will need to prepend
// the relative path to the file.  E.g. "../.."
export const IMG = {
  [USERS]:IMG_USERS,
  [WAYS]:IMG_WAYS,
  [ACTS]:IMG_ACTS,
  [ITNS]:IMG_ITNS,
};

/*
export const getFilters = (collectionName) => {
  switch(collectionName) {
    case USERS:
      return {
        field:"roles",
        labels:["root", "admin", "guide", "traveler"],
      };
    case WAYS:
      return {
        field:"statusAttribute",
        labels:[PENDING, APPROVED, FLAGGED],
      };
    case ACTS:
      return {
        field:"statusAttribute",
        labels:[PENDING, APPROVED, FLAGGED],
      };
    case ITNS:
      return {
        field:"statusAttribute",
        labels:[PENDING, APPROVED, FLAGGED],
      };
    default:
      log.bug("In MyHeader.getFilterButtonInfo(), "+
        "unhandled collectionName: ", collectionName);
      debugger;
  }
}
*/


// ******** Time stuff to change from ISO to milliseconds.

// Maximum JavaScript Date is defined in ECMA-262 as ±100,000,000
// (one hundred million) days relative to January 1, 1970 UTC
// const EXPIRES_NEVER = (new Date(8640000000000000)).toISOString();
// But, the above string makes RxDB unhappy because it does not think
// it is an ISOString.  Year below is under 10,000, so it is happy.
//export const EXPIRES_NEVER = (new Date(250000000000000)).toISOString();
export const EXPIRES_NEVER = (new Date(250000000000000)).getTime();


//export const DEFAULT_CREATED = (new Date(0)).toISOString();
export const DEFAULT_CREATED = (new Date(0)).getTime();

export const PRIVATE_ID = "users_privateUserId";
export const DEFAULT_USER_ID = "users_defaultUserId";
export const FOSTER_USER_ID = "users_auth0|5f597d0925bd5200728f880d";
export const SUPPORT_USER_ID = "users_auth0|5f5976b63e11cd006ae6597c";
export const ADMIN_USER_ID = "users_auth0|5f5990c2ac40ef00722d5922";
export const ROOT_USER_ID = "users_auth0|5f5980be3e11cd006ae65a73";

export const DEFAULT_MESSAGES_LAST_RECEIVED = (new Date(0)).getTime();
export const DEFAULT_MESSAGES_LAST_READ = (new Date(1)).getTime();


