import _ from 'lodash';
import React from 'react';
import {Card} from 'semantic-ui-react';
import {log} from 'concierge-common';


class CardsLayout extends React.Component {

  render() {
    log.trace("CardsLayout.render() this.props:", this.props);
    const props = this.props;
    const {objects:objs, CardContents} = props;

    const cards = _.map(objs, obj => {
      const objId = ""+obj._id;
      log.trace("Processing obj:", obj);
      return(<CardContents obj={obj} key={objId} {...props} />);
    });
    return (
      <Card.Group className="centered">
        {cards}
      </Card.Group>
    );
  }
}

export default CardsLayout;
